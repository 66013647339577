import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Local Date Time type */
  LocalDateTime: any;
  /**
   * Leverages the internal Python implmeentation of UUID (uuid.UUID) to provide native UUID objects
   * in fields, resolvers and input.
   */
  UUID: any;
  Map: any;
  Time: any;
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: any;
  /**
   * Allows use of a JSON String for input / output from the GraphQL schema.
   *
   * Use of this type is *not recommended* as you lose the benefits of having a defined, static
   * schema (one of the key benefits of GraphQL).
   */
  JSONString: any;
  /**
   * The `GenericScalar` scalar type represents a generic
   * GraphQL scalar value that could be:
   * String, Boolean, Int, Float, List or Object.
   */
  GenericScalar: any;
  MpesaPhoneNumber: any;
  RequiredString: any;
  Date: any;
  /**
   * Create scalar that ignores normal serialization/deserialization, since
   * that will be handled by the multipart request spec
   */
  Upload: any;
  /** Scalar for BigDecimal */
  BigDecimal: any;
  /** Scalar for BigInteger */
  BigInteger: any;
};


export type GqlQuery = {
  __typename?: 'Query';
  /** Fetch inbox messages by userID */
  inbox: GqlInboxGqlConnection;
  eTIMsCodes: GqlSelectCodesResponse;
  eTIMsClassCodes: GqlClassCodesResponse;
  eTIMsBranchList: GqlBranchListResponse;
  eTIMsNoticeList: GqlNoticeResponse;
  eTIMsStockMovementList: GqlStockMovementsResponse;
  eTIMsItems: GqlItemsResponse;
  eTIMsPurchases: GqlPurchasesResponse;
  permissions_v2: Maybe<GqlPermissionV2GqlConnection>;
  permission_v2_by_id: Maybe<GqlPermissionV2Gql>;
  users_v2: Maybe<GqlUserV2GqlConnection>;
  user_v2_by_id: Maybe<GqlUserV2Gql>;
  users_v2_by_permissions: Maybe<Array<Maybe<GqlUserV2Gql>>>;
  user_v2_by_id_or_number: Maybe<GqlUserV2Gql>;
  users_v2_by_ids: Maybe<Array<Maybe<GqlUserV2Gql>>>;
  roles_v2: Maybe<GqlRoleV2GqlConnection>;
  role_v2_by_id: Maybe<GqlRoleV2Gql>;
  serverVersion: Scalars['String'];
  autoReconConfig: Maybe<GqlAutoReconConfig>;
  autoReconConfigs: GqlAutoreconConfigConnection;
  paymentClaim: Maybe<GqlPaymentClaim>;
  paymentClaims: GqlPaymentClaimConnection;
  autoreconLookup: GqlGenericResponse;
  requestMpesaBalance: GqlGenericResponse;
  paymentParty: Maybe<GqlParty>;
  paymentParties: GqlPartyConnection;
  partyProviderModes: GqlPartyProviderModeConnection;
  payment: Maybe<GqlPayment>;
  payments: GqlPaymentConnection;
  paymentConfig: Maybe<GqlPaymentConfig>;
  paymentConfigs: GqlPaymentConfigConnection;
  paymentProviders: Array<GqlProvider>;
  paymentProvider: Maybe<GqlProvider>;
  providerModes: Array<GqlProviderMode>;
  work_orders: Maybe<GqlWorkOrderGqlConnection>;
  work_order_by_id: Maybe<GqlWorkOrderGql>;
  work_order_definitions: Maybe<Array<Maybe<GqlWorkOrderDefinitionGql>>>;
  work_order_definition_by_id: Maybe<GqlWorkOrderDefinitionGql>;
  work_order_items: Maybe<GqlWorkOrderItemGqlConnection>;
  work_order_item_by_id: Maybe<GqlWorkOrderItemGql>;
  work_order_reasons: Maybe<Array<Maybe<GqlWorkOrderReasonGql>>>;
  work_order_reason_by_id: Maybe<GqlWorkOrderReasonGql>;
  clusters: Maybe<GqlSalesAgentClusterGqlConnection>;
  cluster_by_id: Maybe<GqlSalesAgentClusterGql>;
  cluster_assignments: Maybe<GqlClusterAssignmentGqlConnection>;
  cluster_assignment_by_id: Maybe<GqlClusterAssignmentGql>;
  cluster_assignments_by_user_id: Maybe<Array<Maybe<GqlClusterAssignmentGql>>>;
  cluster_agent_earnings: Maybe<GqlClusterAgentEarningGqlConnection>;
  cluster_agent_earning_by_id: Maybe<GqlClusterAgentEarningGql>;
  cluster_earning_summary: Maybe<GqlClusterEarningSummaryGql>;
  agent_daily_order_summary: Maybe<GqlAgentDailyOrderSummaryGqlConnection>;
  segment_earnings_summary: Maybe<Array<Maybe<GqlSegmentEarningsSummaryGql>>>;
  territories: Maybe<Array<Maybe<GqlTerritoryGql>>>;
  agent_earnings: Maybe<Array<Maybe<GqlAgentEarningsGql>>>;
  agent_earnings_summary: Maybe<GqlAgentEarningsSummaryGql>;
  delivery_returns: Maybe<GqlDeliveryReturnGqlConnection>;
  delivery_return_by_id: Maybe<GqlDeliveryReturnGql>;
  unpaginated_delivery_returns: Maybe<Array<Maybe<GqlDeliveryReturnGql>>>;
  refund_statuses: Maybe<Array<Maybe<Scalars['String']>>>;
  delivery_return_items: Maybe<GqlDeliveryReturnItemGqlConnection>;
  delivery_return_item_by_id: Maybe<GqlDeliveryReturnItemGql>;
  order_returns: Maybe<GqlOrderReturnGqlConnection>;
  order_return_by_id: Maybe<GqlOrderReturnGql>;
  order_return_items: Maybe<GqlOrderReturnItemGqlConnection>;
  order_return_item_by_id: Maybe<GqlOrderReturnItemGql>;
  sales_return_reasons: Maybe<Array<Maybe<GqlReturnReasonGql>>>;
  sales_return_reason_by_id: Maybe<GqlReturnReasonGql>;
  return_item_rejection_reasons: Maybe<Array<Maybe<GqlReturnItemRejectionReasonGql>>>;
  return_item_rejection_reason_by_id: Maybe<GqlReturnItemRejectionReasonGql>;
  mcommerce_account_by_customer_id: Maybe<GqlMcommerceAccountGql>;
  stock_notifications: Maybe<GqlStockNotificationGqlConnection>;
  stock_notification_by_id: Maybe<GqlStockNotificationGql>;
  ordering_windows: Maybe<GqlOrderingWindowGqlConnection>;
  ordering_window_by_id: Maybe<GqlOrderingWindowGql>;
  ordering_window_items: Maybe<GqlOrderingWindowItemGqlConnection>;
  ordering_window_item_by_id: Maybe<GqlOrderingWindowItemGql>;
  mcommerce_faq_sections: Maybe<Array<Maybe<GqlMcommerceFaqSectionGql>>>;
  mcommerce_faq_section_by_id: Maybe<GqlMcommerceFaqSectionGql>;
  mcommerce_faqs: Maybe<GqlMcommerceFaqgqlConnection>;
  mcommerce_faq_by_id: Maybe<GqlMcommerceFaqgql>;
  next_of_kins: Maybe<Array<Maybe<GqlNextOfKinGql>>>;
  customer_policies: Maybe<Array<Maybe<GqlPolicyGql>>>;
  cover_types: Maybe<GqlCoverTypeGqlConnection>;
  insurers: Maybe<Array<Maybe<GqlInsurerGql>>>;
  payment_accounts_v2: Maybe<GqlPaymentAccountV2GqlConnection>;
  payment_account_v2_by_id: Maybe<GqlPaymentAccountV2Gql>;
  route_payment_accounts_v2: Maybe<GqlRoutePaymentAccountV2GqlConnection>;
  route_payment_account_v2_by_id: Maybe<GqlRoutePaymentAccountV2Gql>;
  route_payment_accounts_v2_by_route_id: Maybe<Array<Maybe<GqlRoutePaymentAccountV2Gql>>>;
  payments_v2: Maybe<GqlPaymentV2GqlConnection>;
  payment_v2_by_id: Maybe<GqlPaymentV2Gql>;
  payment_modes: Maybe<Array<Maybe<Scalars['String']>>>;
  payment_visibilities: Maybe<Array<Maybe<GqlPaymentVisibilityGql>>>;
  payment_visibility_by_id: Maybe<GqlPaymentVisibilityGql>;
  payment_methods: Maybe<Array<Maybe<GqlPaymentMethodGql>>>;
  payment_method_by_id: Maybe<GqlPaymentMethodGql>;
  route_till_alert_contacts: Maybe<GqlRouteTillAlertContactGqlConnection>;
  credited_delivery_fee_credits: Maybe<GqlCashBackGqlConnection>;
  vendor_payments_summary: Maybe<Array<Maybe<GqlVendorPaymentsSummaryGql>>>;
  vendor_payments: Maybe<GqlVendorPaymentsGqlConnection>;
  unpaginated_vendor_payments: Maybe<Array<Maybe<GqlVendorPaymentsGql>>>;
  deliveries_v2: Maybe<GqlDeliveryV2GqlConnection>;
  delivery_v2_by_id: Maybe<GqlDeliveryV2Gql>;
  delivery_fee_schedules: Maybe<GqlDeliveryFeeScheduleGqlConnection>;
  delivery_fee_schedule_by_id: Maybe<GqlDeliveryFeeScheduleGql>;
  realized_revenue_v2: Maybe<GqlRealizedRevenueV2Gql>;
  unpaginated_deliveries: Maybe<Array<Maybe<GqlDeliveryV2Gql>>>;
  delivery_items_v2: Maybe<GqlDeliveryItemV2GqlConnection>;
  delivery_item_v2_by_id: Maybe<GqlDeliveryItemV2Gql>;
  shop_delivery_summary_v2: Maybe<GqlShopDeliverySummaryV2Gql>;
  delivery_product_summary_v2: Maybe<GqlDeliveryProductSummaryV2GqlConnection>;
  sales_per_product: Maybe<GqlSalesPerProductGqlConnection>;
  sales_per_product_unpaginated: Maybe<Array<Maybe<GqlSalesPerProductGql>>>;
  daily_otif: Maybe<GqlDailyOtifgqlConnection>;
  daily_otif_summary: Maybe<GqlDailyOtifSummaryGql>;
  daily_otif_unpaginated: Maybe<Array<Maybe<GqlDailyOtifgql>>>;
  delivery_item_warranties: Maybe<GqlDeliveryItemWarrantyGqlConnection>;
  delivery_item_warranty_by_id: Maybe<GqlDeliveryItemWarrantyGql>;
  trader_entities_v2: Maybe<Array<Maybe<GqlTraderEntityV2Gql>>>;
  trader_entity_v2_by_id: Maybe<GqlTraderEntityV2Gql>;
  orders_v2: Maybe<GqlOrderV2GqlConnection>;
  order_v2_by_id: Maybe<GqlOrderV2Gql>;
  current_orders_v2: Maybe<Array<Maybe<GqlOrderV2Gql>>>;
  unpaginated_orders: Maybe<Array<Maybe<GqlOrderV2Gql>>>;
  order_items_v2: Maybe<GqlOrderItemV2GqlConnection>;
  order_item_v2_by_id: Maybe<GqlOrderItemV2Gql>;
  order_again: Maybe<GqlOrderAgainGql>;
  order_assignments_v2: Maybe<GqlOrderAssignmentV2GqlConnection>;
  order_assignment_v2_by_id: Maybe<GqlOrderAssignmentV2Gql>;
  all_stock_ordered_depot_summary: Maybe<Array<Maybe<GqlStockOrderedGql>>>;
  stock_ordered_depot_summary: Maybe<GqlStockOrderedGql>;
  multiple_product_items_stock_ordered_depot_summary: Maybe<Array<Maybe<GqlStockOrderedGql>>>;
  stock_ordered_by_product_item: Maybe<GqlStockOrderedGql>;
  multiple_product_items_stock_ordered: Maybe<Array<Maybe<GqlStockOrderedGql>>>;
  shop_order_summary_v2: Maybe<GqlShopOrderSummaryV2Gql>;
  order_product_summary_v2: Maybe<GqlOrderProductSummaryV2GqlConnection>;
  projected_revenue_v2: Maybe<GqlProjectedRevenueV2Gql>;
  route_plan_order_summary: Maybe<GqlRoutePlanOrderSummaryGql>;
  route_plan_order_summaries_by_route_plan_ids: Maybe<Array<Maybe<GqlRoutePlanOrderSummaryGql>>>;
  delivery_vehicle_manifest_summary: Maybe<GqlDeliveryVehicleManifestSummaryGql>;
  ordered_stock_allocation: Maybe<GqlOrderedStockAllocationGqlConnection>;
  unpaginated_ordered_stock_allocation: Maybe<Array<Maybe<GqlOrderedStockAllocationGql>>>;
  order_restrictions: Maybe<Array<Maybe<GqlOrderRestrictionGql>>>;
  depot_dc_product_item_restrictions: Maybe<GqlDepotDcProductItemRestrictionGqlConnection>;
  distribution_center_orders_summary: Maybe<Array<Maybe<GqlDistributionCenterOrderSummaryGql>>>;
  delivery_schedules: Maybe<Array<Maybe<GqlDeliveryScheduleGql>>>;
  delivery_schedule_by_id: Maybe<GqlDeliveryScheduleGql>;
  delivery_processing_fees: Maybe<GqlDeliveryProcessingFeeGqlConnection>;
  delivery_processing_fee_by_id: Maybe<GqlDeliveryProcessingFeeGql>;
  shop_delivery_processing_fees: Maybe<GqlShopDeliveryProcessingFeeGql>;
  regions_v2: Maybe<GqlRegionV2GqlConnection>;
  region_v2_by_id: Maybe<GqlRegionV2Gql>;
  country_by_id: Maybe<GqlCountryGql>;
  countries_by_ids: Maybe<Array<Maybe<GqlCountryGql>>>;
  regions_v2_by_ids: Maybe<Array<Maybe<GqlRegionV2Gql>>>;
  areas_v2: Maybe<GqlAreaV2GqlConnection>;
  area_v2_by_id: Maybe<GqlAreaV2Gql>;
  depots_v2: Maybe<GqlDepotV2GqlConnection>;
  depot_v2_by_id: Maybe<GqlDepotV2Gql>;
  depots_v2_by_ids: Maybe<Array<Maybe<GqlDepotV2Gql>>>;
  route_types: Maybe<Array<Maybe<GqlRouteType>>>;
  routes_v2: Maybe<GqlRouteV2GqlConnection>;
  route_v2_by_id: Maybe<GqlRouteV2Gql>;
  routes_v2_by_ids: Maybe<Array<Maybe<GqlRouteV2Gql>>>;
  route_teams_v2: Maybe<GqlRouteTeamV2GqlConnection>;
  route_team_v2_by_id: Maybe<GqlRouteTeamV2Gql>;
  route_team_v2_by_user_id: Maybe<GqlRouteTeamV2Gql>;
  route_team_members_v2: Maybe<GqlRouteTeamMemberV2GqlConnection>;
  route_team_member_v2_by_id: Maybe<GqlRouteTeamMemberV2Gql>;
  countries: Maybe<Array<Maybe<GqlCountryGql>>>;
  depot_routing_summary: Maybe<GqlDepotRoutingSummaryGqlConnection>;
  customers_v2: Maybe<GqlCustomerV2GqlConnection>;
  customer_v2_by_id: Maybe<GqlCustomerV2Gql>;
  phone_number_change_reasons: Maybe<Array<Maybe<GqlPhoneNumberChangeReasonGql>>>;
  phone_number_change_approvals: Maybe<Array<Maybe<GqlPhoneNumberChangeApprovalGql>>>;
  shops_v2: Maybe<GqlShopV2GqlConnection>;
  shops_v2_by_customer_id: Maybe<Array<Maybe<GqlShopV2Gql>>>;
  shop_v2_by_id: Maybe<GqlShopV2Gql>;
  shop_types_v2: Maybe<Array<Maybe<GqlShopTypeV2Gql>>>;
  shop_type_v2_by_id: Maybe<GqlShopTypeV2Gql>;
  customer_types_v2: Maybe<Array<Maybe<GqlCustomerTypeV2Gql>>>;
  vendor_kyc: Maybe<GqlVendorKycgqlConnection>;
  vendor_kyc_by_id: Maybe<GqlVendorKycgql>;
  customer_type_v2_by_id: Maybe<GqlCustomerTypeV2Gql>;
  customer_v2_by_phone_number: Maybe<GqlCustomerV2Gql>;
  vendor_finance_plans: Maybe<Array<Maybe<GqlVendorFinancePlanGql>>>;
  delivery_fee_by_shop_id: Maybe<GqlShopDeliveryFeeGql>;
  shops_v2_by_ids: Maybe<Array<Maybe<GqlShopV2Gql>>>;
  checklist_sections: Maybe<Array<Maybe<GqlChecklistSectionGql>>>;
  checklist_section_by_id: Maybe<GqlChecklistSectionGql>;
  checklist_questions: Maybe<GqlChecklistQuestionGqlConnection>;
  checklist_question_by_id: Maybe<GqlChecklistQuestionGql>;
  checklist_entries: Maybe<GqlChecklistEntryGqlConnection>;
  checklist_entry_by_id: Maybe<GqlChecklistEntryGql>;
  incident_categories: Maybe<Array<Maybe<GqlIncidentCategoryGql>>>;
  incidents: Maybe<GqlIncidentGqlConnection>;
  incident_by_id: Maybe<GqlIncidentGql>;
  vehicle_cost_models: Maybe<Array<Maybe<Scalars['String']>>>;
  vehicle_rejection_reasons: Maybe<Array<Maybe<Scalars['String']>>>;
  vehicle_suppliers: Maybe<GqlVehicleSupplierGqlConnection>;
  vehicle_supplier_payment_accounts: Maybe<GqlVehicleSupplierPaymentAccountGqlConnection>;
  trips: Maybe<GqlTripGqlConnection>;
  vehicle_supplier_statement_files: Maybe<GqlVehicleSupplierStatementFileGqlConnection>;
  vehicle_supplier_statements: Maybe<GqlVehicleSupplierStatementGqlConnection>;
  vehicle_types: Maybe<GqlVehicleTypeGqlConnection>;
  vehicle_type_by_id: Maybe<GqlVehicleTypeGql>;
  vehicles: Maybe<GqlVehicleGqlConnection>;
  vehicle_by_id: Maybe<GqlVehicleGql>;
  route_plans: Maybe<GqlRoutePlanGqlConnection>;
  route_plan_by_id: Maybe<GqlRoutePlanGql>;
  route_plans_by_ids: Maybe<Array<Maybe<GqlRoutePlanGql>>>;
  unpaginated_route_plans: Maybe<Array<Maybe<GqlRoutePlanGql>>>;
  fulfillment_teams: Maybe<GqlFulfillmentTeamGqlConnection>;
  fulfillment_team_by_id: Maybe<GqlFulfillmentTeamGql>;
  static_routes: Maybe<GqlStaticRouteGqlConnection>;
  static_route_by_id: Maybe<GqlStaticRouteGql>;
  depot_route_plan_summary: Maybe<GqlDepotRoutePlanSummaryGql>;
  pseudo_routes: Maybe<GqlPseudoRouteGqlConnection>;
  pseudo_route_by_id: Maybe<GqlPseudoRouteGql>;
  pseudo_routes_vehicle_assignment: Maybe<GqlPseudoRoutesVehicleAssignmentGqlConnection>;
  unpaginated_pseudo_routes_vehicle_assignment: Maybe<Array<Maybe<GqlPseudoRoutesVehicleAssignmentGql>>>;
  /** The ID of the object */
  node: Maybe<GqlNode>;
  dispatches: Maybe<GqlDispatchGqlConnection>;
  dispatch: Maybe<GqlDispatchGqlConnection>;
  dispatch_by_id: Maybe<GqlDispatchGql>;
  dispatch_by_route_id: Maybe<GqlDispatchGql>;
  dispatch_item_by_id: Maybe<GqlDispatchItemGql>;
  dispatch_items: Maybe<GqlDispatchItemGqlConnection>;
  stock_transfers: Maybe<GqlStockTransferGqlConnection>;
  stock_transfer: Maybe<GqlStockTransferGqlConnection>;
  stock_transfer_by_id: Maybe<GqlStockTransferGql>;
  stock_transfer_item_by_id: Maybe<GqlStockTransferItemGql>;
  stock_transfer_items: Maybe<GqlStockTransferItemGqlConnection>;
  dispatch_balance: Maybe<GqlDispatchBalanceGql>;
  depot_intakes: Maybe<GqlDepotIntakeGqlConnection>;
  depot_intake: Maybe<GqlDepotIntakeGqlConnection>;
  depot_intake_by_id: Maybe<GqlDepotIntakeGql>;
  depot_intake_item_by_id: Maybe<GqlDepotIntakeItemGql>;
  depot_intake_items: Maybe<GqlDepotIntakeItemGqlConnection>;
  returns: Maybe<GqlReturnGqlConnection>;
  return_by_id: Maybe<GqlReturnGql>;
  product_return_item_by_id: Maybe<GqlProductReturnItemGql>;
  product_return_items: Maybe<GqlProductReturnItemGqlConnection>;
  packaging_return_item_by_id: Maybe<GqlPackagingReturnItemGql>;
  packaging_return_items: Maybe<GqlPackagingReturnItemGqlConnection>;
  dispatch_product_summary_v2: Maybe<GqlDispatchProductSummaryV2GqlConnection>;
  projected_ph_revenue_v2: Maybe<GqlProjectedPhRevenueV2Gql>;
  vehicle_stock_allocations: Maybe<GqlVehicleStockAllocationGqlConnection>;
  vehicle_stock_allocation_by_id: Maybe<GqlVehicleStockAllocationGql>;
  vehicle_delivery_and_loading: Maybe<GqlVehicleDeliveryAndLoadingGqlConnection>;
  unpaginated_vehicle_delivery_and_loading: Maybe<Array<Maybe<GqlVehicleDeliveryAndLoadingGql>>>;
  vehicle_delivery_and_loading_summary: Maybe<GqlVehicleDeliveryAndLoadingSummaryGql>;
  pricing_v2: Maybe<GqlPricingV2GqlConnection>;
  pricing_v2_by_id: Maybe<GqlPricingV2Gql>;
  pricing_v2_by_product_item_id: Maybe<GqlPricingV2Gql>;
  pricing_v2_by_product_item_ids: Maybe<Array<Maybe<GqlPricingV2Gql>>>;
  pricing_v2_by_region_id: Maybe<GqlPricingV2Gql>;
  dynamic_pricing: Maybe<GqlDynamicPricingGqlConnection>;
  dynamic_pricing_by_id: Maybe<GqlDynamicPricingGql>;
  price_comparison: Maybe<Array<Maybe<GqlPriceComparisonGql>>>;
  batches: Maybe<GqlBatchGqlConnection>;
  batch_by_id: Maybe<GqlBatchGql>;
  articles: Maybe<GqlArticleGqlConnection>;
  article_by_id: Maybe<GqlArticleGql>;
  saved_articles: Maybe<GqlSavedArticleGqlConnection>;
  saved_article_by_id: Maybe<GqlSavedArticleGql>;
  bids: Maybe<GqlBidGqlConnection>;
  bid_applications: Maybe<GqlBidApplicationGqlConnection>;
  bid_by_id: Maybe<GqlBidGql>;
  bids_by_category_ids: Maybe<GqlBidGqlConnection>;
  count_bids_by_category_ids: Maybe<Scalars['Int']>;
  bid_application_by_id: Maybe<GqlBidApplicationGql>;
  bid_status: Maybe<Array<Maybe<GqlBidStatus>>>;
  bid_application_status: Maybe<Array<Maybe<GqlBidApplicationStatus>>>;
  bid_application_status_type: Maybe<Array<Maybe<GqlBidApplicationType>>>;
  bid_application_by_agent_id: Maybe<GqlBidApplicationGqlConnection>;
  bid_application_by_vehicle_registration: Maybe<GqlBidApplicationGqlConnection>;
  currency: Maybe<Array<Maybe<GqlCurrency>>>;
  harvests: Maybe<GqlHarvestGqlConnection>;
  harvest_vs_intake: Maybe<GqlHarvestVsIntakeGqlConnection>;
  harvest_vs_intake_unpaginated: Maybe<Array<Maybe<GqlHarvestVsIntakeGql>>>;
  harvest_issues: Maybe<Array<Maybe<GqlHarvestIssueGql>>>;
  harvest_issue_types: Maybe<Array<Maybe<GqlHarvestIssueTypeGql>>>;
  harvest_by_id: Maybe<GqlHarvestGql>;
  harvest_issues_by_harvest_id: Maybe<Array<Maybe<GqlHarvestIssueGql>>>;
  payment_details: Maybe<GqlPaymentDetailGqlConnection>;
  harvest_items: Maybe<GqlHarvestItemGqlConnection>;
  harvest_item_by_id: Maybe<GqlHarvestItemGql>;
  harvest_item_assets: Maybe<GqlHarvestItemAssetGqlConnection>;
  harvest_item_assets_by_id: Maybe<GqlHarvestItemAssetGqlConnection>;
  payment_account_types: Maybe<Array<Maybe<GqlPaymentAccountType>>>;
  farmer_types: Maybe<Array<Maybe<GqlFarmerType>>>;
  farmers: Maybe<GqlFarmerGqlConnection>;
  farmers_by_collection_center_id: Maybe<Array<Maybe<GqlFarmerGql>>>;
  farmers_by_area_id: Maybe<Array<Maybe<GqlFarmerGql>>>;
  farmer_by_id: Maybe<GqlFarmerGql>;
  sourcing_agents: Maybe<GqlSourcingAgentGqlConnection>;
  sourcing_agent_by_id: Maybe<GqlSourcingAgentGql>;
  farm_by_id: Maybe<GqlFarmGql>;
  farms: Maybe<GqlFarmGqlConnection>;
  farms_by_farmer_id: Maybe<Array<Maybe<GqlFarmGql>>>;
  farmer_payment_accounts: Maybe<GqlFarmerPaymentAccountGqlConnection>;
  agent_feedback: Maybe<GqlAgentFeedbackGqlConnection>;
  agent_feedback_by_id: Maybe<GqlAgentFeedbackGql>;
  categories: Maybe<Array<Maybe<Scalars['GenericScalar']>>>;
  discovery_methods: Maybe<Array<Maybe<GqlDiscoveryGql>>>;
  supplier_users: Maybe<GqlSupplierUserGqlConnection>;
  supplier_user_by_id: Maybe<GqlSupplierUserGql>;
  supplier_user_by_supplier_id: Maybe<GqlSupplierUserGqlConnection>;
  supplier_products: Maybe<GqlSupplierProductGqlConnection>;
  supplier_product_by_id: Maybe<GqlSupplierProductGql>;
  supplier_product_by_supplier_id: Maybe<GqlSupplierProductGqlConnection>;
  booking_by_id: Maybe<GqlBookingGql>;
  bookings: Maybe<GqlBookingGqlConnection>;
  sourcing_price_range: Maybe<GqlPriceRangeGqlConnection>;
  sourcing_price_range_by_id: Maybe<GqlPriceRangeGql>;
  sourcing_price_range_by_product_id: Maybe<GqlPriceRangeGql>;
  booking_items: Maybe<GqlBookingItemGqlConnection>;
  booking_item_by_id: Maybe<GqlBookingItemGql>;
  booking_summary: Maybe<GqlBookingSummaryGqlConnection>;
  booking_summary_unpaginated: Maybe<Array<Maybe<GqlBookingSummaryGql>>>;
  booking_summary_totals: Maybe<GqlBookingSummaryTotalsGql>;
  areas: Maybe<Array<Maybe<GqlAreaGql>>>;
  blocks: Maybe<Array<Maybe<GqlBlockGql>>>;
  pivots: Maybe<Array<Maybe<GqlPivotGql>>>;
  area_by_id: Maybe<GqlAreaGql>;
  collection_centers: Maybe<Array<Maybe<GqlCollectionCenterGql>>>;
  collection_centers_by_area_id: Maybe<Array<Maybe<GqlCollectionCenterGql>>>;
  collection_center_by_id: Maybe<GqlCollectionCenterGql>;
  harvest_areas: Maybe<Array<Maybe<GqlHarvestAreaGql>>>;
  harvest_area_by_id: Maybe<GqlHarvestAreaGql>;
  harvest_areas_by_collection_center_id: Maybe<Array<Maybe<GqlHarvestAreaGql>>>;
  ewalletAccount: Maybe<GqlEwalletAccount>;
  ewalletAccounts: GqlEwalletAccountConnection;
  ewalletAccountsForUser: Array<GqlEwalletAccount>;
  ledgerEntry: Maybe<GqlLedgerEntry>;
  ledgerEntries: GqlLedgerEntryConnection;
  ledgerEntriesForUser: GqlLedgerEntryConnection;
  ewalletTransactionType: Maybe<GqlEwalletTransactionType>;
  ewalletTransactionTypes: GqlEwalletTransactionTypeConnection;
  /** Authorizers given Ids */
  authorizers_by_ids: Maybe<Array<Maybe<GqlAuthorizerGql>>>;
  /** list of Authorizers */
  authorizers: Maybe<GqlAuthorizerGqlConnection>;
  /** Workflows given Ids */
  workflows_by_ids: Maybe<Array<Maybe<GqlWorkflowGql>>>;
  /** Workflow given an Id */
  workflow_by_id: Maybe<GqlWorkflowGql>;
  /** Authorizer given an Id */
  authorizer_by_id: Maybe<GqlAuthorizerGql>;
  /** Request given an Id */
  request_by_id: Maybe<GqlRequestGql>;
  /** Requests given Ids */
  requests_by_ids: Maybe<Array<Maybe<GqlRequestGql>>>;
  /** list of Requests */
  requests: Maybe<GqlRequestGqlConnection>;
  /** list of Workflows */
  workflows: Maybe<GqlWorkflowGqlConnection>;
  returns_handling_unit_items: Maybe<GqlReturnsHandlingUnitItemGqlConnection>;
  returns_handling_units: Maybe<GqlReturnsHandlingUnitGqlConnection>;
  wms_returns: Maybe<GqlWmsReturnsGqlConnection>;
  disposal_items: Maybe<GqlDisposalItemGqlConnection>;
  disposals: Maybe<GqlDisposalGqlConnection>;
  warehouse_transfer_request_items: Maybe<GqlWarehouseTransferRequestItemGqlConnection>;
  warehouse_transfer_requests: Maybe<GqlWarehouseTransferRequestGqlConnection>;
  fdr_skus: Maybe<GqlFdrskugqlConnection>;
  fulfilment_dispatch_request_items: Maybe<GqlFulfilmentDispatchRequestItemGqlConnection>;
  fulfilment_dispatch_requests: Maybe<GqlFulfilmentDispatchRequestGqlConnection>;
  reserve_picking_jobs: Maybe<GqlReservePickingJobGqlConnection>;
  pickface_picking_tasks: Maybe<GqlPickFacePickingTaskGqlConnection>;
  picking_handling_units: Maybe<GqlPickingHandlingUnitGqlConnection>;
  pickface_picking_task_items: Maybe<GqlPickFacePickingTaskItemGqlConnection>;
  loading_tasks: Maybe<GqlLoadingTaskGqlConnection>;
  loading_task_items: Maybe<GqlLoadingTaskItemGqlConnection>;
  putaway_jobs: Maybe<GqlPutAwayJobGqlConnection>;
  product_zones: Maybe<GqlProductZoneGqlConnection>;
  rules: Maybe<GqlRuleGqlConnection>;
  product_rules: Maybe<GqlProductRuleGqlConnection>;
  category_rules: Maybe<GqlCategoryRuleGqlConnection>;
  handling_units: Maybe<GqlHandlingUnitGqlConnection>;
  stock_intake_skus: Maybe<GqlStockIntakeSkuGqlConnection>;
  handling_unit_items: Maybe<GqlHandlingUnitItemGqlConnection>;
  storage_locations: Maybe<GqlStorageLocationGqlConnection>;
  storage_location_types: Maybe<GqlStorageLocationTypeGqlConnection>;
  product_loss_reasons: Maybe<GqlProductLossReasonGqlConnection>;
  product_loss_reason_by_id: Maybe<GqlProductLossReasonGql>;
  product_loss_reason_by_product_id: Maybe<GqlProductLossReasonGql>;
  storage_unit_types: Maybe<Array<Maybe<GqlStorageUnitTypeGql>>>;
  dock_doors: Maybe<GqlDockDoorGqlConnection>;
  filter_bins: Maybe<Array<Maybe<GqlCustomBinGql>>>;
  bins: Maybe<GqlBinGqlConnection>;
  bin_names: Maybe<GqlBinNameGqlConnection>;
  waste_areas: Maybe<GqlWasteAreaGqlConnection>;
  appointment_items: Maybe<GqlAppointmentItemGqlConnection>;
  appointment_item_by_batch_id: Maybe<Array<Maybe<GqlAppointmentItemGql>>>;
  appointments: Maybe<GqlAppointmentGqlConnection>;
  warehouses: Maybe<GqlWarehouseGqlConnection>;
  stock_intakes: Maybe<GqlStockIntakeGqlConnection>;
  stock_intake_summary: Maybe<Array<Maybe<GqlStockIntakeSummaryGql>>>;
  stock_intake_by_id: Maybe<GqlStockIntakeGql>;
  etims_event_outbox_items: Maybe<GqlEtimsEventOutboxConnection>;
  stock_intake_items: Maybe<GqlStockIntakeItemGqlConnection>;
  stock_intake_item_by_id: Maybe<GqlStockIntakeItemGql>;
  stock_intake_items_by_batch_id: Maybe<GqlStockIntakeItemBatchGql>;
  processing_loss: Maybe<GqlProcessingLossGqlConnection>;
  processing_loss_by_id: Maybe<GqlProcessingLossGql>;
  processing_loss_items: Maybe<GqlProcessingLossItemGqlConnection>;
  processing_loss_item_by_id: Maybe<GqlProcessingLossItemGql>;
  storage_units: Maybe<GqlStorageUnitGqlConnection>;
  storage: Maybe<GqlStorageGqlConnection>;
  storage_by_id: Maybe<GqlStorageGql>;
  v2_stock_availability: Maybe<GqlStockAvailabilityGqlConnection>;
  v2_stock_availability_by_id: Maybe<GqlStockAvailabilityGqLv2>;
  v2_stock_availability_product_item_summary: Maybe<GqlStockAvailabilitySummaryGqlConnection>;
  v2_stock_availability_unpaginated: Maybe<Array<Maybe<GqlStockAvailabilitySummaryGql>>>;
  stock_availability_by_product_item: Maybe<GqlStockAvailabilityProductItemGqlConnection>;
  v2_stock_availability_by_product_item_id: Maybe<GqlStockAvailabilityGqLv2>;
  multiple_product_items_stock_availability_per_dc: Maybe<Array<Maybe<GqlStockAvailabilityGqLv2>>>;
  dc_stock_availability_by_product_item_id: Maybe<GqlDcStockAvailabilityGql>;
  multiple_product_items_stock_availability: Maybe<Array<Maybe<GqlDcStockAvailabilityGql>>>;
  loss_reasons: Maybe<Array<Maybe<GqlLossReasonGql>>>;
  loss_reason_by_id: Maybe<GqlLossReasonGql>;
  processing_loss_types: Maybe<GqlProcessingLossTypeGqlConnection>;
  processing_loss_type_by_id: Maybe<GqlProcessingLossTypeGql>;
  inventory_by_product_item: Maybe<GqlInventoryGql>;
  stock_intake_weight_by_batch_id: Maybe<Scalars['Float']>;
  processing_loss_weight_by_batch_id: Maybe<Scalars['Float']>;
  distribution_centers: Maybe<Array<Maybe<GqlDistributionCenterGql>>>;
  distribution_centers_v2: Maybe<GqlDistributionCenterGqlConnection>;
  distribution_center_by_id: Maybe<GqlDistributionCenterGql>;
  distribution_centers_by_ids: Maybe<Array<Maybe<GqlDistributionCenterGql>>>;
  stock_take: Maybe<GqlStockTakeGqlConnection>;
  stock_take_by_id: Maybe<GqlStockTakeGql>;
  stock_take_location: Maybe<GqlStockTakeLocationGqlConnection>;
  stock_take_location_by_id: Maybe<GqlStockTakeLocationGql>;
  warehouse_transfer_item: Maybe<GqlWarehouseTransferItemGqlConnection>;
  warehouse_transfer_item_by_id: Maybe<GqlWarehouseTransferItemGql>;
  warehouse_transfer_reason: Maybe<GqlWarehouseTransferReasonGqlConnection>;
  warehouse_transfer: Maybe<GqlWarehouseTransferGqlConnection>;
  warehouse_transfer_by_id: Maybe<GqlWarehouseTransferGql>;
  assets: Maybe<GqlAssetGqlConnection>;
  asset_by_id: Maybe<GqlAssetGql>;
  asset_types: Maybe<Array<Maybe<GqlAssetTypeGql>>>;
  asset_photos: Maybe<GqlAssetPhotoGqlConnection>;
  asset_photo_by_id: Maybe<GqlAssetPhotoGql>;
  damaged_asset_photos: Maybe<GqlDamagedAssetPhotoGqlConnection>;
  damaged_asset_photo_by_id: Maybe<GqlDamagedAssetPhotoGql>;
  asset_status: Maybe<Array<GqlAssetStatus>>;
  asset_trackers: Maybe<GqlAssetTrackerGqlConnection>;
  asset_tracker_by_id: Maybe<GqlAssetTrackerGql>;
  asset_tracker_items: Maybe<GqlAssetTrackerItemGqlConnection>;
  asset_tracker_item_by_id: Maybe<GqlAssetTrackerItemGql>;
  inventory_locations: Maybe<GqlInventoryLocationGqlConnection>;
  inventory_location_by_id: Maybe<GqlInventoryLocationGql>;
  inventory_location_type: Maybe<Array<Maybe<Scalars['String']>>>;
  asset_controls: Maybe<GqlAssetControlGqlConnection>;
  asset_control_by_id: Maybe<GqlAssetControlGql>;
  appointment_types: Maybe<Array<GqlAppointmentType>>;
  stock_availability_status: Maybe<Array<Maybe<GqlStockAvailabilityStatus>>>;
  stock_intake_status: Maybe<Array<Maybe<GqlStockIntakeStatus>>>;
  stock_intake_type: Maybe<Array<GqlStockIntakeType>>;
  stock_intake_item_status: Maybe<Array<Maybe<GqlStockIntakeItemStatus>>>;
  product_zone_types: Maybe<Array<Maybe<Scalars['String']>>>;
  product_locations: Maybe<GqlProductLocationGqlConnection>;
  product_locations_unpaginated: Maybe<Array<Maybe<GqlProductLocationGql>>>;
  product_expiry: Maybe<GqlProductExpiryGqlConnection>;
  product_expiry_unpaginated: Maybe<Array<Maybe<GqlProductExpiryGql>>>;
  product_transfer_reasons: Maybe<GqlProductTransferReasonGqlConnection>;
  product_transfer_reason_by_id: Maybe<GqlProductTransferReasonGql>;
  product_transfers: Maybe<GqlProductTransferGqlConnection>;
  product_transfer_by_id: Maybe<GqlProductTransferGql>;
  product_transfer_type: Maybe<Array<GqlProductTransferType>>;
  product_transfer_handling_units: Maybe<GqlProductTransferHandlingUnitGqlConnection>;
  product_transfer_handling_unit_by_id: Maybe<GqlProductTransferHandlingUnitGql>;
  product_transfer_handling_unit_items: Maybe<GqlProductTransferHandlingUnitItemGqlConnection>;
  product_transfer_handling_unit_item_by_id: Maybe<GqlProductTransferHandlingUnitItemGql>;
  LeadCallbackConnection: Maybe<GqlLeadCallbackConnection>;
  OrderCallbackConnection: Maybe<GqlOrderCallbackConnection>;
  LoanOfferConnection: Maybe<GqlLoanOfferConnection>;
  LoanRequestConnection: Maybe<GqlLoanRequestConnection>;
  CustomerPartnerConnection: Maybe<GqlCustomerPartnerConnection>;
  CustomerConnection: Maybe<GqlCustomerConnection>;
  PartnerConnection: Maybe<GqlPartnerConnection>;
  PartnerTopupConnection: Maybe<GqlPartnerTopupConnection>;
  PartnerUpdateConnection: Maybe<GqlPartnerUpdateConnection>;
  PartnerEarningConnection: Maybe<GqlPartnerEarningConnection>;
  LoanProductConnection: Maybe<GqlLoanProductConnection>;
  DepotPartnerConnection: Maybe<GqlDepotPartnerConnection>;
  LoanConnection: Maybe<GqlLoanConnection>;
  LoanUpdateConnection: Maybe<GqlLoanUpdateConnection>;
  LoanRepaymentConnection: Maybe<GqlLoanRepaymentConnection>;
  LoanRejectionReason: Array<Maybe<GqlLoanRejectionReason>>;
  LoanWaiverReason: Array<Maybe<GqlLoanWaiverReason>>;
  sokoloan_repayments: Maybe<GqlSokoLoanRepaymentGqlConnection>;
  sokoloan_repayment_by_id: Maybe<GqlSokoLoanRepaymentGql>;
  lead_callbacks: Maybe<GqlLeadCallbackGqlConnection>;
  lead_callback_by_id: Maybe<GqlLeadCallbackGql>;
  fintech_loans: Maybe<GqlLoanGqlConnection>;
  fintech_loans_by_customer_id: Maybe<GqlLoanGqlConnection>;
  fintech_loan_by_id: Maybe<GqlLoanGql>;
  loan_offers: Maybe<GqlLoanOfferGqlConnection>;
  loan_offer_by_delivery_id: Maybe<GqlLoanOfferGql>;
  loan_offer_by_id: Maybe<GqlLoanOfferGql>;
  loan_requests: Maybe<GqlLoanRequestGqlConnection>;
  loan_request_by_id: Maybe<GqlLoanRequestGql>;
  partner_loan_query_by_customer_id: Maybe<Array<Maybe<GqlPartnerLoanQueryGql>>>;
  partner_member_query_by_customer_id: Maybe<Array<Maybe<GqlPartnerMemberQueryGql>>>;
  fintech_deliveries: Maybe<GqlFintechDeliveryGqlConnection>;
  fintech_deliveries_by_customer_id: Maybe<GqlFintechDeliveryGqlConnection>;
  fintech_customers: Maybe<GqlFintechCustomerGqlConnection>;
  fintech_customer_by_id: Maybe<GqlFintechCustomerGql>;
  fintech_partners: Maybe<Array<Maybe<GqlPartnerGql>>>;
  fintech_partner_by_id: Maybe<GqlPartnerGql>;
  paginated_product_item_bundles: Maybe<GqlProductItemBundleGqlConnection>;
  product_item_bundle_by_id: Maybe<GqlProductItemBundleGql>;
  product_item_bundle_by_ids: Maybe<Array<Maybe<GqlProductItemBundleGql>>>;
  paginated_product_items_v2: Maybe<GqlProductItemV2GqlConnection>;
  product_items_v2_by_ids: Maybe<Array<Maybe<GqlProductItemV2Gql>>>;
  product_item_v2_by_id: Maybe<GqlProductItemV2Gql>;
  product_items_v2: Maybe<Array<Maybe<GqlProductItemV2Gql>>>;
  product_item_segments_v2: Maybe<Array<Maybe<GqlProductItemSegmentV2Gql>>>;
  product_item_segment_v2_by_id: Maybe<GqlProductItemSegmentV2Gql>;
  bundle_association_v2_by_sku: Maybe<Array<Maybe<GqlBundleAssociationV2Gql>>>;
  product_promotional_banners_v2: Maybe<Array<Maybe<GqlProductPromotionalBannerV2Gql>>>;
  product_promotional_banner_v2_by_id: Maybe<GqlProductPromotionalBannerV2Gql>;
  product_items_with_tags: Maybe<Array<Maybe<GqlPromotionTagGql>>>;
  promotion_tag_by_id: Maybe<GqlPromotionTagGql>;
  promotion_types: Maybe<Array<Maybe<GqlPromotionTypeGql>>>;
  promotion_type_by_id: Maybe<GqlPromotionTypeGql>;
  product_categories_v2: Maybe<Array<Maybe<GqlCategoryV2Gql>>>;
  product_category_v2_by_id: Maybe<GqlCategoryV2Gql>;
  product_categories_v2_by_department_id: Maybe<Array<Maybe<GqlCategoryV2Gql>>>;
  product_departments_v2: Maybe<Array<Maybe<GqlDepartmentV2Gql>>>;
  product_department_v2_by_id: Maybe<GqlDepartmentV2Gql>;
  uoms_v2: Maybe<GqlUomv2GqlConnection>;
  uom_v2_by_id: Maybe<GqlUomv2Gql>;
  business_domains_v2: Maybe<Array<Maybe<GqlBusinessDomainV2Gql>>>;
  uom_v2_by_ids: Maybe<Array<Maybe<GqlUomv2Gql>>>;
  product_item_uoms_v2: Maybe<GqlProductItemUomv2GqlConnection>;
  product_item_uom_v2_by_id: Maybe<GqlProductItemUomv2Gql>;
  product_item_uom_v2_by_ids: Maybe<Array<Maybe<GqlProductItemUomv2Gql>>>;
  paginated_products_v2: Maybe<GqlProductV2GqlConnection>;
  product_v2_by_id: Maybe<GqlProductV2Gql>;
  product_v2_by_ids: Maybe<Array<Maybe<GqlProductV2Gql>>>;
  packaging_units_v2: Maybe<GqlPackagingUnitV2GqlConnection>;
  packaging_unit_v2_by_id: Maybe<GqlPackagingUnitV2Gql>;
  packaging_unit_v2_by_ids: Maybe<Array<Maybe<GqlPackagingUnitV2Gql>>>;
  product_types_v2: Maybe<Array<Maybe<Scalars['String']>>>;
  finance_product_types: Maybe<Array<Maybe<GqlFinanceProductTypeGql>>>;
  finance_product_type_by_id: Maybe<GqlFinanceProductTypeGql>;
  standard_unit_costs: Maybe<Array<Maybe<GqlStandardUnitCostGql>>>;
  standard_unit_cost_by_id: Maybe<GqlStandardUnitCostGql>;
  standard_unit_cost_by_product_id: Maybe<GqlStandardUnitCostGql>;
  product_varieties: Maybe<GqlProductVarietyGqlConnection>;
  endpoints: Array<GqlEndpoint>;
};


export type GqlQueryInboxArgs = {
  userID: Scalars['String'];
  limit: Scalars['Int'];
  after: Maybe<Scalars['String']>;
};


export type GqlQueryETiMsCodesArgs = {
  filters: Maybe<GqlEtiMsCodeFilters>;
};


export type GqlQueryETiMsClassCodesArgs = {
  filters: Maybe<GqlEtiMsClassCodesFilters>;
};


export type GqlQueryETiMsNoticeListArgs = {
  filters: Maybe<GqlNoticeFilters>;
};


export type GqlQueryETiMsStockMovementListArgs = {
  filters: Maybe<GqlStockMovementFilters>;
};


export type GqlQueryETiMsItemsArgs = {
  filters: Maybe<GqlItemsFilters>;
};


export type GqlQueryETiMsPurchasesArgs = {
  filters: Maybe<GqlPurchasesFilters>;
};


export type GqlQueryPermissions_V2Args = {
  search: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
};


export type GqlQueryPermission_V2_By_IdArgs = {
  permission_id: Maybe<Scalars['String']>;
};


export type GqlQueryUsers_V2Args = {
  search: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
};


export type GqlQueryUser_V2_By_IdArgs = {
  user_id: Maybe<Scalars['String']>;
};


export type GqlQueryUsers_V2_By_PermissionsArgs = {
  permissions: Maybe<Array<Maybe<Scalars['String']>>>;
  search: Maybe<Scalars['String']>;
};


export type GqlQueryUser_V2_By_Id_Or_NumberArgs = {
  user_id_or_number: Maybe<Scalars['String']>;
};


export type GqlQueryUsers_V2_By_IdsArgs = {
  user_ids: Array<Maybe<Scalars['String']>>;
};


export type GqlQueryRoles_V2Args = {
  search: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
};


export type GqlQueryRole_V2_By_IdArgs = {
  role_id: Maybe<Scalars['String']>;
};


export type GqlQueryAutoReconConfigArgs = {
  id: Scalars['UUID'];
};


export type GqlQueryAutoReconConfigsArgs = {
  first?: Scalars['Int'];
  after?: Scalars['String'];
  filters: Maybe<GqlAutoreconConfigFilters>;
};


export type GqlQueryPaymentClaimArgs = {
  id: Scalars['UUID'];
};


export type GqlQueryPaymentClaimsArgs = {
  first?: Scalars['Int'];
  after?: Scalars['String'];
  filters: Maybe<GqlPaymentClaimFilters>;
};


export type GqlQueryAutoreconLookupArgs = {
  input: GqlAutoreconLookupInput;
};


export type GqlQueryPaymentPartyArgs = {
  id: Scalars['UUID'];
};


export type GqlQueryPaymentPartiesArgs = {
  first?: Scalars['Int'];
  after?: Scalars['String'];
  filters: Maybe<GqlPartyFilters>;
};


export type GqlQueryPartyProviderModesArgs = {
  first?: Scalars['Int'];
  after?: Scalars['String'];
  filters: Maybe<GqlPartyProviderModeFilters>;
};


export type GqlQueryPaymentArgs = {
  id: Scalars['UUID'];
};


export type GqlQueryPaymentsArgs = {
  first?: Scalars['Int'];
  after?: Scalars['String'];
  filters: Maybe<GqlPaymentFilters>;
};


export type GqlQueryPaymentConfigArgs = {
  id: Scalars['UUID'];
};


export type GqlQueryPaymentConfigsArgs = {
  first?: Scalars['Int'];
  after?: Scalars['String'];
  filters: Maybe<GqlPaymentConfigFilters>;
};


export type GqlQueryPaymentProviderArgs = {
  id: Scalars['UUID'];
};


export type GqlQueryWork_OrdersArgs = {
  cluster_ids: Maybe<Array<Maybe<Scalars['String']>>>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryWork_Order_By_IdArgs = {
  work_order_id: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryWork_Order_Definition_By_IdArgs = {
  definition_id: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryWork_Order_ItemsArgs = {
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryWork_Order_Item_By_IdArgs = {
  work_order_item_id: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryWork_Order_Reason_By_IdArgs = {
  reason_id: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryClustersArgs = {
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryCluster_By_IdArgs = {
  cluster_id: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryCluster_AssignmentsArgs = {
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryCluster_Assignment_By_IdArgs = {
  assignment_id: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryCluster_Assignments_By_User_IdArgs = {
  user_id: Maybe<Scalars['String']>;
  date: Maybe<Scalars['String']>;
};


export type GqlQueryCluster_Agent_EarningsArgs = {
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryCluster_Agent_Earning_By_IdArgs = {
  agent_earning_id: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryCluster_Earning_SummaryArgs = {
  delivery_date: Maybe<Scalars['String']>;
  start_date: Maybe<Scalars['String']>;
  end_date: Maybe<Scalars['String']>;
  agent_id: Scalars['String'];
};


export type GqlQueryAgent_Daily_Order_SummaryArgs = {
  agent_id: Scalars['String'];
  delivery_date: Scalars['String'];
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQuerySegment_Earnings_SummaryArgs = {
  start_date: Scalars['String'];
  end_date: Scalars['String'];
  agent_id: Scalars['String'];
};


export type GqlQueryAgent_EarningsArgs = {
  start_date: Scalars['String'];
  end_date: Scalars['String'];
  agent_id: Scalars['String'];
};


export type GqlQueryAgent_Earnings_SummaryArgs = {
  start_date: Scalars['String'];
  end_date: Scalars['String'];
  agent_id: Scalars['String'];
};


export type GqlQueryDelivery_ReturnsArgs = {
  search: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
  since: Maybe<Scalars['String']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
};


export type GqlQueryDelivery_Return_By_IdArgs = {
  return_id: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryUnpaginated_Delivery_ReturnsArgs = {
  start_date: Maybe<Scalars['String']>;
  end_date: Maybe<Scalars['String']>;
};


export type GqlQueryDelivery_Return_ItemsArgs = {
  filters: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
  since: Maybe<Scalars['String']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  search: Maybe<Scalars['String']>;
};


export type GqlQueryDelivery_Return_Item_By_IdArgs = {
  return_item_id: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryOrder_ReturnsArgs = {
  search: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
  since: Maybe<Scalars['String']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
};


export type GqlQueryOrder_Return_By_IdArgs = {
  return_id: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryOrder_Return_ItemsArgs = {
  filters: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
  since: Maybe<Scalars['String']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  search: Maybe<Scalars['String']>;
};


export type GqlQueryOrder_Return_Item_By_IdArgs = {
  return_item_id: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQuerySales_Return_ReasonsArgs = {
  filters: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
};


export type GqlQuerySales_Return_Reason_By_IdArgs = {
  reason_id: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryReturn_Item_Rejection_ReasonsArgs = {
  filters: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
};


export type GqlQueryReturn_Item_Rejection_Reason_By_IdArgs = {
  rejection_reason_id: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryMcommerce_Account_By_Customer_IdArgs = {
  customer_id: Scalars['String'];
};


export type GqlQueryStock_NotificationsArgs = {
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryStock_Notification_By_IdArgs = {
  stock_notification_id: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryOrdering_WindowsArgs = {
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryOrdering_Window_By_IdArgs = {
  ordering_window_id: Scalars['String'];
  slug: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryOrdering_Window_ItemsArgs = {
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryOrdering_Window_Item_By_IdArgs = {
  ordering_window_item_id: Scalars['String'];
  slug: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryMcommerce_Faq_Section_By_IdArgs = {
  faq_section_id: Scalars['String'];
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryMcommerce_FaqsArgs = {
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryMcommerce_Faq_By_IdArgs = {
  faq_id: Scalars['String'];
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryNext_Of_KinsArgs = {
  filters: Maybe<Scalars['GenericScalar']>;
};


export type GqlQueryCustomer_PoliciesArgs = {
  filters: Maybe<Scalars['GenericScalar']>;
};


export type GqlQueryCover_TypesArgs = {
  insurer_id: Scalars['String'];
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryInsurersArgs = {
  filters: Maybe<Scalars['GenericScalar']>;
};


export type GqlQueryPayment_Accounts_V2Args = {
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryPayment_Account_V2_By_IdArgs = {
  payment_account_id: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryRoute_Payment_Accounts_V2Args = {
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryRoute_Payment_Account_V2_By_IdArgs = {
  route_payment_account_id: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryRoute_Payment_Accounts_V2_By_Route_IdArgs = {
  route_id: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryPayments_V2Args = {
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryPayment_V2_By_IdArgs = {
  payment_id: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryPayment_VisibilitiesArgs = {
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryPayment_Visibility_By_IdArgs = {
  payment_visibility_id: Scalars['String'];
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryPayment_MethodsArgs = {
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryPayment_Method_By_IdArgs = {
  payment_method_id: Scalars['String'];
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryRoute_Till_Alert_ContactsArgs = {
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryCredited_Delivery_Fee_CreditsArgs = {
  shop_id: Scalars['String'];
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryVendor_Payments_SummaryArgs = {
  start_date: Maybe<Scalars['String']>;
  end_date: Maybe<Scalars['String']>;
  depot_ids: Maybe<Array<Maybe<Scalars['String']>>>;
  route_plan_ids: Maybe<Array<Maybe<Scalars['String']>>>;
  shop_ids: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type GqlQueryVendor_PaymentsArgs = {
  start_date: Maybe<Scalars['String']>;
  end_date: Maybe<Scalars['String']>;
  depot_ids: Maybe<Array<Maybe<Scalars['String']>>>;
  route_plan_ids: Maybe<Array<Maybe<Scalars['String']>>>;
  shop_ids: Maybe<Array<Maybe<Scalars['String']>>>;
  payment_modes: Maybe<Array<Maybe<Scalars['String']>>>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryUnpaginated_Vendor_PaymentsArgs = {
  start_date: Maybe<Scalars['String']>;
  end_date: Maybe<Scalars['String']>;
  depot_ids: Maybe<Array<Maybe<Scalars['String']>>>;
  route_plan_ids: Maybe<Array<Maybe<Scalars['String']>>>;
  shop_ids: Maybe<Array<Maybe<Scalars['String']>>>;
  payment_modes: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type GqlQueryDeliveries_V2Args = {
  cluster_ids: Maybe<Array<Maybe<Scalars['String']>>>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryDelivery_V2_By_IdArgs = {
  delivery_id: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryDelivery_Fee_SchedulesArgs = {
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryDelivery_Fee_Schedule_By_IdArgs = {
  schedule_id: Scalars['String'];
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryRealized_Revenue_V2Args = {
  route_id: Maybe<Scalars['String']>;
  date: Maybe<Scalars['String']>;
};


export type GqlQueryUnpaginated_DeliveriesArgs = {
  start_date: Maybe<Scalars['String']>;
  end_date: Maybe<Scalars['String']>;
};


export type GqlQueryDelivery_Items_V2Args = {
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryDelivery_Item_V2_By_IdArgs = {
  delivery_item_id: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryShop_Delivery_Summary_V2Args = {
  filters: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
};


export type GqlQueryDelivery_Product_Summary_V2Args = {
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQuerySales_Per_ProductArgs = {
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQuerySales_Per_Product_UnpaginatedArgs = {
  start_date: Maybe<Scalars['String']>;
  end_date: Maybe<Scalars['String']>;
  depot_id: Maybe<Scalars['String']>;
  route_id: Maybe<Scalars['String']>;
  delivery_id: Maybe<Scalars['String']>;
  product_id: Maybe<Scalars['String']>;
  product_item_id: Maybe<Scalars['String']>;
  category_id: Maybe<Scalars['String']>;
  product_segment_id: Maybe<Scalars['String']>;
};


export type GqlQueryDaily_OtifArgs = {
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryDaily_Otif_SummaryArgs = {
  start_date: Maybe<Scalars['String']>;
  end_date: Maybe<Scalars['String']>;
  region_id: Maybe<Scalars['String']>;
  depot_id: Maybe<Scalars['String']>;
  route_id: Maybe<Scalars['String']>;
  product_id: Maybe<Scalars['String']>;
  product_item_id: Maybe<Scalars['String']>;
  payment_mode: Maybe<Scalars['String']>;
  category_id: Maybe<Scalars['String']>;
  product_segment_id: Maybe<Scalars['String']>;
  on_time: Maybe<Scalars['String']>;
};


export type GqlQueryDaily_Otif_UnpaginatedArgs = {
  start_date: Maybe<Scalars['String']>;
  end_date: Maybe<Scalars['String']>;
  region_id: Maybe<Scalars['String']>;
  depot_id: Maybe<Scalars['String']>;
  route_id: Maybe<Scalars['String']>;
  product_id: Maybe<Scalars['String']>;
  product_item_id: Maybe<Scalars['String']>;
  payment_mode: Maybe<Scalars['String']>;
  category_id: Maybe<Scalars['String']>;
  product_segment_id: Maybe<Scalars['String']>;
  on_time: Maybe<Scalars['String']>;
};


export type GqlQueryDelivery_Item_WarrantiesArgs = {
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryDelivery_Item_Warranty_By_IdArgs = {
  warranty_id: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryTrader_Entities_V2Args = {
  search: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
  since: Maybe<Scalars['String']>;
};


export type GqlQueryTrader_Entity_V2_By_IdArgs = {
  trader_entity_id: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryOrders_V2Args = {
  sort_order: Maybe<Scalars['Int']>;
  cluster_ids: Maybe<Array<Maybe<Scalars['String']>>>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryOrder_V2_By_IdArgs = {
  order_id: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryCurrent_Orders_V2Args = {
  shop_id: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryUnpaginated_OrdersArgs = {
  start_date: Maybe<Scalars['String']>;
  end_date: Maybe<Scalars['String']>;
};


export type GqlQueryOrder_Items_V2Args = {
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryOrder_Item_V2_By_IdArgs = {
  order_item_id: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryOrder_AgainArgs = {
  shop_id: Scalars['String'];
};


export type GqlQueryOrder_Assignments_V2Args = {
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryOrder_Assignment_V2_By_IdArgs = {
  order_assignment_id: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryAll_Stock_Ordered_Depot_SummaryArgs = {
  depot_name: Maybe<Scalars['String']>;
  delivery_date: Scalars['String'];
  time_window: Scalars['String'];
};


export type GqlQueryStock_Ordered_Depot_SummaryArgs = {
  depot_id: Scalars['String'];
  product_item_id: Scalars['String'];
  delivery_date: Scalars['String'];
};


export type GqlQueryMultiple_Product_Items_Stock_Ordered_Depot_SummaryArgs = {
  args_list: Array<Maybe<GqlMultipleStockOrderedArgs>>;
};


export type GqlQueryStock_Ordered_By_Product_ItemArgs = {
  product_item_id: Maybe<Scalars['String']>;
  distribution_center_ids: Array<Maybe<Scalars['String']>>;
  sale_date: Maybe<Scalars['String']>;
  depot_id: Scalars['String'];
};


export type GqlQueryMultiple_Product_Items_Stock_OrderedArgs = {
  args_list: Array<Maybe<GqlMultipleStockOrderedArgs>>;
};


export type GqlQueryShop_Order_Summary_V2Args = {
  filters: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
};


export type GqlQueryOrder_Product_Summary_V2Args = {
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryProjected_Revenue_V2Args = {
  route_id: Maybe<Scalars['String']>;
  sale_date: Maybe<Scalars['String']>;
};


export type GqlQueryRoute_Plan_Order_SummaryArgs = {
  route_plan_id: Maybe<Scalars['String']>;
};


export type GqlQueryRoute_Plan_Order_Summaries_By_Route_Plan_IdsArgs = {
  route_plan_ids: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type GqlQueryDelivery_Vehicle_Manifest_SummaryArgs = {
  start_date: Scalars['String'];
  end_date: Scalars['String'];
  depot_ids: Maybe<Array<Maybe<Scalars['String']>>>;
  product_types: Maybe<Array<Maybe<Scalars['String']>>>;
  route_plan_ids: Maybe<Array<Maybe<Scalars['String']>>>;
  product_ids: Maybe<Array<Maybe<Scalars['String']>>>;
  product_item_ids: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type GqlQueryOrdered_Stock_AllocationArgs = {
  start_date: Scalars['String'];
  end_date: Scalars['String'];
  depot_ids: Maybe<Array<Maybe<Scalars['String']>>>;
  product_types: Maybe<Array<Maybe<Scalars['String']>>>;
  route_plan_ids: Maybe<Array<Maybe<Scalars['String']>>>;
  product_ids: Maybe<Array<Maybe<Scalars['String']>>>;
  product_item_ids: Maybe<Array<Maybe<Scalars['String']>>>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryUnpaginated_Ordered_Stock_AllocationArgs = {
  start_date: Scalars['String'];
  end_date: Scalars['String'];
  depot_ids: Maybe<Array<Maybe<Scalars['String']>>>;
  product_types: Maybe<Array<Maybe<Scalars['String']>>>;
  route_plan_ids: Maybe<Array<Maybe<Scalars['String']>>>;
  product_ids: Maybe<Array<Maybe<Scalars['String']>>>;
  product_item_ids: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type GqlQueryOrder_RestrictionsArgs = {
  cluster_id: Scalars['String'];
  depot_id: Scalars['String'];
  route_id: Scalars['String'];
  customer_id: Scalars['String'];
  shop_id: Scalars['String'];
  shop_type_id: Scalars['String'];
};


export type GqlQueryDepot_Dc_Product_Item_RestrictionsArgs = {
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryDistribution_Center_Orders_SummaryArgs = {
  distribution_center_id: Maybe<Scalars['String']>;
  route_ids: Maybe<Array<Maybe<Scalars['String']>>>;
  route_plan_ids: Maybe<Array<Maybe<Scalars['String']>>>;
  sale_date: Scalars['String'];
};


export type GqlQueryDelivery_SchedulesArgs = {
  filters: Maybe<Scalars['GenericScalar']>;
};


export type GqlQueryDelivery_Schedule_By_IdArgs = {
  schedule_id: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryDelivery_Processing_FeesArgs = {
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryDelivery_Processing_Fee_By_IdArgs = {
  processing_fee_id: Scalars['String'];
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryShop_Delivery_Processing_FeesArgs = {
  shop_id: Scalars['String'];
  cluster_id: Scalars['String'];
  route_id: Scalars['String'];
  order_amount: Scalars['Float'];
  delivery_date: Maybe<Scalars['String']>;
};


export type GqlQueryRegions_V2Args = {
  search: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
  since: Maybe<Scalars['String']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
};


export type GqlQueryRegion_V2_By_IdArgs = {
  region_id: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryCountry_By_IdArgs = {
  country_id: Maybe<Scalars['String']>;
};


export type GqlQueryCountries_By_IdsArgs = {
  country_ids: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type GqlQueryRegions_V2_By_IdsArgs = {
  region_ids: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type GqlQueryAreas_V2Args = {
  search: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
  since: Maybe<Scalars['String']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
};


export type GqlQueryArea_V2_By_IdArgs = {
  area_id: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryDepots_V2Args = {
  search: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
  since: Maybe<Scalars['String']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
};


export type GqlQueryDepot_V2_By_IdArgs = {
  depot_id: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryDepots_V2_By_IdsArgs = {
  depot_ids: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type GqlQueryRoutes_V2Args = {
  search: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
  since: Maybe<Scalars['String']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
};


export type GqlQueryRoute_V2_By_IdArgs = {
  route_id: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryRoutes_V2_By_IdsArgs = {
  route_ids: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type GqlQueryRoute_Teams_V2Args = {
  search: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
  since: Maybe<Scalars['String']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
};


export type GqlQueryRoute_Team_V2_By_IdArgs = {
  route_team_id: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryRoute_Team_V2_By_User_IdArgs = {
  user_id: Maybe<Scalars['String']>;
  sale_date: Maybe<Scalars['String']>;
};


export type GqlQueryRoute_Team_Members_V2Args = {
  search: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
  since: Maybe<Scalars['String']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
};


export type GqlQueryRoute_Team_Member_V2_By_IdArgs = {
  route_team_member_id: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryCountriesArgs = {
  filters: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryDepot_Routing_SummaryArgs = {
  search: Maybe<Scalars['String']>;
  sale_date: Maybe<Scalars['String']>;
  depot_id: Maybe<Scalars['String']>;
  per_page: Maybe<Scalars['Int']>;
  page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryCustomers_V2Args = {
  search: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
  since: Maybe<Scalars['String']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
};


export type GqlQueryCustomer_V2_By_IdArgs = {
  customer_id: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryPhone_Number_Change_ApprovalsArgs = {
  filters: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
};


export type GqlQueryShops_V2Args = {
  search: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
  cordinates: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  business_domain: Maybe<Scalars['String']>;
  cluster_ids: Maybe<Array<Maybe<Scalars['String']>>>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
};


export type GqlQueryShops_V2_By_Customer_IdArgs = {
  customer_id: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryShop_V2_By_IdArgs = {
  shop_id: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryShop_Types_V2Args = {
  active: Maybe<Scalars['Boolean']>;
  since: Maybe<Scalars['String']>;
  grouped: Maybe<Scalars['Boolean']>;
  search: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
};


export type GqlQueryShop_Type_V2_By_IdArgs = {
  shop_type_id: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryCustomer_Types_V2Args = {
  active: Maybe<Scalars['Boolean']>;
  since: Maybe<Scalars['String']>;
};


export type GqlQueryVendor_KycArgs = {
  active: Maybe<Scalars['Boolean']>;
  since: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
};


export type GqlQueryVendor_Kyc_By_IdArgs = {
  vendor_kyc_id: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryCustomer_Type_V2_By_IdArgs = {
  customer_type_id: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryCustomer_V2_By_Phone_NumberArgs = {
  phone_number: Maybe<Scalars['String']>;
};


export type GqlQueryVendor_Finance_PlansArgs = {
  active: Maybe<Scalars['Boolean']>;
  since: Maybe<Scalars['String']>;
};


export type GqlQueryDelivery_Fee_By_Shop_IdArgs = {
  shop_id: Scalars['String'];
};


export type GqlQueryShops_V2_By_IdsArgs = {
  shop_ids: Array<Maybe<Scalars['String']>>;
};


export type GqlQueryChecklist_SectionsArgs = {
  search: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
};


export type GqlQueryChecklist_Section_By_IdArgs = {
  section_id: Maybe<Scalars['String']>;
};


export type GqlQueryChecklist_QuestionsArgs = {
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryChecklist_Question_By_IdArgs = {
  question_id: Maybe<Scalars['String']>;
};


export type GqlQueryChecklist_EntriesArgs = {
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryChecklist_Entry_By_IdArgs = {
  entry_id: Maybe<Scalars['String']>;
};


export type GqlQueryIncident_CategoriesArgs = {
  active: Maybe<Scalars['Boolean']>;
  filters: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
};


export type GqlQueryIncidentsArgs = {
  active: Maybe<Scalars['Boolean']>;
  filters: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
};


export type GqlQueryIncident_By_IdArgs = {
  incident_id: Maybe<Scalars['String']>;
};


export type GqlQueryVehicle_SuppliersArgs = {
  search: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  since: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryVehicle_Supplier_Payment_AccountsArgs = {
  search: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  since: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryTripsArgs = {
  search: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  since: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryVehicle_Supplier_Statement_FilesArgs = {
  search: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  since: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryVehicle_Supplier_StatementsArgs = {
  search: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  since: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryVehicle_TypesArgs = {
  search: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  since: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryVehicle_Type_By_IdArgs = {
  vehicle_type_id: Maybe<Scalars['String']>;
};


export type GqlQueryVehiclesArgs = {
  search: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
  available_on_delivery_date: Maybe<Scalars['Date']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  since: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryVehicle_By_IdArgs = {
  vehicle_id: Maybe<Scalars['String']>;
};


export type GqlQueryRoute_PlansArgs = {
  filters: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryRoute_Plan_By_IdArgs = {
  route_plan_id: Maybe<Scalars['String']>;
};


export type GqlQueryRoute_Plans_By_IdsArgs = {
  route_plan_ids: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type GqlQueryUnpaginated_Route_PlansArgs = {
  delivery_date: Scalars['String'];
  depot_id: Maybe<Scalars['String']>;
};


export type GqlQueryFulfillment_TeamsArgs = {
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryFulfillment_Team_By_IdArgs = {
  fulfillment_team_id: Maybe<Scalars['String']>;
};


export type GqlQueryStatic_RoutesArgs = {
  filters: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  since: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryStatic_Route_By_IdArgs = {
  static_route_id: Maybe<Scalars['String']>;
};


export type GqlQueryDepot_Route_Plan_SummaryArgs = {
  depot_id: Maybe<Scalars['String']>;
  delivery_date: Maybe<Scalars['String']>;
};


export type GqlQueryPseudo_RoutesArgs = {
  filters: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryPseudo_Route_By_IdArgs = {
  pseudo_route_id: Scalars['String'];
};


export type GqlQueryPseudo_Routes_Vehicle_AssignmentArgs = {
  delivery_date: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  depot_ids: Maybe<Array<Maybe<Scalars['String']>>>;
  shops_vs_vehicles: Maybe<Scalars['String']>;
  ordered_weight_vs_capacity: Maybe<Scalars['String']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryUnpaginated_Pseudo_Routes_Vehicle_AssignmentArgs = {
  delivery_date: Maybe<Scalars['String']>;
  depot_ids: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type GqlQueryNodeArgs = {
  id: Scalars['ID'];
};


export type GqlQueryDispatchesArgs = {
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter: Maybe<Scalars['GenericScalar']>;
  filter_array: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
  return_all: Maybe<Scalars['Boolean']>;
  since: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
  search: Maybe<Scalars['String']>;
};


export type GqlQueryDispatchArgs = {
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter: Maybe<Scalars['GenericScalar']>;
  filter_array: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
  return_all: Maybe<Scalars['Boolean']>;
  since: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
  search: Maybe<Scalars['String']>;
};


export type GqlQueryDispatch_By_IdArgs = {
  dispatch_id: Maybe<Scalars['String']>;
};


export type GqlQueryDispatch_By_Route_IdArgs = {
  route_id: Maybe<Scalars['String']>;
};


export type GqlQueryDispatch_Item_By_IdArgs = {
  dispatch_item_id: Maybe<Scalars['String']>;
};


export type GqlQueryDispatch_ItemsArgs = {
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter: Maybe<Scalars['GenericScalar']>;
  filter_array: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
  return_all: Maybe<Scalars['Boolean']>;
  since: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
  search: Maybe<Scalars['String']>;
};


export type GqlQueryStock_TransfersArgs = {
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter: Maybe<Scalars['GenericScalar']>;
  filter_array: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
  return_all: Maybe<Scalars['Boolean']>;
  since: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
  search: Maybe<Scalars['String']>;
};


export type GqlQueryStock_TransferArgs = {
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter: Maybe<Scalars['GenericScalar']>;
  filter_array: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
  return_all: Maybe<Scalars['Boolean']>;
  since: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
  search: Maybe<Scalars['String']>;
};


export type GqlQueryStock_Transfer_By_IdArgs = {
  stock_transfer_id: Maybe<Scalars['String']>;
};


export type GqlQueryStock_Transfer_Item_By_IdArgs = {
  stock_transfer_item: Maybe<Scalars['String']>;
};


export type GqlQueryStock_Transfer_ItemsArgs = {
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter: Maybe<Scalars['GenericScalar']>;
  filter_array: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
  return_all: Maybe<Scalars['Boolean']>;
  since: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
  search: Maybe<Scalars['String']>;
};


export type GqlQueryDispatch_BalanceArgs = {
  route_id: Maybe<Scalars['String']>;
  date: Maybe<Scalars['String']>;
  product_item_id: Maybe<Scalars['String']>;
};


export type GqlQueryDepot_IntakesArgs = {
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter: Maybe<Scalars['GenericScalar']>;
  filter_array: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
  return_all: Maybe<Scalars['Boolean']>;
  since: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
  search: Maybe<Scalars['String']>;
};


export type GqlQueryDepot_IntakeArgs = {
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter: Maybe<Scalars['GenericScalar']>;
  filter_array: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
  return_all: Maybe<Scalars['Boolean']>;
  since: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
  search: Maybe<Scalars['String']>;
};


export type GqlQueryDepot_Intake_By_IdArgs = {
  depot_intake_id: Maybe<Scalars['String']>;
};


export type GqlQueryDepot_Intake_Item_By_IdArgs = {
  depot_intake_item_id: Maybe<Scalars['String']>;
};


export type GqlQueryDepot_Intake_ItemsArgs = {
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter: Maybe<Scalars['GenericScalar']>;
  filter_array: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
  return_all: Maybe<Scalars['Boolean']>;
  since: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
  search: Maybe<Scalars['String']>;
};


export type GqlQueryReturnsArgs = {
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter: Maybe<Scalars['GenericScalar']>;
  filter_array: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
  return_all: Maybe<Scalars['Boolean']>;
  since: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
  search: Maybe<Scalars['String']>;
};


export type GqlQueryReturn_By_IdArgs = {
  return_id: Maybe<Scalars['String']>;
};


export type GqlQueryProduct_Return_Item_By_IdArgs = {
  product_return_item_id: Maybe<Scalars['String']>;
};


export type GqlQueryProduct_Return_ItemsArgs = {
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter: Maybe<Scalars['GenericScalar']>;
  filter_array: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
  return_all: Maybe<Scalars['Boolean']>;
  since: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
  search: Maybe<Scalars['String']>;
};


export type GqlQueryPackaging_Return_Item_By_IdArgs = {
  packaging_return_item_id: Maybe<Scalars['String']>;
};


export type GqlQueryPackaging_Return_ItemsArgs = {
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter: Maybe<Scalars['GenericScalar']>;
  filter_array: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
  return_all: Maybe<Scalars['Boolean']>;
  since: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
  search: Maybe<Scalars['String']>;
};


export type GqlQueryDispatch_Product_Summary_V2Args = {
  filters: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter: Maybe<Scalars['GenericScalar']>;
  filter_array: Maybe<Scalars['GenericScalar']>;
  return_all: Maybe<Scalars['Boolean']>;
  since: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
  search: Maybe<Scalars['String']>;
};


export type GqlQueryProjected_Ph_Revenue_V2Args = {
  route_id: Maybe<Scalars['String']>;
  date: Maybe<Scalars['String']>;
};


export type GqlQueryVehicle_Stock_AllocationsArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_array: Maybe<Scalars['GenericScalar']>;
  return_all: Maybe<Scalars['Boolean']>;
  since: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryVehicle_Stock_Allocation_By_IdArgs = {
  vehicle_stock_allocation_id: Maybe<Scalars['String']>;
};


export type GqlQueryVehicle_Delivery_And_LoadingArgs = {
  filters: Maybe<Scalars['GenericScalar']>;
  search: Maybe<Scalars['String']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter: Maybe<Scalars['GenericScalar']>;
  filter_array: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
  return_all: Maybe<Scalars['Boolean']>;
  since: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryUnpaginated_Vehicle_Delivery_And_LoadingArgs = {
  filters: Maybe<Scalars['GenericScalar']>;
  search: Maybe<Scalars['String']>;
};


export type GqlQueryPricing_V2Args = {
  filter: Maybe<Scalars['GenericScalar']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
};


export type GqlQueryPricing_V2_By_IdArgs = {
  pricing_id: Maybe<Scalars['String']>;
};


export type GqlQueryPricing_V2_By_Product_Item_IdArgs = {
  product_item_id: Maybe<Scalars['String']>;
  region_id: Scalars['String'];
};


export type GqlQueryPricing_V2_By_Product_Item_IdsArgs = {
  product_item_ids: Maybe<Array<Maybe<Scalars['String']>>>;
  region_ids: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type GqlQueryPricing_V2_By_Region_IdArgs = {
  region_id: Maybe<Scalars['String']>;
};


export type GqlQueryDynamic_PricingArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  valid_only: Maybe<Scalars['Boolean']>;
  exclude_markdowns: Maybe<Scalars['Boolean']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
};


export type GqlQueryDynamic_Pricing_By_IdArgs = {
  dynamic_pricing_id: Maybe<Scalars['String']>;
};


export type GqlQueryPrice_ComparisonArgs = {
  filters: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
  product_item_id: Scalars['UUID'];
  depot_id: Scalars['UUID'];
  pricing_date: Scalars['Date'];
  static_params: Scalars['GenericScalar'];
};


export type GqlQueryBatchesArgs = {
  product_id: Maybe<Scalars['String']>;
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryBatch_By_IdArgs = {
  batch_id: Maybe<Scalars['String']>;
};


export type GqlQueryArticlesArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryArticle_By_IdArgs = {
  article_id: Maybe<Scalars['String']>;
};


export type GqlQuerySaved_ArticlesArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQuerySaved_Article_By_IdArgs = {
  saved_article_id: Maybe<Scalars['String']>;
};


export type GqlQueryBidsArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryBid_ApplicationsArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryBid_By_IdArgs = {
  bid_id: Maybe<Scalars['String']>;
};


export type GqlQueryBids_By_Category_IdsArgs = {
  categories: Maybe<Array<Maybe<Scalars['String']>>>;
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryCount_Bids_By_Category_IdsArgs = {
  categories: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type GqlQueryBid_Application_By_IdArgs = {
  bid_application_id: Maybe<Scalars['String']>;
};


export type GqlQueryBid_Application_By_Agent_IdArgs = {
  agent_id: Scalars['String'];
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryBid_Application_By_Vehicle_RegistrationArgs = {
  vehicle_registration: Maybe<Scalars['String']>;
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryHarvestsArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryHarvest_Vs_IntakeArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryHarvest_Vs_Intake_UnpaginatedArgs = {
  start_date: Maybe<Scalars['String']>;
  end_date: Maybe<Scalars['String']>;
  batch_id: Maybe<Scalars['String']>;
  product_id: Maybe<Scalars['String']>;
  product_item_id: Maybe<Scalars['String']>;
  product_type: Maybe<Scalars['String']>;
  stock_intake_number: Maybe<Scalars['String']>;
};


export type GqlQueryHarvest_By_IdArgs = {
  harvest_id: Maybe<Scalars['String']>;
};


export type GqlQueryHarvest_Issues_By_Harvest_IdArgs = {
  harvest_id: Maybe<Scalars['String']>;
};


export type GqlQueryPayment_DetailsArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryHarvest_ItemsArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryHarvest_Item_By_IdArgs = {
  harvest_item_id: Maybe<Scalars['String']>;
};


export type GqlQueryHarvest_Item_AssetsArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryHarvest_Item_Assets_By_IdArgs = {
  harvest_item_asset_id: Maybe<Scalars['String']>;
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryFarmersArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryFarmers_By_Collection_Center_IdArgs = {
  collection_center_id: Maybe<Scalars['String']>;
  filter: Maybe<Scalars['GenericScalar']>;
};


export type GqlQueryFarmers_By_Area_IdArgs = {
  area_id: Maybe<Scalars['String']>;
  filter: Maybe<Scalars['GenericScalar']>;
};


export type GqlQueryFarmer_By_IdArgs = {
  farmer_id: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQuerySourcing_AgentsArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQuerySourcing_Agent_By_IdArgs = {
  agent_number: Maybe<Scalars['String']>;
  agent_id: Maybe<Scalars['String']>;
};


export type GqlQueryFarm_By_IdArgs = {
  farm_id: Maybe<Scalars['String']>;
};


export type GqlQueryFarmsArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryFarms_By_Farmer_IdArgs = {
  farmer_id: Maybe<Scalars['String']>;
  filter: Maybe<Scalars['GenericScalar']>;
};


export type GqlQueryFarmer_Payment_AccountsArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryAgent_FeedbackArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryAgent_Feedback_By_IdArgs = {
  agent_feedback_id: Maybe<Scalars['String']>;
};


export type GqlQuerySupplier_UsersArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQuerySupplier_User_By_IdArgs = {
  supplier_user_id: Maybe<Scalars['String']>;
};


export type GqlQuerySupplier_User_By_Supplier_IdArgs = {
  supplier_id: Maybe<Scalars['String']>;
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQuerySupplier_ProductsArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQuerySupplier_Product_By_IdArgs = {
  supplier_product_id: Maybe<Scalars['String']>;
};


export type GqlQuerySupplier_Product_By_Supplier_IdArgs = {
  supplier_id: Maybe<Scalars['String']>;
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryBooking_By_IdArgs = {
  booking_id: Maybe<Scalars['String']>;
};


export type GqlQueryBookingsArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQuerySourcing_Price_RangeArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQuerySourcing_Price_Range_By_IdArgs = {
  price_range_id: Maybe<Scalars['String']>;
};


export type GqlQuerySourcing_Price_Range_By_Product_IdArgs = {
  product_id: Maybe<Scalars['String']>;
};


export type GqlQueryBooking_ItemsArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryBooking_Item_By_IdArgs = {
  booking_item_id: Maybe<Scalars['String']>;
};


export type GqlQueryBooking_SummaryArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryBooking_Summary_UnpaginatedArgs = {
  harvest_date: Maybe<Array<Maybe<Scalars['String']>>>;
  product_id: Maybe<Array<Maybe<Scalars['String']>>>;
  product_item_id: Maybe<Array<Maybe<Scalars['String']>>>;
  created_by: Maybe<Scalars['String']>;
  collection_center_id: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type GqlQueryBooking_Summary_TotalsArgs = {
  harvest_date: Maybe<Array<Maybe<Scalars['String']>>>;
  product_id: Maybe<Array<Maybe<Scalars['String']>>>;
  product_item_id: Maybe<Array<Maybe<Scalars['String']>>>;
  created_by: Maybe<Scalars['String']>;
  collection_center_id: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type GqlQueryAreasArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  since: Maybe<Scalars['String']>;
};


export type GqlQueryArea_By_IdArgs = {
  area_id: Maybe<Scalars['String']>;
};


export type GqlQueryCollection_CentersArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  since: Maybe<Scalars['String']>;
};


export type GqlQueryCollection_Centers_By_Area_IdArgs = {
  area_id: Maybe<Scalars['String']>;
  filter: Maybe<Scalars['GenericScalar']>;
};


export type GqlQueryCollection_Center_By_IdArgs = {
  collection_center_id: Maybe<Scalars['String']>;
};


export type GqlQueryHarvest_AreasArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  since: Maybe<Scalars['String']>;
};


export type GqlQueryHarvest_Area_By_IdArgs = {
  harvest_area_id: Maybe<Scalars['String']>;
};


export type GqlQueryHarvest_Areas_By_Collection_Center_IdArgs = {
  collection_center_id: Maybe<Scalars['String']>;
  filter: Maybe<Scalars['GenericScalar']>;
};


export type GqlQueryEwalletAccountArgs = {
  id: Scalars['ID'];
};


export type GqlQueryEwalletAccountsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  filters: Maybe<GqlEwalletAccountFilters>;
};


export type GqlQueryEwalletAccountsForUserArgs = {
  filters: Maybe<GqlEwalletAccountFilters>;
};


export type GqlQueryLedgerEntryArgs = {
  id: Scalars['ID'];
};


export type GqlQueryLedgerEntriesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  filters: Maybe<GqlLedgerEntryFilters>;
};


export type GqlQueryLedgerEntriesForUserArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  filters: Maybe<GqlLedgerEntryFilters>;
};


export type GqlQueryEwalletTransactionTypeArgs = {
  id: Scalars['ID'];
};


export type GqlQueryEwalletTransactionTypesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  filters: Maybe<GqlEwalletTransactionTypeFilters>;
};


export type GqlQueryAuthorizers_By_IdsArgs = {
  authorizer_ids: Array<Scalars['String']>;
};


export type GqlQueryAuthorizersArgs = {
  filters: Maybe<GqlAuthorizerGqlInput>;
  filter_string: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
  search: Maybe<Scalars['String']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  since: Maybe<Scalars['DateTime']>;
};


export type GqlQueryWorkflows_By_IdsArgs = {
  workflow_ids: Array<Scalars['String']>;
};


export type GqlQueryWorkflow_By_IdArgs = {
  workflow_id: Scalars['String'];
};


export type GqlQueryAuthorizer_By_IdArgs = {
  authorizer_id: Scalars['String'];
};


export type GqlQueryRequest_By_IdArgs = {
  request_id: Scalars['String'];
};


export type GqlQueryRequests_By_IdsArgs = {
  request_ids: Array<Scalars['String']>;
};


export type GqlQueryRequestsArgs = {
  filters: Maybe<GqlRequestInputInput>;
  filter_string: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
  search: Maybe<Scalars['String']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  since: Maybe<Scalars['DateTime']>;
};


export type GqlQueryWorkflowsArgs = {
  filters: Maybe<GqlWorkflowGqlInput>;
  filter_string: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
  search: Maybe<Scalars['String']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  since: Maybe<Scalars['DateTime']>;
};


export type GqlQueryReturns_Handling_Unit_ItemsArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryReturns_Handling_UnitsArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryWms_ReturnsArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryDisposal_ItemsArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryDisposalsArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryWarehouse_Transfer_Request_ItemsArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryWarehouse_Transfer_RequestsArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryFdr_SkusArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryFulfilment_Dispatch_Request_ItemsArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryFulfilment_Dispatch_RequestsArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryReserve_Picking_JobsArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryPickface_Picking_TasksArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryPicking_Handling_UnitsArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryPickface_Picking_Task_ItemsArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryLoading_TasksArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryLoading_Task_ItemsArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryPutaway_JobsArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryProduct_ZonesArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryRulesArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryProduct_RulesArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryCategory_RulesArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryHandling_UnitsArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryStock_Intake_SkusArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryHandling_Unit_ItemsArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryStorage_LocationsArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryStorage_Location_TypesArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryProduct_Loss_ReasonsArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryProduct_Loss_Reason_By_IdArgs = {
  product_loss_reason_id: Maybe<Scalars['String']>;
};


export type GqlQueryProduct_Loss_Reason_By_Product_IdArgs = {
  product_id: Maybe<Scalars['String']>;
};


export type GqlQueryDock_DoorsArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryFilter_BinsArgs = {
  _from: Maybe<Scalars['String']>;
  _to: Maybe<Scalars['String']>;
};


export type GqlQueryBinsArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryBin_NamesArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryWaste_AreasArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryAppointment_ItemsArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryAppointment_Item_By_Batch_IdArgs = {
  batch_id: Maybe<Scalars['String']>;
};


export type GqlQueryAppointmentsArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryWarehousesArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryStock_IntakesArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryStock_Intake_SummaryArgs = {
  batch_id: Maybe<Scalars['String']>;
  product_item_id: Maybe<Scalars['String']>;
  harvested_quantity: Maybe<Scalars['Float']>;
  harvested_weight: Maybe<Scalars['Float']>;
};


export type GqlQueryStock_Intake_By_IdArgs = {
  stock_intake_id: Maybe<Scalars['String']>;
};


export type GqlQueryEtims_Event_Outbox_ItemsArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryStock_Intake_ItemsArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryStock_Intake_Item_By_IdArgs = {
  stock_intake_item_id: Maybe<Scalars['String']>;
};


export type GqlQueryStock_Intake_Items_By_Batch_IdArgs = {
  batch_id: Maybe<Scalars['String']>;
};


export type GqlQueryProcessing_LossArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryProcessing_Loss_By_IdArgs = {
  processing_loss_id: Maybe<Scalars['String']>;
};


export type GqlQueryProcessing_Loss_ItemsArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryProcessing_Loss_Item_By_IdArgs = {
  processing_loss_item_id: Maybe<Scalars['String']>;
};


export type GqlQueryStorage_UnitsArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryStorageArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryStorage_By_IdArgs = {
  storage_id: Maybe<Scalars['String']>;
};


export type GqlQueryV2_Stock_AvailabilityArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryV2_Stock_Availability_By_IdArgs = {
  stock_availability_id: Maybe<Scalars['String']>;
};


export type GqlQueryV2_Stock_Availability_Product_Item_SummaryArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryV2_Stock_Availability_UnpaginatedArgs = {
  filters: Maybe<Scalars['GenericScalar']>;
};


export type GqlQueryStock_Availability_By_Product_ItemArgs = {
  filters: Maybe<Scalars['GenericScalar']>;
  sale_date: Maybe<Scalars['String']>;
  per_page: Maybe<Scalars['Int']>;
  page: Maybe<Scalars['Int']>;
};


export type GqlQueryV2_Stock_Availability_By_Product_Item_IdArgs = {
  product_item_id: Scalars['String'];
  sale_date: Maybe<Scalars['String']>;
  distribution_center_id: Scalars['String'];
};


export type GqlQueryMultiple_Product_Items_Stock_Availability_Per_DcArgs = {
  args_list: Array<Maybe<GqlBatchDcStockAvailabilityArgs>>;
};


export type GqlQueryDc_Stock_Availability_By_Product_Item_IdArgs = {
  product_item_id: Scalars['String'];
  sale_date: Maybe<Scalars['String']>;
  distribution_center_ids: Array<Maybe<Scalars['String']>>;
  depot_id: Scalars['String'];
};


export type GqlQueryMultiple_Product_Items_Stock_AvailabilityArgs = {
  args_list: Array<Maybe<GqlBatchStockAvailabilityArgs>>;
};


export type GqlQueryLoss_Reason_By_IdArgs = {
  loss_reason_id: Maybe<Scalars['String']>;
};


export type GqlQueryProcessing_Loss_TypesArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryProcessing_Loss_Type_By_IdArgs = {
  processing_loss_type_id: Maybe<Scalars['String']>;
};


export type GqlQueryInventory_By_Product_ItemArgs = {
  product_item_id: Scalars['String'];
  date: Scalars['String'];
  distribution_center_id: Scalars['String'];
};


export type GqlQueryStock_Intake_Weight_By_Batch_IdArgs = {
  batch_id: Scalars['String'];
};


export type GqlQueryProcessing_Loss_Weight_By_Batch_IdArgs = {
  batch_id: Scalars['String'];
};


export type GqlQueryDistribution_CentersArgs = {
  dc_names: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type GqlQueryDistribution_Centers_V2Args = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryDistribution_Center_By_IdArgs = {
  distribution_center_id: Maybe<Scalars['String']>;
};


export type GqlQueryDistribution_Centers_By_IdsArgs = {
  distribution_center_ids: Array<Maybe<Scalars['String']>>;
};


export type GqlQueryStock_TakeArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryStock_Take_By_IdArgs = {
  stock_take_id: Maybe<Scalars['String']>;
};


export type GqlQueryStock_Take_LocationArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryStock_Take_Location_By_IdArgs = {
  stock_take_location_id: Maybe<Scalars['String']>;
};


export type GqlQueryWarehouse_Transfer_ItemArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryWarehouse_Transfer_Item_By_IdArgs = {
  warehouse_transfer_item_id: Maybe<Scalars['String']>;
};


export type GqlQueryWarehouse_Transfer_ReasonArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryWarehouse_TransferArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryWarehouse_Transfer_By_IdArgs = {
  warehouse_transfer_id: Maybe<Scalars['String']>;
};


export type GqlQueryAssetsArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryAsset_By_IdArgs = {
  asset_id: Maybe<Scalars['String']>;
};


export type GqlQueryAsset_PhotosArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryAsset_Photo_By_IdArgs = {
  asset_photo_id: Maybe<Scalars['String']>;
};


export type GqlQueryDamaged_Asset_PhotosArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryDamaged_Asset_Photo_By_IdArgs = {
  asset_photo_id: Maybe<Scalars['String']>;
};


export type GqlQueryAsset_TrackersArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryAsset_Tracker_By_IdArgs = {
  asset_tracker_id: Maybe<Scalars['String']>;
};


export type GqlQueryAsset_Tracker_ItemsArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryAsset_Tracker_Item_By_IdArgs = {
  asset_tracker_item_id: Maybe<Scalars['String']>;
};


export type GqlQueryInventory_LocationsArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryInventory_Location_By_IdArgs = {
  inventory_location_id: Maybe<Scalars['String']>;
};


export type GqlQueryAsset_ControlsArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryAsset_Control_By_IdArgs = {
  asset_control_id: Maybe<Scalars['String']>;
};


export type GqlQueryProduct_LocationsArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryProduct_Locations_UnpaginatedArgs = {
  product_item_id: Maybe<Array<Maybe<Scalars['String']>>>;
  storage_location_number: Maybe<Scalars['String']>;
  location_type_id: Maybe<Array<Maybe<Scalars['String']>>>;
  product_segment_id: Maybe<Array<Maybe<Scalars['String']>>>;
  category_id: Maybe<Array<Maybe<Scalars['String']>>>;
  product_id: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type GqlQueryProduct_ExpiryArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryProduct_Expiry_UnpaginatedArgs = {
  asset_number: Maybe<Array<Maybe<Scalars['String']>>>;
  storage_location_number: Maybe<Array<Maybe<Scalars['String']>>>;
  location_type_id: Maybe<Array<Maybe<Scalars['String']>>>;
  category_id: Maybe<Array<Maybe<Scalars['String']>>>;
  product_id: Maybe<Array<Maybe<Scalars['String']>>>;
  product_item_id: Maybe<Array<Maybe<Scalars['String']>>>;
  stock_age: Maybe<Scalars['Int']>;
  expiry_duration: Maybe<Scalars['Int']>;
  start_date: Maybe<Scalars['String']>;
  end_date: Maybe<Scalars['String']>;
};


export type GqlQueryProduct_Transfer_ReasonsArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryProduct_Transfer_Reason_By_IdArgs = {
  product_transfer_reason_id: Maybe<Scalars['String']>;
};


export type GqlQueryProduct_TransfersArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryProduct_Transfer_By_IdArgs = {
  product_transfer_id: Maybe<Scalars['String']>;
};


export type GqlQueryProduct_Transfer_Handling_UnitsArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryProduct_Transfer_Handling_Unit_By_IdArgs = {
  product_transfer_handling_unit_id: Maybe<Scalars['String']>;
};


export type GqlQueryProduct_Transfer_Handling_Unit_ItemsArgs = {
  filter: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryProduct_Transfer_Handling_Unit_Item_By_IdArgs = {
  product_transfer_handling_unit_item_id: Maybe<Scalars['String']>;
};


export type GqlQueryLeadCallbackConnectionArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
};


export type GqlQueryOrderCallbackConnectionArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
};


export type GqlQueryLoanOfferConnectionArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  filters: Maybe<GqlLoanOfferFilters>;
  filter_string: Maybe<Scalars['String']>;
};


export type GqlQueryLoanRequestConnectionArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
};


export type GqlQueryCustomerPartnerConnectionArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  filters: Maybe<GqlCustomerPartnerConnectionFilters>;
  filter_string: Maybe<Scalars['String']>;
};


export type GqlQueryCustomerConnectionArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  filters: Maybe<GqlCustomerConnectionFilters>;
  filter_string: Maybe<Scalars['String']>;
};


export type GqlQueryPartnerConnectionArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  filters: Maybe<GqlPartnerConnectionFilters>;
};


export type GqlQueryPartnerTopupConnectionArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  filters: Maybe<GqlPartnerTopupConnectionFilters>;
};


export type GqlQueryPartnerUpdateConnectionArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  filters: Maybe<GqlPartnerUpdateConnectionFilters>;
};


export type GqlQueryPartnerEarningConnectionArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  filters: Maybe<GqlPartnerEarningConnectionFilters>;
};


export type GqlQueryLoanProductConnectionArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  filters: Maybe<GqlLoanProductConnectionFilters>;
};


export type GqlQueryDepotPartnerConnectionArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
};


export type GqlQueryLoanConnectionArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  filters: Maybe<GqlLoanConnectionFilters>;
  filter_string: Maybe<Scalars['String']>;
};


export type GqlQueryLoanUpdateConnectionArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  filters: Maybe<GqlLoanUpdateConnectionFilters>;
};


export type GqlQueryLoanRepaymentConnectionArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  filters: Maybe<GqlLoanRepaymentConnectionFilters>;
};


export type GqlQueryLoanRejectionReasonArgs = {
  filters: Maybe<GqlLoanRejectionReasonFilters>;
};


export type GqlQuerySokoloan_RepaymentsArgs = {
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
  filters: Maybe<Scalars['GenericScalar']>;
  since: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
};


export type GqlQuerySokoloan_Repayment_By_IdArgs = {
  sokoloan_repayment_id: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryLead_CallbacksArgs = {
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
  filters: Maybe<Scalars['GenericScalar']>;
  since: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
};


export type GqlQueryLead_Callback_By_IdArgs = {
  active: Maybe<Scalars['Boolean']>;
  lead_callback_id: Scalars['UUID'];
};


export type GqlQueryFintech_LoansArgs = {
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
  filters: Maybe<Scalars['GenericScalar']>;
  since: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
};


export type GqlQueryFintech_Loans_By_Customer_IdArgs = {
  customer_id: Scalars['String'];
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
  filters: Maybe<Scalars['GenericScalar']>;
  since: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
};


export type GqlQueryFintech_Loan_By_IdArgs = {
  loan_confirmation_id: Scalars['String'];
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryLoan_OffersArgs = {
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
  filters: Maybe<Scalars['GenericScalar']>;
  since: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
};


export type GqlQueryLoan_Offer_By_Delivery_IdArgs = {
  delivery_id: Scalars['String'];
};


export type GqlQueryLoan_Offer_By_IdArgs = {
  loan_offer_id: Scalars['String'];
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryLoan_RequestsArgs = {
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
  filters: Maybe<Scalars['GenericScalar']>;
  since: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
};


export type GqlQueryLoan_Request_By_IdArgs = {
  loan_request_id: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryPartner_Loan_Query_By_Customer_IdArgs = {
  customer_id: Scalars['String'];
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryPartner_Member_Query_By_Customer_IdArgs = {
  customer_id: Scalars['String'];
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryFintech_DeliveriesArgs = {
  search: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  since: Maybe<Scalars['String']>;
};


export type GqlQueryFintech_Deliveries_By_Customer_IdArgs = {
  search: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
  customer_id: Scalars['String'];
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  since: Maybe<Scalars['String']>;
};


export type GqlQueryFintech_CustomersArgs = {
  search: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
  since: Maybe<Scalars['String']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
};


export type GqlQueryFintech_Customer_By_IdArgs = {
  fintech_customer_id: Scalars['String'];
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryFintech_PartnersArgs = {
  active: Maybe<Scalars['Boolean']>;
  since: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
};


export type GqlQueryFintech_Partner_By_IdArgs = {
  partner_id: Scalars['String'];
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryPaginated_Product_Item_BundlesArgs = {
  search: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
  static_params: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  since: Maybe<Scalars['String']>;
};


export type GqlQueryProduct_Item_Bundle_By_IdArgs = {
  product_item_bundle_id: Scalars['String'];
};


export type GqlQueryProduct_Item_Bundle_By_IdsArgs = {
  product_item_bundle_ids: Array<Maybe<Scalars['String']>>;
};


export type GqlQueryPaginated_Product_Items_V2Args = {
  filters: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
  static_params: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  since: Maybe<Scalars['String']>;
};


export type GqlQueryProduct_Items_V2_By_IdsArgs = {
  active: Maybe<Scalars['Boolean']>;
  product_item_ids: Maybe<Array<Maybe<Scalars['String']>>>;
  static_params: Maybe<Scalars['GenericScalar']>;
  filters: Maybe<Scalars['GenericScalar']>;
};


export type GqlQueryProduct_Item_V2_By_IdArgs = {
  product_item_id: Maybe<Scalars['String']>;
  product_slug: Maybe<Scalars['String']>;
  item_slug: Maybe<Scalars['String']>;
  static_params: Maybe<Scalars['GenericScalar']>;
  filters: Maybe<Scalars['GenericScalar']>;
};


export type GqlQueryProduct_Items_V2Args = {
  active: Maybe<Scalars['Boolean']>;
  filters: Maybe<Scalars['GenericScalar']>;
  static_params: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
};


export type GqlQueryProduct_Item_Segments_V2Args = {
  filters: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryProduct_Item_Segment_V2_By_IdArgs = {
  product_item_segment_id: Scalars['String'];
};


export type GqlQueryBundle_Association_V2_By_SkuArgs = {
  product_item_id: Scalars['String'];
};


export type GqlQueryProduct_Promotional_Banners_V2Args = {
  active: Maybe<Scalars['Boolean']>;
  published: Maybe<Scalars['Boolean']>;
  filters: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
};


export type GqlQueryProduct_Promotional_Banner_V2_By_IdArgs = {
  banner_id: Scalars['String'];
  active: Maybe<Scalars['Boolean']>;
  filter_string: Maybe<Scalars['String']>;
};


export type GqlQueryProduct_Items_With_TagsArgs = {
  active: Maybe<Scalars['Boolean']>;
  filters: Maybe<Scalars['GenericScalar']>;
  static_params: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
};


export type GqlQueryPromotion_Tag_By_IdArgs = {
  promotion_tag_id: Scalars['String'];
  active: Maybe<Scalars['Boolean']>;
  filter_string: Maybe<Scalars['String']>;
};


export type GqlQueryPromotion_TypesArgs = {
  filters: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryPromotion_Type_By_IdArgs = {
  promotion_type_id: Scalars['String'];
  active: Maybe<Scalars['Boolean']>;
  filter_string: Maybe<Scalars['String']>;
};


export type GqlQueryProduct_Categories_V2Args = {
  filters: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
  search: Maybe<Scalars['String']>;
};


export type GqlQueryProduct_Category_V2_By_IdArgs = {
  category_id: Scalars['String'];
  slug: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryProduct_Categories_V2_By_Department_IdArgs = {
  department_id: Scalars['String'];
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryProduct_Departments_V2Args = {
  filters: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryProduct_Department_V2_By_IdArgs = {
  department_id: Scalars['String'];
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryUoms_V2Args = {
  filters: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
  search: Maybe<Scalars['String']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  since: Maybe<Scalars['String']>;
};


export type GqlQueryUom_V2_By_IdArgs = {
  uom_id: Scalars['String'];
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryBusiness_Domains_V2Args = {
  filters: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryUom_V2_By_IdsArgs = {
  uom_ids: Array<Maybe<Scalars['String']>>;
};


export type GqlQueryProduct_Item_Uoms_V2Args = {
  filters: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
  search: Maybe<Scalars['String']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  since: Maybe<Scalars['String']>;
};


export type GqlQueryProduct_Item_Uom_V2_By_IdArgs = {
  product_item_uom_id: Scalars['String'];
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryProduct_Item_Uom_V2_By_IdsArgs = {
  product_item_uom_ids: Array<Maybe<Scalars['String']>>;
};


export type GqlQueryPaginated_Products_V2Args = {
  search: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  since: Maybe<Scalars['String']>;
};


export type GqlQueryProduct_V2_By_IdArgs = {
  product_id: Scalars['String'];
};


export type GqlQueryProduct_V2_By_IdsArgs = {
  product_ids: Array<Maybe<Scalars['String']>>;
};


export type GqlQueryPackaging_Units_V2Args = {
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
};


export type GqlQueryPackaging_Unit_V2_By_IdArgs = {
  packaging_unit_id: Scalars['String'];
};


export type GqlQueryPackaging_Unit_V2_By_IdsArgs = {
  packaging_unit_ids: Array<Maybe<Scalars['String']>>;
};


export type GqlQueryFinance_Product_TypesArgs = {
  filters: Maybe<Scalars['GenericScalar']>;
  search: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryFinance_Product_Type_By_IdArgs = {
  id: Scalars['String'];
};


export type GqlQueryStandard_Unit_CostsArgs = {
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
};


export type GqlQueryStandard_Unit_Cost_By_IdArgs = {
  id: Scalars['String'];
};


export type GqlQueryStandard_Unit_Cost_By_Product_IdArgs = {
  product_id: Scalars['String'];
  date: Scalars['String'];
};


export type GqlQueryProduct_VarietiesArgs = {
  filters: Maybe<Scalars['GenericScalar']>;
  active: Maybe<Scalars['Boolean']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter_string: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  since: Maybe<Scalars['String']>;
};

export type GqlInboxGqlConnection = {
  __typename?: 'InboxGQLConnection';
  edges: Array<GqlInboxGqlEdge>;
  page_info: GqlPageInfo;
};

export type GqlInboxGqlEdge = {
  __typename?: 'InboxGQLEdge';
  cursor: Scalars['String'];
  node: GqlInboxMessage;
};

export type GqlInboxMessage = {
  __typename?: 'InboxMessage';
  created_at: Scalars['LocalDateTime'];
  isRead: Scalars['Boolean'];
  message: GqlMessage;
  message_id: Scalars['UUID'];
  updated_at: Maybe<Scalars['LocalDateTime']>;
  user_id: Scalars['UUID'];
};


export type GqlMessage = {
  __typename?: 'Message';
  body: Scalars['String'];
  data: Maybe<Scalars['String']>;
  image: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type GqlPageInfo = {
  __typename?: 'PageInfo';
  endCursor: Scalars['String'];
  hasNext: Scalars['Boolean'];
  hasPrevious: Scalars['Boolean'];
  startCursor: Scalars['String'];
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  count: Scalars['Int'];
};


export type GqlBhf = {
  __typename?: 'Bhf';
  tin: Scalars['String'];
  bhfId: Scalars['String'];
  bhfNm: Scalars['String'];
  bhfSttsCd: Scalars['String'];
  prvncNm: Scalars['String'];
  dstrtNm: Scalars['String'];
  sctrNm: Scalars['String'];
  locDesc: Scalars['String'];
  mgrNm: Scalars['String'];
  mgrTelNo: Scalars['String'];
  mgrEmail: Scalars['String'];
  hqYn: Scalars['String'];
};

export type GqlBranchList = {
  __typename?: 'BranchList';
  bhfList: Array<GqlBhf>;
};

export type GqlBranchListResponse = {
  __typename?: 'BranchListResponse';
  resultCd: Scalars['String'];
  resultMsg: Scalars['String'];
  resultDt: Scalars['String'];
  data: GqlBranchList;
};

export type GqlClassCodesResponse = {
  __typename?: 'ClassCodesResponse';
  resultCd: Scalars['String'];
  resultMsg: Scalars['String'];
  resultDt: Scalars['String'];
  data: GqlData;
};

export type GqlClsListItem = {
  __typename?: 'ClsListItem';
  cdCls: Scalars['String'];
  cdClsNm: Scalars['String'];
  cdClsDesc: Scalars['String'];
  useYn: Scalars['String'];
  userDfnNm1: Scalars['String'];
  userDfnNm2: Scalars['String'];
  userDfnNm3: Scalars['String'];
  dtlList: Array<GqlDtlListItem>;
};

export type GqlData = {
  __typename?: 'Data';
  itemClsList: Array<GqlItemCls>;
};

export type GqlDtlListItem = {
  __typename?: 'DtlListItem';
  cd: Scalars['String'];
  cdNm: Scalars['String'];
  cdDesc: Scalars['String'];
  useYn: Scalars['String'];
  srtOrd: Scalars['Int'];
  userDfnCd1: Scalars['String'];
  userDfnCd2: Scalars['String'];
  userDfnCd3: Scalars['String'];
};

export type GqlEtiMsClassCodesFilters = {
  itemClsCd?: Maybe<Scalars['String']>;
};

export type GqlEtiMsCodeFilters = {
  cdClsNm?: Maybe<Scalars['String']>;
};

export type GqlGraphPurchase = {
  __typename?: 'GraphPurchase';
  id: Scalars['String'];
  spplr_tin: Scalars['String'];
  spplr_nm: Scalars['String'];
  spplr_bhf_id: Scalars['String'];
  spplr_invc_no: Scalars['Int'];
  spplr_sdc_id: Scalars['String'];
  spplr_mrc_no: Scalars['String'];
  rcpt_ty_cd: Scalars['String'];
  pmt_ty_cd: Scalars['String'];
  cfm_dt: Scalars['String'];
  sales_dt: Scalars['String'];
  stock_rls_dt: Scalars['String'];
  tot_item_cnt: Scalars['Int'];
  taxbl_amt_a: Scalars['Float'];
  taxbl_amt_b: Scalars['Float'];
  taxbl_amt_c: Scalars['Float'];
  taxbl_amt_d: Scalars['Float'];
  taxbl_amt_e: Scalars['Float'];
  tax_rt_a: Scalars['Int'];
  tax_rt_b: Scalars['Int'];
  tax_rt_c: Scalars['Int'];
  tax_rt_d: Scalars['Int'];
  tax_rt_e: Scalars['Int'];
  tax_amt_a: Scalars['Float'];
  tax_amt_b: Scalars['Float'];
  tax_amt_c: Scalars['Float'];
  tax_amt_d: Scalars['Float'];
  tax_amt_e: Scalars['Float'];
  tot_taxbl_amt: Scalars['Float'];
  tot_tax_amt: Scalars['Float'];
  tot_amt: Scalars['Float'];
  item_list: Array<GqlGraphPurchaseItem>;
};

export type GqlGraphPurchaseItem = {
  __typename?: 'GraphPurchaseItem';
  id: Scalars['String'];
  purchase_id: Scalars['String'];
  item_seq: Scalars['Int'];
  item_cd: Scalars['String'];
  item_cls_cd: Scalars['String'];
  item_nm: Scalars['String'];
  bcd: Scalars['String'];
  pkg_unit_cd: Scalars['String'];
  pkg: Scalars['Int'];
  qty_unit_cd: Scalars['String'];
  qty: Scalars['Int'];
  prc: Scalars['Float'];
  sply_amt: Scalars['Float'];
  dc_rt: Scalars['Float'];
  dc_amt: Scalars['Float'];
  tax_ty_cd: Scalars['String'];
  taxbl_amt: Scalars['Float'];
  tax_amt: Scalars['Float'];
  tot_amt: Scalars['Float'];
};

export type GqlItem = {
  __typename?: 'Item';
  tin: Scalars['String'];
  itemCd: Scalars['String'];
  itemClsCd: Scalars['String'];
  itemTyCd: Scalars['String'];
  itemNm: Scalars['String'];
  itemStdNm: Maybe<Scalars['String']>;
  orgnNatCd: Scalars['String'];
  pkgUnitCd: Scalars['String'];
  qtyUnitCd: Scalars['String'];
  taxTyCd: Scalars['String'];
  btchNo: Scalars['String'];
  regBhfID: Scalars['String'];
  bcd: Scalars['String'];
  dftPrc: Scalars['Int'];
  grpPrcL1: Scalars['Int'];
  grpPrcL2: Scalars['Int'];
  grpPrcL3: Scalars['Int'];
  grpPrcL4: Scalars['Int'];
  grpPrcL5: Scalars['Int'];
  addInfo: Scalars['String'];
  sftyQty: Scalars['Int'];
  isrcAplcbYn: Scalars['String'];
  KRAModYn: Scalars['String'];
  useYn: Scalars['String'];
};

export type GqlItemCls = {
  __typename?: 'ItemCls';
  itemClsCd: Scalars['String'];
  itemClsNm: Scalars['String'];
  itemClsLvl: Scalars['Int'];
  taxTyCd: Scalars['String'];
  mjrTgYn: Scalars['String'];
  useYn: Scalars['String'];
};

export type GqlItemsData = {
  __typename?: 'ItemsData';
  itemList: Array<GqlItem>;
};

export type GqlItemsFilters = {
  itemCd?: Maybe<Scalars['String']>;
};

export type GqlItemsResponse = {
  __typename?: 'ItemsResponse';
  resultCd: Scalars['String'];
  resultMsg: Scalars['String'];
  resultDt: Scalars['String'];
  data: GqlItemsData;
};


export type GqlNotice = {
  __typename?: 'Notice';
  noticeNo: Scalars['Int'];
  title: Scalars['String'];
  cont: Scalars['String'];
  dtlUrl: Scalars['String'];
  regrNm: Scalars['String'];
  regDt: Scalars['String'];
};

export type GqlNoticeData = {
  __typename?: 'NoticeData';
  noticeList: Array<GqlNotice>;
};

export type GqlNoticeFilters = {
  noticeNo?: Maybe<Scalars['Int']>;
};

export type GqlNoticeResponse = {
  __typename?: 'NoticeResponse';
  resultCd: Scalars['String'];
  resultMsg: Scalars['String'];
  resultDt: Scalars['String'];
  data: GqlNoticeData;
};

export type GqlPurchasesFilters = {
  sales_dt?: Maybe<Scalars['String']>;
  spplr_invc_no?: Maybe<Scalars['Int']>;
};

export type GqlPurchasesResponse = {
  __typename?: 'PurchasesResponse';
  SalesList: Array<GqlGraphPurchase>;
};

export type GqlSelectCodesData = {
  __typename?: 'SelectCodesData';
  clsList: Array<GqlClsListItem>;
};

export type GqlSelectCodesResponse = {
  __typename?: 'SelectCodesResponse';
  resultCd: Scalars['String'];
  resultMsg: Scalars['String'];
  resultDt: Scalars['String'];
  data: GqlSelectCodesData;
};

export type GqlStock = {
  __typename?: 'Stock';
  custTin: Scalars['String'];
  custBhfId: Scalars['String'];
  sarNo: Scalars['Int'];
  ocrnDt: Scalars['String'];
  totItemCnt: Scalars['Int'];
  totTaxblAmt: Scalars['Int'];
  totTaxAmt: Scalars['Float'];
  totAmt: Scalars['Int'];
  remark: Scalars['String'];
  itemList: Array<GqlStockItem>;
};

export type GqlStockData = {
  __typename?: 'StockData';
  stockList: Array<GqlStock>;
};

export type GqlStockItem = {
  __typename?: 'StockItem';
  itemSeq: Scalars['Int'];
  itemCd: Scalars['String'];
  itemClsCd: Scalars['String'];
  itemNm: Scalars['String'];
  bcd: Scalars['String'];
  pkgUnitCd: Scalars['String'];
  pkg: Scalars['Int'];
  qtyUnitCd: Scalars['String'];
  qty: Scalars['Int'];
  itemExprDt: Scalars['String'];
  prc: Scalars['Int'];
  splyAmt: Scalars['Int'];
  totDcAmt: Scalars['Int'];
  taxblAmt: Scalars['Int'];
  taxTyCd: Scalars['String'];
  taxAmt: Scalars['Float'];
  totAmt: Scalars['Int'];
};

export type GqlStockMovementFilters = {
  ocrnDt?: Maybe<Scalars['String']>;
  custTin?: Maybe<Scalars['String']>;
};

export type GqlStockMovementsResponse = {
  __typename?: 'StockMovementsResponse';
  resultCd: Scalars['String'];
  resultMsg: Scalars['String'];
  resultDt: Scalars['String'];
  data: GqlStockData;
};

export type GqlTaxInvoiceFilter = {
  deliveryID: Scalars['String'];
};


/** Pagination Connection for PermissionV2GQL */
export type GqlPermissionV2GqlConnection = {
  __typename?: 'PermissionV2GQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  edges: Array<Maybe<GqlPermissionV2GqlEdge>>;
};

/** Construct pagination info. */
export type GqlPaginatorInfo = {
  __typename?: 'PaginatorInfo';
  current_page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  has_next: Maybe<Scalars['Boolean']>;
  has_previous: Maybe<Scalars['Boolean']>;
  total_pages: Maybe<Scalars['Int']>;
  total_count: Maybe<Scalars['Int']>;
};

export type GqlPermissionV2GqlEdge = {
  __typename?: 'PermissionV2GQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlPermissionV2Gql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlPermissionV2Gql = {
  __typename?: 'PermissionV2GQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Scalars['String'];
  client_timestamp: Scalars['DateTime'];
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  permission_id: Scalars['String'];
  permission_name: Scalars['String'];
  domain: Scalars['String'];
  roles: Maybe<Array<Maybe<GqlRoleV2Gql>>>;
};



export type GqlRoleV2Gql = {
  __typename?: 'RoleV2GQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Scalars['String'];
  client_timestamp: Scalars['DateTime'];
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  role_id: Maybe<Scalars['String']>;
  role_name: Scalars['String'];
  domain: Scalars['String'];
  permissions: Maybe<Array<Maybe<GqlPermissionV2Gql>>>;
};


/** Pagination Connection for UserV2GQL */
export type GqlUserV2GqlConnection = {
  __typename?: 'UserV2GQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  edges: Array<Maybe<GqlUserV2GqlEdge>>;
};

export type GqlUserV2GqlEdge = {
  __typename?: 'UserV2GQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlUserV2Gql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlUserV2Gql = {
  __typename?: 'UserV2GQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Scalars['String'];
  client_timestamp: Scalars['DateTime'];
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  user_id: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  user_number: Scalars['String'];
  phone_number: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  primary_role_id: Scalars['String'];
  last_login: Maybe<Scalars['DateTime']>;
  domain: Scalars['String'];
  region_id: Maybe<Scalars['String']>;
  default_country_id: Maybe<Scalars['String']>;
  country_ids: Maybe<Array<Maybe<Scalars['String']>>>;
  primary_role: Maybe<GqlRoleV2Gql>;
  roles: Maybe<Array<Maybe<GqlRoleV2Gql>>>;
  permissions: Maybe<Array<Maybe<GqlPermissionV2Gql>>>;
  permissions_blacklist: Maybe<Array<Maybe<GqlPermissionV2Gql>>>;
  user_no: Maybe<Scalars['Int']>;
  region: Maybe<GqlRegionV2Gql>;
};

export type GqlRegionV2Gql = {
  __typename?: 'RegionV2GQL';
  region_id: Scalars['String'];
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  region_name: Maybe<Scalars['String']>;
  regional_manager: Maybe<Scalars['String']>;
  country_id: Maybe<Scalars['String']>;
  country: Maybe<GqlCountryGql>;
  areas: Maybe<Array<Maybe<GqlAreaV2Gql>>>;
};

/** Pagination Connection for RoleV2GQL */
export type GqlRoleV2GqlConnection = {
  __typename?: 'RoleV2GQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  edges: Array<Maybe<GqlRoleV2GqlEdge>>;
};

export type GqlRoleV2GqlEdge = {
  __typename?: 'RoleV2GQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlRoleV2Gql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlMutation = {
  __typename?: 'Mutation';
  serverVersion: Scalars['String'];
  createAutoReconConfig: GqlAutoReconConfig;
  claimPayment: GqlPaymentClaim;
  createPaymentParty: GqlParty;
  updatePaymentParty: GqlParty;
  deletePaymentParty: GqlParty;
  createPartyProviderMode: GqlPartyProviderMode;
  updatePartyProviderMode: GqlPartyProviderMode;
  deletePartyProviderMode: GqlPartyProviderMode;
  createPaymentConfig: GqlPaymentConfig;
  makePayment: GqlPayment;
  createPaymentProvider: GqlProvider;
  createPaymentProviderMode: GqlProviderMode;
  upload_incident_image: Maybe<GqlUploadIncidentImage>;
  add_incident_category: Maybe<GqlCreateIncidentCategory>;
  update_incident_category: Maybe<GqlUpdateIncidentCategory>;
  delete_incident_category: Maybe<GqlDeleteIncidentCategory>;
  add_incident: Maybe<GqlCreateIncident>;
  update_incident: Maybe<GqlUpdateIncident>;
  delete_incident: Maybe<GqlDeleteIncident>;
  createEwalletAccount: Maybe<GqlEwalletAccount>;
  updateEwalletAccount: Maybe<GqlEwalletAccount>;
  createEwalletTransactionType: Maybe<GqlEwalletTransactionType>;
  updateEwalletTransactionType: Maybe<GqlEwalletTransactionType>;
  ewalletTransact: Maybe<GqlLedgerEntry>;
  ewalletAccountTransact: Maybe<GqlLedgerEntry>;
  add_workflow: Maybe<GqlCreateWorkflow>;
  update_authorizer: Maybe<GqlUpdateAuthorizer>;
  update_approval_request: Maybe<GqlUpdateRequest>;
  update_workflow: Maybe<GqlUpdateWorkflow>;
  add_approval_request: Maybe<GqlCreateRequest>;
  add_authorizer: Maybe<GqlCreateAuthorizer>;
  createPartner: Maybe<GqlPartner>;
  updatePartner: Maybe<GqlPartner>;
  deletePartner: Scalars['ID'];
  createPartnerTopup: Maybe<GqlPartnerTopup>;
  updatePartnerTopup: Maybe<GqlPartnerTopup>;
  deletePartnerTopup: Scalars['ID'];
  createPartnerWithdrawal: Maybe<GqlPartnerTopup>;
  updatePartnerWithdrawal: Maybe<GqlPartnerTopup>;
  createPartnerEarning: Maybe<GqlPartnerEarning>;
  createLoanProduct: Maybe<GqlLoanProduct>;
  updateLoanProduct: Maybe<GqlLoanProduct>;
  deleteLoanProduct: Scalars['ID'];
  createDepotPartner: Maybe<GqlDepotPartner>;
  updateDepotPartner: Maybe<GqlDepotPartner>;
  deleteDepotPartner: Scalars['ID'];
  createLoanUpdate: Maybe<GqlLoanUpdate>;
  deleteLoanUpdate: Scalars['ID'];
  createLoanRejectionReason: Maybe<GqlLoanRejectionReason>;
  deleteLoanRejectionReason: Scalars['ID'];
  createLoanWaiverReason: Maybe<GqlLoanWaiverReason>;
  deleteLoanWaiverReason: Scalars['ID'];
  updateCustomerPartner: Maybe<GqlCustomerPartner>;
  updateCustomer: Maybe<GqlCustomer>;
  add_product_item_bundle: Maybe<GqlCreateProductItemBundle>;
  update_product_item_bundle: Maybe<GqlUpdateProductItemBundle>;
  delete_product_item_bundle: Maybe<GqlDeleteProductItemBundle>;
  add_product_item_bundle_image: Maybe<GqlUploadProductItemBundleImage>;
  add_promotional_banner: Maybe<GqlCreateProductPromotionalBanner>;
  upload_banner_image: Maybe<GqlUploadProductPromotionalBannerImage>;
  update_promotional_banner: Maybe<GqlUpdateProductPromotionalBanner>;
  delete_promotional_banner: Maybe<GqlDeleteProductPromotionalBanner>;
  add_promotion_tag: Maybe<GqlCreatePromotionTag>;
  update_promotion_tag: Maybe<GqlUpdatePromotionTag>;
  delete_promotion_tag: Maybe<GqlDeletePromotionTag>;
  add_promotion_type: Maybe<GqlCreatePromotionType>;
  update_promotion_type: Maybe<GqlUpdatePromotionType>;
  delete_promotion_type: Maybe<GqlDeletePromotionType>;
  add_department: Maybe<GqlCreateDepartment>;
  update_department: Maybe<GqlUpdateDepartment>;
  delete_department: Maybe<GqlDeleteDepartment>;
  add_category: Maybe<GqlCreateCategory>;
  update_category: Maybe<GqlUpdateCategory>;
  delete_category: Maybe<GqlDeleteCategory>;
  add_category_region: Maybe<GqlCreateCategoryRegion>;
  update_category_region: Maybe<GqlUpdateCategoryRegion>;
  delete_category_region: Maybe<GqlDeleteCategoryRegion>;
  upload_category_image: Maybe<GqlUploadCategoryImage>;
  upload_department_image: Maybe<GqlUploadDepartmentImage>;
  add_product_item: Maybe<GqlCreateProductItem>;
  update_product_item: Maybe<GqlUpdateProductItem>;
  delete_product_item: Maybe<GqlDeleteProductItem>;
  add_product_item_image: Maybe<GqlUploadProductItemImage>;
  add_product_item_region: Maybe<GqlCreateProductItemRegion>;
  update_product_item_region: Maybe<GqlUpdateProductItemRegion>;
  delete_product_item_region: Maybe<GqlDeleteProductItemRegion>;
  add_product_item_segment: Maybe<GqlCreateProductItemSegment>;
  update_product_item_segment: Maybe<GqlUpdateProductItemSegment>;
  delete_product_item_segment: Maybe<GqlDeleteProductItemSegment>;
  add_bundle_association: Maybe<GqlCreateBundleAssociation>;
  delete_bundle_association: Maybe<GqlDeleteBundleAssociation>;
  add_uom: Maybe<GqlCreateUom>;
  update_uom: Maybe<GqlUpdateUom>;
  delete_uom: Maybe<GqlDeleteUom>;
  add_product_item_uom: Maybe<GqlCreateProductItemUom>;
  update_product_item_uom: Maybe<GqlUpdateProductItemUom>;
  delete_product_item_uom: Maybe<GqlDeleteProductItemUom>;
  add_business_domain: Maybe<GqlCreateBusinessDomain>;
  update_business_domain: Maybe<GqlUpdateBusinessDomain>;
  delete_business_domain: Maybe<GqlDeleteBusinessDomain>;
  add_product_domain_activation: Maybe<GqlCreateProductDomainActivation>;
  update_product_domain_activation: Maybe<GqlUpdateProductDomainActivation>;
  delete_product_domain_activation: Maybe<GqlDeleteProductDomainActivation>;
  add_packaging_unit: Maybe<GqlCreatePackagingUnit>;
  update_packaging_unit: Maybe<GqlUpdatePackagingUnit>;
  delete_packaging_unit: Maybe<GqlDeletePackagingUnit>;
  add_product: Maybe<GqlCreateProduct>;
  update_product: Maybe<GqlUpdateProduct>;
  delete_product: Maybe<GqlDeleteProduct>;
  add_product_image: Maybe<GqlUploadProductImage>;
  add_product_tag: Maybe<GqlCreateProductTag>;
  update_product_tag: Maybe<GqlUpdateProductTag>;
  delete_product_tag: Maybe<GqlDeleteProductTag>;
  add_finance_product_type: Maybe<GqlCreateFinanceProductType>;
  update_finance_product_type: Maybe<GqlUpdateFinanceProductType>;
  add_standard_unit_cost: Maybe<GqlCreateStandardUnitCost>;
  update_standard_unit_cost: Maybe<GqlUpdateStandardUnitCost>;
  add_product_variety: Maybe<GqlCreateProductVariety>;
  update_product_variety: Maybe<GqlUpdateProductVariety>;
  delete_product_variety: Maybe<GqlDeleteProductVariety>;
  addEndpoint: GqlAddEndpointResult;
  removeEndpoint: GqlRemoveEndpointResult;
  reloadAllEndpoints: GqlReloadAllEndpointsResult;
};


export type GqlMutationCreateAutoReconConfigArgs = {
  input: GqlCreateAutoReconConfigInput;
};


export type GqlMutationClaimPaymentArgs = {
  input: GqlClaimPaymentInput;
};


export type GqlMutationCreatePaymentPartyArgs = {
  input: GqlCreatePartyInput;
};


export type GqlMutationUpdatePaymentPartyArgs = {
  id: Scalars['UUID'];
  input: GqlUpdatePartyInput;
};


export type GqlMutationDeletePaymentPartyArgs = {
  id: Scalars['UUID'];
};


export type GqlMutationCreatePartyProviderModeArgs = {
  input: GqlCreatePartyProviderModeInput;
};


export type GqlMutationUpdatePartyProviderModeArgs = {
  id: Scalars['UUID'];
  input: GqlUpdatePartyProviderModeInput;
};


export type GqlMutationDeletePartyProviderModeArgs = {
  id: Scalars['UUID'];
};


export type GqlMutationCreatePaymentConfigArgs = {
  input: GqlCreatePaymentConfigInput;
};


export type GqlMutationMakePaymentArgs = {
  input: Maybe<GqlMakePaymentInput>;
};


export type GqlMutationCreatePaymentProviderArgs = {
  input: GqlCreateProviderInput;
};


export type GqlMutationCreatePaymentProviderModeArgs = {
  input: GqlCreateProviderModeInput;
};


export type GqlMutationUpload_Incident_ImageArgs = {
  image: Scalars['Upload'];
  incident_id: Scalars['String'];
};


export type GqlMutationAdd_Incident_CategoryArgs = {
  data: GqlIncidentCategoryInput;
};


export type GqlMutationUpdate_Incident_CategoryArgs = {
  data: GqlIncidentCategoryInput;
  incident_category_id: Scalars['String'];
};


export type GqlMutationDelete_Incident_CategoryArgs = {
  incident_category_id: Scalars['String'];
};


export type GqlMutationAdd_IncidentArgs = {
  data: GqlIncidentInput;
};


export type GqlMutationUpdate_IncidentArgs = {
  data: GqlIncidentInput;
  incident_id: Scalars['String'];
};


export type GqlMutationDelete_IncidentArgs = {
  incident_id: Scalars['String'];
};


export type GqlMutationCreateEwalletAccountArgs = {
  input: GqlCreateEwalletAccountInput;
};


export type GqlMutationUpdateEwalletAccountArgs = {
  input: GqlUpdateEwalletAccountInput;
};


export type GqlMutationCreateEwalletTransactionTypeArgs = {
  input: GqlCreateEwalletTransactionTypeInput;
};


export type GqlMutationUpdateEwalletTransactionTypeArgs = {
  input: GqlUpdateEwalletTransactionTypeInput;
};


export type GqlMutationEwalletTransactArgs = {
  input: Maybe<GqlEwalletTransactInput>;
};


export type GqlMutationEwalletAccountTransactArgs = {
  input: Maybe<GqlEwalletAccountTransactInput>;
};


export type GqlMutationAdd_WorkflowArgs = {
  data: GqlWorkflowInputInput;
};


export type GqlMutationUpdate_AuthorizerArgs = {
  data: GqlAuthorizerInputInput;
  authorizer_id: Scalars['String'];
};


export type GqlMutationUpdate_Approval_RequestArgs = {
  data: GqlRequestInputInput;
  request_id: Scalars['String'];
};


export type GqlMutationUpdate_WorkflowArgs = {
  data: GqlWorkflowInputInput;
  workflow_id: Scalars['String'];
};


export type GqlMutationAdd_Approval_RequestArgs = {
  data: GqlRequestInputInput;
};


export type GqlMutationAdd_AuthorizerArgs = {
  data: GqlAuthorizerInputInput;
};


export type GqlMutationCreatePartnerArgs = {
  input: GqlNewPartner;
};


export type GqlMutationUpdatePartnerArgs = {
  input: GqlUpdatePartner;
};


export type GqlMutationDeletePartnerArgs = {
  id: Scalars['ID'];
};


export type GqlMutationCreatePartnerTopupArgs = {
  input: GqlNewPartnerTopup;
};


export type GqlMutationUpdatePartnerTopupArgs = {
  input: GqlUpdatePartnerTopup;
};


export type GqlMutationDeletePartnerTopupArgs = {
  id: Scalars['ID'];
};


export type GqlMutationCreatePartnerWithdrawalArgs = {
  input: GqlNewPartnerWithdrawal;
};


export type GqlMutationUpdatePartnerWithdrawalArgs = {
  input: GqlUpdatePartnerWithdrawal;
};


export type GqlMutationCreatePartnerEarningArgs = {
  input: GqlNewPartnerEarning;
};


export type GqlMutationCreateLoanProductArgs = {
  input: GqlNewLoanProduct;
};


export type GqlMutationUpdateLoanProductArgs = {
  input: GqlUpdateLoanProduct;
};


export type GqlMutationDeleteLoanProductArgs = {
  id: Scalars['ID'];
};


export type GqlMutationCreateDepotPartnerArgs = {
  input: GqlNewDepotPartner;
};


export type GqlMutationUpdateDepotPartnerArgs = {
  input: GqlUpdateDepotPartner;
};


export type GqlMutationDeleteDepotPartnerArgs = {
  id: Scalars['ID'];
};


export type GqlMutationCreateLoanUpdateArgs = {
  input: GqlNewLoanUpdate;
};


export type GqlMutationDeleteLoanUpdateArgs = {
  id: Scalars['ID'];
};


export type GqlMutationCreateLoanRejectionReasonArgs = {
  input: GqlNewLoanRejectionReason;
};


export type GqlMutationDeleteLoanRejectionReasonArgs = {
  id: Scalars['ID'];
};


export type GqlMutationCreateLoanWaiverReasonArgs = {
  input: GqlNewLoanWaiverReason;
};


export type GqlMutationDeleteLoanWaiverReasonArgs = {
  id: Scalars['ID'];
};


export type GqlMutationUpdateCustomerPartnerArgs = {
  input: GqlUpdateCustomerPartner;
};


export type GqlMutationUpdateCustomerArgs = {
  input: GqlUpdateCustomer;
};


export type GqlMutationAdd_Product_Item_BundleArgs = {
  data: GqlProductItemBundleInput;
};


export type GqlMutationUpdate_Product_Item_BundleArgs = {
  data: GqlProductItemBundleInput;
  product_item_bundle_id: Scalars['String'];
};


export type GqlMutationDelete_Product_Item_BundleArgs = {
  product_item_bundle_id: Scalars['String'];
};


export type GqlMutationAdd_Product_Item_Bundle_ImageArgs = {
  image: Scalars['Upload'];
  product_item_bundle_id: Scalars['String'];
};


export type GqlMutationAdd_Promotional_BannerArgs = {
  data: GqlProductPromotionalBannerInput;
};


export type GqlMutationUpload_Banner_ImageArgs = {
  banner_id: Scalars['String'];
  image: Scalars['Upload'];
};


export type GqlMutationUpdate_Promotional_BannerArgs = {
  banner_id: Scalars['String'];
  data: GqlProductPromotionalBannerInput;
};


export type GqlMutationDelete_Promotional_BannerArgs = {
  banner_id: Scalars['String'];
};


export type GqlMutationAdd_Promotion_TagArgs = {
  data: GqlPromotionTagInput;
};


export type GqlMutationUpdate_Promotion_TagArgs = {
  data: GqlPromotionTagInput;
  promotion_tag_id: Scalars['String'];
};


export type GqlMutationDelete_Promotion_TagArgs = {
  promotion_tag_id: Scalars['String'];
};


export type GqlMutationAdd_Promotion_TypeArgs = {
  data: GqlPromotionTypeInput;
};


export type GqlMutationUpdate_Promotion_TypeArgs = {
  data: GqlPromotionTypeInput;
  promotion_type_id: Scalars['String'];
};


export type GqlMutationDelete_Promotion_TypeArgs = {
  promotion_type_id: Scalars['String'];
};


export type GqlMutationAdd_DepartmentArgs = {
  data: GqlDepartmentInput;
};


export type GqlMutationUpdate_DepartmentArgs = {
  data: GqlDepartmentInput;
  department_id: Scalars['String'];
};


export type GqlMutationDelete_DepartmentArgs = {
  department_id: Scalars['String'];
};


export type GqlMutationAdd_CategoryArgs = {
  data: GqlCategoryInput;
};


export type GqlMutationUpdate_CategoryArgs = {
  category_id: Scalars['String'];
  data: GqlCategoryInput;
};


export type GqlMutationDelete_CategoryArgs = {
  category_id: Scalars['String'];
};


export type GqlMutationAdd_Category_RegionArgs = {
  data: GqlCategoryRegionInput;
};


export type GqlMutationUpdate_Category_RegionArgs = {
  category_region_id: Scalars['String'];
  data: GqlCategoryRegionInput;
};


export type GqlMutationDelete_Category_RegionArgs = {
  category_region_id: Scalars['String'];
};


export type GqlMutationUpload_Category_ImageArgs = {
  category_id: Scalars['String'];
  image: Scalars['Upload'];
};


export type GqlMutationUpload_Department_ImageArgs = {
  department_id: Scalars['String'];
  image: Scalars['Upload'];
};


export type GqlMutationAdd_Product_ItemArgs = {
  data: GqlProductItemInput;
};


export type GqlMutationUpdate_Product_ItemArgs = {
  data: GqlProductItemInput;
  product_item_id: Scalars['String'];
};


export type GqlMutationDelete_Product_ItemArgs = {
  product_item_id: Scalars['String'];
};


export type GqlMutationAdd_Product_Item_ImageArgs = {
  image: Scalars['Upload'];
  product_item_id: Scalars['String'];
};


export type GqlMutationAdd_Product_Item_RegionArgs = {
  data: GqlProductItemRegionInput;
};


export type GqlMutationUpdate_Product_Item_RegionArgs = {
  data: GqlProductItemRegionInput;
  product_item_region_id: Scalars['String'];
};


export type GqlMutationDelete_Product_Item_RegionArgs = {
  product_item_region_id: Scalars['String'];
};


export type GqlMutationAdd_Product_Item_SegmentArgs = {
  data: GqlProductItemSegmentInput;
};


export type GqlMutationUpdate_Product_Item_SegmentArgs = {
  data: GqlProductItemSegmentInput;
  product_item_segment_id: Scalars['String'];
};


export type GqlMutationDelete_Product_Item_SegmentArgs = {
  product_item_segment_id: Scalars['String'];
};


export type GqlMutationAdd_Bundle_AssociationArgs = {
  data: Maybe<Array<Maybe<GqlBundleAssociationInput>>>;
};


export type GqlMutationDelete_Bundle_AssociationArgs = {
  association_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
};


export type GqlMutationAdd_UomArgs = {
  data: GqlUomInput;
};


export type GqlMutationUpdate_UomArgs = {
  data: GqlUomInput;
  uom_id: Scalars['String'];
};


export type GqlMutationDelete_UomArgs = {
  uom_id: Scalars['String'];
};


export type GqlMutationAdd_Product_Item_UomArgs = {
  data: GqlProductItemUomInput;
};


export type GqlMutationUpdate_Product_Item_UomArgs = {
  business_domain_id: Scalars['String'];
  data: GqlProductItemUomInput;
  product_item_id: Scalars['String'];
  product_item_uom_id: Scalars['String'];
  uom_id: Scalars['String'];
};


export type GqlMutationDelete_Product_Item_UomArgs = {
  business_domain_id: Scalars['String'];
  product_item_id: Scalars['String'];
  product_item_uom_id: Scalars['String'];
  uom_id: Scalars['String'];
};


export type GqlMutationAdd_Business_DomainArgs = {
  data: GqlBusinessDomainInput;
};


export type GqlMutationUpdate_Business_DomainArgs = {
  business_domain_id: Scalars['String'];
  data: GqlBusinessDomainInput;
};


export type GqlMutationDelete_Business_DomainArgs = {
  business_domain_id: Scalars['String'];
};


export type GqlMutationAdd_Product_Domain_ActivationArgs = {
  data: GqlProductDomainActivationInput;
};


export type GqlMutationUpdate_Product_Domain_ActivationArgs = {
  data: GqlProductDomainActivationInput;
  product_domain_activation_id: Scalars['String'];
};


export type GqlMutationDelete_Product_Domain_ActivationArgs = {
  product_domain_activation_id: Scalars['String'];
};


export type GqlMutationAdd_Packaging_UnitArgs = {
  data: GqlPackagingUnitInput;
};


export type GqlMutationUpdate_Packaging_UnitArgs = {
  data: GqlPackagingUnitInput;
  packaging_unit_id: Scalars['String'];
};


export type GqlMutationDelete_Packaging_UnitArgs = {
  packaging_unit_id: Scalars['String'];
};


export type GqlMutationAdd_ProductArgs = {
  data: GqlProductInput;
};


export type GqlMutationUpdate_ProductArgs = {
  data: GqlProductInput;
  product_id: Scalars['String'];
};


export type GqlMutationDelete_ProductArgs = {
  product_id: Scalars['String'];
};


export type GqlMutationAdd_Product_ImageArgs = {
  image: Scalars['Upload'];
  product_id: Scalars['String'];
};


export type GqlMutationAdd_Product_TagArgs = {
  data: GqlProductTagInput;
};


export type GqlMutationUpdate_Product_TagArgs = {
  data: GqlProductTagInput;
  product_tag_id: Scalars['String'];
};


export type GqlMutationDelete_Product_TagArgs = {
  product_tag_id: Scalars['String'];
};


export type GqlMutationAdd_Finance_Product_TypeArgs = {
  data: GqlFinanceProductTypeInput;
};


export type GqlMutationUpdate_Finance_Product_TypeArgs = {
  data: GqlFinanceProductTypeInput;
  finance_product_type_id: Scalars['String'];
};


export type GqlMutationAdd_Standard_Unit_CostArgs = {
  data: GqlStandardUnitCostInput;
};


export type GqlMutationUpdate_Standard_Unit_CostArgs = {
  data: GqlStandardUnitCostInput;
  standard_unit_cost_id: Scalars['String'];
};


export type GqlMutationAdd_Product_VarietyArgs = {
  data: GqlProductVarietyInput;
};


export type GqlMutationUpdate_Product_VarietyArgs = {
  data: GqlProductVarietyInput;
  product_variety_id: Scalars['String'];
};


export type GqlMutationDelete_Product_VarietyArgs = {
  product_variety_id: Scalars['String'];
};


export type GqlMutationAddEndpointArgs = {
  url: Scalars['String'];
};


export type GqlMutationRemoveEndpointArgs = {
  url: Scalars['String'];
};

export type GqlAutoReconConfig = {
  __typename?: 'AutoReconConfig';
  id: Scalars['ID'];
  dateCreated: Scalars['Time'];
  dateUpdated: Scalars['Time'];
  deletedAt: Maybe<Scalars['Time']>;
  active: Scalars['Boolean'];
  createdByID: Scalars['String'];
  updatedByID: Scalars['String'];
  meta: Maybe<Scalars['Map']>;
  tillNumber: Scalars['String'];
  shortCode: Scalars['String'];
  paymentConfigID: Scalars['String'];
  paymentConfig: Maybe<GqlPaymentConfig>;
};

export type GqlAutoreconConfigConnection = {
  __typename?: 'AutoreconConfigConnection';
  pageInfo: GqlPageInfo;
  edges: Array<GqlAutoReconConfig>;
};

export type GqlAutoreconConfigFilters = {
  ids?: Maybe<Array<Scalars['UUID']>>;
  active?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
};

export type GqlAutoreconLookupInput = {
  transactionID: Scalars['RequiredString'];
  configName: Scalars['RequiredString'];
};

export type GqlClaimPaymentInput = {
  paymentID: Scalars['UUID'];
  amount: Scalars['Float'];
  referenceID: Scalars['RequiredString'];
  configName: Scalars['RequiredString'];
  meta?: Maybe<Scalars['Map']>;
};

export type GqlCreateAutoReconConfigInput = {
  tillNumber: Scalars['RequiredString'];
  shortCode: Scalars['RequiredString'];
  paymentConfigID: Scalars['UUID'];
  meta?: Maybe<Scalars['Map']>;
};

export type GqlCreatePartyInput = {
  meta?: Maybe<Scalars['Map']>;
  countryID: Scalars['UUID'];
  name: Scalars['RequiredString'];
  partyCode: Scalars['RequiredString'];
  partyProviderModes: Array<GqlCreatePartyProviderModeBulkInput>;
};

export type GqlCreatePartyProviderModeBulkInput = {
  meta?: Maybe<Scalars['Map']>;
  providerModeID: Scalars['UUID'];
  providerData: Scalars['Map'];
  paymentMode: GqlPaymentModeEnum;
};

export type GqlCreatePartyProviderModeInput = {
  meta?: Maybe<Scalars['Map']>;
  providerModeID: Scalars['UUID'];
  partyID: Scalars['UUID'];
  providerData: Scalars['Map'];
  paymentMode: GqlPaymentModeEnum;
};

export type GqlCreatePaymentConfigInput = {
  providerModeID: Scalars['UUID'];
  name: Scalars['RequiredString'];
  meta?: Maybe<Scalars['Map']>;
  serviceName: Scalars['RequiredString'];
  serviceConfig: Scalars['Map'];
  providerConfig: Scalars['Map'];
};

export type GqlCreateProviderInput = {
  countryID: Scalars['UUID'];
  currency: GqlCurrencyEnum;
  meta?: Maybe<Scalars['Map']>;
  name: Scalars['RequiredString'];
  providerCode: Scalars['RequiredString'];
  imageURL?: Maybe<Scalars['String']>;
  isManual: Scalars['Boolean'];
  modes?: Maybe<Array<GqlCreateProviderModeBulkInput>>;
};

export type GqlCreateProviderModeBulkInput = {
  meta?: Maybe<Scalars['Map']>;
  name: Scalars['RequiredString'];
  operationType: GqlOperationTypeEnum;
  minAmount?: Maybe<Scalars['Float']>;
  maxAmount?: Maybe<Scalars['Float']>;
  hasBalance: Scalars['Boolean'];
};

export type GqlCreateProviderModeInput = {
  meta?: Maybe<Scalars['Map']>;
  name: Scalars['RequiredString'];
  providerID: Scalars['UUID'];
  operationType: GqlOperationTypeEnum;
  minAmount?: Maybe<Scalars['Float']>;
  maxAmount?: Maybe<Scalars['Float']>;
  hasBalance: Scalars['Boolean'];
};

export enum GqlCurrencyEnum {
  Kes = 'KES',
  Tzs = 'TZS',
  Ugx = 'UGX'
}

export type GqlGenericResponse = {
  __typename?: 'GenericResponse';
  success: Scalars['Boolean'];
  error: Maybe<Scalars['String']>;
};

export type GqlMakePaymentInput = {
  amount: Scalars['Float'];
  referenceID: Scalars['RequiredString'];
  countryID: Scalars['UUID'];
  currency: GqlCurrencyEnum;
  description?: Maybe<Scalars['String']>;
  paymentConfig: Scalars['RequiredString'];
  accountNumber: Scalars['RequiredString'];
  partyCode?: Maybe<Scalars['RequiredString']>;
  meta?: Maybe<Scalars['Map']>;
};


export enum GqlOperationTypeEnum {
  Inbound = 'INBOUND',
  Outbound = 'OUTBOUND'
}

export type GqlParty = {
  __typename?: 'Party';
  id: Scalars['ID'];
  dateCreated: Scalars['Time'];
  dateUpdated: Scalars['Time'];
  deletedAt: Maybe<Scalars['Time']>;
  active: Scalars['Boolean'];
  createdByID: Scalars['String'];
  updatedByID: Scalars['String'];
  meta: Maybe<Scalars['Map']>;
  countryID: Scalars['String'];
  name: Scalars['String'];
  partyCode: Scalars['String'];
  providerModes: Array<GqlPartyProviderMode>;
};

export type GqlPartyConnection = {
  __typename?: 'PartyConnection';
  pageInfo: GqlPageInfo;
  edges: Array<GqlParty>;
};

export type GqlPartyFilters = {
  ids?: Maybe<Array<Scalars['UUID']>>;
  active?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  countryID?: Maybe<Scalars['UUID']>;
  partyCodes?: Maybe<Array<Scalars['String']>>;
};

export type GqlPartyProviderMode = {
  __typename?: 'PartyProviderMode';
  id: Scalars['ID'];
  dateCreated: Scalars['Time'];
  dateUpdated: Scalars['Time'];
  deletedAt: Maybe<Scalars['Time']>;
  active: Scalars['Boolean'];
  createdByID: Scalars['String'];
  updatedByID: Scalars['String'];
  meta: Maybe<Scalars['Map']>;
  providerModeID: Scalars['String'];
  partyID: Scalars['String'];
  providerData: Scalars['Map'];
  paymentMode: Scalars['String'];
  party: GqlParty;
  providerMode: GqlProviderMode;
};

export type GqlPartyProviderModeConnection = {
  __typename?: 'PartyProviderModeConnection';
  pageInfo: GqlPageInfo;
  edges: Array<GqlPartyProviderMode>;
};

export type GqlPartyProviderModeFilters = {
  ids?: Maybe<Array<Scalars['UUID']>>;
  active?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  paymentMode?: Maybe<Scalars['String']>;
};

export type GqlPayment = {
  __typename?: 'Payment';
  id: Scalars['ID'];
  dateCreated: Scalars['Time'];
  dateUpdated: Scalars['Time'];
  deletedAt: Maybe<Scalars['Time']>;
  active: Scalars['Boolean'];
  createdByID: Scalars['String'];
  updatedByID: Scalars['String'];
  meta: Maybe<Scalars['Map']>;
  countryID: Scalars['String'];
  currency: GqlCurrencyEnum;
  providerID: Scalars['String'];
  providerModeID: Scalars['String'];
  paymentConfigID: Scalars['String'];
  referenceID: Maybe<Scalars['String']>;
  partyID: Maybe<Scalars['String']>;
  accountNumber: Scalars['String'];
  operationType: GqlOperationTypeEnum;
  status: GqlPaymentStatusEnum;
  amount: Scalars['Float'];
  description: Maybe<Scalars['String']>;
  transactionNumber: Maybe<Scalars['String']>;
  transactionID: Maybe<Scalars['String']>;
  transactionRequest: Maybe<Scalars['String']>;
  transactionResponse: Maybe<Scalars['String']>;
  transactionCallback: Maybe<Scalars['String']>;
  transactionStatus: Maybe<Scalars['String']>;
  dateCompleted: Maybe<Scalars['Time']>;
  provider: GqlProvider;
  providerMode: GqlProviderMode;
  paymentConfig: GqlPaymentConfig;
  claims: Array<GqlPaymentClaim>;
};

export type GqlPaymentClaim = {
  __typename?: 'PaymentClaim';
  id: Scalars['ID'];
  dateCreated: Scalars['Time'];
  dateUpdated: Scalars['Time'];
  deletedAt: Maybe<Scalars['Time']>;
  active: Scalars['Boolean'];
  createdByID: Scalars['String'];
  updatedByID: Scalars['String'];
  meta: Maybe<Scalars['Map']>;
  paymentID: Scalars['String'];
  payment: GqlPayment;
  claimedBy: Scalars['String'];
  amount: Scalars['Float'];
  referenceID: Scalars['String'];
};

export type GqlPaymentClaimConnection = {
  __typename?: 'PaymentClaimConnection';
  pageInfo: GqlPageInfo;
  edges: Array<GqlPaymentClaim>;
};

export type GqlPaymentClaimFilters = {
  ids?: Maybe<Array<Scalars['UUID']>>;
  active?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  paymentID?: Maybe<Scalars['UUID']>;
  claimedBy?: Maybe<Scalars['String']>;
  referenceID?: Maybe<Scalars['String']>;
};

export type GqlPaymentConfig = {
  __typename?: 'PaymentConfig';
  id: Scalars['ID'];
  dateCreated: Scalars['Time'];
  dateUpdated: Scalars['Time'];
  deletedAt: Maybe<Scalars['Time']>;
  active: Scalars['Boolean'];
  createdByID: Scalars['String'];
  updatedByID: Scalars['String'];
  meta: Maybe<Scalars['Map']>;
  name: Scalars['String'];
  serviceName: Scalars['String'];
  serviceConfig: Scalars['Map'];
  providerConfig: Scalars['Map'];
  providerModeID: Scalars['String'];
  providerMode: GqlProviderMode;
};

export type GqlPaymentConfigConnection = {
  __typename?: 'PaymentConfigConnection';
  pageInfo: GqlPageInfo;
  edges: Array<GqlPaymentConfig>;
};

export type GqlPaymentConfigFilters = {
  ids?: Maybe<Array<Scalars['UUID']>>;
  active?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  providerModeID?: Maybe<Scalars['UUID']>;
  providerID?: Maybe<Scalars['UUID']>;
};

export type GqlPaymentConnection = {
  __typename?: 'PaymentConnection';
  pageInfo: GqlPageInfo;
  edges: Array<GqlPayment>;
};

export type GqlPaymentFilters = {
  ids?: Maybe<Array<Scalars['UUID']>>;
  active?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  countryID?: Maybe<Scalars['UUID']>;
  currency?: Maybe<GqlCurrencyEnum>;
  providerID?: Maybe<Scalars['UUID']>;
  providerModeID?: Maybe<Scalars['UUID']>;
  paymentConfigID?: Maybe<Scalars['UUID']>;
  referenceID?: Maybe<Scalars['UUID']>;
  partyID?: Maybe<Scalars['UUID']>;
  account_number?: Maybe<Scalars['String']>;
  operation_type?: Maybe<GqlOperationTypeEnum>;
  status?: Maybe<GqlPaymentStatusEnum>;
};

export enum GqlPaymentModeEnum {
  Mobile = 'MOBILE',
  Bank = 'BANK',
  Manual = 'MANUAL'
}

export enum GqlPaymentStatusEnum {
  Pending = 'PENDING',
  InProgress = 'IN_PROGRESS',
  Cancelled = 'CANCELLED',
  Successful = 'SUCCESSFUL',
  Failed = 'FAILED',
  Rejected = 'REJECTED',
  Reversed = 'REVERSED'
}

export type GqlProvider = {
  __typename?: 'Provider';
  id: Scalars['ID'];
  dateCreated: Scalars['Time'];
  dateUpdated: Scalars['Time'];
  deletedAt: Maybe<Scalars['Time']>;
  active: Scalars['Boolean'];
  createdByID: Scalars['String'];
  updatedByID: Scalars['String'];
  countryID: Scalars['String'];
  currency: GqlCurrencyEnum;
  meta: Maybe<Scalars['Map']>;
  name: Scalars['String'];
  providerCode: Scalars['String'];
  imageURL: Maybe<Scalars['String']>;
  isManual: Scalars['Boolean'];
  modes: Array<GqlProviderMode>;
};

export type GqlProviderMode = {
  __typename?: 'ProviderMode';
  id: Scalars['ID'];
  dateCreated: Scalars['Time'];
  dateUpdated: Scalars['Time'];
  deletedAt: Maybe<Scalars['Time']>;
  active: Scalars['Boolean'];
  createdByID: Scalars['String'];
  updatedByID: Scalars['String'];
  meta: Maybe<Scalars['Map']>;
  name: Scalars['String'];
  providerID: Scalars['String'];
  operationType: Scalars['String'];
  minAmount: Maybe<Scalars['Float']>;
  maxAmount: Maybe<Scalars['Float']>;
  hasBalance: Scalars['Boolean'];
  balance: Maybe<Scalars['Float']>;
  provider: GqlProvider;
};


export type GqlUpdatePartyInput = {
  meta?: Maybe<Scalars['Map']>;
  name?: Maybe<Scalars['RequiredString']>;
  partyCode?: Maybe<Scalars['RequiredString']>;
  active?: Maybe<Scalars['Boolean']>;
};

export type GqlUpdatePartyProviderModeInput = {
  meta?: Maybe<Scalars['Map']>;
  providerData?: Maybe<Scalars['Map']>;
  active?: Maybe<Scalars['Boolean']>;
};

export type GqlAgentDailyOrderSummaryGql = {
  __typename?: 'AgentDailyOrderSummaryGQL';
  agent_id: Maybe<Scalars['String']>;
  delivery_date: Maybe<Scalars['String']>;
  product_item_id: Maybe<Scalars['String']>;
  product_name: Maybe<Scalars['String']>;
  product_item_name: Maybe<Scalars['String']>;
  product_item_segment: Maybe<Scalars['String']>;
  total_ordered_weight: Maybe<Scalars['Float']>;
  total_potential_pay: Maybe<Scalars['Float']>;
};

export type GqlAgentDailyOrderSummaryGqlConnection = {
  __typename?: 'AgentDailyOrderSummaryGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlAgentDailyOrderSummaryGqlEdge>>;
};

/** A Relay edge containing a `AgentDailyOrderSummaryGQL` and its cursor. */
export type GqlAgentDailyOrderSummaryGqlEdge = {
  __typename?: 'AgentDailyOrderSummaryGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlAgentDailyOrderSummaryGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlAgentEarningsGql = {
  __typename?: 'AgentEarningsGQL';
  agent_id: Maybe<Scalars['String']>;
  date: Maybe<Scalars['String']>;
  total_amount: Maybe<Scalars['Float']>;
  earnings_attributes: Maybe<Array<Maybe<GqlEarningsAttributesGql>>>;
};

export type GqlAgentEarningsSummaryGql = {
  __typename?: 'AgentEarningsSummaryGQL';
  agent_id: Maybe<Scalars['String']>;
  start_date: Maybe<Scalars['String']>;
  end_date: Maybe<Scalars['String']>;
  total_amount: Maybe<Scalars['Float']>;
  earnings_attributes: Maybe<Array<Maybe<GqlEarningsAttributesGql>>>;
};

/** Area GQL. */
export type GqlAreaV2Gql = {
  __typename?: 'AreaV2GQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  area_id: Scalars['String'];
  region_id: Scalars['String'];
  area_name: Maybe<Scalars['String']>;
  area_manager: Maybe<Scalars['String']>;
  region: Maybe<GqlRegionV2Gql>;
  depots: Maybe<Array<Maybe<GqlDepotV2Gql>>>;
};

/** Node and relay Connection. */
export type GqlAreaV2GqlConnection = {
  __typename?: 'AreaV2GQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlAreaV2GqlEdge>>;
};

/** A Relay edge containing a `AreaV2GQL` and its cursor. */
export type GqlAreaV2GqlEdge = {
  __typename?: 'AreaV2GQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlAreaV2Gql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum GqlBillingCycleEnum {
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  Annual = 'ANNUAL'
}

export type GqlCashBackGql = {
  __typename?: 'CashBackGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  cashback_id: Scalars['String'];
  amount: Scalars['Float'];
  currency: GqlCurrencyEnum;
  customer_name: Maybe<Scalars['String']>;
  account_id: Scalars['String'];
  order_id: Scalars['String'];
  phone_number: Maybe<Scalars['String']>;
  cashback_date: Scalars['String'];
  cashback_type: Maybe<Scalars['String']>;
  credited_amount: Maybe<Scalars['Float']>;
};

export type GqlCashBackGqlConnection = {
  __typename?: 'CashBackGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlCashBackGqlEdge>>;
};

/** A Relay edge containing a `CashBackGQL` and its cursor. */
export type GqlCashBackGqlEdge = {
  __typename?: 'CashBackGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlCashBackGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** Mcommerce Channel Enum */
export enum GqlChannelEnum {
  Sokocall = 'SOKOCALL',
  Sokoyetu = 'SOKOYETU',
  Mcommerce = 'MCOMMERCE',
  Dms = 'DMS',
  Courier = 'COURIER',
  Pwa = 'PWA'
}

export type GqlClusterAgentEarningGql = {
  __typename?: 'ClusterAgentEarningGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  agent_earning_id: Scalars['String'];
  cluster_id: Scalars['String'];
  cluster_code: Scalars['String'];
  depot_id: Maybe<Scalars['String']>;
  delivery_date: Scalars['String'];
  shop_id: Scalars['String'];
  product_item_segment: Scalars['String'];
  ordered_weight: Maybe<Scalars['Float']>;
  delivered_weight: Maybe<Scalars['Float']>;
  potential_pay: Maybe<Scalars['Float']>;
  unlocked_pay: Maybe<Scalars['Float']>;
  agent_id: Maybe<Scalars['String']>;
  product_item_id: Scalars['String'];
  product_name: Scalars['String'];
  product_item_name: Scalars['String'];
};

export type GqlClusterAgentEarningGqlConnection = {
  __typename?: 'ClusterAgentEarningGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlClusterAgentEarningGqlEdge>>;
};

/** A Relay edge containing a `ClusterAgentEarningGQL` and its cursor. */
export type GqlClusterAgentEarningGqlEdge = {
  __typename?: 'ClusterAgentEarningGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlClusterAgentEarningGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlClusterAssignmentGql = {
  __typename?: 'ClusterAssignmentGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  assignment_id: Scalars['String'];
  cluster_id: Scalars['String'];
  agent_id: Scalars['String'];
  date: Scalars['String'];
  start_time: Maybe<Scalars['DateTime']>;
  end_time: Maybe<Scalars['DateTime']>;
  cluster: Maybe<GqlSalesAgentClusterGql>;
  agent: Maybe<GqlUserV2Gql>;
};

export type GqlClusterAssignmentGqlConnection = {
  __typename?: 'ClusterAssignmentGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlClusterAssignmentGqlEdge>>;
};

/** A Relay edge containing a `ClusterAssignmentGQL` and its cursor. */
export type GqlClusterAssignmentGqlEdge = {
  __typename?: 'ClusterAssignmentGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlClusterAssignmentGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlClusterEarningSummaryGql = {
  __typename?: 'ClusterEarningSummaryGQL';
  agent_id: Maybe<Scalars['String']>;
  total_ordered_weight: Maybe<Scalars['Float']>;
  total_delivered_weight: Maybe<Scalars['Float']>;
  total_potential_pay: Maybe<Scalars['Float']>;
  total_unlocked_pay: Maybe<Scalars['Float']>;
  delivery_date: Maybe<Scalars['String']>;
  start_date: Maybe<Scalars['String']>;
  end_date: Maybe<Scalars['String']>;
};

export type GqlCountryGql = {
  __typename?: 'CountryGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  country_id: Maybe<Scalars['String']>;
  iso_alpha2_code: GqlIsoAlpha2Enum;
  iso_alpha2_name: Scalars['String'];
  default_currency: GqlCurrencyEnum;
  country_calling_code: Scalars['String'];
  company_legal_entity: Scalars['String'];
  default_language_code: Scalars['String'];
  timezone_utc_offset: Scalars['Int'];
  regions: Maybe<Array<Maybe<GqlRegionV2Gql>>>;
};

export type GqlCoverTypeGql = {
  __typename?: 'CoverTypeGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  cover_type_id: Scalars['String'];
  type: Scalars['String'];
  insurer_id: Scalars['String'];
};

export type GqlCoverTypeGqlConnection = {
  __typename?: 'CoverTypeGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlCoverTypeGqlEdge>>;
};

/** A Relay edge containing a `CoverTypeGQL` and its cursor. */
export type GqlCoverTypeGqlEdge = {
  __typename?: 'CoverTypeGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlCoverTypeGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlCustomerTypeV2Gql = {
  __typename?: 'CustomerTypeV2GQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  customer_type_id: Scalars['String'];
  name: Scalars['String'];
};

export type GqlCustomerV2Gql = {
  __typename?: 'CustomerV2GQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  customer_id: Scalars['String'];
  customer_name: Scalars['String'];
  phone_number: Scalars['String'];
  approved: Scalars['Boolean'];
  approved_by: Maybe<Scalars['String']>;
  approved_date: Maybe<Scalars['DateTime']>;
  shops: Maybe<Array<Maybe<GqlShopV2Gql>>>;
  vendor_kyc: Maybe<GqlVendorKycgql>;
  account: Maybe<GqlMcommerceAccountGql>;
};

/** Pagination Connection for CustomerV2GQL */
export type GqlCustomerV2GqlConnection = {
  __typename?: 'CustomerV2GQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlCustomerV2GqlEdge>>;
};

/** A Relay edge containing a `CustomerV2GQL` and its cursor. */
export type GqlCustomerV2GqlEdge = {
  __typename?: 'CustomerV2GQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlCustomerV2Gql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlDailyOtifgql = {
  __typename?: 'DailyOTIFGQL';
  delivery_date: Maybe<Scalars['String']>;
  region_name: Maybe<Scalars['String']>;
  depot_name: Maybe<Scalars['String']>;
  route_name: Maybe<Scalars['String']>;
  shop_name: Maybe<Scalars['String']>;
  phone_number: Maybe<Scalars['String']>;
  product_item_id: Maybe<Scalars['String']>;
  product_item: Maybe<GqlProductItemV2Gql>;
  source: Maybe<Scalars['String']>;
  delivery_time: Maybe<Scalars['String']>;
  payment_mode: Maybe<Scalars['String']>;
  ordered_quantity: Maybe<Scalars['Float']>;
  delivered_quantity: Maybe<Scalars['Float']>;
  ordered_amount: Maybe<Scalars['Float']>;
  delivery_amount: Maybe<Scalars['Float']>;
  fill_rate: Maybe<Scalars['Float']>;
  on_time: Maybe<Scalars['String']>;
};

export type GqlDailyOtifgqlConnection = {
  __typename?: 'DailyOTIFGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlDailyOtifgqlEdge>>;
};

/** A Relay edge containing a `DailyOTIFGQL` and its cursor. */
export type GqlDailyOtifgqlEdge = {
  __typename?: 'DailyOTIFGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlDailyOtifgql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlDailyOtifSummaryGql = {
  __typename?: 'DailyOTIFSummaryGQL';
  delivery_amount: Maybe<Scalars['Float']>;
  delivered_quantity: Maybe<Scalars['Float']>;
  ordered_amount: Maybe<Scalars['Float']>;
  ordered_quantity: Maybe<Scalars['Float']>;
  on_time: Maybe<Scalars['Float']>;
  in_full: Maybe<Scalars['Float']>;
  otif: Maybe<Scalars['Float']>;
};


/** DeliveryFeeSchedule GQL. */
export type GqlDeliveryFeeScheduleGql = {
  __typename?: 'DeliveryFeeScheduleGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  schedule_id: Scalars['String'];
  route_id: Scalars['String'];
  lower_bound: Scalars['Float'];
  upper_bound: Scalars['Float'];
  fee: Scalars['Float'];
  route: Maybe<GqlRouteV2Gql>;
};

/** Node and relay Connection. */
export type GqlDeliveryFeeScheduleGqlConnection = {
  __typename?: 'DeliveryFeeScheduleGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlDeliveryFeeScheduleGqlEdge>>;
};

/** A Relay edge containing a `DeliveryFeeScheduleGQL` and its cursor. */
export type GqlDeliveryFeeScheduleGqlEdge = {
  __typename?: 'DeliveryFeeScheduleGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlDeliveryFeeScheduleGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** Delivery item GQL. */
export type GqlDeliveryItemV2Gql = {
  __typename?: 'DeliveryItemV2GQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  delivery_item_id: Scalars['String'];
  delivery_id: Scalars['String'];
  product_item_id: Scalars['String'];
  unit_of_measure_id: Maybe<Scalars['String']>;
  delivery_date: Scalars['String'];
  amount: Scalars['Float'];
  currency: GqlCurrencyEnum;
  quantity: Scalars['Float'];
  weight: Scalars['Float'];
  packed_weight: Maybe<Scalars['Float']>;
  packed_volume: Maybe<Scalars['Float']>;
  unit_price: Scalars['Float'];
  comment: Maybe<Scalars['String']>;
  delivery: Maybe<GqlDeliveryV2Gql>;
  warranties: Maybe<Array<Maybe<GqlDeliveryItemWarrantyGql>>>;
  product_item: Maybe<GqlProductItemV2Gql>;
  uom: Maybe<GqlUomv2Gql>;
};

/** Node and relay Connection. */
export type GqlDeliveryItemV2GqlConnection = {
  __typename?: 'DeliveryItemV2GQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlDeliveryItemV2GqlEdge>>;
};

/** A Relay edge containing a `DeliveryItemV2GQL` and its cursor. */
export type GqlDeliveryItemV2GqlEdge = {
  __typename?: 'DeliveryItemV2GQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlDeliveryItemV2Gql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** Delivery item warranty GQL. */
export type GqlDeliveryItemWarrantyGql = {
  __typename?: 'DeliveryItemWarrantyGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  warranty_id: Scalars['String'];
  delivery_item_id: Scalars['String'];
  serial_number: Scalars['String'];
  image: Maybe<Scalars['String']>;
  image_url: Maybe<Scalars['String']>;
  country_id: Maybe<Scalars['String']>;
  delivery_item: Maybe<GqlDeliveryItemV2Gql>;
};

/** Node and relay Connection. */
export type GqlDeliveryItemWarrantyGqlConnection = {
  __typename?: 'DeliveryItemWarrantyGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlDeliveryItemWarrantyGqlEdge>>;
};

/** A Relay edge containing a `DeliveryItemWarrantyGQL` and its cursor. */
export type GqlDeliveryItemWarrantyGqlEdge = {
  __typename?: 'DeliveryItemWarrantyGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlDeliveryItemWarrantyGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum GqlDeliveryProcessingFeeEntityEnum {
  Cluster = 'CLUSTER',
  Shop = 'SHOP',
  Depot = 'DEPOT'
}

export type GqlDeliveryProcessingFeeGql = {
  __typename?: 'DeliveryProcessingFeeGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  processing_fee_id: Scalars['String'];
  reference_entity_id: Scalars['String'];
  reference_entity: Maybe<GqlDeliveryProcessingFeeEntityEnum>;
  delivery_fee: Scalars['Float'];
  processing_fee: Scalars['Float'];
};

export type GqlDeliveryProcessingFeeGqlConnection = {
  __typename?: 'DeliveryProcessingFeeGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlDeliveryProcessingFeeGqlEdge>>;
};

/** A Relay edge containing a `DeliveryProcessingFeeGQL` and its cursor. */
export type GqlDeliveryProcessingFeeGqlEdge = {
  __typename?: 'DeliveryProcessingFeeGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlDeliveryProcessingFeeGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlDeliveryProductSummaryV2Gql = {
  __typename?: 'DeliveryProductSummaryV2GQL';
  date: Maybe<Scalars['String']>;
  product_item_id: Maybe<Scalars['String']>;
  revenue: Maybe<Scalars['Float']>;
  quantity: Maybe<Scalars['Float']>;
  unit_price: Maybe<Scalars['Float']>;
  unit_of_measure_id: Maybe<Scalars['String']>;
};

/** Node and relay Connection. */
export type GqlDeliveryProductSummaryV2GqlConnection = {
  __typename?: 'DeliveryProductSummaryV2GQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlDeliveryProductSummaryV2GqlEdge>>;
};

/** A Relay edge containing a `DeliveryProductSummaryV2GQL` and its cursor. */
export type GqlDeliveryProductSummaryV2GqlEdge = {
  __typename?: 'DeliveryProductSummaryV2GQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlDeliveryProductSummaryV2Gql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** DeliveryReturn GQL. */
export type GqlDeliveryReturnGql = {
  __typename?: 'DeliveryReturnGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  return_date: Scalars['String'];
  return_number: Scalars['String'];
  user_id: Scalars['String'];
  route_plan_id: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  currency: GqlCurrencyEnum;
  delivery_id: Scalars['String'];
  claim_processed: Scalars['Boolean'];
  claim_processed_at: Maybe<Scalars['DateTime']>;
  claim_processed_by: Maybe<Scalars['String']>;
  return_id: Scalars['String'];
  shop_id: Scalars['String'];
  route_id: Maybe<Scalars['String']>;
  country_id: Maybe<Scalars['String']>;
  return_items: Maybe<Array<Maybe<GqlDeliveryReturnItemGql>>>;
  delivery: Maybe<GqlDeliveryV2Gql>;
  shop: Maybe<GqlShopV2Gql>;
  refund_status: Maybe<Scalars['String']>;
};

/** Node and relay Connection. */
export type GqlDeliveryReturnGqlConnection = {
  __typename?: 'DeliveryReturnGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlDeliveryReturnGqlEdge>>;
};

/** A Relay edge containing a `DeliveryReturnGQL` and its cursor. */
export type GqlDeliveryReturnGqlEdge = {
  __typename?: 'DeliveryReturnGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlDeliveryReturnGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** Delivery item return GQL. */
export type GqlDeliveryReturnItemGql = {
  __typename?: 'DeliveryReturnItemGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  return_item_id: Scalars['String'];
  unit_of_measure_id: Maybe<Scalars['String']>;
  return_date: Scalars['String'];
  quantity: Scalars['Float'];
  weight: Scalars['Float'];
  comment: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  currency: GqlCurrencyEnum;
  unit_price: Scalars['Float'];
  image: Maybe<Scalars['String']>;
  return_id: Scalars['String'];
  approved: Maybe<Scalars['Boolean']>;
  approved_at: Maybe<Scalars['DateTime']>;
  approved_by: Maybe<Scalars['String']>;
  approved_amount: Maybe<Scalars['Float']>;
  approved_quantity: Maybe<Scalars['Float']>;
  rejection_reason_id: Maybe<Scalars['String']>;
  product_item_id: Scalars['String'];
  reason_id: Maybe<Scalars['String']>;
  rejection_reason: Maybe<GqlReturnItemRejectionReasonGql>;
  reason: Maybe<GqlReturnReasonGql>;
  delivery_return: Maybe<GqlDeliveryReturnGql>;
  signed_image_url: Maybe<Scalars['String']>;
  product_item: Maybe<GqlProductItemV2Gql>;
  uom: Maybe<GqlUomv2Gql>;
  approved_by_user: Maybe<GqlUserV2Gql>;
};

/** Node and relay Connection. */
export type GqlDeliveryReturnItemGqlConnection = {
  __typename?: 'DeliveryReturnItemGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlDeliveryReturnItemGqlEdge>>;
};

/** A Relay edge containing a `DeliveryReturnItemGQL` and its cursor. */
export type GqlDeliveryReturnItemGqlEdge = {
  __typename?: 'DeliveryReturnItemGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlDeliveryReturnItemGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** Delivery Schedule GQL. */
export type GqlDeliveryScheduleGql = {
  __typename?: 'DeliveryScheduleGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  schedule_id: Scalars['String'];
  delivery_days: Array<Maybe<Scalars['Int']>>;
  description: Scalars['String'];
  routes: Maybe<Array<Maybe<GqlRouteV2Gql>>>;
  shops: Maybe<Array<Maybe<GqlShopV2Gql>>>;
};

/** Delivery Status Enum */
export enum GqlDeliveryStatusEnum {
  Delivered = 'DELIVERED',
  Confirmed = 'CONFIRMED',
  PartiallyPaid = 'PARTIALLY_PAID',
  FullyPaid = 'FULLY_PAID'
}

/** Delivery Status GQL. */
export type GqlDeliveryStatusV2Gql = {
  __typename?: 'DeliveryStatusV2GQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  delivery_status_id: Scalars['String'];
  delivery_id: Scalars['String'];
  delivery_status: GqlDeliveryStatusEnum;
  delivery: Maybe<GqlDeliveryV2Gql>;
};

/** Delivery GQL. */
export type GqlDeliveryV2Gql = {
  __typename?: 'DeliveryV2GQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  delivery_id: Scalars['String'];
  order_id: Maybe<Scalars['String']>;
  depot_id: Maybe<Scalars['String']>;
  region_id: Maybe<Scalars['String']>;
  shop_id: Scalars['String'];
  route_id: Maybe<Scalars['String']>;
  trader_entity_id: Maybe<Scalars['String']>;
  delivery_date: Scalars['String'];
  route_plan_id: Maybe<Scalars['String']>;
  delivery_number: Scalars['String'];
  amount: Scalars['Float'];
  currency: GqlCurrencyEnum;
  comment: Maybe<Scalars['String']>;
  version: Maybe<Scalars['String']>;
  priority: Maybe<Scalars['Boolean']>;
  cancellation_reason: Maybe<Scalars['String']>;
  trader_entity_user_id: Scalars['String'];
  trader_entity: GqlTraderEntityEnum;
  delivery_fee: Scalars['Float'];
  processing_fee: Scalars['Float'];
  country_id: Maybe<Scalars['String']>;
  shop: Maybe<GqlShopV2Gql>;
  route: Maybe<GqlRouteV2Gql>;
  payments: Maybe<Array<Maybe<GqlPaymentV2Gql>>>;
  delivery_status: Maybe<Array<Maybe<GqlDeliveryStatusV2Gql>>>;
  order: Maybe<GqlOrderV2Gql>;
  depot: Maybe<GqlDepotV2Gql>;
  delivery_items: Maybe<Array<Maybe<GqlDeliveryItemV2Gql>>>;
  delivery_returns: Maybe<Array<Maybe<GqlDeliveryReturnGql>>>;
  total_amount: Maybe<Scalars['Float']>;
  served_by: Maybe<GqlUserV2Gql>;
};

/** Node and relay Connection. */
export type GqlDeliveryV2GqlConnection = {
  __typename?: 'DeliveryV2GQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlDeliveryV2GqlEdge>>;
  served_by: Maybe<GqlUserV2Gql>;
};

/** A Relay edge containing a `DeliveryV2GQL` and its cursor. */
export type GqlDeliveryV2GqlEdge = {
  __typename?: 'DeliveryV2GQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlDeliveryV2Gql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlDeliveryVehicleManifestSummaryGql = {
  __typename?: 'DeliveryVehicleManifestSummaryGQL';
  total_ordered_quantity: Maybe<Scalars['Int']>;
  total_ordered_weight: Maybe<Scalars['Float']>;
  total_ordered_amount: Maybe<Scalars['Float']>;
};

export type GqlDepotDcProductItemRestrictionGql = {
  __typename?: 'DepotDCProductItemRestrictionGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  depot_dc_product_item_restriction_id: Scalars['String'];
  product_item_id: Scalars['String'];
  depot_id: Scalars['String'];
  distribution_center_ids: Array<Maybe<Scalars['String']>>;
  depot: Maybe<GqlDepotV2Gql>;
  product_item: Maybe<GqlProductItemV2Gql>;
  distribution_centers: Array<Maybe<GqlDistributionCenterGql>>;
};

export type GqlDepotDcProductItemRestrictionGqlConnection = {
  __typename?: 'DepotDCProductItemRestrictionGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlDepotDcProductItemRestrictionGqlEdge>>;
};

/** A Relay edge containing a `DepotDCProductItemRestrictionGQL` and its cursor. */
export type GqlDepotDcProductItemRestrictionGqlEdge = {
  __typename?: 'DepotDCProductItemRestrictionGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlDepotDcProductItemRestrictionGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** Depot Distribution Center GQL. */
export type GqlDepotDistributionCenterGql = {
  __typename?: 'DepotDistributionCenterGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  depot_distribution_center_id: Scalars['String'];
  depot_id: Scalars['String'];
  distribution_center_id: Scalars['String'];
  distribution_center_type: GqlDistributionCenterType;
  depot: Maybe<GqlDepotV2Gql>;
};

export type GqlDepotRoutePlanSummaryGql = {
  __typename?: 'DepotRoutePlanSummaryGQL';
  depot_id: Maybe<Scalars['String']>;
  delivery_date: Maybe<Scalars['String']>;
  planned_orders: Maybe<Scalars['Int']>;
  vehicles: Maybe<Scalars['Int']>;
};

export type GqlDepotRoutingSummaryGql = {
  __typename?: 'DepotRoutingSummaryGQL';
  depot_id: Maybe<Scalars['String']>;
  depot_name: Maybe<Scalars['String']>;
  sale_date: Maybe<Scalars['String']>;
  route_plans: Maybe<Scalars['Int']>;
  total_orders: Maybe<Scalars['Int']>;
  routed_orders: Maybe<Scalars['Int']>;
  total_ordered_revenue: Maybe<Scalars['Float']>;
  total_routed_revenue: Maybe<Scalars['Float']>;
  total_ordered_weight: Maybe<Scalars['Float']>;
  total_routed_weight: Maybe<Scalars['Float']>;
};

/** Node and relay Connection. */
export type GqlDepotRoutingSummaryGqlConnection = {
  __typename?: 'DepotRoutingSummaryGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlDepotRoutingSummaryGqlEdge>>;
};

/** A Relay edge containing a `DepotRoutingSummaryGQL` and its cursor. */
export type GqlDepotRoutingSummaryGqlEdge = {
  __typename?: 'DepotRoutingSummaryGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlDepotRoutingSummaryGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlDepotV2Gql = {
  __typename?: 'DepotV2GQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  depot_id: Scalars['String'];
  area_id: Maybe<Scalars['String']>;
  depot_name: Maybe<Scalars['String']>;
  area_sales_manager: Maybe<Scalars['String']>;
  depot_clerk: Maybe<Scalars['String']>;
  latitude: Maybe<Scalars['Float']>;
  longitude: Maybe<Scalars['Float']>;
  current_latitude: Maybe<Scalars['Float']>;
  current_longitude: Maybe<Scalars['Float']>;
  depot_fulfillment_officer: Maybe<Scalars['String']>;
  country_id: Maybe<Scalars['String']>;
  area: Maybe<GqlAreaV2Gql>;
  distribution_centers: Maybe<Array<Maybe<GqlDepotDistributionCenterGql>>>;
  routes: Maybe<Array<Maybe<GqlRouteV2Gql>>>;
  depot_dc_product_item_restrictions: Maybe<Array<Maybe<GqlDepotDcProductItemRestrictionGql>>>;
  user: Maybe<GqlUserV2Gql>;
  clerk: Maybe<GqlUserV2Gql>;
  dfo: Maybe<GqlUserV2Gql>;
  route_plan_summary: Maybe<GqlDepotRoutePlanSummaryGql>;
};


export type GqlDepotV2GqlRoute_Plan_SummaryArgs = {
  delivery_date: Scalars['String'];
};

/** Node and relay Connection. */
export type GqlDepotV2GqlConnection = {
  __typename?: 'DepotV2GQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlDepotV2GqlEdge>>;
};

/** A Relay edge containing a `DepotV2GQL` and its cursor. */
export type GqlDepotV2GqlEdge = {
  __typename?: 'DepotV2GQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlDepotV2Gql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlDistributionCenterGql = {
  __typename?: 'DistributionCenterGQL';
  distribution_center_id: Scalars['String'];
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  distribution_center_name: Scalars['String'];
  description: Maybe<Scalars['String']>;
  branch_id: Maybe<Scalars['String']>;
  group_email: Scalars['String'];
  distribution_center_type: GqlDistributionCenterType;
  sar_number: Scalars['Float'];
  warehouses: Maybe<Array<Maybe<GqlWarehouseGql>>>;
  intakes: Maybe<Array<Maybe<GqlStockIntakeGql>>>;
  stock_counts: Maybe<Array<Maybe<GqlStockTakeGql>>>;
  processing_losses: Maybe<Array<Maybe<GqlProcessingLossGql>>>;
  appointments: Maybe<Array<Maybe<GqlAppointmentGql>>>;
  stock_availability: Maybe<GqlStockAvailabilityGqLv2Connection>;
};


export type GqlDistributionCenterGqlStock_AvailabilityArgs = {
  before: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
};

export type GqlDistributionCenterOrderSummaryGql = {
  __typename?: 'DistributionCenterOrderSummaryGQL';
  product_item_id: Maybe<Scalars['String']>;
  sale_date: Maybe<Scalars['String']>;
  quantity: Maybe<Scalars['Float']>;
  distribution_center_id: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum GqlDistributionCenterType {
  Warehouse = 'WAREHOUSE',
  CrossDock = 'CROSS_DOCK'
}

export type GqlEarningsAttributesGql = {
  __typename?: 'EarningsAttributesGQL';
  attribute_id: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  total_value: Maybe<Scalars['Float']>;
  unit: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum GqlGenderEnum {
  Male = 'MALE',
  Female = 'FEMALE',
  Other = 'OTHER'
}

export type GqlInsurerGql = {
  __typename?: 'InsurerGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  insurer_id: Scalars['String'];
  insurer_name: Scalars['String'];
};

/** ISO Alpha2 Code Enum */
export enum GqlIsoAlpha2Enum {
  Ke = 'KE',
  Tz = 'TZ',
  Ug = 'UG'
}

/** Legal Document Enum */
export enum GqlLegalDocumentEnum {
  NationalId = 'NATIONAL_ID',
  Passport = 'PASSPORT'
}

export type GqlMcommerceAccountGql = {
  __typename?: 'McommerceAccountGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  account_id: Scalars['String'];
  shop_id: Maybe<Scalars['String']>;
  customer_id: Maybe<Scalars['String']>;
  user_name: Maybe<Scalars['String']>;
  change_password: Scalars['Boolean'];
  terms_date: Maybe<Scalars['DateTime']>;
  customer: Maybe<GqlCustomerV2Gql>;
};

export type GqlMcommerceFaqgql = {
  __typename?: 'McommerceFAQGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  faq_id: Scalars['String'];
  question: Scalars['String'];
  answer: Scalars['String'];
  faq_section_id: Scalars['String'];
  image_urls: Maybe<Array<Maybe<Scalars['String']>>>;
  faq_section: Maybe<GqlMcommerceFaqSectionGql>;
};

export type GqlMcommerceFaqgqlConnection = {
  __typename?: 'McommerceFAQGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlMcommerceFaqgqlEdge>>;
};

/** A Relay edge containing a `McommerceFAQGQL` and its cursor. */
export type GqlMcommerceFaqgqlEdge = {
  __typename?: 'McommerceFAQGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlMcommerceFaqgql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlMcommerceFaqSectionGql = {
  __typename?: 'McommerceFAQSectionGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  faq_section_id: Scalars['String'];
  name: Scalars['String'];
  icon_url: Maybe<Scalars['String']>;
  faqs: Maybe<Array<Maybe<GqlMcommerceFaqgql>>>;
};

export type GqlMultipleStockOrderedArgs = {
  product_item_id?: Maybe<Scalars['String']>;
  distribution_center_ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  sale_date?: Maybe<Scalars['String']>;
  depot_id: Scalars['String'];
  delivery_date?: Maybe<Scalars['String']>;
};

export type GqlNextOfKinGql = {
  __typename?: 'NextOfKinGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  next_of_kin_id: Scalars['String'];
  full_name: Scalars['String'];
  mobile_number: Scalars['String'];
  relationship: GqlRelationshipEnum;
  gender: GqlGenderEnum;
  date_of_birth: Scalars['String'];
  id_number: Maybe<Scalars['String']>;
  principal_member_id: Scalars['String'];
};

export type GqlOrderAgainGql = {
  __typename?: 'OrderAgainGQL';
  product_items: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Order Assignment GQL. */
export type GqlOrderAssignmentV2Gql = {
  __typename?: 'OrderAssignmentV2GQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  assignment_id: Scalars['String'];
  order_id: Scalars['String'];
  trader_entity_id: Scalars['String'];
  trader_entity_user_id: Scalars['String'];
  sale_date: Scalars['String'];
  fulfilled: Scalars['Boolean'];
  order: Maybe<GqlOrderV2Gql>;
};

/** Node and relay Connection. */
export type GqlOrderAssignmentV2GqlConnection = {
  __typename?: 'OrderAssignmentV2GQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlOrderAssignmentV2GqlEdge>>;
};

/** A Relay edge containing a `OrderAssignmentV2GQL` and its cursor. */
export type GqlOrderAssignmentV2GqlEdge = {
  __typename?: 'OrderAssignmentV2GQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlOrderAssignmentV2Gql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** Order item GQL. */
export type GqlOrderItemV2Gql = {
  __typename?: 'OrderItemV2GQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  order_item_id: Scalars['String'];
  order_id: Scalars['String'];
  product_item_id: Scalars['String'];
  fulfilled: Scalars['Boolean'];
  unit_of_measure_id: Maybe<Scalars['String']>;
  order_date: Scalars['String'];
  amount: Scalars['Float'];
  currency: GqlCurrencyEnum;
  quantity: Scalars['Float'];
  weight: Scalars['Float'];
  unit_price: Scalars['Float'];
  comment: Maybe<Scalars['String']>;
  packed_weight: Maybe<Scalars['Float']>;
  packed_volume: Maybe<Scalars['Float']>;
  order: Maybe<GqlOrderV2Gql>;
  product_item: Maybe<GqlProductItemV2Gql>;
  uom: Maybe<GqlUomv2Gql>;
};

/** Node and relay Connection. */
export type GqlOrderItemV2GqlConnection = {
  __typename?: 'OrderItemV2GQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlOrderItemV2GqlEdge>>;
};

/** A Relay edge containing a `OrderItemV2GQL` and its cursor. */
export type GqlOrderItemV2GqlEdge = {
  __typename?: 'OrderItemV2GQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlOrderItemV2Gql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlOrderProductSummaryV2Gql = {
  __typename?: 'OrderProductSummaryV2GQL';
  date: Maybe<Scalars['String']>;
  product_item_id: Maybe<Scalars['String']>;
  revenue: Maybe<Scalars['Float']>;
  quantity: Maybe<Scalars['Float']>;
  unit_price: Maybe<Scalars['Float']>;
  unit_of_measure_id: Maybe<Scalars['String']>;
};

/** Node and relay Connection. */
export type GqlOrderProductSummaryV2GqlConnection = {
  __typename?: 'OrderProductSummaryV2GQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlOrderProductSummaryV2GqlEdge>>;
};

/** A Relay edge containing a `OrderProductSummaryV2GQL` and its cursor. */
export type GqlOrderProductSummaryV2GqlEdge = {
  __typename?: 'OrderProductSummaryV2GQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlOrderProductSummaryV2Gql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlOrderRestrictionGql = {
  __typename?: 'OrderRestrictionGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  order_restriction_id: Scalars['String'];
  product_item_id: Maybe<Scalars['String']>;
  product_id: Maybe<Scalars['String']>;
  category_id: Maybe<Scalars['String']>;
  max_orders: Maybe<Scalars['Int']>;
  max_order_quantity: Maybe<Scalars['Int']>;
  start_date: Scalars['DateTime'];
  end_date: Scalars['DateTime'];
  formula: Maybe<Scalars['JSONString']>;
  exemptions: Maybe<Scalars['JSONString']>;
  min_order_quantity: Maybe<Scalars['Int']>;
  product_name: Maybe<GqlProductV2Gql>;
  product_item_name: Maybe<GqlProductItemV2Gql>;
};

/** OrderReturn GQL. */
export type GqlOrderReturnGql = {
  __typename?: 'OrderReturnGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  return_date: Scalars['String'];
  return_number: Scalars['String'];
  user_id: Scalars['String'];
  route_plan_id: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  currency: GqlCurrencyEnum;
  order_id: Scalars['String'];
  return_id: Scalars['String'];
  shop_id: Scalars['String'];
  route_id: Maybe<Scalars['String']>;
  country_id: Maybe<Scalars['String']>;
  return_items: Maybe<Array<Maybe<GqlOrderReturnItemGql>>>;
  order: Maybe<GqlOrderV2Gql>;
  shop: Maybe<GqlShopV2Gql>;
  confirmation_status: Maybe<Scalars['String']>;
};

/** Node and relay Connection. */
export type GqlOrderReturnGqlConnection = {
  __typename?: 'OrderReturnGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlOrderReturnGqlEdge>>;
};

/** A Relay edge containing a `OrderReturnGQL` and its cursor. */
export type GqlOrderReturnGqlEdge = {
  __typename?: 'OrderReturnGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlOrderReturnGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** Order item return GQL. */
export type GqlOrderReturnItemGql = {
  __typename?: 'OrderReturnItemGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  return_item_id: Scalars['String'];
  unit_of_measure_id: Maybe<Scalars['String']>;
  return_date: Scalars['String'];
  quantity: Scalars['Float'];
  weight: Scalars['Float'];
  comment: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  currency: GqlCurrencyEnum;
  unit_price: Scalars['Float'];
  image: Maybe<Scalars['String']>;
  return_id: Scalars['String'];
  conforming: Maybe<Scalars['Boolean']>;
  labelled: Maybe<Scalars['Boolean']>;
  confirmed_at: Maybe<Scalars['DateTime']>;
  confirmed_by: Maybe<Scalars['String']>;
  product_item_id: Scalars['String'];
  reason_id: Maybe<Scalars['String']>;
  reason: Maybe<GqlReturnReasonGql>;
  return: Maybe<GqlOrderReturnGql>;
  product_item: Maybe<GqlProductItemV2Gql>;
  signed_image_url: Maybe<Scalars['String']>;
};

/** Node and relay Connection. */
export type GqlOrderReturnItemGqlConnection = {
  __typename?: 'OrderReturnItemGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlOrderReturnItemGqlEdge>>;
};

/** A Relay edge containing a `OrderReturnItemGQL` and its cursor. */
export type GqlOrderReturnItemGqlEdge = {
  __typename?: 'OrderReturnItemGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlOrderReturnItemGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** Order GQL. */
export type GqlOrderV2Gql = {
  __typename?: 'OrderV2GQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  order_id: Scalars['String'];
  shop_id: Scalars['String'];
  route_id: Scalars['String'];
  region_id: Maybe<Scalars['String']>;
  route_plan_id: Maybe<Scalars['String']>;
  trader_entity_id: Maybe<Scalars['String']>;
  order_date: Scalars['String'];
  delivery_date: Scalars['String'];
  order_number: Scalars['String'];
  amount: Scalars['Float'];
  currency: GqlCurrencyEnum;
  comment: Maybe<Scalars['String']>;
  version: Maybe<Scalars['String']>;
  priority: Maybe<Scalars['Boolean']>;
  cancellation_reason: Maybe<Scalars['String']>;
  trader_entity_user_id: Scalars['String'];
  trader_entity: GqlTraderEntityEnum;
  fulfilled: Scalars['Boolean'];
  dispatched: Scalars['Boolean'];
  non_delivery_reason: Maybe<Scalars['String']>;
  routing_order: Maybe<Scalars['Int']>;
  delivery_fee: Scalars['Float'];
  processing_fee: Scalars['Float'];
  country_id: Maybe<Scalars['String']>;
  depot_id: Maybe<Scalars['String']>;
  shop: Maybe<GqlShopV2Gql>;
  route: Maybe<GqlRouteV2Gql>;
  order_assignment: Maybe<GqlOrderAssignmentV2Gql>;
  order_items: Maybe<Array<Maybe<GqlOrderItemV2Gql>>>;
  order_returns: Maybe<Array<Maybe<GqlOrderReturnGql>>>;
  served_by: Maybe<GqlUserV2Gql>;
  updated_by_user: Maybe<GqlUserV2Gql>;
  route_plan: Maybe<GqlRoutePlanGql>;
  total_amount: Maybe<Scalars['Float']>;
};

/** Node and relay Connection. */
export type GqlOrderV2GqlConnection = {
  __typename?: 'OrderV2GQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlOrderV2GqlEdge>>;
};

/** A Relay edge containing a `OrderV2GQL` and its cursor. */
export type GqlOrderV2GqlEdge = {
  __typename?: 'OrderV2GQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlOrderV2Gql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlOrderedStockAllocationGql = {
  __typename?: 'OrderedStockAllocationGQL';
  delivery_date: Maybe<Scalars['String']>;
  depot_name: Maybe<Scalars['String']>;
  route_plan_id: Maybe<Scalars['String']>;
  product_item_id: Maybe<Scalars['String']>;
  unit_of_measure_id: Maybe<Scalars['String']>;
  ordered_quantity: Maybe<Scalars['Int']>;
  ordered_weight: Maybe<Scalars['Float']>;
  ordered_amount: Maybe<Scalars['Float']>;
  product_item: Maybe<GqlProductItemV2Gql>;
  route_plan: Maybe<GqlRoutePlanGql>;
  uom: Maybe<GqlUomv2Gql>;
};

export type GqlOrderedStockAllocationGqlConnection = {
  __typename?: 'OrderedStockAllocationGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlOrderedStockAllocationGqlEdge>>;
};

/** A Relay edge containing a `OrderedStockAllocationGQL` and its cursor. */
export type GqlOrderedStockAllocationGqlEdge = {
  __typename?: 'OrderedStockAllocationGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlOrderedStockAllocationGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** Ordering Window GQL */
export type GqlOrderingWindowGql = {
  __typename?: 'OrderingWindowGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  ordering_window_id: Scalars['String'];
  channel: GqlChannelEnum;
  region_id: Scalars['String'];
  depot_id: Maybe<Scalars['String']>;
  region: Maybe<GqlRegionV2Gql>;
  ordering_window_items: Maybe<Array<Maybe<GqlOrderingWindowItemGql>>>;
  depot: Maybe<GqlDepotV2Gql>;
};

export type GqlOrderingWindowGqlConnection = {
  __typename?: 'OrderingWindowGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlOrderingWindowGqlEdge>>;
};

/** A Relay edge containing a `OrderingWindowGQL` and its cursor. */
export type GqlOrderingWindowGqlEdge = {
  __typename?: 'OrderingWindowGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlOrderingWindowGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** Ordering Window Item GQL */
export type GqlOrderingWindowItemGql = {
  __typename?: 'OrderingWindowItemGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  ordering_window_item_id: Scalars['String'];
  ordering_window_id: Scalars['String'];
  day: Scalars['Int'];
  opening_time: Scalars['String'];
  closing_time: Scalars['String'];
  ordering_window: Maybe<GqlOrderingWindowGql>;
};

export type GqlOrderingWindowItemGqlConnection = {
  __typename?: 'OrderingWindowItemGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlOrderingWindowItemGqlEdge>>;
};

/** A Relay edge containing a `OrderingWindowItemGQL` and its cursor. */
export type GqlOrderingWindowItemGqlEdge = {
  __typename?: 'OrderingWindowItemGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlOrderingWindowItemGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** Payment Account Type */
export enum GqlPaymentAccountTypeEnum {
  Mpesa = 'MPESA',
  Bank = 'BANK'
}

export type GqlPaymentAccountV2Gql = {
  __typename?: 'PaymentAccountV2GQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  payment_account_id: Scalars['String'];
  account_number: Scalars['String'];
  account_type: Maybe<GqlPaymentAccountTypeEnum>;
  account_details: Maybe<Scalars['String']>;
  account_name: Maybe<Scalars['String']>;
};

/** Pagination Connection for PaymentAccountV2GQL */
export type GqlPaymentAccountV2GqlConnection = {
  __typename?: 'PaymentAccountV2GQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlPaymentAccountV2GqlEdge>>;
};

/** A Relay edge containing a `PaymentAccountV2GQL` and its cursor. */
export type GqlPaymentAccountV2GqlEdge = {
  __typename?: 'PaymentAccountV2GQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlPaymentAccountV2Gql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlPaymentMethodGql = {
  __typename?: 'PaymentMethodGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  payment_method_id: Scalars['String'];
  payment_method_name: GqlPaymentMode;
  description: Scalars['String'];
  logo_url: Maybe<Scalars['String']>;
  country_id: Maybe<Scalars['String']>;
  payment_visibilities: Maybe<Array<Maybe<GqlPaymentVisibilityGql>>>;
};

/** Payment Mode Enum */
export enum GqlPaymentMode {
  Cash = 'CASH',
  MobileMoney = 'MOBILE_MONEY',
  Bank = 'BANK',
  Loan = 'LOAN',
  MpesaTill = 'MPESA_TILL',
  MpesaStk = 'MPESA_STK',
  MpesaTillAuto = 'MPESA_TILL_AUTO',
  Ewallet = 'EWALLET'
}

export type GqlPaymentV2Gql = {
  __typename?: 'PaymentV2GQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  payment_id: Scalars['String'];
  delivery_id: Scalars['String'];
  payment_mode: GqlPaymentMode;
  reference_number: Maybe<Scalars['String']>;
  payment_number: Scalars['String'];
  amount: Scalars['Float'];
  currency: GqlCurrencyEnum;
  delivery: Maybe<GqlDeliveryV2Gql>;
};

/** Pagination Connection for PaymentV2GQL */
export type GqlPaymentV2GqlConnection = {
  __typename?: 'PaymentV2GQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlPaymentV2GqlEdge>>;
};

/** A Relay edge containing a `PaymentV2GQL` and its cursor. */
export type GqlPaymentV2GqlEdge = {
  __typename?: 'PaymentV2GQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlPaymentV2Gql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlPaymentVisibilityGql = {
  __typename?: 'PaymentVisibilityGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  payment_visibility_id: Scalars['String'];
  payment_method_id: Scalars['String'];
  visible: Scalars['Boolean'];
  region_id: Scalars['String'];
  channel: GqlChannelEnum;
  payment_method: Maybe<GqlPaymentMethodGql>;
};

export type GqlPhoneNumberChangeApprovalGql = {
  __typename?: 'PhoneNumberChangeApprovalGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  phone_number_change_id: Scalars['String'];
  customer_id: Scalars['String'];
  approved: Scalars['Boolean'];
  reason_id: Maybe<Scalars['String']>;
  comment: Maybe<Scalars['String']>;
  customer: Maybe<GqlCustomerV2Gql>;
  phone_number_change_reason: Maybe<GqlPhoneNumberChangeReasonGql>;
};

export type GqlPhoneNumberChangeReasonGql = {
  __typename?: 'PhoneNumberChangeReasonGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  reason_id: Scalars['String'];
  reason: Scalars['String'];
  type: GqlPhoneNumberChangeReasonTypeEnum;
};

/** Phone change reason type enum */
export enum GqlPhoneNumberChangeReasonTypeEnum {
  Approval = 'APPROVAL',
  Decline = 'DECLINE'
}

export type GqlPolicyGql = {
  __typename?: 'PolicyGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  policy_id: Scalars['String'];
  insurer_id: Scalars['String'];
  cover_type_id: Scalars['String'];
  principal_member_id: Scalars['String'];
  billing_cycle: GqlBillingCycleEnum;
  paid: Scalars['Boolean'];
  beneficiaries: Array<Maybe<Scalars['String']>>;
};

export type GqlProductItemV2Gql = {
  __typename?: 'ProductItemV2GQL';
  product_item_id: Scalars['String'];
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  upc_code: Maybe<Scalars['String']>;
  product_item_code: Maybe<Scalars['String']>;
  product_item_name: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  product_id: Maybe<Scalars['String']>;
  image: Maybe<Scalars['String']>;
  image_url: Maybe<Scalars['String']>;
  staff_only: Scalars['Boolean'];
  description: Maybe<Scalars['String']>;
  featured: Scalars['Boolean'];
  packed_weight: Scalars['Float'];
  packed_volume: Scalars['Float'];
  supplier_item: Scalars['Boolean'];
  product_item_segment_id: Maybe<Scalars['String']>;
  whitelisted_routes: Maybe<Array<Maybe<Scalars['String']>>>;
  blacklisted_routes: Maybe<Array<Maybe<Scalars['String']>>>;
  wms_default_available: Maybe<Scalars['Boolean']>;
  wms_pickface_putaway: Scalars['Boolean'];
  wms_std_pallet_quantity: Maybe<Scalars['Int']>;
  has_warranty: Scalars['Boolean'];
  etims_code: Maybe<Scalars['String']>;
  etims_class_code: Maybe<Scalars['String']>;
  tax_type_code: Maybe<Scalars['String']>;
  country_of_origin_code: Maybe<Scalars['String']>;
  item_sequence: Maybe<Scalars['Int']>;
  etims_packaging_unit: Maybe<Scalars['String']>;
  etims_quantity_code: Maybe<Scalars['String']>;
  synced_to_etims: Scalars['Boolean'];
  product: Maybe<GqlProductV2Gql>;
  product_item_segment: Maybe<GqlProductItemSegmentV2Gql>;
  units_of_measure: Maybe<Array<Maybe<GqlProductItemUomv2Gql>>>;
  promotion_tags: Maybe<Array<Maybe<GqlPromotionTagGql>>>;
  base_price: Maybe<Scalars['Float']>;
  dynamic_price: Maybe<GqlDynamicPriceGql>;
  promotion_tag: Maybe<GqlPromotionTagGql>;
  regions: Maybe<Array<Maybe<GqlProductItemRegionV2Gql>>>;
  total_regions: Maybe<Scalars['Int']>;
  whitelisted: Maybe<Array<Maybe<GqlWhitelistedRoutesGql>>>;
  blacklisted: Maybe<Array<Maybe<GqlBlacklistedRoutesGql>>>;
  is_liquor: Maybe<Scalars['Boolean']>;
  currency_base_price: Maybe<GqlBasePriceGql>;
  current_stock_availability: Maybe<GqlStockAvailabilityGqLv2>;
  current_dcs_stock_availability: Maybe<GqlDcStockAvailabilityGql>;
  current_pricing_v2: Maybe<GqlPricingV2Gql>;
  ordered_stock: Maybe<GqlStockOrderedGql>;
  ordered_stock_depot: Maybe<GqlStockOrderedGql>;
  inventory: Maybe<GqlInventoryGql>;
};


export type GqlProductItemV2GqlCurrent_Stock_AvailabilityArgs = {
  distribution_center_id: Scalars['String'];
};


export type GqlProductItemV2GqlCurrent_Dcs_Stock_AvailabilityArgs = {
  distribution_center_ids: Array<Maybe<Scalars['String']>>;
  depot_id: Scalars['String'];
};


export type GqlProductItemV2GqlCurrent_Pricing_V2Args = {
  region_id: Scalars['String'];
};


export type GqlProductItemV2GqlOrdered_StockArgs = {
  distribution_center_ids: Array<Maybe<Scalars['String']>>;
  depot_id: Scalars['String'];
};


export type GqlProductItemV2GqlOrdered_Stock_DepotArgs = {
  date: Scalars['String'];
  depot_id: Scalars['String'];
};


export type GqlProductItemV2GqlInventoryArgs = {
  date: Maybe<Scalars['String']>;
  distribution_center_id: Scalars['String'];
};

export type GqlProductV2Gql = {
  __typename?: 'ProductV2GQL';
  product_id: Scalars['String'];
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  product_name: Scalars['String'];
  product_code: Maybe<Scalars['String']>;
  hs_code: Maybe<Scalars['String']>;
  kra_code: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  product_description: Maybe<Scalars['String']>;
  category_id: Maybe<Scalars['String']>;
  vatable: Maybe<Scalars['Boolean']>;
  featured: Scalars['Boolean'];
  product_type: Maybe<GqlProductTypeEnum>;
  image: Maybe<Scalars['String']>;
  image_url: Maybe<Scalars['String']>;
  finance_product_type_id: Maybe<Scalars['String']>;
  wms_shelf_life_days: Maybe<Scalars['Int']>;
  packaging_units: Maybe<Array<Maybe<GqlPackagingUnitV2Gql>>>;
  category: Maybe<GqlCategoryV2Gql>;
  categories: Maybe<Array<Maybe<GqlCategoryV2Gql>>>;
  domain_activations: Maybe<Array<Maybe<GqlProductDomainActivationV2Gql>>>;
  product_tags: Maybe<Array<Maybe<GqlProductTagV2Gql>>>;
  finance_product_type: Maybe<GqlFinanceProductTypeGql>;
  product_items: Maybe<Array<Maybe<GqlProductItemV2Gql>>>;
  sourcing_price_range: Maybe<GqlPriceRangeGql>;
};

export type GqlProjectedRevenueV2Gql = {
  __typename?: 'ProjectedRevenueV2GQL';
  date: Maybe<Scalars['String']>;
  projected_revenue: Maybe<Scalars['Float']>;
};

export type GqlRealizedRevenueV2Gql = {
  __typename?: 'RealizedRevenueV2GQL';
  realized_revenue: Maybe<Scalars['Float']>;
  date: Maybe<Scalars['String']>;
};

/** Node and relay Connection. */
export type GqlRegionV2GqlConnection = {
  __typename?: 'RegionV2GQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlRegionV2GqlEdge>>;
};

/** A Relay edge containing a `RegionV2GQL` and its cursor. */
export type GqlRegionV2GqlEdge = {
  __typename?: 'RegionV2GQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlRegionV2Gql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum GqlRelationshipEnum {
  Member = 'MEMBER',
  Spouse = 'SPOUSE',
  Child = 'CHILD'
}

/** Return Item Rejection Reason GQL */
export type GqlReturnItemRejectionReasonGql = {
  __typename?: 'ReturnItemRejectionReasonGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  rejection_reason_id: Scalars['String'];
  name: Scalars['String'];
  description: Maybe<Scalars['String']>;
};

/** Return Reason GQL */
export type GqlReturnReasonGql = {
  __typename?: 'ReturnReasonGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  reason_id: Scalars['String'];
  parent_id: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description: Maybe<Scalars['String']>;
  return_type: GqlReturnTypeEnum;
  parent: Maybe<GqlReturnReasonGql>;
  children: Maybe<Array<Maybe<GqlReturnReasonGql>>>;
};

/** Return type Enum */
export enum GqlReturnTypeEnum {
  DeliveryReturn = 'DELIVERY_RETURN',
  OrderReturn = 'ORDER_RETURN'
}

export type GqlRoutePaymentAccountV2Gql = {
  __typename?: 'RoutePaymentAccountV2GQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  route_payment_account_id: Scalars['String'];
  route_id: Scalars['String'];
  payment_account_id: Scalars['String'];
  inbound_payment_config_id: Maybe<Scalars['String']>;
  route: Maybe<GqlRouteV2Gql>;
  payment_account: Maybe<GqlPaymentAccountV2Gql>;
};

/** Pagination Connection for RoutePaymentAccountV2GQL */
export type GqlRoutePaymentAccountV2GqlConnection = {
  __typename?: 'RoutePaymentAccountV2GQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlRoutePaymentAccountV2GqlEdge>>;
};

/** A Relay edge containing a `RoutePaymentAccountV2GQL` and its cursor. */
export type GqlRoutePaymentAccountV2GqlEdge = {
  __typename?: 'RoutePaymentAccountV2GQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlRoutePaymentAccountV2Gql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlRoutePlanGql = {
  __typename?: 'RoutePlanGQL';
  route_plan_id: Scalars['String'];
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  delivery_date: Scalars['String'];
  vehicle_id: Scalars['String'];
  depot_id: Scalars['String'];
  route_id: Maybe<Scalars['String']>;
  distance: Scalars['Float'];
  orders: Array<Maybe<Scalars['String']>>;
  weight: Maybe<Scalars['Float']>;
  volume: Maybe<Scalars['Float']>;
  num_shops: Maybe<Scalars['Int']>;
  starting_odometer: Maybe<Scalars['Float']>;
  closing_odometer: Maybe<Scalars['Float']>;
  region_id: Maybe<Scalars['String']>;
  trip_id: Maybe<Scalars['String']>;
  multiple_trips: Scalars['Boolean'];
  vehicle_trip: Scalars['Int'];
  vehicle: Maybe<GqlVehicleGql>;
  trip: Maybe<GqlTripGql>;
  fulfillment_teams: Maybe<Array<Maybe<GqlFulfillmentTeamGql>>>;
  planned_orders: Maybe<Scalars['Int']>;
  actual_distance: Maybe<Scalars['Float']>;
  depot: Maybe<GqlDepotV2Gql>;
  route: Maybe<GqlRouteV2Gql>;
  region: Maybe<GqlRegionV2Gql>;
  order_summary: Maybe<GqlRoutePlanOrderSummaryGql>;
};

export type GqlRoutePlanOrderSummaryGql = {
  __typename?: 'RoutePlanOrderSummaryGQL';
  route_plan_id: Maybe<Scalars['String']>;
  fulfilled_orders: Maybe<Scalars['Int']>;
};

/** Route Team Member GQL. */
export type GqlRouteTeamMemberV2Gql = {
  __typename?: 'RouteTeamMemberV2GQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  route_team_member_id: Scalars['String'];
  route_team_id: Scalars['String'];
  user_id: Scalars['String'];
  sale_date: Scalars['String'];
  route_team: Maybe<GqlRouteTeamV2Gql>;
  user: Maybe<GqlUserV2Gql>;
};

/** Node and relay Connection. */
export type GqlRouteTeamMemberV2GqlConnection = {
  __typename?: 'RouteTeamMemberV2GQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlRouteTeamMemberV2GqlEdge>>;
};

/** A Relay edge containing a `RouteTeamMemberV2GQL` and its cursor. */
export type GqlRouteTeamMemberV2GqlEdge = {
  __typename?: 'RouteTeamMemberV2GQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlRouteTeamMemberV2Gql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** Route Team GQL. */
export type GqlRouteTeamV2Gql = {
  __typename?: 'RouteTeamV2GQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  route_team_id: Scalars['String'];
  route_id: Scalars['String'];
  sale_date: Scalars['String'];
  country_id: Maybe<Scalars['String']>;
  route: Maybe<GqlRouteV2Gql>;
  route_team_members: Maybe<Array<Maybe<GqlRouteTeamMemberV2Gql>>>;
};

/** Node and relay Connection. */
export type GqlRouteTeamV2GqlConnection = {
  __typename?: 'RouteTeamV2GQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlRouteTeamV2GqlEdge>>;
};

/** A Relay edge containing a `RouteTeamV2GQL` and its cursor. */
export type GqlRouteTeamV2GqlEdge = {
  __typename?: 'RouteTeamV2GQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlRouteTeamV2Gql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlRouteTillAlertContactGql = {
  __typename?: 'RouteTillAlertContactGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  route_till_alert_contact_id: Scalars['String'];
  user_id: Scalars['String'];
  phone_number: Scalars['String'];
  route_id: Scalars['String'];
  route: Maybe<GqlRouteV2Gql>;
};

export type GqlRouteTillAlertContactGqlConnection = {
  __typename?: 'RouteTillAlertContactGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlRouteTillAlertContactGqlEdge>>;
};

/** A Relay edge containing a `RouteTillAlertContactGQL` and its cursor. */
export type GqlRouteTillAlertContactGqlEdge = {
  __typename?: 'RouteTillAlertContactGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlRouteTillAlertContactGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** Route List. */
export enum GqlRouteType {
  Delivery = 'DELIVERY',
  Outermarket = 'OUTERMARKET',
  Courier = 'COURIER',
  Staff = 'STAFF'
}

export type GqlRouteV2Gql = {
  __typename?: 'RouteV2GQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  route_id: Scalars['String'];
  depot_id: Scalars['String'];
  route_name: Maybe<Scalars['String']>;
  route_type: Maybe<Scalars['String']>;
  auto_assign_shop: Scalars['Boolean'];
  depot: Maybe<GqlDepotV2Gql>;
  payment_accounts: Maybe<Array<Maybe<GqlRoutePaymentAccountV2Gql>>>;
};

/** Node and relay Connection. */
export type GqlRouteV2GqlConnection = {
  __typename?: 'RouteV2GQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlRouteV2GqlEdge>>;
};

/** A Relay edge containing a `RouteV2GQL` and its cursor. */
export type GqlRouteV2GqlEdge = {
  __typename?: 'RouteV2GQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlRouteV2Gql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlSalesAgentClusterGql = {
  __typename?: 'SalesAgentClusterGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  cluster_id: Scalars['String'];
  cluster_code: Scalars['String'];
  territory_name: Maybe<Scalars['String']>;
  updated_by_user: Maybe<GqlUserV2Gql>;
};

export type GqlSalesAgentClusterGqlConnection = {
  __typename?: 'SalesAgentClusterGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlSalesAgentClusterGqlEdge>>;
};

/** A Relay edge containing a `SalesAgentClusterGQL` and its cursor. */
export type GqlSalesAgentClusterGqlEdge = {
  __typename?: 'SalesAgentClusterGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlSalesAgentClusterGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlSalesPerProductGql = {
  __typename?: 'SalesPerProductGQL';
  start_date: Maybe<Scalars['String']>;
  end_date: Maybe<Scalars['String']>;
  product_item_id: Maybe<Scalars['String']>;
  product_item: Maybe<GqlProductItemV2Gql>;
  amount: Maybe<Scalars['Float']>;
  quantity: Maybe<Scalars['Float']>;
  weight: Maybe<Scalars['Float']>;
  stock_availability: Maybe<GqlStockAvailabilityRangeSummaryGql>;
};

/** Node and relay Connection. */
export type GqlSalesPerProductGqlConnection = {
  __typename?: 'SalesPerProductGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlSalesPerProductGqlEdge>>;
};

/** A Relay edge containing a `SalesPerProductGQL` and its cursor. */
export type GqlSalesPerProductGqlEdge = {
  __typename?: 'SalesPerProductGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlSalesPerProductGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlSegmentEarningsGql = {
  __typename?: 'SegmentEarningsGQL';
  delivery_date: Maybe<Scalars['String']>;
  product_item_segment: Maybe<Scalars['String']>;
  total_ordered_weight: Maybe<Scalars['Float']>;
  total_potential_pay: Maybe<Scalars['Float']>;
  total_delivered_weight: Maybe<Scalars['Float']>;
  total_unlocked_pay: Maybe<Scalars['Float']>;
};

export type GqlSegmentEarningsSummaryGql = {
  __typename?: 'SegmentEarningsSummaryGQL';
  agent_id: Maybe<Scalars['String']>;
  delivery_date: Maybe<Scalars['Date']>;
  segment_earnings: Maybe<Array<Maybe<GqlSegmentEarningsGql>>>;
};

export type GqlShopContactV2Gql = {
  __typename?: 'ShopContactV2GQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  contact_id: Scalars['String'];
  shop_id: Scalars['String'];
  contact_name: Scalars['String'];
  phone_number: Scalars['String'];
  email_address: Maybe<Scalars['String']>;
};

export type GqlShopDeliveryFeeGql = {
  __typename?: 'ShopDeliveryFeeGQL';
  shop_id: Maybe<Scalars['String']>;
  delivery_fee: Maybe<Scalars['Float']>;
};

export type GqlShopDeliveryProcessingFeeGql = {
  __typename?: 'ShopDeliveryProcessingFeeGQL';
  delivery_fee: Maybe<Scalars['Float']>;
  processing_fee: Maybe<Scalars['Float']>;
  total_ordered: Maybe<Scalars['Float']>;
  charged_delivery_fee: Maybe<Scalars['Float']>;
  charged_processing_fee: Maybe<Scalars['Float']>;
};

export type GqlShopDeliverySummaryV2Gql = {
  __typename?: 'ShopDeliverySummaryV2GQL';
  date: Maybe<Scalars['String']>;
  last_delivery_date: Maybe<Scalars['String']>;
  total_sales: Maybe<Scalars['Float']>;
  average_drop_size: Maybe<Scalars['Float']>;
  last_sale_amount: Maybe<Scalars['Float']>;
};

export type GqlShopOrderSummaryV2Gql = {
  __typename?: 'ShopOrderSummaryV2GQL';
  date: Maybe<Scalars['String']>;
  last_order_date: Maybe<Scalars['String']>;
  total_orders: Maybe<Scalars['Float']>;
  average_order_value: Maybe<Scalars['Float']>;
  last_order_amount: Maybe<Scalars['Float']>;
};

export type GqlShopTypeV2Gql = {
  __typename?: 'ShopTypeV2GQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  shop_type_id: Scalars['String'];
  name: Scalars['String'];
  parent_id: Maybe<Scalars['String']>;
  parent: Maybe<GqlShopTypeV2Gql>;
  children: Maybe<Array<Maybe<GqlShopTypeV2Gql>>>;
  shops: Maybe<Array<Maybe<GqlShopV2Gql>>>;
};

export type GqlShopV2Gql = {
  __typename?: 'ShopV2GQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  shop_id: Scalars['String'];
  shop_number: Scalars['String'];
  shop_name: Scalars['String'];
  shop_type_id: Scalars['String'];
  route_id: Maybe<Scalars['String']>;
  region_id: Maybe<Scalars['String']>;
  customer_id: Scalars['String'];
  kra_pin: Maybe<Scalars['String']>;
  country_id: Maybe<Scalars['String']>;
  latitude: Maybe<Scalars['Float']>;
  longitude: Maybe<Scalars['Float']>;
  physical_address: Maybe<Scalars['String']>;
  image: Maybe<Scalars['String']>;
  version: Maybe<Scalars['String']>;
  approved: Scalars['Boolean'];
  approved_by: Maybe<Scalars['String']>;
  approved_date: Maybe<Scalars['DateTime']>;
  depot_distance: Maybe<Scalars['Float']>;
  cluster_id: Maybe<Scalars['String']>;
  synced_to_etims: Maybe<Scalars['Boolean']>;
  shop_type: Maybe<GqlShopTypeV2Gql>;
  customer: Maybe<GqlCustomerV2Gql>;
  region: Maybe<GqlRegionV2Gql>;
  country: Maybe<GqlCountryGql>;
  contacts: Maybe<Array<Maybe<GqlShopContactV2Gql>>>;
  customer_types: Maybe<Array<Maybe<GqlCustomerTypeV2Gql>>>;
  cluster: Maybe<GqlSalesAgentClusterGql>;
  route: Maybe<GqlRouteV2Gql>;
  distribution_centers: Maybe<Array<Maybe<GqlDepotDistributionCenterGql>>>;
  work_orders: Maybe<Array<Maybe<GqlWorkOrderGql>>>;
  order_returns: Maybe<Array<Maybe<GqlOrderReturnGql>>>;
  delivery_returns: Maybe<Array<Maybe<GqlDeliveryReturnGql>>>;
  status: Maybe<Array<Maybe<Scalars['String']>>>;
  signed_image_url: Maybe<Scalars['String']>;
  image_url: Maybe<Scalars['String']>;
  delivery_fee: Maybe<Scalars['Float']>;
  updated_by_user: Maybe<GqlUserV2Gql>;
};

/** Pagination Connection for ShopV2GQL */
export type GqlShopV2GqlConnection = {
  __typename?: 'ShopV2GQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlShopV2GqlEdge>>;
};

/** A Relay edge containing a `ShopV2GQL` and its cursor. */
export type GqlShopV2GqlEdge = {
  __typename?: 'ShopV2GQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlShopV2Gql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlStockAvailabilityRangeSummaryGql = {
  __typename?: 'StockAvailabilityRangeSummaryGQL';
  start_date: Maybe<Scalars['String']>;
  end_date: Maybe<Scalars['String']>;
  product_item_id: Maybe<Scalars['String']>;
};

export type GqlStockNotificationGql = {
  __typename?: 'StockNotificationGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  stock_notification_id: Scalars['String'];
  shop_id: Scalars['String'];
  product_item_id: Scalars['String'];
  distribution_center_ids: Maybe<Array<Maybe<Scalars['String']>>>;
  region_id: Maybe<Scalars['String']>;
  phone_number: Scalars['String'];
  device_token: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
};

export type GqlStockNotificationGqlConnection = {
  __typename?: 'StockNotificationGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlStockNotificationGqlEdge>>;
};

/** A Relay edge containing a `StockNotificationGQL` and its cursor. */
export type GqlStockNotificationGqlEdge = {
  __typename?: 'StockNotificationGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlStockNotificationGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlStockOrderedGql = {
  __typename?: 'StockOrderedGQL';
  product_item_id: Scalars['String'];
  order_quantity: Maybe<Scalars['Float']>;
  order_weight: Maybe<Scalars['Float']>;
  unit_of_measure_id: Maybe<Scalars['String']>;
  depot_id: Scalars['String'];
  route_id: Maybe<Scalars['String']>;
  distribution_center_ids: Maybe<Array<Maybe<Scalars['String']>>>;
  sale_date: Scalars['String'];
  date: Maybe<Scalars['String']>;
};

export type GqlTerritoryGql = {
  __typename?: 'TerritoryGQL';
  territory_name: Maybe<Scalars['String']>;
};

/** Trader Entity. */
export enum GqlTraderEntityEnum {
  Mcommerce = 'MCOMMERCE',
  Dms = 'DMS',
  Courier = 'COURIER',
  Pwa = 'PWA',
  Sokocall = 'SOKOCALL',
  Sokoyetu = 'SOKOYETU'
}

/** TraderEntity GQL. */
export type GqlTraderEntityV2Gql = {
  __typename?: 'TraderEntityV2GQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  trader_entity_id: Scalars['String'];
  trader_entity_name: Scalars['String'];
  description: Maybe<Scalars['String']>;
};

/** UOM GQL Schema */
export type GqlUomv2Gql = {
  __typename?: 'UOMV2GQL';
  uom_id: Scalars['String'];
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  name: Scalars['String'];
  abbreviation: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  etims_quantity_code: Maybe<Scalars['String']>;
  uom: Maybe<GqlProductItemUomv2Gql>;
};

export type GqlVendorFinancePlanGql = {
  __typename?: 'VendorFinancePlanGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  plan_id: Scalars['String'];
  name: Scalars['String'];
  required_fields: Array<Maybe<Scalars['String']>>;
};

export type GqlVendorKycgql = {
  __typename?: 'VendorKYCGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  vendor_kyc_id: Scalars['String'];
  legal_name: Scalars['String'];
  customer_id: Maybe<Scalars['String']>;
  legal_document: GqlLegalDocumentEnum;
  phone_number: Scalars['String'];
  legal_document_number: Scalars['String'];
  legal_document_front_image: Maybe<Scalars['String']>;
  legal_document_back_image: Maybe<Scalars['String']>;
  consent_form_image: Maybe<Scalars['String']>;
  approved: Scalars['Boolean'];
  approved_by: Maybe<Scalars['String']>;
  date_approved: Maybe<Scalars['DateTime']>;
  monthly_income: Maybe<Scalars['Float']>;
  monthly_expenditure: Maybe<Scalars['Float']>;
  id_verified: Scalars['Boolean'];
  country_id: Maybe<Scalars['String']>;
  turaco_opt_in: Maybe<Scalars['Boolean']>;
  customer: Maybe<GqlCustomerV2Gql>;
  finance_plans: Maybe<Array<Maybe<GqlVendorFinancePlanGql>>>;
  signed_consent_form_url: Maybe<Scalars['String']>;
  signed_legal_document_front_image_url: Maybe<Scalars['String']>;
  signed_legal_document_back_image_url: Maybe<Scalars['String']>;
};

/** Pagination Connection for VendorKYCGQL */
export type GqlVendorKycgqlConnection = {
  __typename?: 'VendorKYCGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlVendorKycgqlEdge>>;
};

/** A Relay edge containing a `VendorKYCGQL` and its cursor. */
export type GqlVendorKycgqlEdge = {
  __typename?: 'VendorKYCGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlVendorKycgql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlVendorPaymentsGql = {
  __typename?: 'VendorPaymentsGQL';
  delivery_date: Maybe<Scalars['String']>;
  depot_name: Maybe<Scalars['String']>;
  route_name: Maybe<Scalars['String']>;
  route_plan_id: Maybe<Scalars['String']>;
  shop_number: Maybe<Scalars['String']>;
  shop_name: Maybe<Scalars['String']>;
  customer_name: Maybe<Scalars['String']>;
  phone_number: Maybe<Scalars['String']>;
  delivery_number: Maybe<Scalars['String']>;
  payment_mode: Maybe<Scalars['String']>;
  reference_number: Maybe<Scalars['String']>;
  payment_number: Maybe<Scalars['String']>;
  delivery_amount: Maybe<Scalars['Float']>;
  delivery_fee: Maybe<Scalars['Float']>;
  total_amount: Maybe<Scalars['Float']>;
  paid_amount: Maybe<Scalars['Float']>;
  route_plan: Maybe<GqlRoutePlanGql>;
};

export type GqlVendorPaymentsGqlConnection = {
  __typename?: 'VendorPaymentsGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlVendorPaymentsGqlEdge>>;
};

/** A Relay edge containing a `VendorPaymentsGQL` and its cursor. */
export type GqlVendorPaymentsGqlEdge = {
  __typename?: 'VendorPaymentsGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlVendorPaymentsGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlVendorPaymentsSummaryGql = {
  __typename?: 'VendorPaymentsSummaryGQL';
  payment_mode: Maybe<Scalars['String']>;
  amount: Maybe<Scalars['Float']>;
};

export type GqlWorkOrderDefinitionGql = {
  __typename?: 'WorkOrderDefinitionGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  definition_id: Scalars['String'];
  role_id: Scalars['String'];
  query: Scalars['String'];
  start_date: Maybe<Scalars['DateTime']>;
  end_date: Maybe<Scalars['DateTime']>;
  reason_id: Maybe<Scalars['String']>;
};

/** Work Order Entity Enum */
export enum GqlWorkOrderEntityEnum {
  Order = 'ORDER',
  Delivery = 'DELIVERY',
  Payment = 'PAYMENT',
  Return = 'RETURN'
}

export type GqlWorkOrderGql = {
  __typename?: 'WorkOrderGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  work_order_id: Scalars['String'];
  route_id: Scalars['String'];
  shop_id: Scalars['String'];
  role_id: Scalars['String'];
  user_id: Maybe<Scalars['String']>;
  sort_order: Maybe<Scalars['Int']>;
  date: Scalars['String'];
  cluster_id: Maybe<Scalars['String']>;
  shop: Maybe<GqlShopV2Gql>;
  route: Maybe<GqlRouteV2Gql>;
  work_order_items: Maybe<Array<Maybe<GqlWorkOrderItemGql>>>;
};

export type GqlWorkOrderGqlConnection = {
  __typename?: 'WorkOrderGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlWorkOrderGqlEdge>>;
};

/** A Relay edge containing a `WorkOrderGQL` and its cursor. */
export type GqlWorkOrderGqlEdge = {
  __typename?: 'WorkOrderGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlWorkOrderGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlWorkOrderItemGql = {
  __typename?: 'WorkOrderItemGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  work_order_item_id: Scalars['String'];
  work_order_id: Maybe<Scalars['String']>;
  reason_id: Maybe<Scalars['String']>;
  comment: Maybe<Scalars['String']>;
  reference_entity_id: Maybe<Scalars['String']>;
  reference_entity: Maybe<GqlWorkOrderEntityEnum>;
  reason: Maybe<GqlWorkOrderReasonGql>;
  work_order: Maybe<GqlWorkOrderGql>;
};

export type GqlWorkOrderItemGqlConnection = {
  __typename?: 'WorkOrderItemGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlWorkOrderItemGqlEdge>>;
};

/** A Relay edge containing a `WorkOrderItemGQL` and its cursor. */
export type GqlWorkOrderItemGqlEdge = {
  __typename?: 'WorkOrderItemGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlWorkOrderItemGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlWorkOrderReasonGql = {
  __typename?: 'WorkOrderReasonGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  reason_id: Scalars['String'];
  name: Scalars['String'];
  description: Maybe<Scalars['String']>;
};

export type GqlChecklistSectionGql = {
  __typename?: 'ChecklistSectionGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  section_id: Scalars['String'];
  name: Scalars['String'];
  description: Maybe<Scalars['String']>;
  checklist: GqlChecklistEnum;
  questions: Maybe<Array<Maybe<GqlChecklistQuestionGql>>>;
};

/** An enumeration. */
export enum GqlChecklistEnum {
  Driver = 'DRIVER',
  Maintenance = 'MAINTENANCE'
}

export type GqlChecklistQuestionGql = {
  __typename?: 'ChecklistQuestionGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  question_id: Scalars['String'];
  section_id: Scalars['String'];
  question: Scalars['String'];
  section: Maybe<GqlChecklistSectionGql>;
};

export type GqlChecklistQuestionGqlConnection = {
  __typename?: 'ChecklistQuestionGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlChecklistQuestionGqlEdge>>;
};

/** A Relay edge containing a `ChecklistQuestionGQL` and its cursor. */
export type GqlChecklistQuestionGqlEdge = {
  __typename?: 'ChecklistQuestionGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlChecklistQuestionGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlChecklistEntryGqlConnection = {
  __typename?: 'ChecklistEntryGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlChecklistEntryGqlEdge>>;
};

/** A Relay edge containing a `ChecklistEntryGQL` and its cursor. */
export type GqlChecklistEntryGqlEdge = {
  __typename?: 'ChecklistEntryGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlChecklistEntryGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlChecklistEntryGql = {
  __typename?: 'ChecklistEntryGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  entry_id: Scalars['String'];
  checklist_entry_number: Scalars['String'];
  odometer_reading: Maybe<Scalars['Float']>;
  route_plan_id: Scalars['String'];
  vehicle_id: Scalars['String'];
  user_id: Scalars['String'];
  depot_id: Maybe<Scalars['String']>;
  entry_date: Scalars['String'];
  service_due_date: Maybe<Scalars['String']>;
  vehicle: Maybe<GqlVehicleGql>;
  route_plan: Maybe<GqlRoutePlanGql>;
  entry_items: Maybe<Array<Maybe<GqlChecklistEntryItemGql>>>;
};

export type GqlVehicleGql = {
  __typename?: 'VehicleGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  country_id: Maybe<Scalars['String']>;
  vehicle_cost_model: Maybe<Scalars['String']>;
  cost_rate: Scalars['Float'];
  logistics_type: Maybe<Scalars['String']>;
  vehicle_id: Scalars['String'];
  vehicle_type_id: Scalars['String'];
  depot_id: Maybe<Scalars['String']>;
  route_id: Maybe<Scalars['String']>;
  registration_number: Scalars['String'];
  owner_name: Maybe<Scalars['String']>;
  phone_number: Maybe<Scalars['String']>;
  load_capacity: Scalars['Float'];
  usable_load_capacity: Scalars['Float'];
  capacity_volume: Maybe<Scalars['Float']>;
  usable_volume: Scalars['Float'];
  locked: Scalars['Boolean'];
  product_type: Maybe<Scalars['String']>;
  vehicle_supplier_id: Maybe<Scalars['String']>;
  insurance_expiry_date: Maybe<Scalars['String']>;
  service_due_date: Maybe<Scalars['String']>;
  region_id: Maybe<Scalars['String']>;
  rejected: Scalars['Boolean'];
  rejection_reason: Maybe<Array<Maybe<GqlVehicleRejectionReason>>>;
  till_number: Maybe<Scalars['Int']>;
  vehicle_type: Maybe<GqlVehicleTypeGql>;
  trips: Maybe<Array<Maybe<GqlTripGql>>>;
  vehicle_supplier: Maybe<GqlVehicleSupplierGql>;
  last_assigned: Maybe<Scalars['String']>;
  starting_odometer: Maybe<Scalars['String']>;
  closing_odometer: Maybe<Scalars['String']>;
  depot: Maybe<GqlDepotV2Gql>;
  route: Maybe<GqlRouteV2Gql>;
  region: Maybe<GqlRegionV2Gql>;
};

/** An enumeration. */
export enum GqlVehicleRejectionReason {
  MissingTracker = 'MISSING_TRACKER',
  MissingSpareWheel = 'MISSING_SPARE_WHEEL',
  DamagedOdometer = 'DAMAGED_ODOMETER',
  MissingLogBook = 'MISSING_LOG_BOOK',
  MissingInsuranceCover = 'MISSING_INSURANCE_COVER'
}

export type GqlVehicleTypeGql = {
  __typename?: 'VehicleTypeGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  vehicle_type_id: Scalars['String'];
  vehicle_type_name: Scalars['String'];
  details: Maybe<Scalars['String']>;
};

export type GqlTripGql = {
  __typename?: 'TripGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  vehicle_cost_model: Maybe<Scalars['String']>;
  cost_rate: Scalars['Float'];
  logistics_type: Maybe<Scalars['String']>;
  trip_id: Scalars['String'];
  vehicle_id: Scalars['String'];
  starting_odometer: Maybe<Scalars['Float']>;
  closing_odometer: Maybe<Scalars['Float']>;
  distance: Maybe<Scalars['Float']>;
  weight: Maybe<Scalars['Float']>;
  cost: Maybe<Scalars['Float']>;
  variable_cost: Maybe<Scalars['Float']>;
  starting_date: Maybe<Scalars['DateTime']>;
  end_date: Maybe<Scalars['DateTime']>;
  region_id: Maybe<Scalars['String']>;
  from_location: Maybe<Scalars['String']>;
  to_location: Maybe<Scalars['String']>;
  status: GqlTripStatus;
  vehicle: Maybe<GqlVehicleGql>;
  route_plan: Maybe<Array<Maybe<GqlRoutePlanGql>>>;
  has_statement: Maybe<Scalars['Boolean']>;
  total_cost: Maybe<Scalars['Int']>;
  region: Maybe<GqlRegionV2Gql>;
  from_inventory_location: Maybe<GqlInventoryLocationGql>;
  to_inventory_location: Maybe<GqlInventoryLocationGql>;
};

/** An enumeration. */
export enum GqlTripStatus {
  InProgress = 'IN_PROGRESS',
  OnHold = 'ON_HOLD',
  Complete = 'COMPLETE'
}

export type GqlFulfillmentTeamGql = {
  __typename?: 'FulfillmentTeamGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  country_id: Maybe<Scalars['String']>;
  fulfillment_team_id: Scalars['String'];
  route_plan_id: Scalars['String'];
  start_time: Maybe<Scalars['DateTime']>;
  end_time: Maybe<Scalars['DateTime']>;
  user_id: Scalars['String'];
  assignment_number: Scalars['String'];
  delivery_date: Scalars['String'];
  route_plan: Maybe<GqlRoutePlanGql>;
  status: Maybe<Scalars['String']>;
  user: Maybe<GqlUserV2Gql>;
};

export type GqlInventoryLocationGql = {
  __typename?: 'InventoryLocationGQL';
  inventory_location_id: Scalars['String'];
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  inventory_location_name: Scalars['String'];
  inventory_location_type: Maybe<Scalars['String']>;
  asset_tracker_origin: Maybe<Array<Maybe<GqlAssetTrackerGql>>>;
  asset_tracker_destination: Maybe<Array<Maybe<GqlAssetTrackerGql>>>;
  asset_tracker_expected_final_destination: Maybe<Array<Maybe<GqlAssetTrackerGql>>>;
};

export type GqlVehicleSupplierGql = {
  __typename?: 'VehicleSupplierGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  vehicle_supplier_id: Scalars['String'];
  supplier_number: Scalars['String'];
  name: Scalars['String'];
  phone_number: Scalars['String'];
  alt_phone_number: Maybe<Scalars['String']>;
  email: Scalars['String'];
  country_id: Scalars['String'];
  vehicles: Maybe<Array<Maybe<GqlVehicleGql>>>;
  payment_accounts: Maybe<Array<Maybe<GqlVehicleSupplierPaymentAccountGql>>>;
  statements: Maybe<Array<Maybe<GqlVehicleSupplierStatementGql>>>;
  num_vehicles: Maybe<Scalars['Int']>;
  country: Maybe<GqlCountryGql>;
};

export type GqlVehicleSupplierPaymentAccountGql = {
  __typename?: 'VehicleSupplierPaymentAccountGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  vehicle_supplier_payment_account_id: Scalars['String'];
  vehicle_supplier_id: Scalars['String'];
  account_type: GqlPaymentAccountType;
  party: Scalars['String'];
  party_code: Maybe<Scalars['String']>;
  account_number: Scalars['String'];
  account_name: Scalars['String'];
  is_primary: Scalars['Boolean'];
  vehicle_supplier: Maybe<GqlVehicleSupplierGql>;
};

/** An enumeration. */
export enum GqlPaymentAccountType {
  Bank = 'BANK',
  Mobile = 'MOBILE',
  Manual = 'MANUAL'
}

export type GqlVehicleSupplierStatementGql = {
  __typename?: 'VehicleSupplierStatementGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  statement_id: Scalars['String'];
  statement_number: Scalars['String'];
  vehicle_supplier_id: Scalars['String'];
  date_from: Scalars['DateTime'];
  date_to: Scalars['DateTime'];
  amount: Scalars['Float'];
  approval_status: GqlStatementApprovalStatus;
  approved_by: Maybe<Scalars['String']>;
  payment_status: GqlPaymentStatus;
  invoice_amount: Maybe<Scalars['Float']>;
  vehicle_supplier: Maybe<GqlVehicleSupplierGql>;
  statement_files: Maybe<Array<Maybe<GqlVehicleSupplierStatementFileGql>>>;
  trips: Maybe<Array<Maybe<GqlTripGql>>>;
  created_by_user: Maybe<GqlUserV2Gql>;
  updated_by_user: Maybe<GqlUserV2Gql>;
  approved_by_user: Maybe<GqlUserV2Gql>;
};

/** An enumeration. */
export enum GqlStatementApprovalStatus {
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  Approved = 'APPROVED'
}

/** An enumeration. */
export enum GqlPaymentStatus {
  Notpaid = 'NOTPAID',
  Fullypaid = 'FULLYPAID',
  Partiallypaid = 'PARTIALLYPAID',
  Inprogress = 'INPROGRESS'
}

export type GqlVehicleSupplierStatementFileGql = {
  __typename?: 'VehicleSupplierStatementFileGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  statement_file_id: Scalars['String'];
  statement_id: Scalars['String'];
  file_url: Scalars['String'];
  vehicle_supplier_statement: Maybe<GqlVehicleSupplierStatementGql>;
};

export type GqlChecklistEntryItemGql = {
  __typename?: 'ChecklistEntryItemGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  entry_item_id: Scalars['String'];
  entry_id: Maybe<Scalars['String']>;
  question_id: Maybe<Scalars['String']>;
  okay: Scalars['Boolean'];
  comment: Maybe<Scalars['String']>;
  question: Maybe<GqlChecklistQuestionGql>;
  entry: Maybe<GqlChecklistEntryGql>;
};

export type GqlIncidentCategoryGql = {
  __typename?: 'IncidentCategoryGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  incident_category_id: Scalars['String'];
  name: Scalars['String'];
  parent_id: Maybe<Scalars['String']>;
  parent: Maybe<GqlIncidentCategoryGql>;
  incident_categories: Maybe<Array<Maybe<GqlIncidentCategoryGql>>>;
};

export type GqlIncidentGqlConnection = {
  __typename?: 'IncidentGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlIncidentGqlEdge>>;
};

/** A Relay edge containing a `IncidentGQL` and its cursor. */
export type GqlIncidentGqlEdge = {
  __typename?: 'IncidentGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlIncidentGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlIncidentGql = {
  __typename?: 'IncidentGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  incident_id: Scalars['String'];
  incident_category_id: Scalars['String'];
  incident_number: Scalars['String'];
  vehicle_id: Scalars['String'];
  depot_id: Scalars['String'];
  report_date: Scalars['DateTime'];
  reported_by_id: Scalars['String'];
  incident_status: Scalars['Boolean'];
  incident_comment: Maybe<Scalars['String']>;
  closing_comment: Maybe<Scalars['String']>;
  closed_by_id: Maybe<Scalars['String']>;
  image_url: Maybe<Scalars['String']>;
  images: Array<Maybe<Scalars['String']>>;
  vehicle: Maybe<GqlVehicleGql>;
  incident_category: Maybe<GqlIncidentCategoryGql>;
  depot: Maybe<GqlDepotV2Gql>;
  reported_by: Maybe<GqlUserV2Gql>;
  closed_by: Maybe<GqlUserV2Gql>;
};

/** Pagination Connection for VehicleSupplierGQL */
export type GqlVehicleSupplierGqlConnection = {
  __typename?: 'VehicleSupplierGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlVehicleSupplierGqlEdge>>;
};

/** A Relay edge containing a `VehicleSupplierGQL` and its cursor. */
export type GqlVehicleSupplierGqlEdge = {
  __typename?: 'VehicleSupplierGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlVehicleSupplierGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** Pagination Connection for VehicleSupplierPaymentAccountGQL */
export type GqlVehicleSupplierPaymentAccountGqlConnection = {
  __typename?: 'VehicleSupplierPaymentAccountGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlVehicleSupplierPaymentAccountGqlEdge>>;
};

/** A Relay edge containing a `VehicleSupplierPaymentAccountGQL` and its cursor. */
export type GqlVehicleSupplierPaymentAccountGqlEdge = {
  __typename?: 'VehicleSupplierPaymentAccountGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlVehicleSupplierPaymentAccountGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** Pagination Connection for TripGQL */
export type GqlTripGqlConnection = {
  __typename?: 'TripGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlTripGqlEdge>>;
};

/** A Relay edge containing a `TripGQL` and its cursor. */
export type GqlTripGqlEdge = {
  __typename?: 'TripGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlTripGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** Pagination Connection for VehicleSupplierStatementFileGQL */
export type GqlVehicleSupplierStatementFileGqlConnection = {
  __typename?: 'VehicleSupplierStatementFileGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlVehicleSupplierStatementFileGqlEdge>>;
};

/** A Relay edge containing a `VehicleSupplierStatementFileGQL` and its cursor. */
export type GqlVehicleSupplierStatementFileGqlEdge = {
  __typename?: 'VehicleSupplierStatementFileGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlVehicleSupplierStatementFileGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** Pagination Connection for VehicleSupplierStatementGQL */
export type GqlVehicleSupplierStatementGqlConnection = {
  __typename?: 'VehicleSupplierStatementGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlVehicleSupplierStatementGqlEdge>>;
};

/** A Relay edge containing a `VehicleSupplierStatementGQL` and its cursor. */
export type GqlVehicleSupplierStatementGqlEdge = {
  __typename?: 'VehicleSupplierStatementGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlVehicleSupplierStatementGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** Pagination Connection for VehicleTypeGQL */
export type GqlVehicleTypeGqlConnection = {
  __typename?: 'VehicleTypeGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlVehicleTypeGqlEdge>>;
};

/** A Relay edge containing a `VehicleTypeGQL` and its cursor. */
export type GqlVehicleTypeGqlEdge = {
  __typename?: 'VehicleTypeGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlVehicleTypeGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** Pagination Connection for VehicleGQL */
export type GqlVehicleGqlConnection = {
  __typename?: 'VehicleGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlVehicleGqlEdge>>;
};

/** A Relay edge containing a `VehicleGQL` and its cursor. */
export type GqlVehicleGqlEdge = {
  __typename?: 'VehicleGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlVehicleGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlRoutePlanGqlConnection = {
  __typename?: 'RoutePlanGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlRoutePlanGqlEdge>>;
};

/** A Relay edge containing a `RoutePlanGQL` and its cursor. */
export type GqlRoutePlanGqlEdge = {
  __typename?: 'RoutePlanGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlRoutePlanGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** Pagination Connection for Fulfillment Team GQL */
export type GqlFulfillmentTeamGqlConnection = {
  __typename?: 'FulfillmentTeamGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlFulfillmentTeamGqlEdge>>;
};

/** A Relay edge containing a `FulfillmentTeamGQL` and its cursor. */
export type GqlFulfillmentTeamGqlEdge = {
  __typename?: 'FulfillmentTeamGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlFulfillmentTeamGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlStaticRouteGqlConnection = {
  __typename?: 'StaticRouteGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlStaticRouteGqlEdge>>;
};

/** A Relay edge containing a `StaticRouteGQL` and its cursor. */
export type GqlStaticRouteGqlEdge = {
  __typename?: 'StaticRouteGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlStaticRouteGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlStaticRouteGql = {
  __typename?: 'StaticRouteGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  static_route_id: Scalars['String'];
  delivery_date: Scalars['String'];
  status: Scalars['String'];
  route_name: Scalars['String'];
  route_id: Scalars['String'];
  distance: Scalars['Float'];
  path: Scalars['String'];
  shops: Array<Maybe<Scalars['JSONString']>>;
  purged_shops: Maybe<Array<Maybe<Scalars['JSONString']>>>;
  flagged_shops: Maybe<Array<Maybe<Scalars['JSONString']>>>;
  total_amount: Scalars['Float'];
  revenue_per_kilometer: Scalars['Float'];
  errors: Maybe<Array<Maybe<Scalars['JSONString']>>>;
};

export type GqlPseudoRouteGqlConnection = {
  __typename?: 'PseudoRouteGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlPseudoRouteGqlEdge>>;
};

/** A Relay edge containing a `PseudoRouteGQL` and its cursor. */
export type GqlPseudoRouteGqlEdge = {
  __typename?: 'PseudoRouteGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlPseudoRouteGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlPseudoRouteGql = {
  __typename?: 'PseudoRouteGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  pseudo_route_id: Scalars['String'];
  name: Scalars['String'];
  merged_routes: Array<Maybe<Scalars['String']>>;
  constraints: Array<Maybe<Scalars['String']>>;
  force_share: Scalars['Boolean'];
  custom_constraints: Scalars['JSONString'];
  routes: Array<Maybe<GqlRouteV2Gql>>;
};

export type GqlPseudoRoutesVehicleAssignmentGqlConnection = {
  __typename?: 'PseudoRoutesVehicleAssignmentGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlPseudoRoutesVehicleAssignmentGqlEdge>>;
};

/** A Relay edge containing a `PseudoRoutesVehicleAssignmentGQL` and its cursor. */
export type GqlPseudoRoutesVehicleAssignmentGqlEdge = {
  __typename?: 'PseudoRoutesVehicleAssignmentGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlPseudoRoutesVehicleAssignmentGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlPseudoRoutesVehicleAssignmentGql = {
  __typename?: 'PseudoRoutesVehicleAssignmentGQL';
  delivery_date: Maybe<Scalars['String']>;
  route_id: Maybe<Scalars['String']>;
  user_id: Maybe<Scalars['String']>;
  combined_routes: Maybe<Scalars['String']>;
  unique_shops: Maybe<Scalars['Int']>;
  ordered_weight: Maybe<Scalars['Float']>;
  available_vehicles: Maybe<Scalars['Int']>;
  load_capacity: Maybe<Scalars['Float']>;
  shops_vs_vehicles: Maybe<Scalars['String']>;
  ordered_weight_vs_capacity: Maybe<Scalars['String']>;
  route: Maybe<GqlRouteV2Gql>;
  distance: Maybe<Scalars['Float']>;
  user: Maybe<GqlUserV2Gql>;
};

export type GqlUploadIncidentImage = {
  __typename?: 'UploadIncidentImage';
  uploaded: Maybe<Scalars['Boolean']>;
  incident: Maybe<GqlIncidentGql>;
};


export type GqlCreateIncidentCategory = {
  __typename?: 'CreateIncidentCategory';
  created: Maybe<Scalars['Boolean']>;
  incident_category: Maybe<GqlIncidentCategoryGql>;
};

export type GqlIncidentCategoryInput = {
  created_by?: Maybe<Scalars['String']>;
  updated_by?: Maybe<Scalars['String']>;
  client_id?: Maybe<Scalars['String']>;
  client_stamp?: Maybe<Scalars['String']>;
  client_timestamp?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['String']>;
};

export type GqlUpdateIncidentCategory = {
  __typename?: 'UpdateIncidentCategory';
  incident_category: Maybe<GqlIncidentCategoryGql>;
};

export type GqlDeleteIncidentCategory = {
  __typename?: 'DeleteIncidentCategory';
  deleted: Maybe<Scalars['Boolean']>;
};

export type GqlCreateIncident = {
  __typename?: 'CreateIncident';
  created: Maybe<Scalars['Boolean']>;
  incident: Maybe<GqlIncidentGql>;
};

export type GqlIncidentInput = {
  created_by?: Maybe<Scalars['String']>;
  updated_by?: Maybe<Scalars['String']>;
  client_id?: Maybe<Scalars['String']>;
  client_stamp?: Maybe<Scalars['String']>;
  client_timestamp?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  incident_category_id?: Maybe<Scalars['String']>;
  incident_number?: Maybe<Scalars['String']>;
  vehicle_id?: Maybe<Scalars['String']>;
  depot_id?: Maybe<Scalars['String']>;
  report_date?: Maybe<Scalars['String']>;
  reported_by_id?: Maybe<Scalars['String']>;
  incident_status?: Maybe<Scalars['Boolean']>;
  incident_comment?: Maybe<Scalars['String']>;
  closing_comment?: Maybe<Scalars['String']>;
  closed_by_id?: Maybe<Scalars['String']>;
};

export type GqlUpdateIncident = {
  __typename?: 'UpdateIncident';
  incident: Maybe<GqlIncidentGql>;
};

export type GqlDeleteIncident = {
  __typename?: 'DeleteIncident';
  deleted: Maybe<Scalars['Boolean']>;
};

/** An object with an ID */
export type GqlNode = {
  /** The ID of the object. */
  id: Scalars['ID'];
};

export type GqlDispatchGqlConnection = {
  __typename?: 'DispatchGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlDispatchGqlEdge>>;
};

/** A Relay edge containing a `DispatchGQL` and its cursor. */
export type GqlDispatchGqlEdge = {
  __typename?: 'DispatchGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlDispatchGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlDispatchGql = GqlNode & {
  __typename?: 'DispatchGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  distribution_center_id: Maybe<Scalars['String']>;
  dispatch_id: Scalars['String'];
  dispatch_number: Scalars['String'];
  depot_id: Scalars['String'];
  sale_date: Scalars['String'];
  dispatch_type: GqlDispatchType;
  stock_transfer_id: Maybe<Scalars['String']>;
  cancellation_reason: Maybe<Scalars['String']>;
  dispatch_items: Maybe<Array<Maybe<GqlDispatchItemGql>>>;
  dispatch: Maybe<GqlDispatchItemGql>;
  /** The ID of the object. */
  id: Scalars['ID'];
  total_products: Maybe<Scalars['Float']>;
  depot: Maybe<GqlDepotV2Gql>;
  user: Maybe<GqlUserV2Gql>;
  distribution_center: Maybe<GqlDistributionCenterGql>;
};

/** An enumeration. */
export enum GqlDispatchType {
  Dispatch = 'DISPATCH',
  StockTransfer = 'STOCK_TRANSFER'
}

export type GqlDispatchItemGql = {
  __typename?: 'DispatchItemGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  dispatch_item_id: Scalars['String'];
  dispatch_id: Scalars['String'];
  route_id: Scalars['String'];
  product_item_id: Scalars['String'];
  unit_of_measure_id: Scalars['String'];
  quantity: Scalars['Float'];
  weight: Scalars['Float'];
  packaging_unit_id: Maybe<Scalars['String']>;
  packaging_unit_quantity: Maybe<Scalars['Int']>;
  cancellation_reason: Maybe<Scalars['String']>;
  dispatch: Maybe<GqlDispatchGql>;
  dispatch_items: Maybe<GqlDispatchGql>;
  product_item: Maybe<GqlProductItemV2Gql>;
  uom: Maybe<GqlUomv2Gql>;
  packaging_unit: Maybe<GqlPackagingUnitV2Gql>;
  route: Maybe<GqlRouteV2Gql>;
};

export type GqlPackagingUnitV2Gql = {
  __typename?: 'PackagingUnitV2GQL';
  packaging_unit_id: Scalars['String'];
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  name: Scalars['String'];
  returnable: Scalars['Boolean'];
  weight: Scalars['Float'];
  etims_packaging_code: Maybe<Scalars['String']>;
};

export type GqlDispatchItemGqlConnection = {
  __typename?: 'DispatchItemGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlDispatchItemGqlEdge>>;
};

/** A Relay edge containing a `DispatchItemGQL` and its cursor. */
export type GqlDispatchItemGqlEdge = {
  __typename?: 'DispatchItemGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlDispatchItemGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlStockTransferGqlConnection = {
  __typename?: 'StockTransferGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlStockTransferGqlEdge>>;
};

/** A Relay edge containing a `StockTransferGQL` and its cursor. */
export type GqlStockTransferGqlEdge = {
  __typename?: 'StockTransferGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlStockTransferGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlStockTransferGql = GqlNode & {
  __typename?: 'StockTransferGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  stock_transfer_id: Scalars['String'];
  stock_transfer_number: Scalars['String'];
  sale_date: Scalars['String'];
  product_item_id: Scalars['String'];
  depot_id: Scalars['String'];
  route_id_from: Scalars['String'];
  cancellation_reason: Maybe<Scalars['String']>;
  stock_transfer_items: Maybe<Array<Maybe<GqlStockTransferItemGql>>>;
  stock_transfer: Maybe<GqlStockTransferItemGql>;
  /** The ID of the object. */
  id: Scalars['ID'];
  product_item: Maybe<GqlProductItemV2Gql>;
  route_from: Maybe<GqlRouteV2Gql>;
  user: Maybe<GqlUserV2Gql>;
};

export type GqlStockTransferItemGql = {
  __typename?: 'StockTransferItemGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  stock_transfer_item_id: Scalars['String'];
  stock_transfer_id: Scalars['String'];
  unit_of_measure_id: Scalars['String'];
  quantity: Scalars['Float'];
  packaging_unit_id: Maybe<Scalars['String']>;
  packaging_unit_quantity: Maybe<Scalars['Int']>;
  route_id_to: Scalars['String'];
  stock_transfer: Maybe<GqlStockTransferGql>;
  stock_transfer_items: Maybe<GqlStockTransferGql>;
  uom: Maybe<GqlUomv2Gql>;
  packaging_unit: Maybe<GqlPackagingUnitV2Gql>;
  route_to: Maybe<GqlRouteV2Gql>;
};

export type GqlStockTransferItemGqlConnection = {
  __typename?: 'StockTransferItemGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlStockTransferItemGqlEdge>>;
};

/** A Relay edge containing a `StockTransferItemGQL` and its cursor. */
export type GqlStockTransferItemGqlEdge = {
  __typename?: 'StockTransferItemGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlStockTransferItemGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlDispatchBalanceGql = {
  __typename?: 'DispatchBalanceGQL';
  route_id: Maybe<Scalars['String']>;
  product_item_id: Maybe<Scalars['String']>;
  date: Maybe<Scalars['String']>;
  stock_balance: Maybe<Scalars['Float']>;
  packaging_balance: Maybe<Scalars['Float']>;
  product_item: Maybe<GqlProductItemV2Gql>;
};

export type GqlDepotIntakeGqlConnection = {
  __typename?: 'DepotIntakeGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlDepotIntakeGqlEdge>>;
};

/** A Relay edge containing a `DepotIntakeGQL` and its cursor. */
export type GqlDepotIntakeGqlEdge = {
  __typename?: 'DepotIntakeGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlDepotIntakeGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlDepotIntakeGql = GqlNode & {
  __typename?: 'DepotIntakeGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  depot_intake_id: Scalars['String'];
  depot_intake_number: Scalars['String'];
  sale_date: Scalars['String'];
  depot_id: Scalars['String'];
  route_id: Scalars['String'];
  cancellation_reason: Maybe<Scalars['String']>;
  depot_intake_items: Maybe<Array<Maybe<GqlDepotIntakeItemGql>>>;
  depot_intake: Maybe<GqlDepotIntakeItemGql>;
  /** The ID of the object. */
  id: Scalars['ID'];
  route: Maybe<GqlRouteV2Gql>;
  user: Maybe<GqlUserV2Gql>;
};

export type GqlDepotIntakeItemGql = {
  __typename?: 'DepotIntakeItemGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  depot_intake_item_id: Scalars['String'];
  depot_intake_id: Scalars['String'];
  product_id: Scalars['String'];
  product_item_id: Scalars['String'];
  quantity: Scalars['Float'];
  unit_of_measure_id: Scalars['String'];
  packaging_unit_id: Maybe<Scalars['String']>;
  packaging_unit_quantity: Maybe<Scalars['Int']>;
  depot_intake: Maybe<GqlDepotIntakeGql>;
  depot_intake_item: Maybe<GqlDepotIntakeGql>;
  product_item: Maybe<GqlProductItemV2Gql>;
  uom: Maybe<GqlUomv2Gql>;
  product: Maybe<GqlProductV2Gql>;
};

export type GqlDepotIntakeItemGqlConnection = {
  __typename?: 'DepotIntakeItemGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlDepotIntakeItemGqlEdge>>;
};

/** A Relay edge containing a `DepotIntakeItemGQL` and its cursor. */
export type GqlDepotIntakeItemGqlEdge = {
  __typename?: 'DepotIntakeItemGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlDepotIntakeItemGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlReturnGqlConnection = {
  __typename?: 'ReturnGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlReturnGqlEdge>>;
};

/** A Relay edge containing a `ReturnGQL` and its cursor. */
export type GqlReturnGqlEdge = {
  __typename?: 'ReturnGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlReturnGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlReturnGql = GqlNode & {
  __typename?: 'ReturnGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  distribution_center_id: Maybe<Scalars['String']>;
  return_id: Scalars['String'];
  return_number: Scalars['String'];
  sale_date: Scalars['String'];
  depot_id: Scalars['String'];
  route_id: Maybe<Scalars['String']>;
  return_level: GqlReturnLevel;
  cancellation_reason: Maybe<Scalars['String']>;
  route_plan_id: Maybe<Scalars['String']>;
  product_return_items: Maybe<Array<Maybe<GqlProductReturnItemGql>>>;
  packaging_return_items: Maybe<Array<Maybe<GqlPackagingReturnItemGql>>>;
  /** The ID of the object. */
  id: Scalars['ID'];
  route: Maybe<GqlRouteV2Gql>;
  depot: Maybe<GqlDepotV2Gql>;
  user: Maybe<GqlUserV2Gql>;
  distribution_center: Maybe<GqlDistributionCenterGql>;
  route_plan: Maybe<GqlRoutePlanGql>;
};

/** An enumeration. */
export enum GqlReturnLevel {
  Depot = 'DEPOT',
  PackHouse = 'PACK_HOUSE',
  DcDepot = 'DC_DEPOT'
}

export type GqlProductReturnItemGql = {
  __typename?: 'ProductReturnItemGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  product_return_item_id: Scalars['String'];
  return_id: Scalars['String'];
  product_item_id: Scalars['String'];
  unit_of_measure_id: Scalars['String'];
  quantity: Scalars['Float'];
  weight: Maybe<Scalars['Float']>;
  product_loss_reason_id: Maybe<Scalars['String']>;
  cancellation_reason: Maybe<Scalars['String']>;
  picture: Maybe<Scalars['String']>;
  return_type: Maybe<GqlReturnType>;
  returns: Maybe<GqlReturnGql>;
  product_item: Maybe<GqlProductItemV2Gql>;
  uom: Maybe<GqlUomv2Gql>;
};

/** An enumeration. */
export enum GqlReturnType {
  Disposed = 'DISPOSED',
  Resale = 'RESALE',
  Conforming = 'CONFORMING',
  Nonconforming = 'NONCONFORMING'
}

export type GqlPackagingReturnItemGql = {
  __typename?: 'PackagingReturnItemGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  packaging_return_item_id: Scalars['String'];
  return_id: Scalars['String'];
  quantity: Scalars['Float'];
  packaging_unit_id: Maybe<Scalars['String']>;
  cancellation_reason: Maybe<Scalars['String']>;
  returns: Maybe<GqlReturnGql>;
  packaging_unit: Maybe<GqlPackagingUnitV2Gql>;
};

export type GqlProductReturnItemGqlConnection = {
  __typename?: 'ProductReturnItemGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlProductReturnItemGqlEdge>>;
};

/** A Relay edge containing a `ProductReturnItemGQL` and its cursor. */
export type GqlProductReturnItemGqlEdge = {
  __typename?: 'ProductReturnItemGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlProductReturnItemGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlPackagingReturnItemGqlConnection = {
  __typename?: 'PackagingReturnItemGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlPackagingReturnItemGqlEdge>>;
};

/** A Relay edge containing a `PackagingReturnItemGQL` and its cursor. */
export type GqlPackagingReturnItemGqlEdge = {
  __typename?: 'PackagingReturnItemGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlPackagingReturnItemGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlDispatchProductSummaryV2GqlConnection = {
  __typename?: 'DispatchProductSummaryV2GQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlDispatchProductSummaryV2GqlEdge>>;
};

/** A Relay edge containing a `DispatchProductSummaryV2GQL` and its cursor. */
export type GqlDispatchProductSummaryV2GqlEdge = {
  __typename?: 'DispatchProductSummaryV2GQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlDispatchProductSummaryV2Gql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlDispatchProductSummaryV2Gql = {
  __typename?: 'DispatchProductSummaryV2GQL';
  date: Maybe<Scalars['String']>;
  product_item_id: Maybe<Scalars['String']>;
  dispatch_quantity: Maybe<Scalars['Float']>;
  intake_quantity: Maybe<Scalars['Float']>;
  variance: Maybe<Scalars['Float']>;
};

export type GqlProjectedPhRevenueV2Gql = {
  __typename?: 'ProjectedPhRevenueV2GQL';
  date: Maybe<Scalars['String']>;
  projected_ph_revenue: Maybe<Scalars['Float']>;
};

export type GqlVehicleStockAllocationGqlConnection = {
  __typename?: 'VehicleStockAllocationGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlVehicleStockAllocationGqlEdge>>;
};

/** A Relay edge containing a `VehicleStockAllocationGQL` and its cursor. */
export type GqlVehicleStockAllocationGqlEdge = {
  __typename?: 'VehicleStockAllocationGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlVehicleStockAllocationGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlVehicleStockAllocationGql = {
  __typename?: 'VehicleStockAllocationGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  vehicle_stock_allocation_id: Scalars['String'];
  delivery_date: Scalars['String'];
  product_id: Maybe<Scalars['String']>;
  product_item_id: Scalars['String'];
  depot_id: Scalars['String'];
  route_plan_id: Scalars['String'];
  quantity: Scalars['Float'];
  packaging_unit_id: Maybe<Scalars['String']>;
  packaging_unit_quantity: Maybe<Scalars['Int']>;
  unit_of_measure_id: Scalars['String'];
  weight: Scalars['Float'];
  confirmed: Maybe<Scalars['Boolean']>;
  country_id: Maybe<Scalars['String']>;
  product_item: Maybe<GqlProductItemV2Gql>;
  uom: Maybe<GqlUomv2Gql>;
  packaging_unit: Maybe<GqlPackagingUnitV2Gql>;
  depot: Maybe<GqlDepotV2Gql>;
  route_plan: Maybe<GqlRoutePlanGql>;
};

export type GqlVehicleDeliveryAndLoadingGqlConnection = {
  __typename?: 'VehicleDeliveryAndLoadingGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlVehicleDeliveryAndLoadingGqlEdge>>;
};

/** A Relay edge containing a `VehicleDeliveryAndLoadingGQL` and its cursor. */
export type GqlVehicleDeliveryAndLoadingGqlEdge = {
  __typename?: 'VehicleDeliveryAndLoadingGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlVehicleDeliveryAndLoadingGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlVehicleDeliveryAndLoadingGql = {
  __typename?: 'VehicleDeliveryAndLoadingGQL';
  depot_id: Maybe<Scalars['String']>;
  route_plan_id: Maybe<Scalars['String']>;
  delivery_date: Maybe<Scalars['String']>;
  vehicle_allocated_amount: Maybe<Scalars['Float']>;
  delivery_payments: Maybe<Array<Maybe<GqlDeliveryPaymentGql>>>;
  depot: Maybe<GqlDepotV2Gql>;
  route_plan: Maybe<GqlRoutePlanGql>;
};

export type GqlDeliveryPaymentGql = {
  __typename?: 'DeliveryPaymentGQL';
  payment_mode: Maybe<Scalars['String']>;
  amount: Maybe<Scalars['Float']>;
};

export type GqlVehicleDeliveryAndLoadingSummaryGql = {
  __typename?: 'VehicleDeliveryAndLoadingSummaryGQL';
  total_loaded_amount: Maybe<Scalars['Float']>;
  total_delivered_amount: Maybe<Scalars['Float']>;
};

/** Pagination Connection for PricingGQL type. */
export type GqlPricingV2GqlConnection = {
  __typename?: 'PricingV2GQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlPricingV2GqlEdge>>;
};

/** A Relay edge containing a `PricingV2GQL` and its cursor. */
export type GqlPricingV2GqlEdge = {
  __typename?: 'PricingV2GQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlPricingV2Gql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlPricingV2Gql = GqlNode & {
  __typename?: 'PricingV2GQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  pricing_id: Scalars['String'];
  product_item_id: Scalars['String'];
  unit_of_measure_id: Scalars['String'];
  pricing_date: Scalars['String'];
  base_price: Scalars['Float'];
  rrp: Scalars['Float'];
  currency: GqlCurrencyEnum;
  region_id: Scalars['String'];
  previous_price: Maybe<Scalars['Float']>;
  discount_tag: Maybe<Scalars['Boolean']>;
  discount_value_type: Maybe<GqlDiscountValueTypeEnum>;
  /** The ID of the object. */
  id: Scalars['ID'];
  product_item: Maybe<GqlProductItemV2Gql>;
  user: Maybe<GqlUserV2Gql>;
  uom: Maybe<GqlUomv2Gql>;
};

/** An enumeration. */
export enum GqlDiscountValueTypeEnum {
  Percentage = 'PERCENTAGE',
  Value = 'VALUE'
}

/** Pagination Connection for DynamicPricingGQL type. */
export type GqlDynamicPricingGqlConnection = {
  __typename?: 'DynamicPricingGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlDynamicPricingGqlEdge>>;
};

/** A Relay edge containing a `DynamicPricingGQL` and its cursor. */
export type GqlDynamicPricingGqlEdge = {
  __typename?: 'DynamicPricingGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlDynamicPricingGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** DynamicPricingGQL type. */
export type GqlDynamicPricingGql = GqlNode & {
  __typename?: 'DynamicPricingGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  dynamic_pricing_id: Scalars['String'];
  pricing_name: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  start_time: Maybe<Scalars['DateTime']>;
  end_time: Maybe<Scalars['DateTime']>;
  exclusive: Maybe<Scalars['Boolean']>;
  discount_type: Maybe<GqlDiscountTypeEnum>;
  value: Maybe<Scalars['Float']>;
  formula: Scalars['JSONString'];
  markdown_display: Maybe<GqlDiscountValueTypeEnum>;
  /** The ID of the object. */
  id: Scalars['ID'];
  user: Maybe<GqlUserV2Gql>;
};

/** An enumeration. */
export enum GqlDiscountTypeEnum {
  Ratio = 'RATIO',
  Value = 'VALUE',
  FixedPrice = 'FIXED_PRICE',
  PriceMarkdown = 'PRICE_MARKDOWN'
}

/** PriceComparisonGQL type. */
export type GqlPriceComparisonGql = GqlNode & {
  __typename?: 'PriceComparisonGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  price_comparison_id: Scalars['String'];
  product_item_id: Scalars['String'];
  region: Scalars['String'];
  region_id: Scalars['String'];
  competitor_name: Scalars['String'];
  competitor_price: Scalars['Float'];
  pricing_date: Scalars['String'];
  best_price: Scalars['Boolean'];
  /** The ID of the object. */
  id: Scalars['ID'];
};

export type GqlBatchGqlConnection = {
  __typename?: 'BatchGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlBatchGqlEdge>>;
};

/** A Relay edge containing a `BatchGQL` and its cursor. */
export type GqlBatchGqlEdge = {
  __typename?: 'BatchGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlBatchGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlBatchGql = {
  __typename?: 'BatchGQL';
  field_weight: Maybe<Scalars['Float']>;
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  batch_id: Scalars['String'];
  batch_name: Scalars['String'];
  batch_date: Scalars['String'];
  collection_center_id: Maybe<Scalars['String']>;
  area_id: Maybe<Scalars['String']>;
  lpo_number: Maybe<Scalars['String']>;
  farmer_code: Maybe<Scalars['String']>;
  harvests: Maybe<Array<Maybe<GqlHarvestGql>>>;
  area: Maybe<GqlAreaGql>;
  collection_center: Maybe<GqlCollectionCenterGql>;
  intake_weight: Maybe<Scalars['Float']>;
  processing_loss_weight: Maybe<Scalars['Float']>;
  appointment_items: Maybe<Array<Maybe<GqlAppointmentItemGql>>>;
  stock_intake_items_batch: Maybe<GqlStockIntakeItemBatchGql>;
};

export type GqlHarvestGql = {
  __typename?: 'HarvestGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  cancelled: Scalars['Boolean'];
  cancellation_reason: Maybe<Scalars['String']>;
  harvest_id: Scalars['String'];
  collection_center_id: Scalars['String'];
  farm_id: Maybe<Scalars['String']>;
  farmer_id: Maybe<Scalars['String']>;
  batch_id: Maybe<Scalars['String']>;
  country_id: Scalars['String'];
  product_id: Maybe<Scalars['String']>;
  product_item_id: Maybe<Scalars['String']>;
  unit_of_measure_id: Maybe<Scalars['String']>;
  receipt_number: Scalars['String'];
  receipt_url: Maybe<Scalars['String']>;
  unit_price: Maybe<Scalars['Float']>;
  original_currency: Scalars['String'];
  fx_rate: Maybe<Scalars['Float']>;
  quantity: Maybe<Scalars['Float']>;
  weight: Maybe<Scalars['Float']>;
  total_payable: Maybe<Scalars['Float']>;
  harvest_date: Scalars['String'];
  harvested_by: Maybe<Scalars['String']>;
  packaging_unit_id: Maybe<Scalars['String']>;
  packaging_unit_quantity: Maybe<Scalars['Int']>;
  invoice_number: Maybe<Scalars['String']>;
  control_unit_invoice_number: Maybe<Scalars['String']>;
  farm: Maybe<GqlFarmGql>;
  collection_center: Maybe<GqlCollectionCenterGql>;
  harvest_issues: Maybe<GqlHarvestIssueGql>;
  batch: Maybe<GqlBatchGql>;
  farmer: Maybe<GqlFarmerGql>;
  harvest_items: Maybe<Array<Maybe<GqlHarvestItemGql>>>;
  harvest_payment_batch_detail: Maybe<GqlPaymentDetailGql>;
  payment: Maybe<GqlHarvestPaymentGql>;
  signed_receipt_url: Maybe<Scalars['String']>;
  product: Maybe<GqlProductV2Gql>;
  product_item: Maybe<GqlProductItemV2Gql>;
  harvester: Maybe<GqlUserV2Gql>;
  packaging_unit: Maybe<GqlPackagingUnitV2Gql>;
  uom: Maybe<GqlUomv2Gql>;
  country: Maybe<GqlCountryGql>;
};

export type GqlFarmGql = GqlNode & {
  __typename?: 'FarmGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  farm_id: Scalars['String'];
  farm_number: Scalars['Int'];
  farm_name: Maybe<Scalars['String']>;
  farmer_type: Scalars['String'];
  farm_size: Scalars['Float'];
  farmer_id: Scalars['String'];
  latitude: Maybe<Scalars['Float']>;
  longitude: Maybe<Scalars['Float']>;
  harvest_area_id: Scalars['String'];
  agent_farm: Maybe<Scalars['Boolean']>;
  products: Maybe<Array<Maybe<GqlFarmProductGql>>>;
  farmer: Maybe<GqlFarmerGql>;
  harvest_area: Maybe<GqlHarvestAreaGql>;
  harvest: Maybe<GqlHarvestGql>;
  booking: Maybe<GqlBookingGql>;
  /** The ID of the object. */
  id: Scalars['ID'];
};

export type GqlFarmProductGql = GqlNode & {
  __typename?: 'FarmProductGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  farm_product_id: Scalars['String'];
  product_id: Scalars['String'];
  farm_id: Scalars['String'];
  farm: Maybe<GqlFarmGql>;
  /** The ID of the object. */
  id: Scalars['ID'];
  product: Maybe<GqlProductV2Gql>;
};

export type GqlFarmerGql = GqlNode & {
  __typename?: 'FarmerGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  farmer_id: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  farmer_number: Scalars['Int'];
  phone_number: Scalars['String'];
  alt_phone_number: Maybe<Scalars['String']>;
  farmer_type: Maybe<Scalars['String']>;
  supplier_name: Maybe<Scalars['String']>;
  supplier_kra_pin: Maybe<Scalars['String']>;
  email_address: Maybe<Scalars['String']>;
  country_id: Scalars['String'];
  discovery_id: Maybe<Scalars['String']>;
  discovery_other: Maybe<Scalars['String']>;
  locale: Scalars['String'];
  farmer_payment_accounts: Maybe<Array<Maybe<GqlFarmerPaymentAccountGql>>>;
  agent: Maybe<GqlSourcingAgentGql>;
  discovery: Maybe<GqlDiscoveryGql>;
  supplier_products: Maybe<Array<Maybe<GqlSupplierProductGql>>>;
  supplier_users: Maybe<Array<Maybe<GqlSupplierUserGql>>>;
  farms: Maybe<Array<Maybe<GqlFarmGql>>>;
  harvests: Maybe<GqlHarvestGql>;
  harvest_payment_batch_detail: Maybe<GqlPaymentDetailGql>;
  bookings: Maybe<GqlBookingGql>;
  /** The ID of the object. */
  id: Scalars['ID'];
  primary_payment_account: Maybe<GqlFarmerPaymentAccountGql>;
  batch_code: Maybe<Scalars['String']>;
  country: Maybe<GqlCountryGql>;
};

export type GqlFarmerPaymentAccountGql = GqlNode & {
  __typename?: 'FarmerPaymentAccountGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  farmer_payment_account_id: Scalars['String'];
  farmer_id: Scalars['String'];
  account_type: Maybe<Scalars['String']>;
  party: Scalars['String'];
  party_code: Maybe<Scalars['String']>;
  account_number: Scalars['String'];
  account_name: Scalars['String'];
  is_primary: Scalars['Boolean'];
  farmer_payment_accounts: Maybe<GqlFarmerGql>;
  /** The ID of the object. */
  id: Scalars['ID'];
};

export type GqlSourcingAgentGql = {
  __typename?: 'SourcingAgentGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  agent_id: Scalars['String'];
  farmer_id: Scalars['String'];
  user_name: Scalars['String'];
  password: Scalars['String'];
  last_login: Maybe<Scalars['DateTime']>;
  locale: Scalars['String'];
  farmer: Maybe<GqlFarmerGql>;
  agent: Maybe<GqlBidApplicationGql>;
  products: Maybe<Array<Maybe<Scalars['GenericScalar']>>>;
};

export type GqlBidApplicationGql = {
  __typename?: 'BidApplicationGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  bid_application_id: Scalars['String'];
  bid_id: Scalars['String'];
  bid_application_number: Scalars['String'];
  farmer_id: Scalars['String'];
  agent_id: Scalars['String'];
  bid_application_date: Scalars['DateTime'];
  delivery_date: Scalars['DateTime'];
  product_id: Scalars['String'];
  product_item_id: Maybe<Scalars['String']>;
  unit_of_measure_id: Scalars['String'];
  quantity: Scalars['Float'];
  unit_price: Scalars['Float'];
  original_currency: Scalars['String'];
  fx_rate: Maybe<Scalars['Float']>;
  bid_application_status: Maybe<Scalars['String']>;
  cancellation_reason: Maybe<Scalars['String']>;
  vehicle_registration: Maybe<Scalars['String']>;
  bid_application_status_type: Maybe<Scalars['String']>;
  latitude: Maybe<Scalars['Float']>;
  longitude: Maybe<Scalars['Float']>;
  country_id: Maybe<Scalars['String']>;
  currency: Maybe<Scalars['String']>;
  bid: Maybe<GqlBidGql>;
  agent: Maybe<GqlSourcingAgentGql>;
  agent_feedback: Maybe<Array<Maybe<GqlAgentFeedbackGql>>>;
  product: Maybe<GqlProductV2Gql>;
  product_item: Maybe<GqlProductItemV2Gql>;
  uom: Maybe<GqlUomv2Gql>;
  country: Maybe<GqlCountryGql>;
};

export type GqlBidGql = {
  __typename?: 'BidGQL';
  bid_application_count: Maybe<Scalars['Int']>;
  bid_application_lowest_price: Maybe<Scalars['Float']>;
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  bid_id: Scalars['String'];
  bid_number: Scalars['String'];
  opening_bid_price: Scalars['Float'];
  minimum_quantity: Scalars['Float'];
  product_id: Scalars['String'];
  product_item_id: Maybe<Scalars['String']>;
  unit_of_measure_id: Scalars['String'];
  quantity: Scalars['Float'];
  opening_date: Scalars['DateTime'];
  closing_date: Scalars['DateTime'];
  delivery_date: Scalars['DateTime'];
  bid_notes: Maybe<Scalars['String']>;
  bid_status: Maybe<Scalars['String']>;
  cancellation_reason: Maybe<Scalars['String']>;
  country_id: Maybe<Scalars['String']>;
  currency: Maybe<Scalars['String']>;
  bid_applications: Maybe<Array<Maybe<GqlBidApplicationGql>>>;
  product: Maybe<GqlProductV2Gql>;
  product_item: Maybe<GqlProductItemV2Gql>;
  uom: Maybe<GqlUomv2Gql>;
  user: Maybe<GqlUserV2Gql>;
  country: Maybe<GqlCountryGql>;
};

export type GqlAgentFeedbackGql = {
  __typename?: 'AgentFeedbackGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  agent_feedback_id: Scalars['String'];
  agent_id: Scalars['String'];
  harvest_id: Scalars['String'];
  bid_application_id: Scalars['String'];
  delivery_id: Maybe<Scalars['String']>;
  comment: Maybe<Scalars['String']>;
  rating: Scalars['Int'];
  categories: Maybe<Array<Maybe<GqlFeedbackCategoryGql>>>;
  bid_application: Maybe<GqlBidApplicationGql>;
};

export type GqlFeedbackCategoryGql = {
  __typename?: 'FeedbackCategoryGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  feedback_category_id: Scalars['String'];
  feedback_category_name: Scalars['String'];
  feedback_category_description: Maybe<Scalars['String']>;
};

export type GqlDiscoveryGql = {
  __typename?: 'DiscoveryGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  discovery_id: Scalars['String'];
  method: Scalars['String'];
};

export type GqlSupplierProductGql = {
  __typename?: 'SupplierProductGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  supplier_product_id: Scalars['String'];
  farmer_id: Scalars['String'];
  product_id: Scalars['String'];
  supplier: Maybe<GqlFarmerGql>;
  product: Maybe<GqlProductV2Gql>;
};

export type GqlSupplierUserGql = {
  __typename?: 'SupplierUserGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  supplier_user_id: Scalars['String'];
  farmer_id: Scalars['String'];
  user_id: Scalars['String'];
  role_id: Scalars['String'];
  supplier: Maybe<GqlFarmerGql>;
  user: Maybe<GqlUserV2Gql>;
  role: Maybe<GqlRoleV2Gql>;
};

export type GqlPaymentDetailGql = GqlNode & {
  __typename?: 'PaymentDetailGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  harvest_payment_batch_detail_id: Scalars['String'];
  harvest_payment_batch_id: Scalars['String'];
  harvest_id: Scalars['String'];
  amount: Scalars['Float'];
  farmer_payment_account_id: Scalars['String'];
  farmer_id: Scalars['String'];
  account_type: Maybe<Scalars['String']>;
  account_number: Scalars['String'];
  payment_provider_mode_id: Scalars['String'];
  payment_transaction_type: Maybe<Scalars['String']>;
  payment_status: Maybe<Scalars['String']>;
  external_payment_transaction_id: Maybe<Scalars['String']>;
  external_payment_date: Maybe<Scalars['DateTime']>;
  harvest: Maybe<GqlHarvestGql>;
  farmer: Maybe<GqlFarmerGql>;
  /** The ID of the object. */
  id: Scalars['ID'];
};

export type GqlBookingGql = GqlNode & {
  __typename?: 'BookingGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  cancelled: Scalars['Boolean'];
  cancellation_reason: Maybe<Scalars['String']>;
  booking_id: Scalars['String'];
  receipt_number: Scalars['String'];
  farm_id: Maybe<Scalars['String']>;
  farmer_id: Maybe<Scalars['String']>;
  collection_center_id: Scalars['String'];
  country_id: Scalars['String'];
  product_id: Maybe<Scalars['String']>;
  product_item_id: Maybe<Scalars['String']>;
  unit_of_measure_id: Maybe<Scalars['String']>;
  quantity: Maybe<Scalars['Float']>;
  weight: Maybe<Scalars['Float']>;
  price: Maybe<Scalars['Float']>;
  original_currency: Scalars['String'];
  fx_rate: Maybe<Scalars['Float']>;
  invoice_url: Maybe<Scalars['String']>;
  booking_date: Scalars['String'];
  harvest_date: Maybe<Scalars['String']>;
  booked_by: Maybe<Scalars['String']>;
  invoice_number: Maybe<Scalars['String']>;
  status: Maybe<GqlBookingStatus>;
  purchase_order_number: Maybe<Scalars['String']>;
  purchase_order_url: Maybe<Scalars['String']>;
  farm: Maybe<GqlFarmGql>;
  collection_center: Maybe<GqlCollectionCenterGql>;
  booking_items: Maybe<Array<Maybe<GqlBookingItemGql>>>;
  farmer: Maybe<GqlFarmerGql>;
  /** The ID of the object. */
  id: Scalars['ID'];
  signed_invoice_url: Maybe<Scalars['String']>;
  signed_purchase_order_url: Maybe<Scalars['String']>;
  product: Maybe<GqlProductV2Gql>;
  product_item: Maybe<GqlProductItemV2Gql>;
  scout: Maybe<GqlUserV2Gql>;
  uom: Maybe<GqlUomv2Gql>;
  country: Maybe<GqlCountryGql>;
};

/** An enumeration. */
export enum GqlBookingStatus {
  Pending = 'PENDING',
  Approved = 'APPROVED'
}

export type GqlCollectionCenterGql = GqlNode & {
  __typename?: 'CollectionCenterGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  collection_center_id: Scalars['String'];
  collection_center_name: Scalars['String'];
  area_id: Scalars['String'];
  country_id: Scalars['String'];
  latitude: Maybe<Scalars['Float']>;
  longitude: Maybe<Scalars['Float']>;
  collection_center_supervisor: Maybe<Scalars['String']>;
  collection_center_code: Maybe<Scalars['String']>;
  harvest_areas: Maybe<Array<Maybe<GqlHarvestAreaGql>>>;
  area: Maybe<GqlAreaGql>;
  harvest: Maybe<GqlHarvestGql>;
  booking: Maybe<GqlBookingGql>;
  /** The ID of the object. */
  id: Scalars['ID'];
  country: Maybe<GqlCountryGql>;
};

export type GqlHarvestAreaGql = GqlNode & {
  __typename?: 'HarvestAreaGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  harvest_area_id: Scalars['String'];
  harvest_area_name: Scalars['String'];
  collection_center_id: Scalars['String'];
  collection_center: Maybe<GqlCollectionCenterGql>;
  /** The ID of the object. */
  id: Scalars['ID'];
  area: Maybe<GqlAreaGql>;
};

export type GqlAreaGql = GqlNode & {
  __typename?: 'AreaGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  area_id: Scalars['String'];
  area_name: Scalars['String'];
  area_manager: Maybe<Scalars['String']>;
  area_code: Maybe<Scalars['String']>;
  collection_centers: Maybe<Array<Maybe<GqlCollectionCenterGql>>>;
  /** The ID of the object. */
  id: Scalars['ID'];
};

export type GqlBookingItemGql = {
  __typename?: 'BookingItemGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  cancelled: Scalars['Boolean'];
  cancellation_reason: Maybe<Scalars['String']>;
  booking_item_id: Scalars['String'];
  booking_id: Scalars['String'];
  product_id: Scalars['String'];
  product_item_id: Maybe<Scalars['String']>;
  unit_of_measure_id: Scalars['String'];
  quantity: Scalars['Float'];
  weight: Maybe<Scalars['Float']>;
  unit_price: Scalars['Float'];
  harvest_date: Scalars['String'];
  booked_by: Maybe<Scalars['String']>;
  booking: Maybe<GqlBookingGql>;
  product: Maybe<GqlProductV2Gql>;
  product_item: Maybe<GqlProductItemV2Gql>;
  scout: Maybe<GqlUserV2Gql>;
  uom: Maybe<GqlUomv2Gql>;
};

export type GqlHarvestIssueGql = GqlNode & {
  __typename?: 'HarvestIssueGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  harvest_issue_id: Scalars['String'];
  harvest_id: Scalars['String'];
  comment: Maybe<Scalars['String']>;
  harvest_issue_types: Maybe<Array<Maybe<GqlHarvestIssueDetailGql>>>;
  harvest: Maybe<GqlHarvestGql>;
  /** The ID of the object. */
  id: Scalars['ID'];
};

export type GqlHarvestIssueDetailGql = GqlNode & {
  __typename?: 'HarvestIssueDetailGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  harvest_issue_detail_id: Scalars['String'];
  harvest_issue_type_id: Scalars['Int'];
  harvest_issue_id: Scalars['String'];
  harvest_issue_type: Maybe<GqlHarvestIssueTypeGql>;
  harvest_issue: Maybe<GqlHarvestIssueGql>;
  /** The ID of the object. */
  id: Scalars['ID'];
};

export type GqlHarvestIssueTypeGql = GqlNode & {
  __typename?: 'HarvestIssueTypeGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  harvest_issue_type_id: Scalars['ID'];
  harvest_issue_type_name: Scalars['String'];
  harvest_issue_detail: Maybe<GqlHarvestIssueDetailGql>;
  /** The ID of the object. */
  id: Scalars['ID'];
};

export type GqlHarvestItemGql = {
  __typename?: 'HarvestItemGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  cancelled: Scalars['Boolean'];
  cancellation_reason: Maybe<Scalars['String']>;
  harvest_item_id: Scalars['String'];
  harvest_id: Scalars['String'];
  product_id: Scalars['String'];
  product_item_id: Maybe<Scalars['String']>;
  unit_of_measure_id: Scalars['String'];
  unit_price: Scalars['Float'];
  quantity: Scalars['Float'];
  weight: Scalars['Float'];
  waste: Scalars['Boolean'];
  total_payable: Scalars['Float'];
  packaging_unit_id: Maybe<Scalars['String']>;
  packaging_unit_quantity: Maybe<Scalars['Int']>;
  number_of_crates: Maybe<Scalars['Int']>;
  harvest: Maybe<GqlHarvestGql>;
  assets: Maybe<Array<Maybe<GqlHarvestItemAssetGql>>>;
  product: Maybe<GqlProductV2Gql>;
  product_item: Maybe<GqlProductItemV2Gql>;
  harvester: Maybe<GqlUserV2Gql>;
  packaging_unit: Maybe<GqlPackagingUnitV2Gql>;
  uom: Maybe<GqlUomv2Gql>;
};

export type GqlHarvestItemAssetGql = {
  __typename?: 'HarvestItemAssetGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  harvest_item_asset_id: Scalars['String'];
  harvest_item_id: Scalars['String'];
  asset_number: Scalars['String'];
  harvest_item: Maybe<GqlHarvestItemGql>;
  asset: Maybe<GqlAssetGql>;
};

export type GqlAssetGql = {
  __typename?: 'AssetGQL';
  asset_id: Scalars['String'];
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  asset_number: Scalars['String'];
  asset_type_id: Maybe<Scalars['String']>;
  asset_status: GqlAssetStatus;
  asset_type: Maybe<GqlAssetTypeGql>;
  asset_photos: Maybe<Array<Maybe<GqlAssetPhotoGql>>>;
  handling_units: Maybe<Array<Maybe<GqlHandlingUnitGql>>>;
  all_handling_unit_items: Maybe<Array<Maybe<GqlHandlingUnitItemGql>>>;
  handling_unit_items: Maybe<Array<Maybe<GqlHandlingUnitItemGql>>>;
  asset_tracker_item: Maybe<Array<Maybe<GqlAssetTrackerItemGql>>>;
  last_active_hu_item: Maybe<GqlHandlingUnitItemGql>;
};

export type GqlHarvestPaymentGql = {
  __typename?: 'HarvestPaymentGQL';
  harvest_id: Maybe<Scalars['String']>;
  amount_paid: Maybe<Scalars['Float']>;
  total_payable: Maybe<Scalars['Float']>;
  harvest_payment_status: Maybe<Scalars['String']>;
  payment_details: Maybe<Array<Maybe<GqlHarvestPaymentDetailGql>>>;
};

export type GqlHarvestPaymentDetailGql = {
  __typename?: 'HarvestPaymentDetailGQL';
  amount: Maybe<Scalars['Float']>;
  payment_date: Maybe<Scalars['String']>;
  account_type: Maybe<Scalars['String']>;
  account_number: Maybe<Scalars['String']>;
  harvest_id: Maybe<Scalars['String']>;
  transaction_id: Maybe<Scalars['String']>;
  payment_status: Maybe<Scalars['String']>;
  date_created: Maybe<Scalars['String']>;
  payer_name: Maybe<Scalars['String']>;
  harvest_payment_batch_id: Maybe<Scalars['String']>;
  harvest_payment_batch_detail_id: Maybe<Scalars['String']>;
};

export type GqlAppointmentItemGql = {
  __typename?: 'AppointmentItemGQL';
  batch_id: Maybe<Scalars['String']>;
  cancelled: Scalars['Boolean'];
  cancellation_reason: Maybe<Scalars['String']>;
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  appointment_item_id: Scalars['String'];
  appointment_id: Scalars['String'];
  booking_id: Maybe<Scalars['String']>;
  warehouse_transfer_request_id: Maybe<Scalars['String']>;
  warehouse_transfer_id: Maybe<Scalars['String']>;
  return_id: Maybe<Scalars['String']>;
  appointment: Maybe<GqlAppointmentGql>;
  warehouse_transfer_request: Maybe<GqlWarehouseTransferRequestGql>;
  warehouse_transfer: Maybe<GqlWarehouseTransferGql>;
  booking: Maybe<GqlBookingGql>;
  batch: Maybe<GqlBatchGql>;
  returns: Maybe<GqlReturnGql>;
};

export type GqlStockIntakeItemBatchGql = {
  __typename?: 'StockIntakeItemBatchGQL';
  batch_id: Scalars['String'];
  stock_intake_items: Maybe<Array<Maybe<GqlStockIntakeItemGql>>>;
};

export type GqlArticleGqlConnection = {
  __typename?: 'ArticleGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlArticleGqlEdge>>;
};

/** A Relay edge containing a `ArticleGQL` and its cursor. */
export type GqlArticleGqlEdge = {
  __typename?: 'ArticleGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlArticleGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlArticleGql = GqlNode & {
  __typename?: 'ArticleGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  article_id: Scalars['String'];
  date: Scalars['DateTime'];
  title: Scalars['String'];
  category: GqlArticleCategory;
  body: Scalars['String'];
  image: Maybe<Scalars['String']>;
  image_url: Maybe<Scalars['String']>;
  saved_article: Maybe<GqlSavedArticleGql>;
  /** The ID of the object. */
  id: Scalars['ID'];
};

/** An enumeration. */
export enum GqlArticleCategory {
  Tutorial = 'TUTORIAL',
  Statistics = 'STATISTICS'
}

export type GqlSavedArticleGql = GqlNode & {
  __typename?: 'SavedArticleGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  article_id: Maybe<Scalars['String']>;
  user_id: Scalars['String'];
  save_date: Scalars['DateTime'];
  saved_article_id: Scalars['String'];
  article: Maybe<GqlArticleGql>;
  /** The ID of the object. */
  id: Scalars['ID'];
};

export type GqlSavedArticleGqlConnection = {
  __typename?: 'SavedArticleGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlSavedArticleGqlEdge>>;
};

/** A Relay edge containing a `SavedArticleGQL` and its cursor. */
export type GqlSavedArticleGqlEdge = {
  __typename?: 'SavedArticleGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlSavedArticleGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlBidGqlConnection = {
  __typename?: 'BidGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlBidGqlEdge>>;
};

/** A Relay edge containing a `BidGQL` and its cursor. */
export type GqlBidGqlEdge = {
  __typename?: 'BidGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlBidGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlBidApplicationGqlConnection = {
  __typename?: 'BidApplicationGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlBidApplicationGqlEdge>>;
};

/** A Relay edge containing a `BidApplicationGQL` and its cursor. */
export type GqlBidApplicationGqlEdge = {
  __typename?: 'BidApplicationGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlBidApplicationGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum GqlBidStatus {
  Pending = 'PENDING',
  Active = 'ACTIVE',
  Closed = 'CLOSED'
}

/** An enumeration. */
export enum GqlBidApplicationStatus {
  Submitted = 'SUBMITTED',
  Accepted = 'ACCEPTED',
  Confirmed = 'CONFIRMED',
  Delivered = 'DELIVERED',
  Expired = 'EXPIRED'
}

/** An enumeration. */
export enum GqlBidApplicationType {
  Active = 'ACTIVE',
  Past = 'PAST',
  Won = 'WON'
}

/** An enumeration. */
export enum GqlCurrency {
  Kes = 'KES',
  Tzs = 'TZS',
  Ugx = 'UGX'
}

export type GqlHarvestGqlConnection = {
  __typename?: 'HarvestGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlHarvestGqlEdge>>;
};

/** A Relay edge containing a `HarvestGQL` and its cursor. */
export type GqlHarvestGqlEdge = {
  __typename?: 'HarvestGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlHarvestGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlHarvestVsIntakeGqlConnection = {
  __typename?: 'HarvestVsIntakeGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlHarvestVsIntakeGqlEdge>>;
};

/** A Relay edge containing a `HarvestVsIntakeGQL` and its cursor. */
export type GqlHarvestVsIntakeGqlEdge = {
  __typename?: 'HarvestVsIntakeGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlHarvestVsIntakeGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlHarvestVsIntakeGql = {
  __typename?: 'HarvestVsIntakeGQL';
  harvest_date: Maybe<Scalars['Date']>;
  product_item_id: Maybe<Scalars['String']>;
  product_item: Maybe<GqlProductItemV2Gql>;
  batch_id: Maybe<Scalars['String']>;
  batch: Maybe<GqlBatchGql>;
  invoice_number: Maybe<Scalars['String']>;
  control_unit_invoice_number: Maybe<Scalars['String']>;
  farmer_id: Maybe<Scalars['String']>;
  farmer: Maybe<GqlFarmerGql>;
  farm_id: Maybe<Scalars['String']>;
  farm: Maybe<GqlFarmGql>;
  collection_center_id: Maybe<Scalars['String']>;
  collection_center: Maybe<GqlCollectionCenterGql>;
  amount: Maybe<Scalars['Float']>;
  harvested_weight: Maybe<Scalars['Float']>;
  harvested_quantity: Maybe<Scalars['Float']>;
  stock_intake_summary: Maybe<Array<Maybe<GqlStockIntakeSummaryGql>>>;
  intake_quantity: Maybe<Scalars['Float']>;
  intake_weight: Maybe<Scalars['Float']>;
  rejected_quantity: Maybe<Scalars['Float']>;
  intake_variance: Maybe<Scalars['Float']>;
  net_variance_uom: Maybe<Scalars['Float']>;
  buying_price_uom: Maybe<Scalars['Float']>;
  buying_price_kg: Maybe<Scalars['Float']>;
};

export type GqlStockIntakeSummaryGql = {
  __typename?: 'StockIntakeSummaryGQL';
  batch_id: Maybe<Scalars['String']>;
  product_item_id: Maybe<Scalars['String']>;
  harvested_quantity: Maybe<Scalars['Float']>;
  harvested_weight: Maybe<Scalars['Float']>;
  stock_intake_number: Maybe<Scalars['String']>;
  truck_number: Maybe<Scalars['String']>;
  distribution_center_name: Maybe<Scalars['String']>;
  intake_weight: Maybe<Scalars['Float']>;
  intake_quantity: Maybe<Scalars['Float']>;
  intake_variance_uom: Maybe<Scalars['Float']>;
  intake_variance_kg: Maybe<Scalars['Float']>;
  net_variance_uom: Maybe<Scalars['Float']>;
  rejected_quantity: Maybe<Scalars['Float']>;
};

export type GqlPaymentDetailGqlConnection = {
  __typename?: 'PaymentDetailGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlPaymentDetailGqlEdge>>;
};

/** A Relay edge containing a `PaymentDetailGQL` and its cursor. */
export type GqlPaymentDetailGqlEdge = {
  __typename?: 'PaymentDetailGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlPaymentDetailGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlHarvestItemGqlConnection = {
  __typename?: 'HarvestItemGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlHarvestItemGqlEdge>>;
};

/** A Relay edge containing a `HarvestItemGQL` and its cursor. */
export type GqlHarvestItemGqlEdge = {
  __typename?: 'HarvestItemGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlHarvestItemGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlHarvestItemAssetGqlConnection = {
  __typename?: 'HarvestItemAssetGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlHarvestItemAssetGqlEdge>>;
};

/** A Relay edge containing a `HarvestItemAssetGQL` and its cursor. */
export type GqlHarvestItemAssetGqlEdge = {
  __typename?: 'HarvestItemAssetGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlHarvestItemAssetGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum GqlFarmerType {
  NonContractFarmer = 'NON_CONTRACT_FARMER',
  ContractFarmer = 'CONTRACT_FARMER',
  Agent = 'AGENT',
  Wholesaler = 'WHOLESALER',
  Distributor = 'DISTRIBUTOR',
  Manufacturer = 'MANUFACTURER'
}

export type GqlFarmerGqlConnection = {
  __typename?: 'FarmerGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlFarmerGqlEdge>>;
};

/** A Relay edge containing a `FarmerGQL` and its cursor. */
export type GqlFarmerGqlEdge = {
  __typename?: 'FarmerGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlFarmerGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlSourcingAgentGqlConnection = {
  __typename?: 'SourcingAgentGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlSourcingAgentGqlEdge>>;
};

/** A Relay edge containing a `SourcingAgentGQL` and its cursor. */
export type GqlSourcingAgentGqlEdge = {
  __typename?: 'SourcingAgentGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlSourcingAgentGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlFarmGqlConnection = {
  __typename?: 'FarmGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlFarmGqlEdge>>;
};

/** A Relay edge containing a `FarmGQL` and its cursor. */
export type GqlFarmGqlEdge = {
  __typename?: 'FarmGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlFarmGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlFarmerPaymentAccountGqlConnection = {
  __typename?: 'FarmerPaymentAccountGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlFarmerPaymentAccountGqlEdge>>;
};

/** A Relay edge containing a `FarmerPaymentAccountGQL` and its cursor. */
export type GqlFarmerPaymentAccountGqlEdge = {
  __typename?: 'FarmerPaymentAccountGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlFarmerPaymentAccountGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlAgentFeedbackGqlConnection = {
  __typename?: 'AgentFeedbackGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlAgentFeedbackGqlEdge>>;
};

/** A Relay edge containing a `AgentFeedbackGQL` and its cursor. */
export type GqlAgentFeedbackGqlEdge = {
  __typename?: 'AgentFeedbackGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlAgentFeedbackGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlSupplierUserGqlConnection = {
  __typename?: 'SupplierUserGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlSupplierUserGqlEdge>>;
};

/** A Relay edge containing a `SupplierUserGQL` and its cursor. */
export type GqlSupplierUserGqlEdge = {
  __typename?: 'SupplierUserGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlSupplierUserGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlSupplierProductGqlConnection = {
  __typename?: 'SupplierProductGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlSupplierProductGqlEdge>>;
};

/** A Relay edge containing a `SupplierProductGQL` and its cursor. */
export type GqlSupplierProductGqlEdge = {
  __typename?: 'SupplierProductGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlSupplierProductGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlBookingGqlConnection = {
  __typename?: 'BookingGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlBookingGqlEdge>>;
};

/** A Relay edge containing a `BookingGQL` and its cursor. */
export type GqlBookingGqlEdge = {
  __typename?: 'BookingGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlBookingGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlPriceRangeGqlConnection = {
  __typename?: 'PriceRangeGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlPriceRangeGqlEdge>>;
};

/** A Relay edge containing a `PriceRangeGQL` and its cursor. */
export type GqlPriceRangeGqlEdge = {
  __typename?: 'PriceRangeGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlPriceRangeGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlPriceRangeGql = {
  __typename?: 'PriceRangeGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  price_range_id: Scalars['String'];
  product_id: Scalars['String'];
  client_stamp: Scalars['String'];
  min_price: Scalars['Float'];
  max_price: Scalars['Float'];
  product: Maybe<GqlProductV2Gql>;
};

export type GqlBookingItemGqlConnection = {
  __typename?: 'BookingItemGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlBookingItemGqlEdge>>;
};

/** A Relay edge containing a `BookingItemGQL` and its cursor. */
export type GqlBookingItemGqlEdge = {
  __typename?: 'BookingItemGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlBookingItemGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlBookingSummaryGqlConnection = {
  __typename?: 'BookingSummaryGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlBookingSummaryGqlEdge>>;
};

/** A Relay edge containing a `BookingSummaryGQL` and its cursor. */
export type GqlBookingSummaryGqlEdge = {
  __typename?: 'BookingSummaryGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlBookingSummaryGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlBookingSummaryGql = {
  __typename?: 'BookingSummaryGQL';
  booking_date: Maybe<Scalars['String']>;
  harvest_date: Maybe<Scalars['String']>;
  supplier_name: Maybe<Scalars['String']>;
  product_id: Maybe<Scalars['String']>;
  product_item_id: Maybe<Scalars['String']>;
  product_item: Maybe<GqlProductItemV2Gql>;
  quantity: Maybe<Scalars['Float']>;
  weight: Maybe<Scalars['Float']>;
  unit_price: Maybe<Scalars['Float']>;
  amount: Maybe<Scalars['Float']>;
  created_by: Maybe<Scalars['String']>;
  created_by_user: Maybe<GqlUserV2Gql>;
  collection_center_id: Maybe<Scalars['String']>;
  collection_center_name: Maybe<Scalars['String']>;
};

export type GqlBookingSummaryTotalsGql = {
  __typename?: 'BookingSummaryTotalsGQL';
  quantity: Maybe<Scalars['Float']>;
  weight: Maybe<Scalars['Float']>;
  amount: Maybe<Scalars['Float']>;
};

export type GqlBlockGql = GqlNode & {
  __typename?: 'BlockGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  block_id: Scalars['String'];
  block_name: Scalars['String'];
  block_code: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
};

export type GqlPivotGql = GqlNode & {
  __typename?: 'PivotGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  pivot_id: Scalars['String'];
  pivot_name: Scalars['String'];
  pivot_code: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
};

export enum GqlAccountPolicyEnum {
  Internal = 'INTERNAL',
  External = 'EXTERNAL'
}

export type GqlCreateEwalletAccountInput = {
  name?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['Map']>;
  entity: GqlEwalletAccountEntityEnum;
  entityID: Scalars['UUID'];
  accountType: GqlEwalletAccountTypeEnum;
  countryID: Scalars['UUID'];
  currency: GqlCurrencyEnum;
  description?: Maybe<Scalars['String']>;
};

export type GqlCreateEwalletTransactionTypeInput = {
  name: Scalars['String'];
  displayName: Scalars['String'];
  displayImage?: Maybe<Scalars['String']>;
  minAmount?: Maybe<Scalars['Float']>;
  maxAmount?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  ledgerOperation: GqlLedgerOperationEnum;
  accountPolicy: GqlAccountPolicyEnum;
  meta?: Maybe<Scalars['Map']>;
};

export type GqlEwalletAccount = {
  __typename?: 'EwalletAccount';
  id: Scalars['ID'];
  dateCreated: Scalars['Time'];
  dateUpdated: Scalars['Time'];
  deletedAt: Maybe<Scalars['Time']>;
  active: Scalars['Boolean'];
  createdByID: Scalars['String'];
  updatedByID: Scalars['String'];
  name: Scalars['String'];
  meta: Maybe<Scalars['Map']>;
  entity: Scalars['String'];
  entityID: Scalars['String'];
  accountType: Scalars['String'];
  countryID: Scalars['String'];
  currency: Scalars['String'];
  balance: Scalars['Float'];
  description: Scalars['String'];
};

export type GqlEwalletAccountConnection = {
  __typename?: 'EwalletAccountConnection';
  edges: Array<GqlEwalletAccount>;
  pageInfo: GqlPageInfo;
};

export enum GqlEwalletAccountEntityEnum {
  Customer = 'CUSTOMER'
}

export type GqlEwalletAccountFilters = {
  ids?: Maybe<Array<Scalars['UUID']>>;
  active?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  entityID?: Maybe<Scalars['UUID']>;
  accountType?: Maybe<GqlEwalletAccountTypeEnum>;
  countryID?: Maybe<Scalars['UUID']>;
  currency?: Maybe<GqlCurrencyEnum>;
};

export type GqlEwalletAccountTransactInput = {
  amount: Scalars['Float'];
  accountID: Scalars['UUID'];
  currency: GqlCurrencyEnum;
  countryID: Scalars['UUID'];
  transactionType: Scalars['String'];
  description: Scalars['String'];
  referenceID: Scalars['UUID'];
  entity?: Maybe<GqlEwalletAccountEntityEnum>;
  entityID?: Maybe<Scalars['UUID']>;
  meta?: Maybe<Scalars['Map']>;
};

export enum GqlEwalletAccountTypeEnum {
  Internal = 'INTERNAL',
  External = 'EXTERNAL'
}

export type GqlEwalletTransactInput = {
  amount: Scalars['Float'];
  entity: GqlEwalletAccountEntityEnum;
  entityID: Scalars['UUID'];
  transactionType: Scalars['String'];
  currency: GqlCurrencyEnum;
  countryID: Scalars['UUID'];
  referenceID: Scalars['UUID'];
  description: Scalars['String'];
  meta?: Maybe<Scalars['Map']>;
};

export type GqlEwalletTransactionType = {
  __typename?: 'EwalletTransactionType';
  id: Scalars['ID'];
  dateCreated: Scalars['Time'];
  dateUpdated: Scalars['Time'];
  deletedAt: Maybe<Scalars['Time']>;
  active: Scalars['Boolean'];
  createdByID: Scalars['String'];
  updatedByID: Scalars['String'];
  meta: Maybe<Scalars['Map']>;
  name: Scalars['String'];
  displayName: Scalars['String'];
  displayImage: Scalars['String'];
  minAmount: Maybe<Scalars['Float']>;
  maxAmount: Maybe<Scalars['Float']>;
  description: Scalars['String'];
  ledgerOperation: Scalars['String'];
  accountPolicy: Scalars['String'];
};

export type GqlEwalletTransactionTypeConnection = {
  __typename?: 'EwalletTransactionTypeConnection';
  edges: Array<GqlEwalletTransactionType>;
  pageInfo: GqlPageInfo;
};

export type GqlEwalletTransactionTypeFilters = {
  ids?: Maybe<Array<Scalars['UUID']>>;
  active?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  ledgerOperation?: Maybe<GqlLedgerOperationEnum>;
  accountPolicy?: Maybe<GqlAccountPolicyEnum>;
};

export type GqlLedgerEntry = {
  __typename?: 'LedgerEntry';
  id: Scalars['ID'];
  dateCreated: Scalars['Time'];
  dateUpdated: Scalars['Time'];
  deletedAt: Maybe<Scalars['Time']>;
  active: Scalars['Boolean'];
  createdByID: Scalars['String'];
  updatedByID: Scalars['String'];
  meta: Maybe<Scalars['Map']>;
  accountID: Scalars['String'];
  ledgerOperation: Scalars['String'];
  countryID: Scalars['String'];
  currency: Scalars['String'];
  amount: Scalars['Float'];
  transactionTypeID: Scalars['String'];
  referenceID: Scalars['String'];
  description: Scalars['String'];
  transactionType: GqlEwalletTransactionType;
  account: GqlEwalletAccount;
};

export type GqlLedgerEntryConnection = {
  __typename?: 'LedgerEntryConnection';
  edges: Array<GqlLedgerEntry>;
  pageInfo: GqlPageInfo;
};

export type GqlLedgerEntryFilters = {
  ids?: Maybe<Array<Scalars['UUID']>>;
  active?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['UUID']>;
  countryID?: Maybe<Scalars['UUID']>;
  currency?: Maybe<GqlCurrencyEnum>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  transactionTypeID?: Maybe<Scalars['UUID']>;
  accountIDs?: Maybe<Array<Maybe<Scalars['UUID']>>>;
};

export enum GqlLedgerOperationEnum {
  Credit = 'CREDIT',
  Debit = 'DEBIT'
}

export type GqlUpdateEwalletAccountInput = {
  id: Scalars['UUID'];
  name?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['Map']>;
  description?: Maybe<Scalars['String']>;
};

export type GqlUpdateEwalletTransactionTypeInput = {
  id: Scalars['UUID'];
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  displayImage?: Maybe<Scalars['String']>;
  minAmount?: Maybe<Scalars['Float']>;
  maxAmount?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  accountPolicy?: Maybe<GqlAccountPolicyEnum>;
  meta?: Maybe<Scalars['Map']>;
};

export type GqlApprovalRule = {
  __typename?: 'ApprovalRule';
  authorizers: Array<Maybe<GqlAuthorizer>>;
  mandate: GqlApprovalRuleMandate;
  minimumAuthorizers: Maybe<Scalars['Int']>;
  ruleId: Scalars['String'];
};

export type GqlApprovalRuleInput = {
  authorizers: Array<Maybe<GqlAuthorizerInput>>;
  mandate: GqlApprovalRuleMandate;
  minimumAuthorizers?: Maybe<Scalars['Int']>;
  ruleId: Scalars['String'];
};

export enum GqlApprovalRuleMandate {
  AllToApprove = 'ALL_TO_APPROVE',
  AnyToApprove = 'ANY_TO_APPROVE',
  XToApprove = 'X_TO_APPROVE'
}

export enum GqlApprovalStatus {
  Pending = 'PENDING',
  RequestApproved = 'REQUEST_APPROVED',
  RequestRejected = 'REQUEST_REJECTED',
  RequestTimedOut = 'REQUEST_TIMED_OUT'
}

export type GqlAuthorizer = {
  __typename?: 'Authorizer';
  approved: Maybe<Scalars['Boolean']>;
  authorizerId: Scalars['String'];
  email: Maybe<Scalars['String']>;
  mobileNumber: Maybe<Scalars['String']>;
  name: Scalars['String'];
  reportsTo: Maybe<Scalars['String']>;
  role: Scalars['String'];
  ruleIds: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type GqlAuthorizerGql = {
  __typename?: 'AuthorizerGQL';
  authorizerId: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  mobileNumber: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  reportsTo: Maybe<Scalars['String']>;
  role: Maybe<Scalars['String']>;
  workflows: Maybe<Array<Maybe<GqlWorkflow>>>;
};

export type GqlAuthorizerGqlConnection = {
  __typename?: 'AuthorizerGQLConnection';
  edges: Maybe<Array<Maybe<GqlAuthorizerGqlEdge>>>;
  page_info: Maybe<GqlPaginatorInfo>;
};

export type GqlAuthorizerGqlEdge = {
  __typename?: 'AuthorizerGQLEdge';
  cursor: Scalars['String'];
  node: Maybe<GqlAuthorizerGql>;
};

export type GqlAuthorizerGqlInput = {
  authorizerId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  reportsTo?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  workflows?: Maybe<Array<Maybe<GqlWorkflowInput>>>;
};

export type GqlAuthorizerInput = {
  approved?: Maybe<Scalars['Boolean']>;
  authorizerId: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  reportsTo?: Maybe<Scalars['String']>;
  role: Scalars['String'];
  ruleIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type GqlAuthorizerInputInput = {
  authorizerId: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  reportsTo?: Maybe<Scalars['String']>;
  role: Scalars['String'];
};



export type GqlCreateAuthorizer = {
  __typename?: 'CreateAuthorizer';
  authorizer: GqlAuthorizerGql;
  created: Scalars['Boolean'];
};

export type GqlCreateRequest = {
  __typename?: 'CreateRequest';
  created: Scalars['Boolean'];
  request: GqlRequestGql;
};

export type GqlCreateWorkflow = {
  __typename?: 'CreateWorkflow';
  created: Scalars['Boolean'];
  workflow: GqlWorkflowGql;
};

export type GqlDecision = {
  __typename?: 'Decision';
  authorizerId: Scalars['String'];
  currentLevel: Maybe<GqlWorkflowLevel>;
  decisionId: Scalars['Int'];
  reason: Maybe<Scalars['String']>;
  requestId: Scalars['String'];
  status: Maybe<GqlDecisionType>;
};

export enum GqlDecisionType {
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Timeout = 'TIMEOUT'
}

export type GqlEntry_WorkflowLevel_ApprovalRule = {
  __typename?: 'Entry_WorkflowLevel_ApprovalRule';
  key: Maybe<GqlWorkflowLevel>;
  value: Maybe<GqlApprovalRule>;
};

export type GqlEntry_WorkflowLevel_ApprovalRuleInputInput = {
  key?: Maybe<GqlWorkflowLevel>;
  value?: Maybe<GqlApprovalRuleInput>;
};

export type GqlRequestGql = {
  __typename?: 'RequestGQL';
  authorizers: Maybe<Scalars['String']>;
  callbackStatus: Maybe<Scalars['Boolean']>;
  currentLevel: Maybe<GqlWorkflowLevel>;
  details: Maybe<Scalars['String']>;
  externalReferenceId: Maybe<Scalars['String']>;
  initiator: Maybe<Scalars['String']>;
  lastDecision: Maybe<GqlDecision>;
  reason: Maybe<Scalars['String']>;
  requestId: Maybe<Scalars['String']>;
  status: Maybe<GqlApprovalStatus>;
  /** ISO-8601 */
  timeOutAt: Maybe<Scalars['DateTime']>;
  workflow: Maybe<GqlWorkflow>;
  workflowId: Maybe<Scalars['String']>;
};

export type GqlRequestGqlConnection = {
  __typename?: 'RequestGQLConnection';
  edges: Maybe<Array<Maybe<GqlRequestGqlEdge>>>;
  page_info: Maybe<GqlPaginatorInfo>;
};

export type GqlRequestGqlEdge = {
  __typename?: 'RequestGQLEdge';
  cursor: Scalars['String'];
  node: Maybe<GqlRequestGql>;
};

export type GqlRequestInputInput = {
  authorizers?: Maybe<Scalars['String']>;
  callbackStatus?: Maybe<Scalars['Boolean']>;
  currentLevel?: Maybe<GqlWorkflowLevel>;
  details?: Maybe<Scalars['String']>;
  externalReferenceId?: Maybe<Scalars['String']>;
  initiator?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  requestId?: Maybe<Scalars['String']>;
  status?: Maybe<GqlApprovalStatus>;
  /** ISO-8601 */
  timeOutAt?: Maybe<Scalars['DateTime']>;
  workflowId?: Maybe<Scalars['String']>;
};

export type GqlUpdateAuthorizer = {
  __typename?: 'UpdateAuthorizer';
  authorizer: GqlAuthorizerGql;
};

export type GqlUpdateRequest = {
  __typename?: 'UpdateRequest';
  request: GqlRequestGql;
};

export type GqlUpdateWorkflow = {
  __typename?: 'UpdateWorkflow';
  workflow: GqlWorkflowGql;
};

export type GqlWorkflow = {
  __typename?: 'Workflow';
  callbackURL: Maybe<Array<Maybe<Scalars['String']>>>;
  name: Scalars['String'];
  rules: Array<Maybe<GqlEntry_WorkflowLevel_ApprovalRule>>;
  ttl: Maybe<Scalars['Int']>;
  workflowId: Scalars['String'];
};


export type GqlWorkflowRulesArgs = {
  key: Maybe<Array<Maybe<GqlWorkflowLevel>>>;
};

export type GqlWorkflowGql = {
  __typename?: 'WorkflowGQL';
  callbackURL: Maybe<Array<Maybe<Scalars['String']>>>;
  name: Maybe<Scalars['String']>;
  rules: Maybe<Array<Maybe<GqlEntry_WorkflowLevel_ApprovalRule>>>;
  ttl: Maybe<Scalars['Int']>;
  workflowId: Maybe<Scalars['String']>;
};


export type GqlWorkflowGqlRulesArgs = {
  key: Maybe<Array<Maybe<GqlWorkflowLevel>>>;
};

export type GqlWorkflowGqlConnection = {
  __typename?: 'WorkflowGQLConnection';
  edges: Maybe<Array<Maybe<GqlWorkflowGqlEdge>>>;
  page_info: Maybe<GqlPaginatorInfo>;
};

export type GqlWorkflowGqlEdge = {
  __typename?: 'WorkflowGQLEdge';
  cursor: Scalars['String'];
  node: Maybe<GqlWorkflowGql>;
};

export type GqlWorkflowGqlInput = {
  callbackURL?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  rules?: Maybe<Array<Maybe<GqlEntry_WorkflowLevel_ApprovalRuleInputInput>>>;
  ttl?: Maybe<Scalars['Int']>;
  workflowId?: Maybe<Scalars['String']>;
};

export type GqlWorkflowInput = {
  callbackURL?: Maybe<Array<Maybe<Scalars['String']>>>;
  name: Scalars['String'];
  rules: Array<Maybe<GqlEntry_WorkflowLevel_ApprovalRuleInputInput>>;
  ttl?: Maybe<Scalars['Int']>;
  workflowId: Scalars['String'];
};

export type GqlWorkflowInputInput = {
  callbackURL?: Maybe<Array<Maybe<Scalars['String']>>>;
  name: Scalars['String'];
  rules: Array<Maybe<GqlEntry_WorkflowLevel_ApprovalRuleInputInput>>;
  ttl?: Maybe<Scalars['Int']>;
  workflowId: Scalars['String'];
};

export enum GqlWorkflowLevel {
  Level_1 = 'LEVEL_1',
  Level_2 = 'LEVEL_2',
  Level_3 = 'LEVEL_3',
  Level_4 = 'LEVEL_4',
  Level_5 = 'LEVEL_5'
}

export type GqlReturnsHandlingUnitItemGqlConnection = {
  __typename?: 'ReturnsHandlingUnitItemGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlReturnsHandlingUnitItemGqlEdge>>;
};

/** A Relay edge containing a `ReturnsHandlingUnitItemGQL` and its cursor. */
export type GqlReturnsHandlingUnitItemGqlEdge = {
  __typename?: 'ReturnsHandlingUnitItemGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlReturnsHandlingUnitItemGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlReturnsHandlingUnitItemGql = {
  __typename?: 'ReturnsHandlingUnitItemGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  returns_handling_unit_item_id: Scalars['String'];
  returns_handling_unit_id: Scalars['String'];
  handling_unit_item_id: Scalars['String'];
  product_loss_reason_id: Maybe<Scalars['String']>;
  picture: Maybe<Scalars['String']>;
  scheduled_for_picking: Scalars['Boolean'];
  wms_returns_id: Scalars['String'];
  returns_handling_units: Maybe<GqlReturnsHandlingUnitGql>;
  handling_unit_item: Maybe<GqlHandlingUnitItemGql>;
  product_loss_reason: Maybe<GqlProductLossReasonGql>;
  wms_returns: Maybe<GqlWmsReturnsGql>;
};

export type GqlReturnsHandlingUnitGql = {
  __typename?: 'ReturnsHandlingUnitGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  returns_handling_unit_id: Scalars['String'];
  handling_unit_id: Scalars['String'];
  wms_returns_type: GqlWmsReturnsType;
  is_complete: Scalars['Boolean'];
  handling_unit: Maybe<GqlHandlingUnitGql>;
  returns_handling_unit_items: Maybe<Array<Maybe<GqlReturnsHandlingUnitItemGql>>>;
};

/** An enumeration. */
export enum GqlWmsReturnsType {
  Reavail = 'REAVAIL',
  Dispose = 'DISPOSE'
}

export type GqlHandlingUnitGql = {
  __typename?: 'HandlingUnitGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  handling_unit_id: Scalars['String'];
  asset_id: Scalars['String'];
  batch_id: Maybe<Scalars['String']>;
  booking_id: Maybe<Scalars['String']>;
  warehouse_transfer_id: Maybe<Scalars['String']>;
  return_id: Maybe<Scalars['String']>;
  storage_location_id: Scalars['String'];
  stock_date: Maybe<Scalars['String']>;
  quarantine_reason: Maybe<Scalars['String']>;
  disposal_reason: Maybe<Scalars['String']>;
  order_by_date: Maybe<Scalars['String']>;
  stock_availability_status: GqlHuStockAvailabilityStatus;
  asset: Maybe<GqlAssetGql>;
  warehouse_transfer: Maybe<GqlWarehouseTransferGql>;
  all_handling_unit_items: Maybe<Array<Maybe<GqlHandlingUnitItemGql>>>;
  handling_unit_items: Maybe<Array<Maybe<GqlHandlingUnitItemGql>>>;
  storage_location: Maybe<GqlStorageLocationGql>;
  picking_handling_units: Maybe<Array<Maybe<GqlPickingHandlingUnitGql>>>;
  active_picking_hu: Maybe<GqlPickingHandlingUnitGql>;
  product_transfer_handling_unit: Maybe<Array<Maybe<GqlProductTransferHandlingUnitGql>>>;
  returns_handling_unit: Maybe<GqlReturnsHandlingUnitGql>;
  quantity: Maybe<Scalars['Int']>;
  batch: Maybe<GqlBatchGql>;
  returns: Maybe<GqlReturnGql>;
  booking: Maybe<GqlBookingGql>;
  updated_by_user: Maybe<GqlUserV2Gql>;
};

/** An enumeration. */
export enum GqlHuStockAvailabilityStatus {
  Available = 'AVAILABLE',
  Unavailable = 'UNAVAILABLE',
  Quarantined = 'QUARANTINED',
  Disposed = 'DISPOSED'
}

/** An enumeration. */
export enum GqlAssetStatus {
  Ok = 'OK',
  Lost = 'LOST',
  Damaged = 'DAMAGED'
}

export type GqlAssetTypeGql = {
  __typename?: 'AssetTypeGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  asset_type_id: Scalars['String'];
  asset_type_name: Scalars['String'];
  assets: Maybe<Array<Maybe<GqlAssetGql>>>;
};

export type GqlAssetPhotoGql = {
  __typename?: 'AssetPhotoGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  asset_photo_id: Scalars['String'];
  asset_id: Scalars['String'];
  file_name: Scalars['String'];
  asset: Maybe<GqlAssetGql>;
  signed_url: Maybe<Scalars['String']>;
};

export type GqlHandlingUnitItemGql = {
  __typename?: 'HandlingUnitItemGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  handling_unit_item_id: Scalars['String'];
  stock_date: Maybe<Scalars['String']>;
  quarantine_reason: Maybe<Scalars['String']>;
  disposal_reason: Maybe<Scalars['String']>;
  order_by_date: Maybe<Scalars['String']>;
  parent_id: Maybe<Scalars['String']>;
  is_sku: Scalars['Boolean'];
  handling_unit_id: Maybe<Scalars['String']>;
  product_item_id: Scalars['String'];
  quantity: Scalars['Int'];
  weight: Maybe<Scalars['Int']>;
  asset_id: Scalars['String'];
  dispatched: Scalars['Boolean'];
  verified: Maybe<Scalars['Boolean']>;
  stock_availability_status: Maybe<Scalars['String']>;
  storage_location_id: Scalars['String'];
  parent: Maybe<GqlHandlingUnitItemGql>;
  handling_unit: Maybe<GqlHandlingUnitGql>;
  asset: Maybe<GqlAssetGql>;
  storage_location: Maybe<GqlStorageLocationGql>;
  product_transfer_handling_unit_item: Maybe<Array<Maybe<GqlProductTransferHandlingUnitItemGql>>>;
  returns_handling_unit_item: Maybe<GqlReturnsHandlingUnitItemGql>;
  product_item: Maybe<GqlProductItemV2Gql>;
  updated_by_user: Maybe<GqlUserV2Gql>;
};

export type GqlStorageLocationGql = {
  __typename?: 'StorageLocationGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  storage_location_id: Scalars['String'];
  storage_location_number: Scalars['String'];
  location_type_id: Scalars['String'];
  status: GqlStorageLocationStatus;
  location_type: Maybe<GqlStorageLocationTypeGql>;
  handling_units: Maybe<Array<Maybe<GqlHandlingUnitGql>>>;
  all_handling_unit_items: Maybe<Array<Maybe<GqlHandlingUnitItemGql>>>;
  handling_unit_items: Maybe<Array<Maybe<GqlHandlingUnitItemGql>>>;
  dock_door: Maybe<Array<Maybe<GqlDockDoorGql>>>;
  bin: Maybe<Array<Maybe<GqlBinGql>>>;
  waste_area: Maybe<Array<Maybe<GqlWasteAreaGql>>>;
  hu_count: Maybe<Scalars['Int']>;
  hui_count: Maybe<Scalars['Int']>;
};

/** An enumeration. */
export enum GqlStorageLocationStatus {
  Available = 'AVAILABLE',
  Unavailable = 'UNAVAILABLE',
  Outoforder = 'OUTOFORDER'
}

export type GqlStorageLocationTypeGql = {
  __typename?: 'StorageLocationTypeGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  location_type_id: Scalars['String'];
  location_type_name: Scalars['String'];
  storage_locations: Maybe<Array<Maybe<GqlStorageLocationGql>>>;
};

export type GqlDockDoorGql = {
  __typename?: 'DockDoorGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  dock_door_id: Scalars['String'];
  dock_door_number: Maybe<Scalars['Int']>;
  status: GqlDockDoorStatus;
  door_type: Array<Maybe<Scalars['String']>>;
  staging_area_name: Scalars['String'];
  warehouse_id: Scalars['String'];
  storage_location_id: Scalars['String'];
  appointments: Maybe<Array<Maybe<GqlAppointmentGql>>>;
  storage_location: Maybe<GqlStorageLocationGql>;
  warehouse: Maybe<GqlWarehouseGql>;
  storage: Maybe<GqlStorageLocationGql>;
};

/** An enumeration. */
export enum GqlDockDoorStatus {
  Available = 'AVAILABLE',
  Unavailable = 'UNAVAILABLE'
}

export type GqlAppointmentGql = {
  __typename?: 'AppointmentGQL';
  cancelled: Scalars['Boolean'];
  cancellation_reason: Maybe<Scalars['String']>;
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  appointment_id: Scalars['String'];
  appointment_number: Scalars['String'];
  vehicle_registration: Maybe<Scalars['String']>;
  driver_name: Maybe<Scalars['String']>;
  driver_phone_number: Maybe<Scalars['String']>;
  status: GqlAppointmentStatus;
  decline_reason: Maybe<Scalars['String']>;
  appointment_type: GqlAppointmentType;
  dock_door_id: Maybe<Scalars['String']>;
  eta: Scalars['DateTime'];
  issued_by: Scalars['String'];
  assigned_by: Maybe<Scalars['String']>;
  delivery_note: Maybe<Scalars['String']>;
  images: Maybe<Scalars['GenericScalar']>;
  calender_event_id: Maybe<Scalars['String']>;
  type_of_truck: Maybe<Scalars['String']>;
  driver_id: Maybe<Scalars['String']>;
  vehicle_seal_number: Maybe<Scalars['String']>;
  distribution_center_id: Maybe<Scalars['String']>;
  appointment_items: Maybe<Array<Maybe<GqlAppointmentItemGql>>>;
  dock_door: Maybe<GqlDockDoorGql>;
  distribution_center: Maybe<GqlDistributionCenterGql>;
  intakes: Maybe<GqlStockIntakeGql>;
  wms_returns: Maybe<GqlWmsReturnsGql>;
  issued_by_user: Maybe<GqlUserV2Gql>;
  assigned_by_user: Maybe<GqlUserV2Gql>;
};

/** An enumeration. */
export enum GqlAppointmentStatus {
  Upcoming = 'UPCOMING',
  Assigned = 'ASSIGNED',
  Receiving = 'RECEIVING',
  Closed = 'CLOSED',
  PastDue = 'PAST_DUE',
  Rejected = 'REJECTED',
  QaApproved = 'QA_APPROVED',
  QaDeclined = 'QA_DECLINED',
  SecurityApproved = 'SECURITY_APPROVED',
  SecurityDeclined = 'SECURITY_DECLINED'
}

/** An enumeration. */
export enum GqlAppointmentType {
  Booking = 'BOOKING',
  Returns = 'RETURNS',
  Purchase = 'PURCHASE',
  WarehouseTransfer = 'WAREHOUSE_TRANSFER',
  FfvBatch = 'FFV_BATCH',
  ReIntake = 'RE_INTAKE',
  WmsWarehouseTransfer = 'WMS_WAREHOUSE_TRANSFER'
}

export type GqlWarehouseTransferRequestGql = {
  __typename?: 'WarehouseTransferRequestGQL';
  cancelled: Scalars['Boolean'];
  cancellation_reason: Maybe<Scalars['String']>;
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  warehouse_transfer_request_id: Scalars['String'];
  warehouse_transfer_request_number: Scalars['String'];
  vehicle_id: Maybe<Scalars['String']>;
  source_dc_id: Maybe<Scalars['String']>;
  destination_dc_id: Scalars['String'];
  dock_door_id: Scalars['String'];
  warehouse_transfer_request_complete: Scalars['Boolean'];
  destination_dc: Maybe<GqlDistributionCenterGql>;
  dock_door: Maybe<GqlDockDoorGql>;
  warehouse_transfer_request_items: Maybe<Array<Maybe<GqlWarehouseTransferRequestItemGql>>>;
  reserve_picking_jobs: Maybe<Array<Maybe<GqlReservePickingJobGql>>>;
  pickface_picking_tasks: Maybe<Array<Maybe<GqlPickFacePickingTaskGql>>>;
  loading_task: Maybe<Array<Maybe<GqlLoadingTaskGql>>>;
  appointment_item: Maybe<GqlAppointmentItemGql>;
  stock_intake_items: Maybe<Array<Maybe<GqlStockIntakeItemGql>>>;
  reserve_picking_balance: Maybe<Array<Maybe<GqlReservePickingBalanceGql>>>;
  vehicle: Maybe<GqlVehicleGql>;
};

export type GqlWarehouseGql = {
  __typename?: 'WarehouseGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  warehouse_id: Scalars['String'];
  warehouse_name: Scalars['String'];
  distribution_center_id: Maybe<Scalars['String']>;
  storage_units: Maybe<Array<Maybe<GqlStorageUnitGql>>>;
  distribution_center: Maybe<GqlDistributionCenterGql>;
  warehouse_transfer_items: Maybe<Array<Maybe<GqlWarehouseTransferItemGql>>>;
};

export type GqlStorageUnitGql = {
  __typename?: 'StorageUnitGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  storage_unit_id: Scalars['String'];
  storage_unit_type_id: Scalars['String'];
  unit_name: Scalars['String'];
  warehouse_id: Scalars['String'];
  warehouse: Maybe<GqlWarehouseGql>;
  storage_unit_type: Maybe<GqlStorageUnitTypeGql>;
  storages: Maybe<Array<Maybe<GqlStorageGql>>>;
  warehouse_transfer_items: Maybe<Array<Maybe<GqlWarehouseTransferItemGql>>>;
};

export type GqlStorageUnitTypeGql = {
  __typename?: 'StorageUnitTypeGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  storage_unit_type_id: Scalars['String'];
  storage_unit_type: Scalars['String'];
  storage_units: Maybe<Array<Maybe<GqlStorageUnitGql>>>;
};

export type GqlStorageGql = {
  __typename?: 'StorageGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  storage_id: Scalars['String'];
  batch_id: Maybe<Scalars['String']>;
  storage_unit_id: Scalars['String'];
  storage_entry_number: Scalars['String'];
  date: Scalars['DateTime'];
  product_item_id: Scalars['String'];
  quantity: Scalars['Float'];
  weight: Scalars['Float'];
  unit_of_measure_id: Scalars['String'];
  packaging_unit_id: Maybe<Scalars['String']>;
  packaging_unit_quantity: Maybe<Scalars['Int']>;
  comments: Maybe<Scalars['String']>;
  storage_unit: Maybe<GqlStorageUnitGql>;
  direction: Maybe<Scalars['String']>;
  product_item: Maybe<GqlProductItemV2Gql>;
  uom: Maybe<GqlUomv2Gql>;
  packaging_unit: Maybe<GqlPackagingUnitV2Gql>;
  user: Maybe<GqlUserV2Gql>;
  batch: Maybe<GqlBatchGql>;
};

export type GqlWarehouseTransferItemGql = {
  __typename?: 'WarehouseTransferItemGQL';
  cancelled: Scalars['Boolean'];
  cancellation_reason: Maybe<Scalars['String']>;
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  warehouse_transfer_item_id: Scalars['String'];
  warehouse_transfer_id: Scalars['String'];
  warehouse_id: Scalars['String'];
  storage_unit_id: Scalars['String'];
  batch_id: Maybe<Scalars['String']>;
  product_id: Scalars['String'];
  product_item_id: Scalars['String'];
  unit_of_measure_id: Scalars['String'];
  quantity: Scalars['Int'];
  weight: Scalars['Float'];
  packaging_unit_id: Maybe<Scalars['String']>;
  packaging_unit_quantity: Maybe<Scalars['Int']>;
  warehouse_transfer: Maybe<GqlWarehouseTransferGql>;
  warehouse: Maybe<GqlWarehouseGql>;
  storage_unit: Maybe<GqlStorageUnitGql>;
  product: Maybe<GqlProductV2Gql>;
  product_item: Maybe<GqlProductItemV2Gql>;
  uom: Maybe<GqlUomv2Gql>;
  packaging_unit: Maybe<GqlPackagingUnitV2Gql>;
  batch: Maybe<GqlBatchGql>;
};

export type GqlWarehouseTransferGql = {
  __typename?: 'WarehouseTransferGQL';
  cancelled: Scalars['Boolean'];
  cancellation_reason: Maybe<Scalars['String']>;
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  warehouse_transfer_id: Scalars['String'];
  direction: GqlStockDirection;
  source_distribution_center_id: Scalars['String'];
  dest_distribution_center_id: Scalars['String'];
  transfer_date: Scalars['String'];
  transferred_by: Scalars['String'];
  transfer_number: Scalars['String'];
  vehicle_id: Maybe<Scalars['String']>;
  transfer_reason_id: Maybe<Scalars['String']>;
  source_distribution_center: Maybe<GqlDistributionCenterGql>;
  dest_distribution_center: Maybe<GqlDistributionCenterGql>;
  warehouse_transfer_items: Maybe<Array<Maybe<GqlWarehouseTransferItemGql>>>;
  appointment_item: Maybe<GqlAppointmentItemGql>;
  transfer_reason: Maybe<GqlWarehouseTransferReasonGql>;
  handling_units: Maybe<Array<Maybe<GqlHandlingUnitGql>>>;
  stock_intake_items: Maybe<Array<Maybe<GqlStockIntakeItemGql>>>;
  transferred_by_user: Maybe<GqlUserV2Gql>;
  vehicle: Maybe<GqlVehicleGql>;
};

/**
 * The direction of stock, either a dispatch (In) or a receipt (Out),
 * at a inventory_location.
 */
export enum GqlStockDirection {
  In = 'IN',
  Out = 'OUT'
}

export type GqlWarehouseTransferReasonGql = {
  __typename?: 'WarehouseTransferReasonGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  reason_id: Scalars['String'];
  reason_name: Scalars['String'];
};

export type GqlStockIntakeItemGql = {
  __typename?: 'StockIntakeItemGQL';
  cancelled: Scalars['Boolean'];
  cancellation_reason: Maybe<Scalars['String']>;
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  stock_intake_item_id: Scalars['String'];
  stock_intake_id: Scalars['String'];
  batch_id: Maybe<Scalars['String']>;
  booking_id: Maybe<Scalars['String']>;
  warehouse_transfer_id: Maybe<Scalars['String']>;
  warehouse_transfer_request_id: Maybe<Scalars['String']>;
  return_id: Maybe<Scalars['String']>;
  product_id: Scalars['String'];
  product_item_id: Scalars['String'];
  quantity: Maybe<Scalars['Float']>;
  weight: Maybe<Scalars['Float']>;
  pallet_id: Maybe<Scalars['String']>;
  unit_of_measure_id: Scalars['String'];
  packaging_unit_id: Maybe<Scalars['String']>;
  packaging_unit_quantity: Maybe<Scalars['Int']>;
  gross_weight: Maybe<Scalars['Float']>;
  pallet_weight: Scalars['Float'];
  waste: Scalars['Boolean'];
  stock_intake_item_status: Maybe<GqlStockIntakeItemStatus>;
  asset_type: Maybe<Scalars['String']>;
  warehouse_transfer: Maybe<GqlWarehouseTransferGql>;
  warehouse_transfer_request: Maybe<GqlWarehouseTransferRequestGql>;
  pallet: Maybe<GqlAssetGql>;
  stock_intake: Maybe<GqlStockIntakeGql>;
  stock_intake_item_assets: Maybe<Array<Maybe<GqlStockIntakeItemAssetGql>>>;
  product: Maybe<GqlProductV2Gql>;
  product_item: Maybe<GqlProductItemV2Gql>;
  uom: Maybe<GqlUomv2Gql>;
  packaging_unit: Maybe<GqlPackagingUnitV2Gql>;
  batch: Maybe<GqlBatchGql>;
  returns: Maybe<GqlReturnGql>;
  booking: Maybe<GqlBookingGql>;
  user: Maybe<GqlUserV2Gql>;
};

/** An enumeration. */
export enum GqlStockIntakeItemStatus {
  Inprogress = 'INPROGRESS',
  Closed = 'CLOSED'
}

export type GqlStockIntakeGql = {
  __typename?: 'StockIntakeGQL';
  cancelled: Scalars['Boolean'];
  cancellation_reason: Maybe<Scalars['String']>;
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  stock_intake_id: Scalars['String'];
  stock_intake_number: Scalars['String'];
  arrival_date: Maybe<Scalars['DateTime']>;
  start_time: Maybe<Scalars['DateTime']>;
  close_time: Maybe<Scalars['DateTime']>;
  truck_number: Maybe<Scalars['String']>;
  stock_intake_status: Maybe<GqlStockIntakeStatus>;
  stock_intake_type: GqlStockIntakeType;
  product_type: Maybe<GqlProductType>;
  decline_reason: Maybe<Scalars['String']>;
  stock_date: Maybe<Scalars['String']>;
  order_by_date: Maybe<Scalars['String']>;
  stock_availability_status: Maybe<GqlStockAvailabilityStatus>;
  appointment_id: Maybe<Scalars['String']>;
  harvest_id: Maybe<Scalars['String']>;
  wms_intake: Scalars['Boolean'];
  distribution_center_id: Maybe<Scalars['String']>;
  intake_count: Maybe<Scalars['Int']>;
  appointment: Maybe<GqlAppointmentGql>;
  stock_intake_items: Maybe<Array<Maybe<GqlStockIntakeItemGql>>>;
  stock_intake_photos: Maybe<Array<Maybe<GqlStockIntakePhotoGql>>>;
  stock_intake_skus: Maybe<Array<Maybe<GqlStockIntakeSkuGql>>>;
  stock_intake_users: Maybe<Array<Maybe<GqlStockIntakeUserGql>>>;
  distribution_center: Maybe<GqlDistributionCenterGql>;
  user: Maybe<GqlUserV2Gql>;
};

/** An enumeration. */
export enum GqlStockIntakeStatus {
  Inprogress = 'INPROGRESS',
  Closed = 'CLOSED',
  Rejected = 'REJECTED',
  Onhold = 'ONHOLD',
  QaApproved = 'QA_APPROVED',
  QaDeclined = 'QA_DECLINED'
}

/** An enumeration. */
export enum GqlStockIntakeType {
  Supplier = 'SUPPLIER',
  Returns = 'RETURNS',
  Rework = 'REWORK'
}

/** An enumeration. */
export enum GqlProductType {
  Fmcg = 'FMCG',
  Ffv = 'FFV'
}

/** An enumeration. */
export enum GqlStockAvailabilityStatus {
  Available = 'AVAILABLE',
  Unavailable = 'UNAVAILABLE',
  Quarantined = 'QUARANTINED'
}

export type GqlStockIntakePhotoGql = {
  __typename?: 'StockIntakePhotoGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  intake_photo_id: Scalars['String'];
  stock_intake_id: Scalars['String'];
  file_name: Scalars['String'];
  signed_url: Maybe<Scalars['String']>;
};

export type GqlStockIntakeSkuGql = {
  __typename?: 'StockIntakeSkuGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  stock_intake_sku_id: Scalars['String'];
  stock_intake_id: Scalars['String'];
  product_item_id: Scalars['String'];
  supplier_batch_number: Maybe<Scalars['String']>;
  batch_id: Maybe<Scalars['String']>;
  stock_date: Maybe<Scalars['String']>;
  order_by_date: Maybe<Scalars['String']>;
  stock_availability_status: Maybe<Scalars['String']>;
  rejected_quantity: Scalars['Int'];
  rejection_reason: Maybe<Scalars['String']>;
  stock_intake: Maybe<GqlStockIntakeGql>;
  product_item: Maybe<GqlProductItemV2Gql>;
};

export type GqlStockIntakeUserGql = {
  __typename?: 'StockIntakeUserGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  stock_intake_user_id: Scalars['String'];
  stock_intake_id: Scalars['String'];
  user_id: Scalars['String'];
  stock_intake: Maybe<GqlStockIntakeGql>;
  user: Maybe<GqlUserV2Gql>;
};

export type GqlStockIntakeItemAssetGql = {
  __typename?: 'StockIntakeItemAssetGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  stock_intake_item_asset_id: Scalars['String'];
  stock_intake_item_id: Maybe<Scalars['String']>;
  parent_id: Maybe<Scalars['String']>;
  is_sku: Scalars['Boolean'];
  asset_id: Scalars['String'];
  product_item_id: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  weight: Scalars['Float'];
  parent: Maybe<GqlStockIntakeItemAssetGql>;
  stock_intake_item: Maybe<GqlStockIntakeItemGql>;
  assets: Maybe<GqlAssetGql>;
};

export type GqlStockTakeGql = {
  __typename?: 'StockTakeGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  stock_take_id: Scalars['String'];
  date: Scalars['String'];
  product_item_id: Scalars['String'];
  unit_of_measure_id: Maybe<Scalars['String']>;
  inventory_quantity: Maybe<Scalars['Float']>;
  verified_quantity: Maybe<Scalars['Float']>;
  distribution_center_id: Maybe<Scalars['String']>;
  distribution_center: Maybe<GqlDistributionCenterGql>;
  product_item: Maybe<GqlProductItemV2Gql>;
};

export type GqlProcessingLossGql = {
  __typename?: 'ProcessingLossGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  processing_loss_id: Scalars['String'];
  processing_loss_number: Scalars['String'];
  depot_id: Maybe<Scalars['String']>;
  batch_id: Maybe<Scalars['String']>;
  date: Scalars['String'];
  cancellation_reason: Maybe<Scalars['String']>;
  loss_type: Maybe<GqlLossType>;
  processing_loss_type_id: Maybe<Scalars['String']>;
  distribution_center_id: Maybe<Scalars['String']>;
  processing_loss_items: Maybe<Array<Maybe<GqlProcessingLossItemGql>>>;
  processing_loss_type: Maybe<GqlProcessingLossTypeGql>;
  distribution_center: Maybe<GqlDistributionCenterGql>;
  product_item: Maybe<GqlProductItemV2Gql>;
  uom: Maybe<GqlUomv2Gql>;
  packaging_unit: Maybe<GqlPackagingUnitV2Gql>;
  depot: Maybe<GqlDepotV2Gql>;
  user: Maybe<GqlUserV2Gql>;
  batch: Maybe<GqlBatchGql>;
};

/** An enumeration. */
export enum GqlLossType {
  Processing = 'PROCESSING',
  PostProcessing = 'POST_PROCESSING',
  Staging = 'STAGING',
  Ripening = 'RIPENING',
  Sampling = 'SAMPLING',
  Disposal = 'DISPOSAL',
  Warehouse = 'WAREHOUSE',
  Writeoff = 'WRITEOFF',
  B2CReturns = 'B2_C_RETURNS',
  GtReturns = 'GT_RETURNS',
  DepotStaging = 'DEPOT_STAGING',
  MarketResearch = 'MARKET_RESEARCH',
  InTransit = 'IN_TRANSIT'
}

export type GqlProcessingLossItemGql = {
  __typename?: 'ProcessingLossItemGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  processing_loss_item_id: Scalars['String'];
  processing_loss_id: Scalars['String'];
  date: Scalars['String'];
  product_id: Scalars['String'];
  product_item_id: Scalars['String'];
  quantity: Scalars['Float'];
  unit_of_measure_id: Scalars['String'];
  packaging_unit_id: Maybe<Scalars['String']>;
  packaging_unit_quantity: Maybe<Scalars['Int']>;
  product_loss_reason_id: Scalars['String'];
  picture: Maybe<Scalars['String']>;
  thumbnail: Maybe<Scalars['String']>;
  weight: Maybe<Scalars['Float']>;
  cancellation_reason: Maybe<Scalars['String']>;
  processing_loss: Maybe<GqlProcessingLossGql>;
  product_loss_reason: Maybe<GqlProductLossReasonGql>;
  signed_picture: Maybe<Scalars['String']>;
  product_item: Maybe<GqlProductItemV2Gql>;
  uom: Maybe<GqlUomv2Gql>;
};

export type GqlProductLossReasonGql = {
  __typename?: 'ProductLossReasonGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  product_loss_reason_id: Scalars['String'];
  loss_reason_id: Scalars['String'];
  product_id: Scalars['String'];
  loss_reason: Maybe<GqlLossReasonGql>;
  product: Maybe<GqlProductV2Gql>;
};

export type GqlLossReasonGql = {
  __typename?: 'LossReasonGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  loss_reason_id: Scalars['String'];
  loss_reason: Scalars['String'];
  product_loss_reasons: Maybe<Array<Maybe<GqlProductLossReasonGql>>>;
};

export type GqlProcessingLossTypeGql = {
  __typename?: 'ProcessingLossTypeGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  processing_loss_type_id: Scalars['String'];
  processing_loss_type_name: Scalars['String'];
  processing_loss_type_description: Maybe<Scalars['String']>;
  processing_losses: Maybe<Array<Maybe<GqlProcessingLossGql>>>;
};

export type GqlStockAvailabilityGqLv2Connection = {
  __typename?: 'StockAvailabilityGQLv2Connection';
  /** Pagination data for this connection. */
  pageInfo: GqlPageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlStockAvailabilityGqLv2Edge>>;
};

/** A Relay edge containing a `StockAvailabilityGQLv2` and its cursor. */
export type GqlStockAvailabilityGqLv2Edge = {
  __typename?: 'StockAvailabilityGQLv2Edge';
  /** The item at the end of the edge */
  node: Maybe<GqlStockAvailabilityGqLv2>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlStockAvailabilityGqLv2 = GqlNode & {
  __typename?: 'StockAvailabilityGQLv2';
  region_id: Maybe<Scalars['String']>;
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  stock_availability_id: Scalars['String'];
  sale_date: Scalars['String'];
  stock_entry_number: Scalars['String'];
  product_item_id: Scalars['String'];
  wms_constraint: Maybe<Scalars['String']>;
  quantity: Maybe<Scalars['Float']>;
  unit_of_measure_id: Scalars['String'];
  distribution_center_id: Scalars['String'];
  distribution_center: Maybe<GqlDistributionCenterGql>;
  /** The ID of the object. */
  id: Scalars['ID'];
  product_item: Maybe<GqlProductItemV2Gql>;
  user: Maybe<GqlUserV2Gql>;
  uom: Maybe<GqlUomv2Gql>;
  ordered_stock: Maybe<GqlStockOrderedGql>;
  region: Maybe<GqlRegionV2Gql>;
};

export type GqlWarehouseTransferRequestItemGql = {
  __typename?: 'WarehouseTransferRequestItemGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  warehouse_transfer_request_item_id: Scalars['String'];
  warehouse_transfer_request_id: Scalars['String'];
  product_item_id: Scalars['String'];
  quantity: Scalars['Int'];
  weight: Scalars['Float'];
  warehouse_transfer_request: Maybe<GqlWarehouseTransferRequestGql>;
  product_item: Maybe<GqlProductItemV2Gql>;
  loaded_quantity: Maybe<Scalars['Int']>;
};

export type GqlReservePickingJobGql = {
  __typename?: 'ReservePickingJobGQL';
  cancelled: Scalars['Boolean'];
  cancellation_reason: Maybe<Scalars['String']>;
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  job_id: Scalars['String'];
  job_number: Scalars['String'];
  assigned_to: Maybe<Scalars['String']>;
  job_status: Maybe<Scalars['String']>;
  start_time: Maybe<Scalars['DateTime']>;
  close_time: Maybe<Scalars['DateTime']>;
  fdr_id: Maybe<Scalars['String']>;
  warehouse_transfer_request_id: Maybe<Scalars['String']>;
  pickface_id: Maybe<Scalars['String']>;
  source_location_id: Scalars['String'];
  product_item_id: Scalars['String'];
  destination_location_id: Scalars['String'];
  handling_unit_id: Scalars['String'];
  quantity: Scalars['Int'];
  job_type: GqlReserveJobType;
  fdr: Maybe<GqlFulfilmentDispatchRequestGql>;
  warehouse_transfer_request: Maybe<GqlWarehouseTransferRequestGql>;
  pickface: Maybe<GqlStorageLocationGql>;
  source_location: Maybe<GqlStorageLocationGql>;
  destination_location: Maybe<GqlStorageLocationGql>;
  handling_unit: Maybe<GqlHandlingUnitGql>;
  assigned: Maybe<GqlUserV2Gql>;
  product_item: Maybe<GqlProductItemV2Gql>;
};

/** An enumeration. */
export enum GqlReserveJobType {
  Letdown = 'LETDOWN',
  Dispatch = 'DISPATCH',
  Disposal = 'DISPOSAL'
}

export type GqlFulfilmentDispatchRequestGql = {
  __typename?: 'FulfilmentDispatchRequestGQL';
  cancelled: Scalars['Boolean'];
  cancellation_reason: Maybe<Scalars['String']>;
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  fdr_id: Scalars['String'];
  fdr_number: Scalars['String'];
  sale_date: Scalars['String'];
  vehicle_registration_number: Maybe<Scalars['String']>;
  product_types: Array<Maybe<Scalars['String']>>;
  dock_door_id: Scalars['String'];
  order_time_start: Maybe<Scalars['DateTime']>;
  order_time_end: Maybe<Scalars['DateTime']>;
  depot_ids: Maybe<Array<Maybe<Scalars['String']>>>;
  fdr_complete: Scalars['Boolean'];
  distribution_center_id: Maybe<Scalars['String']>;
  dock_door: Maybe<GqlDockDoorGql>;
  fdr_items: Maybe<Array<Maybe<GqlFulfilmentDispatchRequestItemGql>>>;
  fdr_skus: Maybe<Array<Maybe<GqlFdrskugql>>>;
  reserve_picking_jobs: Maybe<Array<Maybe<GqlReservePickingJobGql>>>;
  pickface_picking_tasks: Maybe<Array<Maybe<GqlPickFacePickingTaskGql>>>;
  loading_task: Maybe<GqlLoadingTaskGql>;
  distribution_center: Maybe<GqlDistributionCenterGql>;
  reserve_picking_balance: Maybe<Array<Maybe<GqlReservePickingBalanceGql>>>;
  created_by_user: Maybe<GqlUserV2Gql>;
};

export type GqlFulfilmentDispatchRequestItemGql = {
  __typename?: 'FulfilmentDispatchRequestItemGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  fdr_item_id: Scalars['String'];
  fdr_id: Scalars['String'];
  route_id: Maybe<Scalars['String']>;
  route_plan_id: Maybe<Scalars['String']>;
  fdr: Maybe<GqlFulfilmentDispatchRequestGql>;
  route_plan: Maybe<GqlRoutePlanGql>;
  route: Maybe<GqlRouteV2Gql>;
};

export type GqlFdrskugql = {
  __typename?: 'FDRSKUGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  fdr_sku_id: Scalars['String'];
  fdr_id: Scalars['String'];
  product_item_id: Scalars['String'];
  quantity: Scalars['Int'];
  available_stock: Scalars['Int'];
  amount_planned_from_reserve: Scalars['Int'];
  amount_planned_from_pickface: Scalars['Int'];
  fdr: Maybe<GqlFulfilmentDispatchRequestGql>;
  inventory_balance: Maybe<Scalars['Int']>;
  verified: Maybe<Scalars['Int']>;
  unverified: Maybe<Scalars['Int']>;
  product_item: Maybe<GqlProductItemV2Gql>;
  loaded_quantity: Maybe<Scalars['Int']>;
};

export type GqlPickFacePickingTaskGql = {
  __typename?: 'PickFacePickingTaskGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  task_id: Scalars['String'];
  fdr_id: Maybe<Scalars['String']>;
  warehouse_transfer_request_id: Maybe<Scalars['String']>;
  product_item_id: Scalars['String'];
  quantity: Scalars['Int'];
  source_location_id: Scalars['String'];
  fdr: Maybe<GqlFulfilmentDispatchRequestGql>;
  warehouse_transfer_request: Maybe<GqlWarehouseTransferRequestGql>;
  source_location: Maybe<GqlStorageLocationGql>;
  task_items: Maybe<Array<Maybe<GqlPickFacePickingTaskItemGql>>>;
  balance: Maybe<Scalars['Int']>;
  product_item: Maybe<GqlProductItemV2Gql>;
};

export type GqlPickFacePickingTaskItemGql = {
  __typename?: 'PickFacePickingTaskItemGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  task_item_id: Scalars['String'];
  picking_task_id: Scalars['String'];
  source_handling_unit_id: Scalars['String'];
  handling_unit_item_id: Scalars['String'];
  picking_hu_id: Maybe<Scalars['String']>;
  picking_task: Maybe<GqlPickFacePickingTaskGql>;
  source_handling_unit: Maybe<GqlHandlingUnitGql>;
  handling_unit_item: Maybe<GqlHandlingUnitItemGql>;
  picking_hu: Maybe<GqlPickingHandlingUnitGql>;
};

export type GqlPickingHandlingUnitGql = {
  __typename?: 'PickingHandlingUnitGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  picking_hu_id: Scalars['String'];
  dispatch_reference_id: Scalars['String'];
  handling_unit_id: Scalars['String'];
  is_complete: Scalars['Boolean'];
  final_location_id: Maybe<Scalars['String']>;
  final_location: Maybe<GqlStorageLocationGql>;
  picked_labels: Maybe<Array<Maybe<GqlPickFacePickingTaskItemGql>>>;
  handling_unit: Maybe<GqlHandlingUnitGql>;
  picked_quantity: Maybe<Scalars['Int']>;
};

export type GqlLoadingTaskGql = {
  __typename?: 'LoadingTaskGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  loading_task_id: Scalars['String'];
  loading_number: Maybe<Scalars['String']>;
  inventory_location_id: Scalars['String'];
  picture: Maybe<Scalars['String']>;
  fdr_id: Maybe<Scalars['String']>;
  warehouse_transfer_request_id: Maybe<Scalars['String']>;
  disposal_id: Maybe<Scalars['String']>;
  status: GqlLoadingTaskStatus;
  inventory_location: Maybe<GqlInventoryLocationGql>;
  fdr: Maybe<GqlFulfilmentDispatchRequestGql>;
  warehouse_transfer_request: Maybe<GqlWarehouseTransferRequestGql>;
  disposal: Maybe<GqlDisposalGql>;
  loading_task_items: Maybe<Array<Maybe<GqlLoadingTaskItemGql>>>;
  loaded_weight: Maybe<Scalars['Int']>;
  created_by_user: Maybe<GqlUserV2Gql>;
  loaded_quantity: Maybe<Array<Maybe<GqlLoadedQuantityGql>>>;
  total_loaded_quantity: Maybe<Scalars['Int']>;
};

/** An enumeration. */
export enum GqlLoadingTaskStatus {
  Open = 'OPEN',
  Closed = 'CLOSED',
  Inprogress = 'INPROGRESS',
  Verifying = 'VERIFYING',
  Verified = 'VERIFIED',
  Cancelled = 'CANCELLED'
}

export type GqlAssetTrackerGql = {
  __typename?: 'AssetTrackerGQL';
  cancelled: Scalars['Boolean'];
  cancellation_reason: Maybe<Scalars['String']>;
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  asset_tracker_id: Scalars['String'];
  origin_id: Scalars['String'];
  destination_id: Scalars['String'];
  asset_tracker_number: Scalars['String'];
  asset_tracker_reason: GqlAssetTrackerReason;
  expected_final_destination_id: Maybe<Scalars['String']>;
  origin: Maybe<GqlInventoryLocationGql>;
  destination: Maybe<GqlInventoryLocationGql>;
  asset_tracker_items: Maybe<Array<Maybe<GqlAssetTrackerItemGql>>>;
  damaged_asset_photos: Maybe<Array<Maybe<GqlDamagedAssetPhotoGql>>>;
  expected_final_destination: Maybe<GqlInventoryLocationGql>;
  quantity: Maybe<Scalars['Int']>;
};

/** An enumeration. */
export enum GqlAssetTrackerReason {
  WarehouseTransfer = 'WAREHOUSE_TRANSFER',
  RepairAndMaintenance = 'REPAIR_AND_MAINTENANCE',
  Sourcing = 'SOURCING',
  Production = 'PRODUCTION',
  Disposal = 'DISPOSAL',
  Dispatch = 'DISPATCH',
  CrateCount = 'CRATE_COUNT'
}

export type GqlAssetTrackerItemGql = {
  __typename?: 'AssetTrackerItemGQL';
  cancelled: Scalars['Boolean'];
  cancellation_reason: Maybe<Scalars['String']>;
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  asset_tracker_item_id: Scalars['String'];
  asset_tracker_id: Scalars['String'];
  asset_id: Scalars['String'];
  asset_tracker: Maybe<GqlAssetTrackerGql>;
  asset: Maybe<GqlAssetGql>;
};

export type GqlDamagedAssetPhotoGql = {
  __typename?: 'DamagedAssetPhotoGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  asset_photo_id: Scalars['String'];
  asset_tracker_id: Scalars['String'];
  file_name: Scalars['String'];
  asset_tracker: Maybe<GqlAssetTrackerGql>;
  signed_url: Maybe<Scalars['String']>;
};

export type GqlDisposalGql = {
  __typename?: 'DisposalGQL';
  cancelled: Scalars['Boolean'];
  cancellation_reason: Maybe<Scalars['String']>;
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  disposal_id: Scalars['String'];
  disposal_number: Scalars['String'];
  dock_door_id: Scalars['String'];
  disposal_complete: Scalars['Boolean'];
  dock_door: Maybe<GqlDockDoorGql>;
  disposal_items: Maybe<Array<Maybe<GqlDisposalItemGql>>>;
  loading_task: Maybe<Array<Maybe<GqlLoadingTaskGql>>>;
};

export type GqlDisposalItemGql = {
  __typename?: 'DisposalItemGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  disposal_item_id: Scalars['String'];
  disposal_id: Scalars['String'];
  product_item_id: Scalars['String'];
  quantity: Scalars['Int'];
  disposal: Maybe<GqlDisposalGql>;
  product_item: Maybe<GqlProductItemV2Gql>;
  loaded_quantity: Maybe<Scalars['Int']>;
};

export type GqlLoadingTaskItemGql = {
  __typename?: 'LoadingTaskItemGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  task_item_id: Scalars['String'];
  loading_task_id: Scalars['String'];
  handling_unit_item_id: Scalars['String'];
  source_handling_unit_id: Scalars['String'];
  loading_task: Maybe<GqlLoadingTaskGql>;
  handling_unit_item: Maybe<GqlHandlingUnitItemGql>;
  source_handling_unit: Maybe<GqlHandlingUnitGql>;
};

export type GqlLoadedQuantityGql = {
  __typename?: 'LoadedQuantityGQL';
  quantity: Maybe<Scalars['Int']>;
  product_item_id: Maybe<Scalars['String']>;
};

export type GqlReservePickingBalanceGql = {
  __typename?: 'ReservePickingBalanceGQL';
  balance: Maybe<Scalars['Int']>;
  product_item_id: Maybe<Scalars['String']>;
};

export type GqlWmsReturnsGql = {
  __typename?: 'WMSReturnsGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  wms_returns_id: Scalars['String'];
  wms_returns_number: Scalars['String'];
  appointment_id: Scalars['String'];
  is_complete: Scalars['Boolean'];
  appointment: Maybe<GqlAppointmentGql>;
  returns_handling_unit_items: Maybe<Array<Maybe<GqlReturnsHandlingUnitItemGql>>>;
};

export type GqlBinGql = {
  __typename?: 'BinGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  bin_id: Scalars['String'];
  bin_type: GqlBinType;
  aisle: Maybe<Scalars['String']>;
  level: Maybe<Scalars['String']>;
  bin_position: Maybe<Scalars['String']>;
  is_pickface: Scalars['Boolean'];
  capacity: Scalars['Int'];
  pickface_product_item_id: Maybe<Scalars['String']>;
  warehouse_id: Scalars['String'];
  storage_location_id: Scalars['String'];
  distribution_center_id: Maybe<Scalars['String']>;
  storage_location: Maybe<GqlStorageLocationGql>;
  warehouse: Maybe<GqlWarehouseGql>;
  storage: Maybe<GqlStorageLocationGql>;
  product_zone: Maybe<Array<Maybe<GqlProductZoneGql>>>;
  bin_names: Maybe<Array<Maybe<GqlBinNameGql>>>;
  pickface_product_item: Maybe<GqlProductItemV2Gql>;
};

/** An enumeration. */
export enum GqlBinType {
  Blockstack = 'BLOCKSTACK',
  Crossdock = 'CROSSDOCK',
  Deepracking3 = 'DEEPRACKING3',
  Deepracking6 = 'DEEPRACKING6',
  Selectiveracking = 'SELECTIVERACKING',
  Qualitycontrol = 'QUALITYCONTROL',
  Returns = 'RETURNS',
  Waste = 'WASTE',
  Staging = 'STAGING',
  Bananaroom = 'BANANAROOM'
}

export type GqlProductZoneGql = {
  __typename?: 'ProductZoneGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  product_zone_id: Scalars['String'];
  product_zone_name: Scalars['String'];
  product_zone_description: Maybe<Scalars['String']>;
  product_zone_type: Maybe<Scalars['String']>;
  bins: Maybe<Array<Maybe<GqlBinGql>>>;
  rules: Maybe<Array<Maybe<GqlRuleGql>>>;
};

export type GqlRuleGql = {
  __typename?: 'RuleGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  rule_id: Scalars['String'];
  rule_name: Scalars['String'];
  rule_description: Maybe<Scalars['String']>;
  product_rules: Maybe<Array<Maybe<GqlProductRuleGql>>>;
  category_rules: Maybe<Array<Maybe<GqlCategoryRuleGql>>>;
  product_zone: Maybe<Array<Maybe<GqlProductZoneGql>>>;
};

export type GqlProductRuleGql = {
  __typename?: 'ProductRuleGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  product_rule_id: Scalars['String'];
  rule_id: Scalars['String'];
  product_id: Maybe<Scalars['String']>;
  product_item_id: Maybe<Scalars['String']>;
  is_sku_rule: Scalars['Boolean'];
  rule: Maybe<GqlRuleGql>;
  product_item: Maybe<GqlProductItemV2Gql>;
  product: Maybe<GqlProductV2Gql>;
};

export type GqlCategoryRuleGql = {
  __typename?: 'CategoryRuleGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  category_rule_id: Scalars['String'];
  rule_id: Scalars['String'];
  category_rule_type: GqlCategoryType;
  category_id: Scalars['String'];
  rule: Maybe<GqlRuleGql>;
  category: Maybe<GqlCategoryV2Gql>;
};

/** An enumeration. */
export enum GqlCategoryType {
  Primary = 'PRIMARY',
  Secondary = 'SECONDARY'
}

/** Category GQL schema */
export type GqlCategoryV2Gql = {
  __typename?: 'CategoryV2GQL';
  category_id: Scalars['String'];
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  category_name: Scalars['String'];
  slug: Scalars['String'];
  image: Maybe<Scalars['String']>;
  image_url: Maybe<Scalars['String']>;
  banner_image: Maybe<Scalars['String']>;
  banner_image_url: Maybe<Scalars['String']>;
  department_id: Scalars['String'];
  featured: Scalars['Boolean'];
  parent_id: Maybe<Scalars['String']>;
  ordering_window: Maybe<Scalars['JSONString']>;
  department: Maybe<GqlDepartmentV2Gql>;
  products: Maybe<Array<Maybe<GqlProductV2Gql>>>;
  regions: Maybe<Array<Maybe<GqlCategoryRegionV2Gql>>>;
  sub_categories: Maybe<Array<Maybe<GqlCategoryV2Gql>>>;
  total_products: Maybe<Scalars['Int']>;
  total_regions: Maybe<Scalars['Int']>;
};

export type GqlBinNameGql = {
  __typename?: 'BinNameGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  bin_name_id: Scalars['String'];
  bin_id: Scalars['String'];
  aisle: Scalars['String'];
  level: Scalars['String'];
  bin_position: Scalars['String'];
  bin_name_direction: GqlBinNameDirection;
  bin: Maybe<GqlBinGql>;
};

/** An enumeration. */
export enum GqlBinNameDirection {
  In = 'IN',
  Out = 'OUT',
  Both = 'BOTH'
}

export type GqlWasteAreaGql = {
  __typename?: 'WasteAreaGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  waste_area_id: Scalars['String'];
  waste_area_number: Scalars['String'];
  warehouse_id: Scalars['String'];
  storage_location_id: Scalars['String'];
  storage_location: Maybe<GqlStorageLocationGql>;
  warehouse: Maybe<GqlWarehouseGql>;
  storage: Maybe<GqlStorageLocationGql>;
};

export type GqlProductTransferHandlingUnitItemGql = {
  __typename?: 'ProductTransferHandlingUnitItemGQL';
  cancelled: Scalars['Boolean'];
  cancellation_reason: Maybe<Scalars['String']>;
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  product_transfer_handling_unit_item_id: Scalars['String'];
  product_transfer_id: Scalars['String'];
  handling_unit_item_id: Scalars['String'];
  product_transfer: Maybe<GqlProductTransferGql>;
  handling_unit_item: Maybe<GqlHandlingUnitItemGql>;
};

export type GqlProductTransferGql = {
  __typename?: 'ProductTransferGQL';
  cancelled: Scalars['Boolean'];
  cancellation_reason: Maybe<Scalars['String']>;
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  product_transfer_id: Scalars['String'];
  datetime: Scalars['DateTime'];
  product_transfer_reason_id: Maybe<Scalars['String']>;
  product_transfer_type: GqlProductTransferType;
  product_transfer_number: Scalars['String'];
  origin_handling_unit_id: Maybe<Scalars['String']>;
  destination_handling_unit_id: Maybe<Scalars['String']>;
  origin_handling_unit_item_id: Maybe<Scalars['String']>;
  destination_handling_unit_item_id: Maybe<Scalars['String']>;
  origin_storage_location_id: Scalars['String'];
  destination_storage_location_id: Scalars['String'];
  distribution_center_id: Maybe<Scalars['String']>;
  product_transfer_reason: Maybe<GqlProductTransferReasonGql>;
  origin_handling_unit: Maybe<GqlHandlingUnitGql>;
  destination_handling_unit: Maybe<GqlHandlingUnitGql>;
  origin_handling_unit_item: Maybe<GqlHandlingUnitItemGql>;
  destination_handling_unit_item: Maybe<GqlHandlingUnitItemGql>;
  origin_storage_location: Maybe<GqlStorageLocationGql>;
  destination_storage_location: Maybe<GqlStorageLocationGql>;
  product_transfer_handling_units: Maybe<Array<Maybe<GqlProductTransferHandlingUnitGql>>>;
  product_transfer_handling_unit_items: Maybe<Array<Maybe<GqlProductTransferHandlingUnitItemGql>>>;
};

/** An enumeration. */
export enum GqlProductTransferType {
  HandlingUnitTransfer = 'HANDLING_UNIT_TRANSFER',
  ProductTransfer = 'PRODUCT_TRANSFER',
  LocationTransfer = 'LOCATION_TRANSFER'
}

export type GqlProductTransferReasonGql = {
  __typename?: 'ProductTransferReasonGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  product_transfer_reason_id: Scalars['String'];
  product_transfer_reason_name: Scalars['String'];
  product_transfers: Maybe<Array<Maybe<GqlProductTransferGql>>>;
};

export type GqlProductTransferHandlingUnitGql = {
  __typename?: 'ProductTransferHandlingUnitGQL';
  cancelled: Scalars['Boolean'];
  cancellation_reason: Maybe<Scalars['String']>;
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  product_transfer_handling_unit_id: Scalars['String'];
  product_transfer_id: Scalars['String'];
  handling_unit_id: Scalars['String'];
  product_transfer: Maybe<GqlProductTransferGql>;
  handling_unit: Maybe<GqlHandlingUnitGql>;
};

export type GqlReturnsHandlingUnitGqlConnection = {
  __typename?: 'ReturnsHandlingUnitGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlReturnsHandlingUnitGqlEdge>>;
};

/** A Relay edge containing a `ReturnsHandlingUnitGQL` and its cursor. */
export type GqlReturnsHandlingUnitGqlEdge = {
  __typename?: 'ReturnsHandlingUnitGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlReturnsHandlingUnitGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlWmsReturnsGqlConnection = {
  __typename?: 'WMSReturnsGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlWmsReturnsGqlEdge>>;
};

/** A Relay edge containing a `WMSReturnsGQL` and its cursor. */
export type GqlWmsReturnsGqlEdge = {
  __typename?: 'WMSReturnsGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlWmsReturnsGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlDisposalItemGqlConnection = {
  __typename?: 'DisposalItemGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlDisposalItemGqlEdge>>;
};

/** A Relay edge containing a `DisposalItemGQL` and its cursor. */
export type GqlDisposalItemGqlEdge = {
  __typename?: 'DisposalItemGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlDisposalItemGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlDisposalGqlConnection = {
  __typename?: 'DisposalGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlDisposalGqlEdge>>;
};

/** A Relay edge containing a `DisposalGQL` and its cursor. */
export type GqlDisposalGqlEdge = {
  __typename?: 'DisposalGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlDisposalGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlWarehouseTransferRequestItemGqlConnection = {
  __typename?: 'WarehouseTransferRequestItemGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlWarehouseTransferRequestItemGqlEdge>>;
};

/** A Relay edge containing a `WarehouseTransferRequestItemGQL` and its cursor. */
export type GqlWarehouseTransferRequestItemGqlEdge = {
  __typename?: 'WarehouseTransferRequestItemGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlWarehouseTransferRequestItemGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlWarehouseTransferRequestGqlConnection = {
  __typename?: 'WarehouseTransferRequestGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlWarehouseTransferRequestGqlEdge>>;
};

/** A Relay edge containing a `WarehouseTransferRequestGQL` and its cursor. */
export type GqlWarehouseTransferRequestGqlEdge = {
  __typename?: 'WarehouseTransferRequestGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlWarehouseTransferRequestGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlFdrskugqlConnection = {
  __typename?: 'FDRSKUGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlFdrskugqlEdge>>;
};

/** A Relay edge containing a `FDRSKUGQL` and its cursor. */
export type GqlFdrskugqlEdge = {
  __typename?: 'FDRSKUGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlFdrskugql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlFulfilmentDispatchRequestItemGqlConnection = {
  __typename?: 'FulfilmentDispatchRequestItemGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlFulfilmentDispatchRequestItemGqlEdge>>;
};

/** A Relay edge containing a `FulfilmentDispatchRequestItemGQL` and its cursor. */
export type GqlFulfilmentDispatchRequestItemGqlEdge = {
  __typename?: 'FulfilmentDispatchRequestItemGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlFulfilmentDispatchRequestItemGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlFulfilmentDispatchRequestGqlConnection = {
  __typename?: 'FulfilmentDispatchRequestGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlFulfilmentDispatchRequestGqlEdge>>;
};

/** A Relay edge containing a `FulfilmentDispatchRequestGQL` and its cursor. */
export type GqlFulfilmentDispatchRequestGqlEdge = {
  __typename?: 'FulfilmentDispatchRequestGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlFulfilmentDispatchRequestGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlReservePickingJobGqlConnection = {
  __typename?: 'ReservePickingJobGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlReservePickingJobGqlEdge>>;
};

/** A Relay edge containing a `ReservePickingJobGQL` and its cursor. */
export type GqlReservePickingJobGqlEdge = {
  __typename?: 'ReservePickingJobGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlReservePickingJobGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlPickFacePickingTaskGqlConnection = {
  __typename?: 'PickFacePickingTaskGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlPickFacePickingTaskGqlEdge>>;
};

/** A Relay edge containing a `PickFacePickingTaskGQL` and its cursor. */
export type GqlPickFacePickingTaskGqlEdge = {
  __typename?: 'PickFacePickingTaskGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlPickFacePickingTaskGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlPickingHandlingUnitGqlConnection = {
  __typename?: 'PickingHandlingUnitGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlPickingHandlingUnitGqlEdge>>;
};

/** A Relay edge containing a `PickingHandlingUnitGQL` and its cursor. */
export type GqlPickingHandlingUnitGqlEdge = {
  __typename?: 'PickingHandlingUnitGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlPickingHandlingUnitGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlPickFacePickingTaskItemGqlConnection = {
  __typename?: 'PickFacePickingTaskItemGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlPickFacePickingTaskItemGqlEdge>>;
};

/** A Relay edge containing a `PickFacePickingTaskItemGQL` and its cursor. */
export type GqlPickFacePickingTaskItemGqlEdge = {
  __typename?: 'PickFacePickingTaskItemGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlPickFacePickingTaskItemGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlLoadingTaskGqlConnection = {
  __typename?: 'LoadingTaskGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlLoadingTaskGqlEdge>>;
};

/** A Relay edge containing a `LoadingTaskGQL` and its cursor. */
export type GqlLoadingTaskGqlEdge = {
  __typename?: 'LoadingTaskGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlLoadingTaskGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlLoadingTaskItemGqlConnection = {
  __typename?: 'LoadingTaskItemGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlLoadingTaskItemGqlEdge>>;
};

/** A Relay edge containing a `LoadingTaskItemGQL` and its cursor. */
export type GqlLoadingTaskItemGqlEdge = {
  __typename?: 'LoadingTaskItemGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlLoadingTaskItemGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlPutAwayJobGqlConnection = {
  __typename?: 'PutAwayJobGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlPutAwayJobGqlEdge>>;
};

/** A Relay edge containing a `PutAwayJobGQL` and its cursor. */
export type GqlPutAwayJobGqlEdge = {
  __typename?: 'PutAwayJobGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlPutAwayJobGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlPutAwayJobGql = {
  __typename?: 'PutAwayJobGQL';
  cancelled: Scalars['Boolean'];
  cancellation_reason: Maybe<Scalars['String']>;
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  job_id: Scalars['String'];
  job_number: Scalars['String'];
  assigned_to: Maybe<Scalars['String']>;
  job_status: Maybe<Scalars['String']>;
  start_time: Maybe<Scalars['DateTime']>;
  close_time: Maybe<Scalars['DateTime']>;
  batch_id: Maybe<Scalars['String']>;
  stock_intake_id: Maybe<Scalars['String']>;
  stock_intake_item_id: Maybe<Scalars['String']>;
  source_location_id: Scalars['String'];
  destination_location_id: Scalars['String'];
  source_handling_unit_id: Scalars['String'];
  destination_handling_unit_id: Scalars['String'];
  product_item_id: Maybe<Scalars['String']>;
  quantity: Scalars['Int'];
  stock_intake: Maybe<GqlStockIntakeGql>;
  stock_intake_item: Maybe<GqlStockIntakeItemGql>;
  source_location: Maybe<GqlStorageLocationGql>;
  destination_location: Maybe<GqlStorageLocationGql>;
  source_handling_unit: Maybe<GqlHandlingUnitGql>;
  destination_handling_unit: Maybe<GqlHandlingUnitGql>;
  product_item: Maybe<GqlProductItemV2Gql>;
  uom: Maybe<GqlUomv2Gql>;
  assigned: Maybe<GqlUserV2Gql>;
  batch: Maybe<GqlBatchGql>;
};

export type GqlProductZoneGqlConnection = {
  __typename?: 'ProductZoneGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlProductZoneGqlEdge>>;
};

/** A Relay edge containing a `ProductZoneGQL` and its cursor. */
export type GqlProductZoneGqlEdge = {
  __typename?: 'ProductZoneGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlProductZoneGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlRuleGqlConnection = {
  __typename?: 'RuleGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlRuleGqlEdge>>;
};

/** A Relay edge containing a `RuleGQL` and its cursor. */
export type GqlRuleGqlEdge = {
  __typename?: 'RuleGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlRuleGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlProductRuleGqlConnection = {
  __typename?: 'ProductRuleGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlProductRuleGqlEdge>>;
};

/** A Relay edge containing a `ProductRuleGQL` and its cursor. */
export type GqlProductRuleGqlEdge = {
  __typename?: 'ProductRuleGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlProductRuleGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlCategoryRuleGqlConnection = {
  __typename?: 'CategoryRuleGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlCategoryRuleGqlEdge>>;
};

/** A Relay edge containing a `CategoryRuleGQL` and its cursor. */
export type GqlCategoryRuleGqlEdge = {
  __typename?: 'CategoryRuleGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlCategoryRuleGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlHandlingUnitGqlConnection = {
  __typename?: 'HandlingUnitGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlHandlingUnitGqlEdge>>;
};

/** A Relay edge containing a `HandlingUnitGQL` and its cursor. */
export type GqlHandlingUnitGqlEdge = {
  __typename?: 'HandlingUnitGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlHandlingUnitGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlStockIntakeSkuGqlConnection = {
  __typename?: 'StockIntakeSkuGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlStockIntakeSkuGqlEdge>>;
};

/** A Relay edge containing a `StockIntakeSkuGQL` and its cursor. */
export type GqlStockIntakeSkuGqlEdge = {
  __typename?: 'StockIntakeSkuGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlStockIntakeSkuGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlHandlingUnitItemGqlConnection = {
  __typename?: 'HandlingUnitItemGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlHandlingUnitItemGqlEdge>>;
};

/** A Relay edge containing a `HandlingUnitItemGQL` and its cursor. */
export type GqlHandlingUnitItemGqlEdge = {
  __typename?: 'HandlingUnitItemGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlHandlingUnitItemGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlStorageLocationGqlConnection = {
  __typename?: 'StorageLocationGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlStorageLocationGqlEdge>>;
};

/** A Relay edge containing a `StorageLocationGQL` and its cursor. */
export type GqlStorageLocationGqlEdge = {
  __typename?: 'StorageLocationGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlStorageLocationGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlStorageLocationTypeGqlConnection = {
  __typename?: 'StorageLocationTypeGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlStorageLocationTypeGqlEdge>>;
};

/** A Relay edge containing a `StorageLocationTypeGQL` and its cursor. */
export type GqlStorageLocationTypeGqlEdge = {
  __typename?: 'StorageLocationTypeGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlStorageLocationTypeGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlProductLossReasonGqlConnection = {
  __typename?: 'ProductLossReasonGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlProductLossReasonGqlEdge>>;
};

/** A Relay edge containing a `ProductLossReasonGQL` and its cursor. */
export type GqlProductLossReasonGqlEdge = {
  __typename?: 'ProductLossReasonGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlProductLossReasonGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlDockDoorGqlConnection = {
  __typename?: 'DockDoorGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlDockDoorGqlEdge>>;
};

/** A Relay edge containing a `DockDoorGQL` and its cursor. */
export type GqlDockDoorGqlEdge = {
  __typename?: 'DockDoorGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlDockDoorGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlCustomBinGql = {
  __typename?: 'CustomBinGQL';
  bin_id: Maybe<Scalars['String']>;
  storage_location_id: Maybe<Scalars['String']>;
  aisle: Maybe<Scalars['String']>;
  bin_position: Maybe<Scalars['String']>;
  level: Maybe<Scalars['String']>;
};

export type GqlBinGqlConnection = {
  __typename?: 'BinGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlBinGqlEdge>>;
};

/** A Relay edge containing a `BinGQL` and its cursor. */
export type GqlBinGqlEdge = {
  __typename?: 'BinGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlBinGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlBinNameGqlConnection = {
  __typename?: 'BinNameGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlBinNameGqlEdge>>;
};

/** A Relay edge containing a `BinNameGQL` and its cursor. */
export type GqlBinNameGqlEdge = {
  __typename?: 'BinNameGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlBinNameGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlWasteAreaGqlConnection = {
  __typename?: 'WasteAreaGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlWasteAreaGqlEdge>>;
};

/** A Relay edge containing a `WasteAreaGQL` and its cursor. */
export type GqlWasteAreaGqlEdge = {
  __typename?: 'WasteAreaGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlWasteAreaGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlAppointmentItemGqlConnection = {
  __typename?: 'AppointmentItemGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlAppointmentItemGqlEdge>>;
};

/** A Relay edge containing a `AppointmentItemGQL` and its cursor. */
export type GqlAppointmentItemGqlEdge = {
  __typename?: 'AppointmentItemGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlAppointmentItemGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlAppointmentGqlConnection = {
  __typename?: 'AppointmentGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlAppointmentGqlEdge>>;
};

/** A Relay edge containing a `AppointmentGQL` and its cursor. */
export type GqlAppointmentGqlEdge = {
  __typename?: 'AppointmentGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlAppointmentGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlWarehouseGqlConnection = {
  __typename?: 'WarehouseGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlWarehouseGqlEdge>>;
};

/** A Relay edge containing a `WarehouseGQL` and its cursor. */
export type GqlWarehouseGqlEdge = {
  __typename?: 'WarehouseGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlWarehouseGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlStockIntakeGqlConnection = {
  __typename?: 'StockIntakeGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlStockIntakeGqlEdge>>;
};

/** A Relay edge containing a `StockIntakeGQL` and its cursor. */
export type GqlStockIntakeGqlEdge = {
  __typename?: 'StockIntakeGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlStockIntakeGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlEtimsEventOutboxConnection = {
  __typename?: 'EtimsEventOutboxConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlEtimsEventOutboxEdge>>;
};

/** A Relay edge containing a `EtimsEventOutbox` and its cursor. */
export type GqlEtimsEventOutboxEdge = {
  __typename?: 'EtimsEventOutboxEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlEtimsEventOutboxGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlEtimsEventOutboxGql = {
  __typename?: 'EtimsEventOutboxGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  outbox_id: Scalars['Float'];
  intake_id: Maybe<Scalars['String']>;
  dispatch_id: Maybe<Scalars['String']>;
  disposal_id: Maybe<Scalars['String']>;
  dms_disposal_id: Maybe<Scalars['String']>;
  product_item_id: Maybe<Scalars['String']>;
  reference_id: Maybe<Scalars['String']>;
  event_type: Maybe<GqlEtimsEventType>;
  event_payload: Maybe<Scalars['JSONString']>;
  event_response: Maybe<Scalars['JSONString']>;
  exception: Maybe<Scalars['String']>;
  retries: Scalars['Int'];
  processed: Scalars['Boolean'];
  stock_intake: Maybe<GqlStockIntakeGql>;
  fdr: Maybe<GqlFulfilmentDispatchRequestGql>;
  disposed_handling_unit: Maybe<GqlHandlingUnitGql>;
  disposal: Maybe<GqlDisposalGql>;
  product_item: Maybe<GqlProductItemV2Gql>;
};

/** An enumeration. */
export enum GqlEtimsEventType {
  PostIntake = 'POST_INTAKE',
  PostDispatch = 'POST_DISPATCH',
  PostDisposal = 'POST_DISPOSAL',
  PostDmsDisposal = 'POST_DMS_DISPOSAL',
  PostRemainingStock = 'POST_REMAINING_STOCK',
  PostRemainingStockAfterIntake = 'POST_REMAINING_STOCK_AFTER_INTAKE',
  PostRemainingStockAfterDispatch = 'POST_REMAINING_STOCK_AFTER_DISPATCH',
  PostRemainingStockAfterDisposal = 'POST_REMAINING_STOCK_AFTER_DISPOSAL'
}

export type GqlStockIntakeItemGqlConnection = {
  __typename?: 'StockIntakeItemGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlStockIntakeItemGqlEdge>>;
};

/** A Relay edge containing a `StockIntakeItemGQL` and its cursor. */
export type GqlStockIntakeItemGqlEdge = {
  __typename?: 'StockIntakeItemGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlStockIntakeItemGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlProcessingLossGqlConnection = {
  __typename?: 'ProcessingLossGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlProcessingLossGqlEdge>>;
};

/** A Relay edge containing a `ProcessingLossGQL` and its cursor. */
export type GqlProcessingLossGqlEdge = {
  __typename?: 'ProcessingLossGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlProcessingLossGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlProcessingLossItemGqlConnection = {
  __typename?: 'ProcessingLossItemGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlProcessingLossItemGqlEdge>>;
};

/** A Relay edge containing a `ProcessingLossItemGQL` and its cursor. */
export type GqlProcessingLossItemGqlEdge = {
  __typename?: 'ProcessingLossItemGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlProcessingLossItemGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlStorageUnitGqlConnection = {
  __typename?: 'StorageUnitGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlStorageUnitGqlEdge>>;
};

/** A Relay edge containing a `StorageUnitGQL` and its cursor. */
export type GqlStorageUnitGqlEdge = {
  __typename?: 'StorageUnitGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlStorageUnitGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlStorageGqlConnection = {
  __typename?: 'StorageGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlStorageGqlEdge>>;
};

/** A Relay edge containing a `StorageGQL` and its cursor. */
export type GqlStorageGqlEdge = {
  __typename?: 'StorageGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlStorageGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlStockAvailabilityGqlConnection = {
  __typename?: 'StockAvailabilityGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlStockAvailabilityGqlEdge>>;
};

/** A Relay edge containing a `StockAvailabilityGQL` and its cursor. */
export type GqlStockAvailabilityGqlEdge = {
  __typename?: 'StockAvailabilityGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlStockAvailabilityGqLv2>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlStockAvailabilitySummaryGqlConnection = {
  __typename?: 'StockAvailabilitySummaryGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlStockAvailabilitySummaryGqlEdge>>;
};

/** A Relay edge containing a `StockAvailabilitySummaryGQL` and its cursor. */
export type GqlStockAvailabilitySummaryGqlEdge = {
  __typename?: 'StockAvailabilitySummaryGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlStockAvailabilitySummaryGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlStockAvailabilitySummaryGql = {
  __typename?: 'StockAvailabilitySummaryGQL';
  sale_date: Maybe<Scalars['String']>;
  product_item_id: Maybe<Scalars['String']>;
  unit_of_measure_id: Maybe<Scalars['String']>;
  quantity: Maybe<Scalars['Float']>;
  last_date_available: Maybe<Scalars['DateTime']>;
  stock_status: Maybe<Scalars['String']>;
  no_days_oos: Maybe<Scalars['String']>;
  product_item: Maybe<GqlProductItemV2Gql>;
  product_item_uom: Maybe<GqlProductItemUomv2Gql>;
};

/** ProductItemUOM GQL Schema */
export type GqlProductItemUomv2Gql = {
  __typename?: 'ProductItemUOMV2GQL';
  product_item_uom_id: Scalars['String'];
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  product_item_id: Scalars['String'];
  uom_id: Scalars['String'];
  business_domain_id: Scalars['String'];
  conversion_ratio: Scalars['Float'];
  uom: Maybe<GqlUomv2Gql>;
  business_domain: Maybe<GqlBusinessDomainV2Gql>;
  product_item: Maybe<GqlProductItemV2Gql>;
};

export type GqlStockAvailabilityProductItemGqlConnection = {
  __typename?: 'StockAvailabilityProductItemGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlStockAvailabilityProductItemGqlEdge>>;
};

/** A Relay edge containing a `StockAvailabilityProductItemGQL` and its cursor. */
export type GqlStockAvailabilityProductItemGqlEdge = {
  __typename?: 'StockAvailabilityProductItemGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlStockAvailabilityProductItemGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlStockAvailabilityProductItemGql = {
  __typename?: 'StockAvailabilityProductItemGQL';
  product_item_id: Maybe<Scalars['UUID']>;
  product_item_name: Maybe<Scalars['String']>;
  product_id: Maybe<Scalars['UUID']>;
  date_created: Maybe<Scalars['Date']>;
  created_by: Maybe<Scalars['String']>;
  date_updated: Maybe<Scalars['Date']>;
  updated_by: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
  stock_availability: Maybe<GqlStockAvailabilityGqLv2>;
  product_item: Maybe<GqlProductItemV2Gql>;
  product: Maybe<GqlProductV2Gql>;
};

export type GqlBatchDcStockAvailabilityArgs = {
  product_item_id: Scalars['String'];
  distribution_center_id: Scalars['String'];
};

export type GqlDcStockAvailabilityGql = {
  __typename?: 'DCStockAvailabilityGQL';
  product_item_id: Scalars['String'];
  quantity: Maybe<Scalars['Float']>;
  distribution_center_ids: Array<Maybe<Scalars['String']>>;
  depot_id: Scalars['String'];
};

export type GqlBatchStockAvailabilityArgs = {
  product_item_id: Scalars['String'];
  distribution_center_ids: Array<Maybe<Scalars['String']>>;
  depot_id: Scalars['String'];
};

export type GqlProcessingLossTypeGqlConnection = {
  __typename?: 'ProcessingLossTypeGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlProcessingLossTypeGqlEdge>>;
};

/** A Relay edge containing a `ProcessingLossTypeGQL` and its cursor. */
export type GqlProcessingLossTypeGqlEdge = {
  __typename?: 'ProcessingLossTypeGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlProcessingLossTypeGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlInventoryGql = {
  __typename?: 'InventoryGQL';
  storage_quantity: Maybe<Scalars['Float']>;
  storage_weight: Maybe<Scalars['Float']>;
  packaging_unit_quantity: Maybe<Scalars['Float']>;
  inventory_quantity: Maybe<Scalars['Float']>;
  verified_quantity: Maybe<Scalars['Float']>;
  distribution_center_id: Scalars['String'];
  stock_take_id: Maybe<Scalars['String']>;
  product_item_id: Scalars['String'];
  date: Maybe<Scalars['String']>;
};

export type GqlDistributionCenterGqlConnection = {
  __typename?: 'DistributionCenterGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlDistributionCenterGqlEdge>>;
};

/** A Relay edge containing a `DistributionCenterGQL` and its cursor. */
export type GqlDistributionCenterGqlEdge = {
  __typename?: 'DistributionCenterGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlDistributionCenterGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlStockTakeGqlConnection = {
  __typename?: 'StockTakeGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlStockTakeGqlEdge>>;
};

/** A Relay edge containing a `StockTakeGQL` and its cursor. */
export type GqlStockTakeGqlEdge = {
  __typename?: 'StockTakeGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlStockTakeGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlStockTakeLocationGqlConnection = {
  __typename?: 'StockTakeLocationGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlStockTakeLocationGqlEdge>>;
};

/** A Relay edge containing a `StockTakeLocationGQL` and its cursor. */
export type GqlStockTakeLocationGqlEdge = {
  __typename?: 'StockTakeLocationGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlStockTakeLocationGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlStockTakeLocationGql = {
  __typename?: 'StockTakeLocationGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  stock_take_location_id: Scalars['String'];
  date: Scalars['String'];
  product_item_id: Scalars['String'];
  expected_pallets: Scalars['Int'];
  expected_quantity: Scalars['Int'];
  actual_quantity: Scalars['Int'];
  storage_location_id: Scalars['String'];
  amended: Scalars['Boolean'];
  distribution_center_id: Maybe<Scalars['String']>;
  storage_location: Maybe<GqlStorageLocationGql>;
  product_item: Maybe<GqlProductItemV2Gql>;
};

export type GqlWarehouseTransferItemGqlConnection = {
  __typename?: 'WarehouseTransferItemGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlWarehouseTransferItemGqlEdge>>;
};

/** A Relay edge containing a `WarehouseTransferItemGQL` and its cursor. */
export type GqlWarehouseTransferItemGqlEdge = {
  __typename?: 'WarehouseTransferItemGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlWarehouseTransferItemGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlWarehouseTransferReasonGqlConnection = {
  __typename?: 'WarehouseTransferReasonGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlWarehouseTransferReasonGqlEdge>>;
};

/** A Relay edge containing a `WarehouseTransferReasonGQL` and its cursor. */
export type GqlWarehouseTransferReasonGqlEdge = {
  __typename?: 'WarehouseTransferReasonGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlWarehouseTransferReasonGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlWarehouseTransferGqlConnection = {
  __typename?: 'WarehouseTransferGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlWarehouseTransferGqlEdge>>;
};

/** A Relay edge containing a `WarehouseTransferGQL` and its cursor. */
export type GqlWarehouseTransferGqlEdge = {
  __typename?: 'WarehouseTransferGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlWarehouseTransferGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlAssetGqlConnection = {
  __typename?: 'AssetGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlAssetGqlEdge>>;
};

/** A Relay edge containing a `AssetGQL` and its cursor. */
export type GqlAssetGqlEdge = {
  __typename?: 'AssetGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlAssetGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlAssetPhotoGqlConnection = {
  __typename?: 'AssetPhotoGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlAssetPhotoGqlEdge>>;
};

/** A Relay edge containing a `AssetPhotoGQL` and its cursor. */
export type GqlAssetPhotoGqlEdge = {
  __typename?: 'AssetPhotoGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlAssetPhotoGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlDamagedAssetPhotoGqlConnection = {
  __typename?: 'DamagedAssetPhotoGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlDamagedAssetPhotoGqlEdge>>;
};

/** A Relay edge containing a `DamagedAssetPhotoGQL` and its cursor. */
export type GqlDamagedAssetPhotoGqlEdge = {
  __typename?: 'DamagedAssetPhotoGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlDamagedAssetPhotoGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlAssetTrackerGqlConnection = {
  __typename?: 'AssetTrackerGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlAssetTrackerGqlEdge>>;
};

/** A Relay edge containing a `AssetTrackerGQL` and its cursor. */
export type GqlAssetTrackerGqlEdge = {
  __typename?: 'AssetTrackerGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlAssetTrackerGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlAssetTrackerItemGqlConnection = {
  __typename?: 'AssetTrackerItemGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlAssetTrackerItemGqlEdge>>;
};

/** A Relay edge containing a `AssetTrackerItemGQL` and its cursor. */
export type GqlAssetTrackerItemGqlEdge = {
  __typename?: 'AssetTrackerItemGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlAssetTrackerItemGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlInventoryLocationGqlConnection = {
  __typename?: 'InventoryLocationGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlInventoryLocationGqlEdge>>;
};

/** A Relay edge containing a `InventoryLocationGQL` and its cursor. */
export type GqlInventoryLocationGqlEdge = {
  __typename?: 'InventoryLocationGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlInventoryLocationGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlAssetControlGqlConnection = {
  __typename?: 'AssetControlGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlAssetControlGqlEdge>>;
};

/** A Relay edge containing a `AssetControlGQL` and its cursor. */
export type GqlAssetControlGqlEdge = {
  __typename?: 'AssetControlGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlAssetControlGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlAssetControlGql = {
  __typename?: 'AssetControlGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  date_updated: Scalars['DateTime'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  asset_location_id: Scalars['String'];
  storage_location_id: Scalars['String'];
  storage: Maybe<GqlStorageLocationGql>;
};

export type GqlProductLocationGqlConnection = {
  __typename?: 'ProductLocationGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlProductLocationGqlEdge>>;
};

/** A Relay edge containing a `ProductLocationGQL` and its cursor. */
export type GqlProductLocationGqlEdge = {
  __typename?: 'ProductLocationGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlProductLocationGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlProductLocationGql = {
  __typename?: 'ProductLocationGQL';
  storage_location_id: Maybe<Scalars['String']>;
  storage_location_number: Maybe<Scalars['String']>;
  location_type_name: Maybe<Scalars['String']>;
  product_item_id: Maybe<Scalars['String']>;
  hu_count: Maybe<Scalars['Float']>;
  hui_count: Maybe<Scalars['Float']>;
  product_item: Maybe<GqlProductItemV2Gql>;
};

export type GqlProductExpiryGqlConnection = {
  __typename?: 'ProductExpiryGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlProductExpiryGqlEdge>>;
};

/** A Relay edge containing a `ProductExpiryGQL` and its cursor. */
export type GqlProductExpiryGqlEdge = {
  __typename?: 'ProductExpiryGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlProductExpiryGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlProductExpiryGql = {
  __typename?: 'ProductExpiryGQL';
  storage_location_id: Maybe<Scalars['String']>;
  storage_location_number: Maybe<Scalars['String']>;
  location_type_name: Maybe<Scalars['String']>;
  asset_number: Maybe<Scalars['String']>;
  stock_availability_status: Maybe<Scalars['String']>;
  product_item_id: Maybe<Scalars['String']>;
  stock_date: Maybe<Scalars['String']>;
  order_by_date: Maybe<Scalars['String']>;
  stock_age: Maybe<Scalars['String']>;
  expiry_duration: Maybe<Scalars['String']>;
  hui_count: Maybe<Scalars['Int']>;
  product_item: Maybe<GqlProductItemV2Gql>;
};

export type GqlProductTransferReasonGqlConnection = {
  __typename?: 'ProductTransferReasonGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlProductTransferReasonGqlEdge>>;
};

/** A Relay edge containing a `ProductTransferReasonGQL` and its cursor. */
export type GqlProductTransferReasonGqlEdge = {
  __typename?: 'ProductTransferReasonGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlProductTransferReasonGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlProductTransferGqlConnection = {
  __typename?: 'ProductTransferGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlProductTransferGqlEdge>>;
};

/** A Relay edge containing a `ProductTransferGQL` and its cursor. */
export type GqlProductTransferGqlEdge = {
  __typename?: 'ProductTransferGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlProductTransferGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlProductTransferHandlingUnitGqlConnection = {
  __typename?: 'ProductTransferHandlingUnitGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlProductTransferHandlingUnitGqlEdge>>;
};

/** A Relay edge containing a `ProductTransferHandlingUnitGQL` and its cursor. */
export type GqlProductTransferHandlingUnitGqlEdge = {
  __typename?: 'ProductTransferHandlingUnitGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlProductTransferHandlingUnitGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlProductTransferHandlingUnitItemGqlConnection = {
  __typename?: 'ProductTransferHandlingUnitItemGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlProductTransferHandlingUnitItemGqlEdge>>;
};

/** A Relay edge containing a `ProductTransferHandlingUnitItemGQL` and its cursor. */
export type GqlProductTransferHandlingUnitItemGqlEdge = {
  __typename?: 'ProductTransferHandlingUnitItemGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlProductTransferHandlingUnitItemGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export enum GqlCallbackType {
  Order = 'ORDER',
  Delivery = 'DELIVERY'
}

export type GqlCustomer = {
  __typename?: 'Customer';
  id: Scalars['ID'];
  leadCallbackID: Scalars['ID'];
  leadCallback: GqlLeadCallback;
  twigaCustomerID: Scalars['ID'];
  legalName: Scalars['String'];
  phoneNumber: Scalars['String'];
  documentType: GqlDocumentType;
  documentNo: Scalars['String'];
  optedIn: Scalars['Boolean'];
  deliveryCount: Scalars['Int'];
  customerStatus: GqlCustomerStatus;
  loans: Array<GqlLoan>;
  dateCreated: Scalars['Time'];
  dateUpdated: Scalars['Time'];
  deletedAt: Maybe<Scalars['Time']>;
  active: Maybe<Scalars['Boolean']>;
  createdByID: Scalars['String'];
  updatedByID: Scalars['String'];
  ClientStamp: Scalars['String'];
  meta: Maybe<Scalars['Map']>;
};

export type GqlCustomerConnection = {
  __typename?: 'CustomerConnection';
  edges: Array<Maybe<GqlCustomer>>;
  page_info: GqlPageInfo;
};

export type GqlCustomerConnectionFilters = {
  id?: Maybe<Scalars['ID']>;
  phoneNumber?: Maybe<Scalars['String']>;
  idNumber?: Maybe<Scalars['String']>;
  twigaCustomerId?: Maybe<Scalars['String']>;
};

export type GqlCustomerPartner = {
  __typename?: 'CustomerPartner';
  id: Scalars['ID'];
  customerID: Scalars['ID'];
  customer: GqlCustomer;
  partnerID: Scalars['ID'];
  partner: GqlPartner;
  loanLimit: Scalars['Int'];
  loanAvailable: Scalars['Int'];
  canTakeALoan: Scalars['Boolean'];
  rejectionReasonCode: Scalars['String'];
  dateCreated: Scalars['Time'];
  dateUpdated: Scalars['Time'];
  deletedAt: Maybe<Scalars['Time']>;
  active: Maybe<Scalars['Boolean']>;
  createdByID: Scalars['String'];
  updatedByID: Scalars['String'];
  ClientStamp: Scalars['String'];
  meta: Maybe<Scalars['Map']>;
};

export type GqlCustomerPartnerConnection = {
  __typename?: 'CustomerPartnerConnection';
  edges: Array<Maybe<GqlCustomerPartner>>;
  page_info: GqlPageInfo;
};

export type GqlCustomerPartnerConnectionFilters = {
  id?: Maybe<Scalars['ID']>;
  customerID?: Maybe<Scalars['ID']>;
  partnerID?: Maybe<Scalars['ID']>;
};

export enum GqlCustomerStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Unqualified = 'UNQUALIFIED'
}

export type GqlDepotPartner = {
  __typename?: 'DepotPartner';
  id: Scalars['ID'];
  partnerID: Maybe<Scalars['ID']>;
  partner: Maybe<GqlPartner>;
  depot: GqlDepotV2Gql;
  dateCreated: Scalars['Time'];
  dateUpdated: Scalars['Time'];
  deletedAt: Maybe<Scalars['Time']>;
  active: Maybe<Scalars['Boolean']>;
  createdByID: Scalars['String'];
  updatedByID: Scalars['String'];
  clientStamp: Scalars['String'];
  meta: Maybe<Scalars['Map']>;
};

export type GqlDepotPartnerConnection = {
  __typename?: 'DepotPartnerConnection';
  edges: Array<Maybe<GqlDepotPartner>>;
  page_info: GqlPageInfo;
};

export enum GqlDocumentType {
  NationalId = 'NATIONAL_ID',
  Passport = 'PASSPORT'
}

export enum GqlEarningType {
  Interest = 'INTEREST',
  Penalty = 'PENALTY',
  Waiver = 'WAIVER',
  Withdrawal = 'WITHDRAWAL'
}

export enum GqlInterestType {
  Fixed = 'FIXED',
  Percentage = 'PERCENTAGE'
}

export type GqlLeadCallback = {
  __typename?: 'LeadCallback';
  id: Scalars['ID'];
  twigaLeadCallbackID: Scalars['ID'];
  customerData: Scalars['String'];
  partnerID: Maybe<Scalars['String']>;
  completed: Maybe<Scalars['Boolean']>;
  attempts: Maybe<Scalars['Int']>;
  PartnerPayloadSent: Maybe<Scalars['String']>;
  PartnerPayloadReceived: Maybe<Scalars['String']>;
  dateCreated: Scalars['Time'];
  dateUpdated: Scalars['Time'];
  deletedAt: Maybe<Scalars['Time']>;
  active: Maybe<Scalars['Boolean']>;
  createdByID: Scalars['String'];
  updatedByID: Scalars['String'];
  ClientStamp: Scalars['String'];
  meta: Maybe<Scalars['Map']>;
};

export type GqlLeadCallbackConnection = {
  __typename?: 'LeadCallbackConnection';
  edges: Array<Maybe<GqlLeadCallback>>;
  page_info: GqlPageInfo;
};

export type GqlLoan = {
  __typename?: 'Loan';
  id: Scalars['ID'];
  loanNumber: Scalars['String'];
  loanedAmount: Scalars['Int'];
  status: GqlLoanStatus;
  loanRequestID: Scalars['ID'];
  loanRequest: GqlLoanRequest;
  loanOfferID: Scalars['ID'];
  loanOffer: GqlLoanOffer;
  loanProductID: Scalars['ID'];
  loanProduct: GqlLoanProduct;
  balance: Scalars['Int'];
  repaidAmount: Scalars['Int'];
  partnerID: Scalars['ID'];
  partner: GqlPartner;
  customerID: Scalars['ID'];
  customer: GqlCustomer;
  gracePeriodEndDate: Scalars['String'];
  loanMaturityDate: Scalars['String'];
  loanSuspensionDate: Scalars['String'];
  details: Scalars['String'];
  receiptNo: Scalars['String'];
  rejectionReasonCode: Scalars['String'];
  penaltyCommencementDate: Scalars['String'];
  completed: Maybe<Scalars['Boolean']>;
  attempts: Maybe<Scalars['Int']>;
  PartnerPayloadSent: Maybe<Scalars['String']>;
  PartnerPayloadReceived: Maybe<Scalars['String']>;
  repayments: Array<GqlLoanRepayment>;
  dateCreated: Scalars['Time'];
  dateUpdated: Scalars['Time'];
  deletedAt: Maybe<Scalars['Time']>;
  active: Maybe<Scalars['Boolean']>;
  createdByID: Scalars['String'];
  updatedByID: Scalars['String'];
  clientStamp: Scalars['String'];
  meta: Maybe<Scalars['Map']>;
};

export type GqlLoanConnection = {
  __typename?: 'LoanConnection';
  edges: Array<Maybe<GqlLoan>>;
  page_info: GqlPageInfo;
};

export type GqlLoanConnectionFilters = {
  id?: Maybe<Scalars['ID']>;
  loanNumber?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  idNumber?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};

export type GqlLoanOffer = {
  __typename?: 'LoanOffer';
  id: Scalars['ID'];
  customerID: Scalars['ID'];
  customer: GqlCustomer;
  offerAmount: Scalars['Int'];
  rejectionReasonCode: Scalars['String'];
  partnerID: Scalars['ID'];
  partner: GqlPartner;
  orderCallbackID: Scalars['ID'];
  orderCallback: GqlOrderCallback;
  loanTerms: Scalars['Map'];
  rejectionReason: GqlLoanRejectionReason;
  loanRejectionReasonID: Scalars['ID'];
  expiryDate: Scalars['Time'];
  receiptNo: Scalars['String'];
  dateCreated: Scalars['Time'];
  dateUpdated: Scalars['Time'];
  deletedAt: Maybe<Scalars['Time']>;
  active: Maybe<Scalars['Boolean']>;
  createdByID: Scalars['String'];
  updatedByID: Scalars['String'];
  clientStamp: Scalars['String'];
  meta: Maybe<Scalars['Map']>;
};

export type GqlLoanOfferConnection = {
  __typename?: 'LoanOfferConnection';
  edges: Array<Maybe<GqlLoanOffer>>;
  page_info: GqlPageInfo;
};

export type GqlLoanOfferFilters = {
  id?: Maybe<Scalars['ID']>;
  orderCallbackID?: Maybe<Scalars['String']>;
  receiptNo?: Maybe<Scalars['String']>;
};

export type GqlLoanProduct = {
  __typename?: 'LoanProduct';
  id: Scalars['ID'];
  partnerID: Scalars['ID'];
  partner: GqlPartner;
  name: Scalars['String'];
  interestRate: Scalars['Float'];
  interestType: GqlInterestType;
  interestGracePeriod: Scalars['Int'];
  penaltyGracePeriod: Scalars['Int'];
  penaltyApplicationPeriod: Scalars['Int'];
  repaymentPeriod: Scalars['Int'];
  penaltyType: GqlInterestType;
  penaltyRate: Scalars['Float'];
  interestAmount: Scalars['Int'];
  interestSchedule: Scalars['Int'];
  penaltyAmount: Scalars['Int'];
  minAmount: Scalars['Int'];
  maxAmount: Scalars['Int'];
  hasFacilitationFee: Scalars['Boolean'];
  facilitationFeeAmount: Scalars['Int'];
  facilitationFeeRate: Scalars['Float'];
  facilitationFeeType: GqlInterestType;
  facilitationFeeThreshold: Scalars['Int'];
  loanType: GqlLoanType;
  penaltyMethod: GqlPenaltyMethod;
  dateCreated: Scalars['Time'];
  dateUpdated: Scalars['Time'];
  deletedAt: Maybe<Scalars['Time']>;
  active: Maybe<Scalars['Boolean']>;
  createdByID: Scalars['String'];
  updatedByID: Scalars['String'];
  clientStamp: Scalars['String'];
  meta: Maybe<Scalars['Map']>;
};

export type GqlLoanProductConnection = {
  __typename?: 'LoanProductConnection';
  edges: Array<Maybe<GqlLoanProduct>>;
  page_info: GqlPageInfo;
};

export type GqlLoanProductConnectionFilters = {
  id?: Maybe<Scalars['ID']>;
  partnerID?: Maybe<Scalars['ID']>;
  loanLimit?: Maybe<Scalars['String']>;
};

export type GqlLoanRejectionReason = {
  __typename?: 'LoanRejectionReason';
  id: Scalars['ID'];
  code: Scalars['String'];
  description: Scalars['String'];
  dateCreated: Scalars['Time'];
  dateUpdated: Scalars['Time'];
  deletedAt: Maybe<Scalars['Time']>;
  active: Maybe<Scalars['Boolean']>;
  createdByID: Scalars['String'];
  updatedByID: Scalars['String'];
  clientStamp: Scalars['String'];
  meta: Maybe<Scalars['Map']>;
};

export type GqlLoanRejectionReasonFilters = {
  rejectionReasonCode?: Maybe<Scalars['String']>;
};

export type GqlLoanRepayment = {
  __typename?: 'LoanRepayment';
  id: Scalars['ID'];
  amount: Scalars['Int'];
  transactionRef: Scalars['String'];
  claimed: Scalars['Boolean'];
  payloadReceived: Scalars['String'];
  completed: Maybe<Scalars['Boolean']>;
  attempts: Maybe<Scalars['Int']>;
  isForwarded: Maybe<Scalars['Boolean']>;
  partnerPayloadSent: Maybe<Scalars['String']>;
  partnerPayloadReceived: Maybe<Scalars['String']>;
  partnerPaymentReference: Maybe<Scalars['String']>;
  paymentDate: Maybe<Scalars['String']>;
  amountReconciled: Maybe<Scalars['Int']>;
  loanID: Scalars['ID'];
  dateCreated: Scalars['Time'];
  dateUpdated: Scalars['Time'];
  deletedAt: Maybe<Scalars['Time']>;
  active: Maybe<Scalars['Boolean']>;
  createdByID: Scalars['String'];
  updatedByID: Scalars['String'];
  clientStamp: Scalars['String'];
  meta: Maybe<Scalars['Map']>;
};

export type GqlLoanRepaymentConnection = {
  __typename?: 'LoanRepaymentConnection';
  edges: Array<Maybe<GqlLoanRepayment>>;
  page_info: GqlPageInfo;
};

export type GqlLoanRepaymentConnectionFilters = {
  code?: Maybe<Scalars['String']>;
};

export type GqlLoanRequest = {
  __typename?: 'LoanRequest';
  id: Scalars['ID'];
  customerID: Scalars['ID'];
  customer: GqlCustomer;
  receiptNo: Scalars['String'];
  amount: Scalars['Int'];
  loanOfferID: Scalars['ID'];
  loanOffer: GqlLoanOffer;
  partnerID: Scalars['ID'];
  partner: GqlPartner;
  payloadReceived: Scalars['String'];
  dateCreated: Scalars['Time'];
  dateUpdated: Scalars['Time'];
  deletedAt: Maybe<Scalars['Time']>;
  active: Maybe<Scalars['Boolean']>;
  createdByID: Scalars['String'];
  updatedByID: Scalars['String'];
  clientStamp: Scalars['String'];
  meta: Maybe<Scalars['Map']>;
};

export type GqlLoanRequestConnection = {
  __typename?: 'LoanRequestConnection';
  edges: Array<Maybe<GqlLoanRequest>>;
  page_info: GqlPageInfo;
};

export enum GqlLoanStatus {
  ActiveUnpaid = 'ACTIVE_UNPAID',
  ActivePartiallyPaid = 'ACTIVE_PARTIALLY_PAID',
  FullyPaid = 'FULLY_PAID',
  Cancelled = 'CANCELLED',
  Defaulted = 'DEFAULTED',
  Suspended = 'SUSPENDED',
  Overdue = 'OVERDUE',
  Completed = 'COMPLETED'
}

export enum GqlLoanType {
  Single = 'SINGLE',
  MultiloanSingleDay = 'MULTILOAN_SINGLE_DAY',
  MultiloanInfinite = 'MULTILOAN_INFINITE'
}

export type GqlLoanUpdate = {
  __typename?: 'LoanUpdate';
  id: Scalars['ID'];
  updateType: GqlLoanUpdateType;
  transactionType: GqlTransactionType;
  amount: Scalars['Int'];
  loanBalance: Scalars['Int'];
  loanID: Scalars['ID'];
  loan: GqlLoan;
  transactionRef: Scalars['String'];
  details: Scalars['String'];
  dateCreated: Scalars['Time'];
  dateUpdated: Scalars['Time'];
  deletedAt: Maybe<Scalars['Time']>;
  active: Maybe<Scalars['Boolean']>;
  createdByID: Scalars['String'];
  updatedByID: Scalars['String'];
  clientStamp: Scalars['String'];
  meta: Maybe<Scalars['Map']>;
};

export type GqlLoanUpdateConnection = {
  __typename?: 'LoanUpdateConnection';
  edges: Array<Maybe<GqlLoanUpdate>>;
  page_info: GqlPageInfo;
};

export type GqlLoanUpdateConnectionFilters = {
  loanID?: Maybe<Scalars['ID']>;
};

export enum GqlLoanUpdateType {
  Interest = 'INTEREST',
  Penalty = 'PENALTY',
  Repayment = 'REPAYMENT',
  Waiver = 'WAIVER',
  Cancellation = 'CANCELLATION',
  Suspended = 'SUSPENDED'
}

export type GqlLoanWaiverReason = {
  __typename?: 'LoanWaiverReason';
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
  dateCreated: Scalars['Time'];
  dateUpdated: Scalars['Time'];
  deletedAt: Maybe<Scalars['Time']>;
  active: Maybe<Scalars['Boolean']>;
  createdByID: Scalars['String'];
  updatedByID: Scalars['String'];
  clientStamp: Scalars['String'];
  meta: Maybe<Scalars['Map']>;
};

export type GqlMediatedLimit = {
  __typename?: 'MediatedLimit';
  id: Scalars['ID'];
  customerID: Scalars['ID'];
  partnerID: Scalars['ID'];
  loanLimit: Scalars['Int'];
  dateCreated: Scalars['Time'];
  dateUpdated: Scalars['Time'];
  deletedAt: Maybe<Scalars['Time']>;
  active: Maybe<Scalars['Boolean']>;
  createdByID: Scalars['String'];
  updatedByID: Scalars['String'];
  ClientStamp: Scalars['String'];
  meta: Maybe<Scalars['Map']>;
};

export type GqlNewDepotPartner = {
  partnerID: Scalars['ID'];
  depotID: Scalars['ID'];
  clientStamp: Scalars['String'];
};

export type GqlNewLoanProduct = {
  partnerID: Scalars['ID'];
  name: Scalars['String'];
  interestRate: Scalars['Float'];
  interestType: GqlInterestType;
  interestGracePeriod: Scalars['Int'];
  penaltyGracePeriod: Scalars['Int'];
  repaymentPeriod: Scalars['Int'];
  penaltyType: GqlInterestType;
  penaltyRate: Scalars['Float'];
  interestAmount: Scalars['Int'];
  penaltyAmount: Scalars['Int'];
  minAmount: Scalars['Int'];
  maxAmount: Scalars['Int'];
  facilitationFee: Scalars['Int'];
  facilitationFeeType: GqlInterestType;
  facilitationFeeThreshold: Scalars['Int'];
  loanType: GqlLoanType;
  penaltyMethod: GqlPenaltyMethod;
  clientStamp: Scalars['String'];
};

export type GqlNewLoanRejectionReason = {
  code: Scalars['String'];
  description: Scalars['String'];
  clientStamp: Scalars['String'];
};

export type GqlNewLoanUpdate = {
  updateType: GqlLoanUpdateType;
  amount?: Maybe<Scalars['Int']>;
  loanID: Scalars['ID'];
  details?: Maybe<Scalars['String']>;
  clientStamp: Scalars['String'];
};

export type GqlNewLoanWaiverReason = {
  name: Scalars['String'];
  description: Scalars['String'];
  clientStamp: Scalars['String'];
};

export type GqlNewPartner = {
  name: Scalars['String'];
  creditScoringURL: Scalars['String'];
  creditScoringToken?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  clientStamp: Scalars['String'];
};

export type GqlNewPartnerEarning = {
  amount: Scalars['Int'];
  partnerID: Scalars['ID'];
  earningType: GqlEarningType;
  clientStamp: Scalars['String'];
};

export type GqlNewPartnerTopup = {
  amount: Scalars['Int'];
  partnerID: Scalars['ID'];
  details: Scalars['String'];
  clientStamp: Scalars['String'];
};

export type GqlNewPartnerWithdrawal = {
  amount: Scalars['Int'];
  partnerID: Scalars['ID'];
  details: Scalars['String'];
  clientStamp: Scalars['String'];
};

export type GqlOrderCallback = {
  __typename?: 'OrderCallback';
  id: Scalars['ID'];
  customerID: Scalars['ID'];
  receiptNo: Scalars['String'];
  amount: Scalars['Int'];
  referenceID: Scalars['String'];
  callbackType: GqlCallbackType;
  depotID: Scalars['ID'];
  payloadReceived: Scalars['String'];
  dateCreated: Scalars['Time'];
  dateUpdated: Scalars['Time'];
  deletedAt: Maybe<Scalars['Time']>;
  active: Maybe<Scalars['Boolean']>;
  createdByID: Scalars['String'];
  updatedByID: Scalars['String'];
  ClientStamp: Scalars['String'];
  meta: Maybe<Scalars['Map']>;
};

export type GqlOrderCallbackConnection = {
  __typename?: 'OrderCallbackConnection';
  edges: Array<Maybe<GqlOrderCallback>>;
  page_info: GqlPageInfo;
};

export type GqlPartner = {
  __typename?: 'Partner';
  id: Scalars['ID'];
  name: Scalars['String'];
  creditScoringURL: Scalars['String'];
  floatBalance: Scalars['Int'];
  earningsBalance: Scalars['Int'];
  creditScoringToken: Scalars['String'];
  details: Scalars['String'];
  loans: Array<GqlLoan>;
  loanProducts: Array<GqlLoanProduct>;
  loanConfirmationURL: Maybe<Scalars['String']>;
  loanRepaymentURL: Maybe<Scalars['String']>;
  isCharged: Maybe<Scalars['Boolean']>;
  subscribedDepots: Maybe<Scalars['String']>;
  blacklistedDepots: Maybe<Scalars['String']>;
  leadCallbackURL: Maybe<Scalars['String']>;
  dateCreated: Scalars['Time'];
  dateUpdated: Scalars['Time'];
  deletedAt: Maybe<Scalars['Time']>;
  active: Maybe<Scalars['Boolean']>;
  createdByID: Scalars['String'];
  updatedByID: Scalars['String'];
  clientStamp: Scalars['String'];
  meta: Maybe<Scalars['Map']>;
};

export type GqlPartnerConnection = {
  __typename?: 'PartnerConnection';
  edges: Array<Maybe<GqlPartner>>;
  page_info: GqlPageInfo;
};

export type GqlPartnerConnectionFilters = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type GqlPartnerEarning = {
  __typename?: 'PartnerEarning';
  id: Scalars['ID'];
  amount: Scalars['Int'];
  earningsBalance: Scalars['Int'];
  partnerID: Scalars['ID'];
  partner: GqlPartner;
  earningType: GqlEarningType;
  transactionType: GqlTransactionType;
  loanID: Scalars['ID'];
  loan: GqlLoan;
  dateCreated: Scalars['Time'];
  dateUpdated: Scalars['Time'];
  deletedAt: Maybe<Scalars['Time']>;
  active: Maybe<Scalars['Boolean']>;
  createdByID: Scalars['String'];
  updatedByID: Scalars['String'];
  clientStamp: Scalars['String'];
  meta: Maybe<Scalars['Map']>;
};

export type GqlPartnerEarningConnection = {
  __typename?: 'PartnerEarningConnection';
  edges: Array<Maybe<GqlPartnerEarning>>;
  page_info: GqlPageInfo;
};

export type GqlPartnerEarningConnectionFilters = {
  partnerID?: Maybe<Scalars['ID']>;
};

export type GqlPartnerTopup = {
  __typename?: 'PartnerTopup';
  id: Scalars['ID'];
  amount: Scalars['Int'];
  partnerID: Scalars['ID'];
  partner: GqlPartner;
  details: Scalars['String'];
  approved: Scalars['Boolean'];
  approvedByID: Scalars['String'];
  floatBalance: Scalars['Int'];
  updateType: GqlPartnerUpdateType;
  transactionType: GqlTransactionType;
  dateCreated: Scalars['Time'];
  dateUpdated: Scalars['Time'];
  deletedAt: Maybe<Scalars['Time']>;
  active: Maybe<Scalars['Boolean']>;
  createdByID: Scalars['String'];
  updatedByID: Scalars['String'];
  clientStamp: Scalars['String'];
  meta: Maybe<Scalars['Map']>;
};

export type GqlPartnerTopupConnection = {
  __typename?: 'PartnerTopupConnection';
  edges: Array<Maybe<GqlPartnerTopup>>;
  page_info: GqlPageInfo;
};

export type GqlPartnerTopupConnectionFilters = {
  partnerID?: Maybe<Scalars['ID']>;
};

export type GqlPartnerUpdate = {
  __typename?: 'PartnerUpdate';
  id: Scalars['ID'];
  amount: Scalars['Int'];
  partnerID: Scalars['ID'];
  partner: GqlPartner;
  loanID: Scalars['ID'];
  loan: GqlLoan;
  floatBalance: Scalars['Int'];
  updateType: GqlPartnerUpdateType;
  transactionType: GqlTransactionType;
  dateCreated: Scalars['Time'];
  dateUpdated: Scalars['Time'];
  deletedAt: Maybe<Scalars['Time']>;
  active: Maybe<Scalars['Boolean']>;
  createdByID: Scalars['String'];
  updatedByID: Scalars['String'];
  clientStamp: Scalars['String'];
  meta: Maybe<Scalars['Map']>;
};

export type GqlPartnerUpdateConnection = {
  __typename?: 'PartnerUpdateConnection';
  edges: Array<Maybe<GqlPartnerUpdate>>;
  page_info: GqlPageInfo;
};

export type GqlPartnerUpdateConnectionFilters = {
  partnerID?: Maybe<Scalars['ID']>;
};

export enum GqlPartnerUpdateType {
  Repayment = 'REPAYMENT',
  Loan = 'LOAN',
  Topup = 'TOPUP'
}

export enum GqlPenaltyMethod {
  OneOff = 'ONE_OFF',
  Recurring = 'RECURRING'
}

export type GqlSokowalletRequest = {
  __typename?: 'SokowalletRequest';
  id: Scalars['ID'];
  customerID: Scalars['ID'];
  customer: GqlCustomer;
  amount: Scalars['Int'];
  transactionRef: Scalars['String'];
  sent: Scalars['Boolean'];
  dateCreated: Scalars['Time'];
  dateUpdated: Scalars['Time'];
  deletedAt: Maybe<Scalars['Time']>;
  active: Maybe<Scalars['Boolean']>;
  createdByID: Scalars['String'];
  updatedByID: Scalars['String'];
  clientStamp: Scalars['String'];
  meta: Maybe<Scalars['Map']>;
};

export enum GqlTransactionType {
  Credit = 'CREDIT',
  Debit = 'DEBIT',
  None = 'NONE'
}

export type GqlUpdateCustomer = {
  id: Scalars['ID'];
  phoneNumber: Scalars['String'];
};

export type GqlUpdateCustomerPartner = {
  id: Scalars['ID'];
  limit: Scalars['Int'];
};

export type GqlUpdateDepotPartner = {
  ID: Scalars['ID'];
  partnerID: Scalars['ID'];
};

export type GqlUpdateLoanProduct = {
  ID: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  interestRate?: Maybe<Scalars['Float']>;
  interestType?: Maybe<GqlInterestType>;
  interestGracePeriod?: Maybe<Scalars['Int']>;
  penaltyGracePeriod?: Maybe<Scalars['Int']>;
  repaymentPeriod?: Maybe<Scalars['Int']>;
  penaltyType?: Maybe<GqlInterestType>;
  penaltyRate?: Maybe<Scalars['Float']>;
  interestAmount?: Maybe<Scalars['Int']>;
  penaltyAmount?: Maybe<Scalars['Int']>;
  minAmount?: Maybe<Scalars['Int']>;
  maxAmount?: Maybe<Scalars['Int']>;
  loanType?: Maybe<GqlLoanType>;
  active?: Maybe<Scalars['Boolean']>;
};

export type GqlUpdatePartner = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  creditScoringURL?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
};

export type GqlUpdatePartnerTopup = {
  id: Scalars['ID'];
  amount: Scalars['Int'];
  partnerID: Scalars['ID'];
  approved: Scalars['Boolean'];
  clientStamp: Scalars['String'];
};

export type GqlUpdatePartnerWithdrawal = {
  id: Scalars['ID'];
  amount: Scalars['Int'];
  partnerID: Scalars['ID'];
  approved: Scalars['Boolean'];
  clientStamp: Scalars['String'];
};

/** Pagination Connection for SokoLoanRepaymentGQL */
export type GqlSokoLoanRepaymentGqlConnection = {
  __typename?: 'SokoLoanRepaymentGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  edges: Array<Maybe<GqlSokoLoanRepaymentGqlEdge>>;
};

export type GqlSokoLoanRepaymentGqlEdge = {
  __typename?: 'SokoLoanRepaymentGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlSokoLoanRepaymentGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlSokoLoanRepaymentGql = {
  __typename?: 'SokoLoanRepaymentGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  payload_sent: Maybe<Scalars['JSONString']>;
  payload_received: Maybe<Scalars['JSONString']>;
  completed: Scalars['Boolean'];
  attempts: Scalars['Int'];
  sokoloan_repayment_id: Scalars['String'];
  loan_confirmation_id: Scalars['String'];
  partner_id: Scalars['String'];
  reference_number: Maybe<Scalars['String']>;
  loan_reference_id: Scalars['String'];
  amount: Scalars['Float'];
  payment_date: Scalars['String'];
  partner_payment_reference: Maybe<Scalars['String']>;
  forwarded: Scalars['Boolean'];
  payment_mode: Scalars['String'];
  loan_confirmation: Maybe<GqlLoanGql>;
};

export type GqlLoanGql = {
  __typename?: 'LoanGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  loan_confirmation_id: Scalars['String'];
  delivery_callback_id: Maybe<Scalars['String']>;
  delivery_id: Maybe<Scalars['String']>;
  delivery_amount: Maybe<Scalars['Float']>;
  loaned_amount: Maybe<Scalars['Float']>;
  loan_reference_id: Maybe<Scalars['String']>;
  loan_status: Maybe<Scalars['Boolean']>;
  due_amount: Maybe<Scalars['Float']>;
  due_date: Maybe<Scalars['DateTime']>;
  offer_date: Maybe<Scalars['DateTime']>;
  receipt_no: Maybe<Scalars['String']>;
  reported_balance: Maybe<Scalars['Float']>;
  loan_offer_id: Maybe<Scalars['String']>;
  rejection_reason_id: Maybe<Scalars['String']>;
  partner_id: Maybe<Scalars['String']>;
  charge: Scalars['Float'];
  delivery_callback: Maybe<GqlDeliveryCallbackGql>;
  partner: Maybe<GqlPartnerGql>;
  rejection_reason: Maybe<GqlLoanRejectionReasonGql>;
  repayments: Maybe<Array<Maybe<GqlLoanRepaymentGql>>>;
  loan_offer: Maybe<GqlLoanOfferGql>;
};

export type GqlDeliveryCallbackGql = {
  __typename?: 'DeliveryCallbackGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  payload_sent: Maybe<Scalars['JSONString']>;
  payload_received: Maybe<Scalars['JSONString']>;
  completed: Scalars['Boolean'];
  attempts: Scalars['Int'];
  delivery_callback_id: Scalars['String'];
  partner_id: Maybe<Scalars['String']>;
  customer_id: Maybe<Scalars['String']>;
  fintech_delivery_id: Maybe<Scalars['String']>;
  delivery_id: Maybe<Scalars['String']>;
  receipt_no: Maybe<Scalars['String']>;
  partner: Maybe<GqlPartnerGql>;
  delivery: Maybe<GqlFintechDeliveryGql>;
  loan_confirmation: Maybe<Array<Maybe<GqlLoanGql>>>;
};

/** Fintech Partner GQL Type */
export type GqlPartnerGql = {
  __typename?: 'PartnerGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  partner_id: Scalars['String'];
  name: Maybe<Scalars['String']>;
  auth_token: Maybe<Scalars['String']>;
  delivery_callback_url: Maybe<Scalars['String']>;
  lead_callback_url: Maybe<Scalars['String']>;
  loan_request_url: Maybe<Scalars['String']>;
  loan_query_url: Maybe<Scalars['String']>;
  member_query_url: Maybe<Scalars['String']>;
  repayment_callback_url: Maybe<Scalars['String']>;
  options: Maybe<Scalars['JSONString']>;
  product_name: Maybe<Scalars['String']>;
  blacklisted_routes: Maybe<Array<Maybe<Scalars['String']>>>;
  subscribed_routes: Maybe<Array<Maybe<Scalars['String']>>>;
  payment_info: Maybe<Scalars['JSONString']>;
  is_charged: Scalars['Boolean'];
  summary: Maybe<Scalars['String']>;
  delivery_callbacks: Maybe<Array<Maybe<GqlDeliveryCallbackGql>>>;
  customers: Maybe<Array<Maybe<GqlFintechCustomerGql>>>;
  loans: Maybe<Array<Maybe<GqlLoanGql>>>;
  can_receive_payment: Maybe<Scalars['Boolean']>;
};

export type GqlFintechCustomerGql = {
  __typename?: 'FintechCustomerGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  fintech_customer_id: Scalars['String'];
  customer_number: Maybe<Scalars['String']>;
  full_name: Maybe<Scalars['String']>;
  phone_number: Maybe<Scalars['String']>;
  legal_name: Maybe<Scalars['String']>;
  document_number: Maybe<Scalars['String']>;
  document_type: Maybe<Scalars['String']>;
  date_registered: Maybe<Scalars['String']>;
  opt_in: Maybe<Scalars['Boolean']>;
  lead_callback_id: Maybe<Scalars['String']>;
  partners: Maybe<Array<Maybe<GqlPartnerGql>>>;
  deliveries: Maybe<Array<Maybe<GqlFintechDeliveryGql>>>;
  total_deliveries: Maybe<Scalars['Int']>;
  total_loans: Maybe<Scalars['Int']>;
  total_delivery_amount: Maybe<Scalars['Float']>;
  total_loan_amount_received: Maybe<Scalars['Float']>;
};

export type GqlFintechDeliveryGql = {
  __typename?: 'FintechDeliveryGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  fintech_delivery_id: Scalars['String'];
  delivery_id: Maybe<Scalars['String']>;
  customer_id: Maybe<Scalars['String']>;
  stall_id: Maybe<Scalars['String']>;
  route_id: Maybe<Scalars['String']>;
  route_team_id: Maybe<Scalars['String']>;
  amount: Maybe<Scalars['Float']>;
  receipt_no: Maybe<Scalars['String']>;
  till_number: Maybe<Scalars['String']>;
  date: Maybe<Scalars['DateTime']>;
  delivery_data: Maybe<Scalars['JSONString']>;
  assigned_partner_id: Maybe<Scalars['String']>;
  order_delivery_id: Maybe<Scalars['String']>;
  meta: Maybe<Scalars['JSONString']>;
  customer: Maybe<GqlFintechCustomerGql>;
  assigned_partner: Maybe<GqlPartnerGql>;
  delivery_callbacks: Maybe<Array<Maybe<GqlDeliveryCallbackGql>>>;
  route_team_members: Maybe<Array<Maybe<GqlRouteTeamMemberGql>>>;
};

export type GqlRouteTeamMemberGql = {
  __typename?: 'RouteTeamMemberGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  route_team_member_id: Scalars['String'];
  fintech_delivery_id: Maybe<Scalars['String']>;
  route_team_id: Maybe<Scalars['String']>;
  phone_number: Maybe<Scalars['String']>;
  user_id: Maybe<Scalars['String']>;
  first_name: Maybe<Scalars['String']>;
  last_name: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  user_no: Maybe<Scalars['String']>;
  delivery: Maybe<GqlFintechDeliveryGql>;
};

export type GqlLoanRejectionReasonGql = {
  __typename?: 'LoanRejectionReasonGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  loan_rejection_reason_id: Scalars['String'];
  code: Scalars['String'];
  description: Scalars['String'];
  message: Maybe<Scalars['String']>;
};

export type GqlLoanRepaymentGql = {
  __typename?: 'LoanRepaymentGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  loan_repayment_id: Scalars['String'];
  loan_confirmation_id: Maybe<Scalars['String']>;
  loan_reference_id: Maybe<Scalars['String']>;
  loan_amount: Maybe<Scalars['Float']>;
  amount_paid: Maybe<Scalars['Float']>;
  loan_balance: Maybe<Scalars['Float']>;
  paid_datetime: Maybe<Scalars['DateTime']>;
  due_date: Maybe<Scalars['DateTime']>;
  receipt_no: Maybe<Scalars['String']>;
  partner_id: Maybe<Scalars['String']>;
  loan_confirmation: Maybe<GqlLoanGql>;
};

export type GqlLoanOfferGql = {
  __typename?: 'LoanOfferGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  loan_offer_id: Scalars['String'];
  partner_id: Scalars['String'];
  delivery_callback_id: Maybe<Scalars['String']>;
  delivery_id: Scalars['String'];
  offer_amount: Scalars['Float'];
  offer_status: Scalars['Boolean'];
  receipt_no: Maybe<Scalars['String']>;
  offer_expiry: Scalars['DateTime'];
  limit_amount: Maybe<Scalars['Float']>;
  offer_reference_id: Scalars['String'];
  offer_date: Scalars['DateTime'];
  loan_terms: Scalars['JSONString'];
  rejection_reason_id: Maybe<Scalars['String']>;
  rejection_reason: Maybe<GqlLoanRejectionReasonGql>;
  partner: Maybe<GqlPartnerGql>;
  loan_request: Maybe<GqlLoanRequestGql>;
};

export type GqlLoanRequestGql = {
  __typename?: 'LoanRequestGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  payload_sent: Maybe<Scalars['JSONString']>;
  payload_received: Maybe<Scalars['JSONString']>;
  completed: Scalars['Boolean'];
  attempts: Scalars['Int'];
  loan_request_id: Scalars['String'];
  customer_id: Scalars['String'];
  partner_id: Scalars['String'];
  delivery_id: Scalars['String'];
  loan_amount: Scalars['Float'];
  loan_offer_id: Maybe<Scalars['String']>;
  date_initiated: Scalars['DateTime'];
  loan_offer: Maybe<GqlLoanOfferGql>;
  partner: Maybe<GqlPartnerGql>;
  customer: Maybe<GqlFintechCustomerGql>;
};

export type GqlLeadCallbackGqlConnection = {
  __typename?: 'LeadCallbackGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  edges: Array<Maybe<GqlLeadCallbackGqlEdge>>;
};

export type GqlLeadCallbackGqlEdge = {
  __typename?: 'LeadCallbackGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlLeadCallbackGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlLeadCallbackGql = {
  __typename?: 'LeadCallbackGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  payload_sent: Maybe<Scalars['JSONString']>;
  payload_received: Maybe<Scalars['JSONString']>;
  completed: Scalars['Boolean'];
  attempts: Scalars['Int'];
  lead_callback_id: Scalars['String'];
  partner_id: Maybe<Scalars['String']>;
  customer_id: Scalars['String'];
  shop_id: Scalars['String'];
  customer_data: Scalars['JSONString'];
  partner: Maybe<GqlPartnerGql>;
};

/** Pagination Connection for LoanGQL */
export type GqlLoanGqlConnection = {
  __typename?: 'LoanGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  edges: Array<Maybe<GqlLoanGqlEdge>>;
};

export type GqlLoanGqlEdge = {
  __typename?: 'LoanGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlLoanGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** Pagination Connection for LoanOfferGQL */
export type GqlLoanOfferGqlConnection = {
  __typename?: 'LoanOfferGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  edges: Array<Maybe<GqlLoanOfferGqlEdge>>;
};

export type GqlLoanOfferGqlEdge = {
  __typename?: 'LoanOfferGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlLoanOfferGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** Pagination Connection for LoanRequestGQL */
export type GqlLoanRequestGqlConnection = {
  __typename?: 'LoanRequestGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  edges: Array<Maybe<GqlLoanRequestGqlEdge>>;
};

export type GqlLoanRequestGqlEdge = {
  __typename?: 'LoanRequestGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlLoanRequestGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlPartnerLoanQueryGql = {
  __typename?: 'PartnerLoanQueryGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  payload_sent: Maybe<Scalars['JSONString']>;
  payload_received: Maybe<Scalars['JSONString']>;
  completed: Scalars['Boolean'];
  attempts: Scalars['Int'];
  partner_loan_query_id: Scalars['String'];
  limit_amount: Scalars['Float'];
  limit_date: Scalars['DateTime'];
  loan_balance: Scalars['Float'];
  customer_id: Scalars['String'];
  partner_id: Scalars['String'];
  partner: Maybe<GqlPartnerGql>;
  customer: Maybe<GqlFintechCustomerGql>;
};

export type GqlPartnerMemberQueryGql = {
  __typename?: 'PartnerMemberQueryGQL';
  date_created: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  payload_sent: Maybe<Scalars['JSONString']>;
  payload_received: Maybe<Scalars['JSONString']>;
  completed: Scalars['Boolean'];
  attempts: Scalars['Int'];
  date_updated: Scalars['DateTime'];
  partner_member_query_id: Scalars['String'];
  opted_in: Scalars['Boolean'];
  current_loan_limit: Scalars['Float'];
  can_take_a_loan: Scalars['Boolean'];
  vendor_class: Scalars['JSONString'];
  rejection_reason_id: Maybe<Scalars['String']>;
  customer_id: Scalars['String'];
  partner_id: Scalars['String'];
  partner: Maybe<GqlPartnerGql>;
  customer: Maybe<GqlFintechCustomerGql>;
  rejection_reason: Maybe<GqlLoanRejectionReasonGql>;
};

/** Pagination Connection for FintechDeliveryGQL */
export type GqlFintechDeliveryGqlConnection = {
  __typename?: 'FintechDeliveryGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  edges: Array<Maybe<GqlFintechDeliveryGqlEdge>>;
};

export type GqlFintechDeliveryGqlEdge = {
  __typename?: 'FintechDeliveryGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlFintechDeliveryGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** Pagination Connection for FintechCustomerGQL */
export type GqlFintechCustomerGqlConnection = {
  __typename?: 'FintechCustomerGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  edges: Array<Maybe<GqlFintechCustomerGqlEdge>>;
};

export type GqlFintechCustomerGqlEdge = {
  __typename?: 'FintechCustomerGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlFintechCustomerGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** Pagination Connection for ProductItemBundleGQL */
export type GqlProductItemBundleGqlConnection = {
  __typename?: 'ProductItemBundleGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlProductItemBundleGqlEdge>>;
};

/** A Relay edge containing a `ProductItemBundleGQL` and its cursor. */
export type GqlProductItemBundleGqlEdge = {
  __typename?: 'ProductItemBundleGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlProductItemBundleGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlProductItemBundleGql = {
  __typename?: 'ProductItemBundleGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  product_item_bundle_id: Scalars['String'];
  bundle_name: Scalars['String'];
  bundle_description: Maybe<Scalars['String']>;
  image_path: Maybe<Scalars['String']>;
  image_url: Maybe<Scalars['String']>;
  product_item_ids: Array<Maybe<Scalars['String']>>;
  discounts_map: Scalars['JSONString'];
  quantities_map: Scalars['JSONString'];
  active: Scalars['Boolean'];
  promotion_type_id: Maybe<Scalars['String']>;
  product_items: Maybe<Array<Maybe<GqlProductItemV2Gql>>>;
  promotion_type: Maybe<GqlPromotionTypeGql>;
  initial_total_price: Maybe<Scalars['Float']>;
  discounted_total_price: Maybe<Scalars['Float']>;
  product_type: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum GqlProductTypeEnum {
  Fmcg = 'FMCG',
  Ffv = 'FFV',
  Bundle = 'BUNDLE'
}

/** Department GQL schema */
export type GqlDepartmentV2Gql = {
  __typename?: 'DepartmentV2GQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  department_id: Scalars['String'];
  department_name: Scalars['String'];
  slug: Scalars['String'];
  short_name: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  image: Maybe<Scalars['String']>;
  image_url: Maybe<Scalars['String']>;
  featured: Scalars['Boolean'];
  categories: Maybe<Array<Maybe<GqlCategoryV2Gql>>>;
};

/** CategoryRegion GQL schema */
export type GqlCategoryRegionV2Gql = {
  __typename?: 'CategoryRegionV2GQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  category_region_id: Scalars['String'];
  category_id: Scalars['String'];
  region_id: Scalars['String'];
  region: Maybe<GqlRegionV2Gql>;
};

/** ProductDomainActivation GQL Schema */
export type GqlProductDomainActivationV2Gql = {
  __typename?: 'ProductDomainActivationV2GQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  product_domain_activation_id: Scalars['String'];
  product_id: Scalars['String'];
  business_domain_id: Scalars['String'];
  business_domain: Maybe<GqlBusinessDomainV2Gql>;
};

/** Business Domain GQL Schema */
export type GqlBusinessDomainV2Gql = {
  __typename?: 'BusinessDomainV2GQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  business_domain_id: Scalars['String'];
  domain_name: Scalars['String'];
};

export type GqlProductTagV2Gql = {
  __typename?: 'ProductTagV2GQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  product_tag_id: Scalars['String'];
  product_tag: Scalars['String'];
  product_id: Maybe<Scalars['String']>;
  product: Maybe<GqlProductV2Gql>;
};

export type GqlFinanceProductTypeGql = {
  __typename?: 'FinanceProductTypeGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  id: Scalars['String'];
  name: Scalars['String'];
  standard_unit_costs: Maybe<Array<Maybe<GqlStandardUnitCostGql>>>;
};

export type GqlStandardUnitCostGql = {
  __typename?: 'StandardUnitCostGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  id: Scalars['String'];
  finance_product_type_id: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  start_date: Scalars['DateTime'];
  end_date: Maybe<Scalars['DateTime']>;
  finance_product_type: Maybe<GqlFinanceProductTypeGql>;
};

export type GqlProductItemSegmentV2Gql = {
  __typename?: 'ProductItemSegmentV2GQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  product_item_segment_id: Scalars['String'];
  segment_name: Scalars['String'];
  description: Maybe<Scalars['String']>;
  featured: Scalars['Boolean'];
  product_items: Maybe<Array<Maybe<GqlProductItemV2Gql>>>;
};

export type GqlPromotionTagGql = {
  __typename?: 'PromotionTagGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  country_id: Maybe<Scalars['String']>;
  promotion_tag_id: Scalars['String'];
  region_ids: Array<Maybe<Scalars['String']>>;
  product_item_id: Scalars['String'];
  start_date: Scalars['DateTime'];
  end_date: Scalars['DateTime'];
  promotion_type_id: Scalars['String'];
  product_item: Maybe<GqlProductItemV2Gql>;
  promotion_type: Maybe<GqlPromotionTypeGql>;
};

export type GqlPromotionTypeGql = {
  __typename?: 'PromotionTypeGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  promotion_type_id: Scalars['String'];
  title: Scalars['String'];
  background_color: Scalars['String'];
  entity: Scalars['String'];
};

export type GqlDynamicPriceGql = {
  __typename?: 'DynamicPriceGQL';
  dynamic_price: Maybe<Scalars['Float']>;
  base_price: Maybe<Scalars['Float']>;
  previous_price: Maybe<Scalars['Float']>;
  discount_tag: Maybe<Scalars['Boolean']>;
  discount_value_type: Maybe<Scalars['String']>;
  discount_value: Maybe<Scalars['Float']>;
  markdown_display: Maybe<Scalars['String']>;
  markdown: Maybe<Scalars['Float']>;
  currency: Maybe<Scalars['String']>;
  bundle_discount: Maybe<Scalars['Float']>;
};

export type GqlProductItemRegionV2Gql = {
  __typename?: 'ProductItemRegionV2GQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  product_item_region_id: Scalars['String'];
  product_item_id: Scalars['String'];
  region_id: Scalars['String'];
  business_domain_id: Scalars['String'];
  product_item: Maybe<GqlProductItemV2Gql>;
  business_domain: Maybe<GqlBusinessDomainV2Gql>;
  region: Maybe<GqlRegionV2Gql>;
};

export type GqlWhitelistedRoutesGql = {
  __typename?: 'WhitelistedRoutesGQL';
  route_id: Maybe<Scalars['String']>;
  route: Maybe<GqlRouteV2Gql>;
};

export type GqlBlacklistedRoutesGql = {
  __typename?: 'BlacklistedRoutesGQL';
  route_id: Maybe<Scalars['String']>;
  route: Maybe<GqlRouteV2Gql>;
};

export type GqlBasePriceGql = {
  __typename?: 'BasePriceGQL';
  base_price: Maybe<Scalars['Float']>;
  currency: Maybe<Scalars['String']>;
};

/** Pagination Connection for ProductGQL */
export type GqlProductItemV2GqlConnection = {
  __typename?: 'ProductItemV2GQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlProductItemV2GqlEdge>>;
};

/** A Relay edge containing a `ProductItemV2GQL` and its cursor. */
export type GqlProductItemV2GqlEdge = {
  __typename?: 'ProductItemV2GQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlProductItemV2Gql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlBundleAssociationV2Gql = {
  __typename?: 'BundleAssociationV2GQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  association_id: Scalars['String'];
  parent_product_item_id: Scalars['String'];
  child_product_item_id: Scalars['String'];
  expiry_date: Maybe<Scalars['DateTime']>;
  quantity: Scalars['Float'];
  discount: Scalars['Float'];
  parent_sku: Maybe<GqlProductItemV2Gql>;
  child_sku: Maybe<GqlProductItemV2Gql>;
};

export type GqlProductPromotionalBannerV2Gql = {
  __typename?: 'ProductPromotionalBannerV2GQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  banner_id: Scalars['String'];
  start_date: Scalars['DateTime'];
  end_date: Scalars['DateTime'];
  image: Maybe<Scalars['String']>;
  image_url: Maybe<Scalars['String']>;
  banner_name: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  destination_url: Scalars['String'];
  featured: Scalars['Boolean'];
};

export type GqlUomv2GqlConnection = {
  __typename?: 'UOMV2GQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlUomv2GqlEdge>>;
};

/** A Relay edge containing a `UOMV2GQL` and its cursor. */
export type GqlUomv2GqlEdge = {
  __typename?: 'UOMV2GQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlUomv2Gql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlProductItemUomv2GqlConnection = {
  __typename?: 'ProductItemUOMV2GQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlProductItemUomv2GqlEdge>>;
};

/** A Relay edge containing a `ProductItemUOMV2GQL` and its cursor. */
export type GqlProductItemUomv2GqlEdge = {
  __typename?: 'ProductItemUOMV2GQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlProductItemUomv2Gql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** Pagination Connection for ProductGQL */
export type GqlProductV2GqlConnection = {
  __typename?: 'ProductV2GQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlProductV2GqlEdge>>;
};

/** A Relay edge containing a `ProductV2GQL` and its cursor. */
export type GqlProductV2GqlEdge = {
  __typename?: 'ProductV2GQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlProductV2Gql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** Pagination connection for PackagingUnitV2GQL */
export type GqlPackagingUnitV2GqlConnection = {
  __typename?: 'PackagingUnitV2GQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlPackagingUnitV2GqlEdge>>;
};

/** A Relay edge containing a `PackagingUnitV2GQL` and its cursor. */
export type GqlPackagingUnitV2GqlEdge = {
  __typename?: 'PackagingUnitV2GQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlPackagingUnitV2Gql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlProductVarietyGqlConnection = {
  __typename?: 'ProductVarietyGQLConnection';
  page_info: Maybe<GqlPaginatorInfo>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GqlProductVarietyGqlEdge>>;
};

/** A Relay edge containing a `ProductVarietyGQL` and its cursor. */
export type GqlProductVarietyGqlEdge = {
  __typename?: 'ProductVarietyGQLEdge';
  /** The item at the end of the edge */
  node: Maybe<GqlProductVarietyGql>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GqlProductVarietyGql = {
  __typename?: 'ProductVarietyGQL';
  date_created: Scalars['DateTime'];
  date_updated: Scalars['DateTime'];
  created_by: Scalars['String'];
  updated_by: Scalars['String'];
  client_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
  client_timestamp: Maybe<Scalars['DateTime']>;
  client_info: Maybe<Scalars['JSONString']>;
  active: Scalars['Boolean'];
  deleted_at: Maybe<Scalars['DateTime']>;
  meta: Maybe<Scalars['JSONString']>;
  id: Scalars['String'];
  product_id: Scalars['String'];
  variety_name: Scalars['String'];
  variety_code: Scalars['String'];
};

export type GqlCreateProductItemBundle = {
  __typename?: 'CreateProductItemBundle';
  created: Maybe<Scalars['Boolean']>;
  product_item_bundle: Maybe<GqlProductItemBundleGql>;
};

export type GqlProductItemBundleInput = {
  created_by?: Maybe<Scalars['String']>;
  updated_by?: Maybe<Scalars['String']>;
  client_id?: Maybe<Scalars['String']>;
  client_stamp?: Maybe<Scalars['String']>;
  client_timestamp?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  bundle_name?: Maybe<Scalars['String']>;
  bundle_description?: Maybe<Scalars['String']>;
  image_path?: Maybe<Scalars['String']>;
  image_url?: Maybe<Scalars['String']>;
  product_item_ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  discounts_map?: Maybe<Scalars['GenericScalar']>;
  quantities_map?: Maybe<Scalars['GenericScalar']>;
  promotion_type_id?: Maybe<Scalars['String']>;
};

export type GqlUpdateProductItemBundle = {
  __typename?: 'UpdateProductItemBundle';
  product_item_bundle: Maybe<GqlProductItemBundleGql>;
};

export type GqlDeleteProductItemBundle = {
  __typename?: 'DeleteProductItemBundle';
  deleted: Maybe<Scalars['Boolean']>;
};

export type GqlUploadProductItemBundleImage = {
  __typename?: 'UploadProductItemBundleImage';
  product_item_bundle: Maybe<GqlProductItemBundleGql>;
  uploaded: Maybe<Scalars['Boolean']>;
};

export type GqlCreateProductPromotionalBanner = {
  __typename?: 'CreateProductPromotionalBanner';
  created: Maybe<Scalars['Boolean']>;
  product_promotional_banner: Maybe<GqlProductPromotionalBannerV2Gql>;
};

export type GqlProductPromotionalBannerInput = {
  created_by?: Maybe<Scalars['String']>;
  updated_by?: Maybe<Scalars['String']>;
  client_id?: Maybe<Scalars['String']>;
  client_stamp?: Maybe<Scalars['String']>;
  client_timestamp?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  start_date?: Maybe<Scalars['DateTime']>;
  end_date?: Maybe<Scalars['DateTime']>;
  banner_name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  destination_url?: Maybe<Scalars['String']>;
  featured?: Maybe<Scalars['Boolean']>;
};

export type GqlUploadProductPromotionalBannerImage = {
  __typename?: 'UploadProductPromotionalBannerImage';
  uploaded: Maybe<Scalars['Boolean']>;
  product_promotional_banner: Maybe<GqlProductPromotionalBannerV2Gql>;
};

export type GqlUpdateProductPromotionalBanner = {
  __typename?: 'UpdateProductPromotionalBanner';
  product_promotional_banner: Maybe<GqlProductPromotionalBannerV2Gql>;
};

export type GqlDeleteProductPromotionalBanner = {
  __typename?: 'DeleteProductPromotionalBanner';
  deleted: Maybe<Scalars['Boolean']>;
};

export type GqlCreatePromotionTag = {
  __typename?: 'CreatePromotionTag';
  created: Maybe<Scalars['Boolean']>;
  promotion_tag: Maybe<GqlPromotionTagGql>;
};

export type GqlPromotionTagInput = {
  created_by?: Maybe<Scalars['String']>;
  updated_by?: Maybe<Scalars['String']>;
  client_id?: Maybe<Scalars['String']>;
  client_stamp?: Maybe<Scalars['String']>;
  client_timestamp?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  region_ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  product_item_id?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['String']>;
  promotion_type_id?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['GenericScalar']>;
  country_id?: Maybe<Scalars['String']>;
};

export type GqlUpdatePromotionTag = {
  __typename?: 'UpdatePromotionTag';
  promotion_tag: Maybe<GqlPromotionTagGql>;
};

export type GqlDeletePromotionTag = {
  __typename?: 'DeletePromotionTag';
  deleted: Maybe<Scalars['Boolean']>;
};

export type GqlCreatePromotionType = {
  __typename?: 'CreatePromotionType';
  created: Maybe<Scalars['Boolean']>;
  promotion_type: Maybe<GqlPromotionTypeGql>;
};

export type GqlPromotionTypeInput = {
  created_by?: Maybe<Scalars['String']>;
  updated_by?: Maybe<Scalars['String']>;
  client_id?: Maybe<Scalars['String']>;
  client_stamp?: Maybe<Scalars['String']>;
  client_timestamp?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  promotion_type_id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  background_color?: Maybe<Scalars['String']>;
  entity?: Maybe<Scalars['String']>;
};

export type GqlUpdatePromotionType = {
  __typename?: 'UpdatePromotionType';
  promotion_type: Maybe<GqlPromotionTypeGql>;
};

export type GqlDeletePromotionType = {
  __typename?: 'DeletePromotionType';
  deleted: Maybe<Scalars['Boolean']>;
};

export type GqlCreateDepartment = {
  __typename?: 'CreateDepartment';
  created: Maybe<Scalars['Boolean']>;
  department: Maybe<GqlDepartmentV2Gql>;
};

export type GqlDepartmentInput = {
  created_by?: Maybe<Scalars['String']>;
  updated_by?: Maybe<Scalars['String']>;
  client_id?: Maybe<Scalars['String']>;
  client_stamp?: Maybe<Scalars['String']>;
  client_timestamp?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  department_name?: Maybe<Scalars['String']>;
  short_name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  image_url?: Maybe<Scalars['String']>;
};

export type GqlUpdateDepartment = {
  __typename?: 'UpdateDepartment';
  department: Maybe<GqlDepartmentV2Gql>;
};

export type GqlDeleteDepartment = {
  __typename?: 'DeleteDepartment';
  deleted: Maybe<Scalars['Boolean']>;
};

export type GqlCreateCategory = {
  __typename?: 'CreateCategory';
  created: Maybe<Scalars['Boolean']>;
  category: Maybe<GqlCategoryV2Gql>;
};

export type GqlCategoryInput = {
  created_by?: Maybe<Scalars['String']>;
  updated_by?: Maybe<Scalars['String']>;
  client_id?: Maybe<Scalars['String']>;
  client_stamp?: Maybe<Scalars['String']>;
  client_timestamp?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  category_name?: Maybe<Scalars['String']>;
  department_id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  image_url?: Maybe<Scalars['String']>;
  banner_image?: Maybe<Scalars['String']>;
  banner_image_url?: Maybe<Scalars['String']>;
  products?: Maybe<Array<Maybe<Scalars['String']>>>;
  parent_id?: Maybe<Scalars['String']>;
  ordering_window?: Maybe<Scalars['GenericScalar']>;
};

export type GqlUpdateCategory = {
  __typename?: 'UpdateCategory';
  category: Maybe<GqlCategoryV2Gql>;
};

export type GqlDeleteCategory = {
  __typename?: 'DeleteCategory';
  deleted: Maybe<Scalars['Boolean']>;
};

export type GqlCreateCategoryRegion = {
  __typename?: 'CreateCategoryRegion';
  created: Maybe<Scalars['Boolean']>;
  category_region: Maybe<GqlCategoryRegionV2Gql>;
};

export type GqlCategoryRegionInput = {
  created_by?: Maybe<Scalars['String']>;
  updated_by?: Maybe<Scalars['String']>;
  client_id?: Maybe<Scalars['String']>;
  client_stamp?: Maybe<Scalars['String']>;
  client_timestamp?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  category_id?: Maybe<Scalars['String']>;
  region_id?: Maybe<Scalars['String']>;
};

export type GqlUpdateCategoryRegion = {
  __typename?: 'UpdateCategoryRegion';
  category_region: Maybe<GqlCategoryRegionV2Gql>;
};

export type GqlDeleteCategoryRegion = {
  __typename?: 'DeleteCategoryRegion';
  deleted: Maybe<Scalars['Boolean']>;
};

export type GqlUploadCategoryImage = {
  __typename?: 'UploadCategoryImage';
  uploaded: Maybe<Scalars['Boolean']>;
  category: Maybe<GqlCategoryV2Gql>;
};

export type GqlUploadDepartmentImage = {
  __typename?: 'UploadDepartmentImage';
  uploaded: Maybe<Scalars['Boolean']>;
  department: Maybe<GqlDepartmentV2Gql>;
};

export type GqlCreateProductItem = {
  __typename?: 'CreateProductItem';
  created: Maybe<Scalars['Boolean']>;
  product_item: Maybe<GqlProductItemV2Gql>;
};

export type GqlProductItemInput = {
  created_by?: Maybe<Scalars['String']>;
  updated_by?: Maybe<Scalars['String']>;
  client_id?: Maybe<Scalars['String']>;
  client_stamp?: Maybe<Scalars['String']>;
  client_timestamp?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  product_item_name?: Maybe<Scalars['String']>;
  product_id?: Maybe<Scalars['String']>;
  staff_only?: Maybe<Scalars['Boolean']>;
  featured?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  supplier_item?: Maybe<Scalars['Boolean']>;
  product_item_segment_id?: Maybe<Scalars['String']>;
  uoms?: Maybe<Array<Maybe<GqlProductItemUomInput>>>;
  regions?: Maybe<Array<Maybe<GqlProductItemRegionInput>>>;
  packed_weight?: Maybe<Scalars['Float']>;
  packed_volume?: Maybe<Scalars['Float']>;
  image?: Maybe<Scalars['String']>;
  image_url?: Maybe<Scalars['String']>;
  whitelisted_routes?: Maybe<Array<Maybe<Scalars['String']>>>;
  blacklisted_routes?: Maybe<Array<Maybe<Scalars['String']>>>;
  promotion_tag?: Maybe<GqlPromotionTagInput>;
  upc_code?: Maybe<Scalars['String']>;
  wms_default_available?: Maybe<Scalars['Boolean']>;
  wms_pickface_putaway?: Maybe<Scalars['Boolean']>;
  wms_std_pallet_quantity?: Maybe<Scalars['Int']>;
  has_warranty?: Maybe<Scalars['Boolean']>;
  etims_class_code?: Maybe<Scalars['String']>;
  tax_type_code?: Maybe<Scalars['String']>;
  country_of_origin_code?: Maybe<Scalars['String']>;
  item_sequence?: Maybe<Scalars['Int']>;
  etims_packaging_unit?: Maybe<Scalars['String']>;
  etims_quantity_code?: Maybe<Scalars['String']>;
  synced_to_etims?: Maybe<Scalars['Boolean']>;
};

export type GqlProductItemUomInput = {
  created_by?: Maybe<Scalars['String']>;
  updated_by?: Maybe<Scalars['String']>;
  client_id?: Maybe<Scalars['String']>;
  client_stamp?: Maybe<Scalars['String']>;
  client_timestamp?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  product_item_id?: Maybe<Scalars['String']>;
  uom_id?: Maybe<Scalars['String']>;
  business_domain_id?: Maybe<Scalars['String']>;
  conversion_ratio?: Maybe<Scalars['Float']>;
};

export type GqlProductItemRegionInput = {
  created_by?: Maybe<Scalars['String']>;
  updated_by?: Maybe<Scalars['String']>;
  client_id?: Maybe<Scalars['String']>;
  client_stamp?: Maybe<Scalars['String']>;
  client_timestamp?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  region_id?: Maybe<Scalars['String']>;
  product_item_id?: Maybe<Scalars['String']>;
  business_domain_id?: Maybe<Scalars['String']>;
};

export type GqlUpdateProductItem = {
  __typename?: 'UpdateProductItem';
  product_item: Maybe<GqlProductItemV2Gql>;
};

export type GqlDeleteProductItem = {
  __typename?: 'DeleteProductItem';
  deleted: Maybe<Scalars['Boolean']>;
};

export type GqlUploadProductItemImage = {
  __typename?: 'UploadProductItemImage';
  product_item: Maybe<GqlProductItemV2Gql>;
  uploaded: Maybe<Scalars['Boolean']>;
};

export type GqlCreateProductItemRegion = {
  __typename?: 'CreateProductItemRegion';
  created: Maybe<Scalars['Boolean']>;
  product_item_region: Maybe<GqlProductItemRegionV2Gql>;
};

export type GqlUpdateProductItemRegion = {
  __typename?: 'UpdateProductItemRegion';
  product_item_region: Maybe<GqlProductItemRegionV2Gql>;
};

export type GqlDeleteProductItemRegion = {
  __typename?: 'DeleteProductItemRegion';
  deleted: Maybe<Scalars['Boolean']>;
};

export type GqlCreateProductItemSegment = {
  __typename?: 'CreateProductItemSegment';
  created: Maybe<Scalars['Boolean']>;
  product_item_segment: Maybe<GqlProductItemSegmentV2Gql>;
};

export type GqlProductItemSegmentInput = {
  created_by?: Maybe<Scalars['String']>;
  updated_by?: Maybe<Scalars['String']>;
  client_id?: Maybe<Scalars['String']>;
  client_stamp?: Maybe<Scalars['String']>;
  client_timestamp?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  segment_name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  featured?: Maybe<Scalars['Boolean']>;
};

export type GqlUpdateProductItemSegment = {
  __typename?: 'UpdateProductItemSegment';
  product_item_segment: Maybe<GqlProductItemSegmentV2Gql>;
};

export type GqlDeleteProductItemSegment = {
  __typename?: 'DeleteProductItemSegment';
  deleted: Maybe<Scalars['Boolean']>;
};

export type GqlCreateBundleAssociation = {
  __typename?: 'CreateBundleAssociation';
  created: Maybe<Scalars['Boolean']>;
  bundle_association: Maybe<Array<Maybe<GqlBundleAssociationV2Gql>>>;
};

export type GqlBundleAssociationInput = {
  created_by?: Maybe<Scalars['String']>;
  updated_by?: Maybe<Scalars['String']>;
  client_id?: Maybe<Scalars['String']>;
  client_stamp?: Maybe<Scalars['String']>;
  client_timestamp?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  parent_product_item_id?: Maybe<Scalars['String']>;
  child_product_item_id?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  expiry_date?: Maybe<Scalars['DateTime']>;
};

export type GqlDeleteBundleAssociation = {
  __typename?: 'DeleteBundleAssociation';
  deleted: Maybe<Scalars['Boolean']>;
};

export type GqlCreateUom = {
  __typename?: 'CreateUom';
  created: Maybe<Scalars['Boolean']>;
  uom: Maybe<GqlUomv2Gql>;
};

export type GqlUomInput = {
  created_by?: Maybe<Scalars['String']>;
  updated_by?: Maybe<Scalars['String']>;
  client_id?: Maybe<Scalars['String']>;
  client_stamp?: Maybe<Scalars['String']>;
  client_timestamp?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  abbreviation?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  etims_quantity_code?: Maybe<Scalars['String']>;
};

export type GqlUpdateUom = {
  __typename?: 'UpdateUom';
  uom: Maybe<GqlUomv2Gql>;
};

export type GqlDeleteUom = {
  __typename?: 'DeleteUom';
  deleted: Maybe<Scalars['Boolean']>;
};

export type GqlCreateProductItemUom = {
  __typename?: 'CreateProductItemUOM';
  created: Maybe<Scalars['Boolean']>;
  product_item_uom: Maybe<GqlProductItemUomv2Gql>;
};

export type GqlUpdateProductItemUom = {
  __typename?: 'UpdateProductItemUOM';
  product_item_uom: Maybe<GqlProductItemUomv2Gql>;
};

export type GqlDeleteProductItemUom = {
  __typename?: 'DeleteProductItemUom';
  deleted: Maybe<Scalars['Boolean']>;
};

export type GqlCreateBusinessDomain = {
  __typename?: 'CreateBusinessDomain';
  created: Maybe<Scalars['Boolean']>;
  business_domain: Maybe<GqlBusinessDomainV2Gql>;
};

export type GqlBusinessDomainInput = {
  created_by?: Maybe<Scalars['String']>;
  updated_by?: Maybe<Scalars['String']>;
  client_id?: Maybe<Scalars['String']>;
  client_stamp?: Maybe<Scalars['String']>;
  client_timestamp?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  domain_name?: Maybe<Scalars['String']>;
};

export type GqlUpdateBusinessDomain = {
  __typename?: 'UpdateBusinessDomain';
  business_domain: Maybe<GqlBusinessDomainV2Gql>;
};

export type GqlDeleteBusinessDomain = {
  __typename?: 'DeleteBusinessDomain';
  deleted: Maybe<Scalars['Boolean']>;
};

export type GqlCreateProductDomainActivation = {
  __typename?: 'CreateProductDomainActivation';
  created: Maybe<Scalars['Boolean']>;
  product_domain_activation: Maybe<GqlProductDomainActivationV2Gql>;
};

export type GqlProductDomainActivationInput = {
  created_by?: Maybe<Scalars['String']>;
  updated_by?: Maybe<Scalars['String']>;
  client_id?: Maybe<Scalars['String']>;
  client_stamp?: Maybe<Scalars['String']>;
  client_timestamp?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  product_id?: Maybe<Scalars['String']>;
  business_domain_id?: Maybe<Scalars['String']>;
};

export type GqlUpdateProductDomainActivation = {
  __typename?: 'UpdateProductDomainActivation';
  product_domain_activation: Maybe<GqlProductDomainActivationV2Gql>;
};

export type GqlDeleteProductDomainActivation = {
  __typename?: 'DeleteProductDomainActivation';
  deleted: Maybe<Scalars['Boolean']>;
};

export type GqlCreatePackagingUnit = {
  __typename?: 'CreatePackagingUnit';
  created: Maybe<Scalars['Boolean']>;
  packaging_unit: Maybe<GqlPackagingUnitV2Gql>;
};

export type GqlPackagingUnitInput = {
  created_by?: Maybe<Scalars['String']>;
  updated_by?: Maybe<Scalars['String']>;
  client_id?: Maybe<Scalars['String']>;
  client_stamp?: Maybe<Scalars['String']>;
  client_timestamp?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  returnable?: Maybe<Scalars['Boolean']>;
  weight?: Maybe<Scalars['Float']>;
  etims_packaging_code?: Maybe<Scalars['String']>;
};

export type GqlUpdatePackagingUnit = {
  __typename?: 'UpdatePackagingUnit';
  packaging_unit: Maybe<GqlPackagingUnitV2Gql>;
};

export type GqlDeletePackagingUnit = {
  __typename?: 'DeletePackagingUnit';
  deleted: Maybe<Scalars['Boolean']>;
};

export type GqlCreateProduct = {
  __typename?: 'CreateProduct';
  created: Maybe<Scalars['Boolean']>;
  product: Maybe<GqlProductV2Gql>;
};

export type GqlProductInput = {
  created_by?: Maybe<Scalars['String']>;
  updated_by?: Maybe<Scalars['String']>;
  client_id?: Maybe<Scalars['String']>;
  client_stamp?: Maybe<Scalars['String']>;
  client_timestamp?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  product_name?: Maybe<Scalars['String']>;
  product_code?: Maybe<Scalars['String']>;
  hs_code?: Maybe<Scalars['String']>;
  product_description?: Maybe<Scalars['String']>;
  product_tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  category_id?: Maybe<Scalars['String']>;
  vatable?: Maybe<Scalars['Boolean']>;
  featured?: Maybe<Scalars['Boolean']>;
  product_type?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  image_url?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  business_domains?: Maybe<Array<Maybe<Scalars['String']>>>;
  packaging_units?: Maybe<Array<Maybe<Scalars['String']>>>;
  loss_reasons?: Maybe<Array<Maybe<Scalars['String']>>>;
  finance_product_type_id?: Maybe<Scalars['String']>;
  wms_shelf_life_days?: Maybe<Scalars['Int']>;
  kra_code?: Maybe<Scalars['String']>;
};

export type GqlUpdateProduct = {
  __typename?: 'UpdateProduct';
  product: Maybe<GqlProductV2Gql>;
};

export type GqlDeleteProduct = {
  __typename?: 'DeleteProduct';
  deleted: Maybe<Scalars['Boolean']>;
};

export type GqlUploadProductImage = {
  __typename?: 'UploadProductImage';
  product: Maybe<GqlProductV2Gql>;
  uploaded: Maybe<Scalars['Boolean']>;
};

export type GqlCreateProductTag = {
  __typename?: 'CreateProductTag';
  created: Maybe<Scalars['Boolean']>;
  product_tag: Maybe<GqlProductTagV2Gql>;
};

export type GqlProductTagInput = {
  created_by?: Maybe<Scalars['String']>;
  updated_by?: Maybe<Scalars['String']>;
  client_id?: Maybe<Scalars['String']>;
  client_stamp?: Maybe<Scalars['String']>;
  client_timestamp?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  product_tag?: Maybe<Scalars['String']>;
  product_id?: Maybe<Scalars['String']>;
};

export type GqlUpdateProductTag = {
  __typename?: 'UpdateProductTag';
  product_tag: Maybe<GqlProductTagV2Gql>;
};

export type GqlDeleteProductTag = {
  __typename?: 'DeleteProductTag';
  deleted: Maybe<Scalars['Boolean']>;
};

export type GqlCreateFinanceProductType = {
  __typename?: 'CreateFinanceProductType';
  created: Maybe<Scalars['Boolean']>;
  finance_product_type: Maybe<GqlFinanceProductTypeGql>;
};

export type GqlFinanceProductTypeInput = {
  created_by?: Maybe<Scalars['String']>;
  updated_by?: Maybe<Scalars['String']>;
  client_id?: Maybe<Scalars['String']>;
  client_stamp?: Maybe<Scalars['String']>;
  client_timestamp?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type GqlUpdateFinanceProductType = {
  __typename?: 'UpdateFinanceProductType';
  finance_product_type: Maybe<GqlFinanceProductTypeGql>;
};

export type GqlCreateStandardUnitCost = {
  __typename?: 'CreateStandardUnitCost';
  created: Maybe<Scalars['Boolean']>;
  standard_unit_cost: Maybe<GqlStandardUnitCostGql>;
};

export type GqlStandardUnitCostInput = {
  created_by?: Maybe<Scalars['String']>;
  updated_by?: Maybe<Scalars['String']>;
  client_id?: Maybe<Scalars['String']>;
  client_stamp?: Maybe<Scalars['String']>;
  client_timestamp?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  finance_product_type_id?: Maybe<Scalars['UUID']>;
  amount?: Maybe<Scalars['Float']>;
  start_date?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['String']>;
};

export type GqlUpdateStandardUnitCost = {
  __typename?: 'UpdateStandardUnitCost';
  standard_unit_cost: Maybe<GqlStandardUnitCostGql>;
};

export type GqlCreateProductVariety = {
  __typename?: 'CreateProductVariety';
  created: Maybe<Scalars['Boolean']>;
  product_variety: Maybe<GqlProductVarietyGql>;
};

export type GqlProductVarietyInput = {
  created_by?: Maybe<Scalars['String']>;
  updated_by?: Maybe<Scalars['String']>;
  client_id?: Maybe<Scalars['String']>;
  client_stamp?: Maybe<Scalars['String']>;
  client_timestamp?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  product_id?: Maybe<Scalars['String']>;
  variety_name?: Maybe<Scalars['String']>;
  variety_code?: Maybe<Scalars['String']>;
};

export type GqlUpdateProductVariety = {
  __typename?: 'UpdateProductVariety';
  product_variety: Maybe<GqlProductVarietyGql>;
};

export type GqlDeleteProductVariety = {
  __typename?: 'DeleteProductVariety';
  deleted: Maybe<Scalars['Boolean']>;
};

export type GqlEndpoint = {
  __typename?: 'Endpoint';
  url: Scalars['String'];
  serviceName: Scalars['String'];
};

export type GqlAddEndpointResult = {
  __typename?: 'AddEndpointResult';
  endpoint: Maybe<GqlEndpoint>;
  success: Scalars['Boolean'];
};

export type GqlRemoveEndpointResult = {
  __typename?: 'RemoveEndpointResult';
  success: Scalars['Boolean'];
};

export type GqlReloadAllEndpointsResult = {
  __typename?: 'ReloadAllEndpointsResult';
  success: Scalars['Boolean'];
};

export type GqlPaginationFragment = (
  { __typename?: 'PaginatorInfo' }
  & Pick<GqlPaginatorInfo, 'current_page' | 'per_page' | 'has_next' | 'has_previous' | 'total_pages' | 'total_count'>
);

export type GqlGetProductBundlesQueryVariables = Exact<{
  per_page: Maybe<Scalars['Int']>;
  page: Maybe<Scalars['Int']>;
  search: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
}>;


export type GqlGetProductBundlesQuery = (
  { __typename?: 'Query' }
  & { paginated_product_item_bundles: Maybe<(
    { __typename?: 'ProductItemBundleGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'ProductItemBundleGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'ProductItemBundleGQL' }
        & Pick<GqlProductItemBundleGql, 'bundle_name' | 'image_url' | 'initial_total_price' | 'discounted_total_price' | 'date_created' | 'active' | 'product_item_bundle_id'>
        & { product_items: Maybe<Array<Maybe<(
          { __typename?: 'ProductItemV2GQL' }
          & Pick<GqlProductItemV2Gql, 'product_item_name' | 'base_price'>
          & { product: Maybe<(
            { __typename?: 'ProductV2GQL' }
            & Pick<GqlProductV2Gql, 'product_name'>
          )>, dynamic_price: Maybe<(
            { __typename?: 'DynamicPriceGQL' }
            & Pick<GqlDynamicPriceGql, 'dynamic_price' | 'discount_value'>
          )> }
        )>>> }
      )> }
    )>> }
  )> }
);

export type GqlAddProductItemBundleMutationVariables = Exact<{
  data: GqlProductItemBundleInput;
}>;


export type GqlAddProductItemBundleMutation = (
  { __typename?: 'Mutation' }
  & { add_product_item_bundle: Maybe<(
    { __typename?: 'CreateProductItemBundle' }
    & Pick<GqlCreateProductItemBundle, 'created'>
    & { product_item_bundle: Maybe<(
      { __typename?: 'ProductItemBundleGQL' }
      & Pick<GqlProductItemBundleGql, 'product_item_bundle_id' | 'bundle_name' | 'discounts_map' | 'product_item_ids'>
      & { product_items: Maybe<Array<Maybe<(
        { __typename?: 'ProductItemV2GQL' }
        & Pick<GqlProductItemV2Gql, 'product_item_name'>
        & { product: Maybe<(
          { __typename?: 'ProductV2GQL' }
          & Pick<GqlProductV2Gql, 'product_name'>
        )> }
      )>>> }
    )> }
  )> }
);

export type GqlAddProductItemBundleImageMutationVariables = Exact<{
  image: Scalars['Upload'];
  product_item_bundle_id: Scalars['String'];
}>;


export type GqlAddProductItemBundleImageMutation = (
  { __typename?: 'Mutation' }
  & { add_product_item_bundle_image: Maybe<(
    { __typename?: 'UploadProductItemBundleImage' }
    & Pick<GqlUploadProductItemBundleImage, 'uploaded'>
    & { product_item_bundle: Maybe<(
      { __typename?: 'ProductItemBundleGQL' }
      & Pick<GqlProductItemBundleGql, 'image_url'>
    )> }
  )> }
);

export type GqlUpdateProductItemBundleMutationVariables = Exact<{
  product_item_bundle_id: Scalars['String'];
  data: GqlProductItemBundleInput;
}>;


export type GqlUpdateProductItemBundleMutation = (
  { __typename?: 'Mutation' }
  & { update_product_item_bundle: Maybe<(
    { __typename?: 'UpdateProductItemBundle' }
    & { product_item_bundle: Maybe<(
      { __typename?: 'ProductItemBundleGQL' }
      & Pick<GqlProductItemBundleGql, 'product_item_bundle_id' | 'active'>
    )> }
  )> }
);

export type GqlCountriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlCountriesQuery = (
  { __typename?: 'Query' }
  & { countries: Maybe<Array<Maybe<(
    { __typename?: 'CountryGQL' }
    & GqlCountryFragment
  )>>> }
);

export type GqlCountryFragment = (
  { __typename?: 'CountryGQL' }
  & Pick<GqlCountryGql, 'country_id' | 'iso_alpha2_name' | 'iso_alpha2_code' | 'default_currency' | 'country_calling_code'>
);

export type GqlDepotsQueryVariables = Exact<{
  filters: Maybe<Scalars['GenericScalar']>;
}>;


export type GqlDepotsQuery = (
  { __typename?: 'Query' }
  & { depots_v2: Maybe<(
    { __typename?: 'DepotV2GQLConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'DepotV2GQLEdge' }
      & { node: Maybe<(
        { __typename?: 'DepotV2GQL' }
        & Pick<GqlDepotV2Gql, 'active' | 'depot_id' | 'depot_name'>
        & { routes: Maybe<Array<Maybe<(
          { __typename?: 'RouteV2GQL' }
          & Pick<GqlRouteV2Gql, 'active' | 'route_id' | 'route_name'>
        )>>> }
      )> }
    )>> }
  )> }
);

export type GqlRoutesQueryVariables = Exact<{
  filters: Maybe<Scalars['GenericScalar']>;
  search: Maybe<Scalars['String']>;
  per_page: Maybe<Scalars['Int']>;
  page: Maybe<Scalars['Int']>;
}>;


export type GqlRoutesQuery = (
  { __typename?: 'Query' }
  & { routes_v2: Maybe<(
    { __typename?: 'RouteV2GQLConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'RouteV2GQLEdge' }
      & { node: Maybe<(
        { __typename?: 'RouteV2GQL' }
        & Pick<GqlRouteV2Gql, 'date_created' | 'depot_id' | 'route_id' | 'route_name'>
        & { depot: Maybe<(
          { __typename?: 'DepotV2GQL' }
          & Pick<GqlDepotV2Gql, 'depot_name' | 'depot_id' | 'area_id'>
          & { area: Maybe<(
            { __typename?: 'AreaV2GQL' }
            & Pick<GqlAreaV2Gql, 'area_name' | 'region_id'>
            & { region: Maybe<(
              { __typename?: 'RegionV2GQL' }
              & Pick<GqlRegionV2Gql, 'region_name' | 'region_id'>
            )> }
          )> }
        )> }
      )> }
    )>> }
  )> }
);

export type GqlRegionsQueryVariables = Exact<{
  filters: Maybe<Scalars['GenericScalar']>;
}>;


export type GqlRegionsQuery = (
  { __typename?: 'Query' }
  & { regions_v2: Maybe<(
    { __typename?: 'RegionV2GQLConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'RegionV2GQLEdge' }
      & { node: Maybe<(
        { __typename?: 'RegionV2GQL' }
        & Pick<GqlRegionV2Gql, 'region_id' | 'region_name' | 'country_id'>
        & { country: Maybe<(
          { __typename?: 'CountryGQL' }
          & Pick<GqlCountryGql, 'default_currency'>
        )>, areas: Maybe<Array<Maybe<(
          { __typename?: 'AreaV2GQL' }
          & Pick<GqlAreaV2Gql, 'area_id' | 'area_name'>
          & { depots: Maybe<Array<Maybe<(
            { __typename?: 'DepotV2GQL' }
            & Pick<GqlDepotV2Gql, 'depot_id' | 'depot_name'>
            & { routes: Maybe<Array<Maybe<(
              { __typename?: 'RouteV2GQL' }
              & Pick<GqlRouteV2Gql, 'route_id' | 'route_name'>
            )>>> }
          )>>> }
        )>>> }
      )> }
    )>> }
  )> }
);

export type GqlFetchDistributionCentersQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlFetchDistributionCentersQuery = (
  { __typename?: 'Query' }
  & { distribution_centers: Maybe<Array<Maybe<(
    { __typename?: 'DistributionCenterGQL' }
    & Pick<GqlDistributionCenterGql, 'distribution_center_name' | 'distribution_center_id' | 'distribution_center_type'>
  )>>> }
);

export type GqlFetchDynamicPricingQueryVariables = Exact<{
  search: Maybe<Scalars['String']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
}>;


export type GqlFetchDynamicPricingQuery = (
  { __typename?: 'Query' }
  & { dynamic_pricing: Maybe<(
    { __typename?: 'DynamicPricingGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'DynamicPricingGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'DynamicPricingGQL' }
        & Pick<GqlDynamicPricingGql, 'dynamic_pricing_id' | 'pricing_name' | 'description' | 'formula' | 'active' | 'start_time' | 'end_time'>
      )> }
    )>> }
  )> }
);

export type GqlMcommerceFaqsQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlMcommerceFaqsQuery = (
  { __typename?: 'Query' }
  & { mcommerce_faqs: Maybe<(
    { __typename?: 'McommerceFAQGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'McommerceFAQGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'McommerceFAQGQL' }
        & Pick<GqlMcommerceFaqgql, 'client_stamp' | 'client_id' | 'answer' | 'question' | 'date_created' | 'created_by' | 'faq_id' | 'faq_section_id'>
        & { faq_section: Maybe<(
          { __typename?: 'McommerceFAQSectionGQL' }
          & Pick<GqlMcommerceFaqSectionGql, 'name' | 'faq_section_id'>
        )> }
      )> }
    )>> }
  )>, mcommerce_faq_sections: Maybe<Array<Maybe<(
    { __typename?: 'McommerceFAQSectionGQL' }
    & Pick<GqlMcommerceFaqSectionGql, 'name' | 'date_created' | 'active' | 'icon_url' | 'faq_section_id' | 'client_stamp'>
    & { faqs: Maybe<Array<Maybe<(
      { __typename?: 'McommerceFAQGQL' }
      & Pick<GqlMcommerceFaqgql, 'client_stamp' | 'client_id' | 'answer' | 'active' | 'question' | 'date_created' | 'created_by' | 'faq_id' | 'faq_section_id'>
      & { faq_section: Maybe<(
        { __typename?: 'McommerceFAQSectionGQL' }
        & Pick<GqlMcommerceFaqSectionGql, 'name' | 'faq_section_id'>
      )> }
    )>>> }
  )>>> }
);

export type GqlMcommerceFaqSectionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlMcommerceFaqSectionsQuery = (
  { __typename?: 'Query' }
  & { mcommerce_faq_sections: Maybe<Array<Maybe<(
    { __typename?: 'McommerceFAQSectionGQL' }
    & Pick<GqlMcommerceFaqSectionGql, 'date_created' | 'created_by' | 'client_stamp' | 'faq_section_id' | 'name'>
  )>>> }
);

export type GqlOrderRestrictionsQueryVariables = Exact<{
  cluster_id: Scalars['String'];
  depot_id: Scalars['String'];
  route_id: Scalars['String'];
  customer_id: Scalars['String'];
  shop_id: Scalars['String'];
  shop_type_id: Scalars['String'];
}>;


export type GqlOrderRestrictionsQuery = (
  { __typename?: 'Query' }
  & { order_restrictions: Maybe<Array<Maybe<(
    { __typename?: 'OrderRestrictionGQL' }
    & Pick<GqlOrderRestrictionGql, 'order_restriction_id' | 'product_id' | 'product_item_id' | 'active' | 'max_order_quantity' | 'min_order_quantity' | 'max_orders' | 'start_date' | 'end_date'>
    & { product_name: Maybe<(
      { __typename?: 'ProductV2GQL' }
      & Pick<GqlProductV2Gql, 'product_id' | 'product_name'>
    )>, product_item_name: Maybe<(
      { __typename?: 'ProductItemV2GQL' }
      & Pick<GqlProductItemV2Gql, 'product_item_id' | 'product_item_name'>
    )> }
  )>>> }
);

export type GqlPermissionsV2QueryVariables = Exact<{
  search: Maybe<Scalars['String']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
}>;


export type GqlPermissionsV2Query = (
  { __typename?: 'Query' }
  & { permissions_v2: Maybe<(
    { __typename?: 'PermissionV2GQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'PermissionV2GQLEdge' }
      & { node: Maybe<(
        { __typename?: 'PermissionV2GQL' }
        & Pick<GqlPermissionV2Gql, 'permission_name' | 'permission_id' | 'active'>
        & { roles: Maybe<Array<Maybe<(
          { __typename?: 'RoleV2GQL' }
          & Pick<GqlRoleV2Gql, 'role_id' | 'role_name'>
        )>>> }
      )> }
    )>> }
  )> }
);

export type GqlProductFragment = (
  { __typename?: 'ProductItemV2GQL' }
  & Pick<GqlProductItemV2Gql, 'active' | 'product_item_name' | 'product_item_id' | 'base_price'>
  & { dynamic_price: Maybe<(
    { __typename?: 'DynamicPriceGQL' }
    & Pick<GqlDynamicPriceGql, 'base_price' | 'dynamic_price'>
  )>, units_of_measure: Maybe<Array<Maybe<(
    { __typename?: 'ProductItemUOMV2GQL' }
    & Pick<GqlProductItemUomv2Gql, 'active' | 'conversion_ratio'>
    & { business_domain: Maybe<(
      { __typename?: 'BusinessDomainV2GQL' }
      & Pick<GqlBusinessDomainV2Gql, 'domain_name'>
    )>, uom: Maybe<(
      { __typename?: 'UOMV2GQL' }
      & Pick<GqlUomv2Gql, 'name' | 'uom_id'>
    )> }
  )>>>, product: Maybe<(
    { __typename?: 'ProductV2GQL' }
    & Pick<GqlProductV2Gql, 'active' | 'product_name' | 'product_id'>
    & { packaging_units: Maybe<Array<Maybe<(
      { __typename?: 'PackagingUnitV2GQL' }
      & Pick<GqlPackagingUnitV2Gql, 'active' | 'name' | 'weight' | 'packaging_unit_id'>
    )>>>, domain_activations: Maybe<Array<Maybe<(
      { __typename?: 'ProductDomainActivationV2GQL' }
      & { business_domain: Maybe<(
        { __typename?: 'BusinessDomainV2GQL' }
        & Pick<GqlBusinessDomainV2Gql, 'domain_name' | 'business_domain_id'>
      )> }
    )>>> }
  )> }
);

export type GqlProductsV2QueryVariables = Exact<{
  search: Maybe<Scalars['String']>;
  productFilters: Maybe<Scalars['GenericScalar']>;
  per_page: Maybe<Scalars['Int']>;
}>;


export type GqlProductsV2Query = (
  { __typename?: 'Query' }
  & { paginated_products_v2: Maybe<(
    { __typename?: 'ProductV2GQLConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'ProductV2GQLEdge' }
      & { node: Maybe<(
        { __typename?: 'ProductV2GQL' }
        & Pick<GqlProductV2Gql, 'active' | 'product_name' | 'product_id' | 'product_code' | 'product_type'>
        & { product_items: Maybe<Array<Maybe<(
          { __typename?: 'ProductItemV2GQL' }
          & Pick<GqlProductItemV2Gql, 'product_item_id' | 'product_item_name' | 'base_price'>
          & { units_of_measure: Maybe<Array<Maybe<(
            { __typename?: 'ProductItemUOMV2GQL' }
            & Pick<GqlProductItemUomv2Gql, 'business_domain_id' | 'uom_id'>
            & { business_domain: Maybe<(
              { __typename?: 'BusinessDomainV2GQL' }
              & Pick<GqlBusinessDomainV2Gql, 'domain_name'>
            )>, uom: Maybe<(
              { __typename?: 'UOMV2GQL' }
              & Pick<GqlUomv2Gql, 'name' | 'uom_id'>
            )> }
          )>>>, regions: Maybe<Array<Maybe<(
            { __typename?: 'ProductItemRegionV2GQL' }
            & Pick<GqlProductItemRegionV2Gql, 'region_id'>
            & { region: Maybe<(
              { __typename?: 'RegionV2GQL' }
              & Pick<GqlRegionV2Gql, 'region_name' | 'region_id'>
              & { country: Maybe<(
                { __typename?: 'CountryGQL' }
                & GqlCountryFragment
              )> }
            )> }
          )>>> }
        )>>>, packaging_units: Maybe<Array<Maybe<(
          { __typename?: 'PackagingUnitV2GQL' }
          & Pick<GqlPackagingUnitV2Gql, 'active' | 'name' | 'weight' | 'packaging_unit_id'>
        )>>>, domain_activations: Maybe<Array<Maybe<(
          { __typename?: 'ProductDomainActivationV2GQL' }
          & { business_domain: Maybe<(
            { __typename?: 'BusinessDomainV2GQL' }
            & Pick<GqlBusinessDomainV2Gql, 'domain_name' | 'business_domain_id'>
          )> }
        )>>> }
      )> }
    )>> }
  )> }
);

export type GqlProductItemsV2QueryVariables = Exact<{
  search: Maybe<Scalars['String']>;
  productItemFilters: Maybe<Scalars['GenericScalar']>;
  per_page: Maybe<Scalars['Int']>;
}>;


export type GqlProductItemsV2Query = (
  { __typename?: 'Query' }
  & { paginated_product_items_v2: Maybe<(
    { __typename?: 'ProductItemV2GQLConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'ProductItemV2GQLEdge' }
      & { node: Maybe<(
        { __typename?: 'ProductItemV2GQL' }
        & Pick<GqlProductItemV2Gql, 'etims_quantity_code'>
        & GqlProductFragment
      )> }
    )>> }
  )> }
);

export type GqlProductTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlProductTypesQuery = (
  { __typename?: 'Query' }
  & Pick<GqlQuery, 'product_types_v2'>
);

export type GqlProductVarietiesQueryVariables = Exact<{
  search: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  per_page: Maybe<Scalars['Int']>;
}>;


export type GqlProductVarietiesQuery = (
  { __typename?: 'Query' }
  & { product_varieties: Maybe<(
    { __typename?: 'ProductVarietyGQLConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'ProductVarietyGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'ProductVarietyGQL' }
        & Pick<GqlProductVarietyGql, 'variety_name' | 'variety_code'>
      )> }
    )>> }
  )> }
);

export type GqlRolesV2QueryVariables = Exact<{
  search: Maybe<Scalars['String']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filters: Maybe<Scalars['GenericScalar']>;
}>;


export type GqlRolesV2Query = (
  { __typename?: 'Query' }
  & { roles_v2: Maybe<(
    { __typename?: 'RoleV2GQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'RoleV2GQLEdge' }
      & { node: Maybe<(
        { __typename?: 'RoleV2GQL' }
        & Pick<GqlRoleV2Gql, 'role_id' | 'role_name' | 'active'>
        & { permissions: Maybe<Array<Maybe<(
          { __typename?: 'PermissionV2GQL' }
          & Pick<GqlPermissionV2Gql, 'permission_id' | 'permission_name'>
        )>>> }
      )> }
    )>> }
  )> }
);

export type GqlUserV2ByIdQueryVariables = Exact<{
  user_id: Maybe<Scalars['String']>;
}>;


export type GqlUserV2ByIdQuery = (
  { __typename?: 'Query' }
  & { user_v2_by_id: Maybe<(
    { __typename?: 'UserV2GQL' }
    & Pick<GqlUserV2Gql, 'date_created' | 'first_name' | 'last_name' | 'region_id' | 'default_country_id' | 'country_ids' | 'phone_number' | 'email' | 'active' | 'user_no'>
    & { region: Maybe<(
      { __typename?: 'RegionV2GQL' }
      & Pick<GqlRegionV2Gql, 'region_id' | 'region_name'>
    )>, roles: Maybe<Array<Maybe<(
      { __typename?: 'RoleV2GQL' }
      & Pick<GqlRoleV2Gql, 'role_id' | 'role_name'>
    )>>>, permissions: Maybe<Array<Maybe<(
      { __typename?: 'PermissionV2GQL' }
      & Pick<GqlPermissionV2Gql, 'permission_id' | 'permission_name'>
    )>>>, primary_role: Maybe<(
      { __typename?: 'RoleV2GQL' }
      & Pick<GqlRoleV2Gql, 'role_name' | 'role_id'>
      & { permissions: Maybe<Array<Maybe<(
        { __typename?: 'PermissionV2GQL' }
        & Pick<GqlPermissionV2Gql, 'permission_name' | 'permission_id'>
      )>>> }
    )>, permissions_blacklist: Maybe<Array<Maybe<(
      { __typename?: 'PermissionV2GQL' }
      & Pick<GqlPermissionV2Gql, 'permission_name' | 'permission_id'>
    )>>> }
  )> }
);

export type GqlUsersV2QueryVariables = Exact<{
  search: Maybe<Scalars['String']>;
  per_page: Maybe<Scalars['Int']>;
  page: Maybe<Scalars['Int']>;
  filters: Maybe<Scalars['GenericScalar']>;
}>;


export type GqlUsersV2Query = (
  { __typename?: 'Query' }
  & { users_v2: Maybe<(
    { __typename?: 'UserV2GQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'UserV2GQLEdge' }
      & { node: Maybe<(
        { __typename?: 'UserV2GQL' }
        & Pick<GqlUserV2Gql, 'first_name' | 'last_name' | 'user_id' | 'user_no' | 'phone_number' | 'active' | 'email' | 'country_ids' | 'default_country_id' | 'primary_role_id'>
        & { primary_role: Maybe<(
          { __typename?: 'RoleV2GQL' }
          & Pick<GqlRoleV2Gql, 'role_name'>
        )>, roles: Maybe<Array<Maybe<(
          { __typename?: 'RoleV2GQL' }
          & Pick<GqlRoleV2Gql, 'role_id' | 'role_name'>
        )>>> }
      )> }
    )>> }
  )> }
);

export type GqlGetDepotRestrictionsQueryVariables = Exact<{
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
}>;


export type GqlGetDepotRestrictionsQuery = (
  { __typename?: 'Query' }
  & { depot_dc_product_item_restrictions: Maybe<(
    { __typename?: 'DepotDCProductItemRestrictionGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'DepotDCProductItemRestrictionGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'DepotDCProductItemRestrictionGQL' }
        & Pick<GqlDepotDcProductItemRestrictionGql, 'depot_dc_product_item_restriction_id' | 'depot_id' | 'product_item_id' | 'distribution_center_ids'>
        & { depot: Maybe<(
          { __typename?: 'DepotV2GQL' }
          & Pick<GqlDepotV2Gql, 'depot_name'>
          & { area: Maybe<(
            { __typename?: 'AreaV2GQL' }
            & { region: Maybe<(
              { __typename?: 'RegionV2GQL' }
              & Pick<GqlRegionV2Gql, 'region_id' | 'region_name'>
              & { country: Maybe<(
                { __typename?: 'CountryGQL' }
                & Pick<GqlCountryGql, 'country_id' | 'iso_alpha2_name'>
              )> }
            )> }
          )> }
        )>, product_item: Maybe<(
          { __typename?: 'ProductItemV2GQL' }
          & Pick<GqlProductItemV2Gql, 'product_item_name'>
          & { product: Maybe<(
            { __typename?: 'ProductV2GQL' }
            & Pick<GqlProductV2Gql, 'product_name'>
          )> }
        )>, distribution_centers: Array<Maybe<(
          { __typename?: 'DistributionCenterGQL' }
          & Pick<GqlDistributionCenterGql, 'distribution_center_name' | 'distribution_center_type' | 'distribution_center_id'>
        )>> }
      )> }
    )>> }
  )> }
);

export type GqlGetDistributionCentersQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlGetDistributionCentersQuery = (
  { __typename?: 'Query' }
  & { distribution_centers: Maybe<Array<Maybe<(
    { __typename?: 'DistributionCenterGQL' }
    & Pick<GqlDistributionCenterGql, 'date_created' | 'distribution_center_id' | 'distribution_center_name' | 'distribution_center_type' | 'description' | 'branch_id'>
  )>>> }
);

export type GqlFetchPseudoRoutesQueryVariables = Exact<{
  filters: Maybe<Scalars['GenericScalar']>;
  per_page: Maybe<Scalars['Int']>;
  page: Maybe<Scalars['Int']>;
}>;


export type GqlFetchPseudoRoutesQuery = (
  { __typename?: 'Query' }
  & { pseudo_routes: Maybe<(
    { __typename?: 'PseudoRouteGQLConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'PseudoRouteGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'PseudoRouteGQL' }
        & Pick<GqlPseudoRouteGql, 'active' | 'pseudo_route_id' | 'name' | 'merged_routes' | 'constraints' | 'custom_constraints' | 'force_share'>
        & { routes: Array<Maybe<(
          { __typename?: 'RouteV2GQL' }
          & Pick<GqlRouteV2Gql, 'route_id' | 'route_name'>
          & { depot: Maybe<(
            { __typename?: 'DepotV2GQL' }
            & Pick<GqlDepotV2Gql, 'depot_name'>
          )> }
        )>> }
      )> }
    )>>, page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )> }
  )> }
);

export type GqlFetchPseudoRouteByNameQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type GqlFetchPseudoRouteByNameQuery = (
  { __typename?: 'Query' }
  & { pseudo_route_by_id: Maybe<(
    { __typename?: 'PseudoRouteGQL' }
    & Pick<GqlPseudoRouteGql, 'pseudo_route_id'>
  )> }
);

export type GqlRoutesV2QueryVariables = Exact<{
  per_page: Maybe<Scalars['Int']>;
  page: Maybe<Scalars['Int']>;
  search: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
}>;


export type GqlRoutesV2Query = (
  { __typename?: 'Query' }
  & { routes_v2: Maybe<(
    { __typename?: 'RouteV2GQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & Pick<GqlPaginatorInfo, 'per_page' | 'total_count' | 'total_pages' | 'current_page' | 'has_next' | 'has_previous'>
    )>, edges: Array<Maybe<(
      { __typename?: 'RouteV2GQLEdge' }
      & { node: Maybe<(
        { __typename?: 'RouteV2GQL' }
        & Pick<GqlRouteV2Gql, 'route_name' | 'route_id' | 'date_created' | 'depot_id'>
        & { depot: Maybe<(
          { __typename?: 'DepotV2GQL' }
          & Pick<GqlDepotV2Gql, 'depot_name' | 'depot_id' | 'area_id'>
          & { area: Maybe<(
            { __typename?: 'AreaV2GQL' }
            & Pick<GqlAreaV2Gql, 'area_name' | 'region_id'>
            & { region: Maybe<(
              { __typename?: 'RegionV2GQL' }
              & Pick<GqlRegionV2Gql, 'region_name'>
            )> }
          )> }
        )> }
      )> }
    )>> }
  )> }
);

export type GqlRouteTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlRouteTypesQuery = (
  { __typename?: 'Query' }
  & Pick<GqlQuery, 'route_types'>
);

export type GqlStorage_Unit_TypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlStorage_Unit_TypesQuery = (
  { __typename?: 'Query' }
  & { storage_unit_types: Maybe<Array<Maybe<(
    { __typename?: 'StorageUnitTypeGQL' }
    & Pick<GqlStorageUnitTypeGql, 'date_created' | 'storage_unit_type_id' | 'storage_unit_type'>
  )>>> }
);

export type GqlStorage_UnitsQueryVariables = Exact<{
  per_page: Maybe<Scalars['Int']>;
  page: Maybe<Scalars['Int']>;
}>;


export type GqlStorage_UnitsQuery = (
  { __typename?: 'Query' }
  & { storage_units: Maybe<(
    { __typename?: 'StorageUnitGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'StorageUnitGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'StorageUnitGQL' }
        & Pick<GqlStorageUnitGql, 'date_created' | 'unit_name' | 'warehouse_id'>
        & { warehouse: Maybe<(
          { __typename?: 'WarehouseGQL' }
          & Pick<GqlWarehouseGql, 'warehouse_name' | 'distribution_center_id'>
          & { distribution_center: Maybe<(
            { __typename?: 'DistributionCenterGQL' }
            & Pick<GqlDistributionCenterGql, 'distribution_center_name'>
          )> }
        )> }
      )> }
    )>> }
  )> }
);

export type GqlWarehousesQueryVariables = Exact<{
  per_page: Maybe<Scalars['Int']>;
  page: Maybe<Scalars['Int']>;
}>;


export type GqlWarehousesQuery = (
  { __typename?: 'Query' }
  & { warehouses: Maybe<(
    { __typename?: 'WarehouseGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'WarehouseGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'WarehouseGQL' }
        & Pick<GqlWarehouseGql, 'warehouse_id' | 'warehouse_name' | 'distribution_center_id' | 'date_created'>
        & { distribution_center: Maybe<(
          { __typename?: 'DistributionCenterGQL' }
          & Pick<GqlDistributionCenterGql, 'distribution_center_id' | 'distribution_center_name'>
        )> }
      )> }
    )>> }
  )> }
);

export type GqlSourcingAgentsQueryVariables = Exact<{
  page: Maybe<Scalars['Int']>;
  filters: Maybe<Scalars['GenericScalar']>;
  per_page: Maybe<Scalars['Int']>;
  search: Maybe<Scalars['String']>;
}>;


export type GqlSourcingAgentsQuery = (
  { __typename?: 'Query' }
  & { sourcing_agents: Maybe<(
    { __typename?: 'SourcingAgentGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'SourcingAgentGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'SourcingAgentGQL' }
        & Pick<GqlSourcingAgentGql, 'agent_id' | 'user_name' | 'date_created' | 'active' | 'farmer_id'>
        & { farmer: Maybe<(
          { __typename?: 'FarmerGQL' }
          & Pick<GqlFarmerGql, 'supplier_name' | 'first_name' | 'last_name' | 'phone_number' | 'batch_code' | 'country_id'>
          & { country: Maybe<(
            { __typename?: 'CountryGQL' }
            & Pick<GqlCountryGql, 'iso_alpha2_name'>
          )> }
        )> }
      )> }
    )>> }
  )> }
);

export type GqlSourcingAgentByIdQueryVariables = Exact<{
  agent_id: Maybe<Scalars['String']>;
}>;


export type GqlSourcingAgentByIdQuery = (
  { __typename?: 'Query' }
  & { sourcing_agent_by_id: Maybe<(
    { __typename?: 'SourcingAgentGQL' }
    & Pick<GqlSourcingAgentGql, 'agent_id' | 'user_name' | 'active' | 'farmer_id'>
    & { farmer: Maybe<(
      { __typename?: 'FarmerGQL' }
      & Pick<GqlFarmerGql, 'supplier_name' | 'first_name' | 'last_name' | 'phone_number' | 'alt_phone_number' | 'batch_code' | 'farmer_type' | 'country_id'>
      & { country: Maybe<(
        { __typename?: 'CountryGQL' }
        & Pick<GqlCountryGql, 'iso_alpha2_name'>
      )> }
    )> }
  )> }
);

export type GqlBidApplicationsQueryVariables = Exact<{
  page: Maybe<Scalars['Int']>;
  filters: Maybe<Scalars['GenericScalar']>;
  per_page: Maybe<Scalars['Int']>;
  search: Maybe<Scalars['String']>;
}>;


export type GqlBidApplicationsQuery = (
  { __typename?: 'Query' }
  & { bid_applications: Maybe<(
    { __typename?: 'BidApplicationGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'BidApplicationGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'BidApplicationGQL' }
        & Pick<GqlBidApplicationGql, 'quantity' | 'bid_application_number' | 'bid_application_date' | 'bid_application_id' | 'vehicle_registration' | 'delivery_date' | 'active' | 'unit_price' | 'agent_id' | 'product_item_id' | 'product_id' | 'unit_of_measure_id' | 'bid_application_status' | 'country_id' | 'currency' | 'latitude' | 'longitude'>
        & { product_item: Maybe<(
          { __typename?: 'ProductItemV2GQL' }
          & Pick<GqlProductItemV2Gql, 'product_item_name'>
        )>, product: Maybe<(
          { __typename?: 'ProductV2GQL' }
          & Pick<GqlProductV2Gql, 'product_name'>
        )>, agent: Maybe<(
          { __typename?: 'SourcingAgentGQL' }
          & Pick<GqlSourcingAgentGql, 'farmer_id'>
          & { farmer: Maybe<(
            { __typename?: 'FarmerGQL' }
            & Pick<GqlFarmerGql, 'supplier_name' | 'farmer_number' | 'first_name' | 'last_name' | 'phone_number'>
          )> }
        )>, uom: Maybe<(
          { __typename?: 'UOMV2GQL' }
          & Pick<GqlUomv2Gql, 'name' | 'abbreviation'>
        )>, country: Maybe<(
          { __typename?: 'CountryGQL' }
          & Pick<GqlCountryGql, 'iso_alpha2_name'>
        )> }
      )> }
    )>> }
  )> }
);

export type GqlBidsQueryVariables = Exact<{
  page: Maybe<Scalars['Int']>;
  filters: Maybe<Scalars['GenericScalar']>;
  per_page: Maybe<Scalars['Int']>;
  search: Maybe<Scalars['String']>;
}>;


export type GqlBidsQuery = (
  { __typename?: 'Query' }
  & { bids: Maybe<(
    { __typename?: 'BidGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'BidGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'BidGQL' }
        & Pick<GqlBidGql, 'bid_id' | 'date_created' | 'opening_date' | 'closing_date' | 'bid_notes' | 'active' | 'minimum_quantity' | 'bid_application_lowest_price' | 'bid_application_count' | 'delivery_date' | 'bid_number' | 'opening_bid_price' | 'quantity' | 'bid_status' | 'product_item_id' | 'product_id' | 'unit_of_measure_id' | 'country_id'>
        & { product_item: Maybe<(
          { __typename?: 'ProductItemV2GQL' }
          & Pick<GqlProductItemV2Gql, 'product_item_name'>
        )>, product: Maybe<(
          { __typename?: 'ProductV2GQL' }
          & Pick<GqlProductV2Gql, 'product_name'>
        )>, uom: Maybe<(
          { __typename?: 'UOMV2GQL' }
          & Pick<GqlUomv2Gql, 'name'>
        )>, country: Maybe<(
          { __typename?: 'CountryGQL' }
          & Pick<GqlCountryGql, 'iso_alpha2_name'>
        )> }
      )> }
    )>> }
  )> }
);

export type GqlBid_By_IdQueryVariables = Exact<{
  bid_id: Maybe<Scalars['String']>;
}>;


export type GqlBid_By_IdQuery = (
  { __typename?: 'Query' }
  & { bid_by_id: Maybe<(
    { __typename?: 'BidGQL' }
    & Pick<GqlBidGql, 'bid_id' | 'opening_date' | 'closing_date' | 'bid_notes' | 'active' | 'minimum_quantity' | 'bid_application_lowest_price' | 'delivery_date' | 'bid_number' | 'opening_bid_price' | 'quantity' | 'bid_status' | 'product_item_id' | 'product_id' | 'unit_of_measure_id' | 'country_id' | 'cancellation_reason' | 'currency'>
    & { product_item: Maybe<(
      { __typename?: 'ProductItemV2GQL' }
      & Pick<GqlProductItemV2Gql, 'product_item_name'>
    )>, product: Maybe<(
      { __typename?: 'ProductV2GQL' }
      & Pick<GqlProductV2Gql, 'product_name'>
    )>, uom: Maybe<(
      { __typename?: 'UOMV2GQL' }
      & Pick<GqlUomv2Gql, 'name'>
    )>, country: Maybe<(
      { __typename?: 'CountryGQL' }
      & Pick<GqlCountryGql, 'iso_alpha2_name'>
    )> }
  )> }
);

export type GqlSupplierUsersQueryVariables = Exact<{
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  search: Maybe<Scalars['String']>;
}>;


export type GqlSupplierUsersQuery = (
  { __typename?: 'Query' }
  & { supplier_users: Maybe<(
    { __typename?: 'SupplierUserGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'SupplierUserGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'SupplierUserGQL' }
        & Pick<GqlSupplierUserGql, 'supplier_user_id' | 'date_created' | 'user_id' | 'active'>
      )> }
    )>> }
  )> }
);

export type GqlGetAuthorisersQueryVariables = Exact<{
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  search: Maybe<Scalars['String']>;
  authoriserFilters: Maybe<GqlAuthorizerGqlInput>;
}>;


export type GqlGetAuthorisersQuery = (
  { __typename?: 'Query' }
  & { authorizers: Maybe<(
    { __typename?: 'AuthorizerGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Maybe<Array<Maybe<(
      { __typename?: 'AuthorizerGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'AuthorizerGQL' }
        & Pick<GqlAuthorizerGql, 'authorizerId' | 'email' | 'mobileNumber' | 'name' | 'reportsTo' | 'role'>
      )> }
    )>>> }
  )> }
);

export type GqlGetWorkflowsQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlGetWorkflowsQuery = (
  { __typename?: 'Query' }
  & { workflows: Maybe<(
    { __typename?: 'WorkflowGQLConnection' }
    & { edges: Maybe<Array<Maybe<(
      { __typename?: 'WorkflowGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'WorkflowGQL' }
        & Pick<GqlWorkflowGql, 'name' | 'workflowId'>
        & { rules: Maybe<Array<Maybe<(
          { __typename?: 'Entry_WorkflowLevel_ApprovalRule' }
          & Pick<GqlEntry_WorkflowLevel_ApprovalRule, 'key'>
          & { value: Maybe<(
            { __typename?: 'ApprovalRule' }
            & Pick<GqlApprovalRule, 'ruleId' | 'mandate'>
            & { authorizers: Array<Maybe<(
              { __typename?: 'Authorizer' }
              & Pick<GqlAuthorizer, 'name'>
            )>> }
          )> }
        )>>> }
      )> }
    )>>> }
  )> }
);

export type GqlGetApprovalsRequestsQueryVariables = Exact<{
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  search: Maybe<Scalars['String']>;
  approvalFilters: Maybe<GqlRequestInputInput>;
}>;


export type GqlGetApprovalsRequestsQuery = (
  { __typename?: 'Query' }
  & { requests: Maybe<(
    { __typename?: 'RequestGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Maybe<Array<Maybe<(
      { __typename?: 'RequestGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'RequestGQL' }
        & Pick<GqlRequestGql, 'requestId' | 'status' | 'authorizers' | 'details' | 'currentLevel' | 'reason'>
        & { lastDecision: Maybe<(
          { __typename?: 'Decision' }
          & Pick<GqlDecision, 'reason'>
        )>, workflow: Maybe<(
          { __typename?: 'Workflow' }
          & { rules: Array<Maybe<(
            { __typename?: 'Entry_WorkflowLevel_ApprovalRule' }
            & Pick<GqlEntry_WorkflowLevel_ApprovalRule, 'key'>
            & { value: Maybe<(
              { __typename?: 'ApprovalRule' }
              & { authorizers: Array<Maybe<(
                { __typename?: 'Authorizer' }
                & Pick<GqlAuthorizer, 'name' | 'authorizerId' | 'approved'>
              )>> }
            )> }
          )>> }
        )> }
      )> }
    )>>> }
  )> }
);

export type GqlGetApprovalsRequestByIdQueryVariables = Exact<{
  request_id: Scalars['String'];
}>;


export type GqlGetApprovalsRequestByIdQuery = (
  { __typename?: 'Query' }
  & { request_by_id: Maybe<(
    { __typename?: 'RequestGQL' }
    & Pick<GqlRequestGql, 'requestId' | 'status' | 'authorizers' | 'details' | 'currentLevel' | 'initiator' | 'reason'>
    & { workflow: Maybe<(
      { __typename?: 'Workflow' }
      & { rules: Array<Maybe<(
        { __typename?: 'Entry_WorkflowLevel_ApprovalRule' }
        & Pick<GqlEntry_WorkflowLevel_ApprovalRule, 'key'>
        & { value: Maybe<(
          { __typename?: 'ApprovalRule' }
          & { authorizers: Array<Maybe<(
            { __typename?: 'Authorizer' }
            & Pick<GqlAuthorizer, 'name' | 'authorizerId' | 'approved'>
          )>> }
        )> }
      )>> }
    )> }
  )> }
);

export type GqlDeliveryDetailsFragment = (
  { __typename?: 'DeliveryV2GQL' }
  & Pick<GqlDeliveryV2Gql, 'delivery_number' | 'delivery_date' | 'date_created' | 'active' | 'amount' | 'currency' | 'route_id' | 'shop_id'>
  & { served_by: Maybe<(
    { __typename?: 'UserV2GQL' }
    & Pick<GqlUserV2Gql, 'first_name' | 'last_name'>
  )>, route: Maybe<(
    { __typename?: 'RouteV2GQL' }
    & Pick<GqlRouteV2Gql, 'route_name'>
  )>, shop: Maybe<(
    { __typename?: 'ShopV2GQL' }
    & Pick<GqlShopV2Gql, 'shop_id' | 'shop_number' | 'shop_name' | 'delivery_fee' | 'customer_id'>
    & { customer: Maybe<(
      { __typename?: 'CustomerV2GQL' }
      & Pick<GqlCustomerV2Gql, 'customer_name' | 'phone_number'>
    )> }
  )> }
);

export type GqlDeliveriesQueryVariables = Exact<{
  search: Maybe<Scalars['String']>;
  per_page: Maybe<Scalars['Int']>;
  page: Maybe<Scalars['Int']>;
  deliveryFilters: Maybe<Scalars['GenericScalar']>;
}>;


export type GqlDeliveriesQuery = (
  { __typename?: 'Query' }
  & { deliveries_v2: Maybe<(
    { __typename?: 'DeliveryV2GQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'DeliveryV2GQLEdge' }
      & { node: Maybe<(
        { __typename?: 'DeliveryV2GQL' }
        & Pick<GqlDeliveryV2Gql, 'delivery_id'>
        & { payments: Maybe<Array<Maybe<(
          { __typename?: 'PaymentV2GQL' }
          & Pick<GqlPaymentV2Gql, 'payment_id' | 'amount' | 'currency'>
        )>>> }
        & GqlDeliveryDetailsFragment
      )> }
    )>> }
  )> }
);

export type GqlDelivery_V2_By_IdQueryVariables = Exact<{
  deliveryId: Scalars['String'];
}>;


export type GqlDelivery_V2_By_IdQuery = (
  { __typename?: 'Query' }
  & { delivery_v2_by_id: Maybe<(
    { __typename?: 'DeliveryV2GQL' }
    & Pick<GqlDeliveryV2Gql, 'delivery_id' | 'cancellation_reason' | 'amount' | 'currency' | 'active'>
    & { delivery_items: Maybe<Array<Maybe<(
      { __typename?: 'DeliveryItemV2GQL' }
      & Pick<GqlDeliveryItemV2Gql, 'product_item_id' | 'unit_price' | 'amount' | 'currency' | 'quantity' | 'unit_of_measure_id'>
      & { product_item: Maybe<(
        { __typename?: 'ProductItemV2GQL' }
        & Pick<GqlProductItemV2Gql, 'product_item_name'>
        & { product: Maybe<(
          { __typename?: 'ProductV2GQL' }
          & Pick<GqlProductV2Gql, 'product_name'>
        )> }
      )>, uom: Maybe<(
        { __typename?: 'UOMV2GQL' }
        & Pick<GqlUomv2Gql, 'name' | 'abbreviation'>
      )> }
    )>>>, payments: Maybe<Array<Maybe<(
      { __typename?: 'PaymentV2GQL' }
      & Pick<GqlPaymentV2Gql, 'payment_id' | 'reference_number' | 'payment_number' | 'payment_mode' | 'date_created' | 'amount' | 'currency'>
    )>>> }
    & GqlDeliveryDetailsFragment
  )> }
);

export type GqlDeliveryReturnsQueryVariables = Exact<{
  per_page: Maybe<Scalars['Int']>;
  page: Maybe<Scalars['Int']>;
  search: Maybe<Scalars['String']>;
  filter: Maybe<Scalars['GenericScalar']>;
}>;


export type GqlDeliveryReturnsQuery = (
  { __typename?: 'Query' }
  & { delivery_returns: Maybe<(
    { __typename?: 'DeliveryReturnGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & Pick<GqlPaginatorInfo, 'per_page' | 'total_count' | 'total_pages' | 'has_next' | 'has_previous'>
    )>, edges: Array<Maybe<(
      { __typename?: 'DeliveryReturnGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'DeliveryReturnGQL' }
        & Pick<GqlDeliveryReturnGql, 'return_id' | 'return_number' | 'return_date' | 'currency' | 'active' | 'shop_id' | 'claim_processed' | 'refund_status'>
        & { delivery: Maybe<(
          { __typename?: 'DeliveryV2GQL' }
          & Pick<GqlDeliveryV2Gql, 'delivery_date' | 'delivery_id' | 'delivery_number'>
          & { delivery_status: Maybe<Array<Maybe<(
            { __typename?: 'DeliveryStatusV2GQL' }
            & Pick<GqlDeliveryStatusV2Gql, 'delivery_status'>
          )>>> }
        )>, return_items: Maybe<Array<Maybe<(
          { __typename?: 'DeliveryReturnItemGQL' }
          & Pick<GqlDeliveryReturnItemGql, 'approved_by' | 'approved_amount' | 'approved_quantity' | 'comment' | 'approved' | 'image' | 'active' | 'return_item_id' | 'quantity' | 'amount' | 'currency' | 'signed_image_url' | 'unit_of_measure_id' | 'product_item_id' | 'rejection_reason_id'>
          & { approved_by_user: Maybe<(
            { __typename?: 'UserV2GQL' }
            & Pick<GqlUserV2Gql, 'first_name' | 'last_name'>
          )>, uom: Maybe<(
            { __typename?: 'UOMV2GQL' }
            & Pick<GqlUomv2Gql, 'name'>
          )>, product_item: Maybe<(
            { __typename?: 'ProductItemV2GQL' }
            & Pick<GqlProductItemV2Gql, 'product_item_name' | 'product_id'>
            & { product: Maybe<(
              { __typename?: 'ProductV2GQL' }
              & Pick<GqlProductV2Gql, 'product_name'>
            )> }
          )>, reason: Maybe<(
            { __typename?: 'ReturnReasonGQL' }
            & Pick<GqlReturnReasonGql, 'name' | 'description'>
          )>, rejection_reason: Maybe<(
            { __typename?: 'ReturnItemRejectionReasonGQL' }
            & Pick<GqlReturnItemRejectionReasonGql, 'name'>
          )> }
        )>>>, shop: Maybe<(
          { __typename?: 'ShopV2GQL' }
          & Pick<GqlShopV2Gql, 'shop_name' | 'shop_number' | 'signed_image_url' | 'route_id' | 'customer_id'>
          & { region: Maybe<(
            { __typename?: 'RegionV2GQL' }
            & Pick<GqlRegionV2Gql, 'region_name'>
          )>, route: Maybe<(
            { __typename?: 'RouteV2GQL' }
            & Pick<GqlRouteV2Gql, 'route_name'>
            & { depot: Maybe<(
              { __typename?: 'DepotV2GQL' }
              & Pick<GqlDepotV2Gql, 'depot_name'>
            )> }
          )>, customer: Maybe<(
            { __typename?: 'CustomerV2GQL' }
            & Pick<GqlCustomerV2Gql, 'customer_name' | 'phone_number'>
          )> }
        )> }
      )> }
    )>> }
  )> }
);

export type GqlReturnItemRejectionReasonsQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlReturnItemRejectionReasonsQuery = (
  { __typename?: 'Query' }
  & { return_item_rejection_reasons: Maybe<Array<Maybe<(
    { __typename?: 'ReturnItemRejectionReasonGQL' }
    & Pick<GqlReturnItemRejectionReasonGql, 'rejection_reason_id' | 'name' | 'description'>
  )>>> }
);

export type GqlDeliveryRefundStatusesQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlDeliveryRefundStatusesQuery = (
  { __typename?: 'Query' }
  & Pick<GqlQuery, 'refund_statuses'>
);

export type GqlDepotIntakeQueryVariables = Exact<{
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter: Maybe<Scalars['GenericScalar']>;
  search: Maybe<Scalars['String']>;
  filterArray: Maybe<Scalars['GenericScalar']>;
}>;


export type GqlDepotIntakeQuery = (
  { __typename?: 'Query' }
  & { depot_intake: Maybe<(
    { __typename?: 'DepotIntakeGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'DepotIntakeGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'DepotIntakeGQL' }
        & Pick<GqlDepotIntakeGql, 'depot_intake_id' | 'depot_intake_number' | 'cancellation_reason' | 'active' | 'sale_date' | 'route_id' | 'created_by'>
        & { depot_intake_items: Maybe<Array<Maybe<(
          { __typename?: 'DepotIntakeItemGQL' }
          & Pick<GqlDepotIntakeItemGql, 'depot_intake_item_id' | 'packaging_unit_id' | 'packaging_unit_quantity' | 'quantity' | 'unit_of_measure_id' | 'product_item_id'>
          & { uom: Maybe<(
            { __typename?: 'UOMV2GQL' }
            & Pick<GqlUomv2Gql, 'abbreviation'>
          )>, product_item: Maybe<(
            { __typename?: 'ProductItemV2GQL' }
            & Pick<GqlProductItemV2Gql, 'product_item_name' | 'product_id'>
            & { product: Maybe<(
              { __typename?: 'ProductV2GQL' }
              & Pick<GqlProductV2Gql, 'product_name'>
              & { packaging_units: Maybe<Array<Maybe<(
                { __typename?: 'PackagingUnitV2GQL' }
                & Pick<GqlPackagingUnitV2Gql, 'packaging_unit_id' | 'name'>
              )>>> }
            )> }
          )> }
        )>>>, user: Maybe<(
          { __typename?: 'UserV2GQL' }
          & Pick<GqlUserV2Gql, 'first_name' | 'last_name' | 'phone_number' | 'user_no' | 'user_id'>
        )>, route: Maybe<(
          { __typename?: 'RouteV2GQL' }
          & Pick<GqlRouteV2Gql, 'route_name' | 'route_id' | 'depot_id'>
          & { depot: Maybe<(
            { __typename?: 'DepotV2GQL' }
            & Pick<GqlDepotV2Gql, 'depot_name'>
          )> }
        )> }
      )> }
    )>> }
  )> }
);

export type GqlDepotReturnsQueryVariables = Exact<{
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter: Maybe<Scalars['GenericScalar']>;
  filterArray: Maybe<Scalars['GenericScalar']>;
}>;


export type GqlDepotReturnsQuery = (
  { __typename?: 'Query' }
  & { returns: Maybe<(
    { __typename?: 'ReturnGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'ReturnGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'ReturnGQL' }
        & Pick<GqlReturnGql, 'return_id' | 'return_number' | 'sale_date' | 'created_by' | 'active' | 'depot_id' | 'route_id'>
        & { depot: Maybe<(
          { __typename?: 'DepotV2GQL' }
          & Pick<GqlDepotV2Gql, 'depot_name'>
        )>, user: Maybe<(
          { __typename?: 'UserV2GQL' }
          & Pick<GqlUserV2Gql, 'first_name' | 'last_name' | 'phone_number' | 'user_number' | 'user_id'>
        )>, packaging_return_items: Maybe<Array<Maybe<(
          { __typename?: 'PackagingReturnItemGQL' }
          & Pick<GqlPackagingReturnItemGql, 'packaging_return_item_id' | 'active' | 'cancellation_reason' | 'packaging_unit_id' | 'quantity'>
          & { packaging_unit: Maybe<(
            { __typename?: 'PackagingUnitV2GQL' }
            & Pick<GqlPackagingUnitV2Gql, 'name'>
          )> }
        )>>>, product_return_items: Maybe<Array<Maybe<(
          { __typename?: 'ProductReturnItemGQL' }
          & Pick<GqlProductReturnItemGql, 'active' | 'cancellation_reason' | 'return_type' | 'product_item_id' | 'product_return_item_id' | 'quantity' | 'unit_of_measure_id'>
          & { uom: Maybe<(
            { __typename?: 'UOMV2GQL' }
            & Pick<GqlUomv2Gql, 'abbreviation'>
          )>, product_item: Maybe<(
            { __typename?: 'ProductItemV2GQL' }
            & Pick<GqlProductItemV2Gql, 'product_item_name' | 'product_id'>
            & { product: Maybe<(
              { __typename?: 'ProductV2GQL' }
              & Pick<GqlProductV2Gql, 'product_name'>
            )> }
          )> }
        )>>>, route: Maybe<(
          { __typename?: 'RouteV2GQL' }
          & Pick<GqlRouteV2Gql, 'route_name'>
        )>, route_plan: Maybe<(
          { __typename?: 'RoutePlanGQL' }
          & { route: Maybe<(
            { __typename?: 'RouteV2GQL' }
            & Pick<GqlRouteV2Gql, 'route_id' | 'route_name'>
          )>, vehicle: Maybe<(
            { __typename?: 'VehicleGQL' }
            & Pick<GqlVehicleGql, 'registration_number'>
          )> }
        )> }
      )> }
    )>> }
  )> }
);

export type GqlStockTransferQueryVariables = Exact<{
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filter: Maybe<Scalars['GenericScalar']>;
  filterArray: Maybe<Scalars['GenericScalar']>;
}>;


export type GqlStockTransferQuery = (
  { __typename?: 'Query' }
  & { stock_transfer: Maybe<(
    { __typename?: 'StockTransferGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'StockTransferGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'StockTransferGQL' }
        & Pick<GqlStockTransferGql, 'stock_transfer_id' | 'stock_transfer_number' | 'active' | 'sale_date' | 'route_id_from' | 'product_item_id' | 'created_by'>
        & { route_from: Maybe<(
          { __typename?: 'RouteV2GQL' }
          & Pick<GqlRouteV2Gql, 'route_name' | 'depot_id'>
          & { depot: Maybe<(
            { __typename?: 'DepotV2GQL' }
            & Pick<GqlDepotV2Gql, 'depot_name'>
          )> }
        )>, product_item: Maybe<(
          { __typename?: 'ProductItemV2GQL' }
          & Pick<GqlProductItemV2Gql, 'product_item_name' | 'product_id'>
          & { product: Maybe<(
            { __typename?: 'ProductV2GQL' }
            & Pick<GqlProductV2Gql, 'product_name'>
          )> }
        )>, stock_transfer_items: Maybe<Array<Maybe<(
          { __typename?: 'StockTransferItemGQL' }
          & Pick<GqlStockTransferItemGql, 'route_id_to' | 'stock_transfer_item_id' | 'unit_of_measure_id' | 'quantity' | 'packaging_unit_id' | 'packaging_unit_quantity'>
          & { route_to: Maybe<(
            { __typename?: 'RouteV2GQL' }
            & Pick<GqlRouteV2Gql, 'route_name'>
          )>, uom: Maybe<(
            { __typename?: 'UOMV2GQL' }
            & Pick<GqlUomv2Gql, 'abbreviation'>
          )>, packaging_unit: Maybe<(
            { __typename?: 'PackagingUnitV2GQL' }
            & Pick<GqlPackagingUnitV2Gql, 'name'>
          )> }
        )>>>, user: Maybe<(
          { __typename?: 'UserV2GQL' }
          & Pick<GqlUserV2Gql, 'first_name' | 'last_name'>
        )> }
      )> }
    )>> }
  )> }
);

export type GqlDispatchBalanceQueryVariables = Exact<{
  route_id_from: Maybe<Scalars['String']>;
  saleDate: Maybe<Scalars['String']>;
  product_item_id: Maybe<Scalars['String']>;
}>;


export type GqlDispatchBalanceQuery = (
  { __typename?: 'Query' }
  & { dispatch_balance: Maybe<(
    { __typename?: 'DispatchBalanceGQL' }
    & Pick<GqlDispatchBalanceGql, 'date' | 'route_id' | 'stock_balance' | 'packaging_balance' | 'product_item_id'>
  )> }
);

export type GqlOrdersQueryVariables = Exact<{
  search: Maybe<Scalars['String']>;
  per_page: Maybe<Scalars['Int']>;
  page: Maybe<Scalars['Int']>;
  filter: Maybe<Scalars['GenericScalar']>;
}>;


export type GqlOrdersQuery = (
  { __typename?: 'Query' }
  & { orders_v2: Maybe<(
    { __typename?: 'OrderV2GQLConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'OrderV2GQLEdge' }
      & { node: Maybe<(
        { __typename?: 'OrderV2GQL' }
        & Pick<GqlOrderV2Gql, 'priority' | 'order_number' | 'order_id' | 'shop_id' | 'active' | 'cancellation_reason' | 'currency' | 'trader_entity_user_id' | 'route_id' | 'amount' | 'delivery_date' | 'order_date'>
        & { shop: Maybe<(
          { __typename?: 'ShopV2GQL' }
          & Pick<GqlShopV2Gql, 'shop_name' | 'shop_number'>
        )>, served_by: Maybe<(
          { __typename?: 'UserV2GQL' }
          & Pick<GqlUserV2Gql, 'user_id' | 'first_name' | 'last_name' | 'user_no' | 'phone_number'>
        )>, route: Maybe<(
          { __typename?: 'RouteV2GQL' }
          & Pick<GqlRouteV2Gql, 'route_name'>
        )>, order_items: Maybe<Array<Maybe<(
          { __typename?: 'OrderItemV2GQL' }
          & Pick<GqlOrderItemV2Gql, 'product_item_id' | 'amount' | 'currency' | 'unit_price' | 'quantity' | 'unit_of_measure_id'>
          & { product_item: Maybe<(
            { __typename?: 'ProductItemV2GQL' }
            & Pick<GqlProductItemV2Gql, 'product_item_name'>
            & { product: Maybe<(
              { __typename?: 'ProductV2GQL' }
              & Pick<GqlProductV2Gql, 'product_name'>
            )> }
          )>, uom: Maybe<(
            { __typename?: 'UOMV2GQL' }
            & Pick<GqlUomv2Gql, 'uom_id' | 'name'>
          )> }
        )>>> }
      )> }
    )>>, page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )> }
  )> }
);

export type GqlCustomerDetailsFragment = (
  { __typename?: 'ShopV2GQL' }
  & { customer: Maybe<(
    { __typename?: 'CustomerV2GQL' }
    & Pick<GqlCustomerV2Gql, 'customer_id' | 'phone_number' | 'customer_name'>
  )> }
);

export type GqlShopDetailsFragment = (
  { __typename?: 'ShopV2GQL' }
  & Pick<GqlShopV2Gql, 'shop_id' | 'shop_number' | 'shop_name' | 'kra_pin' | 'active' | 'synced_to_etims' | 'shop_type_id' | 'updated_by' | 'region_id' | 'route_id'>
  & { shop_type: Maybe<(
    { __typename?: 'ShopTypeV2GQL' }
    & Pick<GqlShopTypeV2Gql, 'name'>
  )>, country: Maybe<(
    { __typename?: 'CountryGQL' }
    & Pick<GqlCountryGql, 'country_id' | 'default_currency'>
  )>, distribution_centers: Maybe<Array<Maybe<(
    { __typename?: 'DepotDistributionCenterGQL' }
    & Pick<GqlDepotDistributionCenterGql, 'distribution_center_id'>
  )>>>, updated_by_user: Maybe<(
    { __typename?: 'UserV2GQL' }
    & Pick<GqlUserV2Gql, 'first_name' | 'last_name' | 'user_id'>
  )>, route: Maybe<(
    { __typename?: 'RouteV2GQL' }
    & Pick<GqlRouteV2Gql, 'route_id' | 'route_name' | 'depot_id'>
    & { depot: Maybe<(
      { __typename?: 'DepotV2GQL' }
      & Pick<GqlDepotV2Gql, 'depot_name'>
    )> }
  )> }
);

export type GqlShopsV2QueryVariables = Exact<{
  per_page: Maybe<Scalars['Int']>;
  page: Maybe<Scalars['Int']>;
  search: Maybe<Scalars['String']>;
  filter: Maybe<Scalars['GenericScalar']>;
}>;


export type GqlShopsV2Query = (
  { __typename?: 'Query' }
  & { shops_v2: Maybe<(
    { __typename?: 'ShopV2GQLConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'ShopV2GQLEdge' }
      & { node: Maybe<(
        { __typename?: 'ShopV2GQL' }
        & Pick<GqlShopV2Gql, 'customer_id' | 'approved' | 'signed_image_url' | 'cluster_id'>
        & { cluster: Maybe<(
          { __typename?: 'SalesAgentClusterGQL' }
          & Pick<GqlSalesAgentClusterGql, 'cluster_code'>
        )>, customer_types: Maybe<Array<Maybe<(
          { __typename?: 'CustomerTypeV2GQL' }
          & Pick<GqlCustomerTypeV2Gql, 'customer_type_id' | 'name'>
        )>>> }
        & GqlShopDetailsFragment
        & GqlCustomerDetailsFragment
      )> }
    )>>, page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )> }
  )> }
);

export type GqlShopByIdQueryVariables = Exact<{
  shop_id: Scalars['String'];
}>;


export type GqlShopByIdQuery = (
  { __typename?: 'Query' }
  & { shop_v2_by_id: Maybe<(
    { __typename?: 'ShopV2GQL' }
    & Pick<GqlShopV2Gql, 'region_id' | 'kra_pin' | 'customer_id'>
    & { region: Maybe<(
      { __typename?: 'RegionV2GQL' }
      & Pick<GqlRegionV2Gql, 'region_name'>
    )>, customer_types: Maybe<Array<Maybe<(
      { __typename?: 'CustomerTypeV2GQL' }
      & Pick<GqlCustomerTypeV2Gql, 'customer_type_id' | 'name'>
    )>>> }
    & GqlShopDetailsFragment
    & GqlCustomerDetailsFragment
  )> }
);

export type GqlShopTypesV2QueryVariables = Exact<{
  grouped: Maybe<Scalars['Boolean']>;
}>;


export type GqlShopTypesV2Query = (
  { __typename?: 'Query' }
  & { shop_types_v2: Maybe<Array<Maybe<(
    { __typename?: 'ShopTypeV2GQL' }
    & Pick<GqlShopTypeV2Gql, 'shop_type_id' | 'name' | 'active'>
    & { children: Maybe<Array<Maybe<(
      { __typename?: 'ShopTypeV2GQL' }
      & Pick<GqlShopTypeV2Gql, 'shop_type_id' | 'name' | 'active'>
    )>>> }
  )>>> }
);

export type GqlCustomerTypesV2QueryVariables = Exact<{ [key: string]: never; }>;


export type GqlCustomerTypesV2Query = (
  { __typename?: 'Query' }
  & { customer_types_v2: Maybe<Array<Maybe<(
    { __typename?: 'CustomerTypeV2GQL' }
    & Pick<GqlCustomerTypeV2Gql, 'customer_type_id' | 'name'>
  )>>> }
);

export type GqlClustersQueryVariables = Exact<{
  search: Maybe<Scalars['String']>;
}>;


export type GqlClustersQuery = (
  { __typename?: 'Query' }
  & { clusters: Maybe<(
    { __typename?: 'SalesAgentClusterGQLConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'SalesAgentClusterGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'SalesAgentClusterGQL' }
        & Pick<GqlSalesAgentClusterGql, 'date_created' | 'cluster_code' | 'cluster_id' | 'territory_name' | 'active'>
        & { updated_by_user: Maybe<(
          { __typename?: 'UserV2GQL' }
          & Pick<GqlUserV2Gql, 'first_name' | 'last_name' | 'user_id'>
        )> }
      )> }
    )>>, page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & Pick<GqlPaginatorInfo, 'per_page' | 'total_count' | 'total_pages' | 'current_page' | 'has_next' | 'has_previous'>
    )> }
  )> }
);

export type GqlTerritoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlTerritoriesQuery = (
  { __typename?: 'Query' }
  & { territories: Maybe<Array<Maybe<(
    { __typename?: 'TerritoryGQL' }
    & Pick<GqlTerritoryGql, 'territory_name'>
  )>>> }
);

export type GqlDeliveryItemWarrantiesQueryVariables = Exact<{
  per_page: Maybe<Scalars['Int']>;
  page: Maybe<Scalars['Int']>;
  filters: Maybe<Scalars['GenericScalar']>;
}>;


export type GqlDeliveryItemWarrantiesQuery = (
  { __typename?: 'Query' }
  & { delivery_item_warranties: Maybe<(
    { __typename?: 'DeliveryItemWarrantyGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'DeliveryItemWarrantyGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'DeliveryItemWarrantyGQL' }
        & Pick<GqlDeliveryItemWarrantyGql, 'warranty_id' | 'serial_number' | 'delivery_item_id' | 'image_url'>
        & { delivery_item: Maybe<(
          { __typename?: 'DeliveryItemV2GQL' }
          & Pick<GqlDeliveryItemV2Gql, 'product_item_id' | 'unit_price' | 'amount' | 'quantity'>
          & { product_item: Maybe<(
            { __typename?: 'ProductItemV2GQL' }
            & Pick<GqlProductItemV2Gql, 'product_item_name' | 'product_id'>
            & { product: Maybe<(
              { __typename?: 'ProductV2GQL' }
              & Pick<GqlProductV2Gql, 'product_name'>
            )> }
          )>, delivery: Maybe<(
            { __typename?: 'DeliveryV2GQL' }
            & Pick<GqlDeliveryV2Gql, 'delivery_number' | 'delivery_date' | 'route_id'>
            & { route: Maybe<(
              { __typename?: 'RouteV2GQL' }
              & Pick<GqlRouteV2Gql, 'route_name'>
            )>, shop: Maybe<(
              { __typename?: 'ShopV2GQL' }
              & Pick<GqlShopV2Gql, 'shop_number'>
              & { customer: Maybe<(
                { __typename?: 'CustomerV2GQL' }
                & Pick<GqlCustomerV2Gql, 'phone_number'>
              )> }
            )> }
          )> }
        )> }
      )> }
    )>> }
  )> }
);

export type GqlRoutingSummaryQueryVariables = Exact<{
  search: Maybe<Scalars['String']>;
  routingFilters: Maybe<Scalars['GenericScalar']>;
  per_page: Maybe<Scalars['Int']>;
  page: Maybe<Scalars['Int']>;
  sale_date: Maybe<Scalars['String']>;
  depot_id: Maybe<Scalars['String']>;
}>;


export type GqlRoutingSummaryQuery = (
  { __typename?: 'Query' }
  & { depot_routing_summary: Maybe<(
    { __typename?: 'DepotRoutingSummaryGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'DepotRoutingSummaryGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'DepotRoutingSummaryGQL' }
        & Pick<GqlDepotRoutingSummaryGql, 'depot_id' | 'depot_name' | 'sale_date' | 'route_plans' | 'total_orders' | 'routed_orders' | 'total_ordered_revenue' | 'total_routed_revenue' | 'total_ordered_weight' | 'total_routed_weight'>
      )> }
    )>> }
  )> }
);

export type GqlGetRequestsQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlGetRequestsQuery = (
  { __typename?: 'Query' }
  & { requests: Maybe<(
    { __typename?: 'RequestGQLConnection' }
    & { edges: Maybe<Array<Maybe<(
      { __typename?: 'RequestGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'RequestGQL' }
        & Pick<GqlRequestGql, 'reason' | 'status' | 'details' | 'externalReferenceId'>
      )> }
    )>>> }
  )> }
);

export type GqlFarmersQueryVariables = Exact<{
  per_page: Maybe<Scalars['Int']>;
  page: Maybe<Scalars['Int']>;
  filters: Maybe<Scalars['GenericScalar']>;
}>;


export type GqlFarmersQuery = (
  { __typename?: 'Query' }
  & { farmers: Maybe<(
    { __typename?: 'FarmerGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & Pick<GqlPaginatorInfo, 'has_next' | 'has_previous' | 'per_page' | 'total_pages' | 'total_count' | 'current_page'>
    )>, edges: Array<Maybe<(
      { __typename?: 'FarmerGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'FarmerGQL' }
        & Pick<GqlFarmerGql, 'farmer_type' | 'farmer_id' | 'farmer_number' | 'first_name' | 'last_name' | 'supplier_name' | 'active'>
      )> }
    )>> }
  )> }
);

export type GqlGetEtimsCodesQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlGetEtimsCodesQuery = (
  { __typename?: 'Query' }
  & { eTIMsCodes: (
    { __typename?: 'SelectCodesResponse' }
    & Pick<GqlSelectCodesResponse, 'resultCd' | 'resultMsg' | 'resultDt'>
    & { data: (
      { __typename?: 'SelectCodesData' }
      & { clsList: Array<(
        { __typename?: 'ClsListItem' }
        & Pick<GqlClsListItem, 'cdCls' | 'cdClsNm'>
      )> }
    ) }
  ) }
);

export type GqlGetEtimsCodesByCodeNameQueryVariables = Exact<{
  cdClsNm: Maybe<Scalars['String']>;
}>;


export type GqlGetEtimsCodesByCodeNameQuery = (
  { __typename?: 'Query' }
  & { eTIMsCodes: (
    { __typename?: 'SelectCodesResponse' }
    & Pick<GqlSelectCodesResponse, 'resultCd' | 'resultMsg' | 'resultDt'>
    & { data: (
      { __typename?: 'SelectCodesData' }
      & { clsList: Array<(
        { __typename?: 'ClsListItem' }
        & Pick<GqlClsListItem, 'cdCls' | 'cdClsNm'>
        & { dtlList: Array<(
          { __typename?: 'DtlListItem' }
          & Pick<GqlDtlListItem, 'cd' | 'cdNm' | 'cdDesc' | 'srtOrd'>
        )> }
      )> }
    ) }
  ) }
);

export type GqlGetEtiMsNoticeListQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlGetEtiMsNoticeListQuery = (
  { __typename?: 'Query' }
  & { eTIMsNoticeList: (
    { __typename?: 'NoticeResponse' }
    & Pick<GqlNoticeResponse, 'resultCd' | 'resultMsg' | 'resultDt'>
    & { data: (
      { __typename?: 'NoticeData' }
      & { noticeList: Array<(
        { __typename?: 'Notice' }
        & Pick<GqlNotice, 'noticeNo' | 'title' | 'cont' | 'dtlUrl' | 'regrNm' | 'regDt'>
      )> }
    ) }
  ) }
);

export type GqlGetEtimsProductsItemsQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlGetEtimsProductsItemsQuery = (
  { __typename?: 'Query' }
  & { eTIMsItems: (
    { __typename?: 'ItemsResponse' }
    & Pick<GqlItemsResponse, 'resultCd' | 'resultMsg' | 'resultDt'>
    & { data: (
      { __typename?: 'ItemsData' }
      & { itemList: Array<(
        { __typename?: 'Item' }
        & Pick<GqlItem, 'itemCd' | 'itemClsCd' | 'itemNm' | 'qtyUnitCd' | 'taxTyCd' | 'dftPrc' | 'isrcAplcbYn'>
      )> }
    ) }
  ) }
);

export type GqlGetProductItemsForCompositionQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlGetProductItemsForCompositionQuery = (
  { __typename?: 'Query' }
  & { product_items_v2: Maybe<Array<Maybe<(
    { __typename?: 'ProductItemV2GQL' }
    & Pick<GqlProductItemV2Gql, 'product_item_id' | 'product_item_name' | 'product_item_code' | 'etims_code' | 'etims_class_code' | 'etims_quantity_code' | 'etims_packaging_unit' | 'tax_type_code'>
    & { product: Maybe<(
      { __typename?: 'ProductV2GQL' }
      & Pick<GqlProductV2Gql, 'product_name'>
    )> }
  )>>> }
);

export type GqlGetEtimsPurchasesQueryVariables = Exact<{
  sales_dt: Maybe<Scalars['String']>;
  spplr_invc_no: Maybe<Scalars['Int']>;
}>;


export type GqlGetEtimsPurchasesQuery = (
  { __typename?: 'Query' }
  & { eTIMsPurchases: (
    { __typename?: 'PurchasesResponse' }
    & { SalesList: Array<(
      { __typename?: 'GraphPurchase' }
      & Pick<GqlGraphPurchase, 'id' | 'spplr_nm' | 'spplr_invc_no' | 'spplr_tin' | 'spplr_bhf_id' | 'sales_dt'>
      & { item_list: Array<(
        { __typename?: 'GraphPurchaseItem' }
        & Pick<GqlGraphPurchaseItem, 'id' | 'purchase_id' | 'item_cd' | 'item_nm' | 'qty' | 'prc' | 'sply_amt' | 'dc_rt' | 'dc_amt' | 'taxbl_amt' | 'tax_amt' | 'tot_amt'>
      )> }
    )> }
  ) }
);

export type GqlGetEtimsStockMovementListQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlGetEtimsStockMovementListQuery = (
  { __typename?: 'Query' }
  & { eTIMsStockMovementList: (
    { __typename?: 'StockMovementsResponse' }
    & Pick<GqlStockMovementsResponse, 'resultCd' | 'resultMsg' | 'resultDt'>
    & { data: (
      { __typename?: 'StockData' }
      & { stockList: Array<(
        { __typename?: 'Stock' }
        & Pick<GqlStock, 'custBhfId' | 'custTin'>
      )> }
    ) }
  ) }
);

export type GqlGetEtimsStockMovementListByPinQueryVariables = Exact<{
  custTin: Maybe<Scalars['String']>;
}>;


export type GqlGetEtimsStockMovementListByPinQuery = (
  { __typename?: 'Query' }
  & { eTIMsStockMovementList: (
    { __typename?: 'StockMovementsResponse' }
    & Pick<GqlStockMovementsResponse, 'resultCd' | 'resultMsg' | 'resultDt'>
    & { data: (
      { __typename?: 'StockData' }
      & { stockList: Array<(
        { __typename?: 'Stock' }
        & Pick<GqlStock, 'custTin' | 'ocrnDt' | 'totTaxAmt' | 'totItemCnt' | 'totAmt'>
        & { itemList: Array<(
          { __typename?: 'StockItem' }
          & Pick<GqlStockItem, 'itemClsCd' | 'itemNm' | 'qty' | 'prc' | 'itemCd' | 'taxAmt' | 'totAmt'>
        )> }
      )> }
    ) }
  ) }
);

export type GqlVehicleTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlVehicleTypesQuery = (
  { __typename?: 'Query' }
  & { vehicle_types: Maybe<(
    { __typename?: 'VehicleTypeGQLConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'VehicleTypeGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'VehicleTypeGQL' }
        & Pick<GqlVehicleTypeGql, 'vehicle_type_id' | 'vehicle_type_name'>
      )> }
    )>> }
  )> }
);

export type GqlInventory_LocationsQueryVariables = Exact<{
  filter: Maybe<Scalars['GenericScalar']>;
}>;


export type GqlInventory_LocationsQuery = (
  { __typename?: 'Query' }
  & { inventory_locations: Maybe<(
    { __typename?: 'InventoryLocationGQLConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'InventoryLocationGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'InventoryLocationGQL' }
        & Pick<GqlInventoryLocationGql, 'inventory_location_id' | 'inventory_location_name' | 'inventory_location_type'>
      )> }
    )>> }
  )> }
);

export type GqlVehicle_SuppliersQueryVariables = Exact<{
  page: Maybe<Scalars['Int']>;
  filters: Maybe<Scalars['GenericScalar']>;
  per_page: Maybe<Scalars['Int']>;
  search: Maybe<Scalars['String']>;
}>;


export type GqlVehicle_SuppliersQuery = (
  { __typename?: 'Query' }
  & { vehicle_suppliers: Maybe<(
    { __typename?: 'VehicleSupplierGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'VehicleSupplierGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'VehicleSupplierGQL' }
        & Pick<GqlVehicleSupplierGql, 'name' | 'active' | 'date_created' | 'email' | 'vehicle_supplier_id' | 'phone_number' | 'alt_phone_number' | 'country_id' | 'supplier_number'>
        & { country: Maybe<(
          { __typename?: 'CountryGQL' }
          & Pick<GqlCountryGql, 'iso_alpha2_name'>
        )>, vehicles: Maybe<Array<Maybe<(
          { __typename?: 'VehicleGQL' }
          & Pick<GqlVehicleGql, 'vehicle_id'>
        )>>>, payment_accounts: Maybe<Array<Maybe<(
          { __typename?: 'VehicleSupplierPaymentAccountGQL' }
          & Pick<GqlVehicleSupplierPaymentAccountGql, 'vehicle_supplier_id' | 'vehicle_supplier_payment_account_id' | 'account_type' | 'account_name' | 'party' | 'account_number' | 'is_primary'>
        )>>> }
      )> }
    )>> }
  )> }
);

export type GqlCost_ModelQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlCost_ModelQuery = (
  { __typename?: 'Query' }
  & Pick<GqlQuery, 'vehicle_cost_models'>
);

export type GqlVehicle_Rejection_ReasonsQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlVehicle_Rejection_ReasonsQuery = (
  { __typename?: 'Query' }
  & Pick<GqlQuery, 'vehicle_rejection_reasons'>
);

export type GqlSupplierVehiclesQueryVariables = Exact<{
  search: Maybe<Scalars['String']>;
  per_page: Maybe<Scalars['Int']>;
  page: Maybe<Scalars['Int']>;
  filters: Maybe<Scalars['GenericScalar']>;
}>;


export type GqlSupplierVehiclesQuery = (
  { __typename?: 'Query' }
  & { vehicles: Maybe<(
    { __typename?: 'VehicleGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'VehicleGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'VehicleGQL' }
        & Pick<GqlVehicleGql, 'vehicle_id' | 'vehicle_supplier_id' | 'date_created' | 'registration_number' | 'logistics_type' | 'region_id' | 'cost_rate' | 'load_capacity' | 'insurance_expiry_date' | 'service_due_date' | 'capacity_volume' | 'active' | 'vehicle_cost_model' | 'rejection_reason' | 'rejected'>
        & { vehicle_type: Maybe<(
          { __typename?: 'VehicleTypeGQL' }
          & Pick<GqlVehicleTypeGql, 'vehicle_type_name' | 'vehicle_type_id'>
        )>, region: Maybe<(
          { __typename?: 'RegionV2GQL' }
          & Pick<GqlRegionV2Gql, 'region_name'>
        )> }
      )> }
    )>> }
  )> }
);

export type GqlTripsQueryVariables = Exact<{
  page: Maybe<Scalars['Int']>;
  filters: Maybe<Scalars['GenericScalar']>;
  per_page: Maybe<Scalars['Int']>;
  search: Maybe<Scalars['String']>;
}>;


export type GqlTripsQuery = (
  { __typename?: 'Query' }
  & { trips: Maybe<(
    { __typename?: 'TripGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'TripGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'TripGQL' }
        & Pick<GqlTripGql, 'trip_id' | 'active' | 'logistics_type' | 'starting_odometer' | 'closing_odometer' | 'starting_date' | 'end_date' | 'region_id' | 'from_location' | 'to_location' | 'weight' | 'cost_rate' | 'vehicle_cost_model' | 'distance' | 'cost' | 'total_cost' | 'variable_cost' | 'has_statement'>
        & { route_plan: Maybe<Array<Maybe<(
          { __typename?: 'RoutePlanGQL' }
          & Pick<GqlRoutePlanGql, 'route_plan_id' | 'depot_id' | 'route_id'>
          & { depot: Maybe<(
            { __typename?: 'DepotV2GQL' }
            & Pick<GqlDepotV2Gql, 'depot_name'>
          )>, route: Maybe<(
            { __typename?: 'RouteV2GQL' }
            & Pick<GqlRouteV2Gql, 'route_name'>
          )> }
        )>>>, vehicle: Maybe<(
          { __typename?: 'VehicleGQL' }
          & Pick<GqlVehicleGql, 'registration_number' | 'logistics_type' | 'load_capacity' | 'usable_load_capacity'>
          & { vehicle_supplier: Maybe<(
            { __typename?: 'VehicleSupplierGQL' }
            & Pick<GqlVehicleSupplierGql, 'name'>
          )> }
        )>, region: Maybe<(
          { __typename?: 'RegionV2GQL' }
          & Pick<GqlRegionV2Gql, 'region_name'>
        )>, from_inventory_location: Maybe<(
          { __typename?: 'InventoryLocationGQL' }
          & Pick<GqlInventoryLocationGql, 'inventory_location_name'>
        )>, to_inventory_location: Maybe<(
          { __typename?: 'InventoryLocationGQL' }
          & Pick<GqlInventoryLocationGql, 'inventory_location_name'>
        )> }
      )> }
    )>> }
  )> }
);

export type GqlSupplierStatementsQueryVariables = Exact<{
  search: Maybe<Scalars['String']>;
  per_page: Maybe<Scalars['Int']>;
  page: Maybe<Scalars['Int']>;
  filters: Maybe<Scalars['GenericScalar']>;
}>;


export type GqlSupplierStatementsQuery = (
  { __typename?: 'Query' }
  & { vehicle_supplier_statements: Maybe<(
    { __typename?: 'VehicleSupplierStatementGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'VehicleSupplierStatementGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'VehicleSupplierStatementGQL' }
        & Pick<GqlVehicleSupplierStatementGql, 'active' | 'statement_id' | 'statement_number' | 'invoice_amount' | 'vehicle_supplier_id' | 'amount' | 'approval_status' | 'approved_by' | 'date_from' | 'date_to' | 'date_created' | 'created_by' | 'payment_status'>
        & { vehicle_supplier: Maybe<(
          { __typename?: 'VehicleSupplierGQL' }
          & Pick<GqlVehicleSupplierGql, 'name' | 'country_id'>
          & { country: Maybe<(
            { __typename?: 'CountryGQL' }
            & Pick<GqlCountryGql, 'iso_alpha2_code' | 'iso_alpha2_name'>
          )> }
        )>, created_by_user: Maybe<(
          { __typename?: 'UserV2GQL' }
          & Pick<GqlUserV2Gql, 'first_name' | 'last_name'>
        )>, approved_by_user: Maybe<(
          { __typename?: 'UserV2GQL' }
          & Pick<GqlUserV2Gql, 'first_name' | 'last_name'>
        )> }
      )> }
    )>> }
  )> }
);

export type GqlOrdering_WindowsQueryVariables = Exact<{
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  search: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
}>;


export type GqlOrdering_WindowsQuery = (
  { __typename?: 'Query' }
  & { ordering_windows: Maybe<(
    { __typename?: 'OrderingWindowGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'OrderingWindowGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'OrderingWindowGQL' }
        & Pick<GqlOrderingWindowGql, 'ordering_window_id' | 'active' | 'region_id' | 'channel' | 'depot_id'>
        & { region: Maybe<(
          { __typename?: 'RegionV2GQL' }
          & Pick<GqlRegionV2Gql, 'region_name' | 'region_id'>
        )>, depot: Maybe<(
          { __typename?: 'DepotV2GQL' }
          & Pick<GqlDepotV2Gql, 'depot_name'>
        )>, ordering_window_items: Maybe<Array<Maybe<(
          { __typename?: 'OrderingWindowItemGQL' }
          & Pick<GqlOrderingWindowItemGql, 'active' | 'ordering_window_item_id' | 'day' | 'opening_time' | 'closing_time'>
        )>>> }
      )> }
    )>> }
  )> }
);

export type GqlPayment_VisibilitiesQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlPayment_VisibilitiesQuery = (
  { __typename?: 'Query' }
  & { payment_visibilities: Maybe<Array<Maybe<(
    { __typename?: 'PaymentVisibilityGQL' }
    & Pick<GqlPaymentVisibilityGql, 'payment_visibility_id' | 'region_id' | 'channel' | 'visible' | 'active'>
    & { payment_method: Maybe<(
      { __typename?: 'PaymentMethodGQL' }
      & Pick<GqlPaymentMethodGql, 'description' | 'payment_method_id' | 'payment_method_name'>
    )> }
  )>>> }
);

export type GqlPromo_TypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlPromo_TypesQuery = (
  { __typename?: 'Query' }
  & { promotion_types: Maybe<Array<Maybe<(
    { __typename?: 'PromotionTypeGQL' }
    & Pick<GqlPromotionTypeGql, 'promotion_type_id' | 'title' | 'active' | 'background_color'>
  )>>> }
);

export type GqlAdd_PromoMutationVariables = Exact<{
  title: Scalars['String'];
  background_color: Scalars['String'];
}>;


export type GqlAdd_PromoMutation = (
  { __typename?: 'Mutation' }
  & { add_promotion_type: Maybe<(
    { __typename?: 'CreatePromotionType' }
    & { promotion_type: Maybe<(
      { __typename?: 'PromotionTypeGQL' }
      & Pick<GqlPromotionTypeGql, 'promotion_type_id' | 'title' | 'background_color'>
    )> }
  )> }
);

export type GqlUpdate_Promotion_TypeMutationVariables = Exact<{
  promotion_type_id: Scalars['String'];
  data: GqlPromotionTypeInput;
}>;


export type GqlUpdate_Promotion_TypeMutation = (
  { __typename?: 'Mutation' }
  & { update_promotion_type: Maybe<(
    { __typename?: 'UpdatePromotionType' }
    & { promotion_type: Maybe<(
      { __typename?: 'PromotionTypeGQL' }
      & Pick<GqlPromotionTypeGql, 'promotion_type_id' | 'title' | 'background_color'>
    )> }
  )> }
);

export type GqlProduct_Items_With_TagsQueryVariables = Exact<{
  filters: Maybe<Scalars['GenericScalar']>;
}>;


export type GqlProduct_Items_With_TagsQuery = (
  { __typename?: 'Query' }
  & { product_items_with_tags: Maybe<Array<Maybe<(
    { __typename?: 'PromotionTagGQL' }
    & Pick<GqlPromotionTagGql, 'promotion_tag_id' | 'promotion_type_id' | 'active' | 'region_ids' | 'start_date' | 'end_date' | 'product_item_id'>
    & { promotion_type: Maybe<(
      { __typename?: 'PromotionTypeGQL' }
      & Pick<GqlPromotionTypeGql, 'title' | 'background_color'>
    )>, product_item: Maybe<(
      { __typename?: 'ProductItemV2GQL' }
      & Pick<GqlProductItemV2Gql, 'product_item_name'>
      & { product: Maybe<(
        { __typename?: 'ProductV2GQL' }
        & Pick<GqlProductV2Gql, 'product_name'>
      )> }
    )> }
  )>>> }
);

export type GqlPromotion_TypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlPromotion_TypesQuery = (
  { __typename?: 'Query' }
  & { promotion_types: Maybe<Array<Maybe<(
    { __typename?: 'PromotionTypeGQL' }
    & Pick<GqlPromotionTypeGql, 'promotion_type_id' | 'title'>
  )>>> }
);

export type GqlAdd_Promotion_TagMutationVariables = Exact<{
  product_item_id: Scalars['String'];
  promotion_type_id: Maybe<Scalars['String']>;
  region_ids: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  start_date: Maybe<Scalars['String']>;
  end_date: Maybe<Scalars['String']>;
  meta: Maybe<Scalars['GenericScalar']>;
  country_id: Scalars['String'];
}>;


export type GqlAdd_Promotion_TagMutation = (
  { __typename?: 'Mutation' }
  & { add_promotion_tag: Maybe<(
    { __typename?: 'CreatePromotionTag' }
    & Pick<GqlCreatePromotionTag, 'created'>
    & { promotion_tag: Maybe<(
      { __typename?: 'PromotionTagGQL' }
      & Pick<GqlPromotionTagGql, 'promotion_tag_id' | 'product_item_id' | 'region_ids' | 'start_date' | 'end_date'>
    )> }
  )> }
);

export type GqlUpdate_Promotion_TagMutationVariables = Exact<{
  promotion_tag_id: Scalars['String'];
  data: GqlPromotionTagInput;
}>;


export type GqlUpdate_Promotion_TagMutation = (
  { __typename?: 'Mutation' }
  & { update_promotion_tag: Maybe<(
    { __typename?: 'UpdatePromotionTag' }
    & { promotion_tag: Maybe<(
      { __typename?: 'PromotionTagGQL' }
      & Pick<GqlPromotionTagGql, 'promotion_tag_id' | 'product_item_id' | 'region_ids' | 'start_date' | 'end_date'>
    )> }
  )> }
);

export type GqlUserFeedbackQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlUserFeedbackQuery = (
  { __typename?: 'Query' }
  & { agent_feedback: Maybe<(
    { __typename?: 'AgentFeedbackGQLConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'AgentFeedbackGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'AgentFeedbackGQL' }
        & Pick<GqlAgentFeedbackGql, 'client_stamp'>
      )> }
    )>> }
  )> }
);

export type GqlBase_PricingQueryVariables = Exact<{
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  search: Maybe<Scalars['String']>;
  regionId: Scalars['String'];
  filters: Maybe<Scalars['GenericScalar']>;
}>;


export type GqlBase_PricingQuery = (
  { __typename?: 'Query' }
  & { paginated_product_items_v2: Maybe<(
    { __typename?: 'ProductItemV2GQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'ProductItemV2GQLEdge' }
      & { node: Maybe<(
        { __typename?: 'ProductItemV2GQL' }
        & Pick<GqlProductItemV2Gql, 'product_item_id' | 'product_item_name'>
        & { product: Maybe<(
          { __typename?: 'ProductV2GQL' }
          & Pick<GqlProductV2Gql, 'product_name'>
        )>, units_of_measure: Maybe<Array<Maybe<(
          { __typename?: 'ProductItemUOMV2GQL' }
          & Pick<GqlProductItemUomv2Gql, 'business_domain_id'>
          & { business_domain: Maybe<(
            { __typename?: 'BusinessDomainV2GQL' }
            & Pick<GqlBusinessDomainV2Gql, 'domain_name'>
          )>, uom: Maybe<(
            { __typename?: 'UOMV2GQL' }
            & Pick<GqlUomv2Gql, 'uom_id' | 'name'>
          )> }
        )>>>, current_pricing_v2: Maybe<(
          { __typename?: 'PricingV2GQL' }
          & Pick<GqlPricingV2Gql, 'unit_of_measure_id' | 'base_price' | 'currency' | 'rrp' | 'pricing_date' | 'region_id'>
        )> }
      )> }
    )>> }
  )> }
);

export type GqlPricingQueryVariables = Exact<{
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filters: Maybe<Scalars['GenericScalar']>;
}>;


export type GqlPricingQuery = (
  { __typename?: 'Query' }
  & { pricing_v2: Maybe<(
    { __typename?: 'PricingV2GQLConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'PricingV2GQLEdge' }
      & { node: Maybe<(
        { __typename?: 'PricingV2GQL' }
        & Pick<GqlPricingV2Gql, 'date_updated' | 'updated_by' | 'base_price' | 'currency' | 'rrp'>
        & { user: Maybe<(
          { __typename?: 'UserV2GQL' }
          & Pick<GqlUserV2Gql, 'first_name' | 'last_name'>
        )> }
      )> }
    )>> }
  )> }
);

export type GqlProduct_Departments_V2QueryVariables = Exact<{ [key: string]: never; }>;


export type GqlProduct_Departments_V2Query = (
  { __typename?: 'Query' }
  & { product_departments_v2: Maybe<Array<Maybe<(
    { __typename?: 'DepartmentV2GQL' }
    & Pick<GqlDepartmentV2Gql, 'department_id' | 'department_name'>
  )>>> }
);

export type GqlPaginated_Products_V2QueryVariables = Exact<{
  search: Maybe<Scalars['String']>;
}>;


export type GqlPaginated_Products_V2Query = (
  { __typename?: 'Query' }
  & { paginated_products_v2: Maybe<(
    { __typename?: 'ProductV2GQLConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'ProductV2GQLEdge' }
      & { node: Maybe<(
        { __typename?: 'ProductV2GQL' }
        & Pick<GqlProductV2Gql, 'product_id' | 'product_name' | 'product_type'>
        & { product_items: Maybe<Array<Maybe<(
          { __typename?: 'ProductItemV2GQL' }
          & Pick<GqlProductItemV2Gql, 'product_item_id' | 'product_item_name'>
        )>>> }
      )> }
    )>> }
  )> }
);

export type GqlAdd_CategoryMutationVariables = Exact<{
  data: GqlCategoryInput;
}>;


export type GqlAdd_CategoryMutation = (
  { __typename?: 'Mutation' }
  & { add_category: Maybe<(
    { __typename?: 'CreateCategory' }
    & Pick<GqlCreateCategory, 'created'>
    & { category: Maybe<(
      { __typename?: 'CategoryV2GQL' }
      & Pick<GqlCategoryV2Gql, 'category_id' | 'category_name' | 'department_id'>
    )> }
  )> }
);

export type GqlProduct_CategoriesQueryVariables = Exact<{
  search: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
}>;


export type GqlProduct_CategoriesQuery = (
  { __typename?: 'Query' }
  & { product_categories_v2: Maybe<Array<Maybe<(
    { __typename?: 'CategoryV2GQL' }
    & Pick<GqlCategoryV2Gql, 'category_id' | 'category_name' | 'image_url' | 'banner_image_url' | 'date_updated' | 'active' | 'total_products' | 'department_id'>
    & { products: Maybe<Array<Maybe<(
      { __typename?: 'ProductV2GQL' }
      & Pick<GqlProductV2Gql, 'product_id' | 'product_name'>
    )>>>, sub_categories: Maybe<Array<Maybe<(
      { __typename?: 'CategoryV2GQL' }
      & Pick<GqlCategoryV2Gql, 'category_id' | 'category_name' | 'image_url' | 'active' | 'total_products'>
    )>>>, department: Maybe<(
      { __typename?: 'DepartmentV2GQL' }
      & Pick<GqlDepartmentV2Gql, 'department_id' | 'department_name'>
    )> }
  )>>> }
);

export type GqlUpdate_CategoryMutationVariables = Exact<{
  category_id: Scalars['String'];
  data: GqlCategoryInput;
}>;


export type GqlUpdate_CategoryMutation = (
  { __typename?: 'Mutation' }
  & { update_category: Maybe<(
    { __typename?: 'UpdateCategory' }
    & { category: Maybe<(
      { __typename?: 'CategoryV2GQL' }
      & Pick<GqlCategoryV2Gql, 'category_id' | 'active'>
    )> }
  )> }
);

export type GqlUpload_Category_ImageMutationVariables = Exact<{
  image: Scalars['Upload'];
  category_id: Scalars['String'];
}>;


export type GqlUpload_Category_ImageMutation = (
  { __typename?: 'Mutation' }
  & { upload_category_image: Maybe<(
    { __typename?: 'UploadCategoryImage' }
    & Pick<GqlUploadCategoryImage, 'uploaded'>
    & { category: Maybe<(
      { __typename?: 'CategoryV2GQL' }
      & Pick<GqlCategoryV2Gql, 'image_url'>
    )> }
  )> }
);

export type GqlProduct_Category_V2_By_IdQueryVariables = Exact<{
  category_id: Scalars['String'];
}>;


export type GqlProduct_Category_V2_By_IdQuery = (
  { __typename?: 'Query' }
  & { product_category_v2_by_id: Maybe<(
    { __typename?: 'CategoryV2GQL' }
    & Pick<GqlCategoryV2Gql, 'category_id' | 'category_name' | 'image_url' | 'active' | 'parent_id' | 'ordering_window' | 'department_id' | 'total_regions'>
    & { department: Maybe<(
      { __typename?: 'DepartmentV2GQL' }
      & Pick<GqlDepartmentV2Gql, 'department_name' | 'department_id'>
    )>, products: Maybe<Array<Maybe<(
      { __typename?: 'ProductV2GQL' }
      & Pick<GqlProductV2Gql, 'product_id' | 'product_name'>
    )>>>, regions: Maybe<Array<Maybe<(
      { __typename?: 'CategoryRegionV2GQL' }
      & Pick<GqlCategoryRegionV2Gql, 'active' | 'category_region_id' | 'region_id'>
      & { region: Maybe<(
        { __typename?: 'RegionV2GQL' }
        & Pick<GqlRegionV2Gql, 'region_id' | 'region_name'>
      )> }
    )>>> }
  )> }
);

export type GqlAdd_Category_RegionMutationVariables = Exact<{
  category_id: Scalars['String'];
  region_id: Scalars['String'];
}>;


export type GqlAdd_Category_RegionMutation = (
  { __typename?: 'Mutation' }
  & { add_category_region: Maybe<(
    { __typename?: 'CreateCategoryRegion' }
    & Pick<GqlCreateCategoryRegion, 'created'>
    & { category_region: Maybe<(
      { __typename?: 'CategoryRegionV2GQL' }
      & Pick<GqlCategoryRegionV2Gql, 'category_region_id' | 'category_id' | 'region_id'>
    )> }
  )> }
);

export type GqlActivate_Category_RegionMutationVariables = Exact<{
  data: GqlCategoryRegionInput;
  category_region_id: Scalars['String'];
}>;


export type GqlActivate_Category_RegionMutation = (
  { __typename?: 'Mutation' }
  & { update_category_region: Maybe<(
    { __typename?: 'UpdateCategoryRegion' }
    & { category_region: Maybe<(
      { __typename?: 'CategoryRegionV2GQL' }
      & Pick<GqlCategoryRegionV2Gql, 'category_region_id' | 'category_id' | 'region_id'>
    )> }
  )> }
);

export type GqlProductDepartmentsv2QueryVariables = Exact<{
  filters: Maybe<Scalars['GenericScalar']>;
  filter_string: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
}>;


export type GqlProductDepartmentsv2Query = (
  { __typename?: 'Query' }
  & { product_departments_v2: Maybe<Array<Maybe<(
    { __typename?: 'DepartmentV2GQL' }
    & Pick<GqlDepartmentV2Gql, 'department_id' | 'department_name' | 'description' | 'slug' | 'active'>
  )>>> }
);

export type GqlAddDepartmentMutationVariables = Exact<{
  department_name: Scalars['String'];
  description: Maybe<Scalars['String']>;
}>;


export type GqlAddDepartmentMutation = (
  { __typename?: 'Mutation' }
  & { add_department: Maybe<(
    { __typename?: 'CreateDepartment' }
    & Pick<GqlCreateDepartment, 'created'>
    & { department: Maybe<(
      { __typename?: 'DepartmentV2GQL' }
      & Pick<GqlDepartmentV2Gql, 'department_id' | 'department_name'>
    )> }
  )> }
);

export type GqlUpdateDepartmentMutationVariables = Exact<{
  department_id: Scalars['String'];
  data: GqlDepartmentInput;
}>;


export type GqlUpdateDepartmentMutation = (
  { __typename?: 'Mutation' }
  & { update_department: Maybe<(
    { __typename?: 'UpdateDepartment' }
    & { department: Maybe<(
      { __typename?: 'DepartmentV2GQL' }
      & Pick<GqlDepartmentV2Gql, 'department_id' | 'department_name' | 'description' | 'slug'>
    )> }
  )> }
);

export type GqlAdd_GeofencingMutationVariables = Exact<{
  product_item_id: Scalars['String'];
  whitelisted_routes: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  blacklisted_routes: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
}>;


export type GqlAdd_GeofencingMutation = (
  { __typename?: 'Mutation' }
  & { update_product_item: Maybe<(
    { __typename?: 'UpdateProductItem' }
    & { product_item: Maybe<(
      { __typename?: 'ProductItemV2GQL' }
      & Pick<GqlProductItemV2Gql, 'product_item_id' | 'product_item_name' | 'whitelisted_routes' | 'blacklisted_routes' | 'active'>
    )> }
  )> }
);

export type GqlDelete_GeofencingMutationVariables = Exact<{
  product_item_id: Scalars['String'];
}>;


export type GqlDelete_GeofencingMutation = (
  { __typename?: 'Mutation' }
  & { update_product_item: Maybe<(
    { __typename?: 'UpdateProductItem' }
    & { product_item: Maybe<(
      { __typename?: 'ProductItemV2GQL' }
      & Pick<GqlProductItemV2Gql, 'product_item_id' | 'product_item_name' | 'whitelisted_routes' | 'blacklisted_routes'>
    )> }
  )> }
);

export type GqlRoutes_V2QueryVariables = Exact<{
  filters: Maybe<Scalars['GenericScalar']>;
  search: Maybe<Scalars['String']>;
}>;


export type GqlRoutes_V2Query = (
  { __typename?: 'Query' }
  & { routes_v2: Maybe<(
    { __typename?: 'RouteV2GQLConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'RouteV2GQLEdge' }
      & { node: Maybe<(
        { __typename?: 'RouteV2GQL' }
        & Pick<GqlRouteV2Gql, 'route_id' | 'route_name' | 'depot_id'>
        & { depot: Maybe<(
          { __typename?: 'DepotV2GQL' }
          & Pick<GqlDepotV2Gql, 'depot_name'>
          & { area: Maybe<(
            { __typename?: 'AreaV2GQL' }
            & Pick<GqlAreaV2Gql, 'area_id' | 'region_id'>
            & { region: Maybe<(
              { __typename?: 'RegionV2GQL' }
              & Pick<GqlRegionV2Gql, 'region_name'>
            )> }
          )> }
        )> }
      )> }
    )>> }
  )> }
);

export type GqlRouteV2ByIdQueryVariables = Exact<{
  route_id: Maybe<Scalars['String']>;
}>;


export type GqlRouteV2ByIdQuery = (
  { __typename?: 'Query' }
  & { route_v2_by_id: Maybe<(
    { __typename?: 'RouteV2GQL' }
    & Pick<GqlRouteV2Gql, 'route_id' | 'route_name' | 'depot_id'>
    & { depot: Maybe<(
      { __typename?: 'DepotV2GQL' }
      & Pick<GqlDepotV2Gql, 'depot_name'>
      & { area: Maybe<(
        { __typename?: 'AreaV2GQL' }
        & Pick<GqlAreaV2Gql, 'area_id' | 'region_id'>
        & { region: Maybe<(
          { __typename?: 'RegionV2GQL' }
          & Pick<GqlRegionV2Gql, 'region_name'>
        )> }
      )> }
    )> }
  )> }
);

export type GqlPackaging_Units_V2QueryVariables = Exact<{ [key: string]: never; }>;


export type GqlPackaging_Units_V2Query = (
  { __typename?: 'Query' }
  & { packaging_units_v2: Maybe<(
    { __typename?: 'PackagingUnitV2GQLConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'PackagingUnitV2GQLEdge' }
      & { node: Maybe<(
        { __typename?: 'PackagingUnitV2GQL' }
        & Pick<GqlPackagingUnitV2Gql, 'name' | 'active' | 'packaging_unit_id' | 'returnable' | 'weight'>
      )> }
    )>> }
  )> }
);

export type GqlAdd_Packaging_UnitMutationVariables = Exact<{
  name: Scalars['String'];
  weight: Scalars['Float'];
  returnable: Maybe<Scalars['Boolean']>;
  etims_packaging_code: Scalars['String'];
}>;


export type GqlAdd_Packaging_UnitMutation = (
  { __typename?: 'Mutation' }
  & { add_packaging_unit: Maybe<(
    { __typename?: 'CreatePackagingUnit' }
    & Pick<GqlCreatePackagingUnit, 'created'>
    & { packaging_unit: Maybe<(
      { __typename?: 'PackagingUnitV2GQL' }
      & Pick<GqlPackagingUnitV2Gql, 'packaging_unit_id' | 'name' | 'created_by' | 'date_created' | 'etims_packaging_code' | 'date_updated'>
    )> }
  )> }
);

export type GqlUpdate_Packaging_UnitMutationVariables = Exact<{
  data: GqlPackagingUnitInput;
  packaging_unit_id: Scalars['String'];
}>;


export type GqlUpdate_Packaging_UnitMutation = (
  { __typename?: 'Mutation' }
  & { update_packaging_unit: Maybe<(
    { __typename?: 'UpdatePackagingUnit' }
    & { packaging_unit: Maybe<(
      { __typename?: 'PackagingUnitV2GQL' }
      & Pick<GqlPackagingUnitV2Gql, 'packaging_unit_id' | 'returnable' | 'active' | 'name' | 'weight'>
    )> }
  )> }
);

export type GqlAdd_Product_ItemMutationVariables = Exact<{
  data: GqlProductItemInput;
}>;


export type GqlAdd_Product_ItemMutation = (
  { __typename?: 'Mutation' }
  & { add_product_item: Maybe<(
    { __typename?: 'CreateProductItem' }
    & Pick<GqlCreateProductItem, 'created'>
    & { product_item: Maybe<(
      { __typename?: 'ProductItemV2GQL' }
      & Pick<GqlProductItemV2Gql, 'product_item_id'>
      & { units_of_measure: Maybe<Array<Maybe<(
        { __typename?: 'ProductItemUOMV2GQL' }
        & Pick<GqlProductItemUomv2Gql, 'uom_id' | 'business_domain_id'>
      )>>>, regions: Maybe<Array<Maybe<(
        { __typename?: 'ProductItemRegionV2GQL' }
        & Pick<GqlProductItemRegionV2Gql, 'business_domain_id' | 'region_id'>
      )>>> }
    )> }
  )> }
);

export type GqlAdd_Product_Item_ImageMutationVariables = Exact<{
  image: Scalars['Upload'];
  product_item_id: Scalars['String'];
}>;


export type GqlAdd_Product_Item_ImageMutation = (
  { __typename?: 'Mutation' }
  & { add_product_item_image: Maybe<(
    { __typename?: 'UploadProductItemImage' }
    & Pick<GqlUploadProductItemImage, 'uploaded'>
    & { product_item: Maybe<(
      { __typename?: 'ProductItemV2GQL' }
      & Pick<GqlProductItemV2Gql, 'product_item_id' | 'product_item_name'>
    )> }
  )> }
);

export type GqlProduct_Item_SegmentsQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlProduct_Item_SegmentsQuery = (
  { __typename?: 'Query' }
  & { product_item_segments_v2: Maybe<Array<Maybe<(
    { __typename?: 'ProductItemSegmentV2GQL' }
    & Pick<GqlProductItemSegmentV2Gql, 'segment_name' | 'product_item_segment_id'>
  )>>> }
);

export type GqlQuantityUnitCodesQueryVariables = Exact<{
  filters: Maybe<GqlEtiMsCodeFilters>;
}>;


export type GqlQuantityUnitCodesQuery = (
  { __typename?: 'Query' }
  & { eTIMsCodes: (
    { __typename?: 'SelectCodesResponse' }
    & { data: (
      { __typename?: 'SelectCodesData' }
      & { clsList: Array<(
        { __typename?: 'ClsListItem' }
        & Pick<GqlClsListItem, 'cdClsNm' | 'cdCls'>
        & { dtlList: Array<(
          { __typename?: 'DtlListItem' }
          & Pick<GqlDtlListItem, 'cd' | 'cdNm'>
        )> }
      )> }
    ) }
  ) }
);

export type GqlProduct_Item_V2_By_IdQueryVariables = Exact<{
  product_item_id: Scalars['String'];
  filters: Maybe<Scalars['GenericScalar']>;
}>;


export type GqlProduct_Item_V2_By_IdQuery = (
  { __typename?: 'Query' }
  & { product_item_v2_by_id: Maybe<(
    { __typename?: 'ProductItemV2GQL' }
    & Pick<GqlProductItemV2Gql, 'synced_to_etims' | 'etims_code' | 'etims_class_code' | 'country_of_origin_code' | 'etims_packaging_unit' | 'etims_quantity_code' | 'tax_type_code' | 'product_item_id' | 'product_item_name' | 'active' | 'image_url' | 'base_price' | 'total_regions' | 'packed_weight' | 'packed_volume' | 'description' | 'wms_default_available' | 'wms_std_pallet_quantity' | 'wms_pickface_putaway' | 'date_created' | 'item_sequence' | 'product_id' | 'product_item_segment_id'>
    & { product: Maybe<(
      { __typename?: 'ProductV2GQL' }
      & Pick<GqlProductV2Gql, 'product_name'>
      & { packaging_units: Maybe<Array<Maybe<(
        { __typename?: 'PackagingUnitV2GQL' }
        & Pick<GqlPackagingUnitV2Gql, 'etims_packaging_code'>
      )>>> }
    )>, product_item_segment: Maybe<(
      { __typename?: 'ProductItemSegmentV2GQL' }
      & Pick<GqlProductItemSegmentV2Gql, 'segment_name'>
    )>, units_of_measure: Maybe<Array<Maybe<(
      { __typename?: 'ProductItemUOMV2GQL' }
      & Pick<GqlProductItemUomv2Gql, 'business_domain_id' | 'active' | 'conversion_ratio' | 'product_item_uom_id' | 'product_item_id' | 'uom_id'>
      & { business_domain: Maybe<(
        { __typename?: 'BusinessDomainV2GQL' }
        & Pick<GqlBusinessDomainV2Gql, 'domain_name'>
      )>, uom: Maybe<(
        { __typename?: 'UOMV2GQL' }
        & Pick<GqlUomv2Gql, 'etims_quantity_code' | 'name' | 'uom_id'>
      )> }
    )>>>, regions: Maybe<Array<Maybe<(
      { __typename?: 'ProductItemRegionV2GQL' }
      & Pick<GqlProductItemRegionV2Gql, 'region_id' | 'product_item_region_id' | 'business_domain_id' | 'active'>
      & { region: Maybe<(
        { __typename?: 'RegionV2GQL' }
        & Pick<GqlRegionV2Gql, 'region_id' | 'region_name'>
      )>, business_domain: Maybe<(
        { __typename?: 'BusinessDomainV2GQL' }
        & Pick<GqlBusinessDomainV2Gql, 'domain_name'>
      )> }
    )>>>, dynamic_price: Maybe<(
      { __typename?: 'DynamicPriceGQL' }
      & Pick<GqlDynamicPriceGql, 'discount_value'>
    )> }
  )> }
);

export type GqlAdd_Product_Item_UomMutationVariables = Exact<{
  data: GqlProductItemUomInput;
}>;


export type GqlAdd_Product_Item_UomMutation = (
  { __typename?: 'Mutation' }
  & { add_product_item_uom: Maybe<(
    { __typename?: 'CreateProductItemUOM' }
    & { product_item_uom: Maybe<(
      { __typename?: 'ProductItemUOMV2GQL' }
      & Pick<GqlProductItemUomv2Gql, 'product_item_uom_id' | 'product_item_id' | 'business_domain_id' | 'conversion_ratio'>
    )> }
  )> }
);

export type GqlUpdate_Product_Item_UomMutationVariables = Exact<{
  product_item_uom_id: Scalars['String'];
  uom_id: Scalars['String'];
  product_item_id: Scalars['String'];
  business_domain_id: Scalars['String'];
  data: GqlProductItemUomInput;
}>;


export type GqlUpdate_Product_Item_UomMutation = (
  { __typename?: 'Mutation' }
  & { update_product_item_uom: Maybe<(
    { __typename?: 'UpdateProductItemUOM' }
    & { product_item_uom: Maybe<(
      { __typename?: 'ProductItemUOMV2GQL' }
      & Pick<GqlProductItemUomv2Gql, 'product_item_uom_id' | 'active'>
    )> }
  )> }
);

export type GqlAdd_Product_Item_RegionMutationVariables = Exact<{
  data: GqlProductItemRegionInput;
}>;


export type GqlAdd_Product_Item_RegionMutation = (
  { __typename?: 'Mutation' }
  & { add_product_item_region: Maybe<(
    { __typename?: 'CreateProductItemRegion' }
    & Pick<GqlCreateProductItemRegion, 'created'>
    & { product_item_region: Maybe<(
      { __typename?: 'ProductItemRegionV2GQL' }
      & Pick<GqlProductItemRegionV2Gql, 'region_id' | 'product_item_id' | 'business_domain_id'>
    )> }
  )> }
);

export type GqlActivate_Product_Item_RegionMutationVariables = Exact<{
  active: Scalars['Boolean'];
  product_item_region_id: Scalars['String'];
}>;


export type GqlActivate_Product_Item_RegionMutation = (
  { __typename?: 'Mutation' }
  & { update_product_item_region: Maybe<(
    { __typename?: 'UpdateProductItemRegion' }
    & { product_item_region: Maybe<(
      { __typename?: 'ProductItemRegionV2GQL' }
      & Pick<GqlProductItemRegionV2Gql, 'product_item_id' | 'active'>
    )> }
  )> }
);

export type GqlBusiness_Domains_V2QueryVariables = Exact<{ [key: string]: never; }>;


export type GqlBusiness_Domains_V2Query = (
  { __typename?: 'Query' }
  & { business_domains_v2: Maybe<Array<Maybe<(
    { __typename?: 'BusinessDomainV2GQL' }
    & Pick<GqlBusinessDomainV2Gql, 'business_domain_id' | 'domain_name'>
  )>>> }
);

export type GqlPaginated_Product_ItemsQueryVariables = Exact<{
  filters: Maybe<Scalars['GenericScalar']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
}>;


export type GqlPaginated_Product_ItemsQuery = (
  { __typename?: 'Query' }
  & { paginated_product_items_v2: Maybe<(
    { __typename?: 'ProductItemV2GQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & Pick<GqlPaginatorInfo, 'total_pages' | 'total_count' | 'current_page' | 'per_page' | 'has_previous' | 'has_next'>
    )>, edges: Array<Maybe<(
      { __typename?: 'ProductItemV2GQLEdge' }
      & { node: Maybe<(
        { __typename?: 'ProductItemV2GQL' }
        & Pick<GqlProductItemV2Gql, 'active' | 'product_item_id' | 'product_item_name' | 'product_id' | 'image_url' | 'staff_only' | 'etims_class_code' | 'etims_code' | 'country_of_origin_code' | 'tax_type_code' | 'packed_weight' | 'packed_volume' | 'featured' | 'product_item_segment_id' | 'blacklisted_routes' | 'whitelisted_routes'>
        & { product: Maybe<(
          { __typename?: 'ProductV2GQL' }
          & Pick<GqlProductV2Gql, 'product_name'>
        )>, product_item_segment: Maybe<(
          { __typename?: 'ProductItemSegmentV2GQL' }
          & Pick<GqlProductItemSegmentV2Gql, 'segment_name'>
        )>, blacklisted: Maybe<Array<Maybe<(
          { __typename?: 'BlacklistedRoutesGQL' }
          & Pick<GqlBlacklistedRoutesGql, 'route_id'>
          & { route: Maybe<(
            { __typename?: 'RouteV2GQL' }
            & Pick<GqlRouteV2Gql, 'route_id' | 'route_name' | 'depot_id'>
            & { depot: Maybe<(
              { __typename?: 'DepotV2GQL' }
              & Pick<GqlDepotV2Gql, 'depot_name'>
              & { area: Maybe<(
                { __typename?: 'AreaV2GQL' }
                & Pick<GqlAreaV2Gql, 'area_id' | 'region_id'>
                & { region: Maybe<(
                  { __typename?: 'RegionV2GQL' }
                  & Pick<GqlRegionV2Gql, 'region_name'>
                )> }
              )> }
            )> }
          )> }
        )>>>, whitelisted: Maybe<Array<Maybe<(
          { __typename?: 'WhitelistedRoutesGQL' }
          & Pick<GqlWhitelistedRoutesGql, 'route_id'>
          & { route: Maybe<(
            { __typename?: 'RouteV2GQL' }
            & Pick<GqlRouteV2Gql, 'route_id' | 'route_name' | 'depot_id'>
            & { depot: Maybe<(
              { __typename?: 'DepotV2GQL' }
              & Pick<GqlDepotV2Gql, 'depot_name'>
              & { area: Maybe<(
                { __typename?: 'AreaV2GQL' }
                & Pick<GqlAreaV2Gql, 'area_id' | 'region_id'>
                & { region: Maybe<(
                  { __typename?: 'RegionV2GQL' }
                  & Pick<GqlRegionV2Gql, 'region_name'>
                )> }
              )> }
            )> }
          )> }
        )>>> }
      )> }
    )>> }
  )> }
);

export type GqlUpdate_Product_ItemMutationVariables = Exact<{
  product_item_id: Scalars['String'];
  data: GqlProductItemInput;
}>;


export type GqlUpdate_Product_ItemMutation = (
  { __typename?: 'Mutation' }
  & { update_product_item: Maybe<(
    { __typename?: 'UpdateProductItem' }
    & { product_item: Maybe<(
      { __typename?: 'ProductItemV2GQL' }
      & Pick<GqlProductItemV2Gql, 'product_item_id' | 'active'>
    )> }
  )> }
);

export type GqlPaginatedProductsV2QueryVariables = Exact<{
  per_page: Maybe<Scalars['Int']>;
  page: Maybe<Scalars['Int']>;
  active: Maybe<Scalars['Boolean']>;
  search: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
}>;


export type GqlPaginatedProductsV2Query = (
  { __typename?: 'Query' }
  & { paginated_products_v2: Maybe<(
    { __typename?: 'ProductV2GQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & Pick<GqlPaginatorInfo, 'per_page' | 'total_count' | 'total_pages' | 'has_next' | 'has_previous'>
    )>, edges: Array<Maybe<(
      { __typename?: 'ProductV2GQLEdge' }
      & { node: Maybe<(
        { __typename?: 'ProductV2GQL' }
        & Pick<GqlProductV2Gql, 'active' | 'product_id' | 'product_name' | 'product_code' | 'hs_code' | 'kra_code' | 'vatable' | 'product_type' | 'image_url' | 'date_created'>
        & { category: Maybe<(
          { __typename?: 'CategoryV2GQL' }
          & Pick<GqlCategoryV2Gql, 'category_name'>
        )> }
      )> }
    )>> }
  )> }
);

export type GqlAddProductMutationVariables = Exact<{
  data: GqlProductInput;
}>;


export type GqlAddProductMutation = (
  { __typename?: 'Mutation' }
  & { add_product: Maybe<(
    { __typename?: 'CreateProduct' }
    & Pick<GqlCreateProduct, 'created'>
    & { product: Maybe<(
      { __typename?: 'ProductV2GQL' }
      & Pick<GqlProductV2Gql, 'product_id' | 'product_code' | 'category_id' | 'wms_shelf_life_days' | 'hs_code' | 'kra_code'>
      & { product_tags: Maybe<Array<Maybe<(
        { __typename?: 'ProductTagV2GQL' }
        & Pick<GqlProductTagV2Gql, 'product_tag' | 'product_tag_id' | 'product_id'>
      )>>>, category: Maybe<(
        { __typename?: 'CategoryV2GQL' }
        & Pick<GqlCategoryV2Gql, 'category_name'>
      )>, categories: Maybe<Array<Maybe<(
        { __typename?: 'CategoryV2GQL' }
        & Pick<GqlCategoryV2Gql, 'category_name'>
      )>>> }
    )> }
  )> }
);

export type GqlUpdateProductMutationVariables = Exact<{
  product_id: Scalars['String'];
  data: GqlProductInput;
}>;


export type GqlUpdateProductMutation = (
  { __typename?: 'Mutation' }
  & { update_product: Maybe<(
    { __typename?: 'UpdateProduct' }
    & { product: Maybe<(
      { __typename?: 'ProductV2GQL' }
      & Pick<GqlProductV2Gql, 'product_id' | 'active' | 'product_code' | 'product_type' | 'category_id' | 'vatable' | 'wms_shelf_life_days' | 'hs_code' | 'kra_code'>
      & { categories: Maybe<Array<Maybe<(
        { __typename?: 'CategoryV2GQL' }
        & Pick<GqlCategoryV2Gql, 'category_id' | 'category_name'>
      )>>> }
    )> }
  )> }
);

export type GqlProductByIdV2QueryVariables = Exact<{
  product_id: Scalars['String'];
}>;


export type GqlProductByIdV2Query = (
  { __typename?: 'Query' }
  & { product_v2_by_id: Maybe<(
    { __typename?: 'ProductV2GQL' }
    & Pick<GqlProductV2Gql, 'product_id' | 'active' | 'product_type' | 'product_name' | 'product_code' | 'product_description' | 'vatable' | 'image_url' | 'date_created' | 'wms_shelf_life_days' | 'hs_code' | 'kra_code' | 'category_id'>
    & { category: Maybe<(
      { __typename?: 'CategoryV2GQL' }
      & Pick<GqlCategoryV2Gql, 'category_id' | 'category_name'>
    )>, categories: Maybe<Array<Maybe<(
      { __typename?: 'CategoryV2GQL' }
      & Pick<GqlCategoryV2Gql, 'active' | 'category_id' | 'category_name'>
    )>>>, product_tags: Maybe<Array<Maybe<(
      { __typename?: 'ProductTagV2GQL' }
      & Pick<GqlProductTagV2Gql, 'product_tag' | 'product_tag_id' | 'product_id'>
    )>>>, domain_activations: Maybe<Array<Maybe<(
      { __typename?: 'ProductDomainActivationV2GQL' }
      & Pick<GqlProductDomainActivationV2Gql, 'product_domain_activation_id' | 'active'>
      & { business_domain: Maybe<(
        { __typename?: 'BusinessDomainV2GQL' }
        & Pick<GqlBusinessDomainV2Gql, 'domain_name' | 'business_domain_id'>
      )> }
    )>>>, packaging_units: Maybe<Array<Maybe<(
      { __typename?: 'PackagingUnitV2GQL' }
      & Pick<GqlPackagingUnitV2Gql, 'name' | 'active' | 'packaging_unit_id'>
    )>>> }
  )> }
);

export type GqlLossReasonsQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlLossReasonsQuery = (
  { __typename?: 'Query' }
  & { loss_reasons: Maybe<Array<Maybe<(
    { __typename?: 'LossReasonGQL' }
    & Pick<GqlLossReasonGql, 'active' | 'loss_reason' | 'loss_reason_id'>
  )>>> }
);

export type GqlProductLossReasonsQueryVariables = Exact<{
  filter: Maybe<Scalars['GenericScalar']>;
}>;


export type GqlProductLossReasonsQuery = (
  { __typename?: 'Query' }
  & { product_loss_reasons: Maybe<(
    { __typename?: 'ProductLossReasonGQLConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'ProductLossReasonGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'ProductLossReasonGQL' }
        & Pick<GqlProductLossReasonGql, 'product_loss_reason_id' | 'loss_reason_id' | 'active'>
        & { loss_reason: Maybe<(
          { __typename?: 'LossReasonGQL' }
          & Pick<GqlLossReasonGql, 'loss_reason'>
        )> }
      )> }
    )>> }
  )> }
);

export type GqlAddProductDomainActivationMutationVariables = Exact<{
  data: GqlProductDomainActivationInput;
}>;


export type GqlAddProductDomainActivationMutation = (
  { __typename?: 'Mutation' }
  & { add_product_domain_activation: Maybe<(
    { __typename?: 'CreateProductDomainActivation' }
    & Pick<GqlCreateProductDomainActivation, 'created'>
  )> }
);

export type GqlUpdateProductDomainActivationMutationVariables = Exact<{
  product_domain_activation_id: Scalars['String'];
  data: GqlProductDomainActivationInput;
}>;


export type GqlUpdateProductDomainActivationMutation = (
  { __typename?: 'Mutation' }
  & { update_product_domain_activation: Maybe<(
    { __typename?: 'UpdateProductDomainActivation' }
    & { product_domain_activation: Maybe<(
      { __typename?: 'ProductDomainActivationV2GQL' }
      & Pick<GqlProductDomainActivationV2Gql, 'product_id' | 'business_domain_id' | 'active'>
    )> }
  )> }
);

export type GqlUploadProductImageMutationVariables = Exact<{
  image: Scalars['Upload'];
  product_id: Scalars['String'];
}>;


export type GqlUploadProductImageMutation = (
  { __typename?: 'Mutation' }
  & { add_product_image: Maybe<(
    { __typename?: 'UploadProductImage' }
    & Pick<GqlUploadProductImage, 'uploaded'>
    & { product: Maybe<(
      { __typename?: 'ProductV2GQL' }
      & Pick<GqlProductV2Gql, 'product_id' | 'image_url'>
    )> }
  )> }
);

export type GqlAddProductTagMutationVariables = Exact<{
  data: GqlProductTagInput;
}>;


export type GqlAddProductTagMutation = (
  { __typename?: 'Mutation' }
  & { add_product_tag: Maybe<(
    { __typename?: 'CreateProductTag' }
    & Pick<GqlCreateProductTag, 'created'>
    & { product_tag: Maybe<(
      { __typename?: 'ProductTagV2GQL' }
      & Pick<GqlProductTagV2Gql, 'product_tag'>
      & { product: Maybe<(
        { __typename?: 'ProductV2GQL' }
        & Pick<GqlProductV2Gql, 'product_name'>
      )> }
    )> }
  )> }
);

export type GqlDeleteProductTagMutationVariables = Exact<{
  product_tag_id: Scalars['String'];
}>;


export type GqlDeleteProductTagMutation = (
  { __typename?: 'Mutation' }
  & { delete_product_tag: Maybe<(
    { __typename?: 'DeleteProductTag' }
    & Pick<GqlDeleteProductTag, 'deleted'>
  )> }
);

export type GqlAddProductBundleMutationVariables = Exact<{
  data: Maybe<Array<Maybe<GqlBundleAssociationInput>> | Maybe<GqlBundleAssociationInput>>;
}>;


export type GqlAddProductBundleMutation = (
  { __typename?: 'Mutation' }
  & { add_bundle_association: Maybe<(
    { __typename?: 'CreateBundleAssociation' }
    & Pick<GqlCreateBundleAssociation, 'created'>
  )> }
);

export type GqlDeleteBundleAssociationMutationVariables = Exact<{
  association_id: Maybe<Scalars['String']>;
  client_stamp: Maybe<Scalars['String']>;
}>;


export type GqlDeleteBundleAssociationMutation = (
  { __typename?: 'Mutation' }
  & { delete_bundle_association: Maybe<(
    { __typename?: 'DeleteBundleAssociation' }
    & Pick<GqlDeleteBundleAssociation, 'deleted'>
  )> }
);

export type GqlGetBundleAssociationQueryVariables = Exact<{
  product_item_id: Scalars['String'];
}>;


export type GqlGetBundleAssociationQuery = (
  { __typename?: 'Query' }
  & { bundle_association_v2_by_sku: Maybe<Array<Maybe<(
    { __typename?: 'BundleAssociationV2GQL' }
    & Pick<GqlBundleAssociationV2Gql, 'association_id' | 'quantity' | 'discount' | 'client_stamp' | 'parent_product_item_id' | 'child_product_item_id'>
    & { parent_sku: Maybe<(
      { __typename?: 'ProductItemV2GQL' }
      & Pick<GqlProductItemV2Gql, 'product_item_name'>
      & { product: Maybe<(
        { __typename?: 'ProductV2GQL' }
        & Pick<GqlProductV2Gql, 'product_name' | 'product_id'>
      )> }
    )>, child_sku: Maybe<(
      { __typename?: 'ProductItemV2GQL' }
      & Pick<GqlProductItemV2Gql, 'product_item_id' | 'product_item_name'>
      & { product: Maybe<(
        { __typename?: 'ProductV2GQL' }
        & Pick<GqlProductV2Gql, 'product_id' | 'product_name'>
      )> }
    )> }
  )>>> }
);

export type GqlUoms_V2QueryVariables = Exact<{
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  search: Maybe<Scalars['String']>;
}>;


export type GqlUoms_V2Query = (
  { __typename?: 'Query' }
  & { uoms_v2: Maybe<(
    { __typename?: 'UOMV2GQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & Pick<GqlPaginatorInfo, 'has_next' | 'has_previous' | 'total_pages' | 'total_count' | 'current_page' | 'per_page'>
    )>, edges: Array<Maybe<(
      { __typename?: 'UOMV2GQLEdge' }
      & { node: Maybe<(
        { __typename?: 'UOMV2GQL' }
        & Pick<GqlUomv2Gql, 'uom_id' | 'name' | 'etims_quantity_code' | 'description' | 'abbreviation' | 'active'>
      )> }
    )>> }
  )> }
);

export type GqlAdd_UomMutationVariables = Exact<{
  name: Scalars['String'];
  abbreviation: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  etims_quantity_code: Maybe<Scalars['String']>;
}>;


export type GqlAdd_UomMutation = (
  { __typename?: 'Mutation' }
  & { add_uom: Maybe<(
    { __typename?: 'CreateUom' }
    & Pick<GqlCreateUom, 'created'>
    & { uom: Maybe<(
      { __typename?: 'UOMV2GQL' }
      & Pick<GqlUomv2Gql, 'uom_id' | 'name' | 'abbreviation' | 'description'>
    )> }
  )> }
);

export type GqlUpdate_UomMutationVariables = Exact<{
  uom_id: Scalars['String'];
  data: GqlUomInput;
}>;


export type GqlUpdate_UomMutation = (
  { __typename?: 'Mutation' }
  & { update_uom: Maybe<(
    { __typename?: 'UpdateUom' }
    & { uom: Maybe<(
      { __typename?: 'UOMV2GQL' }
      & Pick<GqlUomv2Gql, 'uom_id' | 'name' | 'active' | 'abbreviation' | 'description'>
    )> }
  )> }
);

export type GqlBookingSummaryQueryVariables = Exact<{
  search: Maybe<Scalars['String']>;
  per_page: Maybe<Scalars['Int']>;
  page: Maybe<Scalars['Int']>;
  bookingSummaryFilters: Maybe<Scalars['GenericScalar']>;
}>;


export type GqlBookingSummaryQuery = (
  { __typename?: 'Query' }
  & { booking_summary: Maybe<(
    { __typename?: 'BookingSummaryGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'BookingSummaryGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'BookingSummaryGQL' }
        & Pick<GqlBookingSummaryGql, 'harvest_date' | 'booking_date' | 'product_id' | 'product_item_id' | 'supplier_name' | 'unit_price' | 'quantity' | 'weight' | 'amount' | 'created_by' | 'collection_center_id' | 'collection_center_name'>
        & { product_item: Maybe<(
          { __typename?: 'ProductItemV2GQL' }
          & Pick<GqlProductItemV2Gql, 'product_item_name'>
          & { product: Maybe<(
            { __typename?: 'ProductV2GQL' }
            & Pick<GqlProductV2Gql, 'product_name'>
          )> }
        )>, created_by_user: Maybe<(
          { __typename?: 'UserV2GQL' }
          & Pick<GqlUserV2Gql, 'first_name' | 'last_name'>
        )> }
      )> }
    )>> }
  )> }
);

export type GqlBookingSummaryTotalsQueryVariables = Exact<{
  product_id: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  product_item_id: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  harvest_date: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  collection_center_id: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  created_by: Maybe<Scalars['String']>;
}>;


export type GqlBookingSummaryTotalsQuery = (
  { __typename?: 'Query' }
  & { booking_summary_totals: Maybe<(
    { __typename?: 'BookingSummaryTotalsGQL' }
    & Pick<GqlBookingSummaryTotalsGql, 'quantity' | 'weight' | 'amount'>
  )> }
);

export type GqlBookingSummaryUnpaginatedQueryVariables = Exact<{
  product_id: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  product_item_id: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  harvest_date: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  collection_center_id: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
}>;


export type GqlBookingSummaryUnpaginatedQuery = (
  { __typename?: 'Query' }
  & { booking_summary_unpaginated: Maybe<Array<Maybe<(
    { __typename?: 'BookingSummaryGQL' }
    & Pick<GqlBookingSummaryGql, 'harvest_date' | 'booking_date' | 'product_id' | 'product_item_id' | 'unit_price' | 'quantity' | 'weight' | 'amount' | 'created_by' | 'collection_center_id' | 'collection_center_name' | 'supplier_name'>
    & { product_item: Maybe<(
      { __typename?: 'ProductItemV2GQL' }
      & Pick<GqlProductItemV2Gql, 'product_item_name'>
      & { product: Maybe<(
        { __typename?: 'ProductV2GQL' }
        & Pick<GqlProductV2Gql, 'product_name'>
      )> }
    )>, created_by_user: Maybe<(
      { __typename?: 'UserV2GQL' }
      & Pick<GqlUserV2Gql, 'first_name' | 'last_name'>
    )> }
  )>>> }
);

export type GqlCollectionCentersQueryVariables = Exact<{
  filter: Maybe<Scalars['GenericScalar']>;
}>;


export type GqlCollectionCentersQuery = (
  { __typename?: 'Query' }
  & { collection_centers: Maybe<Array<Maybe<(
    { __typename?: 'CollectionCenterGQL' }
    & Pick<GqlCollectionCenterGql, 'collection_center_name' | 'collection_center_id'>
  )>>> }
);

export type GqlDailyOtifQueryVariables = Exact<{
  search: Maybe<Scalars['String']>;
  per_page: Maybe<Scalars['Int']>;
  page: Maybe<Scalars['Int']>;
  dailyOtifFilters: Maybe<Scalars['GenericScalar']>;
}>;


export type GqlDailyOtifQuery = (
  { __typename?: 'Query' }
  & { daily_otif: Maybe<(
    { __typename?: 'DailyOTIFGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'DailyOTIFGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'DailyOTIFGQL' }
        & Pick<GqlDailyOtifgql, 'delivery_date' | 'region_name' | 'depot_name' | 'route_name' | 'shop_name' | 'phone_number' | 'product_item_id' | 'source' | 'delivery_time' | 'payment_mode' | 'ordered_quantity' | 'delivered_quantity' | 'ordered_amount' | 'delivery_amount' | 'fill_rate' | 'on_time'>
        & { product_item: Maybe<(
          { __typename?: 'ProductItemV2GQL' }
          & Pick<GqlProductItemV2Gql, 'product_item_name'>
          & { product: Maybe<(
            { __typename?: 'ProductV2GQL' }
            & Pick<GqlProductV2Gql, 'product_id' | 'product_name'>
          )> }
        )> }
      )> }
    )>> }
  )> }
);

export type GqlDailyOtifUnpaginatedQueryVariables = Exact<{
  start_date: Maybe<Scalars['String']>;
  end_date: Maybe<Scalars['String']>;
  region_id: Maybe<Scalars['String']>;
  depot_id: Maybe<Scalars['String']>;
  route_id: Maybe<Scalars['String']>;
  category_id: Maybe<Scalars['String']>;
  product_segment_id: Maybe<Scalars['String']>;
  product_id: Maybe<Scalars['String']>;
  payment_mode: Maybe<Scalars['String']>;
  on_time: Maybe<Scalars['String']>;
}>;


export type GqlDailyOtifUnpaginatedQuery = (
  { __typename?: 'Query' }
  & { daily_otif_unpaginated: Maybe<Array<Maybe<(
    { __typename?: 'DailyOTIFGQL' }
    & Pick<GqlDailyOtifgql, 'delivery_date' | 'region_name' | 'depot_name' | 'route_name' | 'shop_name' | 'phone_number' | 'product_item_id' | 'source' | 'delivery_time' | 'payment_mode' | 'ordered_quantity' | 'delivered_quantity' | 'ordered_amount' | 'delivery_amount' | 'fill_rate' | 'on_time'>
    & { product_item: Maybe<(
      { __typename?: 'ProductItemV2GQL' }
      & Pick<GqlProductItemV2Gql, 'product_item_name'>
      & { product: Maybe<(
        { __typename?: 'ProductV2GQL' }
        & Pick<GqlProductV2Gql, 'product_id' | 'product_name'>
      )> }
    )> }
  )>>> }
);

export type GqlDailyOtifSummaryQueryVariables = Exact<{
  start_date: Maybe<Scalars['String']>;
  end_date: Maybe<Scalars['String']>;
  region_id: Maybe<Scalars['String']>;
  depot_id: Maybe<Scalars['String']>;
  route_id: Maybe<Scalars['String']>;
  category_id: Maybe<Scalars['String']>;
  product_segment_id: Maybe<Scalars['String']>;
  product_id: Maybe<Scalars['String']>;
  payment_mode: Maybe<Scalars['String']>;
  on_time: Maybe<Scalars['String']>;
}>;


export type GqlDailyOtifSummaryQuery = (
  { __typename?: 'Query' }
  & { daily_otif_summary: Maybe<(
    { __typename?: 'DailyOTIFSummaryGQL' }
    & Pick<GqlDailyOtifSummaryGql, 'ordered_amount' | 'ordered_quantity' | 'delivery_amount' | 'delivered_quantity' | 'on_time' | 'in_full' | 'otif'>
  )> }
);

export type GqlDeliveryManifestQueryVariables = Exact<{
  start_date: Scalars['String'];
  end_date: Scalars['String'];
  depot_ids: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  product_types: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  search: Maybe<Scalars['String']>;
  per_page: Maybe<Scalars['Int']>;
  page: Maybe<Scalars['Int']>;
}>;


export type GqlDeliveryManifestQuery = (
  { __typename?: 'Query' }
  & { ordered_stock_allocation: Maybe<(
    { __typename?: 'OrderedStockAllocationGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & Pick<GqlPaginatorInfo, 'current_page' | 'per_page' | 'has_next' | 'has_previous' | 'total_pages' | 'total_count'>
    )>, edges: Array<Maybe<(
      { __typename?: 'OrderedStockAllocationGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'OrderedStockAllocationGQL' }
        & Pick<GqlOrderedStockAllocationGql, 'delivery_date' | 'depot_name' | 'route_plan_id' | 'product_item_id' | 'unit_of_measure_id' | 'ordered_quantity' | 'ordered_weight' | 'ordered_amount'>
        & { product_item: Maybe<(
          { __typename?: 'ProductItemV2GQL' }
          & Pick<GqlProductItemV2Gql, 'product_item_id' | 'product_item_name'>
          & { product: Maybe<(
            { __typename?: 'ProductV2GQL' }
            & Pick<GqlProductV2Gql, 'product_name'>
          )> }
        )>, route_plan: Maybe<(
          { __typename?: 'RoutePlanGQL' }
          & Pick<GqlRoutePlanGql, 'route_plan_id'>
          & { vehicle: Maybe<(
            { __typename?: 'VehicleGQL' }
            & Pick<GqlVehicleGql, 'registration_number'>
          )> }
        )>, uom: Maybe<(
          { __typename?: 'UOMV2GQL' }
          & Pick<GqlUomv2Gql, 'uom_id' | 'name'>
        )> }
      )> }
    )>> }
  )> }
);

export type GqlManifestSummaryQueryVariables = Exact<{
  start_date: Scalars['String'];
  end_date: Scalars['String'];
  depot_ids: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  product_types: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
}>;


export type GqlManifestSummaryQuery = (
  { __typename?: 'Query' }
  & { delivery_vehicle_manifest_summary: Maybe<(
    { __typename?: 'DeliveryVehicleManifestSummaryGQL' }
    & Pick<GqlDeliveryVehicleManifestSummaryGql, 'total_ordered_quantity' | 'total_ordered_weight' | 'total_ordered_amount'>
  )> }
);

export type GqlUnpaginated_Order_Stock_AllocationQueryVariables = Exact<{
  start_date: Scalars['String'];
  end_date: Scalars['String'];
  depot_ids: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  product_types: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
}>;


export type GqlUnpaginated_Order_Stock_AllocationQuery = (
  { __typename?: 'Query' }
  & { unpaginated_ordered_stock_allocation: Maybe<Array<Maybe<(
    { __typename?: 'OrderedStockAllocationGQL' }
    & Pick<GqlOrderedStockAllocationGql, 'delivery_date' | 'depot_name' | 'route_plan_id' | 'product_item_id' | 'unit_of_measure_id' | 'ordered_quantity' | 'ordered_weight' | 'ordered_amount'>
    & { product_item: Maybe<(
      { __typename?: 'ProductItemV2GQL' }
      & Pick<GqlProductItemV2Gql, 'product_item_id' | 'product_item_name'>
      & { product: Maybe<(
        { __typename?: 'ProductV2GQL' }
        & Pick<GqlProductV2Gql, 'product_name'>
      )> }
    )>, route_plan: Maybe<(
      { __typename?: 'RoutePlanGQL' }
      & { vehicle: Maybe<(
        { __typename?: 'VehicleGQL' }
        & Pick<GqlVehicleGql, 'registration_number'>
      )> }
    )>, uom: Maybe<(
      { __typename?: 'UOMV2GQL' }
      & Pick<GqlUomv2Gql, 'uom_id' | 'name'>
    )> }
  )>>> }
);

export type GqlProductExpiryQueryVariables = Exact<{
  search: Maybe<Scalars['String']>;
  per_page: Maybe<Scalars['Int']>;
  page: Maybe<Scalars['Int']>;
  productExpiryFilters: Maybe<Scalars['GenericScalar']>;
}>;


export type GqlProductExpiryQuery = (
  { __typename?: 'Query' }
  & { product_expiry: Maybe<(
    { __typename?: 'ProductExpiryGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'ProductExpiryGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'ProductExpiryGQL' }
        & Pick<GqlProductExpiryGql, 'storage_location_id' | 'storage_location_number' | 'location_type_name' | 'asset_number' | 'stock_availability_status' | 'product_item_id' | 'stock_date' | 'order_by_date' | 'stock_age' | 'expiry_duration' | 'hui_count'>
        & { product_item: Maybe<(
          { __typename?: 'ProductItemV2GQL' }
          & Pick<GqlProductItemV2Gql, 'product_item_name'>
          & { product: Maybe<(
            { __typename?: 'ProductV2GQL' }
            & Pick<GqlProductV2Gql, 'product_id' | 'product_name'>
            & { category: Maybe<(
              { __typename?: 'CategoryV2GQL' }
              & Pick<GqlCategoryV2Gql, 'category_name'>
            )> }
          )> }
        )> }
      )> }
    )>> }
  )> }
);

export type GqlProductExpiryUnpaginatedQueryVariables = Exact<{
  storage_location_number: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  location_type_id: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  product_id: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  category_id: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  product_item_id: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  asset_number: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  stock_age: Maybe<Scalars['Int']>;
  expiry_duration: Maybe<Scalars['Int']>;
}>;


export type GqlProductExpiryUnpaginatedQuery = (
  { __typename?: 'Query' }
  & { product_expiry_unpaginated: Maybe<Array<Maybe<(
    { __typename?: 'ProductExpiryGQL' }
    & Pick<GqlProductExpiryGql, 'storage_location_id' | 'storage_location_number' | 'location_type_name' | 'asset_number' | 'stock_availability_status' | 'product_item_id' | 'stock_date' | 'order_by_date' | 'stock_age' | 'expiry_duration' | 'hui_count'>
    & { product_item: Maybe<(
      { __typename?: 'ProductItemV2GQL' }
      & Pick<GqlProductItemV2Gql, 'product_item_name'>
      & { product: Maybe<(
        { __typename?: 'ProductV2GQL' }
        & Pick<GqlProductV2Gql, 'product_id' | 'product_name'>
        & { category: Maybe<(
          { __typename?: 'CategoryV2GQL' }
          & Pick<GqlCategoryV2Gql, 'category_name'>
        )> }
      )> }
    )> }
  )>>> }
);

export type GqlPurchases_Vs_IntakesQueryVariables = Exact<{
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  search: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
}>;


export type GqlPurchases_Vs_IntakesQuery = (
  { __typename?: 'Query' }
  & { harvest_vs_intake: Maybe<(
    { __typename?: 'HarvestVsIntakeGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'HarvestVsIntakeGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'HarvestVsIntakeGQL' }
        & Pick<GqlHarvestVsIntakeGql, 'harvest_date' | 'batch_id' | 'invoice_number' | 'control_unit_invoice_number' | 'amount' | 'harvested_weight' | 'harvested_quantity' | 'buying_price_uom' | 'buying_price_kg' | 'product_item_id'>
        & { product_item: Maybe<(
          { __typename?: 'ProductItemV2GQL' }
          & Pick<GqlProductItemV2Gql, 'product_item_name'>
          & { product: Maybe<(
            { __typename?: 'ProductV2GQL' }
            & Pick<GqlProductV2Gql, 'product_name'>
          )> }
        )>, batch: Maybe<(
          { __typename?: 'BatchGQL' }
          & Pick<GqlBatchGql, 'batch_name' | 'batch_date' | 'field_weight' | 'intake_weight' | 'processing_loss_weight'>
        )>, stock_intake_summary: Maybe<Array<Maybe<(
          { __typename?: 'StockIntakeSummaryGQL' }
          & Pick<GqlStockIntakeSummaryGql, 'stock_intake_number' | 'distribution_center_name' | 'truck_number' | 'intake_weight' | 'intake_quantity' | 'intake_variance_kg' | 'intake_variance_uom' | 'net_variance_uom' | 'rejected_quantity'>
        )>>>, farmer: Maybe<(
          { __typename?: 'FarmerGQL' }
          & Pick<GqlFarmerGql, 'farmer_type' | 'supplier_name'>
        )>, collection_center: Maybe<(
          { __typename?: 'CollectionCenterGQL' }
          & Pick<GqlCollectionCenterGql, 'collection_center_name'>
          & { area: Maybe<(
            { __typename?: 'AreaGQL' }
            & Pick<GqlAreaGql, 'area_name'>
          )> }
        )> }
      )> }
    )>> }
  )> }
);

export type GqlGetUnpaginatedPurchasesQueryVariables = Exact<{
  start_date: Maybe<Scalars['String']>;
  end_date: Maybe<Scalars['String']>;
  batch_id: Maybe<Scalars['String']>;
  product_id: Maybe<Scalars['String']>;
  product_item_id: Maybe<Scalars['String']>;
  product_type: Maybe<Scalars['String']>;
}>;


export type GqlGetUnpaginatedPurchasesQuery = (
  { __typename?: 'Query' }
  & { harvest_vs_intake_unpaginated: Maybe<Array<Maybe<(
    { __typename?: 'HarvestVsIntakeGQL' }
    & Pick<GqlHarvestVsIntakeGql, 'harvest_date' | 'batch_id' | 'invoice_number' | 'control_unit_invoice_number' | 'amount' | 'harvested_weight' | 'harvested_quantity' | 'buying_price_uom' | 'buying_price_kg' | 'product_item_id'>
    & { product_item: Maybe<(
      { __typename?: 'ProductItemV2GQL' }
      & Pick<GqlProductItemV2Gql, 'product_item_name'>
      & { product: Maybe<(
        { __typename?: 'ProductV2GQL' }
        & Pick<GqlProductV2Gql, 'product_name'>
      )> }
    )>, batch: Maybe<(
      { __typename?: 'BatchGQL' }
      & Pick<GqlBatchGql, 'batch_name' | 'batch_date' | 'field_weight' | 'intake_weight' | 'processing_loss_weight'>
    )>, stock_intake_summary: Maybe<Array<Maybe<(
      { __typename?: 'StockIntakeSummaryGQL' }
      & Pick<GqlStockIntakeSummaryGql, 'stock_intake_number' | 'distribution_center_name' | 'truck_number' | 'intake_weight' | 'intake_quantity' | 'intake_variance_kg' | 'intake_variance_uom' | 'net_variance_uom' | 'rejected_quantity'>
    )>>>, farmer: Maybe<(
      { __typename?: 'FarmerGQL' }
      & Pick<GqlFarmerGql, 'farmer_type' | 'supplier_name'>
    )>, collection_center: Maybe<(
      { __typename?: 'CollectionCenterGQL' }
      & Pick<GqlCollectionCenterGql, 'collection_center_name'>
      & { area: Maybe<(
        { __typename?: 'AreaGQL' }
        & Pick<GqlAreaGql, 'area_name'>
      )> }
    )> }
  )>>> }
);

export type GqlSales_Per_ProductQueryVariables = Exact<{
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  search: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
}>;


export type GqlSales_Per_ProductQuery = (
  { __typename?: 'Query' }
  & { sales_per_product: Maybe<(
    { __typename?: 'SalesPerProductGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'SalesPerProductGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'SalesPerProductGQL' }
        & Pick<GqlSalesPerProductGql, 'quantity' | 'amount' | 'weight'>
        & { product_item: Maybe<(
          { __typename?: 'ProductItemV2GQL' }
          & Pick<GqlProductItemV2Gql, 'product_item_name'>
          & { product: Maybe<(
            { __typename?: 'ProductV2GQL' }
            & Pick<GqlProductV2Gql, 'product_name'>
            & { category: Maybe<(
              { __typename?: 'CategoryV2GQL' }
              & Pick<GqlCategoryV2Gql, 'category_name'>
            )> }
          )>, product_item_segment: Maybe<(
            { __typename?: 'ProductItemSegmentV2GQL' }
            & Pick<GqlProductItemSegmentV2Gql, 'segment_name'>
          )> }
        )> }
      )> }
    )>> }
  )> }
);

export type GqlSales_Per_Product_UnpaginatedQueryVariables = Exact<{
  start_date: Maybe<Scalars['String']>;
  end_date: Maybe<Scalars['String']>;
  depot_id: Maybe<Scalars['String']>;
  route_id: Maybe<Scalars['String']>;
  product_item_id: Maybe<Scalars['String']>;
}>;


export type GqlSales_Per_Product_UnpaginatedQuery = (
  { __typename?: 'Query' }
  & { sales_per_product_unpaginated: Maybe<Array<Maybe<(
    { __typename?: 'SalesPerProductGQL' }
    & Pick<GqlSalesPerProductGql, 'quantity' | 'amount' | 'weight'>
    & { product_item: Maybe<(
      { __typename?: 'ProductItemV2GQL' }
      & Pick<GqlProductItemV2Gql, 'product_item_name'>
      & { product: Maybe<(
        { __typename?: 'ProductV2GQL' }
        & Pick<GqlProductV2Gql, 'product_name'>
        & { category: Maybe<(
          { __typename?: 'CategoryV2GQL' }
          & Pick<GqlCategoryV2Gql, 'category_name'>
        )> }
      )>, product_item_segment: Maybe<(
        { __typename?: 'ProductItemSegmentV2GQL' }
        & Pick<GqlProductItemSegmentV2Gql, 'segment_name'>
      )> }
    )> }
  )>>> }
);

export type GqlStockAvailabilityReportQueryVariables = Exact<{
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  search: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
}>;


export type GqlStockAvailabilityReportQuery = (
  { __typename?: 'Query' }
  & { v2_stock_availability_product_item_summary: Maybe<(
    { __typename?: 'StockAvailabilitySummaryGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'StockAvailabilitySummaryGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'StockAvailabilitySummaryGQL' }
        & Pick<GqlStockAvailabilitySummaryGql, 'product_item_id' | 'quantity' | 'sale_date' | 'last_date_available' | 'stock_status' | 'no_days_oos'>
        & { product_item: Maybe<(
          { __typename?: 'ProductItemV2GQL' }
          & Pick<GqlProductItemV2Gql, 'product_item_name' | 'product_item_id' | 'product_id'>
          & { product: Maybe<(
            { __typename?: 'ProductV2GQL' }
            & Pick<GqlProductV2Gql, 'product_name'>
            & { category: Maybe<(
              { __typename?: 'CategoryV2GQL' }
              & Pick<GqlCategoryV2Gql, 'category_name' | 'category_id'>
            )> }
          )>, product_item_segment: Maybe<(
            { __typename?: 'ProductItemSegmentV2GQL' }
            & Pick<GqlProductItemSegmentV2Gql, 'segment_name' | 'product_item_segment_id'>
          )> }
        )> }
      )> }
    )>> }
  )> }
);

export type GqlStockAvailabilityReportUnpaginatedQueryVariables = Exact<{
  filters: Maybe<Scalars['GenericScalar']>;
}>;


export type GqlStockAvailabilityReportUnpaginatedQuery = (
  { __typename?: 'Query' }
  & { v2_stock_availability_unpaginated: Maybe<Array<Maybe<(
    { __typename?: 'StockAvailabilitySummaryGQL' }
    & Pick<GqlStockAvailabilitySummaryGql, 'product_item_id' | 'quantity' | 'sale_date' | 'last_date_available' | 'stock_status' | 'no_days_oos'>
    & { product_item: Maybe<(
      { __typename?: 'ProductItemV2GQL' }
      & Pick<GqlProductItemV2Gql, 'product_item_name' | 'product_item_id' | 'product_id'>
      & { product: Maybe<(
        { __typename?: 'ProductV2GQL' }
        & Pick<GqlProductV2Gql, 'product_name'>
        & { category: Maybe<(
          { __typename?: 'CategoryV2GQL' }
          & Pick<GqlCategoryV2Gql, 'category_name' | 'category_id'>
        )> }
      )>, product_item_segment: Maybe<(
        { __typename?: 'ProductItemSegmentV2GQL' }
        & Pick<GqlProductItemSegmentV2Gql, 'segment_name' | 'product_item_segment_id'>
      )> }
    )> }
  )>>> }
);

export type GqlVehicle_AssignmentQueryVariables = Exact<{
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  search: Maybe<Scalars['String']>;
  shops_vs_vehicles: Maybe<Scalars['String']>;
  ordered_weight_vs_capacity: Maybe<Scalars['String']>;
  delivery_date: Maybe<Scalars['String']>;
  depot_ids: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
}>;


export type GqlVehicle_AssignmentQuery = (
  { __typename?: 'Query' }
  & { pseudo_routes_vehicle_assignment: Maybe<(
    { __typename?: 'PseudoRoutesVehicleAssignmentGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'PseudoRoutesVehicleAssignmentGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'PseudoRoutesVehicleAssignmentGQL' }
        & Pick<GqlPseudoRoutesVehicleAssignmentGql, 'delivery_date' | 'route_id' | 'combined_routes' | 'unique_shops' | 'ordered_weight' | 'available_vehicles' | 'load_capacity' | 'shops_vs_vehicles' | 'ordered_weight_vs_capacity' | 'distance' | 'user_id'>
        & { route: Maybe<(
          { __typename?: 'RouteV2GQL' }
          & { depot: Maybe<(
            { __typename?: 'DepotV2GQL' }
            & Pick<GqlDepotV2Gql, 'depot_name' | 'depot_id'>
          )> }
        )>, user: Maybe<(
          { __typename?: 'UserV2GQL' }
          & Pick<GqlUserV2Gql, 'first_name' | 'last_name'>
        )> }
      )> }
    )>> }
  )> }
);

export type GqlVehicle_Assignment_UnpaginatedQueryVariables = Exact<{
  delivery_date: Maybe<Scalars['String']>;
}>;


export type GqlVehicle_Assignment_UnpaginatedQuery = (
  { __typename?: 'Query' }
  & { unpaginated_pseudo_routes_vehicle_assignment: Maybe<Array<Maybe<(
    { __typename?: 'PseudoRoutesVehicleAssignmentGQL' }
    & Pick<GqlPseudoRoutesVehicleAssignmentGql, 'available_vehicles' | 'combined_routes' | 'delivery_date' | 'distance' | 'load_capacity' | 'ordered_weight' | 'ordered_weight_vs_capacity' | 'user_id' | 'shops_vs_vehicles' | 'unique_shops'>
    & { route: Maybe<(
      { __typename?: 'RouteV2GQL' }
      & Pick<GqlRouteV2Gql, 'route_id' | 'route_name' | 'route_type'>
      & { depot: Maybe<(
        { __typename?: 'DepotV2GQL' }
        & Pick<GqlDepotV2Gql, 'depot_name' | 'depot_id'>
      )> }
    )>, user: Maybe<(
      { __typename?: 'UserV2GQL' }
      & Pick<GqlUserV2Gql, 'first_name' | 'last_name'>
    )> }
  )>>> }
);

export type GqlVehicleDeliveryAndLoadingQueryVariables = Exact<{
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  filters: Maybe<Scalars['GenericScalar']>;
  search: Maybe<Scalars['String']>;
}>;


export type GqlVehicleDeliveryAndLoadingQuery = (
  { __typename?: 'Query' }
  & { vehicle_delivery_and_loading: Maybe<(
    { __typename?: 'VehicleDeliveryAndLoadingGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'VehicleDeliveryAndLoadingGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'VehicleDeliveryAndLoadingGQL' }
        & Pick<GqlVehicleDeliveryAndLoadingGql, 'delivery_date' | 'vehicle_allocated_amount'>
        & { delivery_payments: Maybe<Array<Maybe<(
          { __typename?: 'DeliveryPaymentGQL' }
          & Pick<GqlDeliveryPaymentGql, 'payment_mode' | 'amount'>
        )>>>, depot: Maybe<(
          { __typename?: 'DepotV2GQL' }
          & Pick<GqlDepotV2Gql, 'depot_id' | 'depot_name'>
        )>, route_plan: Maybe<(
          { __typename?: 'RoutePlanGQL' }
          & Pick<GqlRoutePlanGql, 'route_plan_id'>
          & { vehicle: Maybe<(
            { __typename?: 'VehicleGQL' }
            & Pick<GqlVehicleGql, 'registration_number'>
          )> }
        )> }
      )> }
    )>> }
  )> }
);

export type GqlVehicleDeliveryAndLoadingSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlVehicleDeliveryAndLoadingSummaryQuery = (
  { __typename?: 'Query' }
  & { vehicle_delivery_and_loading_summary: Maybe<(
    { __typename?: 'VehicleDeliveryAndLoadingSummaryGQL' }
    & Pick<GqlVehicleDeliveryAndLoadingSummaryGql, 'total_delivered_amount' | 'total_loaded_amount'>
  )> }
);

export type GqlGet_Vendor_PaymentsQueryVariables = Exact<{
  start_date: Maybe<Scalars['String']>;
  end_date: Maybe<Scalars['String']>;
  depot_ids: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  payment_modes: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  shop_ids: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  search: Maybe<Scalars['String']>;
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
}>;


export type GqlGet_Vendor_PaymentsQuery = (
  { __typename?: 'Query' }
  & { vendor_payments: Maybe<(
    { __typename?: 'VendorPaymentsGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'VendorPaymentsGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'VendorPaymentsGQL' }
        & Pick<GqlVendorPaymentsGql, 'delivery_date' | 'depot_name' | 'delivery_fee' | 'total_amount' | 'route_name' | 'route_plan_id' | 'shop_number' | 'shop_name' | 'customer_name' | 'phone_number' | 'delivery_number' | 'payment_mode' | 'reference_number' | 'payment_number' | 'delivery_amount' | 'paid_amount'>
        & { route_plan: Maybe<(
          { __typename?: 'RoutePlanGQL' }
          & Pick<GqlRoutePlanGql, 'route_plan_id'>
          & { vehicle: Maybe<(
            { __typename?: 'VehicleGQL' }
            & Pick<GqlVehicleGql, 'registration_number'>
          )> }
        )> }
      )> }
    )>> }
  )> }
);

export type GqlGet_Vendor_Payments_SummaryQueryVariables = Exact<{
  start_date: Maybe<Scalars['String']>;
  end_date: Maybe<Scalars['String']>;
  depot_ids: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  shop_ids: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
}>;


export type GqlGet_Vendor_Payments_SummaryQuery = (
  { __typename?: 'Query' }
  & { vendor_payments_summary: Maybe<Array<Maybe<(
    { __typename?: 'VendorPaymentsSummaryGQL' }
    & Pick<GqlVendorPaymentsSummaryGql, 'payment_mode' | 'amount'>
  )>>> }
);

export type GqlGet_Unpaginated_Vendor_PaymentsQueryVariables = Exact<{
  start_date: Maybe<Scalars['String']>;
  end_date: Maybe<Scalars['String']>;
  depot_ids: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  payment_modes: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  shop_ids: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
}>;


export type GqlGet_Unpaginated_Vendor_PaymentsQuery = (
  { __typename?: 'Query' }
  & { unpaginated_vendor_payments: Maybe<Array<Maybe<(
    { __typename?: 'VendorPaymentsGQL' }
    & Pick<GqlVendorPaymentsGql, 'delivery_date' | 'depot_name' | 'route_plan_id' | 'delivery_fee' | 'total_amount' | 'route_name' | 'shop_number' | 'shop_name' | 'customer_name' | 'phone_number' | 'delivery_number' | 'payment_mode' | 'reference_number' | 'payment_number' | 'delivery_amount' | 'paid_amount'>
    & { route_plan: Maybe<(
      { __typename?: 'RoutePlanGQL' }
      & Pick<GqlRoutePlanGql, 'route_plan_id'>
      & { vehicle: Maybe<(
        { __typename?: 'VehicleGQL' }
        & Pick<GqlVehicleGql, 'registration_number'>
      )> }
    )> }
  )>>> }
);

export type GqlPaymentModesQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlPaymentModesQuery = (
  { __typename?: 'Query' }
  & Pick<GqlQuery, 'payment_modes'>
);

export type GqlWmsStockBalancesQueryVariables = Exact<{
  search: Maybe<Scalars['String']>;
  per_page: Maybe<Scalars['Int']>;
  page: Maybe<Scalars['Int']>;
  stockBalancesFilters: Maybe<Scalars['GenericScalar']>;
}>;


export type GqlWmsStockBalancesQuery = (
  { __typename?: 'Query' }
  & { product_locations: Maybe<(
    { __typename?: 'ProductLocationGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'ProductLocationGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'ProductLocationGQL' }
        & Pick<GqlProductLocationGql, 'storage_location_number' | 'location_type_name' | 'hu_count' | 'hui_count'>
        & { product_item: Maybe<(
          { __typename?: 'ProductItemV2GQL' }
          & Pick<GqlProductItemV2Gql, 'product_item_name'>
          & { product: Maybe<(
            { __typename?: 'ProductV2GQL' }
            & Pick<GqlProductV2Gql, 'product_id' | 'product_name'>
            & { category: Maybe<(
              { __typename?: 'CategoryV2GQL' }
              & Pick<GqlCategoryV2Gql, 'category_name' | 'category_id'>
            )> }
          )>, product_item_segment: Maybe<(
            { __typename?: 'ProductItemSegmentV2GQL' }
            & Pick<GqlProductItemSegmentV2Gql, 'segment_name' | 'product_item_segment_id'>
          )> }
        )> }
      )> }
    )>> }
  )> }
);

export type GqlWmsStockBalancesUnpaginatedQueryVariables = Exact<{
  location_type_id: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  product_item_id: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  product_id: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  category_id: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  product_segment_id: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  storage_location_number: Maybe<Scalars['String']>;
}>;


export type GqlWmsStockBalancesUnpaginatedQuery = (
  { __typename?: 'Query' }
  & { product_locations_unpaginated: Maybe<Array<Maybe<(
    { __typename?: 'ProductLocationGQL' }
    & Pick<GqlProductLocationGql, 'storage_location_number' | 'location_type_name' | 'hu_count' | 'hui_count'>
    & { product_item: Maybe<(
      { __typename?: 'ProductItemV2GQL' }
      & Pick<GqlProductItemV2Gql, 'product_item_name'>
      & { product: Maybe<(
        { __typename?: 'ProductV2GQL' }
        & Pick<GqlProductV2Gql, 'product_id' | 'product_name'>
        & { category: Maybe<(
          { __typename?: 'CategoryV2GQL' }
          & Pick<GqlCategoryV2Gql, 'category_name' | 'category_id'>
        )> }
      )>, product_item_segment: Maybe<(
        { __typename?: 'ProductItemSegmentV2GQL' }
        & Pick<GqlProductItemSegmentV2Gql, 'segment_name' | 'product_item_segment_id'>
      )> }
    )> }
  )>>> }
);

export type GqlProductPriceRangeQueryVariables = Exact<{
  search: Maybe<Scalars['String']>;
  productFilters: Maybe<Scalars['GenericScalar']>;
  per_page: Maybe<Scalars['Int']>;
  page: Maybe<Scalars['Int']>;
}>;


export type GqlProductPriceRangeQuery = (
  { __typename?: 'Query' }
  & { paginated_products_v2: Maybe<(
    { __typename?: 'ProductV2GQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'ProductV2GQLEdge' }
      & { node: Maybe<(
        { __typename?: 'ProductV2GQL' }
        & Pick<GqlProductV2Gql, 'active' | 'product_name' | 'product_id'>
        & { sourcing_price_range: Maybe<(
          { __typename?: 'PriceRangeGQL' }
          & Pick<GqlPriceRangeGql, 'min_price' | 'max_price' | 'date_updated' | 'price_range_id'>
        )> }
      )> }
    )>> }
  )> }
);

export type GqlPurchasesQueryVariables = Exact<{
  search: Maybe<Scalars['String']>;
  per_page: Maybe<Scalars['Int']>;
  page: Maybe<Scalars['Int']>;
  purchaseFilters: Maybe<Scalars['GenericScalar']>;
}>;


export type GqlPurchasesQuery = (
  { __typename?: 'Query' }
  & { harvests: Maybe<(
    { __typename?: 'HarvestGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & Pick<GqlPaginatorInfo, 'per_page' | 'total_count' | 'total_pages' | 'has_next' | 'has_previous' | 'current_page'>
    )>, edges: Array<Maybe<(
      { __typename?: 'HarvestGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'HarvestGQL' }
        & Pick<GqlHarvestGql, 'active' | 'original_currency' | 'cancelled' | 'cancellation_reason' | 'date_created' | 'receipt_number' | 'harvest_id' | 'invoice_number' | 'batch_id' | 'signed_receipt_url' | 'farmer_id' | 'quantity' | 'weight' | 'unit_price' | 'collection_center_id' | 'farm_id' | 'product_id' | 'product_item_id' | 'unit_of_measure_id' | 'control_unit_invoice_number' | 'harvest_date'>
        & { batch: Maybe<(
          { __typename?: 'BatchGQL' }
          & Pick<GqlBatchGql, 'batch_name'>
        )>, collection_center: Maybe<(
          { __typename?: 'CollectionCenterGQL' }
          & Pick<GqlCollectionCenterGql, 'collection_center_name' | 'area_id'>
          & { area: Maybe<(
            { __typename?: 'AreaGQL' }
            & Pick<GqlAreaGql, 'area_name'>
          )> }
        )>, farmer: Maybe<(
          { __typename?: 'FarmerGQL' }
          & Pick<GqlFarmerGql, 'first_name' | 'last_name' | 'phone_number' | 'farmer_number' | 'farmer_id' | 'supplier_name'>
        )>, farm: Maybe<(
          { __typename?: 'FarmGQL' }
          & Pick<GqlFarmGql, 'farm_number'>
          & { farmer: Maybe<(
            { __typename?: 'FarmerGQL' }
            & Pick<GqlFarmerGql, 'first_name' | 'last_name' | 'phone_number' | 'supplier_name' | 'farmer_id'>
          )> }
        )>, product: Maybe<(
          { __typename?: 'ProductV2GQL' }
          & Pick<GqlProductV2Gql, 'product_name'>
        )>, product_item: Maybe<(
          { __typename?: 'ProductItemV2GQL' }
          & Pick<GqlProductItemV2Gql, 'product_item_name'>
        )>, uom: Maybe<(
          { __typename?: 'UOMV2GQL' }
          & Pick<GqlUomv2Gql, 'name'>
        )>, harvest_items: Maybe<Array<Maybe<(
          { __typename?: 'HarvestItemGQL' }
          & Pick<GqlHarvestItemGql, 'harvest_id' | 'product_id' | 'product_item_id' | 'quantity' | 'unit_price' | 'weight' | 'unit_of_measure_id'>
          & { harvest: Maybe<(
            { __typename?: 'HarvestGQL' }
            & Pick<GqlHarvestGql, 'batch_id'>
          )>, product: Maybe<(
            { __typename?: 'ProductV2GQL' }
            & Pick<GqlProductV2Gql, 'product_name'>
          )>, product_item: Maybe<(
            { __typename?: 'ProductItemV2GQL' }
            & Pick<GqlProductItemV2Gql, 'product_item_name' | 'product_id'>
            & { product: Maybe<(
              { __typename?: 'ProductV2GQL' }
              & Pick<GqlProductV2Gql, 'product_name'>
            )> }
          )>, uom: Maybe<(
            { __typename?: 'UOMV2GQL' }
            & Pick<GqlUomv2Gql, 'name'>
          )> }
        )>>>, payment: Maybe<(
          { __typename?: 'HarvestPaymentGQL' }
          & Pick<GqlHarvestPaymentGql, 'amount_paid' | 'total_payable' | 'harvest_payment_status'>
        )> }
      )> }
    )>> }
  )> }
);

export type GqlBookingsQueryVariables = Exact<{
  search: Maybe<Scalars['String']>;
  per_page: Maybe<Scalars['Int']>;
  page: Maybe<Scalars['Int']>;
  bookingFilters: Maybe<Scalars['GenericScalar']>;
}>;


export type GqlBookingsQuery = (
  { __typename?: 'Query' }
  & { bookings: Maybe<(
    { __typename?: 'BookingGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & Pick<GqlPaginatorInfo, 'per_page' | 'total_count' | 'total_pages' | 'has_next' | 'has_previous' | 'current_page'>
    )>, edges: Array<Maybe<(
      { __typename?: 'BookingGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'BookingGQL' }
        & Pick<GqlBookingGql, 'active' | 'cancelled' | 'cancellation_reason' | 'date_created' | 'receipt_number' | 'booking_id' | 'booking_date' | 'harvest_date' | 'signed_invoice_url' | 'quantity' | 'weight' | 'price' | 'farmer_id' | 'product_id' | 'product_item_id' | 'unit_of_measure_id' | 'collection_center_id' | 'farm_id'>
        & { farmer: Maybe<(
          { __typename?: 'FarmerGQL' }
          & Pick<GqlFarmerGql, 'first_name' | 'last_name' | 'phone_number' | 'supplier_name' | 'farmer_id'>
        )>, product: Maybe<(
          { __typename?: 'ProductV2GQL' }
          & Pick<GqlProductV2Gql, 'product_name'>
        )>, product_item: Maybe<(
          { __typename?: 'ProductItemV2GQL' }
          & Pick<GqlProductItemV2Gql, 'product_item_name'>
        )>, uom: Maybe<(
          { __typename?: 'UOMV2GQL' }
          & Pick<GqlUomv2Gql, 'name'>
        )>, collection_center: Maybe<(
          { __typename?: 'CollectionCenterGQL' }
          & Pick<GqlCollectionCenterGql, 'collection_center_name' | 'area_id'>
          & { area: Maybe<(
            { __typename?: 'AreaGQL' }
            & Pick<GqlAreaGql, 'area_name'>
          )> }
        )>, farm: Maybe<(
          { __typename?: 'FarmGQL' }
          & Pick<GqlFarmGql, 'farm_number' | 'harvest_area_id'>
          & { farmer: Maybe<(
            { __typename?: 'FarmerGQL' }
            & Pick<GqlFarmerGql, 'first_name' | 'last_name' | 'phone_number' | 'supplier_name' | 'farmer_id'>
          )>, harvest_area: Maybe<(
            { __typename?: 'HarvestAreaGQL' }
            & Pick<GqlHarvestAreaGql, 'harvest_area_name'>
          )> }
        )>, booking_items: Maybe<Array<Maybe<(
          { __typename?: 'BookingItemGQL' }
          & Pick<GqlBookingItemGql, 'booking_id' | 'product_id' | 'product_item_id' | 'unit_price' | 'quantity' | 'weight' | 'date_created' | 'harvest_date' | 'unit_of_measure_id'>
          & { product: Maybe<(
            { __typename?: 'ProductV2GQL' }
            & Pick<GqlProductV2Gql, 'product_name'>
          )>, product_item: Maybe<(
            { __typename?: 'ProductItemV2GQL' }
            & Pick<GqlProductItemV2Gql, 'product_item_name' | 'product_id'>
            & { product: Maybe<(
              { __typename?: 'ProductV2GQL' }
              & Pick<GqlProductV2Gql, 'product_name'>
            )>, units_of_measure: Maybe<Array<Maybe<(
              { __typename?: 'ProductItemUOMV2GQL' }
              & Pick<GqlProductItemUomv2Gql, 'conversion_ratio'>
              & { business_domain: Maybe<(
                { __typename?: 'BusinessDomainV2GQL' }
                & Pick<GqlBusinessDomainV2Gql, 'domain_name'>
              )> }
            )>>> }
          )>, uom: Maybe<(
            { __typename?: 'UOMV2GQL' }
            & Pick<GqlUomv2Gql, 'name'>
          )> }
        )>>> }
      )> }
    )>> }
  )> }
);

export type GqlSupplierProductsQueryVariables = Exact<{
  per_page: Maybe<Scalars['Int']>;
  page: Maybe<Scalars['Int']>;
  productFilter: Maybe<Scalars['GenericScalar']>;
}>;


export type GqlSupplierProductsQuery = (
  { __typename?: 'Query' }
  & { supplier_products: Maybe<(
    { __typename?: 'SupplierProductGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & Pick<GqlPaginatorInfo, 'per_page' | 'total_count' | 'total_pages' | 'has_next' | 'has_previous' | 'current_page'>
    )>, edges: Array<Maybe<(
      { __typename?: 'SupplierProductGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'SupplierProductGQL' }
        & Pick<GqlSupplierProductGql, 'active' | 'farmer_id' | 'supplier_product_id' | 'product_id'>
        & { product: Maybe<(
          { __typename?: 'ProductV2GQL' }
          & Pick<GqlProductV2Gql, 'product_name' | 'product_id'>
        )> }
      )> }
    )>> }
  )> }
);

export type GqlPivotsQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlPivotsQuery = (
  { __typename?: 'Query' }
  & { pivots: Maybe<Array<Maybe<(
    { __typename?: 'PivotGQL' }
    & Pick<GqlPivotGql, 'pivot_id' | 'pivot_name' | 'pivot_code'>
  )>>> }
);

export type GqlBlocksQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlBlocksQuery = (
  { __typename?: 'Query' }
  & { blocks: Maybe<Array<Maybe<(
    { __typename?: 'BlockGQL' }
    & Pick<GqlBlockGql, 'block_id' | 'block_name' | 'block_code'>
  )>>> }
);

export type GqlPartyProviderModesQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlPartyProviderModesQuery = (
  { __typename?: 'Query' }
  & { partyProviderModes: (
    { __typename?: 'PartyProviderModeConnection' }
    & { edges: Array<(
      { __typename?: 'PartyProviderMode' }
      & Pick<GqlPartyProviderMode, 'providerModeID' | 'id' | 'paymentMode' | 'partyID'>
      & { party: (
        { __typename?: 'Party' }
        & Pick<GqlParty, 'name' | 'partyCode'>
      ), providerMode: (
        { __typename?: 'ProviderMode' }
        & Pick<GqlProviderMode, 'name' | 'providerID' | 'minAmount' | 'maxAmount' | 'balance'>
      ) }
    )> }
  ) }
);

export type GqlGetHarvestToConfirmPurchaseQueryVariables = Exact<{
  harvestFilter: Maybe<Scalars['GenericScalar']>;
}>;


export type GqlGetHarvestToConfirmPurchaseQuery = (
  { __typename?: 'Query' }
  & { harvests: Maybe<(
    { __typename?: 'HarvestGQLConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'HarvestGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'HarvestGQL' }
        & Pick<GqlHarvestGql, 'harvest_date' | 'harvest_id' | 'date_created' | 'invoice_number' | 'farmer_id'>
        & { farmer: Maybe<(
          { __typename?: 'FarmerGQL' }
          & Pick<GqlFarmerGql, 'first_name' | 'last_name'>
        )>, harvest_items: Maybe<Array<Maybe<(
          { __typename?: 'HarvestItemGQL' }
          & Pick<GqlHarvestItemGql, 'packaging_unit_quantity' | 'quantity' | 'unit_price'>
          & { product_item: Maybe<(
            { __typename?: 'ProductItemV2GQL' }
            & Pick<GqlProductItemV2Gql, 'item_sequence' | 'product_item_id' | 'etims_code' | 'etims_class_code' | 'tax_type_code' | 'product_item_name'>
            & { units_of_measure: Maybe<Array<Maybe<(
              { __typename?: 'ProductItemUOMV2GQL' }
              & { uom: Maybe<(
                { __typename?: 'UOMV2GQL' }
                & Pick<GqlUomv2Gql, 'etims_quantity_code'>
              )> }
            )>>>, product: Maybe<(
              { __typename?: 'ProductV2GQL' }
              & { packaging_units: Maybe<Array<Maybe<(
                { __typename?: 'PackagingUnitV2GQL' }
                & Pick<GqlPackagingUnitV2Gql, 'etims_packaging_code'>
              )>>> }
            )>, dynamic_price: Maybe<(
              { __typename?: 'DynamicPriceGQL' }
              & Pick<GqlDynamicPriceGql, 'discount_value_type' | 'discount_value'>
            )> }
          )> }
        )>>> }
      )> }
    )>> }
  )> }
);

export type GqlStorageQueryVariables = Exact<{
  filters: Maybe<Scalars['GenericScalar']>;
  per_page: Scalars['Int'];
  page: Scalars['Int'];
  search: Maybe<Scalars['String']>;
}>;


export type GqlStorageQuery = (
  { __typename?: 'Query' }
  & { storage: Maybe<(
    { __typename?: 'StorageGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'StorageGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'StorageGQL' }
        & Pick<GqlStorageGql, 'storage_id' | 'storage_entry_number' | 'batch_id' | 'date' | 'quantity' | 'packaging_unit_quantity' | 'created_by' | 'direction' | 'weight' | 'comments' | 'packaging_unit_id' | 'unit_of_measure_id' | 'product_item_id'>
        & { batch: Maybe<(
          { __typename?: 'BatchGQL' }
          & Pick<GqlBatchGql, 'batch_name'>
        )>, user: Maybe<(
          { __typename?: 'UserV2GQL' }
          & Pick<GqlUserV2Gql, 'first_name' | 'last_name'>
        )>, packaging_unit: Maybe<(
          { __typename?: 'PackagingUnitV2GQL' }
          & Pick<GqlPackagingUnitV2Gql, 'name'>
        )>, uom: Maybe<(
          { __typename?: 'UOMV2GQL' }
          & Pick<GqlUomv2Gql, 'name'>
        )>, storage_unit: Maybe<(
          { __typename?: 'StorageUnitGQL' }
          & Pick<GqlStorageUnitGql, 'unit_name' | 'warehouse_id'>
          & { warehouse: Maybe<(
            { __typename?: 'WarehouseGQL' }
            & Pick<GqlWarehouseGql, 'warehouse_name' | 'distribution_center_id'>
            & { distribution_center: Maybe<(
              { __typename?: 'DistributionCenterGQL' }
              & Pick<GqlDistributionCenterGql, 'distribution_center_name'>
            )> }
          )> }
        )>, product_item: Maybe<(
          { __typename?: 'ProductItemV2GQL' }
          & Pick<GqlProductItemV2Gql, 'product_item_name'>
          & { product: Maybe<(
            { __typename?: 'ProductV2GQL' }
            & Pick<GqlProductV2Gql, 'product_name'>
          )> }
        )> }
      )> }
    )>> }
  )> }
);

export type GqlGetBatchesQueryVariables = Exact<{
  filters: Maybe<Scalars['GenericScalar']>;
  per_page: Maybe<Scalars['Int']>;
  page: Maybe<Scalars['Int']>;
  search: Maybe<Scalars['String']>;
}>;


export type GqlGetBatchesQuery = (
  { __typename?: 'Query' }
  & { batches: Maybe<(
    { __typename?: 'BatchGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'BatchGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'BatchGQL' }
        & Pick<GqlBatchGql, 'batch_id' | 'batch_name' | 'batch_date' | 'field_weight' | 'processing_loss_weight' | 'intake_weight' | 'active'>
      )> }
    )>> }
  )> }
);

export type GqlBatchSummaryDetailQueryVariables = Exact<{
  batch_id: Scalars['String'];
}>;


export type GqlBatchSummaryDetailQuery = (
  { __typename?: 'Query' }
  & { batch_by_id: Maybe<(
    { __typename?: 'BatchGQL' }
    & Pick<GqlBatchGql, 'batch_id' | 'batch_name' | 'batch_date' | 'field_weight' | 'intake_weight' | 'collection_center_id'>
    & { collection_center: Maybe<(
      { __typename?: 'CollectionCenterGQL' }
      & Pick<GqlCollectionCenterGql, 'collection_center_name'>
      & { area: Maybe<(
        { __typename?: 'AreaGQL' }
        & Pick<GqlAreaGql, 'area_name'>
      )> }
    )>, stock_intake_items_batch: Maybe<(
      { __typename?: 'StockIntakeItemBatchGQL' }
      & Pick<GqlStockIntakeItemBatchGql, 'batch_id'>
      & { stock_intake_items: Maybe<Array<Maybe<(
        { __typename?: 'StockIntakeItemGQL' }
        & Pick<GqlStockIntakeItemGql, 'active' | 'stock_intake_item_id' | 'product_item_id' | 'cancellation_reason' | 'weight' | 'quantity' | 'unit_of_measure_id' | 'packaging_unit_quantity' | 'packaging_unit_id'>
        & { stock_intake: Maybe<(
          { __typename?: 'StockIntakeGQL' }
          & Pick<GqlStockIntakeGql, 'stock_intake_number'>
        )>, product_item: Maybe<(
          { __typename?: 'ProductItemV2GQL' }
          & Pick<GqlProductItemV2Gql, 'product_item_name'>
          & { product: Maybe<(
            { __typename?: 'ProductV2GQL' }
            & Pick<GqlProductV2Gql, 'product_name'>
          )> }
        )>, uom: Maybe<(
          { __typename?: 'UOMV2GQL' }
          & Pick<GqlUomv2Gql, 'name'>
        )>, packaging_unit: Maybe<(
          { __typename?: 'PackagingUnitV2GQL' }
          & Pick<GqlPackagingUnitV2Gql, 'name'>
        )> }
      )>>> }
    )> }
  )> }
);

export type GqlDcReturnFragment = (
  { __typename?: 'ReturnGQL' }
  & Pick<GqlReturnGql, 'return_id' | 'return_number' | 'sale_date' | 'created_by' | 'return_level' | 'active' | 'cancellation_reason' | 'depot_id' | 'distribution_center_id'>
  & { user: Maybe<(
    { __typename?: 'UserV2GQL' }
    & Pick<GqlUserV2Gql, 'first_name' | 'last_name' | 'phone_number' | 'user_number' | 'user_id'>
  )>, packaging_return_items: Maybe<Array<Maybe<(
    { __typename?: 'PackagingReturnItemGQL' }
    & Pick<GqlPackagingReturnItemGql, 'packaging_return_item_id' | 'active' | 'cancellation_reason' | 'packaging_unit_id' | 'quantity'>
    & { packaging_unit: Maybe<(
      { __typename?: 'PackagingUnitV2GQL' }
      & Pick<GqlPackagingUnitV2Gql, 'name'>
    )> }
  )>>>, product_return_items: Maybe<Array<Maybe<(
    { __typename?: 'ProductReturnItemGQL' }
    & Pick<GqlProductReturnItemGql, 'active' | 'cancellation_reason' | 'product_item_id' | 'product_return_item_id' | 'quantity' | 'unit_of_measure_id'>
    & { uom: Maybe<(
      { __typename?: 'UOMV2GQL' }
      & Pick<GqlUomv2Gql, 'abbreviation'>
    )>, product_item: Maybe<(
      { __typename?: 'ProductItemV2GQL' }
      & Pick<GqlProductItemV2Gql, 'product_item_name' | 'product_id'>
      & { product: Maybe<(
        { __typename?: 'ProductV2GQL' }
        & Pick<GqlProductV2Gql, 'product_name'>
      )> }
    )> }
  )>>>, depot: Maybe<(
    { __typename?: 'DepotV2GQL' }
    & Pick<GqlDepotV2Gql, 'depot_name' | 'depot_id'>
  )>, distribution_center: Maybe<(
    { __typename?: 'DistributionCenterGQL' }
    & Pick<GqlDistributionCenterGql, 'distribution_center_id' | 'distribution_center_name'>
  )> }
);

export type GqlDcReturnsQueryVariables = Exact<{
  filters: Maybe<Scalars['GenericScalar']>;
  per_page: Maybe<Scalars['Int']>;
  page: Maybe<Scalars['Int']>;
  filter_array: Maybe<Scalars['GenericScalar']>;
}>;


export type GqlDcReturnsQuery = (
  { __typename?: 'Query' }
  & { returns: Maybe<(
    { __typename?: 'ReturnGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & Pick<GqlPaginatorInfo, 'per_page' | 'total_count' | 'total_pages' | 'current_page'>
    )>, edges: Array<Maybe<(
      { __typename?: 'ReturnGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'ReturnGQL' }
        & GqlDcReturnFragment
      )> }
    )>> }
  )> }
);

export type GqlDcReturnByIdQueryVariables = Exact<{
  return_id: Scalars['String'];
}>;


export type GqlDcReturnByIdQuery = (
  { __typename?: 'Query' }
  & { return_by_id: Maybe<(
    { __typename?: 'ReturnGQL' }
    & GqlDcReturnFragment
  )> }
);

export type GqlDispatchesQueryVariables = Exact<{
  search: Maybe<Scalars['String']>;
  per_page: Maybe<Scalars['Int']>;
  page: Maybe<Scalars['Int']>;
  dispatchFilters: Maybe<Scalars['GenericScalar']>;
}>;


export type GqlDispatchesQuery = (
  { __typename?: 'Query' }
  & { dispatches: Maybe<(
    { __typename?: 'DispatchGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'DispatchGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'DispatchGQL' }
        & Pick<GqlDispatchGql, 'active' | 'dispatch_id' | 'depot_id' | 'dispatch_number' | 'date_created' | 'total_products' | 'created_by' | 'sale_date' | 'dispatch_type' | 'distribution_center_id'>
        & { user: Maybe<(
          { __typename?: 'UserV2GQL' }
          & Pick<GqlUserV2Gql, 'first_name' | 'last_name' | 'phone_number' | 'user_number' | 'user_id'>
        )>, depot: Maybe<(
          { __typename?: 'DepotV2GQL' }
          & Pick<GqlDepotV2Gql, 'depot_name' | 'depot_id'>
        )>, distribution_center: Maybe<(
          { __typename?: 'DistributionCenterGQL' }
          & Pick<GqlDistributionCenterGql, 'distribution_center_id' | 'distribution_center_name'>
        )> }
      )> }
    )>> }
  )> }
);

export type GqlDispatchDetailsQueryVariables = Exact<{
  dispatch_id: Scalars['String'];
  depot_id: Scalars['String'];
  date: Scalars['String'];
}>;


export type GqlDispatchDetailsQuery = (
  { __typename?: 'Query' }
  & { dispatch_by_id: Maybe<(
    { __typename?: 'DispatchGQL' }
    & Pick<GqlDispatchGql, 'dispatch_id' | 'dispatch_type' | 'date_created' | 'sale_date' | 'depot_id' | 'created_by'>
    & { depot: Maybe<(
      { __typename?: 'DepotV2GQL' }
      & Pick<GqlDepotV2Gql, 'depot_name' | 'depot_id'>
    )>, user: Maybe<(
      { __typename?: 'UserV2GQL' }
      & Pick<GqlUserV2Gql, 'first_name' | 'last_name' | 'phone_number' | 'user_number' | 'user_id'>
    )>, dispatch_items: Maybe<Array<Maybe<(
      { __typename?: 'DispatchItemGQL' }
      & Pick<GqlDispatchItemGql, 'dispatch_item_id' | 'quantity' | 'cancellation_reason' | 'packaging_unit_quantity' | 'active' | 'route_id' | 'packaging_unit_id' | 'unit_of_measure_id' | 'product_item_id'>
      & { route: Maybe<(
        { __typename?: 'RouteV2GQL' }
        & Pick<GqlRouteV2Gql, 'route_name'>
      )>, packaging_unit: Maybe<(
        { __typename?: 'PackagingUnitV2GQL' }
        & Pick<GqlPackagingUnitV2Gql, 'name'>
      )>, uom: Maybe<(
        { __typename?: 'UOMV2GQL' }
        & Pick<GqlUomv2Gql, 'name' | 'abbreviation' | 'uom_id'>
      )>, product_item: Maybe<(
        { __typename?: 'ProductItemV2GQL' }
        & Pick<GqlProductItemV2Gql, 'product_item_id' | 'product_item_name' | 'product_id'>
        & { product: Maybe<(
          { __typename?: 'ProductV2GQL' }
          & Pick<GqlProductV2Gql, 'product_name' | 'product_type'>
          & { packaging_units: Maybe<Array<Maybe<(
            { __typename?: 'PackagingUnitV2GQL' }
            & Pick<GqlPackagingUnitV2Gql, 'active' | 'name' | 'weight' | 'packaging_unit_id'>
          )>>> }
        )>, ordered_stock_depot: Maybe<(
          { __typename?: 'StockOrderedGQL' }
          & Pick<GqlStockOrderedGql, 'order_quantity'>
        )> }
      )> }
    )>>> }
  )> }
);

export type GqlProcessing_LossQueryVariables = Exact<{
  filters: Maybe<Scalars['GenericScalar']>;
  per_page: Scalars['Int'];
  page: Scalars['Int'];
  search: Maybe<Scalars['String']>;
}>;


export type GqlProcessing_LossQuery = (
  { __typename?: 'Query' }
  & { processing_loss: Maybe<(
    { __typename?: 'ProcessingLossGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'ProcessingLossGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'ProcessingLossGQL' }
        & Pick<GqlProcessingLossGql, 'date' | 'processing_loss_number' | 'batch_id' | 'processing_loss_id' | 'loss_type' | 'created_by' | 'distribution_center_id'>
        & { batch: Maybe<(
          { __typename?: 'BatchGQL' }
          & Pick<GqlBatchGql, 'batch_name'>
        )>, processing_loss_type: Maybe<(
          { __typename?: 'ProcessingLossTypeGQL' }
          & Pick<GqlProcessingLossTypeGql, 'processing_loss_type_id' | 'processing_loss_type_name'>
        )>, user: Maybe<(
          { __typename?: 'UserV2GQL' }
          & Pick<GqlUserV2Gql, 'first_name' | 'last_name'>
        )>, distribution_center: Maybe<(
          { __typename?: 'DistributionCenterGQL' }
          & Pick<GqlDistributionCenterGql, 'distribution_center_name'>
        )> }
      )> }
    )>> }
  )> }
);

export type GqlProcessing_Loss_TypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlProcessing_Loss_TypesQuery = (
  { __typename?: 'Query' }
  & { processing_loss_types: Maybe<(
    { __typename?: 'ProcessingLossTypeGQLConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'ProcessingLossTypeGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'ProcessingLossTypeGQL' }
        & Pick<GqlProcessingLossTypeGql, 'processing_loss_type_name' | 'processing_loss_type_id'>
      )> }
    )>> }
  )> }
);

export type GqlProcessingLossByIdQueryVariables = Exact<{
  processingLossId: Maybe<Scalars['String']>;
}>;


export type GqlProcessingLossByIdQuery = (
  { __typename?: 'Query' }
  & { processing_loss_by_id: Maybe<(
    { __typename?: 'ProcessingLossGQL' }
    & Pick<GqlProcessingLossGql, 'date' | 'batch_id' | 'processing_loss_id' | 'loss_type' | 'created_by'>
    & { batch: Maybe<(
      { __typename?: 'BatchGQL' }
      & Pick<GqlBatchGql, 'batch_name'>
    )>, processing_loss_type: Maybe<(
      { __typename?: 'ProcessingLossTypeGQL' }
      & Pick<GqlProcessingLossTypeGql, 'processing_loss_type_id' | 'processing_loss_type_name'>
    )>, user: Maybe<(
      { __typename?: 'UserV2GQL' }
      & Pick<GqlUserV2Gql, 'first_name' | 'last_name'>
    )>, processing_loss_items: Maybe<Array<Maybe<(
      { __typename?: 'ProcessingLossItemGQL' }
      & Pick<GqlProcessingLossItemGql, 'active' | 'quantity' | 'thumbnail' | 'cancellation_reason' | 'processing_loss_item_id' | 'product_item_id' | 'unit_of_measure_id'>
      & { product_loss_reason: Maybe<(
        { __typename?: 'ProductLossReasonGQL' }
        & { loss_reason: Maybe<(
          { __typename?: 'LossReasonGQL' }
          & Pick<GqlLossReasonGql, 'loss_reason'>
        )> }
      )>, product_item: Maybe<(
        { __typename?: 'ProductItemV2GQL' }
        & Pick<GqlProductItemV2Gql, 'product_item_name' | 'product_id'>
        & { product: Maybe<(
          { __typename?: 'ProductV2GQL' }
          & Pick<GqlProductV2Gql, 'product_name'>
        )> }
      )>, uom: Maybe<(
        { __typename?: 'UOMV2GQL' }
        & Pick<GqlUomv2Gql, 'name'>
      )>, processing_loss: Maybe<(
        { __typename?: 'ProcessingLossGQL' }
        & Pick<GqlProcessingLossGql, 'processing_loss_id' | 'loss_type'>
      )> }
    )>>> }
  )> }
);

export type GqlProductItemV2Fragment = (
  { __typename?: 'ProductItemV2GQL' }
  & Pick<GqlProductItemV2Gql, 'product_item_id' | 'product_item_name' | 'packed_weight' | 'packed_volume'>
  & { product: Maybe<(
    { __typename?: 'ProductV2GQL' }
    & Pick<GqlProductV2Gql, 'product_name'>
  )>, units_of_measure: Maybe<Array<Maybe<(
    { __typename?: 'ProductItemUOMV2GQL' }
    & Pick<GqlProductItemUomv2Gql, 'business_domain_id' | 'conversion_ratio'>
    & { business_domain: Maybe<(
      { __typename?: 'BusinessDomainV2GQL' }
      & Pick<GqlBusinessDomainV2Gql, 'domain_name'>
    )>, uom: Maybe<(
      { __typename?: 'UOMV2GQL' }
      & Pick<GqlUomv2Gql, 'uom_id' | 'name'>
    )> }
  )>>> }
);

export type GqlStock_AvailabilityQueryVariables = Exact<{
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  search: Maybe<Scalars['String']>;
  distribution_center_id: Scalars['String'];
  filters: Maybe<Scalars['GenericScalar']>;
}>;


export type GqlStock_AvailabilityQuery = (
  { __typename?: 'Query' }
  & { paginated_product_items_v2: Maybe<(
    { __typename?: 'ProductItemV2GQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'ProductItemV2GQLEdge' }
      & { node: Maybe<(
        { __typename?: 'ProductItemV2GQL' }
        & { current_stock_availability: Maybe<(
          { __typename?: 'StockAvailabilityGQLv2' }
          & Pick<GqlStockAvailabilityGqLv2, 'region_id' | 'created_by' | 'stock_availability_id' | 'updated_by' | 'sale_date' | 'quantity' | 'stock_entry_number' | 'unit_of_measure_id' | 'active'>
          & { region: Maybe<(
            { __typename?: 'RegionV2GQL' }
            & Pick<GqlRegionV2Gql, 'region_name'>
          )>, uom: Maybe<(
            { __typename?: 'UOMV2GQL' }
            & Pick<GqlUomv2Gql, 'name' | 'abbreviation'>
          )>, user: Maybe<(
            { __typename?: 'UserV2GQL' }
            & Pick<GqlUserV2Gql, 'user_id' | 'first_name' | 'last_name' | 'user_number'>
          )> }
        )> }
        & GqlProductItemV2Fragment
      )> }
    )>> }
  )> }
);

export type GqlGetDCsStockAvailabilityQueryVariables = Exact<{
  regionId: Scalars['String'];
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  search: Maybe<Scalars['String']>;
  distribution_center_ids: Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  depot_id: Scalars['String'];
  static_params: Maybe<Scalars['GenericScalar']>;
}>;


export type GqlGetDCsStockAvailabilityQuery = (
  { __typename?: 'Query' }
  & { paginated_product_items_v2: Maybe<(
    { __typename?: 'ProductItemV2GQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'ProductItemV2GQLEdge' }
      & { node: Maybe<(
        { __typename?: 'ProductItemV2GQL' }
        & Pick<GqlProductItemV2Gql, 'base_price'>
        & { current_pricing_v2: Maybe<(
          { __typename?: 'PricingV2GQL' }
          & Pick<GqlPricingV2Gql, 'unit_of_measure_id' | 'base_price' | 'currency' | 'rrp' | 'pricing_date' | 'region_id'>
        )>, current_dcs_stock_availability: Maybe<(
          { __typename?: 'DCStockAvailabilityGQL' }
          & Pick<GqlDcStockAvailabilityGql, 'quantity'>
        )>, dynamic_price: Maybe<(
          { __typename?: 'DynamicPriceGQL' }
          & Pick<GqlDynamicPriceGql, 'base_price' | 'dynamic_price'>
        )> }
        & GqlProductItemV2Fragment
      )> }
    )>> }
  )> }
);

export type GqlWarehouseStockIntakeQueryVariables = Exact<{
  filters: Maybe<Scalars['GenericScalar']>;
  per_page: Maybe<Scalars['Int']>;
  page: Maybe<Scalars['Int']>;
  search: Maybe<Scalars['String']>;
}>;


export type GqlWarehouseStockIntakeQuery = (
  { __typename?: 'Query' }
  & { stock_intakes: Maybe<(
    { __typename?: 'StockIntakeGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & Pick<GqlPaginatorInfo, 'per_page' | 'total_count' | 'total_pages' | 'current_page'>
    )>, edges: Array<Maybe<(
      { __typename?: 'StockIntakeGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'StockIntakeGQL' }
        & Pick<GqlStockIntakeGql, 'created_by' | 'stock_intake_id' | 'stock_intake_number' | 'arrival_date' | 'truck_number' | 'intake_count' | 'distribution_center_id'>
        & { distribution_center: Maybe<(
          { __typename?: 'DistributionCenterGQL' }
          & Pick<GqlDistributionCenterGql, 'distribution_center_id' | 'distribution_center_name'>
        )> }
      )> }
    )>> }
  )> }
);

export type GqlWarehouseStockIntakeByIdQueryVariables = Exact<{
  stock_intake_id: Scalars['String'];
}>;


export type GqlWarehouseStockIntakeByIdQuery = (
  { __typename?: 'Query' }
  & { stock_intake_by_id: Maybe<(
    { __typename?: 'StockIntakeGQL' }
    & Pick<GqlStockIntakeGql, 'stock_intake_id' | 'stock_intake_number' | 'arrival_date' | 'truck_number' | 'created_by'>
    & { stock_intake_photos: Maybe<Array<Maybe<(
      { __typename?: 'StockIntakePhotoGQL' }
      & Pick<GqlStockIntakePhotoGql, 'signed_url'>
    )>>>, user: Maybe<(
      { __typename?: 'UserV2GQL' }
      & Pick<GqlUserV2Gql, 'first_name' | 'last_name'>
    )>, stock_intake_items: Maybe<Array<Maybe<(
      { __typename?: 'StockIntakeItemGQL' }
      & Pick<GqlStockIntakeItemGql, 'active' | 'stock_intake_item_id' | 'cancellation_reason' | 'gross_weight' | 'batch_id' | 'weight' | 'quantity' | 'packaging_unit_id' | 'packaging_unit_quantity' | 'unit_of_measure_id' | 'product_item_id'>
      & { batch: Maybe<(
        { __typename?: 'BatchGQL' }
        & Pick<GqlBatchGql, 'batch_name'>
      )>, packaging_unit: Maybe<(
        { __typename?: 'PackagingUnitV2GQL' }
        & Pick<GqlPackagingUnitV2Gql, 'name'>
      )>, uom: Maybe<(
        { __typename?: 'UOMV2GQL' }
        & Pick<GqlUomv2Gql, 'name'>
      )>, product_item: Maybe<(
        { __typename?: 'ProductItemV2GQL' }
        & Pick<GqlProductItemV2Gql, 'product_item_name' | 'product_id'>
        & { product: Maybe<(
          { __typename?: 'ProductV2GQL' }
          & Pick<GqlProductV2Gql, 'product_name'>
        )> }
      )> }
    )>>> }
  )> }
);

export type GqlDistributionCentersQueryVariables = Exact<{
  dc_names: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
}>;


export type GqlDistributionCentersQuery = (
  { __typename?: 'Query' }
  & { distribution_centers: Maybe<Array<Maybe<(
    { __typename?: 'DistributionCenterGQL' }
    & Pick<GqlDistributionCenterGql, 'distribution_center_name' | 'distribution_center_id'>
    & { warehouses: Maybe<Array<Maybe<(
      { __typename?: 'WarehouseGQL' }
      & Pick<GqlWarehouseGql, 'warehouse_id' | 'warehouse_name'>
      & { storage_units: Maybe<Array<Maybe<(
        { __typename?: 'StorageUnitGQL' }
        & Pick<GqlStorageUnitGql, 'storage_unit_id' | 'unit_name'>
        & { warehouse: Maybe<(
          { __typename?: 'WarehouseGQL' }
          & Pick<GqlWarehouseGql, 'warehouse_name' | 'warehouse_id'>
        )> }
      )>>> }
    )>>> }
  )>>> }
);

export type GqlBatchesQueryVariables = Exact<{
  search: Maybe<Scalars['String']>;
  batchFilters: Maybe<Scalars['GenericScalar']>;
  per_page: Maybe<Scalars['Int']>;
}>;


export type GqlBatchesQuery = (
  { __typename?: 'Query' }
  & { batches: Maybe<(
    { __typename?: 'BatchGQLConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'BatchGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'BatchGQL' }
        & Pick<GqlBatchGql, 'active' | 'batch_id' | 'batch_name'>
        & { harvests: Maybe<Array<Maybe<(
          { __typename?: 'HarvestGQL' }
          & Pick<GqlHarvestGql, 'original_currency' | 'batch_id'>
          & { batch: Maybe<(
            { __typename?: 'BatchGQL' }
            & Pick<GqlBatchGql, 'batch_name'>
          )>, harvest_items: Maybe<Array<Maybe<(
            { __typename?: 'HarvestItemGQL' }
            & Pick<GqlHarvestItemGql, 'product_item_id' | 'quantity' | 'unit_of_measure_id' | 'weight' | 'packaging_unit_id' | 'number_of_crates' | 'packaging_unit_quantity'>
            & { uom: Maybe<(
              { __typename?: 'UOMV2GQL' }
              & Pick<GqlUomv2Gql, 'name'>
            )>, packaging_unit: Maybe<(
              { __typename?: 'PackagingUnitV2GQL' }
              & Pick<GqlPackagingUnitV2Gql, 'packaging_unit_id' | 'name'>
            )>, product_item: Maybe<(
              { __typename?: 'ProductItemV2GQL' }
              & Pick<GqlProductItemV2Gql, 'product_item_name'>
              & { product: Maybe<(
                { __typename?: 'ProductV2GQL' }
                & Pick<GqlProductV2Gql, 'product_name'>
              )> }
            )> }
          )>>> }
        )>>> }
      )> }
    )>> }
  )> }
);

export type GqlWarehouseTransferQueryVariables = Exact<{
  search: Maybe<Scalars['String']>;
  per_page: Maybe<Scalars['Int']>;
  page: Maybe<Scalars['Int']>;
  warehouseFilters: Maybe<Scalars['GenericScalar']>;
}>;


export type GqlWarehouseTransferQuery = (
  { __typename?: 'Query' }
  & { warehouse_transfer: Maybe<(
    { __typename?: 'WarehouseTransferGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'WarehouseTransferGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'WarehouseTransferGQL' }
        & Pick<GqlWarehouseTransferGql, 'cancelled' | 'cancellation_reason' | 'active' | 'date_created' | 'warehouse_transfer_id' | 'transfer_number' | 'transfer_date' | 'direction' | 'vehicle_id' | 'transfer_reason_id' | 'source_distribution_center_id' | 'dest_distribution_center_id' | 'transferred_by'>
        & { vehicle: Maybe<(
          { __typename?: 'VehicleGQL' }
          & Pick<GqlVehicleGql, 'registration_number'>
        )>, transfer_reason: Maybe<(
          { __typename?: 'WarehouseTransferReasonGQL' }
          & Pick<GqlWarehouseTransferReasonGql, 'reason_name' | 'reason_id'>
        )>, source_distribution_center: Maybe<(
          { __typename?: 'DistributionCenterGQL' }
          & Pick<GqlDistributionCenterGql, 'distribution_center_name'>
        )>, dest_distribution_center: Maybe<(
          { __typename?: 'DistributionCenterGQL' }
          & Pick<GqlDistributionCenterGql, 'distribution_center_name'>
        )>, transferred_by_user: Maybe<(
          { __typename?: 'UserV2GQL' }
          & Pick<GqlUserV2Gql, 'first_name' | 'last_name' | 'user_number'>
        )>, warehouse_transfer_items: Maybe<Array<Maybe<(
          { __typename?: 'WarehouseTransferItemGQL' }
          & Pick<GqlWarehouseTransferItemGql, 'cancelled' | 'cancellation_reason' | 'warehouse_transfer_item_id' | 'warehouse_transfer_id' | 'quantity' | 'packaging_unit_quantity' | 'batch_id' | 'product_id' | 'weight' | 'product_item_id' | 'unit_of_measure_id' | 'packaging_unit_id'>
          & { batch: Maybe<(
            { __typename?: 'BatchGQL' }
            & Pick<GqlBatchGql, 'batch_name'>
          )>, product: Maybe<(
            { __typename?: 'ProductV2GQL' }
            & Pick<GqlProductV2Gql, 'product_name'>
          )>, product_item: Maybe<(
            { __typename?: 'ProductItemV2GQL' }
            & Pick<GqlProductItemV2Gql, 'product_item_name' | 'product_id'>
            & { product: Maybe<(
              { __typename?: 'ProductV2GQL' }
              & Pick<GqlProductV2Gql, 'product_name'>
            )>, units_of_measure: Maybe<Array<Maybe<(
              { __typename?: 'ProductItemUOMV2GQL' }
              & Pick<GqlProductItemUomv2Gql, 'conversion_ratio'>
              & { business_domain: Maybe<(
                { __typename?: 'BusinessDomainV2GQL' }
                & Pick<GqlBusinessDomainV2Gql, 'domain_name'>
              )> }
            )>>> }
          )>, uom: Maybe<(
            { __typename?: 'UOMV2GQL' }
            & Pick<GqlUomv2Gql, 'name'>
          )>, packaging_unit: Maybe<(
            { __typename?: 'PackagingUnitV2GQL' }
            & Pick<GqlPackagingUnitV2Gql, 'name'>
          )>, warehouse: Maybe<(
            { __typename?: 'WarehouseGQL' }
            & Pick<GqlWarehouseGql, 'warehouse_name'>
            & { storage_units: Maybe<Array<Maybe<(
              { __typename?: 'StorageUnitGQL' }
              & Pick<GqlStorageUnitGql, 'unit_name'>
            )>>> }
          )>, storage_unit: Maybe<(
            { __typename?: 'StorageUnitGQL' }
            & Pick<GqlStorageUnitGql, 'unit_name'>
          )> }
        )>>> }
      )> }
    )>> }
  )> }
);

export type GqlVehiclesQueryVariables = Exact<{
  search: Maybe<Scalars['String']>;
  per_page: Maybe<Scalars['Int']>;
}>;


export type GqlVehiclesQuery = (
  { __typename?: 'Query' }
  & { vehicles: Maybe<(
    { __typename?: 'VehicleGQLConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'VehicleGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'VehicleGQL' }
        & Pick<GqlVehicleGql, 'vehicle_id' | 'registration_number' | 'owner_name' | 'phone_number'>
      )> }
    )>> }
  )> }
);

export type GqlAppointmentsQueryVariables = Exact<{
  search: Maybe<Scalars['String']>;
  per_page: Maybe<Scalars['Int']>;
  page: Maybe<Scalars['Int']>;
  appointmentFilters: Maybe<Scalars['GenericScalar']>;
}>;


export type GqlAppointmentsQuery = (
  { __typename?: 'Query' }
  & { appointments: Maybe<(
    { __typename?: 'AppointmentGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'AppointmentGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'AppointmentGQL' }
        & Pick<GqlAppointmentGql, 'active' | 'cancelled' | 'cancellation_reason' | 'date_created' | 'appointment_id' | 'appointment_number' | 'appointment_type' | 'eta' | 'decline_reason' | 'distribution_center_id' | 'driver_name' | 'driver_phone_number' | 'images' | 'type_of_truck' | 'driver_id' | 'vehicle_seal_number' | 'dock_door_id' | 'vehicle_registration' | 'status' | 'issued_by'>
        & { distribution_center: Maybe<(
          { __typename?: 'DistributionCenterGQL' }
          & Pick<GqlDistributionCenterGql, 'distribution_center_id' | 'distribution_center_name'>
        )>, dock_door: Maybe<(
          { __typename?: 'DockDoorGQL' }
          & Pick<GqlDockDoorGql, 'dock_door_number'>
        )>, issued_by_user: Maybe<(
          { __typename?: 'UserV2GQL' }
          & Pick<GqlUserV2Gql, 'first_name' | 'last_name'>
        )>, appointment_items: Maybe<Array<Maybe<(
          { __typename?: 'AppointmentItemGQL' }
          & Pick<GqlAppointmentItemGql, 'booking_id' | 'batch_id'>
          & { booking: Maybe<(
            { __typename?: 'BookingGQL' }
            & Pick<GqlBookingGql, 'farmer_id'>
            & { farmer: Maybe<(
              { __typename?: 'FarmerGQL' }
              & Pick<GqlFarmerGql, 'first_name' | 'last_name' | 'farmer_number' | 'supplier_name' | 'farmer_id'>
            )>, booking_items: Maybe<Array<Maybe<(
              { __typename?: 'BookingItemGQL' }
              & Pick<GqlBookingItemGql, 'product_item_id'>
              & { product_item: Maybe<(
                { __typename?: 'ProductItemV2GQL' }
                & Pick<GqlProductItemV2Gql, 'product_item_name' | 'product_id'>
                & { product: Maybe<(
                  { __typename?: 'ProductV2GQL' }
                  & Pick<GqlProductV2Gql, 'product_name'>
                )> }
              )> }
            )>>> }
          )>, batch: Maybe<(
            { __typename?: 'BatchGQL' }
            & Pick<GqlBatchGql, 'batch_id'>
            & { harvests: Maybe<Array<Maybe<(
              { __typename?: 'HarvestGQL' }
              & Pick<GqlHarvestGql, 'farmer_id'>
              & { farmer: Maybe<(
                { __typename?: 'FarmerGQL' }
                & Pick<GqlFarmerGql, 'farmer_id' | 'farmer_number' | 'first_name' | 'last_name'>
              )> }
            )>>> }
          )> }
        )>>> }
      )> }
    )>> }
  )> }
);

export type GqlAppointmentItemsQueryVariables = Exact<{
  search: Maybe<Scalars['String']>;
  per_page: Maybe<Scalars['Int']>;
  page: Maybe<Scalars['Int']>;
  appointmentFilters: Maybe<Scalars['GenericScalar']>;
}>;


export type GqlAppointmentItemsQuery = (
  { __typename?: 'Query' }
  & { appointment_items: Maybe<(
    { __typename?: 'AppointmentItemGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'AppointmentItemGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'AppointmentItemGQL' }
        & Pick<GqlAppointmentItemGql, 'active' | 'cancelled' | 'appointment_id' | 'warehouse_transfer_id' | 'batch_id' | 'booking_id' | 'return_id'>
        & { warehouse_transfer: Maybe<(
          { __typename?: 'WarehouseTransferGQL' }
          & Pick<GqlWarehouseTransferGql, 'warehouse_transfer_id'>
          & { warehouse_transfer_items: Maybe<Array<Maybe<(
            { __typename?: 'WarehouseTransferItemGQL' }
            & Pick<GqlWarehouseTransferItemGql, 'active' | 'product_item_id' | 'quantity' | 'weight' | 'unit_of_measure_id'>
            & { product_item: Maybe<(
              { __typename?: 'ProductItemV2GQL' }
              & Pick<GqlProductItemV2Gql, 'product_item_name'>
              & { product: Maybe<(
                { __typename?: 'ProductV2GQL' }
                & Pick<GqlProductV2Gql, 'product_name'>
              )> }
            )>, uom: Maybe<(
              { __typename?: 'UOMV2GQL' }
              & Pick<GqlUomv2Gql, 'name'>
            )> }
          )>>> }
        )>, returns: Maybe<(
          { __typename?: 'ReturnGQL' }
          & Pick<GqlReturnGql, 'active' | 'return_id'>
          & { product_return_items: Maybe<Array<Maybe<(
            { __typename?: 'ProductReturnItemGQL' }
            & Pick<GqlProductReturnItemGql, 'active' | 'product_item_id' | 'quantity' | 'unit_of_measure_id'>
            & { product_item: Maybe<(
              { __typename?: 'ProductItemV2GQL' }
              & Pick<GqlProductItemV2Gql, 'product_item_name'>
              & { product: Maybe<(
                { __typename?: 'ProductV2GQL' }
                & Pick<GqlProductV2Gql, 'product_name'>
              )> }
            )>, uom: Maybe<(
              { __typename?: 'UOMV2GQL' }
              & Pick<GqlUomv2Gql, 'name'>
            )> }
          )>>> }
        )>, booking: Maybe<(
          { __typename?: 'BookingGQL' }
          & Pick<GqlBookingGql, 'booking_id' | 'farmer_id'>
          & { farmer: Maybe<(
            { __typename?: 'FarmerGQL' }
            & Pick<GqlFarmerGql, 'supplier_name' | 'first_name' | 'last_name' | 'farmer_type' | 'phone_number'>
          )>, booking_items: Maybe<Array<Maybe<(
            { __typename?: 'BookingItemGQL' }
            & Pick<GqlBookingItemGql, 'active' | 'unit_of_measure_id' | 'product_item_id' | 'quantity' | 'weight'>
            & { uom: Maybe<(
              { __typename?: 'UOMV2GQL' }
              & Pick<GqlUomv2Gql, 'name'>
            )>, product_item: Maybe<(
              { __typename?: 'ProductItemV2GQL' }
              & Pick<GqlProductItemV2Gql, 'product_item_name'>
              & { product: Maybe<(
                { __typename?: 'ProductV2GQL' }
                & Pick<GqlProductV2Gql, 'product_name'>
              )> }
            )> }
          )>>> }
        )>, batch: Maybe<(
          { __typename?: 'BatchGQL' }
          & Pick<GqlBatchGql, 'batch_name' | 'batch_id'>
          & { harvests: Maybe<Array<Maybe<(
            { __typename?: 'HarvestGQL' }
            & Pick<GqlHarvestGql, 'original_currency' | 'farmer_id' | 'weight'>
            & { farmer: Maybe<(
              { __typename?: 'FarmerGQL' }
              & Pick<GqlFarmerGql, 'supplier_name' | 'first_name' | 'last_name' | 'farmer_type' | 'phone_number'>
            )>, harvest_items: Maybe<Array<Maybe<(
              { __typename?: 'HarvestItemGQL' }
              & Pick<GqlHarvestItemGql, 'product_item_id' | 'quantity' | 'unit_of_measure_id' | 'weight' | 'packaging_unit_id' | 'packaging_unit_quantity'>
              & { uom: Maybe<(
                { __typename?: 'UOMV2GQL' }
                & Pick<GqlUomv2Gql, 'name'>
              )>, packaging_unit: Maybe<(
                { __typename?: 'PackagingUnitV2GQL' }
                & Pick<GqlPackagingUnitV2Gql, 'packaging_unit_id' | 'name'>
              )>, product_item: Maybe<(
                { __typename?: 'ProductItemV2GQL' }
                & Pick<GqlProductItemV2Gql, 'product_item_name'>
                & { product: Maybe<(
                  { __typename?: 'ProductV2GQL' }
                  & Pick<GqlProductV2Gql, 'product_name'>
                )> }
              )> }
            )>>> }
          )>>> }
        )>, warehouse_transfer_request: Maybe<(
          { __typename?: 'WarehouseTransferRequestGQL' }
          & { warehouse_transfer_request_items: Maybe<Array<Maybe<(
            { __typename?: 'WarehouseTransferRequestItemGQL' }
            & Pick<GqlWarehouseTransferRequestItemGql, 'active' | 'product_item_id' | 'quantity' | 'weight'>
            & { product_item: Maybe<(
              { __typename?: 'ProductItemV2GQL' }
              & Pick<GqlProductItemV2Gql, 'product_item_name'>
              & { product: Maybe<(
                { __typename?: 'ProductV2GQL' }
                & Pick<GqlProductV2Gql, 'product_name'>
              )> }
            )> }
          )>>> }
        )> }
      )> }
    )>> }
  )> }
);

export type GqlDockDoorsQueryVariables = Exact<{
  per_page: Maybe<Scalars['Int']>;
  page: Maybe<Scalars['Int']>;
  search: Maybe<Scalars['String']>;
  filter: Maybe<Scalars['GenericScalar']>;
}>;


export type GqlDockDoorsQuery = (
  { __typename?: 'Query' }
  & { dock_doors: Maybe<(
    { __typename?: 'DockDoorGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'DockDoorGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'DockDoorGQL' }
        & Pick<GqlDockDoorGql, 'active' | 'status' | 'dock_door_id' | 'dock_door_number' | 'door_type'>
        & { warehouse: Maybe<(
          { __typename?: 'WarehouseGQL' }
          & { distribution_center: Maybe<(
            { __typename?: 'DistributionCenterGQL' }
            & Pick<GqlDistributionCenterGql, 'distribution_center_name'>
          )> }
        )> }
      )> }
    )>> }
  )> }
);

export type GqlStockIntakeQueryVariables = Exact<{
  intakeFilters: Maybe<Scalars['GenericScalar']>;
}>;


export type GqlStockIntakeQuery = (
  { __typename?: 'Query' }
  & { stock_intakes: Maybe<(
    { __typename?: 'StockIntakeGQLConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'StockIntakeGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'StockIntakeGQL' }
        & Pick<GqlStockIntakeGql, 'appointment_id' | 'stock_intake_number'>
        & { appointment: Maybe<(
          { __typename?: 'AppointmentGQL' }
          & Pick<GqlAppointmentGql, 'appointment_number' | 'vehicle_registration' | 'driver_name' | 'eta' | 'dock_door_id'>
          & { dock_door: Maybe<(
            { __typename?: 'DockDoorGQL' }
            & Pick<GqlDockDoorGql, 'dock_door_number'>
          )>, appointment_items: Maybe<Array<Maybe<(
            { __typename?: 'AppointmentItemGQL' }
            & Pick<GqlAppointmentItemGql, 'batch_id' | 'booking_id' | 'warehouse_transfer_id' | 'return_id' | 'warehouse_transfer_request_id'>
            & { returns: Maybe<(
              { __typename?: 'ReturnGQL' }
              & Pick<GqlReturnGql, 'active' | 'return_id'>
              & { product_return_items: Maybe<Array<Maybe<(
                { __typename?: 'ProductReturnItemGQL' }
                & Pick<GqlProductReturnItemGql, 'active' | 'product_item_id' | 'quantity' | 'unit_of_measure_id'>
                & { product_item: Maybe<(
                  { __typename?: 'ProductItemV2GQL' }
                  & Pick<GqlProductItemV2Gql, 'product_item_name'>
                  & { product: Maybe<(
                    { __typename?: 'ProductV2GQL' }
                    & Pick<GqlProductV2Gql, 'product_name'>
                  )> }
                )>, uom: Maybe<(
                  { __typename?: 'UOMV2GQL' }
                  & Pick<GqlUomv2Gql, 'name'>
                )> }
              )>>> }
            )>, warehouse_transfer: Maybe<(
              { __typename?: 'WarehouseTransferGQL' }
              & Pick<GqlWarehouseTransferGql, 'warehouse_transfer_id'>
              & { warehouse_transfer_items: Maybe<Array<Maybe<(
                { __typename?: 'WarehouseTransferItemGQL' }
                & Pick<GqlWarehouseTransferItemGql, 'active' | 'product_item_id' | 'quantity' | 'weight' | 'unit_of_measure_id'>
                & { product_item: Maybe<(
                  { __typename?: 'ProductItemV2GQL' }
                  & Pick<GqlProductItemV2Gql, 'product_item_name'>
                  & { product: Maybe<(
                    { __typename?: 'ProductV2GQL' }
                    & Pick<GqlProductV2Gql, 'product_name'>
                  )> }
                )>, uom: Maybe<(
                  { __typename?: 'UOMV2GQL' }
                  & Pick<GqlUomv2Gql, 'name'>
                )> }
              )>>> }
            )>, booking: Maybe<(
              { __typename?: 'BookingGQL' }
              & Pick<GqlBookingGql, 'booking_id' | 'farmer_id'>
              & { farmer: Maybe<(
                { __typename?: 'FarmerGQL' }
                & Pick<GqlFarmerGql, 'first_name' | 'last_name' | 'farmer_number' | 'supplier_name'>
              )>, booking_items: Maybe<Array<Maybe<(
                { __typename?: 'BookingItemGQL' }
                & Pick<GqlBookingItemGql, 'active' | 'unit_of_measure_id' | 'product_item_id' | 'quantity' | 'weight'>
                & { uom: Maybe<(
                  { __typename?: 'UOMV2GQL' }
                  & Pick<GqlUomv2Gql, 'name'>
                )>, product_item: Maybe<(
                  { __typename?: 'ProductItemV2GQL' }
                  & Pick<GqlProductItemV2Gql, 'product_item_name'>
                  & { product: Maybe<(
                    { __typename?: 'ProductV2GQL' }
                    & Pick<GqlProductV2Gql, 'product_name'>
                  )> }
                )> }
              )>>> }
            )>, batch: Maybe<(
              { __typename?: 'BatchGQL' }
              & Pick<GqlBatchGql, 'batch_name'>
              & { harvests: Maybe<Array<Maybe<(
                { __typename?: 'HarvestGQL' }
                & Pick<GqlHarvestGql, 'original_currency' | 'farmer_id' | 'weight' | 'batch_id'>
                & { farmer: Maybe<(
                  { __typename?: 'FarmerGQL' }
                  & Pick<GqlFarmerGql, 'first_name' | 'last_name' | 'farmer_number' | 'supplier_name'>
                )>, harvest_items: Maybe<Array<Maybe<(
                  { __typename?: 'HarvestItemGQL' }
                  & Pick<GqlHarvestItemGql, 'product_item_id' | 'quantity' | 'unit_of_measure_id' | 'weight' | 'packaging_unit_id' | 'packaging_unit_quantity'>
                  & { uom: Maybe<(
                    { __typename?: 'UOMV2GQL' }
                    & Pick<GqlUomv2Gql, 'name'>
                  )>, packaging_unit: Maybe<(
                    { __typename?: 'PackagingUnitV2GQL' }
                    & Pick<GqlPackagingUnitV2Gql, 'packaging_unit_id' | 'name'>
                  )>, product_item: Maybe<(
                    { __typename?: 'ProductItemV2GQL' }
                    & Pick<GqlProductItemV2Gql, 'product_item_name'>
                    & { product: Maybe<(
                      { __typename?: 'ProductV2GQL' }
                      & Pick<GqlProductV2Gql, 'product_name'>
                    )> }
                  )> }
                )>>> }
              )>>> }
            )>, warehouse_transfer_request: Maybe<(
              { __typename?: 'WarehouseTransferRequestGQL' }
              & Pick<GqlWarehouseTransferRequestGql, 'warehouse_transfer_request_id'>
              & { warehouse_transfer_request_items: Maybe<Array<Maybe<(
                { __typename?: 'WarehouseTransferRequestItemGQL' }
                & Pick<GqlWarehouseTransferRequestItemGql, 'active' | 'product_item_id' | 'quantity' | 'weight'>
                & { product_item: Maybe<(
                  { __typename?: 'ProductItemV2GQL' }
                  & Pick<GqlProductItemV2Gql, 'product_item_name'>
                  & { product: Maybe<(
                    { __typename?: 'ProductV2GQL' }
                    & Pick<GqlProductV2Gql, 'product_name'>
                  )> }
                )> }
              )>>> }
            )> }
          )>>> }
        )> }
      )> }
    )>> }
  )> }
);

export type GqlReturnsQueryVariables = Exact<{
  search: Maybe<Scalars['String']>;
  per_page: Maybe<Scalars['Int']>;
  page: Maybe<Scalars['Int']>;
  returnsFilters: Maybe<Scalars['GenericScalar']>;
}>;


export type GqlReturnsQuery = (
  { __typename?: 'Query' }
  & { returns: Maybe<(
    { __typename?: 'ReturnGQLConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'ReturnGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'ReturnGQL' }
        & Pick<GqlReturnGql, 'return_number' | 'return_id'>
        & { product_return_items: Maybe<Array<Maybe<(
          { __typename?: 'ProductReturnItemGQL' }
          & Pick<GqlProductReturnItemGql, 'return_id' | 'product_item_id' | 'unit_of_measure_id' | 'quantity' | 'return_type'>
          & { product_item: Maybe<(
            { __typename?: 'ProductItemV2GQL' }
            & Pick<GqlProductItemV2Gql, 'product_item_name' | 'product_id'>
            & { product: Maybe<(
              { __typename?: 'ProductV2GQL' }
              & Pick<GqlProductV2Gql, 'product_name'>
            )> }
          )>, uom: Maybe<(
            { __typename?: 'UOMV2GQL' }
            & Pick<GqlUomv2Gql, 'name'>
          )> }
        )>>> }
      )> }
    )>> }
  )> }
);

export type GqlWarehouseTransferReasonQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlWarehouseTransferReasonQuery = (
  { __typename?: 'Query' }
  & { warehouse_transfer_reason: Maybe<(
    { __typename?: 'WarehouseTransferReasonGQLConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'WarehouseTransferReasonGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'WarehouseTransferReasonGQL' }
        & Pick<GqlWarehouseTransferReasonGql, 'reason_id' | 'reason_name'>
      )> }
    )>> }
  )> }
);

export type GqlStockTakeQueryVariables = Exact<{
  search: Maybe<Scalars['String']>;
  per_page: Maybe<Scalars['Int']>;
  page: Maybe<Scalars['Int']>;
  filters: Maybe<Scalars['GenericScalar']>;
  distribution_center_id: Scalars['String'];
}>;


export type GqlStockTakeQuery = (
  { __typename?: 'Query' }
  & { paginated_product_items_v2: Maybe<(
    { __typename?: 'ProductItemV2GQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & Pick<GqlPaginatorInfo, 'per_page' | 'total_count' | 'total_pages' | 'current_page'>
    )>, edges: Array<Maybe<(
      { __typename?: 'ProductItemV2GQLEdge' }
      & { node: Maybe<(
        { __typename?: 'ProductItemV2GQL' }
        & Pick<GqlProductItemV2Gql, 'active' | 'product_item_name' | 'product_item_id'>
        & { product: Maybe<(
          { __typename?: 'ProductV2GQL' }
          & Pick<GqlProductV2Gql, 'product_name'>
        )>, units_of_measure: Maybe<Array<Maybe<(
          { __typename?: 'ProductItemUOMV2GQL' }
          & { business_domain: Maybe<(
            { __typename?: 'BusinessDomainV2GQL' }
            & Pick<GqlBusinessDomainV2Gql, 'domain_name'>
          )>, uom: Maybe<(
            { __typename?: 'UOMV2GQL' }
            & Pick<GqlUomv2Gql, 'abbreviation' | 'uom_id'>
          )> }
        )>>>, inventory: Maybe<(
          { __typename?: 'InventoryGQL' }
          & Pick<GqlInventoryGql, 'storage_quantity' | 'inventory_quantity' | 'verified_quantity' | 'distribution_center_id'>
        )> }
      )> }
    )>> }
  )> }
);

export type GqlStockIntakesQueryVariables = Exact<{
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  search: Maybe<Scalars['String']>;
  filter: Maybe<Scalars['GenericScalar']>;
}>;


export type GqlStockIntakesQuery = (
  { __typename?: 'Query' }
  & { stock_intakes: Maybe<(
    { __typename?: 'StockIntakeGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'StockIntakeGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'StockIntakeGQL' }
        & Pick<GqlStockIntakeGql, 'active' | 'stock_intake_id' | 'stock_intake_number' | 'stock_intake_status' | 'date_created' | 'created_by' | 'appointment_id'>
        & { user: Maybe<(
          { __typename?: 'UserV2GQL' }
          & Pick<GqlUserV2Gql, 'user_id' | 'first_name' | 'last_name'>
        )>, appointment: Maybe<(
          { __typename?: 'AppointmentGQL' }
          & Pick<GqlAppointmentGql, 'appointment_number' | 'vehicle_seal_number' | 'distribution_center_id'>
          & { dock_door: Maybe<(
            { __typename?: 'DockDoorGQL' }
            & Pick<GqlDockDoorGql, 'dock_door_number'>
          )>, distribution_center: Maybe<(
            { __typename?: 'DistributionCenterGQL' }
            & Pick<GqlDistributionCenterGql, 'distribution_center_name'>
          )>, appointment_items: Maybe<Array<Maybe<(
            { __typename?: 'AppointmentItemGQL' }
            & Pick<GqlAppointmentItemGql, 'batch_id' | 'booking_id' | 'warehouse_transfer_request_id'>
            & { batch: Maybe<(
              { __typename?: 'BatchGQL' }
              & Pick<GqlBatchGql, 'batch_name'>
            )>, booking: Maybe<(
              { __typename?: 'BookingGQL' }
              & Pick<GqlBookingGql, 'farmer_id'>
              & { farmer: Maybe<(
                { __typename?: 'FarmerGQL' }
                & Pick<GqlFarmerGql, 'first_name' | 'last_name' | 'farmer_number' | 'supplier_name' | 'farmer_id'>
              )>, booking_items: Maybe<Array<Maybe<(
                { __typename?: 'BookingItemGQL' }
                & Pick<GqlBookingItemGql, 'product_item_id'>
                & { product_item: Maybe<(
                  { __typename?: 'ProductItemV2GQL' }
                  & Pick<GqlProductItemV2Gql, 'product_item_name' | 'product_id'>
                  & { product: Maybe<(
                    { __typename?: 'ProductV2GQL' }
                    & Pick<GqlProductV2Gql, 'product_name'>
                  )> }
                )> }
              )>>> }
            )>, warehouse_transfer_request: Maybe<(
              { __typename?: 'WarehouseTransferRequestGQL' }
              & Pick<GqlWarehouseTransferRequestGql, 'warehouse_transfer_request_id'>
              & { warehouse_transfer_request_items: Maybe<Array<Maybe<(
                { __typename?: 'WarehouseTransferRequestItemGQL' }
                & Pick<GqlWarehouseTransferRequestItemGql, 'active' | 'product_item_id' | 'quantity' | 'weight'>
                & { product_item: Maybe<(
                  { __typename?: 'ProductItemV2GQL' }
                  & Pick<GqlProductItemV2Gql, 'product_item_name'>
                  & { product: Maybe<(
                    { __typename?: 'ProductV2GQL' }
                    & Pick<GqlProductV2Gql, 'product_name'>
                  )> }
                )> }
              )>>> }
            )> }
          )>>> }
        )> }
      )> }
    )>> }
  )> }
);

export type GqlStockIntakeItemsQueryVariables = Exact<{
  per_page: Maybe<Scalars['Int']>;
  search: Maybe<Scalars['String']>;
  filter: Maybe<Scalars['GenericScalar']>;
}>;


export type GqlStockIntakeItemsQuery = (
  { __typename?: 'Query' }
  & { stock_intakes: Maybe<(
    { __typename?: 'StockIntakeGQLConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'StockIntakeGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'StockIntakeGQL' }
        & Pick<GqlStockIntakeGql, 'active' | 'stock_intake_id' | 'stock_intake_number'>
        & { stock_intake_skus: Maybe<Array<Maybe<(
          { __typename?: 'StockIntakeSkuGQL' }
          & Pick<GqlStockIntakeSkuGql, 'product_item_id'>
          & { product_item: Maybe<(
            { __typename?: 'ProductItemV2GQL' }
            & Pick<GqlProductItemV2Gql, 'product_id' | 'product_item_name'>
            & { product: Maybe<(
              { __typename?: 'ProductV2GQL' }
              & Pick<GqlProductV2Gql, 'product_name'>
            )>, units_of_measure: Maybe<Array<Maybe<(
              { __typename?: 'ProductItemUOMV2GQL' }
              & Pick<GqlProductItemUomv2Gql, 'uom_id' | 'conversion_ratio'>
              & { business_domain: Maybe<(
                { __typename?: 'BusinessDomainV2GQL' }
                & Pick<GqlBusinessDomainV2Gql, 'domain_name'>
              )> }
            )>>> }
          )> }
        )>>>, stock_intake_items: Maybe<Array<Maybe<(
          { __typename?: 'StockIntakeItemGQL' }
          & Pick<GqlStockIntakeItemGql, 'stock_intake_item_id' | 'batch_id' | 'quantity' | 'active' | 'weight' | 'product_item_id' | 'unit_of_measure_id' | 'booking_id'>
          & { product_item: Maybe<(
            { __typename?: 'ProductItemV2GQL' }
            & Pick<GqlProductItemV2Gql, 'product_id' | 'product_item_name'>
            & { product: Maybe<(
              { __typename?: 'ProductV2GQL' }
              & Pick<GqlProductV2Gql, 'product_name'>
            )>, units_of_measure: Maybe<Array<Maybe<(
              { __typename?: 'ProductItemUOMV2GQL' }
              & Pick<GqlProductItemUomv2Gql, 'conversion_ratio'>
              & { business_domain: Maybe<(
                { __typename?: 'BusinessDomainV2GQL' }
                & Pick<GqlBusinessDomainV2Gql, 'domain_name'>
              )> }
            )>>> }
          )>, uom: Maybe<(
            { __typename?: 'UOMV2GQL' }
            & Pick<GqlUomv2Gql, 'name'>
          )>, booking: Maybe<(
            { __typename?: 'BookingGQL' }
            & Pick<GqlBookingGql, 'price'>
          )> }
        )>>> }
      )> }
    )>> }
  )> }
);

export type GqlStockIntakeSkusQueryVariables = Exact<{
  page: Maybe<Scalars['Int']>;
  filter: Maybe<Scalars['GenericScalar']>;
  per_page: Maybe<Scalars['Int']>;
  search: Maybe<Scalars['String']>;
}>;


export type GqlStockIntakeSkusQuery = (
  { __typename?: 'Query' }
  & { stock_intake_skus: Maybe<(
    { __typename?: 'StockIntakeSkuGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'StockIntakeSkuGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'StockIntakeSkuGQL' }
        & Pick<GqlStockIntakeSkuGql, 'active' | 'stock_intake_sku_id' | 'stock_intake_id' | 'product_item_id' | 'supplier_batch_number' | 'stock_date' | 'order_by_date' | 'stock_availability_status' | 'rejected_quantity' | 'rejection_reason'>
        & { product_item: Maybe<(
          { __typename?: 'ProductItemV2GQL' }
          & Pick<GqlProductItemV2Gql, 'product_id' | 'product_item_name'>
          & { product: Maybe<(
            { __typename?: 'ProductV2GQL' }
            & Pick<GqlProductV2Gql, 'product_name'>
          )>, units_of_measure: Maybe<Array<Maybe<(
            { __typename?: 'ProductItemUOMV2GQL' }
            & { business_domain: Maybe<(
              { __typename?: 'BusinessDomainV2GQL' }
              & Pick<GqlBusinessDomainV2Gql, 'domain_name'>
            )>, uom: Maybe<(
              { __typename?: 'UOMV2GQL' }
              & Pick<GqlUomv2Gql, 'name'>
            )> }
          )>>> }
        )>, stock_intake: Maybe<(
          { __typename?: 'StockIntakeGQL' }
          & Pick<GqlStockIntakeGql, 'stock_intake_status' | 'appointment_id'>
          & { stock_intake_items: Maybe<Array<Maybe<(
            { __typename?: 'StockIntakeItemGQL' }
            & Pick<GqlStockIntakeItemGql, 'active' | 'weight' | 'quantity' | 'unit_of_measure_id' | 'product_item_id'>
            & { stock_intake_item_assets: Maybe<Array<Maybe<(
              { __typename?: 'StockIntakeItemAssetGQL' }
              & Pick<GqlStockIntakeItemAssetGql, 'stock_intake_item_asset_id' | 'quantity'>
            )>>>, uom: Maybe<(
              { __typename?: 'UOMV2GQL' }
              & Pick<GqlUomv2Gql, 'name'>
            )>, product_item: Maybe<(
              { __typename?: 'ProductItemV2GQL' }
              & Pick<GqlProductItemV2Gql, 'product_item_name'>
              & { product: Maybe<(
                { __typename?: 'ProductV2GQL' }
                & Pick<GqlProductV2Gql, 'product_name'>
              )> }
            )> }
          )>>>, appointment: Maybe<(
            { __typename?: 'AppointmentGQL' }
            & { appointment_items: Maybe<Array<Maybe<(
              { __typename?: 'AppointmentItemGQL' }
              & Pick<GqlAppointmentItemGql, 'active' | 'appointment_id' | 'warehouse_transfer_id' | 'batch_id' | 'booking_id' | 'return_id' | 'warehouse_transfer_request_id'>
              & { warehouse_transfer: Maybe<(
                { __typename?: 'WarehouseTransferGQL' }
                & Pick<GqlWarehouseTransferGql, 'warehouse_transfer_id'>
                & { warehouse_transfer_items: Maybe<Array<Maybe<(
                  { __typename?: 'WarehouseTransferItemGQL' }
                  & Pick<GqlWarehouseTransferItemGql, 'active' | 'product_item_id' | 'quantity' | 'weight'>
                  & { product_item: Maybe<(
                    { __typename?: 'ProductItemV2GQL' }
                    & Pick<GqlProductItemV2Gql, 'product_item_name'>
                    & { product: Maybe<(
                      { __typename?: 'ProductV2GQL' }
                      & Pick<GqlProductV2Gql, 'product_name'>
                    )> }
                  )> }
                )>>> }
              )>, returns: Maybe<(
                { __typename?: 'ReturnGQL' }
                & Pick<GqlReturnGql, 'active' | 'return_id'>
                & { product_return_items: Maybe<Array<Maybe<(
                  { __typename?: 'ProductReturnItemGQL' }
                  & Pick<GqlProductReturnItemGql, 'active' | 'product_item_id' | 'quantity' | 'weight'>
                  & { product_item: Maybe<(
                    { __typename?: 'ProductItemV2GQL' }
                    & Pick<GqlProductItemV2Gql, 'product_item_name'>
                    & { product: Maybe<(
                      { __typename?: 'ProductV2GQL' }
                      & Pick<GqlProductV2Gql, 'product_name'>
                    )> }
                  )> }
                )>>> }
              )>, booking: Maybe<(
                { __typename?: 'BookingGQL' }
                & Pick<GqlBookingGql, 'booking_id'>
                & { booking_items: Maybe<Array<Maybe<(
                  { __typename?: 'BookingItemGQL' }
                  & Pick<GqlBookingItemGql, 'active' | 'product_item_id' | 'quantity' | 'weight'>
                  & { product_item: Maybe<(
                    { __typename?: 'ProductItemV2GQL' }
                    & Pick<GqlProductItemV2Gql, 'product_item_name'>
                    & { product: Maybe<(
                      { __typename?: 'ProductV2GQL' }
                      & Pick<GqlProductV2Gql, 'product_name'>
                    )> }
                  )> }
                )>>> }
              )>, batch: Maybe<(
                { __typename?: 'BatchGQL' }
                & Pick<GqlBatchGql, 'batch_name' | 'batch_id'>
                & { harvests: Maybe<Array<Maybe<(
                  { __typename?: 'HarvestGQL' }
                  & Pick<GqlHarvestGql, 'original_currency'>
                  & { harvest_items: Maybe<Array<Maybe<(
                    { __typename?: 'HarvestItemGQL' }
                    & Pick<GqlHarvestItemGql, 'product_item_id' | 'quantity' | 'weight'>
                    & { product_item: Maybe<(
                      { __typename?: 'ProductItemV2GQL' }
                      & Pick<GqlProductItemV2Gql, 'product_item_name'>
                      & { product: Maybe<(
                        { __typename?: 'ProductV2GQL' }
                        & Pick<GqlProductV2Gql, 'product_name'>
                      )> }
                    )> }
                  )>>> }
                )>>> }
              )>, warehouse_transfer_request: Maybe<(
                { __typename?: 'WarehouseTransferRequestGQL' }
                & Pick<GqlWarehouseTransferRequestGql, 'warehouse_transfer_request_id'>
                & { warehouse_transfer_request_items: Maybe<Array<Maybe<(
                  { __typename?: 'WarehouseTransferRequestItemGQL' }
                  & Pick<GqlWarehouseTransferRequestItemGql, 'active' | 'product_item_id' | 'quantity' | 'weight'>
                  & { product_item: Maybe<(
                    { __typename?: 'ProductItemV2GQL' }
                    & Pick<GqlProductItemV2Gql, 'product_item_name'>
                    & { product: Maybe<(
                      { __typename?: 'ProductV2GQL' }
                      & Pick<GqlProductV2Gql, 'product_name'>
                    )> }
                  )> }
                )>>> }
              )> }
            )>>> }
          )> }
        )> }
      )> }
    )>> }
  )> }
);

export type GqlStockIntakeByIdQueryVariables = Exact<{
  stock_intake_id: Maybe<Scalars['String']>;
}>;


export type GqlStockIntakeByIdQuery = (
  { __typename?: 'Query' }
  & { stock_intake_by_id: Maybe<(
    { __typename?: 'StockIntakeGQL' }
    & Pick<GqlStockIntakeGql, 'active' | 'stock_intake_number' | 'stock_intake_id' | 'appointment_id' | 'date_created' | 'created_by' | 'stock_intake_status' | 'decline_reason'>
    & { stock_intake_photos: Maybe<Array<Maybe<(
      { __typename?: 'StockIntakePhotoGQL' }
      & Pick<GqlStockIntakePhotoGql, 'intake_photo_id'>
    )>>>, stock_intake_users: Maybe<Array<Maybe<(
      { __typename?: 'StockIntakeUserGQL' }
      & Pick<GqlStockIntakeUserGql, 'active' | 'user_id' | 'stock_intake_user_id'>
      & { user: Maybe<(
        { __typename?: 'UserV2GQL' }
        & Pick<GqlUserV2Gql, 'first_name' | 'last_name' | 'phone_number' | 'user_number'>
      )> }
    )>>>, appointment: Maybe<(
      { __typename?: 'AppointmentGQL' }
      & Pick<GqlAppointmentGql, 'appointment_number' | 'vehicle_registration' | 'vehicle_seal_number' | 'driver_name' | 'issued_by' | 'distribution_center_id'>
      & { issued_by_user: Maybe<(
        { __typename?: 'UserV2GQL' }
        & Pick<GqlUserV2Gql, 'first_name' | 'last_name'>
      )>, dock_door: Maybe<(
        { __typename?: 'DockDoorGQL' }
        & Pick<GqlDockDoorGql, 'dock_door_number' | 'dock_door_id'>
      )>, distribution_center: Maybe<(
        { __typename?: 'DistributionCenterGQL' }
        & Pick<GqlDistributionCenterGql, 'distribution_center_name'>
      )>, appointment_items: Maybe<Array<Maybe<(
        { __typename?: 'AppointmentItemGQL' }
        & Pick<GqlAppointmentItemGql, 'active' | 'appointment_id' | 'warehouse_transfer_id' | 'batch_id' | 'booking_id' | 'return_id' | 'warehouse_transfer_request_id'>
        & { warehouse_transfer: Maybe<(
          { __typename?: 'WarehouseTransferGQL' }
          & Pick<GqlWarehouseTransferGql, 'warehouse_transfer_id'>
          & { warehouse_transfer_items: Maybe<Array<Maybe<(
            { __typename?: 'WarehouseTransferItemGQL' }
            & Pick<GqlWarehouseTransferItemGql, 'active' | 'product_item_id' | 'unit_of_measure_id' | 'quantity'>
            & { product_item: Maybe<(
              { __typename?: 'ProductItemV2GQL' }
              & Pick<GqlProductItemV2Gql, 'product_item_name'>
              & { product: Maybe<(
                { __typename?: 'ProductV2GQL' }
                & Pick<GqlProductV2Gql, 'product_name'>
              )> }
            )>, uom: Maybe<(
              { __typename?: 'UOMV2GQL' }
              & Pick<GqlUomv2Gql, 'name'>
            )> }
          )>>> }
        )>, returns: Maybe<(
          { __typename?: 'ReturnGQL' }
          & Pick<GqlReturnGql, 'active' | 'return_id'>
          & { product_return_items: Maybe<Array<Maybe<(
            { __typename?: 'ProductReturnItemGQL' }
            & Pick<GqlProductReturnItemGql, 'active' | 'product_item_id' | 'quantity' | 'unit_of_measure_id'>
            & { product_item: Maybe<(
              { __typename?: 'ProductItemV2GQL' }
              & Pick<GqlProductItemV2Gql, 'product_item_name'>
              & { product: Maybe<(
                { __typename?: 'ProductV2GQL' }
                & Pick<GqlProductV2Gql, 'product_name'>
              )> }
            )>, uom: Maybe<(
              { __typename?: 'UOMV2GQL' }
              & Pick<GqlUomv2Gql, 'name'>
            )> }
          )>>> }
        )>, booking: Maybe<(
          { __typename?: 'BookingGQL' }
          & Pick<GqlBookingGql, 'booking_id' | 'farmer_id'>
          & { farmer: Maybe<(
            { __typename?: 'FarmerGQL' }
            & Pick<GqlFarmerGql, 'first_name' | 'last_name' | 'farmer_number'>
          )>, booking_items: Maybe<Array<Maybe<(
            { __typename?: 'BookingItemGQL' }
            & Pick<GqlBookingItemGql, 'active' | 'product_item_id' | 'quantity' | 'unit_of_measure_id'>
            & { product_item: Maybe<(
              { __typename?: 'ProductItemV2GQL' }
              & Pick<GqlProductItemV2Gql, 'product_item_name'>
              & { product: Maybe<(
                { __typename?: 'ProductV2GQL' }
                & Pick<GqlProductV2Gql, 'product_name'>
              )> }
            )>, uom: Maybe<(
              { __typename?: 'UOMV2GQL' }
              & Pick<GqlUomv2Gql, 'name'>
            )> }
          )>>> }
        )>, batch: Maybe<(
          { __typename?: 'BatchGQL' }
          & Pick<GqlBatchGql, 'batch_name' | 'batch_id'>
          & { harvests: Maybe<Array<Maybe<(
            { __typename?: 'HarvestGQL' }
            & Pick<GqlHarvestGql, 'original_currency' | 'farmer_id'>
            & { farmer: Maybe<(
              { __typename?: 'FarmerGQL' }
              & Pick<GqlFarmerGql, 'first_name' | 'last_name' | 'farmer_number'>
            )>, harvest_items: Maybe<Array<Maybe<(
              { __typename?: 'HarvestItemGQL' }
              & Pick<GqlHarvestItemGql, 'product_item_id' | 'quantity' | 'unit_of_measure_id'>
              & { uom: Maybe<(
                { __typename?: 'UOMV2GQL' }
                & Pick<GqlUomv2Gql, 'name'>
              )>, product_item: Maybe<(
                { __typename?: 'ProductItemV2GQL' }
                & Pick<GqlProductItemV2Gql, 'product_item_name'>
                & { product: Maybe<(
                  { __typename?: 'ProductV2GQL' }
                  & Pick<GqlProductV2Gql, 'product_name'>
                )> }
              )> }
            )>>> }
          )>>> }
        )>, warehouse_transfer_request: Maybe<(
          { __typename?: 'WarehouseTransferRequestGQL' }
          & Pick<GqlWarehouseTransferRequestGql, 'warehouse_transfer_request_id'>
          & { warehouse_transfer_request_items: Maybe<Array<Maybe<(
            { __typename?: 'WarehouseTransferRequestItemGQL' }
            & Pick<GqlWarehouseTransferRequestItemGql, 'active' | 'product_item_id' | 'quantity' | 'weight'>
            & { product_item: Maybe<(
              { __typename?: 'ProductItemV2GQL' }
              & Pick<GqlProductItemV2Gql, 'product_item_name'>
              & { product: Maybe<(
                { __typename?: 'ProductV2GQL' }
                & Pick<GqlProductV2Gql, 'product_name'>
              )> }
            )> }
          )>>> }
        )> }
      )>>> }
    )>, user: Maybe<(
      { __typename?: 'UserV2GQL' }
      & Pick<GqlUserV2Gql, 'first_name' | 'last_name'>
    )>, stock_intake_items: Maybe<Array<Maybe<(
      { __typename?: 'StockIntakeItemGQL' }
      & Pick<GqlStockIntakeItemGql, 'stock_intake_item_id' | 'quantity' | 'active' | 'weight' | 'product_item_id' | 'unit_of_measure_id' | 'pallet_id' | 'stock_intake_item_status'>
      & { product_item: Maybe<(
        { __typename?: 'ProductItemV2GQL' }
        & Pick<GqlProductItemV2Gql, 'product_id' | 'product_item_name'>
        & { product: Maybe<(
          { __typename?: 'ProductV2GQL' }
          & Pick<GqlProductV2Gql, 'product_name'>
        )> }
      )>, uom: Maybe<(
        { __typename?: 'UOMV2GQL' }
        & Pick<GqlUomv2Gql, 'name'>
      )>, pallet: Maybe<(
        { __typename?: 'AssetGQL' }
        & Pick<GqlAssetGql, 'asset_number'>
      )> }
    )>>> }
  )> }
);

export type GqlStockIntakeStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlStockIntakeStatusQuery = (
  { __typename?: 'Query' }
  & Pick<GqlQuery, 'stock_intake_status'>
);

export type GqlStockAvailabilityStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlStockAvailabilityStatusQuery = (
  { __typename?: 'Query' }
  & Pick<GqlQuery, 'stock_availability_status'>
);

export type GqlIntakesPutAwayJobsQueryVariables = Exact<{
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  search: Maybe<Scalars['String']>;
  filter: Maybe<Scalars['GenericScalar']>;
}>;


export type GqlIntakesPutAwayJobsQuery = (
  { __typename?: 'Query' }
  & { putaway_jobs: Maybe<(
    { __typename?: 'PutAwayJobGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'PutAwayJobGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'PutAwayJobGQL' }
        & Pick<GqlPutAwayJobGql, 'active' | 'stock_intake_id' | 'job_id' | 'job_number' | 'job_status' | 'assigned_to' | 'product_item_id' | 'source_location_id'>
        & { assigned: Maybe<(
          { __typename?: 'UserV2GQL' }
          & Pick<GqlUserV2Gql, 'first_name' | 'last_name'>
        )>, product_item: Maybe<(
          { __typename?: 'ProductItemV2GQL' }
          & Pick<GqlProductItemV2Gql, 'product_item_name'>
          & { product: Maybe<(
            { __typename?: 'ProductV2GQL' }
            & Pick<GqlProductV2Gql, 'product_name'>
          )> }
        )>, destination_handling_unit: Maybe<(
          { __typename?: 'HandlingUnitGQL' }
          & Pick<GqlHandlingUnitGql, 'handling_unit_id' | 'quantity'>
          & { asset: Maybe<(
            { __typename?: 'AssetGQL' }
            & Pick<GqlAssetGql, 'asset_number'>
            & { asset_type: Maybe<(
              { __typename?: 'AssetTypeGQL' }
              & Pick<GqlAssetTypeGql, 'asset_type_name'>
            )> }
          )> }
        )>, source_location: Maybe<(
          { __typename?: 'StorageLocationGQL' }
          & { dock_door: Maybe<Array<Maybe<(
            { __typename?: 'DockDoorGQL' }
            & Pick<GqlDockDoorGql, 'dock_door_number'>
          )>>> }
        )>, destination_location: Maybe<(
          { __typename?: 'StorageLocationGQL' }
          & { bin: Maybe<Array<Maybe<(
            { __typename?: 'BinGQL' }
            & Pick<GqlBinGql, 'aisle' | 'bin_position' | 'level'>
            & { bin_names: Maybe<Array<Maybe<(
              { __typename?: 'BinNameGQL' }
              & Pick<GqlBinNameGql, 'bin_name_direction' | 'aisle' | 'bin_position' | 'level'>
            )>>> }
          )>>> }
        )> }
      )> }
    )>> }
  )> }
);

export type GqlStockIntakeUsersQueryVariables = Exact<{
  stock_intake_id: Maybe<Scalars['String']>;
}>;


export type GqlStockIntakeUsersQuery = (
  { __typename?: 'Query' }
  & { stock_intake_by_id: Maybe<(
    { __typename?: 'StockIntakeGQL' }
    & Pick<GqlStockIntakeGql, 'stock_intake_id'>
    & { stock_intake_users: Maybe<Array<Maybe<(
      { __typename?: 'StockIntakeUserGQL' }
      & Pick<GqlStockIntakeUserGql, 'active' | 'user_id' | 'stock_intake_user_id'>
      & { user: Maybe<(
        { __typename?: 'UserV2GQL' }
        & Pick<GqlUserV2Gql, 'active' | 'first_name' | 'last_name'>
      )> }
    )>>> }
  )> }
);

export type GqlPutAwayJobsFragment = (
  { __typename?: 'PutAwayJobGQL' }
  & Pick<GqlPutAwayJobGql, 'active' | 'stock_intake_id' | 'quantity' | 'job_id' | 'job_number' | 'job_status' | 'assigned_to' | 'product_item_id' | 'source_location_id'>
  & { assigned: Maybe<(
    { __typename?: 'UserV2GQL' }
    & Pick<GqlUserV2Gql, 'first_name' | 'last_name'>
  )>, product_item: Maybe<(
    { __typename?: 'ProductItemV2GQL' }
    & Pick<GqlProductItemV2Gql, 'product_item_name'>
    & { product: Maybe<(
      { __typename?: 'ProductV2GQL' }
      & Pick<GqlProductV2Gql, 'product_name'>
    )> }
  )>, destination_handling_unit: Maybe<(
    { __typename?: 'HandlingUnitGQL' }
    & Pick<GqlHandlingUnitGql, 'handling_unit_id'>
    & { asset: Maybe<(
      { __typename?: 'AssetGQL' }
      & Pick<GqlAssetGql, 'asset_number'>
      & { asset_type: Maybe<(
        { __typename?: 'AssetTypeGQL' }
        & Pick<GqlAssetTypeGql, 'asset_type_name'>
      )> }
    )> }
  )>, source_location: Maybe<(
    { __typename?: 'StorageLocationGQL' }
    & { dock_door: Maybe<Array<Maybe<(
      { __typename?: 'DockDoorGQL' }
      & Pick<GqlDockDoorGql, 'dock_door_number'>
    )>>> }
  )>, destination_location: Maybe<(
    { __typename?: 'StorageLocationGQL' }
    & { bin: Maybe<Array<Maybe<(
      { __typename?: 'BinGQL' }
      & Pick<GqlBinGql, 'aisle' | 'bin_position' | 'level'>
      & { bin_names: Maybe<Array<Maybe<(
        { __typename?: 'BinNameGQL' }
        & Pick<GqlBinNameGql, 'bin_name_direction' | 'aisle' | 'bin_position' | 'level'>
      )>>> }
    )>>> }
  )> }
);

export type GqlPutAwayJobsQueryVariables = Exact<{
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  search: Maybe<Scalars['String']>;
  filter: Maybe<Scalars['GenericScalar']>;
}>;


export type GqlPutAwayJobsQuery = (
  { __typename?: 'Query' }
  & { putaway_jobs: Maybe<(
    { __typename?: 'PutAwayJobGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'PutAwayJobGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'PutAwayJobGQL' }
        & Pick<GqlPutAwayJobGql, 'date_created' | 'close_time' | 'start_time' | 'stock_intake_item_id' | 'cancelled'>
        & { stock_intake: Maybe<(
          { __typename?: 'StockIntakeGQL' }
          & Pick<GqlStockIntakeGql, 'stock_intake_number' | 'stock_intake_id'>
        )> }
        & GqlPutAwayJobsFragment
      )> }
    )>> }
  )> }
);

export type GqlReservePickingJobsFragmentFragment = (
  { __typename?: 'ReservePickingJobGQL' }
  & Pick<GqlReservePickingJobGql, 'active' | 'cancelled' | 'job_number' | 'job_id' | 'quantity' | 'handling_unit_id' | 'source_location_id' | 'assigned_to' | 'job_status' | 'product_item_id'>
  & { handling_unit: Maybe<(
    { __typename?: 'HandlingUnitGQL' }
    & Pick<GqlHandlingUnitGql, 'quantity' | 'asset_id'>
    & { asset: Maybe<(
      { __typename?: 'AssetGQL' }
      & Pick<GqlAssetGql, 'asset_number'>
    )> }
  )>, source_location: Maybe<(
    { __typename?: 'StorageLocationGQL' }
    & { bin: Maybe<Array<Maybe<(
      { __typename?: 'BinGQL' }
      & Pick<GqlBinGql, 'aisle' | 'bin_position' | 'level'>
      & { bin_names: Maybe<Array<Maybe<(
        { __typename?: 'BinNameGQL' }
        & Pick<GqlBinNameGql, 'bin_name_direction' | 'aisle' | 'bin_position' | 'level'>
      )>>> }
    )>>> }
  )>, assigned: Maybe<(
    { __typename?: 'UserV2GQL' }
    & Pick<GqlUserV2Gql, 'first_name' | 'last_name'>
  )>, product_item: Maybe<(
    { __typename?: 'ProductItemV2GQL' }
    & Pick<GqlProductItemV2Gql, 'product_item_name'>
    & { units_of_measure: Maybe<Array<Maybe<(
      { __typename?: 'ProductItemUOMV2GQL' }
      & { uom: Maybe<(
        { __typename?: 'UOMV2GQL' }
        & Pick<GqlUomv2Gql, 'name'>
      )> }
    )>>>, product: Maybe<(
      { __typename?: 'ProductV2GQL' }
      & Pick<GqlProductV2Gql, 'product_name'>
    )> }
  )> }
);

export type GqlAllReservePickingJobsQueryVariables = Exact<{
  search: Maybe<Scalars['String']>;
  filter: Maybe<Scalars['GenericScalar']>;
  per_page: Maybe<Scalars['Int']>;
  page: Maybe<Scalars['Int']>;
}>;


export type GqlAllReservePickingJobsQuery = (
  { __typename?: 'Query' }
  & { reserve_picking_jobs: Maybe<(
    { __typename?: 'ReservePickingJobGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'ReservePickingJobGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'ReservePickingJobGQL' }
        & Pick<GqlReservePickingJobGql, 'date_created' | 'close_time' | 'start_time' | 'job_type' | 'warehouse_transfer_request_id' | 'fdr_id'>
        & { warehouse_transfer_request: Maybe<(
          { __typename?: 'WarehouseTransferRequestGQL' }
          & Pick<GqlWarehouseTransferRequestGql, 'warehouse_transfer_request_number'>
        )>, fdr: Maybe<(
          { __typename?: 'FulfilmentDispatchRequestGQL' }
          & Pick<GqlFulfilmentDispatchRequestGql, 'fdr_number'>
        )>, destination_location: Maybe<(
          { __typename?: 'StorageLocationGQL' }
          & { dock_door: Maybe<Array<Maybe<(
            { __typename?: 'DockDoorGQL' }
            & Pick<GqlDockDoorGql, 'dock_door_number'>
          )>>>, bin: Maybe<Array<Maybe<(
            { __typename?: 'BinGQL' }
            & Pick<GqlBinGql, 'aisle' | 'bin_position' | 'level'>
            & { bin_names: Maybe<Array<Maybe<(
              { __typename?: 'BinNameGQL' }
              & Pick<GqlBinNameGql, 'bin_name_direction' | 'aisle' | 'bin_position' | 'level'>
            )>>> }
          )>>> }
        )> }
        & GqlReservePickingJobsFragmentFragment
      )> }
    )>> }
  )> }
);

export type GqlProductItemPickfacesBinQueryVariables = Exact<{
  page: Maybe<Scalars['Int']>;
  per_page: Maybe<Scalars['Int']>;
  search: Maybe<Scalars['String']>;
  filter: Maybe<Scalars['GenericScalar']>;
}>;


export type GqlProductItemPickfacesBinQuery = (
  { __typename?: 'Query' }
  & { bins: Maybe<(
    { __typename?: 'BinGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'BinGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'BinGQL' }
        & Pick<GqlBinGql, 'pickface_product_item_id' | 'active' | 'bin_type' | 'is_pickface' | 'storage_location_id' | 'capacity' | 'bin_id'>
        & { storage_location: Maybe<(
          { __typename?: 'StorageLocationGQL' }
          & Pick<GqlStorageLocationGql, 'storage_location_number'>
        )>, product_zone: Maybe<Array<Maybe<(
          { __typename?: 'ProductZoneGQL' }
          & Pick<GqlProductZoneGql, 'active' | 'product_zone_id' | 'product_zone_name'>
        )>>>, pickface_product_item: Maybe<(
          { __typename?: 'ProductItemV2GQL' }
          & Pick<GqlProductItemV2Gql, 'product_item_id' | 'product_item_name' | 'product_id'>
          & { product: Maybe<(
            { __typename?: 'ProductV2GQL' }
            & Pick<GqlProductV2Gql, 'product_name'>
          )> }
        )> }
      )> }
    )>> }
  )> }
);

export type GqlLocationsQueryVariables = Exact<{
  search: Maybe<Scalars['String']>;
}>;


export type GqlLocationsQuery = (
  { __typename?: 'Query' }
  & { storage_locations: Maybe<(
    { __typename?: 'StorageLocationGQLConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'StorageLocationGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'StorageLocationGQL' }
        & Pick<GqlStorageLocationGql, 'status' | 'storage_location_id' | 'storage_location_number' | 'active'>
        & { bin: Maybe<Array<Maybe<(
          { __typename?: 'BinGQL' }
          & Pick<GqlBinGql, 'bin_id' | 'capacity' | 'is_pickface'>
          & { product_zone: Maybe<Array<Maybe<(
            { __typename?: 'ProductZoneGQL' }
            & Pick<GqlProductZoneGql, 'active' | 'product_zone_id' | 'product_zone_name'>
          )>>> }
        )>>>, location_type: Maybe<(
          { __typename?: 'StorageLocationTypeGQL' }
          & Pick<GqlStorageLocationTypeGql, 'location_type_name'>
        )>, handling_units: Maybe<Array<Maybe<(
          { __typename?: 'HandlingUnitGQL' }
          & Pick<GqlHandlingUnitGql, 'quantity' | 'stock_date' | 'stock_availability_status' | 'order_by_date' | 'active' | 'handling_unit_id'>
          & { asset: Maybe<(
            { __typename?: 'AssetGQL' }
            & Pick<GqlAssetGql, 'asset_id' | 'asset_number'>
          )>, handling_unit_items: Maybe<Array<Maybe<(
            { __typename?: 'HandlingUnitItemGQL' }
            & Pick<GqlHandlingUnitItemGql, 'dispatched' | 'active' | 'product_item_id' | 'handling_unit_item_id'>
            & { product_item: Maybe<(
              { __typename?: 'ProductItemV2GQL' }
              & Pick<GqlProductItemV2Gql, 'product_item_name'>
              & { product: Maybe<(
                { __typename?: 'ProductV2GQL' }
                & Pick<GqlProductV2Gql, 'product_name'>
              )> }
            )> }
          )>>> }
        )>>> }
      )> }
    )>> }
  )> }
);

export type GqlProductLocationsQueryVariables = Exact<{
  per_page: Maybe<Scalars['Int']>;
  page: Maybe<Scalars['Int']>;
  search: Maybe<Scalars['String']>;
  productLocationFilters: Maybe<Scalars['GenericScalar']>;
}>;


export type GqlProductLocationsQuery = (
  { __typename?: 'Query' }
  & { product_locations: Maybe<(
    { __typename?: 'ProductLocationGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'ProductLocationGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'ProductLocationGQL' }
        & Pick<GqlProductLocationGql, 'storage_location_id' | 'storage_location_number' | 'product_item_id' | 'hu_count' | 'hui_count'>
        & { product_item: Maybe<(
          { __typename?: 'ProductItemV2GQL' }
          & Pick<GqlProductItemV2Gql, 'product_id' | 'image_url' | 'product_item_name'>
          & { product: Maybe<(
            { __typename?: 'ProductV2GQL' }
            & Pick<GqlProductV2Gql, 'product_id' | 'product_name'>
          )> }
        )> }
      )> }
    )>> }
  )> }
);

export type GqlProductTransferReasonsQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlProductTransferReasonsQuery = (
  { __typename?: 'Query' }
  & { product_transfer_reasons: Maybe<(
    { __typename?: 'ProductTransferReasonGQLConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'ProductTransferReasonGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'ProductTransferReasonGQL' }
        & Pick<GqlProductTransferReasonGql, 'product_transfer_reason_id' | 'product_transfer_reason_name'>
      )> }
    )>> }
  )> }
);

export type GqlHandlingUnitItemsQueryVariables = Exact<{
  filter: Maybe<Scalars['GenericScalar']>;
}>;


export type GqlHandlingUnitItemsQuery = (
  { __typename?: 'Query' }
  & { handling_unit_items: Maybe<(
    { __typename?: 'HandlingUnitItemGQLConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'HandlingUnitItemGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'HandlingUnitItemGQL' }
        & Pick<GqlHandlingUnitItemGql, 'active' | 'handling_unit_item_id' | 'quantity' | 'dispatched' | 'stock_date' | 'stock_availability_status' | 'order_by_date' | 'product_item_id'>
        & { asset: Maybe<(
          { __typename?: 'AssetGQL' }
          & Pick<GqlAssetGql, 'asset_number'>
          & { asset_type: Maybe<(
            { __typename?: 'AssetTypeGQL' }
            & Pick<GqlAssetTypeGql, 'asset_type_name'>
          )> }
        )>, storage_location: Maybe<(
          { __typename?: 'StorageLocationGQL' }
          & Pick<GqlStorageLocationGql, 'storage_location_number'>
          & { location_type: Maybe<(
            { __typename?: 'StorageLocationTypeGQL' }
            & Pick<GqlStorageLocationTypeGql, 'location_type_name'>
          )> }
        )>, handling_unit: Maybe<(
          { __typename?: 'HandlingUnitGQL' }
          & { asset: Maybe<(
            { __typename?: 'AssetGQL' }
            & Pick<GqlAssetGql, 'asset_number'>
            & { asset_type: Maybe<(
              { __typename?: 'AssetTypeGQL' }
              & Pick<GqlAssetTypeGql, 'asset_type_name'>
            )> }
          )> }
        )>, product_item: Maybe<(
          { __typename?: 'ProductItemV2GQL' }
          & Pick<GqlProductItemV2Gql, 'product_item_name' | 'product_id'>
          & { product: Maybe<(
            { __typename?: 'ProductV2GQL' }
            & Pick<GqlProductV2Gql, 'product_name'>
          )> }
        )> }
      )> }
    )>> }
  )> }
);

export type GqlAssetsQueryVariables = Exact<{
  filter: Maybe<Scalars['GenericScalar']>;
  search: Maybe<Scalars['String']>;
}>;


export type GqlAssetsQuery = (
  { __typename?: 'Query' }
  & { assets: Maybe<(
    { __typename?: 'AssetGQLConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'AssetGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'AssetGQL' }
        & Pick<GqlAssetGql, 'asset_id' | 'active' | 'asset_number' | 'asset_type_id'>
        & { asset_type: Maybe<(
          { __typename?: 'AssetTypeGQL' }
          & Pick<GqlAssetTypeGql, 'asset_type_name'>
        )>, handling_units: Maybe<Array<Maybe<(
          { __typename?: 'HandlingUnitGQL' }
          & Pick<GqlHandlingUnitGql, 'active' | 'quantity' | 'storage_location_id' | 'stock_availability_status' | 'stock_date' | 'order_by_date' | 'handling_unit_id'>
          & { storage_location: Maybe<(
            { __typename?: 'StorageLocationGQL' }
            & Pick<GqlStorageLocationGql, 'storage_location_number'>
            & { location_type: Maybe<(
              { __typename?: 'StorageLocationTypeGQL' }
              & Pick<GqlStorageLocationTypeGql, 'location_type_name'>
            )> }
          )>, asset: Maybe<(
            { __typename?: 'AssetGQL' }
            & Pick<GqlAssetGql, 'asset_id' | 'asset_number'>
            & { asset_type: Maybe<(
              { __typename?: 'AssetTypeGQL' }
              & Pick<GqlAssetTypeGql, 'asset_type_name'>
            )> }
          )>, handling_unit_items: Maybe<Array<Maybe<(
            { __typename?: 'HandlingUnitItemGQL' }
            & Pick<GqlHandlingUnitItemGql, 'dispatched' | 'storage_location_id' | 'active' | 'product_item_id' | 'handling_unit_id' | 'handling_unit_item_id' | 'date_updated' | 'order_by_date'>
            & { asset: Maybe<(
              { __typename?: 'AssetGQL' }
              & Pick<GqlAssetGql, 'asset_id' | 'asset_number'>
              & { asset_type: Maybe<(
                { __typename?: 'AssetTypeGQL' }
                & Pick<GqlAssetTypeGql, 'asset_type_name'>
              )> }
            )>, product_item: Maybe<(
              { __typename?: 'ProductItemV2GQL' }
              & Pick<GqlProductItemV2Gql, 'product_id' | 'product_item_id' | 'product_item_name'>
              & { product: Maybe<(
                { __typename?: 'ProductV2GQL' }
                & Pick<GqlProductV2Gql, 'product_name'>
              )> }
            )> }
          )>>> }
        )>>> }
      )> }
    )>> }
  )> }
);

export type GqlFulfilmentDispatchReQuestFragment = (
  { __typename?: 'FulfilmentDispatchRequestGQL' }
  & Pick<GqlFulfilmentDispatchRequestGql, 'fdr_number' | 'fdr_id' | 'active' | 'fdr_complete' | 'sale_date' | 'date_created' | 'order_time_start' | 'order_time_end' | 'dock_door_id'>
  & { dock_door: Maybe<(
    { __typename?: 'DockDoorGQL' }
    & Pick<GqlDockDoorGql, 'dock_door_number' | 'staging_area_name' | 'warehouse_id'>
    & { warehouse: Maybe<(
      { __typename?: 'WarehouseGQL' }
      & Pick<GqlWarehouseGql, 'distribution_center_id'>
      & { distribution_center: Maybe<(
        { __typename?: 'DistributionCenterGQL' }
        & Pick<GqlDistributionCenterGql, 'distribution_center_name'>
      )> }
    )> }
  )>, fdr_items: Maybe<Array<Maybe<(
    { __typename?: 'FulfilmentDispatchRequestItemGQL' }
    & Pick<GqlFulfilmentDispatchRequestItemGql, 'route_id' | 'route_plan_id'>
    & { route: Maybe<(
      { __typename?: 'RouteV2GQL' }
      & Pick<GqlRouteV2Gql, 'route_name' | 'depot_id'>
      & { depot: Maybe<(
        { __typename?: 'DepotV2GQL' }
        & Pick<GqlDepotV2Gql, 'depot_name'>
      )> }
    )>, route_plan: Maybe<(
      { __typename?: 'RoutePlanGQL' }
      & Pick<GqlRoutePlanGql, 'multiple_trips' | 'vehicle_trip' | 'vehicle_id' | 'depot_id'>
      & { vehicle: Maybe<(
        { __typename?: 'VehicleGQL' }
        & Pick<GqlVehicleGql, 'registration_number'>
      )>, depot: Maybe<(
        { __typename?: 'DepotV2GQL' }
        & Pick<GqlDepotV2Gql, 'depot_name'>
      )> }
    )> }
  )>>> }
);

export type GqlFdrSkusFragment = (
  { __typename?: 'FulfilmentDispatchRequestGQL' }
  & { fdr_skus: Maybe<Array<Maybe<(
    { __typename?: 'FDRSKUGQL' }
    & Pick<GqlFdrskugql, 'active' | 'product_item_id' | 'quantity' | 'loaded_quantity' | 'available_stock' | 'amount_planned_from_reserve' | 'amount_planned_from_pickface'>
    & { product_item: Maybe<(
      { __typename?: 'ProductItemV2GQL' }
      & Pick<GqlProductItemV2Gql, 'product_item_name' | 'product_id'>
      & { product: Maybe<(
        { __typename?: 'ProductV2GQL' }
        & Pick<GqlProductV2Gql, 'product_name'>
      )>, units_of_measure: Maybe<Array<Maybe<(
        { __typename?: 'ProductItemUOMV2GQL' }
        & { business_domain: Maybe<(
          { __typename?: 'BusinessDomainV2GQL' }
          & Pick<GqlBusinessDomainV2Gql, 'domain_name' | 'business_domain_id'>
        )>, uom: Maybe<(
          { __typename?: 'UOMV2GQL' }
          & Pick<GqlUomv2Gql, 'name'>
        )> }
      )>>> }
    )> }
  )>>> }
);

export type GqlOrderFulfilmentQueryVariables = Exact<{
  search: Maybe<Scalars['String']>;
  orderFulfilmentFilters: Maybe<Scalars['GenericScalar']>;
  per_page: Maybe<Scalars['Int']>;
  page: Maybe<Scalars['Int']>;
}>;


export type GqlOrderFulfilmentQuery = (
  { __typename?: 'Query' }
  & { fulfilment_dispatch_requests: Maybe<(
    { __typename?: 'FulfilmentDispatchRequestGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'FulfilmentDispatchRequestGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'FulfilmentDispatchRequestGQL' }
        & Pick<GqlFulfilmentDispatchRequestGql, 'distribution_center_id'>
        & { distribution_center: Maybe<(
          { __typename?: 'DistributionCenterGQL' }
          & Pick<GqlDistributionCenterGql, 'distribution_center_name'>
        )> }
        & GqlFulfilmentDispatchReQuestFragment
      )> }
    )>> }
  )> }
);

export type GqlOrderFulfilmentByIdQueryVariables = Exact<{
  search: Maybe<Scalars['String']>;
  orderFulfilmentFilters: Maybe<Scalars['GenericScalar']>;
  per_page: Maybe<Scalars['Int']>;
}>;


export type GqlOrderFulfilmentByIdQuery = (
  { __typename?: 'Query' }
  & { fulfilment_dispatch_requests: Maybe<(
    { __typename?: 'FulfilmentDispatchRequestGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'FulfilmentDispatchRequestGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'FulfilmentDispatchRequestGQL' }
        & Pick<GqlFulfilmentDispatchRequestGql, 'created_by'>
        & { loading_task: Maybe<(
          { __typename?: 'LoadingTaskGQL' }
          & { loaded_quantity: Maybe<Array<Maybe<(
            { __typename?: 'LoadedQuantityGQL' }
            & Pick<GqlLoadedQuantityGql, 'quantity'>
          )>>> }
        )>, created_by_user: Maybe<(
          { __typename?: 'UserV2GQL' }
          & Pick<GqlUserV2Gql, 'first_name' | 'last_name'>
        )> }
        & GqlFulfilmentDispatchReQuestFragment
        & GqlFdrSkusFragment
      )> }
    )>> }
  )> }
);

export type GqlOrderFulfilmentByIdSummaryQueryVariables = Exact<{
  orderFulfilmentFilters: Maybe<Scalars['GenericScalar']>;
}>;


export type GqlOrderFulfilmentByIdSummaryQuery = (
  { __typename?: 'Query' }
  & { fulfilment_dispatch_requests: Maybe<(
    { __typename?: 'FulfilmentDispatchRequestGQLConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'FulfilmentDispatchRequestGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'FulfilmentDispatchRequestGQL' }
        & { reserve_picking_jobs: Maybe<Array<Maybe<(
          { __typename?: 'ReservePickingJobGQL' }
          & Pick<GqlReservePickingJobGql, 'job_status' | 'job_id' | 'quantity'>
        )>>>, pickface_picking_tasks: Maybe<Array<Maybe<(
          { __typename?: 'PickFacePickingTaskGQL' }
          & Pick<GqlPickFacePickingTaskGql, 'task_id' | 'quantity' | 'balance' | 'product_item_id'>
          & { product_item: Maybe<(
            { __typename?: 'ProductItemV2GQL' }
            & Pick<GqlProductItemV2Gql, 'product_item_name'>
            & { product: Maybe<(
              { __typename?: 'ProductV2GQL' }
              & Pick<GqlProductV2Gql, 'product_name'>
            )> }
          )> }
        )>>> }
        & GqlFulfilmentDispatchReQuestFragment
        & GqlFdrSkusFragment
      )> }
    )>> }
  )> }
);

export type GqlFdrSkuQueryVariables = Exact<{
  search: Maybe<Scalars['String']>;
  fdrSkuFilters: Maybe<Scalars['GenericScalar']>;
  per_page: Maybe<Scalars['Int']>;
  page: Maybe<Scalars['Int']>;
}>;


export type GqlFdrSkuQuery = (
  { __typename?: 'Query' }
  & { fdr_skus: Maybe<(
    { __typename?: 'FDRSKUGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'FDRSKUGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'FDRSKUGQL' }
        & Pick<GqlFdrskugql, 'active' | 'product_item_id' | 'available_stock' | 'inventory_balance' | 'loaded_quantity' | 'verified' | 'unverified' | 'quantity' | 'fdr_id'>
        & { product_item: Maybe<(
          { __typename?: 'ProductItemV2GQL' }
          & Pick<GqlProductItemV2Gql, 'product_item_name' | 'product_id'>
          & { product: Maybe<(
            { __typename?: 'ProductV2GQL' }
            & Pick<GqlProductV2Gql, 'product_name'>
          )> }
        )>, fdr: Maybe<(
          { __typename?: 'FulfilmentDispatchRequestGQL' }
          & { reserve_picking_jobs: Maybe<Array<Maybe<(
            { __typename?: 'ReservePickingJobGQL' }
            & Pick<GqlReservePickingJobGql, 'product_item_id' | 'job_status'>
            & { product_item: Maybe<(
              { __typename?: 'ProductItemV2GQL' }
              & Pick<GqlProductItemV2Gql, 'product_item_name'>
              & { product: Maybe<(
                { __typename?: 'ProductV2GQL' }
                & Pick<GqlProductV2Gql, 'product_name'>
              )> }
            )> }
          )>>>, reserve_picking_balance: Maybe<Array<Maybe<(
            { __typename?: 'ReservePickingBalanceGQL' }
            & Pick<GqlReservePickingBalanceGql, 'balance'>
          )>>>, pickface_picking_tasks: Maybe<Array<Maybe<(
            { __typename?: 'PickFacePickingTaskGQL' }
            & Pick<GqlPickFacePickingTaskGql, 'quantity' | 'balance' | 'product_item_id'>
          )>>> }
        )> }
      )> }
    )>> }
  )> }
);

export type GqlReservePickingJobsQueryVariables = Exact<{
  search: Maybe<Scalars['String']>;
  pickingJobsFilters: Maybe<Scalars['GenericScalar']>;
  per_page: Maybe<Scalars['Int']>;
  page: Maybe<Scalars['Int']>;
}>;


export type GqlReservePickingJobsQuery = (
  { __typename?: 'Query' }
  & { reserve_picking_jobs: Maybe<(
    { __typename?: 'ReservePickingJobGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'ReservePickingJobGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'ReservePickingJobGQL' }
        & GqlReservePickingJobsFragmentFragment
      )> }
    )>> }
  )> }
);

export type GqlWarehouseTransferRequestQueryVariables = Exact<{
  search: Maybe<Scalars['String']>;
  warehouseFilters: Maybe<Scalars['GenericScalar']>;
  per_page: Maybe<Scalars['Int']>;
}>;


export type GqlWarehouseTransferRequestQuery = (
  { __typename?: 'Query' }
  & { warehouse_transfer_requests: Maybe<(
    { __typename?: 'WarehouseTransferRequestGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'WarehouseTransferRequestGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'WarehouseTransferRequestGQL' }
        & Pick<GqlWarehouseTransferRequestGql, 'active' | 'warehouse_transfer_request_id' | 'warehouse_transfer_request_number' | 'warehouse_transfer_request_complete' | 'vehicle_id' | 'date_created' | 'destination_dc_id' | 'dock_door_id'>
        & { vehicle: Maybe<(
          { __typename?: 'VehicleGQL' }
          & Pick<GqlVehicleGql, 'registration_number'>
        )>, destination_dc: Maybe<(
          { __typename?: 'DistributionCenterGQL' }
          & Pick<GqlDistributionCenterGql, 'distribution_center_name'>
        )>, dock_door: Maybe<(
          { __typename?: 'DockDoorGQL' }
          & Pick<GqlDockDoorGql, 'dock_door_number' | 'staging_area_name' | 'warehouse_id'>
          & { warehouse: Maybe<(
            { __typename?: 'WarehouseGQL' }
            & Pick<GqlWarehouseGql, 'warehouse_name' | 'distribution_center_id'>
            & { distribution_center: Maybe<(
              { __typename?: 'DistributionCenterGQL' }
              & Pick<GqlDistributionCenterGql, 'distribution_center_name'>
            )> }
          )> }
        )>, warehouse_transfer_request_items: Maybe<Array<Maybe<(
          { __typename?: 'WarehouseTransferRequestItemGQL' }
          & Pick<GqlWarehouseTransferRequestItemGql, 'warehouse_transfer_request_item_id' | 'warehouse_transfer_request_id' | 'quantity' | 'weight' | 'product_item_id'>
          & { product_item: Maybe<(
            { __typename?: 'ProductItemV2GQL' }
            & Pick<GqlProductItemV2Gql, 'product_item_name'>
            & { product: Maybe<(
              { __typename?: 'ProductV2GQL' }
              & Pick<GqlProductV2Gql, 'product_name'>
            )>, units_of_measure: Maybe<Array<Maybe<(
              { __typename?: 'ProductItemUOMV2GQL' }
              & { business_domain: Maybe<(
                { __typename?: 'BusinessDomainV2GQL' }
                & Pick<GqlBusinessDomainV2Gql, 'domain_name' | 'business_domain_id'>
              )>, uom: Maybe<(
                { __typename?: 'UOMV2GQL' }
                & Pick<GqlUomv2Gql, 'name'>
              )> }
            )>>> }
          )> }
        )>>> }
      )> }
    )>> }
  )> }
);

export type GqlWarehouseTransferRequestItemsQueryVariables = Exact<{
  search: Maybe<Scalars['String']>;
  warehouseItemFilters: Maybe<Scalars['GenericScalar']>;
  per_page: Maybe<Scalars['Int']>;
}>;


export type GqlWarehouseTransferRequestItemsQuery = (
  { __typename?: 'Query' }
  & { warehouse_transfer_request_items: Maybe<(
    { __typename?: 'WarehouseTransferRequestItemGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'WarehouseTransferRequestItemGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'WarehouseTransferRequestItemGQL' }
        & Pick<GqlWarehouseTransferRequestItemGql, 'active' | 'warehouse_transfer_request_id' | 'product_item_id' | 'quantity'>
        & { product_item: Maybe<(
          { __typename?: 'ProductItemV2GQL' }
          & Pick<GqlProductItemV2Gql, 'product_item_name' | 'product_id'>
          & { product: Maybe<(
            { __typename?: 'ProductV2GQL' }
            & Pick<GqlProductV2Gql, 'product_name'>
          )> }
        )>, warehouse_transfer_request: Maybe<(
          { __typename?: 'WarehouseTransferRequestGQL' }
          & { reserve_picking_jobs: Maybe<Array<Maybe<(
            { __typename?: 'ReservePickingJobGQL' }
            & Pick<GqlReservePickingJobGql, 'product_item_id' | 'job_status'>
            & { product_item: Maybe<(
              { __typename?: 'ProductItemV2GQL' }
              & Pick<GqlProductItemV2Gql, 'product_item_name'>
              & { product: Maybe<(
                { __typename?: 'ProductV2GQL' }
                & Pick<GqlProductV2Gql, 'product_name'>
              )> }
            )> }
          )>>>, reserve_picking_balance: Maybe<Array<Maybe<(
            { __typename?: 'ReservePickingBalanceGQL' }
            & Pick<GqlReservePickingBalanceGql, 'balance'>
          )>>>, pickface_picking_tasks: Maybe<Array<Maybe<(
            { __typename?: 'PickFacePickingTaskGQL' }
            & Pick<GqlPickFacePickingTaskGql, 'quantity' | 'balance' | 'product_item_id'>
          )>>> }
        )> }
      )> }
    )>> }
  )> }
);

export type GqlDisposalsQueryVariables = Exact<{
  search: Maybe<Scalars['String']>;
  disposalFilters: Maybe<Scalars['GenericScalar']>;
  per_page: Maybe<Scalars['Int']>;
  page: Maybe<Scalars['Int']>;
}>;


export type GqlDisposalsQuery = (
  { __typename?: 'Query' }
  & { disposals: Maybe<(
    { __typename?: 'DisposalGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'DisposalGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'DisposalGQL' }
        & Pick<GqlDisposalGql, 'dock_door_id' | 'disposal_id' | 'active' | 'date_created' | 'disposal_number' | 'disposal_complete'>
        & { dock_door: Maybe<(
          { __typename?: 'DockDoorGQL' }
          & Pick<GqlDockDoorGql, 'dock_door_number' | 'warehouse_id'>
          & { warehouse: Maybe<(
            { __typename?: 'WarehouseGQL' }
            & Pick<GqlWarehouseGql, 'warehouse_name' | 'distribution_center_id'>
            & { distribution_center: Maybe<(
              { __typename?: 'DistributionCenterGQL' }
              & Pick<GqlDistributionCenterGql, 'distribution_center_name'>
            )> }
          )> }
        )>, disposal_items: Maybe<Array<Maybe<(
          { __typename?: 'DisposalItemGQL' }
          & Pick<GqlDisposalItemGql, 'quantity' | 'product_item_id'>
          & { product_item: Maybe<(
            { __typename?: 'ProductItemV2GQL' }
            & Pick<GqlProductItemV2Gql, 'product_item_name'>
            & { product: Maybe<(
              { __typename?: 'ProductV2GQL' }
              & Pick<GqlProductV2Gql, 'product_name'>
            )>, units_of_measure: Maybe<Array<Maybe<(
              { __typename?: 'ProductItemUOMV2GQL' }
              & { business_domain: Maybe<(
                { __typename?: 'BusinessDomainV2GQL' }
                & Pick<GqlBusinessDomainV2Gql, 'domain_name' | 'business_domain_id'>
              )>, uom: Maybe<(
                { __typename?: 'UOMV2GQL' }
                & Pick<GqlUomv2Gql, 'name'>
              )> }
            )>>> }
          )> }
        )>>> }
      )> }
    )>> }
  )> }
);

export type GqlDisposalItemsQueryVariables = Exact<{
  search: Maybe<Scalars['String']>;
  disposalItemFilters: Maybe<Scalars['GenericScalar']>;
  per_page: Maybe<Scalars['Int']>;
}>;


export type GqlDisposalItemsQuery = (
  { __typename?: 'Query' }
  & { disposal_items: Maybe<(
    { __typename?: 'DisposalItemGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'DisposalItemGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'DisposalItemGQL' }
        & Pick<GqlDisposalItemGql, 'active' | 'disposal_item_id' | 'disposal_id' | 'product_item_id' | 'quantity'>
        & { product_item: Maybe<(
          { __typename?: 'ProductItemV2GQL' }
          & Pick<GqlProductItemV2Gql, 'product_item_name' | 'product_id'>
          & { product: Maybe<(
            { __typename?: 'ProductV2GQL' }
            & Pick<GqlProductV2Gql, 'product_name'>
          )> }
        )> }
      )> }
    )>> }
  )> }
);

export type GqlWmsReturnsQueryVariables = Exact<{
  search: Maybe<Scalars['String']>;
  returnsFilters: Maybe<Scalars['GenericScalar']>;
  per_page: Maybe<Scalars['Int']>;
}>;


export type GqlWmsReturnsQuery = (
  { __typename?: 'Query' }
  & { wms_returns: Maybe<(
    { __typename?: 'WMSReturnsGQLConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'WMSReturnsGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'WMSReturnsGQL' }
        & Pick<GqlWmsReturnsGql, 'wms_returns_number' | 'wms_returns_id' | 'appointment_id'>
        & { appointment: Maybe<(
          { __typename?: 'AppointmentGQL' }
          & Pick<GqlAppointmentGql, 'appointment_number' | 'vehicle_registration' | 'driver_name' | 'eta' | 'dock_door_id' | 'issued_by' | 'distribution_center_id'>
          & { dock_door: Maybe<(
            { __typename?: 'DockDoorGQL' }
            & Pick<GqlDockDoorGql, 'dock_door_number'>
          )>, issued_by_user: Maybe<(
            { __typename?: 'UserV2GQL' }
            & Pick<GqlUserV2Gql, 'first_name' | 'last_name'>
          )>, distribution_center: Maybe<(
            { __typename?: 'DistributionCenterGQL' }
            & Pick<GqlDistributionCenterGql, 'distribution_center_name'>
          )>, appointment_items: Maybe<Array<Maybe<(
            { __typename?: 'AppointmentItemGQL' }
            & Pick<GqlAppointmentItemGql, 'return_id'>
            & { returns: Maybe<(
              { __typename?: 'ReturnGQL' }
              & { product_return_items: Maybe<Array<Maybe<(
                { __typename?: 'ProductReturnItemGQL' }
                & Pick<GqlProductReturnItemGql, 'quantity' | 'unit_of_measure_id' | 'product_item_id'>
                & { uom: Maybe<(
                  { __typename?: 'UOMV2GQL' }
                  & Pick<GqlUomv2Gql, 'name'>
                )>, product_item: Maybe<(
                  { __typename?: 'ProductItemV2GQL' }
                  & Pick<GqlProductItemV2Gql, 'product_item_name'>
                  & { product: Maybe<(
                    { __typename?: 'ProductV2GQL' }
                    & Pick<GqlProductV2Gql, 'product_name'>
                  )> }
                )> }
              )>>> }
            )> }
          )>>> }
        )> }
      )> }
    )>> }
  )> }
);

export type GqlLoadingTasksQueryVariables = Exact<{
  search: Maybe<Scalars['String']>;
  filters: Maybe<Scalars['GenericScalar']>;
  per_page: Maybe<Scalars['Int']>;
  page: Maybe<Scalars['Int']>;
}>;


export type GqlLoadingTasksQuery = (
  { __typename?: 'Query' }
  & { loading_tasks: Maybe<(
    { __typename?: 'LoadingTaskGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'LoadingTaskGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'LoadingTaskGQL' }
        & Pick<GqlLoadingTaskGql, 'active' | 'status' | 'loading_number' | 'loaded_weight' | 'loading_task_id' | 'date_updated' | 'inventory_location_id' | 'total_loaded_quantity' | 'picture' | 'created_by'>
        & { fdr: Maybe<(
          { __typename?: 'FulfilmentDispatchRequestGQL' }
          & Pick<GqlFulfilmentDispatchRequestGql, 'fdr_number'>
          & { dock_door: Maybe<(
            { __typename?: 'DockDoorGQL' }
            & { warehouse: Maybe<(
              { __typename?: 'WarehouseGQL' }
              & { distribution_center: Maybe<(
                { __typename?: 'DistributionCenterGQL' }
                & Pick<GqlDistributionCenterGql, 'distribution_center_name'>
              )> }
            )> }
          )>, fdr_items: Maybe<Array<Maybe<(
            { __typename?: 'FulfilmentDispatchRequestItemGQL' }
            & Pick<GqlFulfilmentDispatchRequestItemGql, 'route_id' | 'route_plan_id'>
            & { route: Maybe<(
              { __typename?: 'RouteV2GQL' }
              & Pick<GqlRouteV2Gql, 'route_name' | 'depot_id'>
              & { depot: Maybe<(
                { __typename?: 'DepotV2GQL' }
                & Pick<GqlDepotV2Gql, 'depot_name'>
              )> }
            )>, route_plan: Maybe<(
              { __typename?: 'RoutePlanGQL' }
              & Pick<GqlRoutePlanGql, 'vehicle_id' | 'depot_id'>
              & { vehicle: Maybe<(
                { __typename?: 'VehicleGQL' }
                & Pick<GqlVehicleGql, 'registration_number'>
              )>, depot: Maybe<(
                { __typename?: 'DepotV2GQL' }
                & Pick<GqlDepotV2Gql, 'depot_name'>
              )> }
            )> }
          )>>> }
        )>, inventory_location: Maybe<(
          { __typename?: 'InventoryLocationGQL' }
          & Pick<GqlInventoryLocationGql, 'inventory_location_name'>
        )>, loading_task_items: Maybe<Array<Maybe<(
          { __typename?: 'LoadingTaskItemGQL' }
          & Pick<GqlLoadingTaskItemGql, 'handling_unit_item_id' | 'loading_task_id'>
          & { handling_unit_item: Maybe<(
            { __typename?: 'HandlingUnitItemGQL' }
            & Pick<GqlHandlingUnitItemGql, 'quantity' | 'product_item_id'>
            & { product_item: Maybe<(
              { __typename?: 'ProductItemV2GQL' }
              & Pick<GqlProductItemV2Gql, 'product_item_name'>
              & { product: Maybe<(
                { __typename?: 'ProductV2GQL' }
                & Pick<GqlProductV2Gql, 'product_name'>
              )>, units_of_measure: Maybe<Array<Maybe<(
                { __typename?: 'ProductItemUOMV2GQL' }
                & { business_domain: Maybe<(
                  { __typename?: 'BusinessDomainV2GQL' }
                  & Pick<GqlBusinessDomainV2Gql, 'domain_name'>
                )>, uom: Maybe<(
                  { __typename?: 'UOMV2GQL' }
                  & Pick<GqlUomv2Gql, 'name'>
                )> }
              )>>> }
            )> }
          )>, loading_task: Maybe<(
            { __typename?: 'LoadingTaskGQL' }
            & Pick<GqlLoadingTaskGql, 'loading_task_id'>
            & { fdr: Maybe<(
              { __typename?: 'FulfilmentDispatchRequestGQL' }
              & Pick<GqlFulfilmentDispatchRequestGql, 'fdr_id'>
              & { fdr_skus: Maybe<Array<Maybe<(
                { __typename?: 'FDRSKUGQL' }
                & Pick<GqlFdrskugql, 'fdr_sku_id' | 'product_item_id' | 'verified' | 'unverified'>
              )>>> }
            )> }
          )> }
        )>>>, created_by_user: Maybe<(
          { __typename?: 'UserV2GQL' }
          & Pick<GqlUserV2Gql, 'first_name' | 'last_name'>
        )> }
      )> }
    )>> }
  )> }
);

export type GqlPickingHandlingUnitsQueryVariables = Exact<{
  filters: Maybe<Scalars['GenericScalar']>;
  per_page: Maybe<Scalars['Int']>;
  page: Maybe<Scalars['Int']>;
}>;


export type GqlPickingHandlingUnitsQuery = (
  { __typename?: 'Query' }
  & { picking_handling_units: Maybe<(
    { __typename?: 'PickingHandlingUnitGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'PickingHandlingUnitGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'PickingHandlingUnitGQL' }
        & Pick<GqlPickingHandlingUnitGql, 'active' | 'picking_hu_id' | 'dispatch_reference_id' | 'handling_unit_id' | 'final_location_id' | 'is_complete' | 'picked_quantity'>
        & { final_location: Maybe<(
          { __typename?: 'StorageLocationGQL' }
          & Pick<GqlStorageLocationGql, 'storage_location_number'>
        )>, handling_unit: Maybe<(
          { __typename?: 'HandlingUnitGQL' }
          & Pick<GqlHandlingUnitGql, 'storage_location_id' | 'updated_by'>
          & { asset: Maybe<(
            { __typename?: 'AssetGQL' }
            & Pick<GqlAssetGql, 'asset_number'>
          )>, storage_location: Maybe<(
            { __typename?: 'StorageLocationGQL' }
            & Pick<GqlStorageLocationGql, 'storage_location_number'>
          )>, updated_by_user: Maybe<(
            { __typename?: 'UserV2GQL' }
            & Pick<GqlUserV2Gql, 'first_name' | 'last_name'>
          )>, handling_unit_items: Maybe<Array<Maybe<(
            { __typename?: 'HandlingUnitItemGQL' }
            & Pick<GqlHandlingUnitItemGql, 'storage_location_id' | 'product_item_id'>
            & { storage_location: Maybe<(
              { __typename?: 'StorageLocationGQL' }
              & Pick<GqlStorageLocationGql, 'storage_location_number'>
            )>, asset: Maybe<(
              { __typename?: 'AssetGQL' }
              & Pick<GqlAssetGql, 'asset_number'>
            )>, product_item: Maybe<(
              { __typename?: 'ProductItemV2GQL' }
              & Pick<GqlProductItemV2Gql, 'product_item_name' | 'product_id'>
              & { product: Maybe<(
                { __typename?: 'ProductV2GQL' }
                & Pick<GqlProductV2Gql, 'product_name'>
              )> }
            )> }
          )>>> }
        )> }
      )> }
    )>> }
  )> }
);

export type GqlProductZonesQueryVariables = Exact<{
  per_page: Maybe<Scalars['Int']>;
  page: Maybe<Scalars['Int']>;
  filters: Maybe<Scalars['GenericScalar']>;
  search: Maybe<Scalars['String']>;
}>;


export type GqlProductZonesQuery = (
  { __typename?: 'Query' }
  & { product_zones: Maybe<(
    { __typename?: 'ProductZoneGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'ProductZoneGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'ProductZoneGQL' }
        & Pick<GqlProductZoneGql, 'active' | 'product_zone_name' | 'product_zone_type' | 'product_zone_id' | 'product_zone_description'>
        & { rules: Maybe<Array<Maybe<(
          { __typename?: 'RuleGQL' }
          & Pick<GqlRuleGql, 'active' | 'rule_name' | 'rule_id'>
        )>>>, bins: Maybe<Array<Maybe<(
          { __typename?: 'BinGQL' }
          & Pick<GqlBinGql, 'active' | 'is_pickface' | 'capacity' | 'storage_location_id'>
          & { storage_location: Maybe<(
            { __typename?: 'StorageLocationGQL' }
            & Pick<GqlStorageLocationGql, 'status'>
          )>, bin_names: Maybe<Array<Maybe<(
            { __typename?: 'BinNameGQL' }
            & Pick<GqlBinNameGql, 'aisle' | 'level' | 'bin_position' | 'bin_id'>
          )>>> }
        )>>> }
      )> }
    )>> }
  )> }
);

export type GqlRulesQueryVariables = Exact<{
  per_page: Maybe<Scalars['Int']>;
  page: Maybe<Scalars['Int']>;
  filters: Maybe<Scalars['GenericScalar']>;
  search: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
}>;


export type GqlRulesQuery = (
  { __typename?: 'Query' }
  & { rules: Maybe<(
    { __typename?: 'RuleGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'RuleGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'RuleGQL' }
        & Pick<GqlRuleGql, 'active' | 'rule_id' | 'rule_name' | 'rule_description'>
        & { product_rules: Maybe<Array<Maybe<(
          { __typename?: 'ProductRuleGQL' }
          & Pick<GqlProductRuleGql, 'product_id' | 'product_item_id' | 'is_sku_rule'>
          & { product: Maybe<(
            { __typename?: 'ProductV2GQL' }
            & Pick<GqlProductV2Gql, 'active' | 'product_name' | 'product_id'>
          )>, product_item: Maybe<(
            { __typename?: 'ProductItemV2GQL' }
            & Pick<GqlProductItemV2Gql, 'active' | 'product_item_name'>
            & { product: Maybe<(
              { __typename?: 'ProductV2GQL' }
              & Pick<GqlProductV2Gql, 'product_name'>
            )> }
          )> }
        )>>>, category_rules: Maybe<Array<Maybe<(
          { __typename?: 'CategoryRuleGQL' }
          & Pick<GqlCategoryRuleGql, 'category_id' | 'category_rule_type' | 'active'>
          & { category: Maybe<(
            { __typename?: 'CategoryV2GQL' }
            & Pick<GqlCategoryV2Gql, 'category_name' | 'category_id'>
          )> }
        )>>> }
      )> }
    )>> }
  )> }
);

export type GqlProductRulesQueryVariables = Exact<{
  filters: Maybe<Scalars['GenericScalar']>;
}>;


export type GqlProductRulesQuery = (
  { __typename?: 'Query' }
  & { product_rules: Maybe<(
    { __typename?: 'ProductRuleGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'ProductRuleGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'ProductRuleGQL' }
        & Pick<GqlProductRuleGql, 'active' | 'rule_id' | 'product_id' | 'product_item_id' | 'is_sku_rule'>
        & { product_item: Maybe<(
          { __typename?: 'ProductItemV2GQL' }
          & Pick<GqlProductItemV2Gql, 'product_item_name'>
          & { product: Maybe<(
            { __typename?: 'ProductV2GQL' }
            & Pick<GqlProductV2Gql, 'product_name'>
          )> }
        )>, product: Maybe<(
          { __typename?: 'ProductV2GQL' }
          & Pick<GqlProductV2Gql, 'product_name'>
        )> }
      )> }
    )>> }
  )> }
);

export type GqlCategoryRulesQueryVariables = Exact<{
  filters: Maybe<Scalars['GenericScalar']>;
}>;


export type GqlCategoryRulesQuery = (
  { __typename?: 'Query' }
  & { category_rules: Maybe<(
    { __typename?: 'CategoryRuleGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'CategoryRuleGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'CategoryRuleGQL' }
        & Pick<GqlCategoryRuleGql, 'active' | 'rule_id' | 'category_id'>
        & { category: Maybe<(
          { __typename?: 'CategoryV2GQL' }
          & Pick<GqlCategoryV2Gql, 'category_name'>
        )> }
      )> }
    )>> }
  )> }
);

export type GqlZoneTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlZoneTypesQuery = (
  { __typename?: 'Query' }
  & Pick<GqlQuery, 'product_zone_types'>
);

export type GqlStorageLocationsQueryVariables = Exact<{
  filters: Maybe<Scalars['GenericScalar']>;
  search: Maybe<Scalars['String']>;
  per_page: Maybe<Scalars['Int']>;
}>;


export type GqlStorageLocationsQuery = (
  { __typename?: 'Query' }
  & { bins: Maybe<(
    { __typename?: 'BinGQLConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'BinGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'BinGQL' }
        & Pick<GqlBinGql, 'pickface_product_item_id' | 'is_pickface'>
        & { bin_names: Maybe<Array<Maybe<(
          { __typename?: 'BinNameGQL' }
          & Pick<GqlBinNameGql, 'bin_id' | 'aisle' | 'level' | 'bin_position'>
        )>>> }
      )> }
    )>> }
  )> }
);

export type GqlFilterBinsQueryVariables = Exact<{
  from: Maybe<Scalars['String']>;
  to: Maybe<Scalars['String']>;
}>;


export type GqlFilterBinsQuery = (
  { __typename?: 'Query' }
  & { filter_bins: Maybe<Array<Maybe<(
    { __typename?: 'CustomBinGQL' }
    & Pick<GqlCustomBinGql, 'bin_id' | 'aisle' | 'bin_position' | 'level' | 'storage_location_id'>
  )>>> }
);

export type GqlRoutePlansQueryVariables = Exact<{
  filters: Maybe<Scalars['GenericScalar']>;
  search: Maybe<Scalars['String']>;
}>;


export type GqlRoutePlansQuery = (
  { __typename?: 'Query' }
  & { route_plans: Maybe<(
    { __typename?: 'RoutePlanGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'RoutePlanGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'RoutePlanGQL' }
        & Pick<GqlRoutePlanGql, 'route_plan_id' | 'delivery_date' | 'depot_id' | 'multiple_trips' | 'vehicle_trip' | 'vehicle_id' | 'route_id'>
        & { depot: Maybe<(
          { __typename?: 'DepotV2GQL' }
          & Pick<GqlDepotV2Gql, 'depot_name'>
        )>, vehicle: Maybe<(
          { __typename?: 'VehicleGQL' }
          & Pick<GqlVehicleGql, 'registration_number'>
        )>, route: Maybe<(
          { __typename?: 'RouteV2GQL' }
          & Pick<GqlRouteV2Gql, 'route_name'>
        )> }
      )> }
    )>> }
  )> }
);

export type GqlStorageLocationTypesQueryVariables = Exact<{
  filters: Maybe<Scalars['GenericScalar']>;
  search: Maybe<Scalars['String']>;
}>;


export type GqlStorageLocationTypesQuery = (
  { __typename?: 'Query' }
  & { storage_location_types: Maybe<(
    { __typename?: 'StorageLocationTypeGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'StorageLocationTypeGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'StorageLocationTypeGQL' }
        & Pick<GqlStorageLocationTypeGql, 'location_type_id' | 'location_type_name'>
      )> }
    )>> }
  )> }
);

export type GqlWms_Storage_LocationsQueryVariables = Exact<{
  filters: Maybe<Scalars['GenericScalar']>;
  search: Maybe<Scalars['String']>;
}>;


export type GqlWms_Storage_LocationsQuery = (
  { __typename?: 'Query' }
  & { storage_locations: Maybe<(
    { __typename?: 'StorageLocationGQLConnection' }
    & { page_info: Maybe<(
      { __typename?: 'PaginatorInfo' }
      & GqlPaginationFragment
    )>, edges: Array<Maybe<(
      { __typename?: 'StorageLocationGQLEdge' }
      & { node: Maybe<(
        { __typename?: 'StorageLocationGQL' }
        & Pick<GqlStorageLocationGql, 'storage_location_id' | 'storage_location_number'>
      )> }
    )>> }
  )> }
);

export type GqlGetEtimsEventOutboxItemsQueryVariables = Exact<{
  filter: Maybe<Scalars['GenericScalar']>;
}>;


export type GqlGetEtimsEventOutboxItemsQuery = (
  { __typename?: 'Query' }
  & { etims_event_outbox_items: Maybe<(
    { __typename?: 'EtimsEventOutboxConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'EtimsEventOutboxEdge' }
      & { node: Maybe<(
        { __typename?: 'EtimsEventOutboxGQL' }
        & Pick<GqlEtimsEventOutboxGql, 'processed' | 'dispatch_id' | 'intake_id' | 'dms_disposal_id'>
      )> }
    )>> }
  )> }
);

export const PaginationFragmentDoc = gql`
    fragment Pagination on PaginatorInfo {
  current_page
  per_page
  has_next
  has_previous
  total_pages
  total_count
}
    `;
export const CountryFragmentDoc = gql`
    fragment Country on CountryGQL {
  country_id
  iso_alpha2_name
  iso_alpha2_code
  default_currency
  country_calling_code
}
    `;
export const ProductFragmentDoc = gql`
    fragment Product on ProductItemV2GQL {
  active
  product_item_name
  product_item_id
  dynamic_price {
    base_price
    dynamic_price
  }
  base_price
  units_of_measure {
    active
    business_domain {
      domain_name
    }
    conversion_ratio
    uom {
      name
      uom_id
    }
  }
  product {
    active
    product_name
    product_id
    packaging_units {
      active
      name
      weight
      packaging_unit_id
    }
    domain_activations {
      business_domain {
        domain_name
        business_domain_id
      }
    }
  }
}
    `;
export const DeliveryDetailsFragmentDoc = gql`
    fragment DeliveryDetails on DeliveryV2GQL {
  delivery_number
  delivery_date
  date_created
  active
  amount
  currency
  served_by {
    first_name
    last_name
  }
  route_id
  route {
    route_name
  }
  shop_id
  shop {
    shop_id
    shop_number
    shop_name
    delivery_fee
    customer_id
    customer {
      customer_name
      phone_number
    }
  }
}
    `;
export const CustomerDetailsFragmentDoc = gql`
    fragment CustomerDetails on ShopV2GQL {
  customer {
    customer_id
    phone_number
    customer_name
  }
}
    `;
export const ShopDetailsFragmentDoc = gql`
    fragment ShopDetails on ShopV2GQL {
  shop_id
  shop_number
  shop_name
  kra_pin
  active
  kra_pin
  synced_to_etims
  shop_type_id
  shop_type {
    name
  }
  country {
    country_id
    default_currency
  }
  distribution_centers {
    distribution_center_id
  }
  updated_by
  updated_by_user {
    first_name
    last_name
    user_id
  }
  region_id
  route_id
  route {
    route_id
    route_name
    depot_id
    depot {
      depot_name
    }
  }
}
    `;
export const DcReturnFragmentDoc = gql`
    fragment DCReturn on ReturnGQL {
  return_id
  return_number
  sale_date
  created_by
  return_level
  active
  cancellation_reason
  user {
    first_name
    last_name
    phone_number
    user_number
    user_id
  }
  packaging_return_items {
    packaging_return_item_id
    active
    cancellation_reason
    packaging_unit_id
    quantity
    packaging_unit {
      name
    }
  }
  product_return_items {
    active
    cancellation_reason
    product_item_id
    product_return_item_id
    quantity
    unit_of_measure_id
    uom {
      abbreviation
    }
    product_item {
      product_item_name
      product_id
      product {
        product_name
      }
    }
  }
  depot_id
  depot {
    depot_name
    depot_id
  }
  distribution_center_id
  distribution_center {
    distribution_center_id
    distribution_center_name
  }
}
    `;
export const ProductItemV2FragmentDoc = gql`
    fragment ProductItemV2 on ProductItemV2GQL {
  product_item_id
  product_item_name
  product {
    product_name
  }
  packed_weight
  packed_volume
  units_of_measure {
    business_domain_id
    business_domain {
      domain_name
    }
    conversion_ratio
    uom {
      uom_id
      name
    }
  }
}
    `;
export const PutAwayJobsFragmentDoc = gql`
    fragment PutAwayJobs on PutAwayJobGQL {
  active
  stock_intake_id
  quantity
  job_id
  job_number
  job_status
  quantity
  assigned_to
  assigned {
    first_name
    last_name
  }
  product_item_id
  product_item {
    product_item_name
    product {
      product_name
    }
  }
  destination_handling_unit {
    handling_unit_id
    asset {
      asset_number
      asset_type {
        asset_type_name
      }
    }
  }
  source_location_id
  source_location {
    dock_door {
      dock_door_number
    }
  }
  destination_location {
    bin {
      aisle
      bin_position
      level
      bin_names {
        bin_name_direction
        aisle
        bin_position
        level
      }
    }
  }
}
    `;
export const ReservePickingJobsFragmentFragmentDoc = gql`
    fragment ReservePickingJobsFragment on ReservePickingJobGQL {
  active
  cancelled
  job_number
  job_id
  quantity
  handling_unit_id
  handling_unit {
    quantity
    asset_id
    asset {
      asset_number
    }
  }
  source_location_id
  source_location {
    bin {
      aisle
      bin_position
      level
      bin_names {
        bin_name_direction
        aisle
        bin_position
        level
      }
    }
  }
  assigned_to
  assigned {
    first_name
    last_name
  }
  job_status
  product_item_id
  product_item {
    product_item_name
    units_of_measure {
      uom {
        name
      }
    }
    product {
      product_name
    }
  }
}
    `;
export const FulfilmentDispatchReQuestFragmentDoc = gql`
    fragment FulfilmentDispatchReQuest on FulfilmentDispatchRequestGQL {
  fdr_number
  fdr_id
  active
  fdr_complete
  sale_date
  date_created
  order_time_start
  order_time_end
  dock_door_id
  dock_door {
    dock_door_number
    staging_area_name
    warehouse_id
    warehouse {
      distribution_center_id
      distribution_center {
        distribution_center_name
      }
    }
  }
  fdr_items {
    route_id
    route {
      route_name
      depot_id
      depot {
        depot_name
      }
    }
    route_plan_id
    route_plan {
      multiple_trips
      vehicle_trip
      vehicle_id
      vehicle {
        registration_number
      }
      depot_id
      depot {
        depot_name
      }
    }
  }
}
    `;
export const FdrSkusFragmentDoc = gql`
    fragment FDRSkus on FulfilmentDispatchRequestGQL {
  fdr_skus {
    active
    product_item_id
    quantity
    loaded_quantity
    available_stock
    amount_planned_from_reserve
    amount_planned_from_pickface
    product_item {
      product_item_name
      product_id
      product {
        product_name
      }
      units_of_measure {
        business_domain {
          domain_name
          business_domain_id
        }
        uom {
          name
        }
      }
    }
  }
}
    `;
export const GetProductBundlesDocument = gql`
    query getProductBundles($per_page: Int, $page: Int, $search: String, $active: Boolean) {
  paginated_product_item_bundles(
    page: $page
    per_page: $per_page
    search: $search
    active: $active
  ) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        bundle_name
        image_url
        initial_total_price
        discounted_total_price
        date_created
        active
        product_item_bundle_id
        product_items {
          product {
            product_name
          }
          product_item_name
          base_price
          dynamic_price {
            dynamic_price
            discount_value
          }
        }
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useGetProductBundlesQuery__
 *
 * To run a query within a React component, call `useGetProductBundlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductBundlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductBundlesQuery({
 *   variables: {
 *      per_page: // value for 'per_page'
 *      page: // value for 'page'
 *      search: // value for 'search'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useGetProductBundlesQuery(baseOptions?: Apollo.QueryHookOptions<GqlGetProductBundlesQuery, GqlGetProductBundlesQueryVariables>) {
        return Apollo.useQuery<GqlGetProductBundlesQuery, GqlGetProductBundlesQueryVariables>(GetProductBundlesDocument, baseOptions);
      }
export function useGetProductBundlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlGetProductBundlesQuery, GqlGetProductBundlesQueryVariables>) {
          return Apollo.useLazyQuery<GqlGetProductBundlesQuery, GqlGetProductBundlesQueryVariables>(GetProductBundlesDocument, baseOptions);
        }
export type GetProductBundlesQueryHookResult = ReturnType<typeof useGetProductBundlesQuery>;
export type GetProductBundlesLazyQueryHookResult = ReturnType<typeof useGetProductBundlesLazyQuery>;
export type GetProductBundlesQueryResult = Apollo.QueryResult<GqlGetProductBundlesQuery, GqlGetProductBundlesQueryVariables>;
export const AddProductItemBundleDocument = gql`
    mutation addProductItemBundle($data: ProductItemBundleInput!) {
  add_product_item_bundle(data: $data) {
    created
    product_item_bundle {
      product_item_bundle_id
      bundle_name
      discounts_map
      product_item_ids
      product_items {
        product_item_name
        product {
          product_name
        }
      }
    }
  }
}
    `;
export type GqlAddProductItemBundleMutationFn = Apollo.MutationFunction<GqlAddProductItemBundleMutation, GqlAddProductItemBundleMutationVariables>;

/**
 * __useAddProductItemBundleMutation__
 *
 * To run a mutation, you first call `useAddProductItemBundleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProductItemBundleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProductItemBundleMutation, { data, loading, error }] = useAddProductItemBundleMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddProductItemBundleMutation(baseOptions?: Apollo.MutationHookOptions<GqlAddProductItemBundleMutation, GqlAddProductItemBundleMutationVariables>) {
        return Apollo.useMutation<GqlAddProductItemBundleMutation, GqlAddProductItemBundleMutationVariables>(AddProductItemBundleDocument, baseOptions);
      }
export type AddProductItemBundleMutationHookResult = ReturnType<typeof useAddProductItemBundleMutation>;
export type AddProductItemBundleMutationResult = Apollo.MutationResult<GqlAddProductItemBundleMutation>;
export type AddProductItemBundleMutationOptions = Apollo.BaseMutationOptions<GqlAddProductItemBundleMutation, GqlAddProductItemBundleMutationVariables>;
export const AddProductItemBundleImageDocument = gql`
    mutation addProductItemBundleImage($image: Upload!, $product_item_bundle_id: String!) {
  add_product_item_bundle_image(
    image: $image
    product_item_bundle_id: $product_item_bundle_id
  ) {
    uploaded
    product_item_bundle {
      image_url
    }
  }
}
    `;
export type GqlAddProductItemBundleImageMutationFn = Apollo.MutationFunction<GqlAddProductItemBundleImageMutation, GqlAddProductItemBundleImageMutationVariables>;

/**
 * __useAddProductItemBundleImageMutation__
 *
 * To run a mutation, you first call `useAddProductItemBundleImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProductItemBundleImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProductItemBundleImageMutation, { data, loading, error }] = useAddProductItemBundleImageMutation({
 *   variables: {
 *      image: // value for 'image'
 *      product_item_bundle_id: // value for 'product_item_bundle_id'
 *   },
 * });
 */
export function useAddProductItemBundleImageMutation(baseOptions?: Apollo.MutationHookOptions<GqlAddProductItemBundleImageMutation, GqlAddProductItemBundleImageMutationVariables>) {
        return Apollo.useMutation<GqlAddProductItemBundleImageMutation, GqlAddProductItemBundleImageMutationVariables>(AddProductItemBundleImageDocument, baseOptions);
      }
export type AddProductItemBundleImageMutationHookResult = ReturnType<typeof useAddProductItemBundleImageMutation>;
export type AddProductItemBundleImageMutationResult = Apollo.MutationResult<GqlAddProductItemBundleImageMutation>;
export type AddProductItemBundleImageMutationOptions = Apollo.BaseMutationOptions<GqlAddProductItemBundleImageMutation, GqlAddProductItemBundleImageMutationVariables>;
export const UpdateProductItemBundleDocument = gql`
    mutation updateProductItemBundle($product_item_bundle_id: String!, $data: ProductItemBundleInput!) {
  update_product_item_bundle(
    product_item_bundle_id: $product_item_bundle_id
    data: $data
  ) {
    product_item_bundle {
      product_item_bundle_id
      active
    }
  }
}
    `;
export type GqlUpdateProductItemBundleMutationFn = Apollo.MutationFunction<GqlUpdateProductItemBundleMutation, GqlUpdateProductItemBundleMutationVariables>;

/**
 * __useUpdateProductItemBundleMutation__
 *
 * To run a mutation, you first call `useUpdateProductItemBundleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductItemBundleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductItemBundleMutation, { data, loading, error }] = useUpdateProductItemBundleMutation({
 *   variables: {
 *      product_item_bundle_id: // value for 'product_item_bundle_id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateProductItemBundleMutation(baseOptions?: Apollo.MutationHookOptions<GqlUpdateProductItemBundleMutation, GqlUpdateProductItemBundleMutationVariables>) {
        return Apollo.useMutation<GqlUpdateProductItemBundleMutation, GqlUpdateProductItemBundleMutationVariables>(UpdateProductItemBundleDocument, baseOptions);
      }
export type UpdateProductItemBundleMutationHookResult = ReturnType<typeof useUpdateProductItemBundleMutation>;
export type UpdateProductItemBundleMutationResult = Apollo.MutationResult<GqlUpdateProductItemBundleMutation>;
export type UpdateProductItemBundleMutationOptions = Apollo.BaseMutationOptions<GqlUpdateProductItemBundleMutation, GqlUpdateProductItemBundleMutationVariables>;
export const CountriesDocument = gql`
    query countries {
  countries {
    ...Country
  }
}
    ${CountryFragmentDoc}`;

/**
 * __useCountriesQuery__
 *
 * To run a query within a React component, call `useCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountriesQuery(baseOptions?: Apollo.QueryHookOptions<GqlCountriesQuery, GqlCountriesQueryVariables>) {
        return Apollo.useQuery<GqlCountriesQuery, GqlCountriesQueryVariables>(CountriesDocument, baseOptions);
      }
export function useCountriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlCountriesQuery, GqlCountriesQueryVariables>) {
          return Apollo.useLazyQuery<GqlCountriesQuery, GqlCountriesQueryVariables>(CountriesDocument, baseOptions);
        }
export type CountriesQueryHookResult = ReturnType<typeof useCountriesQuery>;
export type CountriesLazyQueryHookResult = ReturnType<typeof useCountriesLazyQuery>;
export type CountriesQueryResult = Apollo.QueryResult<GqlCountriesQuery, GqlCountriesQueryVariables>;
export const DepotsDocument = gql`
    query depots($filters: GenericScalar) {
  depots_v2(filters: $filters) {
    edges {
      node {
        active
        depot_id
        depot_name
        routes {
          active
          route_id
          route_name
        }
      }
    }
  }
}
    `;

/**
 * __useDepotsQuery__
 *
 * To run a query within a React component, call `useDepotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDepotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepotsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useDepotsQuery(baseOptions?: Apollo.QueryHookOptions<GqlDepotsQuery, GqlDepotsQueryVariables>) {
        return Apollo.useQuery<GqlDepotsQuery, GqlDepotsQueryVariables>(DepotsDocument, baseOptions);
      }
export function useDepotsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlDepotsQuery, GqlDepotsQueryVariables>) {
          return Apollo.useLazyQuery<GqlDepotsQuery, GqlDepotsQueryVariables>(DepotsDocument, baseOptions);
        }
export type DepotsQueryHookResult = ReturnType<typeof useDepotsQuery>;
export type DepotsLazyQueryHookResult = ReturnType<typeof useDepotsLazyQuery>;
export type DepotsQueryResult = Apollo.QueryResult<GqlDepotsQuery, GqlDepotsQueryVariables>;
export const RoutesDocument = gql`
    query Routes($filters: GenericScalar, $search: String, $per_page: Int, $page: Int) {
  routes_v2(filters: $filters, search: $search, page: $page, per_page: $per_page) {
    edges {
      node {
        date_created
        depot_id
        depot {
          depot_name
          depot_id
          area_id
          area {
            area_name
            region_id
            region {
              region_name
              region_id
            }
          }
        }
        route_id
        route_name
      }
    }
  }
}
    `;

/**
 * __useRoutesQuery__
 *
 * To run a query within a React component, call `useRoutesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoutesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoutesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      search: // value for 'search'
 *      per_page: // value for 'per_page'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useRoutesQuery(baseOptions?: Apollo.QueryHookOptions<GqlRoutesQuery, GqlRoutesQueryVariables>) {
        return Apollo.useQuery<GqlRoutesQuery, GqlRoutesQueryVariables>(RoutesDocument, baseOptions);
      }
export function useRoutesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlRoutesQuery, GqlRoutesQueryVariables>) {
          return Apollo.useLazyQuery<GqlRoutesQuery, GqlRoutesQueryVariables>(RoutesDocument, baseOptions);
        }
export type RoutesQueryHookResult = ReturnType<typeof useRoutesQuery>;
export type RoutesLazyQueryHookResult = ReturnType<typeof useRoutesLazyQuery>;
export type RoutesQueryResult = Apollo.QueryResult<GqlRoutesQuery, GqlRoutesQueryVariables>;
export const RegionsDocument = gql`
    query Regions($filters: GenericScalar) {
  regions_v2(filters: $filters) {
    edges {
      node {
        region_id
        region_name
        country_id
        country {
          default_currency
        }
        areas {
          area_id
          area_name
          depots {
            depot_id
            depot_name
            routes {
              route_id
              route_name
            }
          }
        }
        country_id
      }
    }
  }
}
    `;

/**
 * __useRegionsQuery__
 *
 * To run a query within a React component, call `useRegionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegionsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useRegionsQuery(baseOptions?: Apollo.QueryHookOptions<GqlRegionsQuery, GqlRegionsQueryVariables>) {
        return Apollo.useQuery<GqlRegionsQuery, GqlRegionsQueryVariables>(RegionsDocument, baseOptions);
      }
export function useRegionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlRegionsQuery, GqlRegionsQueryVariables>) {
          return Apollo.useLazyQuery<GqlRegionsQuery, GqlRegionsQueryVariables>(RegionsDocument, baseOptions);
        }
export type RegionsQueryHookResult = ReturnType<typeof useRegionsQuery>;
export type RegionsLazyQueryHookResult = ReturnType<typeof useRegionsLazyQuery>;
export type RegionsQueryResult = Apollo.QueryResult<GqlRegionsQuery, GqlRegionsQueryVariables>;
export const FetchDistributionCentersDocument = gql`
    query fetchDistributionCenters {
  distribution_centers {
    distribution_center_name
    distribution_center_id
    distribution_center_type
  }
}
    `;

/**
 * __useFetchDistributionCentersQuery__
 *
 * To run a query within a React component, call `useFetchDistributionCentersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchDistributionCentersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchDistributionCentersQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchDistributionCentersQuery(baseOptions?: Apollo.QueryHookOptions<GqlFetchDistributionCentersQuery, GqlFetchDistributionCentersQueryVariables>) {
        return Apollo.useQuery<GqlFetchDistributionCentersQuery, GqlFetchDistributionCentersQueryVariables>(FetchDistributionCentersDocument, baseOptions);
      }
export function useFetchDistributionCentersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlFetchDistributionCentersQuery, GqlFetchDistributionCentersQueryVariables>) {
          return Apollo.useLazyQuery<GqlFetchDistributionCentersQuery, GqlFetchDistributionCentersQueryVariables>(FetchDistributionCentersDocument, baseOptions);
        }
export type FetchDistributionCentersQueryHookResult = ReturnType<typeof useFetchDistributionCentersQuery>;
export type FetchDistributionCentersLazyQueryHookResult = ReturnType<typeof useFetchDistributionCentersLazyQuery>;
export type FetchDistributionCentersQueryResult = Apollo.QueryResult<GqlFetchDistributionCentersQuery, GqlFetchDistributionCentersQueryVariables>;
export const FetchDynamicPricingDocument = gql`
    query fetchDynamicPricing($search: String, $page: Int, $per_page: Int) {
  dynamic_pricing(
    exclude_markdowns: false
    valid_only: true
    search: $search
    page: $page
    per_page: $per_page
  ) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        dynamic_pricing_id
        pricing_name
        description
        formula
        active
        start_time
        end_time
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useFetchDynamicPricingQuery__
 *
 * To run a query within a React component, call `useFetchDynamicPricingQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchDynamicPricingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchDynamicPricingQuery({
 *   variables: {
 *      search: // value for 'search'
 *      page: // value for 'page'
 *      per_page: // value for 'per_page'
 *   },
 * });
 */
export function useFetchDynamicPricingQuery(baseOptions?: Apollo.QueryHookOptions<GqlFetchDynamicPricingQuery, GqlFetchDynamicPricingQueryVariables>) {
        return Apollo.useQuery<GqlFetchDynamicPricingQuery, GqlFetchDynamicPricingQueryVariables>(FetchDynamicPricingDocument, baseOptions);
      }
export function useFetchDynamicPricingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlFetchDynamicPricingQuery, GqlFetchDynamicPricingQueryVariables>) {
          return Apollo.useLazyQuery<GqlFetchDynamicPricingQuery, GqlFetchDynamicPricingQueryVariables>(FetchDynamicPricingDocument, baseOptions);
        }
export type FetchDynamicPricingQueryHookResult = ReturnType<typeof useFetchDynamicPricingQuery>;
export type FetchDynamicPricingLazyQueryHookResult = ReturnType<typeof useFetchDynamicPricingLazyQuery>;
export type FetchDynamicPricingQueryResult = Apollo.QueryResult<GqlFetchDynamicPricingQuery, GqlFetchDynamicPricingQueryVariables>;
export const McommerceFaqsDocument = gql`
    query mcommerceFAQS {
  mcommerce_faqs {
    page_info {
      ...Pagination
    }
    edges {
      node {
        client_stamp
        client_id
        answer
        question
        answer
        date_created
        created_by
        faq_id
        faq_section_id
        faq_section {
          name
          faq_section_id
        }
      }
    }
  }
  mcommerce_faq_sections {
    name
    date_created
    active
    icon_url
    faq_section_id
    client_stamp
    faqs {
      client_stamp
      client_id
      answer
      active
      question
      answer
      date_created
      created_by
      faq_id
      faq_section_id
      faq_section {
        name
        faq_section_id
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useMcommerceFaqsQuery__
 *
 * To run a query within a React component, call `useMcommerceFaqsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMcommerceFaqsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMcommerceFaqsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMcommerceFaqsQuery(baseOptions?: Apollo.QueryHookOptions<GqlMcommerceFaqsQuery, GqlMcommerceFaqsQueryVariables>) {
        return Apollo.useQuery<GqlMcommerceFaqsQuery, GqlMcommerceFaqsQueryVariables>(McommerceFaqsDocument, baseOptions);
      }
export function useMcommerceFaqsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlMcommerceFaqsQuery, GqlMcommerceFaqsQueryVariables>) {
          return Apollo.useLazyQuery<GqlMcommerceFaqsQuery, GqlMcommerceFaqsQueryVariables>(McommerceFaqsDocument, baseOptions);
        }
export type McommerceFaqsQueryHookResult = ReturnType<typeof useMcommerceFaqsQuery>;
export type McommerceFaqsLazyQueryHookResult = ReturnType<typeof useMcommerceFaqsLazyQuery>;
export type McommerceFaqsQueryResult = Apollo.QueryResult<GqlMcommerceFaqsQuery, GqlMcommerceFaqsQueryVariables>;
export const McommerceFaqSectionsDocument = gql`
    query mcommerceFAQSections {
  mcommerce_faq_sections {
    date_created
    created_by
    client_stamp
    faq_section_id
    name
  }
}
    `;

/**
 * __useMcommerceFaqSectionsQuery__
 *
 * To run a query within a React component, call `useMcommerceFaqSectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMcommerceFaqSectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMcommerceFaqSectionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMcommerceFaqSectionsQuery(baseOptions?: Apollo.QueryHookOptions<GqlMcommerceFaqSectionsQuery, GqlMcommerceFaqSectionsQueryVariables>) {
        return Apollo.useQuery<GqlMcommerceFaqSectionsQuery, GqlMcommerceFaqSectionsQueryVariables>(McommerceFaqSectionsDocument, baseOptions);
      }
export function useMcommerceFaqSectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlMcommerceFaqSectionsQuery, GqlMcommerceFaqSectionsQueryVariables>) {
          return Apollo.useLazyQuery<GqlMcommerceFaqSectionsQuery, GqlMcommerceFaqSectionsQueryVariables>(McommerceFaqSectionsDocument, baseOptions);
        }
export type McommerceFaqSectionsQueryHookResult = ReturnType<typeof useMcommerceFaqSectionsQuery>;
export type McommerceFaqSectionsLazyQueryHookResult = ReturnType<typeof useMcommerceFaqSectionsLazyQuery>;
export type McommerceFaqSectionsQueryResult = Apollo.QueryResult<GqlMcommerceFaqSectionsQuery, GqlMcommerceFaqSectionsQueryVariables>;
export const OrderRestrictionsDocument = gql`
    query orderRestrictions($cluster_id: String!, $depot_id: String!, $route_id: String!, $customer_id: String!, $shop_id: String!, $shop_type_id: String!) {
  order_restrictions(
    cluster_id: $cluster_id
    depot_id: $depot_id
    route_id: $route_id
    customer_id: $customer_id
    shop_id: $shop_id
    shop_type_id: $shop_type_id
  ) {
    order_restriction_id
    product_id
    product_item_id
    active
    product_name {
      product_id
      product_name
    }
    product_item_name {
      product_item_id
      product_item_name
    }
    max_order_quantity
    min_order_quantity
    max_orders
    start_date
    end_date
  }
}
    `;

/**
 * __useOrderRestrictionsQuery__
 *
 * To run a query within a React component, call `useOrderRestrictionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderRestrictionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderRestrictionsQuery({
 *   variables: {
 *      cluster_id: // value for 'cluster_id'
 *      depot_id: // value for 'depot_id'
 *      route_id: // value for 'route_id'
 *      customer_id: // value for 'customer_id'
 *      shop_id: // value for 'shop_id'
 *      shop_type_id: // value for 'shop_type_id'
 *   },
 * });
 */
export function useOrderRestrictionsQuery(baseOptions: Apollo.QueryHookOptions<GqlOrderRestrictionsQuery, GqlOrderRestrictionsQueryVariables>) {
        return Apollo.useQuery<GqlOrderRestrictionsQuery, GqlOrderRestrictionsQueryVariables>(OrderRestrictionsDocument, baseOptions);
      }
export function useOrderRestrictionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlOrderRestrictionsQuery, GqlOrderRestrictionsQueryVariables>) {
          return Apollo.useLazyQuery<GqlOrderRestrictionsQuery, GqlOrderRestrictionsQueryVariables>(OrderRestrictionsDocument, baseOptions);
        }
export type OrderRestrictionsQueryHookResult = ReturnType<typeof useOrderRestrictionsQuery>;
export type OrderRestrictionsLazyQueryHookResult = ReturnType<typeof useOrderRestrictionsLazyQuery>;
export type OrderRestrictionsQueryResult = Apollo.QueryResult<GqlOrderRestrictionsQuery, GqlOrderRestrictionsQueryVariables>;
export const PermissionsV2Document = gql`
    query permissionsV2($search: String, $page: Int, $per_page: Int) {
  permissions_v2(search: $search, page: $page, per_page: $per_page) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        permission_name
        permission_id
        active
        roles {
          role_id
          role_name
        }
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __usePermissionsV2Query__
 *
 * To run a query within a React component, call `usePermissionsV2Query` and pass it any options that fit your needs.
 * When your component renders, `usePermissionsV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePermissionsV2Query({
 *   variables: {
 *      search: // value for 'search'
 *      page: // value for 'page'
 *      per_page: // value for 'per_page'
 *   },
 * });
 */
export function usePermissionsV2Query(baseOptions?: Apollo.QueryHookOptions<GqlPermissionsV2Query, GqlPermissionsV2QueryVariables>) {
        return Apollo.useQuery<GqlPermissionsV2Query, GqlPermissionsV2QueryVariables>(PermissionsV2Document, baseOptions);
      }
export function usePermissionsV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlPermissionsV2Query, GqlPermissionsV2QueryVariables>) {
          return Apollo.useLazyQuery<GqlPermissionsV2Query, GqlPermissionsV2QueryVariables>(PermissionsV2Document, baseOptions);
        }
export type PermissionsV2QueryHookResult = ReturnType<typeof usePermissionsV2Query>;
export type PermissionsV2LazyQueryHookResult = ReturnType<typeof usePermissionsV2LazyQuery>;
export type PermissionsV2QueryResult = Apollo.QueryResult<GqlPermissionsV2Query, GqlPermissionsV2QueryVariables>;
export const ProductsV2Document = gql`
    query ProductsV2($search: String, $productFilters: GenericScalar, $per_page: Int) {
  paginated_products_v2(
    search: $search
    per_page: $per_page
    filters: $productFilters
  ) {
    edges {
      node {
        active
        product_name
        product_id
        product_code
        product_type
        product_items {
          product_item_id
          product_item_name
          base_price
          units_of_measure {
            business_domain_id
            business_domain {
              domain_name
            }
            uom_id
            uom {
              name
              uom_id
            }
          }
          regions {
            region_id
            region {
              region_name
              region_id
              country {
                ...Country
              }
            }
          }
        }
        packaging_units {
          active
          name
          weight
          packaging_unit_id
        }
        domain_activations {
          business_domain {
            domain_name
            business_domain_id
          }
        }
      }
    }
  }
}
    ${CountryFragmentDoc}`;

/**
 * __useProductsV2Query__
 *
 * To run a query within a React component, call `useProductsV2Query` and pass it any options that fit your needs.
 * When your component renders, `useProductsV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsV2Query({
 *   variables: {
 *      search: // value for 'search'
 *      productFilters: // value for 'productFilters'
 *      per_page: // value for 'per_page'
 *   },
 * });
 */
export function useProductsV2Query(baseOptions?: Apollo.QueryHookOptions<GqlProductsV2Query, GqlProductsV2QueryVariables>) {
        return Apollo.useQuery<GqlProductsV2Query, GqlProductsV2QueryVariables>(ProductsV2Document, baseOptions);
      }
export function useProductsV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlProductsV2Query, GqlProductsV2QueryVariables>) {
          return Apollo.useLazyQuery<GqlProductsV2Query, GqlProductsV2QueryVariables>(ProductsV2Document, baseOptions);
        }
export type ProductsV2QueryHookResult = ReturnType<typeof useProductsV2Query>;
export type ProductsV2LazyQueryHookResult = ReturnType<typeof useProductsV2LazyQuery>;
export type ProductsV2QueryResult = Apollo.QueryResult<GqlProductsV2Query, GqlProductsV2QueryVariables>;
export const ProductItemsV2Document = gql`
    query ProductItemsV2($search: String, $productItemFilters: GenericScalar, $per_page: Int) {
  paginated_product_items_v2(
    search: $search
    per_page: $per_page
    filters: $productItemFilters
  ) {
    edges {
      node {
        etims_quantity_code
        ...Product
      }
    }
  }
}
    ${ProductFragmentDoc}`;

/**
 * __useProductItemsV2Query__
 *
 * To run a query within a React component, call `useProductItemsV2Query` and pass it any options that fit your needs.
 * When your component renders, `useProductItemsV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductItemsV2Query({
 *   variables: {
 *      search: // value for 'search'
 *      productItemFilters: // value for 'productItemFilters'
 *      per_page: // value for 'per_page'
 *   },
 * });
 */
export function useProductItemsV2Query(baseOptions?: Apollo.QueryHookOptions<GqlProductItemsV2Query, GqlProductItemsV2QueryVariables>) {
        return Apollo.useQuery<GqlProductItemsV2Query, GqlProductItemsV2QueryVariables>(ProductItemsV2Document, baseOptions);
      }
export function useProductItemsV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlProductItemsV2Query, GqlProductItemsV2QueryVariables>) {
          return Apollo.useLazyQuery<GqlProductItemsV2Query, GqlProductItemsV2QueryVariables>(ProductItemsV2Document, baseOptions);
        }
export type ProductItemsV2QueryHookResult = ReturnType<typeof useProductItemsV2Query>;
export type ProductItemsV2LazyQueryHookResult = ReturnType<typeof useProductItemsV2LazyQuery>;
export type ProductItemsV2QueryResult = Apollo.QueryResult<GqlProductItemsV2Query, GqlProductItemsV2QueryVariables>;
export const ProductTypesDocument = gql`
    query productTypes {
  product_types_v2
}
    `;

/**
 * __useProductTypesQuery__
 *
 * To run a query within a React component, call `useProductTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useProductTypesQuery(baseOptions?: Apollo.QueryHookOptions<GqlProductTypesQuery, GqlProductTypesQueryVariables>) {
        return Apollo.useQuery<GqlProductTypesQuery, GqlProductTypesQueryVariables>(ProductTypesDocument, baseOptions);
      }
export function useProductTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlProductTypesQuery, GqlProductTypesQueryVariables>) {
          return Apollo.useLazyQuery<GqlProductTypesQuery, GqlProductTypesQueryVariables>(ProductTypesDocument, baseOptions);
        }
export type ProductTypesQueryHookResult = ReturnType<typeof useProductTypesQuery>;
export type ProductTypesLazyQueryHookResult = ReturnType<typeof useProductTypesLazyQuery>;
export type ProductTypesQueryResult = Apollo.QueryResult<GqlProductTypesQuery, GqlProductTypesQueryVariables>;
export const ProductVarietiesDocument = gql`
    query ProductVarieties($search: String, $filters: GenericScalar, $per_page: Int) {
  product_varieties(search: $search, per_page: $per_page, filters: $filters) {
    edges {
      node {
        variety_name
        variety_code
      }
    }
  }
}
    `;

/**
 * __useProductVarietiesQuery__
 *
 * To run a query within a React component, call `useProductVarietiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductVarietiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductVarietiesQuery({
 *   variables: {
 *      search: // value for 'search'
 *      filters: // value for 'filters'
 *      per_page: // value for 'per_page'
 *   },
 * });
 */
export function useProductVarietiesQuery(baseOptions?: Apollo.QueryHookOptions<GqlProductVarietiesQuery, GqlProductVarietiesQueryVariables>) {
        return Apollo.useQuery<GqlProductVarietiesQuery, GqlProductVarietiesQueryVariables>(ProductVarietiesDocument, baseOptions);
      }
export function useProductVarietiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlProductVarietiesQuery, GqlProductVarietiesQueryVariables>) {
          return Apollo.useLazyQuery<GqlProductVarietiesQuery, GqlProductVarietiesQueryVariables>(ProductVarietiesDocument, baseOptions);
        }
export type ProductVarietiesQueryHookResult = ReturnType<typeof useProductVarietiesQuery>;
export type ProductVarietiesLazyQueryHookResult = ReturnType<typeof useProductVarietiesLazyQuery>;
export type ProductVarietiesQueryResult = Apollo.QueryResult<GqlProductVarietiesQuery, GqlProductVarietiesQueryVariables>;
export const RolesV2Document = gql`
    query rolesV2($search: String, $page: Int, $per_page: Int, $filters: GenericScalar) {
  roles_v2(search: $search, page: $page, per_page: $per_page, filters: $filters) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        role_id
        role_name
        active
        permissions {
          permission_id
          permission_name
        }
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useRolesV2Query__
 *
 * To run a query within a React component, call `useRolesV2Query` and pass it any options that fit your needs.
 * When your component renders, `useRolesV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRolesV2Query({
 *   variables: {
 *      search: // value for 'search'
 *      page: // value for 'page'
 *      per_page: // value for 'per_page'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useRolesV2Query(baseOptions?: Apollo.QueryHookOptions<GqlRolesV2Query, GqlRolesV2QueryVariables>) {
        return Apollo.useQuery<GqlRolesV2Query, GqlRolesV2QueryVariables>(RolesV2Document, baseOptions);
      }
export function useRolesV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlRolesV2Query, GqlRolesV2QueryVariables>) {
          return Apollo.useLazyQuery<GqlRolesV2Query, GqlRolesV2QueryVariables>(RolesV2Document, baseOptions);
        }
export type RolesV2QueryHookResult = ReturnType<typeof useRolesV2Query>;
export type RolesV2LazyQueryHookResult = ReturnType<typeof useRolesV2LazyQuery>;
export type RolesV2QueryResult = Apollo.QueryResult<GqlRolesV2Query, GqlRolesV2QueryVariables>;
export const UserV2ByIdDocument = gql`
    query userV2ById($user_id: String) {
  user_v2_by_id(user_id: $user_id) {
    date_created
    first_name
    last_name
    region_id
    region {
      region_id
      region_name
    }
    default_country_id
    country_ids
    roles {
      role_id
      role_name
    }
    permissions {
      permission_id
      permission_name
    }
    primary_role {
      role_name
      role_id
      permissions {
        permission_name
        permission_id
      }
    }
    permissions_blacklist {
      permission_name
      permission_id
    }
    phone_number
    email
    active
    user_no
  }
}
    `;

/**
 * __useUserV2ByIdQuery__
 *
 * To run a query within a React component, call `useUserV2ByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserV2ByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserV2ByIdQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useUserV2ByIdQuery(baseOptions?: Apollo.QueryHookOptions<GqlUserV2ByIdQuery, GqlUserV2ByIdQueryVariables>) {
        return Apollo.useQuery<GqlUserV2ByIdQuery, GqlUserV2ByIdQueryVariables>(UserV2ByIdDocument, baseOptions);
      }
export function useUserV2ByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlUserV2ByIdQuery, GqlUserV2ByIdQueryVariables>) {
          return Apollo.useLazyQuery<GqlUserV2ByIdQuery, GqlUserV2ByIdQueryVariables>(UserV2ByIdDocument, baseOptions);
        }
export type UserV2ByIdQueryHookResult = ReturnType<typeof useUserV2ByIdQuery>;
export type UserV2ByIdLazyQueryHookResult = ReturnType<typeof useUserV2ByIdLazyQuery>;
export type UserV2ByIdQueryResult = Apollo.QueryResult<GqlUserV2ByIdQuery, GqlUserV2ByIdQueryVariables>;
export const UsersV2Document = gql`
    query usersV2($search: String, $per_page: Int, $page: Int, $filters: GenericScalar) {
  users_v2(search: $search, per_page: $per_page, page: $page, filters: $filters) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        first_name
        last_name
        user_id
        user_no
        phone_number
        active
        email
        country_ids
        default_country_id
        primary_role_id
        primary_role {
          role_name
        }
        roles {
          role_id
          role_name
        }
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useUsersV2Query__
 *
 * To run a query within a React component, call `useUsersV2Query` and pass it any options that fit your needs.
 * When your component renders, `useUsersV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersV2Query({
 *   variables: {
 *      search: // value for 'search'
 *      per_page: // value for 'per_page'
 *      page: // value for 'page'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useUsersV2Query(baseOptions?: Apollo.QueryHookOptions<GqlUsersV2Query, GqlUsersV2QueryVariables>) {
        return Apollo.useQuery<GqlUsersV2Query, GqlUsersV2QueryVariables>(UsersV2Document, baseOptions);
      }
export function useUsersV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlUsersV2Query, GqlUsersV2QueryVariables>) {
          return Apollo.useLazyQuery<GqlUsersV2Query, GqlUsersV2QueryVariables>(UsersV2Document, baseOptions);
        }
export type UsersV2QueryHookResult = ReturnType<typeof useUsersV2Query>;
export type UsersV2LazyQueryHookResult = ReturnType<typeof useUsersV2LazyQuery>;
export type UsersV2QueryResult = Apollo.QueryResult<GqlUsersV2Query, GqlUsersV2QueryVariables>;
export const GetDepotRestrictionsDocument = gql`
    query getDepotRestrictions($page: Int, $per_page: Int) {
  depot_dc_product_item_restrictions(
    active: true
    page: $page
    per_page: $per_page
  ) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        depot_dc_product_item_restriction_id
        depot_id
        depot {
          depot_name
          area {
            region {
              region_id
              region_name
              country {
                country_id
                iso_alpha2_name
              }
            }
          }
        }
        product_item_id
        product_item {
          product_item_name
          product {
            product_name
          }
        }
        distribution_center_ids
        distribution_centers {
          distribution_center_name
          distribution_center_type
          distribution_center_id
        }
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useGetDepotRestrictionsQuery__
 *
 * To run a query within a React component, call `useGetDepotRestrictionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDepotRestrictionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDepotRestrictionsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      per_page: // value for 'per_page'
 *   },
 * });
 */
export function useGetDepotRestrictionsQuery(baseOptions?: Apollo.QueryHookOptions<GqlGetDepotRestrictionsQuery, GqlGetDepotRestrictionsQueryVariables>) {
        return Apollo.useQuery<GqlGetDepotRestrictionsQuery, GqlGetDepotRestrictionsQueryVariables>(GetDepotRestrictionsDocument, baseOptions);
      }
export function useGetDepotRestrictionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlGetDepotRestrictionsQuery, GqlGetDepotRestrictionsQueryVariables>) {
          return Apollo.useLazyQuery<GqlGetDepotRestrictionsQuery, GqlGetDepotRestrictionsQueryVariables>(GetDepotRestrictionsDocument, baseOptions);
        }
export type GetDepotRestrictionsQueryHookResult = ReturnType<typeof useGetDepotRestrictionsQuery>;
export type GetDepotRestrictionsLazyQueryHookResult = ReturnType<typeof useGetDepotRestrictionsLazyQuery>;
export type GetDepotRestrictionsQueryResult = Apollo.QueryResult<GqlGetDepotRestrictionsQuery, GqlGetDepotRestrictionsQueryVariables>;
export const GetDistributionCentersDocument = gql`
    query GetDistributionCenters {
  distribution_centers {
    date_created
    distribution_center_id
    distribution_center_name
    distribution_center_type
    description
    branch_id
  }
}
    `;

/**
 * __useGetDistributionCentersQuery__
 *
 * To run a query within a React component, call `useGetDistributionCentersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDistributionCentersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDistributionCentersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDistributionCentersQuery(baseOptions?: Apollo.QueryHookOptions<GqlGetDistributionCentersQuery, GqlGetDistributionCentersQueryVariables>) {
        return Apollo.useQuery<GqlGetDistributionCentersQuery, GqlGetDistributionCentersQueryVariables>(GetDistributionCentersDocument, baseOptions);
      }
export function useGetDistributionCentersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlGetDistributionCentersQuery, GqlGetDistributionCentersQueryVariables>) {
          return Apollo.useLazyQuery<GqlGetDistributionCentersQuery, GqlGetDistributionCentersQueryVariables>(GetDistributionCentersDocument, baseOptions);
        }
export type GetDistributionCentersQueryHookResult = ReturnType<typeof useGetDistributionCentersQuery>;
export type GetDistributionCentersLazyQueryHookResult = ReturnType<typeof useGetDistributionCentersLazyQuery>;
export type GetDistributionCentersQueryResult = Apollo.QueryResult<GqlGetDistributionCentersQuery, GqlGetDistributionCentersQueryVariables>;
export const FetchPseudoRoutesDocument = gql`
    query fetchPseudoRoutes($filters: GenericScalar, $per_page: Int, $page: Int) {
  pseudo_routes(filters: $filters, per_page: $per_page, page: $page) {
    edges {
      node {
        active
        pseudo_route_id
        name
        merged_routes
        constraints
        custom_constraints
        force_share
        routes {
          route_id
          route_name
          depot {
            depot_name
          }
        }
      }
    }
    page_info {
      ...Pagination
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useFetchPseudoRoutesQuery__
 *
 * To run a query within a React component, call `useFetchPseudoRoutesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPseudoRoutesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPseudoRoutesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      per_page: // value for 'per_page'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useFetchPseudoRoutesQuery(baseOptions?: Apollo.QueryHookOptions<GqlFetchPseudoRoutesQuery, GqlFetchPseudoRoutesQueryVariables>) {
        return Apollo.useQuery<GqlFetchPseudoRoutesQuery, GqlFetchPseudoRoutesQueryVariables>(FetchPseudoRoutesDocument, baseOptions);
      }
export function useFetchPseudoRoutesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlFetchPseudoRoutesQuery, GqlFetchPseudoRoutesQueryVariables>) {
          return Apollo.useLazyQuery<GqlFetchPseudoRoutesQuery, GqlFetchPseudoRoutesQueryVariables>(FetchPseudoRoutesDocument, baseOptions);
        }
export type FetchPseudoRoutesQueryHookResult = ReturnType<typeof useFetchPseudoRoutesQuery>;
export type FetchPseudoRoutesLazyQueryHookResult = ReturnType<typeof useFetchPseudoRoutesLazyQuery>;
export type FetchPseudoRoutesQueryResult = Apollo.QueryResult<GqlFetchPseudoRoutesQuery, GqlFetchPseudoRoutesQueryVariables>;
export const FetchPseudoRouteByNameDocument = gql`
    query fetchPseudoRouteByName($name: String!) {
  pseudo_route_by_id(pseudo_route_id: $name) {
    pseudo_route_id
  }
}
    `;

/**
 * __useFetchPseudoRouteByNameQuery__
 *
 * To run a query within a React component, call `useFetchPseudoRouteByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPseudoRouteByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPseudoRouteByNameQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useFetchPseudoRouteByNameQuery(baseOptions: Apollo.QueryHookOptions<GqlFetchPseudoRouteByNameQuery, GqlFetchPseudoRouteByNameQueryVariables>) {
        return Apollo.useQuery<GqlFetchPseudoRouteByNameQuery, GqlFetchPseudoRouteByNameQueryVariables>(FetchPseudoRouteByNameDocument, baseOptions);
      }
export function useFetchPseudoRouteByNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlFetchPseudoRouteByNameQuery, GqlFetchPseudoRouteByNameQueryVariables>) {
          return Apollo.useLazyQuery<GqlFetchPseudoRouteByNameQuery, GqlFetchPseudoRouteByNameQueryVariables>(FetchPseudoRouteByNameDocument, baseOptions);
        }
export type FetchPseudoRouteByNameQueryHookResult = ReturnType<typeof useFetchPseudoRouteByNameQuery>;
export type FetchPseudoRouteByNameLazyQueryHookResult = ReturnType<typeof useFetchPseudoRouteByNameLazyQuery>;
export type FetchPseudoRouteByNameQueryResult = Apollo.QueryResult<GqlFetchPseudoRouteByNameQuery, GqlFetchPseudoRouteByNameQueryVariables>;
export const RoutesV2Document = gql`
    query routesV2($per_page: Int, $page: Int, $search: String, $filters: GenericScalar) {
  routes_v2(page: $page, per_page: $per_page, search: $search, filters: $filters) {
    page_info {
      per_page
      total_count
      total_pages
      current_page
      has_next
      has_previous
    }
    edges {
      node {
        route_name
        route_id
        date_created
        depot_id
        depot {
          depot_name
          depot_id
          area_id
          area {
            area_name
            region_id
            region {
              region_name
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useRoutesV2Query__
 *
 * To run a query within a React component, call `useRoutesV2Query` and pass it any options that fit your needs.
 * When your component renders, `useRoutesV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoutesV2Query({
 *   variables: {
 *      per_page: // value for 'per_page'
 *      page: // value for 'page'
 *      search: // value for 'search'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useRoutesV2Query(baseOptions?: Apollo.QueryHookOptions<GqlRoutesV2Query, GqlRoutesV2QueryVariables>) {
        return Apollo.useQuery<GqlRoutesV2Query, GqlRoutesV2QueryVariables>(RoutesV2Document, baseOptions);
      }
export function useRoutesV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlRoutesV2Query, GqlRoutesV2QueryVariables>) {
          return Apollo.useLazyQuery<GqlRoutesV2Query, GqlRoutesV2QueryVariables>(RoutesV2Document, baseOptions);
        }
export type RoutesV2QueryHookResult = ReturnType<typeof useRoutesV2Query>;
export type RoutesV2LazyQueryHookResult = ReturnType<typeof useRoutesV2LazyQuery>;
export type RoutesV2QueryResult = Apollo.QueryResult<GqlRoutesV2Query, GqlRoutesV2QueryVariables>;
export const RouteTypesDocument = gql`
    query routeTypes {
  route_types
}
    `;

/**
 * __useRouteTypesQuery__
 *
 * To run a query within a React component, call `useRouteTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRouteTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRouteTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRouteTypesQuery(baseOptions?: Apollo.QueryHookOptions<GqlRouteTypesQuery, GqlRouteTypesQueryVariables>) {
        return Apollo.useQuery<GqlRouteTypesQuery, GqlRouteTypesQueryVariables>(RouteTypesDocument, baseOptions);
      }
export function useRouteTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlRouteTypesQuery, GqlRouteTypesQueryVariables>) {
          return Apollo.useLazyQuery<GqlRouteTypesQuery, GqlRouteTypesQueryVariables>(RouteTypesDocument, baseOptions);
        }
export type RouteTypesQueryHookResult = ReturnType<typeof useRouteTypesQuery>;
export type RouteTypesLazyQueryHookResult = ReturnType<typeof useRouteTypesLazyQuery>;
export type RouteTypesQueryResult = Apollo.QueryResult<GqlRouteTypesQuery, GqlRouteTypesQueryVariables>;
export const Storage_Unit_TypesDocument = gql`
    query storage_unit_types {
  storage_unit_types {
    date_created
    storage_unit_type_id
    storage_unit_type
  }
}
    `;

/**
 * __useStorage_Unit_TypesQuery__
 *
 * To run a query within a React component, call `useStorage_Unit_TypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStorage_Unit_TypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStorage_Unit_TypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useStorage_Unit_TypesQuery(baseOptions?: Apollo.QueryHookOptions<GqlStorage_Unit_TypesQuery, GqlStorage_Unit_TypesQueryVariables>) {
        return Apollo.useQuery<GqlStorage_Unit_TypesQuery, GqlStorage_Unit_TypesQueryVariables>(Storage_Unit_TypesDocument, baseOptions);
      }
export function useStorage_Unit_TypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlStorage_Unit_TypesQuery, GqlStorage_Unit_TypesQueryVariables>) {
          return Apollo.useLazyQuery<GqlStorage_Unit_TypesQuery, GqlStorage_Unit_TypesQueryVariables>(Storage_Unit_TypesDocument, baseOptions);
        }
export type Storage_Unit_TypesQueryHookResult = ReturnType<typeof useStorage_Unit_TypesQuery>;
export type Storage_Unit_TypesLazyQueryHookResult = ReturnType<typeof useStorage_Unit_TypesLazyQuery>;
export type Storage_Unit_TypesQueryResult = Apollo.QueryResult<GqlStorage_Unit_TypesQuery, GqlStorage_Unit_TypesQueryVariables>;
export const Storage_UnitsDocument = gql`
    query storage_units($per_page: Int, $page: Int) {
  storage_units(page: $page, per_page: $per_page) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        date_created
        unit_name
        warehouse_id
        warehouse {
          warehouse_name
          distribution_center_id
          distribution_center {
            distribution_center_name
          }
        }
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useStorage_UnitsQuery__
 *
 * To run a query within a React component, call `useStorage_UnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStorage_UnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStorage_UnitsQuery({
 *   variables: {
 *      per_page: // value for 'per_page'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useStorage_UnitsQuery(baseOptions?: Apollo.QueryHookOptions<GqlStorage_UnitsQuery, GqlStorage_UnitsQueryVariables>) {
        return Apollo.useQuery<GqlStorage_UnitsQuery, GqlStorage_UnitsQueryVariables>(Storage_UnitsDocument, baseOptions);
      }
export function useStorage_UnitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlStorage_UnitsQuery, GqlStorage_UnitsQueryVariables>) {
          return Apollo.useLazyQuery<GqlStorage_UnitsQuery, GqlStorage_UnitsQueryVariables>(Storage_UnitsDocument, baseOptions);
        }
export type Storage_UnitsQueryHookResult = ReturnType<typeof useStorage_UnitsQuery>;
export type Storage_UnitsLazyQueryHookResult = ReturnType<typeof useStorage_UnitsLazyQuery>;
export type Storage_UnitsQueryResult = Apollo.QueryResult<GqlStorage_UnitsQuery, GqlStorage_UnitsQueryVariables>;
export const WarehousesDocument = gql`
    query warehouses($per_page: Int, $page: Int) {
  warehouses(page: $page, per_page: $per_page) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        warehouse_id
        warehouse_name
        distribution_center_id
        distribution_center {
          distribution_center_id
          distribution_center_name
        }
        date_created
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useWarehousesQuery__
 *
 * To run a query within a React component, call `useWarehousesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWarehousesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWarehousesQuery({
 *   variables: {
 *      per_page: // value for 'per_page'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useWarehousesQuery(baseOptions?: Apollo.QueryHookOptions<GqlWarehousesQuery, GqlWarehousesQueryVariables>) {
        return Apollo.useQuery<GqlWarehousesQuery, GqlWarehousesQueryVariables>(WarehousesDocument, baseOptions);
      }
export function useWarehousesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlWarehousesQuery, GqlWarehousesQueryVariables>) {
          return Apollo.useLazyQuery<GqlWarehousesQuery, GqlWarehousesQueryVariables>(WarehousesDocument, baseOptions);
        }
export type WarehousesQueryHookResult = ReturnType<typeof useWarehousesQuery>;
export type WarehousesLazyQueryHookResult = ReturnType<typeof useWarehousesLazyQuery>;
export type WarehousesQueryResult = Apollo.QueryResult<GqlWarehousesQuery, GqlWarehousesQueryVariables>;
export const SourcingAgentsDocument = gql`
    query SourcingAgents($page: Int, $filters: GenericScalar, $per_page: Int, $search: String) {
  sourcing_agents(
    page: $page
    filter: $filters
    per_page: $per_page
    search: $search
  ) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        agent_id
        user_name
        date_created
        active
        farmer_id
        farmer {
          supplier_name
          first_name
          last_name
          phone_number
          batch_code
          country_id
          country {
            iso_alpha2_name
          }
        }
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useSourcingAgentsQuery__
 *
 * To run a query within a React component, call `useSourcingAgentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSourcingAgentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSourcingAgentsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      filters: // value for 'filters'
 *      per_page: // value for 'per_page'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSourcingAgentsQuery(baseOptions?: Apollo.QueryHookOptions<GqlSourcingAgentsQuery, GqlSourcingAgentsQueryVariables>) {
        return Apollo.useQuery<GqlSourcingAgentsQuery, GqlSourcingAgentsQueryVariables>(SourcingAgentsDocument, baseOptions);
      }
export function useSourcingAgentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlSourcingAgentsQuery, GqlSourcingAgentsQueryVariables>) {
          return Apollo.useLazyQuery<GqlSourcingAgentsQuery, GqlSourcingAgentsQueryVariables>(SourcingAgentsDocument, baseOptions);
        }
export type SourcingAgentsQueryHookResult = ReturnType<typeof useSourcingAgentsQuery>;
export type SourcingAgentsLazyQueryHookResult = ReturnType<typeof useSourcingAgentsLazyQuery>;
export type SourcingAgentsQueryResult = Apollo.QueryResult<GqlSourcingAgentsQuery, GqlSourcingAgentsQueryVariables>;
export const SourcingAgentByIdDocument = gql`
    query SourcingAgentByID($agent_id: String) {
  sourcing_agent_by_id(agent_id: $agent_id) {
    agent_id
    user_name
    active
    farmer_id
    farmer {
      supplier_name
      first_name
      last_name
      phone_number
      alt_phone_number
      batch_code
      farmer_type
      country_id
      country {
        iso_alpha2_name
      }
    }
  }
}
    `;

/**
 * __useSourcingAgentByIdQuery__
 *
 * To run a query within a React component, call `useSourcingAgentByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSourcingAgentByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSourcingAgentByIdQuery({
 *   variables: {
 *      agent_id: // value for 'agent_id'
 *   },
 * });
 */
export function useSourcingAgentByIdQuery(baseOptions?: Apollo.QueryHookOptions<GqlSourcingAgentByIdQuery, GqlSourcingAgentByIdQueryVariables>) {
        return Apollo.useQuery<GqlSourcingAgentByIdQuery, GqlSourcingAgentByIdQueryVariables>(SourcingAgentByIdDocument, baseOptions);
      }
export function useSourcingAgentByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlSourcingAgentByIdQuery, GqlSourcingAgentByIdQueryVariables>) {
          return Apollo.useLazyQuery<GqlSourcingAgentByIdQuery, GqlSourcingAgentByIdQueryVariables>(SourcingAgentByIdDocument, baseOptions);
        }
export type SourcingAgentByIdQueryHookResult = ReturnType<typeof useSourcingAgentByIdQuery>;
export type SourcingAgentByIdLazyQueryHookResult = ReturnType<typeof useSourcingAgentByIdLazyQuery>;
export type SourcingAgentByIdQueryResult = Apollo.QueryResult<GqlSourcingAgentByIdQuery, GqlSourcingAgentByIdQueryVariables>;
export const BidApplicationsDocument = gql`
    query BidApplications($page: Int, $filters: GenericScalar, $per_page: Int, $search: String) {
  bid_applications(
    page: $page
    filter: $filters
    per_page: $per_page
    search: $search
  ) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        quantity
        bid_application_number
        bid_application_date
        bid_application_id
        vehicle_registration
        delivery_date
        active
        unit_price
        agent_id
        product_item_id
        product_item {
          product_item_name
        }
        product_id
        product {
          product_name
        }
        agent {
          farmer_id
          farmer {
            supplier_name
            farmer_number
            first_name
            last_name
            phone_number
          }
        }
        unit_of_measure_id
        uom {
          name
          abbreviation
        }
        bid_application_status
        country_id
        country {
          iso_alpha2_name
        }
        currency
        latitude
        longitude
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useBidApplicationsQuery__
 *
 * To run a query within a React component, call `useBidApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBidApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBidApplicationsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      filters: // value for 'filters'
 *      per_page: // value for 'per_page'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useBidApplicationsQuery(baseOptions?: Apollo.QueryHookOptions<GqlBidApplicationsQuery, GqlBidApplicationsQueryVariables>) {
        return Apollo.useQuery<GqlBidApplicationsQuery, GqlBidApplicationsQueryVariables>(BidApplicationsDocument, baseOptions);
      }
export function useBidApplicationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlBidApplicationsQuery, GqlBidApplicationsQueryVariables>) {
          return Apollo.useLazyQuery<GqlBidApplicationsQuery, GqlBidApplicationsQueryVariables>(BidApplicationsDocument, baseOptions);
        }
export type BidApplicationsQueryHookResult = ReturnType<typeof useBidApplicationsQuery>;
export type BidApplicationsLazyQueryHookResult = ReturnType<typeof useBidApplicationsLazyQuery>;
export type BidApplicationsQueryResult = Apollo.QueryResult<GqlBidApplicationsQuery, GqlBidApplicationsQueryVariables>;
export const BidsDocument = gql`
    query bids($page: Int, $filters: GenericScalar, $per_page: Int, $search: String) {
  bids(page: $page, filter: $filters, per_page: $per_page, search: $search) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        bid_id
        date_created
        opening_date
        closing_date
        bid_notes
        active
        minimum_quantity
        bid_application_lowest_price
        bid_application_count
        delivery_date
        bid_number
        opening_bid_price
        quantity
        bid_status
        bid_notes
        product_item_id
        product_item {
          product_item_name
        }
        product_id
        product {
          product_name
        }
        unit_of_measure_id
        uom {
          name
        }
        country_id
        country {
          iso_alpha2_name
        }
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useBidsQuery__
 *
 * To run a query within a React component, call `useBidsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBidsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBidsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      filters: // value for 'filters'
 *      per_page: // value for 'per_page'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useBidsQuery(baseOptions?: Apollo.QueryHookOptions<GqlBidsQuery, GqlBidsQueryVariables>) {
        return Apollo.useQuery<GqlBidsQuery, GqlBidsQueryVariables>(BidsDocument, baseOptions);
      }
export function useBidsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlBidsQuery, GqlBidsQueryVariables>) {
          return Apollo.useLazyQuery<GqlBidsQuery, GqlBidsQueryVariables>(BidsDocument, baseOptions);
        }
export type BidsQueryHookResult = ReturnType<typeof useBidsQuery>;
export type BidsLazyQueryHookResult = ReturnType<typeof useBidsLazyQuery>;
export type BidsQueryResult = Apollo.QueryResult<GqlBidsQuery, GqlBidsQueryVariables>;
export const Bid_By_IdDocument = gql`
    query bid_by_id($bid_id: String) {
  bid_by_id(bid_id: $bid_id) {
    bid_id
    opening_date
    closing_date
    bid_notes
    active
    minimum_quantity
    bid_application_lowest_price
    delivery_date
    bid_number
    opening_bid_price
    quantity
    bid_status
    bid_notes
    product_item_id
    product_item {
      product_item_name
    }
    product_id
    product {
      product_name
    }
    unit_of_measure_id
    uom {
      name
    }
    country_id
    country {
      iso_alpha2_name
    }
    cancellation_reason
    currency
  }
}
    `;

/**
 * __useBid_By_IdQuery__
 *
 * To run a query within a React component, call `useBid_By_IdQuery` and pass it any options that fit your needs.
 * When your component renders, `useBid_By_IdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBid_By_IdQuery({
 *   variables: {
 *      bid_id: // value for 'bid_id'
 *   },
 * });
 */
export function useBid_By_IdQuery(baseOptions?: Apollo.QueryHookOptions<GqlBid_By_IdQuery, GqlBid_By_IdQueryVariables>) {
        return Apollo.useQuery<GqlBid_By_IdQuery, GqlBid_By_IdQueryVariables>(Bid_By_IdDocument, baseOptions);
      }
export function useBid_By_IdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlBid_By_IdQuery, GqlBid_By_IdQueryVariables>) {
          return Apollo.useLazyQuery<GqlBid_By_IdQuery, GqlBid_By_IdQueryVariables>(Bid_By_IdDocument, baseOptions);
        }
export type Bid_By_IdQueryHookResult = ReturnType<typeof useBid_By_IdQuery>;
export type Bid_By_IdLazyQueryHookResult = ReturnType<typeof useBid_By_IdLazyQuery>;
export type Bid_By_IdQueryResult = Apollo.QueryResult<GqlBid_By_IdQuery, GqlBid_By_IdQueryVariables>;
export const SupplierUsersDocument = gql`
    query supplierUsers($page: Int, $per_page: Int, $search: String) {
  supplier_users(page: $page, per_page: $per_page, search: $search) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        supplier_user_id
        date_created
        user_id
        active
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useSupplierUsersQuery__
 *
 * To run a query within a React component, call `useSupplierUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupplierUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupplierUsersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      per_page: // value for 'per_page'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSupplierUsersQuery(baseOptions?: Apollo.QueryHookOptions<GqlSupplierUsersQuery, GqlSupplierUsersQueryVariables>) {
        return Apollo.useQuery<GqlSupplierUsersQuery, GqlSupplierUsersQueryVariables>(SupplierUsersDocument, baseOptions);
      }
export function useSupplierUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlSupplierUsersQuery, GqlSupplierUsersQueryVariables>) {
          return Apollo.useLazyQuery<GqlSupplierUsersQuery, GqlSupplierUsersQueryVariables>(SupplierUsersDocument, baseOptions);
        }
export type SupplierUsersQueryHookResult = ReturnType<typeof useSupplierUsersQuery>;
export type SupplierUsersLazyQueryHookResult = ReturnType<typeof useSupplierUsersLazyQuery>;
export type SupplierUsersQueryResult = Apollo.QueryResult<GqlSupplierUsersQuery, GqlSupplierUsersQueryVariables>;
export const GetAuthorisersDocument = gql`
    query getAuthorisers($page: Int, $per_page: Int, $search: String, $authoriserFilters: AuthorizerGQLInput) {
  authorizers(
    active: true
    page: $page
    per_page: $per_page
    search: $search
    filters: $authoriserFilters
  ) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        authorizerId
        email
        mobileNumber
        name
        reportsTo
        role
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useGetAuthorisersQuery__
 *
 * To run a query within a React component, call `useGetAuthorisersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuthorisersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuthorisersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      per_page: // value for 'per_page'
 *      search: // value for 'search'
 *      authoriserFilters: // value for 'authoriserFilters'
 *   },
 * });
 */
export function useGetAuthorisersQuery(baseOptions?: Apollo.QueryHookOptions<GqlGetAuthorisersQuery, GqlGetAuthorisersQueryVariables>) {
        return Apollo.useQuery<GqlGetAuthorisersQuery, GqlGetAuthorisersQueryVariables>(GetAuthorisersDocument, baseOptions);
      }
export function useGetAuthorisersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlGetAuthorisersQuery, GqlGetAuthorisersQueryVariables>) {
          return Apollo.useLazyQuery<GqlGetAuthorisersQuery, GqlGetAuthorisersQueryVariables>(GetAuthorisersDocument, baseOptions);
        }
export type GetAuthorisersQueryHookResult = ReturnType<typeof useGetAuthorisersQuery>;
export type GetAuthorisersLazyQueryHookResult = ReturnType<typeof useGetAuthorisersLazyQuery>;
export type GetAuthorisersQueryResult = Apollo.QueryResult<GqlGetAuthorisersQuery, GqlGetAuthorisersQueryVariables>;
export const GetWorkflowsDocument = gql`
    query getWorkflows {
  workflows {
    edges {
      node {
        name
        workflowId
        rules {
          key
          value {
            ruleId
            mandate
            authorizers {
              name
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetWorkflowsQuery__
 *
 * To run a query within a React component, call `useGetWorkflowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkflowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkflowsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWorkflowsQuery(baseOptions?: Apollo.QueryHookOptions<GqlGetWorkflowsQuery, GqlGetWorkflowsQueryVariables>) {
        return Apollo.useQuery<GqlGetWorkflowsQuery, GqlGetWorkflowsQueryVariables>(GetWorkflowsDocument, baseOptions);
      }
export function useGetWorkflowsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlGetWorkflowsQuery, GqlGetWorkflowsQueryVariables>) {
          return Apollo.useLazyQuery<GqlGetWorkflowsQuery, GqlGetWorkflowsQueryVariables>(GetWorkflowsDocument, baseOptions);
        }
export type GetWorkflowsQueryHookResult = ReturnType<typeof useGetWorkflowsQuery>;
export type GetWorkflowsLazyQueryHookResult = ReturnType<typeof useGetWorkflowsLazyQuery>;
export type GetWorkflowsQueryResult = Apollo.QueryResult<GqlGetWorkflowsQuery, GqlGetWorkflowsQueryVariables>;
export const GetApprovalsRequestsDocument = gql`
    query getApprovalsRequests($page: Int, $per_page: Int, $search: String, $approvalFilters: RequestInputInput) {
  requests(
    active: true
    page: $page
    per_page: $per_page
    search: $search
    filters: $approvalFilters
  ) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        requestId
        status
        authorizers
        details
        currentLevel
        reason
        lastDecision {
          reason
        }
        workflow {
          rules {
            key
            value {
              authorizers {
                name
                authorizerId
                approved
              }
            }
          }
        }
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useGetApprovalsRequestsQuery__
 *
 * To run a query within a React component, call `useGetApprovalsRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApprovalsRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApprovalsRequestsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      per_page: // value for 'per_page'
 *      search: // value for 'search'
 *      approvalFilters: // value for 'approvalFilters'
 *   },
 * });
 */
export function useGetApprovalsRequestsQuery(baseOptions?: Apollo.QueryHookOptions<GqlGetApprovalsRequestsQuery, GqlGetApprovalsRequestsQueryVariables>) {
        return Apollo.useQuery<GqlGetApprovalsRequestsQuery, GqlGetApprovalsRequestsQueryVariables>(GetApprovalsRequestsDocument, baseOptions);
      }
export function useGetApprovalsRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlGetApprovalsRequestsQuery, GqlGetApprovalsRequestsQueryVariables>) {
          return Apollo.useLazyQuery<GqlGetApprovalsRequestsQuery, GqlGetApprovalsRequestsQueryVariables>(GetApprovalsRequestsDocument, baseOptions);
        }
export type GetApprovalsRequestsQueryHookResult = ReturnType<typeof useGetApprovalsRequestsQuery>;
export type GetApprovalsRequestsLazyQueryHookResult = ReturnType<typeof useGetApprovalsRequestsLazyQuery>;
export type GetApprovalsRequestsQueryResult = Apollo.QueryResult<GqlGetApprovalsRequestsQuery, GqlGetApprovalsRequestsQueryVariables>;
export const GetApprovalsRequestByIdDocument = gql`
    query getApprovalsRequestById($request_id: String!) {
  request_by_id(request_id: $request_id) {
    requestId
    status
    authorizers
    details
    currentLevel
    initiator
    reason
    workflow {
      rules {
        key
        value {
          authorizers {
            name
            authorizerId
            approved
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetApprovalsRequestByIdQuery__
 *
 * To run a query within a React component, call `useGetApprovalsRequestByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApprovalsRequestByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApprovalsRequestByIdQuery({
 *   variables: {
 *      request_id: // value for 'request_id'
 *   },
 * });
 */
export function useGetApprovalsRequestByIdQuery(baseOptions: Apollo.QueryHookOptions<GqlGetApprovalsRequestByIdQuery, GqlGetApprovalsRequestByIdQueryVariables>) {
        return Apollo.useQuery<GqlGetApprovalsRequestByIdQuery, GqlGetApprovalsRequestByIdQueryVariables>(GetApprovalsRequestByIdDocument, baseOptions);
      }
export function useGetApprovalsRequestByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlGetApprovalsRequestByIdQuery, GqlGetApprovalsRequestByIdQueryVariables>) {
          return Apollo.useLazyQuery<GqlGetApprovalsRequestByIdQuery, GqlGetApprovalsRequestByIdQueryVariables>(GetApprovalsRequestByIdDocument, baseOptions);
        }
export type GetApprovalsRequestByIdQueryHookResult = ReturnType<typeof useGetApprovalsRequestByIdQuery>;
export type GetApprovalsRequestByIdLazyQueryHookResult = ReturnType<typeof useGetApprovalsRequestByIdLazyQuery>;
export type GetApprovalsRequestByIdQueryResult = Apollo.QueryResult<GqlGetApprovalsRequestByIdQuery, GqlGetApprovalsRequestByIdQueryVariables>;
export const DeliveriesDocument = gql`
    query deliveries($search: String, $per_page: Int, $page: Int, $deliveryFilters: GenericScalar) {
  deliveries_v2(
    search: $search
    page: $page
    per_page: $per_page
    filters: $deliveryFilters
  ) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        delivery_id
        ...DeliveryDetails
        payments {
          payment_id
          amount
          currency
        }
      }
    }
  }
}
    ${PaginationFragmentDoc}
${DeliveryDetailsFragmentDoc}`;

/**
 * __useDeliveriesQuery__
 *
 * To run a query within a React component, call `useDeliveriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeliveriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeliveriesQuery({
 *   variables: {
 *      search: // value for 'search'
 *      per_page: // value for 'per_page'
 *      page: // value for 'page'
 *      deliveryFilters: // value for 'deliveryFilters'
 *   },
 * });
 */
export function useDeliveriesQuery(baseOptions?: Apollo.QueryHookOptions<GqlDeliveriesQuery, GqlDeliveriesQueryVariables>) {
        return Apollo.useQuery<GqlDeliveriesQuery, GqlDeliveriesQueryVariables>(DeliveriesDocument, baseOptions);
      }
export function useDeliveriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlDeliveriesQuery, GqlDeliveriesQueryVariables>) {
          return Apollo.useLazyQuery<GqlDeliveriesQuery, GqlDeliveriesQueryVariables>(DeliveriesDocument, baseOptions);
        }
export type DeliveriesQueryHookResult = ReturnType<typeof useDeliveriesQuery>;
export type DeliveriesLazyQueryHookResult = ReturnType<typeof useDeliveriesLazyQuery>;
export type DeliveriesQueryResult = Apollo.QueryResult<GqlDeliveriesQuery, GqlDeliveriesQueryVariables>;
export const Delivery_V2_By_IdDocument = gql`
    query delivery_v2_by_id($deliveryId: String!) {
  delivery_v2_by_id(delivery_id: $deliveryId) {
    delivery_id
    ...DeliveryDetails
    cancellation_reason
    delivery_items {
      product_item_id
      product_item {
        product {
          product_name
        }
        product_item_name
      }
      unit_price
      amount
      currency
      quantity
      unit_of_measure_id
      uom {
        name
        abbreviation
      }
    }
    payments {
      payment_id
      reference_number
      payment_number
      payment_mode
      date_created
      amount
      currency
    }
    amount
    currency
    active
  }
}
    ${DeliveryDetailsFragmentDoc}`;

/**
 * __useDelivery_V2_By_IdQuery__
 *
 * To run a query within a React component, call `useDelivery_V2_By_IdQuery` and pass it any options that fit your needs.
 * When your component renders, `useDelivery_V2_By_IdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDelivery_V2_By_IdQuery({
 *   variables: {
 *      deliveryId: // value for 'deliveryId'
 *   },
 * });
 */
export function useDelivery_V2_By_IdQuery(baseOptions: Apollo.QueryHookOptions<GqlDelivery_V2_By_IdQuery, GqlDelivery_V2_By_IdQueryVariables>) {
        return Apollo.useQuery<GqlDelivery_V2_By_IdQuery, GqlDelivery_V2_By_IdQueryVariables>(Delivery_V2_By_IdDocument, baseOptions);
      }
export function useDelivery_V2_By_IdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlDelivery_V2_By_IdQuery, GqlDelivery_V2_By_IdQueryVariables>) {
          return Apollo.useLazyQuery<GqlDelivery_V2_By_IdQuery, GqlDelivery_V2_By_IdQueryVariables>(Delivery_V2_By_IdDocument, baseOptions);
        }
export type Delivery_V2_By_IdQueryHookResult = ReturnType<typeof useDelivery_V2_By_IdQuery>;
export type Delivery_V2_By_IdLazyQueryHookResult = ReturnType<typeof useDelivery_V2_By_IdLazyQuery>;
export type Delivery_V2_By_IdQueryResult = Apollo.QueryResult<GqlDelivery_V2_By_IdQuery, GqlDelivery_V2_By_IdQueryVariables>;
export const DeliveryReturnsDocument = gql`
    query deliveryReturns($per_page: Int, $page: Int, $search: String, $filter: GenericScalar) {
  delivery_returns(
    page: $page
    per_page: $per_page
    search: $search
    filters: $filter
  ) {
    page_info {
      per_page
      total_count
      total_pages
      has_next
      has_previous
    }
    edges {
      node {
        return_id
        return_number
        return_date
        currency
        active
        shop_id
        claim_processed
        delivery {
          delivery_date
          delivery_id
          delivery_number
          delivery_status {
            delivery_status
          }
        }
        refund_status
        return_items {
          approved_by
          approved_by_user {
            first_name
            last_name
          }
          approved_amount
          approved_quantity
          comment
          approved
          approved_amount
          approved_quantity
          image
          active
          return_item_id
          quantity
          amount
          currency
          signed_image_url
          unit_of_measure_id
          uom {
            name
          }
          product_item_id
          product_item {
            product_item_name
            product_id
            product {
              product_name
            }
          }
          reason {
            name
            description
          }
          rejection_reason_id
          rejection_reason {
            name
          }
        }
        delivery {
          delivery_date
          delivery_id
          delivery_number
          delivery_status {
            delivery_status
          }
        }
        shop {
          shop_name
          shop_number
          signed_image_url
          route_id
          region {
            region_name
          }
          route {
            route_name
            depot {
              depot_name
            }
          }
          customer_id
          customer {
            customer_name
            phone_number
          }
        }
      }
    }
  }
}
    `;

/**
 * __useDeliveryReturnsQuery__
 *
 * To run a query within a React component, call `useDeliveryReturnsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeliveryReturnsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeliveryReturnsQuery({
 *   variables: {
 *      per_page: // value for 'per_page'
 *      page: // value for 'page'
 *      search: // value for 'search'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDeliveryReturnsQuery(baseOptions?: Apollo.QueryHookOptions<GqlDeliveryReturnsQuery, GqlDeliveryReturnsQueryVariables>) {
        return Apollo.useQuery<GqlDeliveryReturnsQuery, GqlDeliveryReturnsQueryVariables>(DeliveryReturnsDocument, baseOptions);
      }
export function useDeliveryReturnsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlDeliveryReturnsQuery, GqlDeliveryReturnsQueryVariables>) {
          return Apollo.useLazyQuery<GqlDeliveryReturnsQuery, GqlDeliveryReturnsQueryVariables>(DeliveryReturnsDocument, baseOptions);
        }
export type DeliveryReturnsQueryHookResult = ReturnType<typeof useDeliveryReturnsQuery>;
export type DeliveryReturnsLazyQueryHookResult = ReturnType<typeof useDeliveryReturnsLazyQuery>;
export type DeliveryReturnsQueryResult = Apollo.QueryResult<GqlDeliveryReturnsQuery, GqlDeliveryReturnsQueryVariables>;
export const ReturnItemRejectionReasonsDocument = gql`
    query returnItemRejectionReasons {
  return_item_rejection_reasons {
    rejection_reason_id
    name
    description
  }
}
    `;

/**
 * __useReturnItemRejectionReasonsQuery__
 *
 * To run a query within a React component, call `useReturnItemRejectionReasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReturnItemRejectionReasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReturnItemRejectionReasonsQuery({
 *   variables: {
 *   },
 * });
 */
export function useReturnItemRejectionReasonsQuery(baseOptions?: Apollo.QueryHookOptions<GqlReturnItemRejectionReasonsQuery, GqlReturnItemRejectionReasonsQueryVariables>) {
        return Apollo.useQuery<GqlReturnItemRejectionReasonsQuery, GqlReturnItemRejectionReasonsQueryVariables>(ReturnItemRejectionReasonsDocument, baseOptions);
      }
export function useReturnItemRejectionReasonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlReturnItemRejectionReasonsQuery, GqlReturnItemRejectionReasonsQueryVariables>) {
          return Apollo.useLazyQuery<GqlReturnItemRejectionReasonsQuery, GqlReturnItemRejectionReasonsQueryVariables>(ReturnItemRejectionReasonsDocument, baseOptions);
        }
export type ReturnItemRejectionReasonsQueryHookResult = ReturnType<typeof useReturnItemRejectionReasonsQuery>;
export type ReturnItemRejectionReasonsLazyQueryHookResult = ReturnType<typeof useReturnItemRejectionReasonsLazyQuery>;
export type ReturnItemRejectionReasonsQueryResult = Apollo.QueryResult<GqlReturnItemRejectionReasonsQuery, GqlReturnItemRejectionReasonsQueryVariables>;
export const DeliveryRefundStatusesDocument = gql`
    query deliveryRefundStatuses {
  refund_statuses
}
    `;

/**
 * __useDeliveryRefundStatusesQuery__
 *
 * To run a query within a React component, call `useDeliveryRefundStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeliveryRefundStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeliveryRefundStatusesQuery({
 *   variables: {
 *   },
 * });
 */
export function useDeliveryRefundStatusesQuery(baseOptions?: Apollo.QueryHookOptions<GqlDeliveryRefundStatusesQuery, GqlDeliveryRefundStatusesQueryVariables>) {
        return Apollo.useQuery<GqlDeliveryRefundStatusesQuery, GqlDeliveryRefundStatusesQueryVariables>(DeliveryRefundStatusesDocument, baseOptions);
      }
export function useDeliveryRefundStatusesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlDeliveryRefundStatusesQuery, GqlDeliveryRefundStatusesQueryVariables>) {
          return Apollo.useLazyQuery<GqlDeliveryRefundStatusesQuery, GqlDeliveryRefundStatusesQueryVariables>(DeliveryRefundStatusesDocument, baseOptions);
        }
export type DeliveryRefundStatusesQueryHookResult = ReturnType<typeof useDeliveryRefundStatusesQuery>;
export type DeliveryRefundStatusesLazyQueryHookResult = ReturnType<typeof useDeliveryRefundStatusesLazyQuery>;
export type DeliveryRefundStatusesQueryResult = Apollo.QueryResult<GqlDeliveryRefundStatusesQuery, GqlDeliveryRefundStatusesQueryVariables>;
export const DepotIntakeDocument = gql`
    query depotIntake($page: Int, $per_page: Int, $filter: GenericScalar, $search: String, $filterArray: GenericScalar) {
  depot_intake(
    per_page: $per_page
    page: $page
    filter: $filter
    search: $search
    filter_array: $filterArray
  ) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        depot_intake_id
        depot_intake_number
        cancellation_reason
        active
        sale_date
        route_id
        depot_intake_items {
          depot_intake_item_id
          packaging_unit_id
          packaging_unit_quantity
          quantity
          unit_of_measure_id
          uom {
            abbreviation
          }
          product_item_id
          product_item {
            product_item_name
            product_id
            product {
              product_name
              packaging_units {
                packaging_unit_id
                name
              }
            }
          }
        }
        created_by
        user {
          first_name
          last_name
          phone_number
          user_no
          user_id
        }
        route {
          route_name
          route_id
          depot_id
          depot {
            depot_name
          }
        }
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useDepotIntakeQuery__
 *
 * To run a query within a React component, call `useDepotIntakeQuery` and pass it any options that fit your needs.
 * When your component renders, `useDepotIntakeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepotIntakeQuery({
 *   variables: {
 *      page: // value for 'page'
 *      per_page: // value for 'per_page'
 *      filter: // value for 'filter'
 *      search: // value for 'search'
 *      filterArray: // value for 'filterArray'
 *   },
 * });
 */
export function useDepotIntakeQuery(baseOptions?: Apollo.QueryHookOptions<GqlDepotIntakeQuery, GqlDepotIntakeQueryVariables>) {
        return Apollo.useQuery<GqlDepotIntakeQuery, GqlDepotIntakeQueryVariables>(DepotIntakeDocument, baseOptions);
      }
export function useDepotIntakeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlDepotIntakeQuery, GqlDepotIntakeQueryVariables>) {
          return Apollo.useLazyQuery<GqlDepotIntakeQuery, GqlDepotIntakeQueryVariables>(DepotIntakeDocument, baseOptions);
        }
export type DepotIntakeQueryHookResult = ReturnType<typeof useDepotIntakeQuery>;
export type DepotIntakeLazyQueryHookResult = ReturnType<typeof useDepotIntakeLazyQuery>;
export type DepotIntakeQueryResult = Apollo.QueryResult<GqlDepotIntakeQuery, GqlDepotIntakeQueryVariables>;
export const DepotReturnsDocument = gql`
    query depotReturns($page: Int, $per_page: Int, $filter: GenericScalar, $filterArray: GenericScalar) {
  returns(
    per_page: $per_page
    page: $page
    filter: $filter
    filter_array: $filterArray
  ) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        return_id
        return_number
        sale_date
        created_by
        active
        depot_id
        depot {
          depot_name
        }
        user {
          first_name
          last_name
          phone_number
          user_number
          user_id
        }
        packaging_return_items {
          packaging_return_item_id
          active
          cancellation_reason
          packaging_unit_id
          quantity
          packaging_unit {
            name
          }
        }
        product_return_items {
          active
          cancellation_reason
          return_type
          product_item_id
          product_return_item_id
          quantity
          unit_of_measure_id
          uom {
            abbreviation
          }
          product_item {
            product_item_name
            product_id
            product {
              product_name
            }
          }
        }
        route_id
        route {
          route_name
        }
        route_plan {
          route {
            route_id
            route_name
          }
          vehicle {
            registration_number
          }
        }
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useDepotReturnsQuery__
 *
 * To run a query within a React component, call `useDepotReturnsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDepotReturnsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepotReturnsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      per_page: // value for 'per_page'
 *      filter: // value for 'filter'
 *      filterArray: // value for 'filterArray'
 *   },
 * });
 */
export function useDepotReturnsQuery(baseOptions?: Apollo.QueryHookOptions<GqlDepotReturnsQuery, GqlDepotReturnsQueryVariables>) {
        return Apollo.useQuery<GqlDepotReturnsQuery, GqlDepotReturnsQueryVariables>(DepotReturnsDocument, baseOptions);
      }
export function useDepotReturnsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlDepotReturnsQuery, GqlDepotReturnsQueryVariables>) {
          return Apollo.useLazyQuery<GqlDepotReturnsQuery, GqlDepotReturnsQueryVariables>(DepotReturnsDocument, baseOptions);
        }
export type DepotReturnsQueryHookResult = ReturnType<typeof useDepotReturnsQuery>;
export type DepotReturnsLazyQueryHookResult = ReturnType<typeof useDepotReturnsLazyQuery>;
export type DepotReturnsQueryResult = Apollo.QueryResult<GqlDepotReturnsQuery, GqlDepotReturnsQueryVariables>;
export const StockTransferDocument = gql`
    query stockTransfer($page: Int, $per_page: Int, $filter: GenericScalar, $filterArray: GenericScalar) {
  stock_transfer(
    per_page: $per_page
    page: $page
    filter: $filter
    filter_array: $filterArray
  ) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        stock_transfer_id
        stock_transfer_number
        active
        sale_date
        route_id_from
        route_from {
          route_name
          depot_id
          depot {
            depot_name
          }
        }
        product_item_id
        product_item {
          product_item_name
          product_id
          product {
            product_name
          }
        }
        stock_transfer_items {
          route_id_to
          route_to {
            route_name
          }
          stock_transfer_item_id
          unit_of_measure_id
          uom {
            abbreviation
          }
          quantity
          packaging_unit_id
          packaging_unit_quantity
          packaging_unit {
            name
          }
        }
        created_by
        user {
          first_name
          last_name
        }
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useStockTransferQuery__
 *
 * To run a query within a React component, call `useStockTransferQuery` and pass it any options that fit your needs.
 * When your component renders, `useStockTransferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStockTransferQuery({
 *   variables: {
 *      page: // value for 'page'
 *      per_page: // value for 'per_page'
 *      filter: // value for 'filter'
 *      filterArray: // value for 'filterArray'
 *   },
 * });
 */
export function useStockTransferQuery(baseOptions?: Apollo.QueryHookOptions<GqlStockTransferQuery, GqlStockTransferQueryVariables>) {
        return Apollo.useQuery<GqlStockTransferQuery, GqlStockTransferQueryVariables>(StockTransferDocument, baseOptions);
      }
export function useStockTransferLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlStockTransferQuery, GqlStockTransferQueryVariables>) {
          return Apollo.useLazyQuery<GqlStockTransferQuery, GqlStockTransferQueryVariables>(StockTransferDocument, baseOptions);
        }
export type StockTransferQueryHookResult = ReturnType<typeof useStockTransferQuery>;
export type StockTransferLazyQueryHookResult = ReturnType<typeof useStockTransferLazyQuery>;
export type StockTransferQueryResult = Apollo.QueryResult<GqlStockTransferQuery, GqlStockTransferQueryVariables>;
export const DispatchBalanceDocument = gql`
    query DispatchBalance($route_id_from: String, $saleDate: String, $product_item_id: String) {
  dispatch_balance(
    route_id: $route_id_from
    date: $saleDate
    product_item_id: $product_item_id
  ) {
    date
    route_id
    stock_balance
    packaging_balance
    product_item_id
  }
}
    `;

/**
 * __useDispatchBalanceQuery__
 *
 * To run a query within a React component, call `useDispatchBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useDispatchBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDispatchBalanceQuery({
 *   variables: {
 *      route_id_from: // value for 'route_id_from'
 *      saleDate: // value for 'saleDate'
 *      product_item_id: // value for 'product_item_id'
 *   },
 * });
 */
export function useDispatchBalanceQuery(baseOptions?: Apollo.QueryHookOptions<GqlDispatchBalanceQuery, GqlDispatchBalanceQueryVariables>) {
        return Apollo.useQuery<GqlDispatchBalanceQuery, GqlDispatchBalanceQueryVariables>(DispatchBalanceDocument, baseOptions);
      }
export function useDispatchBalanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlDispatchBalanceQuery, GqlDispatchBalanceQueryVariables>) {
          return Apollo.useLazyQuery<GqlDispatchBalanceQuery, GqlDispatchBalanceQueryVariables>(DispatchBalanceDocument, baseOptions);
        }
export type DispatchBalanceQueryHookResult = ReturnType<typeof useDispatchBalanceQuery>;
export type DispatchBalanceLazyQueryHookResult = ReturnType<typeof useDispatchBalanceLazyQuery>;
export type DispatchBalanceQueryResult = Apollo.QueryResult<GqlDispatchBalanceQuery, GqlDispatchBalanceQueryVariables>;
export const OrdersDocument = gql`
    query orders($search: String, $per_page: Int, $page: Int, $filter: GenericScalar) {
  orders_v2(search: $search, page: $page, per_page: $per_page, filters: $filter) {
    edges {
      node {
        priority
        order_number
        order_id
        shop_id
        active
        cancellation_reason
        currency
        shop {
          shop_name
          shop_number
        }
        trader_entity_user_id
        served_by {
          user_id
          first_name
          last_name
          user_no
          phone_number
        }
        route_id
        route {
          route_name
        }
        amount
        delivery_date
        order_date
        order_items {
          product_item_id
          amount
          currency
          unit_price
          quantity
          product_item {
            product {
              product_name
            }
            product_item_name
          }
          unit_of_measure_id
          uom {
            uom_id
            name
          }
        }
      }
    }
    page_info {
      ...Pagination
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useOrdersQuery__
 *
 * To run a query within a React component, call `useOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersQuery({
 *   variables: {
 *      search: // value for 'search'
 *      per_page: // value for 'per_page'
 *      page: // value for 'page'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOrdersQuery(baseOptions?: Apollo.QueryHookOptions<GqlOrdersQuery, GqlOrdersQueryVariables>) {
        return Apollo.useQuery<GqlOrdersQuery, GqlOrdersQueryVariables>(OrdersDocument, baseOptions);
      }
export function useOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlOrdersQuery, GqlOrdersQueryVariables>) {
          return Apollo.useLazyQuery<GqlOrdersQuery, GqlOrdersQueryVariables>(OrdersDocument, baseOptions);
        }
export type OrdersQueryHookResult = ReturnType<typeof useOrdersQuery>;
export type OrdersLazyQueryHookResult = ReturnType<typeof useOrdersLazyQuery>;
export type OrdersQueryResult = Apollo.QueryResult<GqlOrdersQuery, GqlOrdersQueryVariables>;
export const ShopsV2Document = gql`
    query shopsV2($per_page: Int, $page: Int, $search: String, $filter: GenericScalar) {
  shops_v2(page: $page, per_page: $per_page, search: $search, filters: $filter) {
    edges {
      node {
        ...ShopDetails
        customer_id
        ...CustomerDetails
        approved
        signed_image_url
        cluster_id
        cluster {
          cluster_code
        }
        customer_types {
          customer_type_id
          name
        }
      }
    }
    page_info {
      ...Pagination
    }
  }
}
    ${ShopDetailsFragmentDoc}
${CustomerDetailsFragmentDoc}
${PaginationFragmentDoc}`;

/**
 * __useShopsV2Query__
 *
 * To run a query within a React component, call `useShopsV2Query` and pass it any options that fit your needs.
 * When your component renders, `useShopsV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopsV2Query({
 *   variables: {
 *      per_page: // value for 'per_page'
 *      page: // value for 'page'
 *      search: // value for 'search'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useShopsV2Query(baseOptions?: Apollo.QueryHookOptions<GqlShopsV2Query, GqlShopsV2QueryVariables>) {
        return Apollo.useQuery<GqlShopsV2Query, GqlShopsV2QueryVariables>(ShopsV2Document, baseOptions);
      }
export function useShopsV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlShopsV2Query, GqlShopsV2QueryVariables>) {
          return Apollo.useLazyQuery<GqlShopsV2Query, GqlShopsV2QueryVariables>(ShopsV2Document, baseOptions);
        }
export type ShopsV2QueryHookResult = ReturnType<typeof useShopsV2Query>;
export type ShopsV2LazyQueryHookResult = ReturnType<typeof useShopsV2LazyQuery>;
export type ShopsV2QueryResult = Apollo.QueryResult<GqlShopsV2Query, GqlShopsV2QueryVariables>;
export const ShopByIdDocument = gql`
    query shopById($shop_id: String!) {
  shop_v2_by_id(shop_id: $shop_id) {
    ...ShopDetails
    region_id
    region {
      region_name
    }
    kra_pin
    customer_types {
      customer_type_id
      name
    }
    customer_id
    ...CustomerDetails
  }
}
    ${ShopDetailsFragmentDoc}
${CustomerDetailsFragmentDoc}`;

/**
 * __useShopByIdQuery__
 *
 * To run a query within a React component, call `useShopByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopByIdQuery({
 *   variables: {
 *      shop_id: // value for 'shop_id'
 *   },
 * });
 */
export function useShopByIdQuery(baseOptions: Apollo.QueryHookOptions<GqlShopByIdQuery, GqlShopByIdQueryVariables>) {
        return Apollo.useQuery<GqlShopByIdQuery, GqlShopByIdQueryVariables>(ShopByIdDocument, baseOptions);
      }
export function useShopByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlShopByIdQuery, GqlShopByIdQueryVariables>) {
          return Apollo.useLazyQuery<GqlShopByIdQuery, GqlShopByIdQueryVariables>(ShopByIdDocument, baseOptions);
        }
export type ShopByIdQueryHookResult = ReturnType<typeof useShopByIdQuery>;
export type ShopByIdLazyQueryHookResult = ReturnType<typeof useShopByIdLazyQuery>;
export type ShopByIdQueryResult = Apollo.QueryResult<GqlShopByIdQuery, GqlShopByIdQueryVariables>;
export const ShopTypesV2Document = gql`
    query shopTypesV2($grouped: Boolean) {
  shop_types_v2(grouped: $grouped) {
    shop_type_id
    name
    active
    children {
      shop_type_id
      name
      active
    }
  }
}
    `;

/**
 * __useShopTypesV2Query__
 *
 * To run a query within a React component, call `useShopTypesV2Query` and pass it any options that fit your needs.
 * When your component renders, `useShopTypesV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopTypesV2Query({
 *   variables: {
 *      grouped: // value for 'grouped'
 *   },
 * });
 */
export function useShopTypesV2Query(baseOptions?: Apollo.QueryHookOptions<GqlShopTypesV2Query, GqlShopTypesV2QueryVariables>) {
        return Apollo.useQuery<GqlShopTypesV2Query, GqlShopTypesV2QueryVariables>(ShopTypesV2Document, baseOptions);
      }
export function useShopTypesV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlShopTypesV2Query, GqlShopTypesV2QueryVariables>) {
          return Apollo.useLazyQuery<GqlShopTypesV2Query, GqlShopTypesV2QueryVariables>(ShopTypesV2Document, baseOptions);
        }
export type ShopTypesV2QueryHookResult = ReturnType<typeof useShopTypesV2Query>;
export type ShopTypesV2LazyQueryHookResult = ReturnType<typeof useShopTypesV2LazyQuery>;
export type ShopTypesV2QueryResult = Apollo.QueryResult<GqlShopTypesV2Query, GqlShopTypesV2QueryVariables>;
export const CustomerTypesV2Document = gql`
    query customerTypesV2 {
  customer_types_v2 {
    customer_type_id
    name
  }
}
    `;

/**
 * __useCustomerTypesV2Query__
 *
 * To run a query within a React component, call `useCustomerTypesV2Query` and pass it any options that fit your needs.
 * When your component renders, `useCustomerTypesV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerTypesV2Query({
 *   variables: {
 *   },
 * });
 */
export function useCustomerTypesV2Query(baseOptions?: Apollo.QueryHookOptions<GqlCustomerTypesV2Query, GqlCustomerTypesV2QueryVariables>) {
        return Apollo.useQuery<GqlCustomerTypesV2Query, GqlCustomerTypesV2QueryVariables>(CustomerTypesV2Document, baseOptions);
      }
export function useCustomerTypesV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlCustomerTypesV2Query, GqlCustomerTypesV2QueryVariables>) {
          return Apollo.useLazyQuery<GqlCustomerTypesV2Query, GqlCustomerTypesV2QueryVariables>(CustomerTypesV2Document, baseOptions);
        }
export type CustomerTypesV2QueryHookResult = ReturnType<typeof useCustomerTypesV2Query>;
export type CustomerTypesV2LazyQueryHookResult = ReturnType<typeof useCustomerTypesV2LazyQuery>;
export type CustomerTypesV2QueryResult = Apollo.QueryResult<GqlCustomerTypesV2Query, GqlCustomerTypesV2QueryVariables>;
export const ClustersDocument = gql`
    query Clusters($search: String) {
  clusters(search: $search) {
    edges {
      node {
        date_created
        cluster_code
        cluster_id
        territory_name
        active
        updated_by_user {
          first_name
          last_name
          user_id
        }
      }
    }
    page_info {
      per_page
      total_count
      total_pages
      current_page
      has_next
      has_previous
    }
  }
}
    `;

/**
 * __useClustersQuery__
 *
 * To run a query within a React component, call `useClustersQuery` and pass it any options that fit your needs.
 * When your component renders, `useClustersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClustersQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useClustersQuery(baseOptions?: Apollo.QueryHookOptions<GqlClustersQuery, GqlClustersQueryVariables>) {
        return Apollo.useQuery<GqlClustersQuery, GqlClustersQueryVariables>(ClustersDocument, baseOptions);
      }
export function useClustersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlClustersQuery, GqlClustersQueryVariables>) {
          return Apollo.useLazyQuery<GqlClustersQuery, GqlClustersQueryVariables>(ClustersDocument, baseOptions);
        }
export type ClustersQueryHookResult = ReturnType<typeof useClustersQuery>;
export type ClustersLazyQueryHookResult = ReturnType<typeof useClustersLazyQuery>;
export type ClustersQueryResult = Apollo.QueryResult<GqlClustersQuery, GqlClustersQueryVariables>;
export const TerritoriesDocument = gql`
    query Territories {
  territories {
    territory_name
  }
}
    `;

/**
 * __useTerritoriesQuery__
 *
 * To run a query within a React component, call `useTerritoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTerritoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTerritoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTerritoriesQuery(baseOptions?: Apollo.QueryHookOptions<GqlTerritoriesQuery, GqlTerritoriesQueryVariables>) {
        return Apollo.useQuery<GqlTerritoriesQuery, GqlTerritoriesQueryVariables>(TerritoriesDocument, baseOptions);
      }
export function useTerritoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlTerritoriesQuery, GqlTerritoriesQueryVariables>) {
          return Apollo.useLazyQuery<GqlTerritoriesQuery, GqlTerritoriesQueryVariables>(TerritoriesDocument, baseOptions);
        }
export type TerritoriesQueryHookResult = ReturnType<typeof useTerritoriesQuery>;
export type TerritoriesLazyQueryHookResult = ReturnType<typeof useTerritoriesLazyQuery>;
export type TerritoriesQueryResult = Apollo.QueryResult<GqlTerritoriesQuery, GqlTerritoriesQueryVariables>;
export const DeliveryItemWarrantiesDocument = gql`
    query deliveryItemWarranties($per_page: Int, $page: Int, $filters: GenericScalar) {
  delivery_item_warranties(page: $page, per_page: $per_page, filters: $filters) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        warranty_id
        serial_number
        delivery_item_id
        image_url
        delivery_item {
          product_item_id
          product_item {
            product_item_name
            product_id
            product {
              product_name
            }
          }
          unit_price
          amount
          quantity
          delivery {
            delivery_number
            delivery_date
            route_id
            route {
              route_name
            }
            shop {
              shop_number
              customer {
                phone_number
              }
            }
          }
        }
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useDeliveryItemWarrantiesQuery__
 *
 * To run a query within a React component, call `useDeliveryItemWarrantiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeliveryItemWarrantiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeliveryItemWarrantiesQuery({
 *   variables: {
 *      per_page: // value for 'per_page'
 *      page: // value for 'page'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useDeliveryItemWarrantiesQuery(baseOptions?: Apollo.QueryHookOptions<GqlDeliveryItemWarrantiesQuery, GqlDeliveryItemWarrantiesQueryVariables>) {
        return Apollo.useQuery<GqlDeliveryItemWarrantiesQuery, GqlDeliveryItemWarrantiesQueryVariables>(DeliveryItemWarrantiesDocument, baseOptions);
      }
export function useDeliveryItemWarrantiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlDeliveryItemWarrantiesQuery, GqlDeliveryItemWarrantiesQueryVariables>) {
          return Apollo.useLazyQuery<GqlDeliveryItemWarrantiesQuery, GqlDeliveryItemWarrantiesQueryVariables>(DeliveryItemWarrantiesDocument, baseOptions);
        }
export type DeliveryItemWarrantiesQueryHookResult = ReturnType<typeof useDeliveryItemWarrantiesQuery>;
export type DeliveryItemWarrantiesLazyQueryHookResult = ReturnType<typeof useDeliveryItemWarrantiesLazyQuery>;
export type DeliveryItemWarrantiesQueryResult = Apollo.QueryResult<GqlDeliveryItemWarrantiesQuery, GqlDeliveryItemWarrantiesQueryVariables>;
export const RoutingSummaryDocument = gql`
    query RoutingSummary($search: String, $routingFilters: GenericScalar, $per_page: Int, $page: Int, $sale_date: String, $depot_id: String) {
  depot_routing_summary(
    search: $search
    per_page: $per_page
    page: $page
    sale_date: $sale_date
    depot_id: $depot_id
    filters: $routingFilters
  ) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        depot_id
        depot_name
        sale_date
        route_plans
        total_orders
        routed_orders
        total_ordered_revenue
        total_routed_revenue
        total_ordered_weight
        total_routed_weight
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useRoutingSummaryQuery__
 *
 * To run a query within a React component, call `useRoutingSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoutingSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoutingSummaryQuery({
 *   variables: {
 *      search: // value for 'search'
 *      routingFilters: // value for 'routingFilters'
 *      per_page: // value for 'per_page'
 *      page: // value for 'page'
 *      sale_date: // value for 'sale_date'
 *      depot_id: // value for 'depot_id'
 *   },
 * });
 */
export function useRoutingSummaryQuery(baseOptions?: Apollo.QueryHookOptions<GqlRoutingSummaryQuery, GqlRoutingSummaryQueryVariables>) {
        return Apollo.useQuery<GqlRoutingSummaryQuery, GqlRoutingSummaryQueryVariables>(RoutingSummaryDocument, baseOptions);
      }
export function useRoutingSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlRoutingSummaryQuery, GqlRoutingSummaryQueryVariables>) {
          return Apollo.useLazyQuery<GqlRoutingSummaryQuery, GqlRoutingSummaryQueryVariables>(RoutingSummaryDocument, baseOptions);
        }
export type RoutingSummaryQueryHookResult = ReturnType<typeof useRoutingSummaryQuery>;
export type RoutingSummaryLazyQueryHookResult = ReturnType<typeof useRoutingSummaryLazyQuery>;
export type RoutingSummaryQueryResult = Apollo.QueryResult<GqlRoutingSummaryQuery, GqlRoutingSummaryQueryVariables>;
export const GetRequestsDocument = gql`
    query getRequests {
  requests {
    edges {
      node {
        reason
        status
        details
        externalReferenceId
      }
    }
  }
}
    `;

/**
 * __useGetRequestsQuery__
 *
 * To run a query within a React component, call `useGetRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRequestsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRequestsQuery(baseOptions?: Apollo.QueryHookOptions<GqlGetRequestsQuery, GqlGetRequestsQueryVariables>) {
        return Apollo.useQuery<GqlGetRequestsQuery, GqlGetRequestsQueryVariables>(GetRequestsDocument, baseOptions);
      }
export function useGetRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlGetRequestsQuery, GqlGetRequestsQueryVariables>) {
          return Apollo.useLazyQuery<GqlGetRequestsQuery, GqlGetRequestsQueryVariables>(GetRequestsDocument, baseOptions);
        }
export type GetRequestsQueryHookResult = ReturnType<typeof useGetRequestsQuery>;
export type GetRequestsLazyQueryHookResult = ReturnType<typeof useGetRequestsLazyQuery>;
export type GetRequestsQueryResult = Apollo.QueryResult<GqlGetRequestsQuery, GqlGetRequestsQueryVariables>;
export const FarmersDocument = gql`
    query farmers($per_page: Int, $page: Int, $filters: GenericScalar) {
  farmers(per_page: $per_page, page: $page, filter: $filters) {
    page_info {
      has_next
      has_previous
      per_page
      total_pages
      total_count
      current_page
    }
    edges {
      node {
        farmer_type
        farmer_id
        farmer_number
        first_name
        last_name
        supplier_name
        active
      }
    }
  }
}
    `;

/**
 * __useFarmersQuery__
 *
 * To run a query within a React component, call `useFarmersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFarmersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFarmersQuery({
 *   variables: {
 *      per_page: // value for 'per_page'
 *      page: // value for 'page'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useFarmersQuery(baseOptions?: Apollo.QueryHookOptions<GqlFarmersQuery, GqlFarmersQueryVariables>) {
        return Apollo.useQuery<GqlFarmersQuery, GqlFarmersQueryVariables>(FarmersDocument, baseOptions);
      }
export function useFarmersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlFarmersQuery, GqlFarmersQueryVariables>) {
          return Apollo.useLazyQuery<GqlFarmersQuery, GqlFarmersQueryVariables>(FarmersDocument, baseOptions);
        }
export type FarmersQueryHookResult = ReturnType<typeof useFarmersQuery>;
export type FarmersLazyQueryHookResult = ReturnType<typeof useFarmersLazyQuery>;
export type FarmersQueryResult = Apollo.QueryResult<GqlFarmersQuery, GqlFarmersQueryVariables>;
export const GetEtimsCodesDocument = gql`
    query getEtimsCodes {
  eTIMsCodes {
    resultCd
    resultMsg
    resultDt
    data {
      clsList {
        cdCls
        cdClsNm
      }
    }
  }
}
    `;

/**
 * __useGetEtimsCodesQuery__
 *
 * To run a query within a React component, call `useGetEtimsCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEtimsCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEtimsCodesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEtimsCodesQuery(baseOptions?: Apollo.QueryHookOptions<GqlGetEtimsCodesQuery, GqlGetEtimsCodesQueryVariables>) {
        return Apollo.useQuery<GqlGetEtimsCodesQuery, GqlGetEtimsCodesQueryVariables>(GetEtimsCodesDocument, baseOptions);
      }
export function useGetEtimsCodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlGetEtimsCodesQuery, GqlGetEtimsCodesQueryVariables>) {
          return Apollo.useLazyQuery<GqlGetEtimsCodesQuery, GqlGetEtimsCodesQueryVariables>(GetEtimsCodesDocument, baseOptions);
        }
export type GetEtimsCodesQueryHookResult = ReturnType<typeof useGetEtimsCodesQuery>;
export type GetEtimsCodesLazyQueryHookResult = ReturnType<typeof useGetEtimsCodesLazyQuery>;
export type GetEtimsCodesQueryResult = Apollo.QueryResult<GqlGetEtimsCodesQuery, GqlGetEtimsCodesQueryVariables>;
export const GetEtimsCodesByCodeNameDocument = gql`
    query getEtimsCodesByCodeName($cdClsNm: String) {
  eTIMsCodes(filters: {cdClsNm: $cdClsNm}) {
    resultCd
    resultMsg
    resultDt
    data {
      clsList {
        cdCls
        cdClsNm
        dtlList {
          cd
          cdNm
          cdDesc
          srtOrd
        }
      }
    }
  }
}
    `;

/**
 * __useGetEtimsCodesByCodeNameQuery__
 *
 * To run a query within a React component, call `useGetEtimsCodesByCodeNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEtimsCodesByCodeNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEtimsCodesByCodeNameQuery({
 *   variables: {
 *      cdClsNm: // value for 'cdClsNm'
 *   },
 * });
 */
export function useGetEtimsCodesByCodeNameQuery(baseOptions?: Apollo.QueryHookOptions<GqlGetEtimsCodesByCodeNameQuery, GqlGetEtimsCodesByCodeNameQueryVariables>) {
        return Apollo.useQuery<GqlGetEtimsCodesByCodeNameQuery, GqlGetEtimsCodesByCodeNameQueryVariables>(GetEtimsCodesByCodeNameDocument, baseOptions);
      }
export function useGetEtimsCodesByCodeNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlGetEtimsCodesByCodeNameQuery, GqlGetEtimsCodesByCodeNameQueryVariables>) {
          return Apollo.useLazyQuery<GqlGetEtimsCodesByCodeNameQuery, GqlGetEtimsCodesByCodeNameQueryVariables>(GetEtimsCodesByCodeNameDocument, baseOptions);
        }
export type GetEtimsCodesByCodeNameQueryHookResult = ReturnType<typeof useGetEtimsCodesByCodeNameQuery>;
export type GetEtimsCodesByCodeNameLazyQueryHookResult = ReturnType<typeof useGetEtimsCodesByCodeNameLazyQuery>;
export type GetEtimsCodesByCodeNameQueryResult = Apollo.QueryResult<GqlGetEtimsCodesByCodeNameQuery, GqlGetEtimsCodesByCodeNameQueryVariables>;
export const GetEtiMsNoticeListDocument = gql`
    query getETIMsNoticeList {
  eTIMsNoticeList {
    resultCd
    resultMsg
    resultDt
    data {
      noticeList {
        noticeNo
        title
        cont
        dtlUrl
        regrNm
        regDt
      }
    }
  }
}
    `;

/**
 * __useGetEtiMsNoticeListQuery__
 *
 * To run a query within a React component, call `useGetEtiMsNoticeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEtiMsNoticeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEtiMsNoticeListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEtiMsNoticeListQuery(baseOptions?: Apollo.QueryHookOptions<GqlGetEtiMsNoticeListQuery, GqlGetEtiMsNoticeListQueryVariables>) {
        return Apollo.useQuery<GqlGetEtiMsNoticeListQuery, GqlGetEtiMsNoticeListQueryVariables>(GetEtiMsNoticeListDocument, baseOptions);
      }
export function useGetEtiMsNoticeListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlGetEtiMsNoticeListQuery, GqlGetEtiMsNoticeListQueryVariables>) {
          return Apollo.useLazyQuery<GqlGetEtiMsNoticeListQuery, GqlGetEtiMsNoticeListQueryVariables>(GetEtiMsNoticeListDocument, baseOptions);
        }
export type GetEtiMsNoticeListQueryHookResult = ReturnType<typeof useGetEtiMsNoticeListQuery>;
export type GetEtiMsNoticeListLazyQueryHookResult = ReturnType<typeof useGetEtiMsNoticeListLazyQuery>;
export type GetEtiMsNoticeListQueryResult = Apollo.QueryResult<GqlGetEtiMsNoticeListQuery, GqlGetEtiMsNoticeListQueryVariables>;
export const GetEtimsProductsItemsDocument = gql`
    query getEtimsProductsItems {
  eTIMsItems {
    resultCd
    resultMsg
    resultDt
    data {
      itemList {
        itemCd
        itemClsCd
        itemNm
        qtyUnitCd
        taxTyCd
        dftPrc
        isrcAplcbYn
      }
    }
  }
}
    `;

/**
 * __useGetEtimsProductsItemsQuery__
 *
 * To run a query within a React component, call `useGetEtimsProductsItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEtimsProductsItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEtimsProductsItemsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEtimsProductsItemsQuery(baseOptions?: Apollo.QueryHookOptions<GqlGetEtimsProductsItemsQuery, GqlGetEtimsProductsItemsQueryVariables>) {
        return Apollo.useQuery<GqlGetEtimsProductsItemsQuery, GqlGetEtimsProductsItemsQueryVariables>(GetEtimsProductsItemsDocument, baseOptions);
      }
export function useGetEtimsProductsItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlGetEtimsProductsItemsQuery, GqlGetEtimsProductsItemsQueryVariables>) {
          return Apollo.useLazyQuery<GqlGetEtimsProductsItemsQuery, GqlGetEtimsProductsItemsQueryVariables>(GetEtimsProductsItemsDocument, baseOptions);
        }
export type GetEtimsProductsItemsQueryHookResult = ReturnType<typeof useGetEtimsProductsItemsQuery>;
export type GetEtimsProductsItemsLazyQueryHookResult = ReturnType<typeof useGetEtimsProductsItemsLazyQuery>;
export type GetEtimsProductsItemsQueryResult = Apollo.QueryResult<GqlGetEtimsProductsItemsQuery, GqlGetEtimsProductsItemsQueryVariables>;
export const GetProductItemsForCompositionDocument = gql`
    query GetProductItemsForComposition {
  product_items_v2 {
    product_item_id
    product_item_name
    product_item_code
    etims_code
    etims_class_code
    etims_quantity_code
    etims_packaging_unit
    tax_type_code
    product {
      product_name
    }
  }
}
    `;

/**
 * __useGetProductItemsForCompositionQuery__
 *
 * To run a query within a React component, call `useGetProductItemsForCompositionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductItemsForCompositionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductItemsForCompositionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProductItemsForCompositionQuery(baseOptions?: Apollo.QueryHookOptions<GqlGetProductItemsForCompositionQuery, GqlGetProductItemsForCompositionQueryVariables>) {
        return Apollo.useQuery<GqlGetProductItemsForCompositionQuery, GqlGetProductItemsForCompositionQueryVariables>(GetProductItemsForCompositionDocument, baseOptions);
      }
export function useGetProductItemsForCompositionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlGetProductItemsForCompositionQuery, GqlGetProductItemsForCompositionQueryVariables>) {
          return Apollo.useLazyQuery<GqlGetProductItemsForCompositionQuery, GqlGetProductItemsForCompositionQueryVariables>(GetProductItemsForCompositionDocument, baseOptions);
        }
export type GetProductItemsForCompositionQueryHookResult = ReturnType<typeof useGetProductItemsForCompositionQuery>;
export type GetProductItemsForCompositionLazyQueryHookResult = ReturnType<typeof useGetProductItemsForCompositionLazyQuery>;
export type GetProductItemsForCompositionQueryResult = Apollo.QueryResult<GqlGetProductItemsForCompositionQuery, GqlGetProductItemsForCompositionQueryVariables>;
export const GetEtimsPurchasesDocument = gql`
    query getEtimsPurchases($sales_dt: String, $spplr_invc_no: Int) {
  eTIMsPurchases(filters: {sales_dt: $sales_dt, spplr_invc_no: $spplr_invc_no}) {
    SalesList {
      id
      spplr_nm
      spplr_invc_no
      spplr_tin
      spplr_bhf_id
      sales_dt
      item_list {
        id
        purchase_id
        item_cd
        item_nm
        qty
        prc
        sply_amt
        dc_rt
        dc_amt
        taxbl_amt
        tax_amt
        tot_amt
      }
    }
  }
}
    `;

/**
 * __useGetEtimsPurchasesQuery__
 *
 * To run a query within a React component, call `useGetEtimsPurchasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEtimsPurchasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEtimsPurchasesQuery({
 *   variables: {
 *      sales_dt: // value for 'sales_dt'
 *      spplr_invc_no: // value for 'spplr_invc_no'
 *   },
 * });
 */
export function useGetEtimsPurchasesQuery(baseOptions?: Apollo.QueryHookOptions<GqlGetEtimsPurchasesQuery, GqlGetEtimsPurchasesQueryVariables>) {
        return Apollo.useQuery<GqlGetEtimsPurchasesQuery, GqlGetEtimsPurchasesQueryVariables>(GetEtimsPurchasesDocument, baseOptions);
      }
export function useGetEtimsPurchasesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlGetEtimsPurchasesQuery, GqlGetEtimsPurchasesQueryVariables>) {
          return Apollo.useLazyQuery<GqlGetEtimsPurchasesQuery, GqlGetEtimsPurchasesQueryVariables>(GetEtimsPurchasesDocument, baseOptions);
        }
export type GetEtimsPurchasesQueryHookResult = ReturnType<typeof useGetEtimsPurchasesQuery>;
export type GetEtimsPurchasesLazyQueryHookResult = ReturnType<typeof useGetEtimsPurchasesLazyQuery>;
export type GetEtimsPurchasesQueryResult = Apollo.QueryResult<GqlGetEtimsPurchasesQuery, GqlGetEtimsPurchasesQueryVariables>;
export const GetEtimsStockMovementListDocument = gql`
    query getEtimsStockMovementList {
  eTIMsStockMovementList {
    resultCd
    resultMsg
    resultDt
    data {
      stockList {
        custBhfId
        custTin
      }
    }
  }
}
    `;

/**
 * __useGetEtimsStockMovementListQuery__
 *
 * To run a query within a React component, call `useGetEtimsStockMovementListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEtimsStockMovementListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEtimsStockMovementListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEtimsStockMovementListQuery(baseOptions?: Apollo.QueryHookOptions<GqlGetEtimsStockMovementListQuery, GqlGetEtimsStockMovementListQueryVariables>) {
        return Apollo.useQuery<GqlGetEtimsStockMovementListQuery, GqlGetEtimsStockMovementListQueryVariables>(GetEtimsStockMovementListDocument, baseOptions);
      }
export function useGetEtimsStockMovementListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlGetEtimsStockMovementListQuery, GqlGetEtimsStockMovementListQueryVariables>) {
          return Apollo.useLazyQuery<GqlGetEtimsStockMovementListQuery, GqlGetEtimsStockMovementListQueryVariables>(GetEtimsStockMovementListDocument, baseOptions);
        }
export type GetEtimsStockMovementListQueryHookResult = ReturnType<typeof useGetEtimsStockMovementListQuery>;
export type GetEtimsStockMovementListLazyQueryHookResult = ReturnType<typeof useGetEtimsStockMovementListLazyQuery>;
export type GetEtimsStockMovementListQueryResult = Apollo.QueryResult<GqlGetEtimsStockMovementListQuery, GqlGetEtimsStockMovementListQueryVariables>;
export const GetEtimsStockMovementListByPinDocument = gql`
    query getEtimsStockMovementListByPin($custTin: String) {
  eTIMsStockMovementList(filters: {custTin: $custTin}) {
    resultCd
    resultMsg
    resultDt
    data {
      stockList {
        custTin
        ocrnDt
        totTaxAmt
        totItemCnt
        totAmt
        itemList {
          itemClsCd
          itemNm
          qty
          prc
          itemCd
          taxAmt
          totAmt
        }
      }
    }
  }
}
    `;

/**
 * __useGetEtimsStockMovementListByPinQuery__
 *
 * To run a query within a React component, call `useGetEtimsStockMovementListByPinQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEtimsStockMovementListByPinQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEtimsStockMovementListByPinQuery({
 *   variables: {
 *      custTin: // value for 'custTin'
 *   },
 * });
 */
export function useGetEtimsStockMovementListByPinQuery(baseOptions?: Apollo.QueryHookOptions<GqlGetEtimsStockMovementListByPinQuery, GqlGetEtimsStockMovementListByPinQueryVariables>) {
        return Apollo.useQuery<GqlGetEtimsStockMovementListByPinQuery, GqlGetEtimsStockMovementListByPinQueryVariables>(GetEtimsStockMovementListByPinDocument, baseOptions);
      }
export function useGetEtimsStockMovementListByPinLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlGetEtimsStockMovementListByPinQuery, GqlGetEtimsStockMovementListByPinQueryVariables>) {
          return Apollo.useLazyQuery<GqlGetEtimsStockMovementListByPinQuery, GqlGetEtimsStockMovementListByPinQueryVariables>(GetEtimsStockMovementListByPinDocument, baseOptions);
        }
export type GetEtimsStockMovementListByPinQueryHookResult = ReturnType<typeof useGetEtimsStockMovementListByPinQuery>;
export type GetEtimsStockMovementListByPinLazyQueryHookResult = ReturnType<typeof useGetEtimsStockMovementListByPinLazyQuery>;
export type GetEtimsStockMovementListByPinQueryResult = Apollo.QueryResult<GqlGetEtimsStockMovementListByPinQuery, GqlGetEtimsStockMovementListByPinQueryVariables>;
export const VehicleTypesDocument = gql`
    query vehicleTypes {
  vehicle_types {
    edges {
      node {
        vehicle_type_id
        vehicle_type_name
      }
    }
  }
}
    `;

/**
 * __useVehicleTypesQuery__
 *
 * To run a query within a React component, call `useVehicleTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehicleTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useVehicleTypesQuery(baseOptions?: Apollo.QueryHookOptions<GqlVehicleTypesQuery, GqlVehicleTypesQueryVariables>) {
        return Apollo.useQuery<GqlVehicleTypesQuery, GqlVehicleTypesQueryVariables>(VehicleTypesDocument, baseOptions);
      }
export function useVehicleTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlVehicleTypesQuery, GqlVehicleTypesQueryVariables>) {
          return Apollo.useLazyQuery<GqlVehicleTypesQuery, GqlVehicleTypesQueryVariables>(VehicleTypesDocument, baseOptions);
        }
export type VehicleTypesQueryHookResult = ReturnType<typeof useVehicleTypesQuery>;
export type VehicleTypesLazyQueryHookResult = ReturnType<typeof useVehicleTypesLazyQuery>;
export type VehicleTypesQueryResult = Apollo.QueryResult<GqlVehicleTypesQuery, GqlVehicleTypesQueryVariables>;
export const Inventory_LocationsDocument = gql`
    query inventory_locations($filter: GenericScalar) {
  inventory_locations(filter: $filter) {
    edges {
      node {
        inventory_location_id
        inventory_location_name
        inventory_location_type
      }
    }
  }
}
    `;

/**
 * __useInventory_LocationsQuery__
 *
 * To run a query within a React component, call `useInventory_LocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInventory_LocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInventory_LocationsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useInventory_LocationsQuery(baseOptions?: Apollo.QueryHookOptions<GqlInventory_LocationsQuery, GqlInventory_LocationsQueryVariables>) {
        return Apollo.useQuery<GqlInventory_LocationsQuery, GqlInventory_LocationsQueryVariables>(Inventory_LocationsDocument, baseOptions);
      }
export function useInventory_LocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlInventory_LocationsQuery, GqlInventory_LocationsQueryVariables>) {
          return Apollo.useLazyQuery<GqlInventory_LocationsQuery, GqlInventory_LocationsQueryVariables>(Inventory_LocationsDocument, baseOptions);
        }
export type Inventory_LocationsQueryHookResult = ReturnType<typeof useInventory_LocationsQuery>;
export type Inventory_LocationsLazyQueryHookResult = ReturnType<typeof useInventory_LocationsLazyQuery>;
export type Inventory_LocationsQueryResult = Apollo.QueryResult<GqlInventory_LocationsQuery, GqlInventory_LocationsQueryVariables>;
export const Vehicle_SuppliersDocument = gql`
    query vehicle_suppliers($page: Int, $filters: GenericScalar, $per_page: Int, $search: String) {
  vehicle_suppliers(
    page: $page
    filters: $filters
    per_page: $per_page
    search: $search
  ) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        name
        active
        date_created
        email
        vehicle_supplier_id
        phone_number
        alt_phone_number
        country_id
        country {
          iso_alpha2_name
        }
        supplier_number
        vehicles {
          vehicle_id
        }
        payment_accounts {
          vehicle_supplier_id
          vehicle_supplier_payment_account_id
          account_type
          account_name
          party
          account_number
          is_primary
        }
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useVehicle_SuppliersQuery__
 *
 * To run a query within a React component, call `useVehicle_SuppliersQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehicle_SuppliersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicle_SuppliersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      filters: // value for 'filters'
 *      per_page: // value for 'per_page'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useVehicle_SuppliersQuery(baseOptions?: Apollo.QueryHookOptions<GqlVehicle_SuppliersQuery, GqlVehicle_SuppliersQueryVariables>) {
        return Apollo.useQuery<GqlVehicle_SuppliersQuery, GqlVehicle_SuppliersQueryVariables>(Vehicle_SuppliersDocument, baseOptions);
      }
export function useVehicle_SuppliersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlVehicle_SuppliersQuery, GqlVehicle_SuppliersQueryVariables>) {
          return Apollo.useLazyQuery<GqlVehicle_SuppliersQuery, GqlVehicle_SuppliersQueryVariables>(Vehicle_SuppliersDocument, baseOptions);
        }
export type Vehicle_SuppliersQueryHookResult = ReturnType<typeof useVehicle_SuppliersQuery>;
export type Vehicle_SuppliersLazyQueryHookResult = ReturnType<typeof useVehicle_SuppliersLazyQuery>;
export type Vehicle_SuppliersQueryResult = Apollo.QueryResult<GqlVehicle_SuppliersQuery, GqlVehicle_SuppliersQueryVariables>;
export const Cost_ModelDocument = gql`
    query cost_model {
  vehicle_cost_models
}
    `;

/**
 * __useCost_ModelQuery__
 *
 * To run a query within a React component, call `useCost_ModelQuery` and pass it any options that fit your needs.
 * When your component renders, `useCost_ModelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCost_ModelQuery({
 *   variables: {
 *   },
 * });
 */
export function useCost_ModelQuery(baseOptions?: Apollo.QueryHookOptions<GqlCost_ModelQuery, GqlCost_ModelQueryVariables>) {
        return Apollo.useQuery<GqlCost_ModelQuery, GqlCost_ModelQueryVariables>(Cost_ModelDocument, baseOptions);
      }
export function useCost_ModelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlCost_ModelQuery, GqlCost_ModelQueryVariables>) {
          return Apollo.useLazyQuery<GqlCost_ModelQuery, GqlCost_ModelQueryVariables>(Cost_ModelDocument, baseOptions);
        }
export type Cost_ModelQueryHookResult = ReturnType<typeof useCost_ModelQuery>;
export type Cost_ModelLazyQueryHookResult = ReturnType<typeof useCost_ModelLazyQuery>;
export type Cost_ModelQueryResult = Apollo.QueryResult<GqlCost_ModelQuery, GqlCost_ModelQueryVariables>;
export const Vehicle_Rejection_ReasonsDocument = gql`
    query vehicle_rejection_reasons {
  vehicle_rejection_reasons
}
    `;

/**
 * __useVehicle_Rejection_ReasonsQuery__
 *
 * To run a query within a React component, call `useVehicle_Rejection_ReasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehicle_Rejection_ReasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicle_Rejection_ReasonsQuery({
 *   variables: {
 *   },
 * });
 */
export function useVehicle_Rejection_ReasonsQuery(baseOptions?: Apollo.QueryHookOptions<GqlVehicle_Rejection_ReasonsQuery, GqlVehicle_Rejection_ReasonsQueryVariables>) {
        return Apollo.useQuery<GqlVehicle_Rejection_ReasonsQuery, GqlVehicle_Rejection_ReasonsQueryVariables>(Vehicle_Rejection_ReasonsDocument, baseOptions);
      }
export function useVehicle_Rejection_ReasonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlVehicle_Rejection_ReasonsQuery, GqlVehicle_Rejection_ReasonsQueryVariables>) {
          return Apollo.useLazyQuery<GqlVehicle_Rejection_ReasonsQuery, GqlVehicle_Rejection_ReasonsQueryVariables>(Vehicle_Rejection_ReasonsDocument, baseOptions);
        }
export type Vehicle_Rejection_ReasonsQueryHookResult = ReturnType<typeof useVehicle_Rejection_ReasonsQuery>;
export type Vehicle_Rejection_ReasonsLazyQueryHookResult = ReturnType<typeof useVehicle_Rejection_ReasonsLazyQuery>;
export type Vehicle_Rejection_ReasonsQueryResult = Apollo.QueryResult<GqlVehicle_Rejection_ReasonsQuery, GqlVehicle_Rejection_ReasonsQueryVariables>;
export const SupplierVehiclesDocument = gql`
    query supplierVehicles($search: String, $per_page: Int, $page: Int, $filters: GenericScalar) {
  vehicles(search: $search, per_page: $per_page, filters: $filters, page: $page) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        vehicle_id
        vehicle_supplier_id
        date_created
        registration_number
        vehicle_type {
          vehicle_type_name
          vehicle_type_id
        }
        logistics_type
        region_id
        cost_rate
        load_capacity
        insurance_expiry_date
        service_due_date
        capacity_volume
        active
        vehicle_cost_model
        region {
          region_name
        }
        rejection_reason
        rejected
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useSupplierVehiclesQuery__
 *
 * To run a query within a React component, call `useSupplierVehiclesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupplierVehiclesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupplierVehiclesQuery({
 *   variables: {
 *      search: // value for 'search'
 *      per_page: // value for 'per_page'
 *      page: // value for 'page'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useSupplierVehiclesQuery(baseOptions?: Apollo.QueryHookOptions<GqlSupplierVehiclesQuery, GqlSupplierVehiclesQueryVariables>) {
        return Apollo.useQuery<GqlSupplierVehiclesQuery, GqlSupplierVehiclesQueryVariables>(SupplierVehiclesDocument, baseOptions);
      }
export function useSupplierVehiclesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlSupplierVehiclesQuery, GqlSupplierVehiclesQueryVariables>) {
          return Apollo.useLazyQuery<GqlSupplierVehiclesQuery, GqlSupplierVehiclesQueryVariables>(SupplierVehiclesDocument, baseOptions);
        }
export type SupplierVehiclesQueryHookResult = ReturnType<typeof useSupplierVehiclesQuery>;
export type SupplierVehiclesLazyQueryHookResult = ReturnType<typeof useSupplierVehiclesLazyQuery>;
export type SupplierVehiclesQueryResult = Apollo.QueryResult<GqlSupplierVehiclesQuery, GqlSupplierVehiclesQueryVariables>;
export const TripsDocument = gql`
    query trips($page: Int, $filters: GenericScalar, $per_page: Int, $search: String) {
  trips(page: $page, filters: $filters, per_page: $per_page, search: $search) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        trip_id
        active
        logistics_type
        route_plan {
          route_plan_id
          depot_id
          depot {
            depot_name
          }
          route_id
          route {
            route_name
          }
        }
        starting_odometer
        closing_odometer
        vehicle {
          vehicle_supplier {
            name
          }
          registration_number
          logistics_type
          load_capacity
          usable_load_capacity
        }
        starting_date
        end_date
        region_id
        region {
          region_name
        }
        from_location
        to_location
        from_inventory_location {
          inventory_location_name
        }
        to_inventory_location {
          inventory_location_name
        }
        weight
        cost_rate
        vehicle_cost_model
        active
        distance
        cost
        total_cost
        variable_cost
        has_statement
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useTripsQuery__
 *
 * To run a query within a React component, call `useTripsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTripsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTripsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      filters: // value for 'filters'
 *      per_page: // value for 'per_page'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useTripsQuery(baseOptions?: Apollo.QueryHookOptions<GqlTripsQuery, GqlTripsQueryVariables>) {
        return Apollo.useQuery<GqlTripsQuery, GqlTripsQueryVariables>(TripsDocument, baseOptions);
      }
export function useTripsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlTripsQuery, GqlTripsQueryVariables>) {
          return Apollo.useLazyQuery<GqlTripsQuery, GqlTripsQueryVariables>(TripsDocument, baseOptions);
        }
export type TripsQueryHookResult = ReturnType<typeof useTripsQuery>;
export type TripsLazyQueryHookResult = ReturnType<typeof useTripsLazyQuery>;
export type TripsQueryResult = Apollo.QueryResult<GqlTripsQuery, GqlTripsQueryVariables>;
export const SupplierStatementsDocument = gql`
    query supplierStatements($search: String, $per_page: Int, $page: Int, $filters: GenericScalar) {
  vehicle_supplier_statements(
    search: $search
    per_page: $per_page
    filters: $filters
    page: $page
  ) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        active
        statement_id
        statement_number
        invoice_amount
        vehicle_supplier_id
        vehicle_supplier {
          name
          country_id
          country {
            iso_alpha2_code
            iso_alpha2_name
          }
        }
        amount
        approval_status
        approved_by
        date_from
        date_to
        date_created
        created_by
        created_by_user {
          first_name
          last_name
        }
        approved_by
        approved_by_user {
          first_name
          last_name
        }
        payment_status
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useSupplierStatementsQuery__
 *
 * To run a query within a React component, call `useSupplierStatementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupplierStatementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupplierStatementsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      per_page: // value for 'per_page'
 *      page: // value for 'page'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useSupplierStatementsQuery(baseOptions?: Apollo.QueryHookOptions<GqlSupplierStatementsQuery, GqlSupplierStatementsQueryVariables>) {
        return Apollo.useQuery<GqlSupplierStatementsQuery, GqlSupplierStatementsQueryVariables>(SupplierStatementsDocument, baseOptions);
      }
export function useSupplierStatementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlSupplierStatementsQuery, GqlSupplierStatementsQueryVariables>) {
          return Apollo.useLazyQuery<GqlSupplierStatementsQuery, GqlSupplierStatementsQueryVariables>(SupplierStatementsDocument, baseOptions);
        }
export type SupplierStatementsQueryHookResult = ReturnType<typeof useSupplierStatementsQuery>;
export type SupplierStatementsLazyQueryHookResult = ReturnType<typeof useSupplierStatementsLazyQuery>;
export type SupplierStatementsQueryResult = Apollo.QueryResult<GqlSupplierStatementsQuery, GqlSupplierStatementsQueryVariables>;
export const Ordering_WindowsDocument = gql`
    query ordering_windows($page: Int, $per_page: Int, $search: String, $filters: GenericScalar) {
  ordering_windows(
    per_page: $per_page
    page: $page
    search: $search
    filters: $filters
  ) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        ordering_window_id
        active
        region_id
        region {
          region_name
          region_id
        }
        channel
        depot_id
        depot {
          depot_name
        }
        ordering_window_items {
          active
          ordering_window_item_id
          day
          opening_time
          closing_time
        }
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useOrdering_WindowsQuery__
 *
 * To run a query within a React component, call `useOrdering_WindowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdering_WindowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdering_WindowsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      per_page: // value for 'per_page'
 *      search: // value for 'search'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useOrdering_WindowsQuery(baseOptions?: Apollo.QueryHookOptions<GqlOrdering_WindowsQuery, GqlOrdering_WindowsQueryVariables>) {
        return Apollo.useQuery<GqlOrdering_WindowsQuery, GqlOrdering_WindowsQueryVariables>(Ordering_WindowsDocument, baseOptions);
      }
export function useOrdering_WindowsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlOrdering_WindowsQuery, GqlOrdering_WindowsQueryVariables>) {
          return Apollo.useLazyQuery<GqlOrdering_WindowsQuery, GqlOrdering_WindowsQueryVariables>(Ordering_WindowsDocument, baseOptions);
        }
export type Ordering_WindowsQueryHookResult = ReturnType<typeof useOrdering_WindowsQuery>;
export type Ordering_WindowsLazyQueryHookResult = ReturnType<typeof useOrdering_WindowsLazyQuery>;
export type Ordering_WindowsQueryResult = Apollo.QueryResult<GqlOrdering_WindowsQuery, GqlOrdering_WindowsQueryVariables>;
export const Payment_VisibilitiesDocument = gql`
    query payment_visibilities {
  payment_visibilities {
    payment_method {
      description
      payment_method_id
      payment_method_name
      description
    }
    payment_visibility_id
    region_id
    channel
    visible
    active
  }
}
    `;

/**
 * __usePayment_VisibilitiesQuery__
 *
 * To run a query within a React component, call `usePayment_VisibilitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayment_VisibilitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayment_VisibilitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePayment_VisibilitiesQuery(baseOptions?: Apollo.QueryHookOptions<GqlPayment_VisibilitiesQuery, GqlPayment_VisibilitiesQueryVariables>) {
        return Apollo.useQuery<GqlPayment_VisibilitiesQuery, GqlPayment_VisibilitiesQueryVariables>(Payment_VisibilitiesDocument, baseOptions);
      }
export function usePayment_VisibilitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlPayment_VisibilitiesQuery, GqlPayment_VisibilitiesQueryVariables>) {
          return Apollo.useLazyQuery<GqlPayment_VisibilitiesQuery, GqlPayment_VisibilitiesQueryVariables>(Payment_VisibilitiesDocument, baseOptions);
        }
export type Payment_VisibilitiesQueryHookResult = ReturnType<typeof usePayment_VisibilitiesQuery>;
export type Payment_VisibilitiesLazyQueryHookResult = ReturnType<typeof usePayment_VisibilitiesLazyQuery>;
export type Payment_VisibilitiesQueryResult = Apollo.QueryResult<GqlPayment_VisibilitiesQuery, GqlPayment_VisibilitiesQueryVariables>;
export const Promo_TypesDocument = gql`
    query promo_types {
  promotion_types {
    promotion_type_id
    title
    active
    background_color
  }
}
    `;

/**
 * __usePromo_TypesQuery__
 *
 * To run a query within a React component, call `usePromo_TypesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePromo_TypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePromo_TypesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePromo_TypesQuery(baseOptions?: Apollo.QueryHookOptions<GqlPromo_TypesQuery, GqlPromo_TypesQueryVariables>) {
        return Apollo.useQuery<GqlPromo_TypesQuery, GqlPromo_TypesQueryVariables>(Promo_TypesDocument, baseOptions);
      }
export function usePromo_TypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlPromo_TypesQuery, GqlPromo_TypesQueryVariables>) {
          return Apollo.useLazyQuery<GqlPromo_TypesQuery, GqlPromo_TypesQueryVariables>(Promo_TypesDocument, baseOptions);
        }
export type Promo_TypesQueryHookResult = ReturnType<typeof usePromo_TypesQuery>;
export type Promo_TypesLazyQueryHookResult = ReturnType<typeof usePromo_TypesLazyQuery>;
export type Promo_TypesQueryResult = Apollo.QueryResult<GqlPromo_TypesQuery, GqlPromo_TypesQueryVariables>;
export const Add_PromoDocument = gql`
    mutation add_promo($title: String!, $background_color: String!) {
  add_promotion_type(data: {title: $title, background_color: $background_color}) {
    promotion_type {
      promotion_type_id
      title
      background_color
    }
  }
}
    `;
export type GqlAdd_PromoMutationFn = Apollo.MutationFunction<GqlAdd_PromoMutation, GqlAdd_PromoMutationVariables>;

/**
 * __useAdd_PromoMutation__
 *
 * To run a mutation, you first call `useAdd_PromoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdd_PromoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPromoMutation, { data, loading, error }] = useAdd_PromoMutation({
 *   variables: {
 *      title: // value for 'title'
 *      background_color: // value for 'background_color'
 *   },
 * });
 */
export function useAdd_PromoMutation(baseOptions?: Apollo.MutationHookOptions<GqlAdd_PromoMutation, GqlAdd_PromoMutationVariables>) {
        return Apollo.useMutation<GqlAdd_PromoMutation, GqlAdd_PromoMutationVariables>(Add_PromoDocument, baseOptions);
      }
export type Add_PromoMutationHookResult = ReturnType<typeof useAdd_PromoMutation>;
export type Add_PromoMutationResult = Apollo.MutationResult<GqlAdd_PromoMutation>;
export type Add_PromoMutationOptions = Apollo.BaseMutationOptions<GqlAdd_PromoMutation, GqlAdd_PromoMutationVariables>;
export const Update_Promotion_TypeDocument = gql`
    mutation update_promotion_type($promotion_type_id: String!, $data: PromotionTypeInput!) {
  update_promotion_type(data: $data, promotion_type_id: $promotion_type_id) {
    promotion_type {
      promotion_type_id
      title
      background_color
    }
  }
}
    `;
export type GqlUpdate_Promotion_TypeMutationFn = Apollo.MutationFunction<GqlUpdate_Promotion_TypeMutation, GqlUpdate_Promotion_TypeMutationVariables>;

/**
 * __useUpdate_Promotion_TypeMutation__
 *
 * To run a mutation, you first call `useUpdate_Promotion_TypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Promotion_TypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePromotionTypeMutation, { data, loading, error }] = useUpdate_Promotion_TypeMutation({
 *   variables: {
 *      promotion_type_id: // value for 'promotion_type_id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdate_Promotion_TypeMutation(baseOptions?: Apollo.MutationHookOptions<GqlUpdate_Promotion_TypeMutation, GqlUpdate_Promotion_TypeMutationVariables>) {
        return Apollo.useMutation<GqlUpdate_Promotion_TypeMutation, GqlUpdate_Promotion_TypeMutationVariables>(Update_Promotion_TypeDocument, baseOptions);
      }
export type Update_Promotion_TypeMutationHookResult = ReturnType<typeof useUpdate_Promotion_TypeMutation>;
export type Update_Promotion_TypeMutationResult = Apollo.MutationResult<GqlUpdate_Promotion_TypeMutation>;
export type Update_Promotion_TypeMutationOptions = Apollo.BaseMutationOptions<GqlUpdate_Promotion_TypeMutation, GqlUpdate_Promotion_TypeMutationVariables>;
export const Product_Items_With_TagsDocument = gql`
    query product_items_with_tags($filters: GenericScalar) {
  product_items_with_tags(filters: $filters) {
    promotion_tag_id
    promotion_type_id
    active
    region_ids
    start_date
    end_date
    promotion_type {
      title
      background_color
    }
    product_item_id
    product_item {
      product_item_name
      product {
        product_name
      }
    }
  }
}
    `;

/**
 * __useProduct_Items_With_TagsQuery__
 *
 * To run a query within a React component, call `useProduct_Items_With_TagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProduct_Items_With_TagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProduct_Items_With_TagsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useProduct_Items_With_TagsQuery(baseOptions?: Apollo.QueryHookOptions<GqlProduct_Items_With_TagsQuery, GqlProduct_Items_With_TagsQueryVariables>) {
        return Apollo.useQuery<GqlProduct_Items_With_TagsQuery, GqlProduct_Items_With_TagsQueryVariables>(Product_Items_With_TagsDocument, baseOptions);
      }
export function useProduct_Items_With_TagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlProduct_Items_With_TagsQuery, GqlProduct_Items_With_TagsQueryVariables>) {
          return Apollo.useLazyQuery<GqlProduct_Items_With_TagsQuery, GqlProduct_Items_With_TagsQueryVariables>(Product_Items_With_TagsDocument, baseOptions);
        }
export type Product_Items_With_TagsQueryHookResult = ReturnType<typeof useProduct_Items_With_TagsQuery>;
export type Product_Items_With_TagsLazyQueryHookResult = ReturnType<typeof useProduct_Items_With_TagsLazyQuery>;
export type Product_Items_With_TagsQueryResult = Apollo.QueryResult<GqlProduct_Items_With_TagsQuery, GqlProduct_Items_With_TagsQueryVariables>;
export const Promotion_TypesDocument = gql`
    query promotion_types {
  promotion_types {
    promotion_type_id
    title
  }
}
    `;

/**
 * __usePromotion_TypesQuery__
 *
 * To run a query within a React component, call `usePromotion_TypesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePromotion_TypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePromotion_TypesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePromotion_TypesQuery(baseOptions?: Apollo.QueryHookOptions<GqlPromotion_TypesQuery, GqlPromotion_TypesQueryVariables>) {
        return Apollo.useQuery<GqlPromotion_TypesQuery, GqlPromotion_TypesQueryVariables>(Promotion_TypesDocument, baseOptions);
      }
export function usePromotion_TypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlPromotion_TypesQuery, GqlPromotion_TypesQueryVariables>) {
          return Apollo.useLazyQuery<GqlPromotion_TypesQuery, GqlPromotion_TypesQueryVariables>(Promotion_TypesDocument, baseOptions);
        }
export type Promotion_TypesQueryHookResult = ReturnType<typeof usePromotion_TypesQuery>;
export type Promotion_TypesLazyQueryHookResult = ReturnType<typeof usePromotion_TypesLazyQuery>;
export type Promotion_TypesQueryResult = Apollo.QueryResult<GqlPromotion_TypesQuery, GqlPromotion_TypesQueryVariables>;
export const Add_Promotion_TagDocument = gql`
    mutation add_promotion_tag($product_item_id: String!, $promotion_type_id: String, $region_ids: [String], $start_date: String, $end_date: String, $meta: GenericScalar, $country_id: String!) {
  add_promotion_tag(
    data: {product_item_id: $product_item_id, promotion_type_id: $promotion_type_id, region_ids: $region_ids, start_date: $start_date, end_date: $end_date, country_id: $country_id, meta: $meta}
  ) {
    created
    promotion_tag {
      promotion_tag_id
      product_item_id
      region_ids
      start_date
      end_date
    }
  }
}
    `;
export type GqlAdd_Promotion_TagMutationFn = Apollo.MutationFunction<GqlAdd_Promotion_TagMutation, GqlAdd_Promotion_TagMutationVariables>;

/**
 * __useAdd_Promotion_TagMutation__
 *
 * To run a mutation, you first call `useAdd_Promotion_TagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdd_Promotion_TagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPromotionTagMutation, { data, loading, error }] = useAdd_Promotion_TagMutation({
 *   variables: {
 *      product_item_id: // value for 'product_item_id'
 *      promotion_type_id: // value for 'promotion_type_id'
 *      region_ids: // value for 'region_ids'
 *      start_date: // value for 'start_date'
 *      end_date: // value for 'end_date'
 *      meta: // value for 'meta'
 *      country_id: // value for 'country_id'
 *   },
 * });
 */
export function useAdd_Promotion_TagMutation(baseOptions?: Apollo.MutationHookOptions<GqlAdd_Promotion_TagMutation, GqlAdd_Promotion_TagMutationVariables>) {
        return Apollo.useMutation<GqlAdd_Promotion_TagMutation, GqlAdd_Promotion_TagMutationVariables>(Add_Promotion_TagDocument, baseOptions);
      }
export type Add_Promotion_TagMutationHookResult = ReturnType<typeof useAdd_Promotion_TagMutation>;
export type Add_Promotion_TagMutationResult = Apollo.MutationResult<GqlAdd_Promotion_TagMutation>;
export type Add_Promotion_TagMutationOptions = Apollo.BaseMutationOptions<GqlAdd_Promotion_TagMutation, GqlAdd_Promotion_TagMutationVariables>;
export const Update_Promotion_TagDocument = gql`
    mutation update_promotion_tag($promotion_tag_id: String!, $data: PromotionTagInput!) {
  update_promotion_tag(promotion_tag_id: $promotion_tag_id, data: $data) {
    promotion_tag {
      promotion_tag_id
      product_item_id
      region_ids
      start_date
      end_date
    }
  }
}
    `;
export type GqlUpdate_Promotion_TagMutationFn = Apollo.MutationFunction<GqlUpdate_Promotion_TagMutation, GqlUpdate_Promotion_TagMutationVariables>;

/**
 * __useUpdate_Promotion_TagMutation__
 *
 * To run a mutation, you first call `useUpdate_Promotion_TagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Promotion_TagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePromotionTagMutation, { data, loading, error }] = useUpdate_Promotion_TagMutation({
 *   variables: {
 *      promotion_tag_id: // value for 'promotion_tag_id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdate_Promotion_TagMutation(baseOptions?: Apollo.MutationHookOptions<GqlUpdate_Promotion_TagMutation, GqlUpdate_Promotion_TagMutationVariables>) {
        return Apollo.useMutation<GqlUpdate_Promotion_TagMutation, GqlUpdate_Promotion_TagMutationVariables>(Update_Promotion_TagDocument, baseOptions);
      }
export type Update_Promotion_TagMutationHookResult = ReturnType<typeof useUpdate_Promotion_TagMutation>;
export type Update_Promotion_TagMutationResult = Apollo.MutationResult<GqlUpdate_Promotion_TagMutation>;
export type Update_Promotion_TagMutationOptions = Apollo.BaseMutationOptions<GqlUpdate_Promotion_TagMutation, GqlUpdate_Promotion_TagMutationVariables>;
export const UserFeedbackDocument = gql`
    query userFeedback {
  agent_feedback {
    edges {
      node {
        client_stamp
      }
    }
  }
}
    `;

/**
 * __useUserFeedbackQuery__
 *
 * To run a query within a React component, call `useUserFeedbackQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserFeedbackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserFeedbackQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserFeedbackQuery(baseOptions?: Apollo.QueryHookOptions<GqlUserFeedbackQuery, GqlUserFeedbackQueryVariables>) {
        return Apollo.useQuery<GqlUserFeedbackQuery, GqlUserFeedbackQueryVariables>(UserFeedbackDocument, baseOptions);
      }
export function useUserFeedbackLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlUserFeedbackQuery, GqlUserFeedbackQueryVariables>) {
          return Apollo.useLazyQuery<GqlUserFeedbackQuery, GqlUserFeedbackQueryVariables>(UserFeedbackDocument, baseOptions);
        }
export type UserFeedbackQueryHookResult = ReturnType<typeof useUserFeedbackQuery>;
export type UserFeedbackLazyQueryHookResult = ReturnType<typeof useUserFeedbackLazyQuery>;
export type UserFeedbackQueryResult = Apollo.QueryResult<GqlUserFeedbackQuery, GqlUserFeedbackQueryVariables>;
export const Base_PricingDocument = gql`
    query base_pricing($page: Int, $per_page: Int, $search: String, $regionId: String!, $filters: GenericScalar) {
  paginated_product_items_v2(
    per_page: $per_page
    page: $page
    search: $search
    filters: $filters
    active: true
  ) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        product_item_id
        product_item_name
        product {
          product_name
        }
        units_of_measure {
          business_domain_id
          business_domain {
            domain_name
          }
          uom {
            uom_id
            name
          }
        }
        current_pricing_v2(region_id: $regionId) {
          unit_of_measure_id
          base_price
          currency
          rrp
          pricing_date
          region_id
        }
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useBase_PricingQuery__
 *
 * To run a query within a React component, call `useBase_PricingQuery` and pass it any options that fit your needs.
 * When your component renders, `useBase_PricingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBase_PricingQuery({
 *   variables: {
 *      page: // value for 'page'
 *      per_page: // value for 'per_page'
 *      search: // value for 'search'
 *      regionId: // value for 'regionId'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useBase_PricingQuery(baseOptions: Apollo.QueryHookOptions<GqlBase_PricingQuery, GqlBase_PricingQueryVariables>) {
        return Apollo.useQuery<GqlBase_PricingQuery, GqlBase_PricingQueryVariables>(Base_PricingDocument, baseOptions);
      }
export function useBase_PricingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlBase_PricingQuery, GqlBase_PricingQueryVariables>) {
          return Apollo.useLazyQuery<GqlBase_PricingQuery, GqlBase_PricingQueryVariables>(Base_PricingDocument, baseOptions);
        }
export type Base_PricingQueryHookResult = ReturnType<typeof useBase_PricingQuery>;
export type Base_PricingLazyQueryHookResult = ReturnType<typeof useBase_PricingLazyQuery>;
export type Base_PricingQueryResult = Apollo.QueryResult<GqlBase_PricingQuery, GqlBase_PricingQueryVariables>;
export const PricingDocument = gql`
    query pricing($page: Int, $per_page: Int, $filters: GenericScalar) {
  pricing_v2(page: $page, per_page: $per_page, filter: $filters) {
    edges {
      node {
        date_updated
        updated_by
        base_price
        currency
        rrp
        user {
          first_name
          last_name
        }
      }
    }
  }
}
    `;

/**
 * __usePricingQuery__
 *
 * To run a query within a React component, call `usePricingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePricingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePricingQuery({
 *   variables: {
 *      page: // value for 'page'
 *      per_page: // value for 'per_page'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function usePricingQuery(baseOptions?: Apollo.QueryHookOptions<GqlPricingQuery, GqlPricingQueryVariables>) {
        return Apollo.useQuery<GqlPricingQuery, GqlPricingQueryVariables>(PricingDocument, baseOptions);
      }
export function usePricingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlPricingQuery, GqlPricingQueryVariables>) {
          return Apollo.useLazyQuery<GqlPricingQuery, GqlPricingQueryVariables>(PricingDocument, baseOptions);
        }
export type PricingQueryHookResult = ReturnType<typeof usePricingQuery>;
export type PricingLazyQueryHookResult = ReturnType<typeof usePricingLazyQuery>;
export type PricingQueryResult = Apollo.QueryResult<GqlPricingQuery, GqlPricingQueryVariables>;
export const Product_Departments_V2Document = gql`
    query product_departments_v2 {
  product_departments_v2 {
    department_id
    department_name
  }
}
    `;

/**
 * __useProduct_Departments_V2Query__
 *
 * To run a query within a React component, call `useProduct_Departments_V2Query` and pass it any options that fit your needs.
 * When your component renders, `useProduct_Departments_V2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProduct_Departments_V2Query({
 *   variables: {
 *   },
 * });
 */
export function useProduct_Departments_V2Query(baseOptions?: Apollo.QueryHookOptions<GqlProduct_Departments_V2Query, GqlProduct_Departments_V2QueryVariables>) {
        return Apollo.useQuery<GqlProduct_Departments_V2Query, GqlProduct_Departments_V2QueryVariables>(Product_Departments_V2Document, baseOptions);
      }
export function useProduct_Departments_V2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlProduct_Departments_V2Query, GqlProduct_Departments_V2QueryVariables>) {
          return Apollo.useLazyQuery<GqlProduct_Departments_V2Query, GqlProduct_Departments_V2QueryVariables>(Product_Departments_V2Document, baseOptions);
        }
export type Product_Departments_V2QueryHookResult = ReturnType<typeof useProduct_Departments_V2Query>;
export type Product_Departments_V2LazyQueryHookResult = ReturnType<typeof useProduct_Departments_V2LazyQuery>;
export type Product_Departments_V2QueryResult = Apollo.QueryResult<GqlProduct_Departments_V2Query, GqlProduct_Departments_V2QueryVariables>;
export const Paginated_Products_V2Document = gql`
    query paginated_products_v2($search: String) {
  paginated_products_v2(search: $search) {
    edges {
      node {
        product_id
        product_name
        product_type
        product_items {
          product_item_id
          product_item_name
        }
      }
    }
  }
}
    `;

/**
 * __usePaginated_Products_V2Query__
 *
 * To run a query within a React component, call `usePaginated_Products_V2Query` and pass it any options that fit your needs.
 * When your component renders, `usePaginated_Products_V2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginated_Products_V2Query({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function usePaginated_Products_V2Query(baseOptions?: Apollo.QueryHookOptions<GqlPaginated_Products_V2Query, GqlPaginated_Products_V2QueryVariables>) {
        return Apollo.useQuery<GqlPaginated_Products_V2Query, GqlPaginated_Products_V2QueryVariables>(Paginated_Products_V2Document, baseOptions);
      }
export function usePaginated_Products_V2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlPaginated_Products_V2Query, GqlPaginated_Products_V2QueryVariables>) {
          return Apollo.useLazyQuery<GqlPaginated_Products_V2Query, GqlPaginated_Products_V2QueryVariables>(Paginated_Products_V2Document, baseOptions);
        }
export type Paginated_Products_V2QueryHookResult = ReturnType<typeof usePaginated_Products_V2Query>;
export type Paginated_Products_V2LazyQueryHookResult = ReturnType<typeof usePaginated_Products_V2LazyQuery>;
export type Paginated_Products_V2QueryResult = Apollo.QueryResult<GqlPaginated_Products_V2Query, GqlPaginated_Products_V2QueryVariables>;
export const Add_CategoryDocument = gql`
    mutation add_category($data: CategoryInput!) {
  add_category(data: $data) {
    category {
      category_id
      category_name
      department_id
    }
    created
  }
}
    `;
export type GqlAdd_CategoryMutationFn = Apollo.MutationFunction<GqlAdd_CategoryMutation, GqlAdd_CategoryMutationVariables>;

/**
 * __useAdd_CategoryMutation__
 *
 * To run a mutation, you first call `useAdd_CategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdd_CategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCategoryMutation, { data, loading, error }] = useAdd_CategoryMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdd_CategoryMutation(baseOptions?: Apollo.MutationHookOptions<GqlAdd_CategoryMutation, GqlAdd_CategoryMutationVariables>) {
        return Apollo.useMutation<GqlAdd_CategoryMutation, GqlAdd_CategoryMutationVariables>(Add_CategoryDocument, baseOptions);
      }
export type Add_CategoryMutationHookResult = ReturnType<typeof useAdd_CategoryMutation>;
export type Add_CategoryMutationResult = Apollo.MutationResult<GqlAdd_CategoryMutation>;
export type Add_CategoryMutationOptions = Apollo.BaseMutationOptions<GqlAdd_CategoryMutation, GqlAdd_CategoryMutationVariables>;
export const Product_CategoriesDocument = gql`
    query product_categories($search: String, $active: Boolean) {
  product_categories_v2(search: $search, active: $active) {
    category_id
    category_name
    image_url
    banner_image_url
    date_updated
    active
    total_products
    products {
      product_id
      product_name
    }
    sub_categories {
      category_id
      category_name
      image_url
      active
      total_products
    }
    department_id
    department {
      department_id
      department_name
    }
  }
}
    `;

/**
 * __useProduct_CategoriesQuery__
 *
 * To run a query within a React component, call `useProduct_CategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProduct_CategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProduct_CategoriesQuery({
 *   variables: {
 *      search: // value for 'search'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useProduct_CategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GqlProduct_CategoriesQuery, GqlProduct_CategoriesQueryVariables>) {
        return Apollo.useQuery<GqlProduct_CategoriesQuery, GqlProduct_CategoriesQueryVariables>(Product_CategoriesDocument, baseOptions);
      }
export function useProduct_CategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlProduct_CategoriesQuery, GqlProduct_CategoriesQueryVariables>) {
          return Apollo.useLazyQuery<GqlProduct_CategoriesQuery, GqlProduct_CategoriesQueryVariables>(Product_CategoriesDocument, baseOptions);
        }
export type Product_CategoriesQueryHookResult = ReturnType<typeof useProduct_CategoriesQuery>;
export type Product_CategoriesLazyQueryHookResult = ReturnType<typeof useProduct_CategoriesLazyQuery>;
export type Product_CategoriesQueryResult = Apollo.QueryResult<GqlProduct_CategoriesQuery, GqlProduct_CategoriesQueryVariables>;
export const Update_CategoryDocument = gql`
    mutation update_category($category_id: String!, $data: CategoryInput!) {
  update_category(category_id: $category_id, data: $data) {
    category {
      category_id
      active
    }
  }
}
    `;
export type GqlUpdate_CategoryMutationFn = Apollo.MutationFunction<GqlUpdate_CategoryMutation, GqlUpdate_CategoryMutationVariables>;

/**
 * __useUpdate_CategoryMutation__
 *
 * To run a mutation, you first call `useUpdate_CategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_CategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCategoryMutation, { data, loading, error }] = useUpdate_CategoryMutation({
 *   variables: {
 *      category_id: // value for 'category_id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdate_CategoryMutation(baseOptions?: Apollo.MutationHookOptions<GqlUpdate_CategoryMutation, GqlUpdate_CategoryMutationVariables>) {
        return Apollo.useMutation<GqlUpdate_CategoryMutation, GqlUpdate_CategoryMutationVariables>(Update_CategoryDocument, baseOptions);
      }
export type Update_CategoryMutationHookResult = ReturnType<typeof useUpdate_CategoryMutation>;
export type Update_CategoryMutationResult = Apollo.MutationResult<GqlUpdate_CategoryMutation>;
export type Update_CategoryMutationOptions = Apollo.BaseMutationOptions<GqlUpdate_CategoryMutation, GqlUpdate_CategoryMutationVariables>;
export const Upload_Category_ImageDocument = gql`
    mutation upload_category_image($image: Upload!, $category_id: String!) {
  upload_category_image(category_id: $category_id, image: $image) {
    uploaded
    category {
      image_url
    }
  }
}
    `;
export type GqlUpload_Category_ImageMutationFn = Apollo.MutationFunction<GqlUpload_Category_ImageMutation, GqlUpload_Category_ImageMutationVariables>;

/**
 * __useUpload_Category_ImageMutation__
 *
 * To run a mutation, you first call `useUpload_Category_ImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpload_Category_ImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadCategoryImageMutation, { data, loading, error }] = useUpload_Category_ImageMutation({
 *   variables: {
 *      image: // value for 'image'
 *      category_id: // value for 'category_id'
 *   },
 * });
 */
export function useUpload_Category_ImageMutation(baseOptions?: Apollo.MutationHookOptions<GqlUpload_Category_ImageMutation, GqlUpload_Category_ImageMutationVariables>) {
        return Apollo.useMutation<GqlUpload_Category_ImageMutation, GqlUpload_Category_ImageMutationVariables>(Upload_Category_ImageDocument, baseOptions);
      }
export type Upload_Category_ImageMutationHookResult = ReturnType<typeof useUpload_Category_ImageMutation>;
export type Upload_Category_ImageMutationResult = Apollo.MutationResult<GqlUpload_Category_ImageMutation>;
export type Upload_Category_ImageMutationOptions = Apollo.BaseMutationOptions<GqlUpload_Category_ImageMutation, GqlUpload_Category_ImageMutationVariables>;
export const Product_Category_V2_By_IdDocument = gql`
    query product_category_v2_by_id($category_id: String!) {
  product_category_v2_by_id(category_id: $category_id) {
    category_id
    category_name
    image_url
    active
    parent_id
    ordering_window
    department_id
    department {
      department_name
      department_id
    }
    products {
      product_id
      product_name
    }
    total_regions
    regions {
      active
      category_region_id
      region_id
      region {
        region_id
        region_name
      }
    }
  }
}
    `;

/**
 * __useProduct_Category_V2_By_IdQuery__
 *
 * To run a query within a React component, call `useProduct_Category_V2_By_IdQuery` and pass it any options that fit your needs.
 * When your component renders, `useProduct_Category_V2_By_IdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProduct_Category_V2_By_IdQuery({
 *   variables: {
 *      category_id: // value for 'category_id'
 *   },
 * });
 */
export function useProduct_Category_V2_By_IdQuery(baseOptions: Apollo.QueryHookOptions<GqlProduct_Category_V2_By_IdQuery, GqlProduct_Category_V2_By_IdQueryVariables>) {
        return Apollo.useQuery<GqlProduct_Category_V2_By_IdQuery, GqlProduct_Category_V2_By_IdQueryVariables>(Product_Category_V2_By_IdDocument, baseOptions);
      }
export function useProduct_Category_V2_By_IdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlProduct_Category_V2_By_IdQuery, GqlProduct_Category_V2_By_IdQueryVariables>) {
          return Apollo.useLazyQuery<GqlProduct_Category_V2_By_IdQuery, GqlProduct_Category_V2_By_IdQueryVariables>(Product_Category_V2_By_IdDocument, baseOptions);
        }
export type Product_Category_V2_By_IdQueryHookResult = ReturnType<typeof useProduct_Category_V2_By_IdQuery>;
export type Product_Category_V2_By_IdLazyQueryHookResult = ReturnType<typeof useProduct_Category_V2_By_IdLazyQuery>;
export type Product_Category_V2_By_IdQueryResult = Apollo.QueryResult<GqlProduct_Category_V2_By_IdQuery, GqlProduct_Category_V2_By_IdQueryVariables>;
export const Add_Category_RegionDocument = gql`
    mutation add_category_region($category_id: String!, $region_id: String!) {
  add_category_region(data: {category_id: $category_id, region_id: $region_id}) {
    category_region {
      category_region_id
      category_id
      region_id
    }
    created
  }
}
    `;
export type GqlAdd_Category_RegionMutationFn = Apollo.MutationFunction<GqlAdd_Category_RegionMutation, GqlAdd_Category_RegionMutationVariables>;

/**
 * __useAdd_Category_RegionMutation__
 *
 * To run a mutation, you first call `useAdd_Category_RegionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdd_Category_RegionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCategoryRegionMutation, { data, loading, error }] = useAdd_Category_RegionMutation({
 *   variables: {
 *      category_id: // value for 'category_id'
 *      region_id: // value for 'region_id'
 *   },
 * });
 */
export function useAdd_Category_RegionMutation(baseOptions?: Apollo.MutationHookOptions<GqlAdd_Category_RegionMutation, GqlAdd_Category_RegionMutationVariables>) {
        return Apollo.useMutation<GqlAdd_Category_RegionMutation, GqlAdd_Category_RegionMutationVariables>(Add_Category_RegionDocument, baseOptions);
      }
export type Add_Category_RegionMutationHookResult = ReturnType<typeof useAdd_Category_RegionMutation>;
export type Add_Category_RegionMutationResult = Apollo.MutationResult<GqlAdd_Category_RegionMutation>;
export type Add_Category_RegionMutationOptions = Apollo.BaseMutationOptions<GqlAdd_Category_RegionMutation, GqlAdd_Category_RegionMutationVariables>;
export const Activate_Category_RegionDocument = gql`
    mutation activate_category_region($data: CategoryRegionInput!, $category_region_id: String!) {
  update_category_region(category_region_id: $category_region_id, data: $data) {
    category_region {
      category_region_id
      category_id
      region_id
    }
  }
}
    `;
export type GqlActivate_Category_RegionMutationFn = Apollo.MutationFunction<GqlActivate_Category_RegionMutation, GqlActivate_Category_RegionMutationVariables>;

/**
 * __useActivate_Category_RegionMutation__
 *
 * To run a mutation, you first call `useActivate_Category_RegionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivate_Category_RegionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateCategoryRegionMutation, { data, loading, error }] = useActivate_Category_RegionMutation({
 *   variables: {
 *      data: // value for 'data'
 *      category_region_id: // value for 'category_region_id'
 *   },
 * });
 */
export function useActivate_Category_RegionMutation(baseOptions?: Apollo.MutationHookOptions<GqlActivate_Category_RegionMutation, GqlActivate_Category_RegionMutationVariables>) {
        return Apollo.useMutation<GqlActivate_Category_RegionMutation, GqlActivate_Category_RegionMutationVariables>(Activate_Category_RegionDocument, baseOptions);
      }
export type Activate_Category_RegionMutationHookResult = ReturnType<typeof useActivate_Category_RegionMutation>;
export type Activate_Category_RegionMutationResult = Apollo.MutationResult<GqlActivate_Category_RegionMutation>;
export type Activate_Category_RegionMutationOptions = Apollo.BaseMutationOptions<GqlActivate_Category_RegionMutation, GqlActivate_Category_RegionMutationVariables>;
export const ProductDepartmentsv2Document = gql`
    query ProductDepartmentsv2($filters: GenericScalar, $filter_string: String, $active: Boolean) {
  product_departments_v2(
    filters: $filters
    filter_string: $filter_string
    active: $active
  ) {
    department_id
    department_name
    description
    slug
    active
  }
}
    `;

/**
 * __useProductDepartmentsv2Query__
 *
 * To run a query within a React component, call `useProductDepartmentsv2Query` and pass it any options that fit your needs.
 * When your component renders, `useProductDepartmentsv2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductDepartmentsv2Query({
 *   variables: {
 *      filters: // value for 'filters'
 *      filter_string: // value for 'filter_string'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useProductDepartmentsv2Query(baseOptions?: Apollo.QueryHookOptions<GqlProductDepartmentsv2Query, GqlProductDepartmentsv2QueryVariables>) {
        return Apollo.useQuery<GqlProductDepartmentsv2Query, GqlProductDepartmentsv2QueryVariables>(ProductDepartmentsv2Document, baseOptions);
      }
export function useProductDepartmentsv2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlProductDepartmentsv2Query, GqlProductDepartmentsv2QueryVariables>) {
          return Apollo.useLazyQuery<GqlProductDepartmentsv2Query, GqlProductDepartmentsv2QueryVariables>(ProductDepartmentsv2Document, baseOptions);
        }
export type ProductDepartmentsv2QueryHookResult = ReturnType<typeof useProductDepartmentsv2Query>;
export type ProductDepartmentsv2LazyQueryHookResult = ReturnType<typeof useProductDepartmentsv2LazyQuery>;
export type ProductDepartmentsv2QueryResult = Apollo.QueryResult<GqlProductDepartmentsv2Query, GqlProductDepartmentsv2QueryVariables>;
export const AddDepartmentDocument = gql`
    mutation addDepartment($department_name: String!, $description: String) {
  add_department(
    data: {department_name: $department_name, description: $description}
  ) {
    department {
      department_id
      department_name
    }
    created
  }
}
    `;
export type GqlAddDepartmentMutationFn = Apollo.MutationFunction<GqlAddDepartmentMutation, GqlAddDepartmentMutationVariables>;

/**
 * __useAddDepartmentMutation__
 *
 * To run a mutation, you first call `useAddDepartmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDepartmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDepartmentMutation, { data, loading, error }] = useAddDepartmentMutation({
 *   variables: {
 *      department_name: // value for 'department_name'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useAddDepartmentMutation(baseOptions?: Apollo.MutationHookOptions<GqlAddDepartmentMutation, GqlAddDepartmentMutationVariables>) {
        return Apollo.useMutation<GqlAddDepartmentMutation, GqlAddDepartmentMutationVariables>(AddDepartmentDocument, baseOptions);
      }
export type AddDepartmentMutationHookResult = ReturnType<typeof useAddDepartmentMutation>;
export type AddDepartmentMutationResult = Apollo.MutationResult<GqlAddDepartmentMutation>;
export type AddDepartmentMutationOptions = Apollo.BaseMutationOptions<GqlAddDepartmentMutation, GqlAddDepartmentMutationVariables>;
export const UpdateDepartmentDocument = gql`
    mutation updateDepartment($department_id: String!, $data: DepartmentInput!) {
  update_department(department_id: $department_id, data: $data) {
    department {
      department_id
      department_name
      description
      slug
    }
  }
}
    `;
export type GqlUpdateDepartmentMutationFn = Apollo.MutationFunction<GqlUpdateDepartmentMutation, GqlUpdateDepartmentMutationVariables>;

/**
 * __useUpdateDepartmentMutation__
 *
 * To run a mutation, you first call `useUpdateDepartmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDepartmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDepartmentMutation, { data, loading, error }] = useUpdateDepartmentMutation({
 *   variables: {
 *      department_id: // value for 'department_id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateDepartmentMutation(baseOptions?: Apollo.MutationHookOptions<GqlUpdateDepartmentMutation, GqlUpdateDepartmentMutationVariables>) {
        return Apollo.useMutation<GqlUpdateDepartmentMutation, GqlUpdateDepartmentMutationVariables>(UpdateDepartmentDocument, baseOptions);
      }
export type UpdateDepartmentMutationHookResult = ReturnType<typeof useUpdateDepartmentMutation>;
export type UpdateDepartmentMutationResult = Apollo.MutationResult<GqlUpdateDepartmentMutation>;
export type UpdateDepartmentMutationOptions = Apollo.BaseMutationOptions<GqlUpdateDepartmentMutation, GqlUpdateDepartmentMutationVariables>;
export const Add_GeofencingDocument = gql`
    mutation add_geofencing($product_item_id: String!, $whitelisted_routes: [String], $blacklisted_routes: [String]) {
  update_product_item(
    product_item_id: $product_item_id
    data: {whitelisted_routes: $whitelisted_routes, blacklisted_routes: $blacklisted_routes}
  ) {
    product_item {
      product_item_id
      product_item_name
      whitelisted_routes
      blacklisted_routes
      active
    }
  }
}
    `;
export type GqlAdd_GeofencingMutationFn = Apollo.MutationFunction<GqlAdd_GeofencingMutation, GqlAdd_GeofencingMutationVariables>;

/**
 * __useAdd_GeofencingMutation__
 *
 * To run a mutation, you first call `useAdd_GeofencingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdd_GeofencingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGeofencingMutation, { data, loading, error }] = useAdd_GeofencingMutation({
 *   variables: {
 *      product_item_id: // value for 'product_item_id'
 *      whitelisted_routes: // value for 'whitelisted_routes'
 *      blacklisted_routes: // value for 'blacklisted_routes'
 *   },
 * });
 */
export function useAdd_GeofencingMutation(baseOptions?: Apollo.MutationHookOptions<GqlAdd_GeofencingMutation, GqlAdd_GeofencingMutationVariables>) {
        return Apollo.useMutation<GqlAdd_GeofencingMutation, GqlAdd_GeofencingMutationVariables>(Add_GeofencingDocument, baseOptions);
      }
export type Add_GeofencingMutationHookResult = ReturnType<typeof useAdd_GeofencingMutation>;
export type Add_GeofencingMutationResult = Apollo.MutationResult<GqlAdd_GeofencingMutation>;
export type Add_GeofencingMutationOptions = Apollo.BaseMutationOptions<GqlAdd_GeofencingMutation, GqlAdd_GeofencingMutationVariables>;
export const Delete_GeofencingDocument = gql`
    mutation delete_geofencing($product_item_id: String!) {
  update_product_item(
    product_item_id: $product_item_id
    data: {whitelisted_routes: null, blacklisted_routes: null}
  ) {
    product_item {
      product_item_id
      product_item_name
      whitelisted_routes
      blacklisted_routes
    }
  }
}
    `;
export type GqlDelete_GeofencingMutationFn = Apollo.MutationFunction<GqlDelete_GeofencingMutation, GqlDelete_GeofencingMutationVariables>;

/**
 * __useDelete_GeofencingMutation__
 *
 * To run a mutation, you first call `useDelete_GeofencingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_GeofencingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGeofencingMutation, { data, loading, error }] = useDelete_GeofencingMutation({
 *   variables: {
 *      product_item_id: // value for 'product_item_id'
 *   },
 * });
 */
export function useDelete_GeofencingMutation(baseOptions?: Apollo.MutationHookOptions<GqlDelete_GeofencingMutation, GqlDelete_GeofencingMutationVariables>) {
        return Apollo.useMutation<GqlDelete_GeofencingMutation, GqlDelete_GeofencingMutationVariables>(Delete_GeofencingDocument, baseOptions);
      }
export type Delete_GeofencingMutationHookResult = ReturnType<typeof useDelete_GeofencingMutation>;
export type Delete_GeofencingMutationResult = Apollo.MutationResult<GqlDelete_GeofencingMutation>;
export type Delete_GeofencingMutationOptions = Apollo.BaseMutationOptions<GqlDelete_GeofencingMutation, GqlDelete_GeofencingMutationVariables>;
export const Routes_V2Document = gql`
    query routes_v2($filters: GenericScalar, $search: String) {
  routes_v2(filters: $filters, search: $search) {
    edges {
      node {
        route_id
        route_name
        depot_id
        depot {
          depot_name
          area {
            area_id
            region_id
            region {
              region_name
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useRoutes_V2Query__
 *
 * To run a query within a React component, call `useRoutes_V2Query` and pass it any options that fit your needs.
 * When your component renders, `useRoutes_V2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoutes_V2Query({
 *   variables: {
 *      filters: // value for 'filters'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useRoutes_V2Query(baseOptions?: Apollo.QueryHookOptions<GqlRoutes_V2Query, GqlRoutes_V2QueryVariables>) {
        return Apollo.useQuery<GqlRoutes_V2Query, GqlRoutes_V2QueryVariables>(Routes_V2Document, baseOptions);
      }
export function useRoutes_V2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlRoutes_V2Query, GqlRoutes_V2QueryVariables>) {
          return Apollo.useLazyQuery<GqlRoutes_V2Query, GqlRoutes_V2QueryVariables>(Routes_V2Document, baseOptions);
        }
export type Routes_V2QueryHookResult = ReturnType<typeof useRoutes_V2Query>;
export type Routes_V2LazyQueryHookResult = ReturnType<typeof useRoutes_V2LazyQuery>;
export type Routes_V2QueryResult = Apollo.QueryResult<GqlRoutes_V2Query, GqlRoutes_V2QueryVariables>;
export const RouteV2ByIdDocument = gql`
    query RouteV2ById($route_id: String) {
  route_v2_by_id(route_id: $route_id) {
    route_id
    route_name
    depot_id
    depot {
      depot_name
      area {
        area_id
        region_id
        region {
          region_name
        }
      }
    }
  }
}
    `;

/**
 * __useRouteV2ByIdQuery__
 *
 * To run a query within a React component, call `useRouteV2ByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useRouteV2ByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRouteV2ByIdQuery({
 *   variables: {
 *      route_id: // value for 'route_id'
 *   },
 * });
 */
export function useRouteV2ByIdQuery(baseOptions?: Apollo.QueryHookOptions<GqlRouteV2ByIdQuery, GqlRouteV2ByIdQueryVariables>) {
        return Apollo.useQuery<GqlRouteV2ByIdQuery, GqlRouteV2ByIdQueryVariables>(RouteV2ByIdDocument, baseOptions);
      }
export function useRouteV2ByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlRouteV2ByIdQuery, GqlRouteV2ByIdQueryVariables>) {
          return Apollo.useLazyQuery<GqlRouteV2ByIdQuery, GqlRouteV2ByIdQueryVariables>(RouteV2ByIdDocument, baseOptions);
        }
export type RouteV2ByIdQueryHookResult = ReturnType<typeof useRouteV2ByIdQuery>;
export type RouteV2ByIdLazyQueryHookResult = ReturnType<typeof useRouteV2ByIdLazyQuery>;
export type RouteV2ByIdQueryResult = Apollo.QueryResult<GqlRouteV2ByIdQuery, GqlRouteV2ByIdQueryVariables>;
export const Packaging_Units_V2Document = gql`
    query packaging_units_v2 {
  packaging_units_v2 {
    edges {
      node {
        name
        active
        packaging_unit_id
        returnable
        weight
        active
      }
    }
  }
}
    `;

/**
 * __usePackaging_Units_V2Query__
 *
 * To run a query within a React component, call `usePackaging_Units_V2Query` and pass it any options that fit your needs.
 * When your component renders, `usePackaging_Units_V2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePackaging_Units_V2Query({
 *   variables: {
 *   },
 * });
 */
export function usePackaging_Units_V2Query(baseOptions?: Apollo.QueryHookOptions<GqlPackaging_Units_V2Query, GqlPackaging_Units_V2QueryVariables>) {
        return Apollo.useQuery<GqlPackaging_Units_V2Query, GqlPackaging_Units_V2QueryVariables>(Packaging_Units_V2Document, baseOptions);
      }
export function usePackaging_Units_V2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlPackaging_Units_V2Query, GqlPackaging_Units_V2QueryVariables>) {
          return Apollo.useLazyQuery<GqlPackaging_Units_V2Query, GqlPackaging_Units_V2QueryVariables>(Packaging_Units_V2Document, baseOptions);
        }
export type Packaging_Units_V2QueryHookResult = ReturnType<typeof usePackaging_Units_V2Query>;
export type Packaging_Units_V2LazyQueryHookResult = ReturnType<typeof usePackaging_Units_V2LazyQuery>;
export type Packaging_Units_V2QueryResult = Apollo.QueryResult<GqlPackaging_Units_V2Query, GqlPackaging_Units_V2QueryVariables>;
export const Add_Packaging_UnitDocument = gql`
    mutation add_packaging_unit($name: String!, $weight: Float!, $returnable: Boolean, $etims_packaging_code: String!) {
  add_packaging_unit(
    data: {name: $name, weight: $weight, returnable: $returnable, etims_packaging_code: $etims_packaging_code}
  ) {
    packaging_unit {
      packaging_unit_id
      name
      created_by
      date_created
      etims_packaging_code
      date_updated
    }
    created
  }
}
    `;
export type GqlAdd_Packaging_UnitMutationFn = Apollo.MutationFunction<GqlAdd_Packaging_UnitMutation, GqlAdd_Packaging_UnitMutationVariables>;

/**
 * __useAdd_Packaging_UnitMutation__
 *
 * To run a mutation, you first call `useAdd_Packaging_UnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdd_Packaging_UnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPackagingUnitMutation, { data, loading, error }] = useAdd_Packaging_UnitMutation({
 *   variables: {
 *      name: // value for 'name'
 *      weight: // value for 'weight'
 *      returnable: // value for 'returnable'
 *      etims_packaging_code: // value for 'etims_packaging_code'
 *   },
 * });
 */
export function useAdd_Packaging_UnitMutation(baseOptions?: Apollo.MutationHookOptions<GqlAdd_Packaging_UnitMutation, GqlAdd_Packaging_UnitMutationVariables>) {
        return Apollo.useMutation<GqlAdd_Packaging_UnitMutation, GqlAdd_Packaging_UnitMutationVariables>(Add_Packaging_UnitDocument, baseOptions);
      }
export type Add_Packaging_UnitMutationHookResult = ReturnType<typeof useAdd_Packaging_UnitMutation>;
export type Add_Packaging_UnitMutationResult = Apollo.MutationResult<GqlAdd_Packaging_UnitMutation>;
export type Add_Packaging_UnitMutationOptions = Apollo.BaseMutationOptions<GqlAdd_Packaging_UnitMutation, GqlAdd_Packaging_UnitMutationVariables>;
export const Update_Packaging_UnitDocument = gql`
    mutation update_packaging_unit($data: PackagingUnitInput!, $packaging_unit_id: String!) {
  update_packaging_unit(data: $data, packaging_unit_id: $packaging_unit_id) {
    packaging_unit {
      packaging_unit_id
      returnable
      active
      name
      weight
    }
  }
}
    `;
export type GqlUpdate_Packaging_UnitMutationFn = Apollo.MutationFunction<GqlUpdate_Packaging_UnitMutation, GqlUpdate_Packaging_UnitMutationVariables>;

/**
 * __useUpdate_Packaging_UnitMutation__
 *
 * To run a mutation, you first call `useUpdate_Packaging_UnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Packaging_UnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePackagingUnitMutation, { data, loading, error }] = useUpdate_Packaging_UnitMutation({
 *   variables: {
 *      data: // value for 'data'
 *      packaging_unit_id: // value for 'packaging_unit_id'
 *   },
 * });
 */
export function useUpdate_Packaging_UnitMutation(baseOptions?: Apollo.MutationHookOptions<GqlUpdate_Packaging_UnitMutation, GqlUpdate_Packaging_UnitMutationVariables>) {
        return Apollo.useMutation<GqlUpdate_Packaging_UnitMutation, GqlUpdate_Packaging_UnitMutationVariables>(Update_Packaging_UnitDocument, baseOptions);
      }
export type Update_Packaging_UnitMutationHookResult = ReturnType<typeof useUpdate_Packaging_UnitMutation>;
export type Update_Packaging_UnitMutationResult = Apollo.MutationResult<GqlUpdate_Packaging_UnitMutation>;
export type Update_Packaging_UnitMutationOptions = Apollo.BaseMutationOptions<GqlUpdate_Packaging_UnitMutation, GqlUpdate_Packaging_UnitMutationVariables>;
export const Add_Product_ItemDocument = gql`
    mutation add_product_item($data: ProductItemInput!) {
  add_product_item(data: $data) {
    created
    product_item {
      product_item_id
      units_of_measure {
        uom_id
        business_domain_id
      }
      regions {
        business_domain_id
        region_id
      }
    }
  }
}
    `;
export type GqlAdd_Product_ItemMutationFn = Apollo.MutationFunction<GqlAdd_Product_ItemMutation, GqlAdd_Product_ItemMutationVariables>;

/**
 * __useAdd_Product_ItemMutation__
 *
 * To run a mutation, you first call `useAdd_Product_ItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdd_Product_ItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProductItemMutation, { data, loading, error }] = useAdd_Product_ItemMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdd_Product_ItemMutation(baseOptions?: Apollo.MutationHookOptions<GqlAdd_Product_ItemMutation, GqlAdd_Product_ItemMutationVariables>) {
        return Apollo.useMutation<GqlAdd_Product_ItemMutation, GqlAdd_Product_ItemMutationVariables>(Add_Product_ItemDocument, baseOptions);
      }
export type Add_Product_ItemMutationHookResult = ReturnType<typeof useAdd_Product_ItemMutation>;
export type Add_Product_ItemMutationResult = Apollo.MutationResult<GqlAdd_Product_ItemMutation>;
export type Add_Product_ItemMutationOptions = Apollo.BaseMutationOptions<GqlAdd_Product_ItemMutation, GqlAdd_Product_ItemMutationVariables>;
export const Add_Product_Item_ImageDocument = gql`
    mutation add_product_item_image($image: Upload!, $product_item_id: String!) {
  add_product_item_image(product_item_id: $product_item_id, image: $image) {
    uploaded
    product_item {
      product_item_id
      product_item_name
    }
  }
}
    `;
export type GqlAdd_Product_Item_ImageMutationFn = Apollo.MutationFunction<GqlAdd_Product_Item_ImageMutation, GqlAdd_Product_Item_ImageMutationVariables>;

/**
 * __useAdd_Product_Item_ImageMutation__
 *
 * To run a mutation, you first call `useAdd_Product_Item_ImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdd_Product_Item_ImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProductItemImageMutation, { data, loading, error }] = useAdd_Product_Item_ImageMutation({
 *   variables: {
 *      image: // value for 'image'
 *      product_item_id: // value for 'product_item_id'
 *   },
 * });
 */
export function useAdd_Product_Item_ImageMutation(baseOptions?: Apollo.MutationHookOptions<GqlAdd_Product_Item_ImageMutation, GqlAdd_Product_Item_ImageMutationVariables>) {
        return Apollo.useMutation<GqlAdd_Product_Item_ImageMutation, GqlAdd_Product_Item_ImageMutationVariables>(Add_Product_Item_ImageDocument, baseOptions);
      }
export type Add_Product_Item_ImageMutationHookResult = ReturnType<typeof useAdd_Product_Item_ImageMutation>;
export type Add_Product_Item_ImageMutationResult = Apollo.MutationResult<GqlAdd_Product_Item_ImageMutation>;
export type Add_Product_Item_ImageMutationOptions = Apollo.BaseMutationOptions<GqlAdd_Product_Item_ImageMutation, GqlAdd_Product_Item_ImageMutationVariables>;
export const Product_Item_SegmentsDocument = gql`
    query product_item_segments {
  product_item_segments_v2 {
    segment_name
    product_item_segment_id
  }
}
    `;

/**
 * __useProduct_Item_SegmentsQuery__
 *
 * To run a query within a React component, call `useProduct_Item_SegmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProduct_Item_SegmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProduct_Item_SegmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProduct_Item_SegmentsQuery(baseOptions?: Apollo.QueryHookOptions<GqlProduct_Item_SegmentsQuery, GqlProduct_Item_SegmentsQueryVariables>) {
        return Apollo.useQuery<GqlProduct_Item_SegmentsQuery, GqlProduct_Item_SegmentsQueryVariables>(Product_Item_SegmentsDocument, baseOptions);
      }
export function useProduct_Item_SegmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlProduct_Item_SegmentsQuery, GqlProduct_Item_SegmentsQueryVariables>) {
          return Apollo.useLazyQuery<GqlProduct_Item_SegmentsQuery, GqlProduct_Item_SegmentsQueryVariables>(Product_Item_SegmentsDocument, baseOptions);
        }
export type Product_Item_SegmentsQueryHookResult = ReturnType<typeof useProduct_Item_SegmentsQuery>;
export type Product_Item_SegmentsLazyQueryHookResult = ReturnType<typeof useProduct_Item_SegmentsLazyQuery>;
export type Product_Item_SegmentsQueryResult = Apollo.QueryResult<GqlProduct_Item_SegmentsQuery, GqlProduct_Item_SegmentsQueryVariables>;
export const QuantityUnitCodesDocument = gql`
    query quantityUnitCodes($filters: ETIMsCodeFilters) {
  eTIMsCodes(filters: $filters) {
    data {
      clsList {
        cdClsNm
        cdCls
        dtlList {
          cd
          cdNm
        }
      }
    }
  }
}
    `;

/**
 * __useQuantityUnitCodesQuery__
 *
 * To run a query within a React component, call `useQuantityUnitCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuantityUnitCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuantityUnitCodesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useQuantityUnitCodesQuery(baseOptions?: Apollo.QueryHookOptions<GqlQuantityUnitCodesQuery, GqlQuantityUnitCodesQueryVariables>) {
        return Apollo.useQuery<GqlQuantityUnitCodesQuery, GqlQuantityUnitCodesQueryVariables>(QuantityUnitCodesDocument, baseOptions);
      }
export function useQuantityUnitCodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlQuantityUnitCodesQuery, GqlQuantityUnitCodesQueryVariables>) {
          return Apollo.useLazyQuery<GqlQuantityUnitCodesQuery, GqlQuantityUnitCodesQueryVariables>(QuantityUnitCodesDocument, baseOptions);
        }
export type QuantityUnitCodesQueryHookResult = ReturnType<typeof useQuantityUnitCodesQuery>;
export type QuantityUnitCodesLazyQueryHookResult = ReturnType<typeof useQuantityUnitCodesLazyQuery>;
export type QuantityUnitCodesQueryResult = Apollo.QueryResult<GqlQuantityUnitCodesQuery, GqlQuantityUnitCodesQueryVariables>;
export const Product_Item_V2_By_IdDocument = gql`
    query product_item_v2_by_id($product_item_id: String!, $filters: GenericScalar) {
  product_item_v2_by_id(product_item_id: $product_item_id, filters: $filters) {
    synced_to_etims
    etims_code
    etims_class_code
    country_of_origin_code
    etims_packaging_unit
    etims_quantity_code
    tax_type_code
    product_item_id
    product_item_name
    active
    image_url
    base_price
    total_regions
    packed_weight
    packed_volume
    description
    base_price
    wms_default_available
    wms_std_pallet_quantity
    wms_pickface_putaway
    date_created
    item_sequence
    product_id
    product {
      product_name
      packaging_units {
        etims_packaging_code
      }
    }
    product_item_segment_id
    product_item_segment {
      segment_name
    }
    units_of_measure {
      business_domain_id
      business_domain {
        domain_name
      }
      active
      conversion_ratio
      product_item_uom_id
      product_item_id
      uom_id
      uom {
        etims_quantity_code
        name
        uom_id
      }
    }
    regions {
      region_id
      region {
        region_id
        region_name
      }
      product_item_region_id
      business_domain_id
      business_domain {
        domain_name
      }
      active
    }
    dynamic_price {
      discount_value
    }
  }
}
    `;

/**
 * __useProduct_Item_V2_By_IdQuery__
 *
 * To run a query within a React component, call `useProduct_Item_V2_By_IdQuery` and pass it any options that fit your needs.
 * When your component renders, `useProduct_Item_V2_By_IdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProduct_Item_V2_By_IdQuery({
 *   variables: {
 *      product_item_id: // value for 'product_item_id'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useProduct_Item_V2_By_IdQuery(baseOptions: Apollo.QueryHookOptions<GqlProduct_Item_V2_By_IdQuery, GqlProduct_Item_V2_By_IdQueryVariables>) {
        return Apollo.useQuery<GqlProduct_Item_V2_By_IdQuery, GqlProduct_Item_V2_By_IdQueryVariables>(Product_Item_V2_By_IdDocument, baseOptions);
      }
export function useProduct_Item_V2_By_IdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlProduct_Item_V2_By_IdQuery, GqlProduct_Item_V2_By_IdQueryVariables>) {
          return Apollo.useLazyQuery<GqlProduct_Item_V2_By_IdQuery, GqlProduct_Item_V2_By_IdQueryVariables>(Product_Item_V2_By_IdDocument, baseOptions);
        }
export type Product_Item_V2_By_IdQueryHookResult = ReturnType<typeof useProduct_Item_V2_By_IdQuery>;
export type Product_Item_V2_By_IdLazyQueryHookResult = ReturnType<typeof useProduct_Item_V2_By_IdLazyQuery>;
export type Product_Item_V2_By_IdQueryResult = Apollo.QueryResult<GqlProduct_Item_V2_By_IdQuery, GqlProduct_Item_V2_By_IdQueryVariables>;
export const Add_Product_Item_UomDocument = gql`
    mutation add_product_item_uom($data: ProductItemUomInput!) {
  add_product_item_uom(data: $data) {
    product_item_uom {
      product_item_uom_id
      product_item_id
      business_domain_id
      conversion_ratio
    }
  }
}
    `;
export type GqlAdd_Product_Item_UomMutationFn = Apollo.MutationFunction<GqlAdd_Product_Item_UomMutation, GqlAdd_Product_Item_UomMutationVariables>;

/**
 * __useAdd_Product_Item_UomMutation__
 *
 * To run a mutation, you first call `useAdd_Product_Item_UomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdd_Product_Item_UomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProductItemUomMutation, { data, loading, error }] = useAdd_Product_Item_UomMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdd_Product_Item_UomMutation(baseOptions?: Apollo.MutationHookOptions<GqlAdd_Product_Item_UomMutation, GqlAdd_Product_Item_UomMutationVariables>) {
        return Apollo.useMutation<GqlAdd_Product_Item_UomMutation, GqlAdd_Product_Item_UomMutationVariables>(Add_Product_Item_UomDocument, baseOptions);
      }
export type Add_Product_Item_UomMutationHookResult = ReturnType<typeof useAdd_Product_Item_UomMutation>;
export type Add_Product_Item_UomMutationResult = Apollo.MutationResult<GqlAdd_Product_Item_UomMutation>;
export type Add_Product_Item_UomMutationOptions = Apollo.BaseMutationOptions<GqlAdd_Product_Item_UomMutation, GqlAdd_Product_Item_UomMutationVariables>;
export const Update_Product_Item_UomDocument = gql`
    mutation update_product_item_uom($product_item_uom_id: String!, $uom_id: String!, $product_item_id: String!, $business_domain_id: String!, $data: ProductItemUomInput!) {
  update_product_item_uom(
    product_item_uom_id: $product_item_uom_id
    product_item_id: $product_item_id
    uom_id: $uom_id
    business_domain_id: $business_domain_id
    data: $data
  ) {
    product_item_uom {
      product_item_uom_id
      active
    }
  }
}
    `;
export type GqlUpdate_Product_Item_UomMutationFn = Apollo.MutationFunction<GqlUpdate_Product_Item_UomMutation, GqlUpdate_Product_Item_UomMutationVariables>;

/**
 * __useUpdate_Product_Item_UomMutation__
 *
 * To run a mutation, you first call `useUpdate_Product_Item_UomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Product_Item_UomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductItemUomMutation, { data, loading, error }] = useUpdate_Product_Item_UomMutation({
 *   variables: {
 *      product_item_uom_id: // value for 'product_item_uom_id'
 *      uom_id: // value for 'uom_id'
 *      product_item_id: // value for 'product_item_id'
 *      business_domain_id: // value for 'business_domain_id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdate_Product_Item_UomMutation(baseOptions?: Apollo.MutationHookOptions<GqlUpdate_Product_Item_UomMutation, GqlUpdate_Product_Item_UomMutationVariables>) {
        return Apollo.useMutation<GqlUpdate_Product_Item_UomMutation, GqlUpdate_Product_Item_UomMutationVariables>(Update_Product_Item_UomDocument, baseOptions);
      }
export type Update_Product_Item_UomMutationHookResult = ReturnType<typeof useUpdate_Product_Item_UomMutation>;
export type Update_Product_Item_UomMutationResult = Apollo.MutationResult<GqlUpdate_Product_Item_UomMutation>;
export type Update_Product_Item_UomMutationOptions = Apollo.BaseMutationOptions<GqlUpdate_Product_Item_UomMutation, GqlUpdate_Product_Item_UomMutationVariables>;
export const Add_Product_Item_RegionDocument = gql`
    mutation add_product_item_region($data: ProductItemRegionInput!) {
  add_product_item_region(data: $data) {
    created
    product_item_region {
      region_id
      product_item_id
      business_domain_id
    }
  }
}
    `;
export type GqlAdd_Product_Item_RegionMutationFn = Apollo.MutationFunction<GqlAdd_Product_Item_RegionMutation, GqlAdd_Product_Item_RegionMutationVariables>;

/**
 * __useAdd_Product_Item_RegionMutation__
 *
 * To run a mutation, you first call `useAdd_Product_Item_RegionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdd_Product_Item_RegionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProductItemRegionMutation, { data, loading, error }] = useAdd_Product_Item_RegionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdd_Product_Item_RegionMutation(baseOptions?: Apollo.MutationHookOptions<GqlAdd_Product_Item_RegionMutation, GqlAdd_Product_Item_RegionMutationVariables>) {
        return Apollo.useMutation<GqlAdd_Product_Item_RegionMutation, GqlAdd_Product_Item_RegionMutationVariables>(Add_Product_Item_RegionDocument, baseOptions);
      }
export type Add_Product_Item_RegionMutationHookResult = ReturnType<typeof useAdd_Product_Item_RegionMutation>;
export type Add_Product_Item_RegionMutationResult = Apollo.MutationResult<GqlAdd_Product_Item_RegionMutation>;
export type Add_Product_Item_RegionMutationOptions = Apollo.BaseMutationOptions<GqlAdd_Product_Item_RegionMutation, GqlAdd_Product_Item_RegionMutationVariables>;
export const Activate_Product_Item_RegionDocument = gql`
    mutation activate_product_item_region($active: Boolean!, $product_item_region_id: String!) {
  update_product_item_region(
    data: {active: $active}
    product_item_region_id: $product_item_region_id
  ) {
    product_item_region {
      product_item_id
      active
    }
  }
}
    `;
export type GqlActivate_Product_Item_RegionMutationFn = Apollo.MutationFunction<GqlActivate_Product_Item_RegionMutation, GqlActivate_Product_Item_RegionMutationVariables>;

/**
 * __useActivate_Product_Item_RegionMutation__
 *
 * To run a mutation, you first call `useActivate_Product_Item_RegionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivate_Product_Item_RegionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateProductItemRegionMutation, { data, loading, error }] = useActivate_Product_Item_RegionMutation({
 *   variables: {
 *      active: // value for 'active'
 *      product_item_region_id: // value for 'product_item_region_id'
 *   },
 * });
 */
export function useActivate_Product_Item_RegionMutation(baseOptions?: Apollo.MutationHookOptions<GqlActivate_Product_Item_RegionMutation, GqlActivate_Product_Item_RegionMutationVariables>) {
        return Apollo.useMutation<GqlActivate_Product_Item_RegionMutation, GqlActivate_Product_Item_RegionMutationVariables>(Activate_Product_Item_RegionDocument, baseOptions);
      }
export type Activate_Product_Item_RegionMutationHookResult = ReturnType<typeof useActivate_Product_Item_RegionMutation>;
export type Activate_Product_Item_RegionMutationResult = Apollo.MutationResult<GqlActivate_Product_Item_RegionMutation>;
export type Activate_Product_Item_RegionMutationOptions = Apollo.BaseMutationOptions<GqlActivate_Product_Item_RegionMutation, GqlActivate_Product_Item_RegionMutationVariables>;
export const Business_Domains_V2Document = gql`
    query business_domains_v2 {
  business_domains_v2 {
    business_domain_id
    domain_name
  }
}
    `;

/**
 * __useBusiness_Domains_V2Query__
 *
 * To run a query within a React component, call `useBusiness_Domains_V2Query` and pass it any options that fit your needs.
 * When your component renders, `useBusiness_Domains_V2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusiness_Domains_V2Query({
 *   variables: {
 *   },
 * });
 */
export function useBusiness_Domains_V2Query(baseOptions?: Apollo.QueryHookOptions<GqlBusiness_Domains_V2Query, GqlBusiness_Domains_V2QueryVariables>) {
        return Apollo.useQuery<GqlBusiness_Domains_V2Query, GqlBusiness_Domains_V2QueryVariables>(Business_Domains_V2Document, baseOptions);
      }
export function useBusiness_Domains_V2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlBusiness_Domains_V2Query, GqlBusiness_Domains_V2QueryVariables>) {
          return Apollo.useLazyQuery<GqlBusiness_Domains_V2Query, GqlBusiness_Domains_V2QueryVariables>(Business_Domains_V2Document, baseOptions);
        }
export type Business_Domains_V2QueryHookResult = ReturnType<typeof useBusiness_Domains_V2Query>;
export type Business_Domains_V2LazyQueryHookResult = ReturnType<typeof useBusiness_Domains_V2LazyQuery>;
export type Business_Domains_V2QueryResult = Apollo.QueryResult<GqlBusiness_Domains_V2Query, GqlBusiness_Domains_V2QueryVariables>;
export const Paginated_Product_ItemsDocument = gql`
    query paginated_product_items($filters: GenericScalar, $page: Int, $per_page: Int) {
  paginated_product_items_v2(filters: $filters, page: $page, per_page: $per_page) {
    page_info {
      total_pages
      total_count
      current_page
      per_page
      has_previous
      has_next
    }
    edges {
      node {
        active
        product_item_id
        product_item_name
        active
        product_id
        product {
          product_name
        }
        image_url
        staff_only
        etims_class_code
        etims_code
        country_of_origin_code
        tax_type_code
        packed_weight
        packed_volume
        featured
        product_item_segment_id
        product_item_segment {
          segment_name
        }
        blacklisted_routes
        blacklisted {
          route_id
          route {
            route_id
            route_name
            depot_id
            depot {
              depot_name
              area {
                area_id
                region_id
                region {
                  region_name
                }
              }
            }
          }
        }
        whitelisted_routes
        whitelisted {
          route_id
          route {
            route_id
            route_name
            depot_id
            depot {
              depot_name
              area {
                area_id
                region_id
                region {
                  region_name
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __usePaginated_Product_ItemsQuery__
 *
 * To run a query within a React component, call `usePaginated_Product_ItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginated_Product_ItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginated_Product_ItemsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      page: // value for 'page'
 *      per_page: // value for 'per_page'
 *   },
 * });
 */
export function usePaginated_Product_ItemsQuery(baseOptions?: Apollo.QueryHookOptions<GqlPaginated_Product_ItemsQuery, GqlPaginated_Product_ItemsQueryVariables>) {
        return Apollo.useQuery<GqlPaginated_Product_ItemsQuery, GqlPaginated_Product_ItemsQueryVariables>(Paginated_Product_ItemsDocument, baseOptions);
      }
export function usePaginated_Product_ItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlPaginated_Product_ItemsQuery, GqlPaginated_Product_ItemsQueryVariables>) {
          return Apollo.useLazyQuery<GqlPaginated_Product_ItemsQuery, GqlPaginated_Product_ItemsQueryVariables>(Paginated_Product_ItemsDocument, baseOptions);
        }
export type Paginated_Product_ItemsQueryHookResult = ReturnType<typeof usePaginated_Product_ItemsQuery>;
export type Paginated_Product_ItemsLazyQueryHookResult = ReturnType<typeof usePaginated_Product_ItemsLazyQuery>;
export type Paginated_Product_ItemsQueryResult = Apollo.QueryResult<GqlPaginated_Product_ItemsQuery, GqlPaginated_Product_ItemsQueryVariables>;
export const Update_Product_ItemDocument = gql`
    mutation update_product_item($product_item_id: String!, $data: ProductItemInput!) {
  update_product_item(product_item_id: $product_item_id, data: $data) {
    product_item {
      product_item_id
      active
    }
  }
}
    `;
export type GqlUpdate_Product_ItemMutationFn = Apollo.MutationFunction<GqlUpdate_Product_ItemMutation, GqlUpdate_Product_ItemMutationVariables>;

/**
 * __useUpdate_Product_ItemMutation__
 *
 * To run a mutation, you first call `useUpdate_Product_ItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Product_ItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductItemMutation, { data, loading, error }] = useUpdate_Product_ItemMutation({
 *   variables: {
 *      product_item_id: // value for 'product_item_id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdate_Product_ItemMutation(baseOptions?: Apollo.MutationHookOptions<GqlUpdate_Product_ItemMutation, GqlUpdate_Product_ItemMutationVariables>) {
        return Apollo.useMutation<GqlUpdate_Product_ItemMutation, GqlUpdate_Product_ItemMutationVariables>(Update_Product_ItemDocument, baseOptions);
      }
export type Update_Product_ItemMutationHookResult = ReturnType<typeof useUpdate_Product_ItemMutation>;
export type Update_Product_ItemMutationResult = Apollo.MutationResult<GqlUpdate_Product_ItemMutation>;
export type Update_Product_ItemMutationOptions = Apollo.BaseMutationOptions<GqlUpdate_Product_ItemMutation, GqlUpdate_Product_ItemMutationVariables>;
export const PaginatedProductsV2Document = gql`
    query paginatedProductsV2($per_page: Int, $page: Int, $active: Boolean, $search: String, $filters: GenericScalar) {
  paginated_products_v2(
    per_page: $per_page
    page: $page
    active: $active
    search: $search
    filters: $filters
  ) {
    page_info {
      per_page
      total_count
      total_pages
      has_next
      has_previous
    }
    edges {
      node {
        active
        product_id
        product_name
        product_code
        hs_code
        kra_code
        vatable
        product_type
        image_url
        date_created
        category {
          category_name
        }
      }
    }
  }
}
    `;

/**
 * __usePaginatedProductsV2Query__
 *
 * To run a query within a React component, call `usePaginatedProductsV2Query` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedProductsV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedProductsV2Query({
 *   variables: {
 *      per_page: // value for 'per_page'
 *      page: // value for 'page'
 *      active: // value for 'active'
 *      search: // value for 'search'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function usePaginatedProductsV2Query(baseOptions?: Apollo.QueryHookOptions<GqlPaginatedProductsV2Query, GqlPaginatedProductsV2QueryVariables>) {
        return Apollo.useQuery<GqlPaginatedProductsV2Query, GqlPaginatedProductsV2QueryVariables>(PaginatedProductsV2Document, baseOptions);
      }
export function usePaginatedProductsV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlPaginatedProductsV2Query, GqlPaginatedProductsV2QueryVariables>) {
          return Apollo.useLazyQuery<GqlPaginatedProductsV2Query, GqlPaginatedProductsV2QueryVariables>(PaginatedProductsV2Document, baseOptions);
        }
export type PaginatedProductsV2QueryHookResult = ReturnType<typeof usePaginatedProductsV2Query>;
export type PaginatedProductsV2LazyQueryHookResult = ReturnType<typeof usePaginatedProductsV2LazyQuery>;
export type PaginatedProductsV2QueryResult = Apollo.QueryResult<GqlPaginatedProductsV2Query, GqlPaginatedProductsV2QueryVariables>;
export const AddProductDocument = gql`
    mutation addProduct($data: ProductInput!) {
  add_product(data: $data) {
    created
    product {
      product_id
      product_code
      category_id
      wms_shelf_life_days
      hs_code
      kra_code
      product_tags {
        product_tag
        product_tag_id
        product_id
      }
      category {
        category_name
      }
      categories {
        category_name
      }
    }
  }
}
    `;
export type GqlAddProductMutationFn = Apollo.MutationFunction<GqlAddProductMutation, GqlAddProductMutationVariables>;

/**
 * __useAddProductMutation__
 *
 * To run a mutation, you first call `useAddProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProductMutation, { data, loading, error }] = useAddProductMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddProductMutation(baseOptions?: Apollo.MutationHookOptions<GqlAddProductMutation, GqlAddProductMutationVariables>) {
        return Apollo.useMutation<GqlAddProductMutation, GqlAddProductMutationVariables>(AddProductDocument, baseOptions);
      }
export type AddProductMutationHookResult = ReturnType<typeof useAddProductMutation>;
export type AddProductMutationResult = Apollo.MutationResult<GqlAddProductMutation>;
export type AddProductMutationOptions = Apollo.BaseMutationOptions<GqlAddProductMutation, GqlAddProductMutationVariables>;
export const UpdateProductDocument = gql`
    mutation updateProduct($product_id: String!, $data: ProductInput!) {
  update_product(data: $data, product_id: $product_id) {
    product {
      product_id
      active
      product_code
      product_type
      category_id
      vatable
      wms_shelf_life_days
      hs_code
      kra_code
      categories {
        category_id
        category_name
      }
      active
    }
  }
}
    `;
export type GqlUpdateProductMutationFn = Apollo.MutationFunction<GqlUpdateProductMutation, GqlUpdateProductMutationVariables>;

/**
 * __useUpdateProductMutation__
 *
 * To run a mutation, you first call `useUpdateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductMutation, { data, loading, error }] = useUpdateProductMutation({
 *   variables: {
 *      product_id: // value for 'product_id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateProductMutation(baseOptions?: Apollo.MutationHookOptions<GqlUpdateProductMutation, GqlUpdateProductMutationVariables>) {
        return Apollo.useMutation<GqlUpdateProductMutation, GqlUpdateProductMutationVariables>(UpdateProductDocument, baseOptions);
      }
export type UpdateProductMutationHookResult = ReturnType<typeof useUpdateProductMutation>;
export type UpdateProductMutationResult = Apollo.MutationResult<GqlUpdateProductMutation>;
export type UpdateProductMutationOptions = Apollo.BaseMutationOptions<GqlUpdateProductMutation, GqlUpdateProductMutationVariables>;
export const ProductByIdV2Document = gql`
    query productByIdV2($product_id: String!) {
  product_v2_by_id(product_id: $product_id) {
    product_id
    active
    product_type
    product_name
    product_code
    product_description
    vatable
    image_url
    date_created
    wms_shelf_life_days
    hs_code
    kra_code
    category_id
    category {
      category_id
      category_name
    }
    categories {
      active
      category_id
      category_name
    }
    product_tags {
      product_tag
      product_tag_id
      product_id
    }
    domain_activations {
      product_domain_activation_id
      active
      business_domain {
        domain_name
        business_domain_id
      }
    }
    packaging_units {
      name
      active
      packaging_unit_id
    }
  }
}
    `;

/**
 * __useProductByIdV2Query__
 *
 * To run a query within a React component, call `useProductByIdV2Query` and pass it any options that fit your needs.
 * When your component renders, `useProductByIdV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductByIdV2Query({
 *   variables: {
 *      product_id: // value for 'product_id'
 *   },
 * });
 */
export function useProductByIdV2Query(baseOptions: Apollo.QueryHookOptions<GqlProductByIdV2Query, GqlProductByIdV2QueryVariables>) {
        return Apollo.useQuery<GqlProductByIdV2Query, GqlProductByIdV2QueryVariables>(ProductByIdV2Document, baseOptions);
      }
export function useProductByIdV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlProductByIdV2Query, GqlProductByIdV2QueryVariables>) {
          return Apollo.useLazyQuery<GqlProductByIdV2Query, GqlProductByIdV2QueryVariables>(ProductByIdV2Document, baseOptions);
        }
export type ProductByIdV2QueryHookResult = ReturnType<typeof useProductByIdV2Query>;
export type ProductByIdV2LazyQueryHookResult = ReturnType<typeof useProductByIdV2LazyQuery>;
export type ProductByIdV2QueryResult = Apollo.QueryResult<GqlProductByIdV2Query, GqlProductByIdV2QueryVariables>;
export const LossReasonsDocument = gql`
    query lossReasons {
  loss_reasons {
    active
    loss_reason
    loss_reason_id
  }
}
    `;

/**
 * __useLossReasonsQuery__
 *
 * To run a query within a React component, call `useLossReasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLossReasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLossReasonsQuery({
 *   variables: {
 *   },
 * });
 */
export function useLossReasonsQuery(baseOptions?: Apollo.QueryHookOptions<GqlLossReasonsQuery, GqlLossReasonsQueryVariables>) {
        return Apollo.useQuery<GqlLossReasonsQuery, GqlLossReasonsQueryVariables>(LossReasonsDocument, baseOptions);
      }
export function useLossReasonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlLossReasonsQuery, GqlLossReasonsQueryVariables>) {
          return Apollo.useLazyQuery<GqlLossReasonsQuery, GqlLossReasonsQueryVariables>(LossReasonsDocument, baseOptions);
        }
export type LossReasonsQueryHookResult = ReturnType<typeof useLossReasonsQuery>;
export type LossReasonsLazyQueryHookResult = ReturnType<typeof useLossReasonsLazyQuery>;
export type LossReasonsQueryResult = Apollo.QueryResult<GqlLossReasonsQuery, GqlLossReasonsQueryVariables>;
export const ProductLossReasonsDocument = gql`
    query productLossReasons($filter: GenericScalar) {
  product_loss_reasons(filter: $filter) {
    edges {
      node {
        product_loss_reason_id
        loss_reason_id
        loss_reason {
          loss_reason
        }
        active
      }
    }
  }
}
    `;

/**
 * __useProductLossReasonsQuery__
 *
 * To run a query within a React component, call `useProductLossReasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductLossReasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductLossReasonsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useProductLossReasonsQuery(baseOptions?: Apollo.QueryHookOptions<GqlProductLossReasonsQuery, GqlProductLossReasonsQueryVariables>) {
        return Apollo.useQuery<GqlProductLossReasonsQuery, GqlProductLossReasonsQueryVariables>(ProductLossReasonsDocument, baseOptions);
      }
export function useProductLossReasonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlProductLossReasonsQuery, GqlProductLossReasonsQueryVariables>) {
          return Apollo.useLazyQuery<GqlProductLossReasonsQuery, GqlProductLossReasonsQueryVariables>(ProductLossReasonsDocument, baseOptions);
        }
export type ProductLossReasonsQueryHookResult = ReturnType<typeof useProductLossReasonsQuery>;
export type ProductLossReasonsLazyQueryHookResult = ReturnType<typeof useProductLossReasonsLazyQuery>;
export type ProductLossReasonsQueryResult = Apollo.QueryResult<GqlProductLossReasonsQuery, GqlProductLossReasonsQueryVariables>;
export const AddProductDomainActivationDocument = gql`
    mutation addProductDomainActivation($data: ProductDomainActivationInput!) {
  add_product_domain_activation(data: $data) {
    created
  }
}
    `;
export type GqlAddProductDomainActivationMutationFn = Apollo.MutationFunction<GqlAddProductDomainActivationMutation, GqlAddProductDomainActivationMutationVariables>;

/**
 * __useAddProductDomainActivationMutation__
 *
 * To run a mutation, you first call `useAddProductDomainActivationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProductDomainActivationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProductDomainActivationMutation, { data, loading, error }] = useAddProductDomainActivationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddProductDomainActivationMutation(baseOptions?: Apollo.MutationHookOptions<GqlAddProductDomainActivationMutation, GqlAddProductDomainActivationMutationVariables>) {
        return Apollo.useMutation<GqlAddProductDomainActivationMutation, GqlAddProductDomainActivationMutationVariables>(AddProductDomainActivationDocument, baseOptions);
      }
export type AddProductDomainActivationMutationHookResult = ReturnType<typeof useAddProductDomainActivationMutation>;
export type AddProductDomainActivationMutationResult = Apollo.MutationResult<GqlAddProductDomainActivationMutation>;
export type AddProductDomainActivationMutationOptions = Apollo.BaseMutationOptions<GqlAddProductDomainActivationMutation, GqlAddProductDomainActivationMutationVariables>;
export const UpdateProductDomainActivationDocument = gql`
    mutation updateProductDomainActivation($product_domain_activation_id: String!, $data: ProductDomainActivationInput!) {
  update_product_domain_activation(
    data: $data
    product_domain_activation_id: $product_domain_activation_id
  ) {
    product_domain_activation {
      product_id
      business_domain_id
      active
    }
  }
}
    `;
export type GqlUpdateProductDomainActivationMutationFn = Apollo.MutationFunction<GqlUpdateProductDomainActivationMutation, GqlUpdateProductDomainActivationMutationVariables>;

/**
 * __useUpdateProductDomainActivationMutation__
 *
 * To run a mutation, you first call `useUpdateProductDomainActivationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductDomainActivationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductDomainActivationMutation, { data, loading, error }] = useUpdateProductDomainActivationMutation({
 *   variables: {
 *      product_domain_activation_id: // value for 'product_domain_activation_id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateProductDomainActivationMutation(baseOptions?: Apollo.MutationHookOptions<GqlUpdateProductDomainActivationMutation, GqlUpdateProductDomainActivationMutationVariables>) {
        return Apollo.useMutation<GqlUpdateProductDomainActivationMutation, GqlUpdateProductDomainActivationMutationVariables>(UpdateProductDomainActivationDocument, baseOptions);
      }
export type UpdateProductDomainActivationMutationHookResult = ReturnType<typeof useUpdateProductDomainActivationMutation>;
export type UpdateProductDomainActivationMutationResult = Apollo.MutationResult<GqlUpdateProductDomainActivationMutation>;
export type UpdateProductDomainActivationMutationOptions = Apollo.BaseMutationOptions<GqlUpdateProductDomainActivationMutation, GqlUpdateProductDomainActivationMutationVariables>;
export const UploadProductImageDocument = gql`
    mutation uploadProductImage($image: Upload!, $product_id: String!) {
  add_product_image(product_id: $product_id, image: $image) {
    uploaded
    product {
      product_id
      image_url
    }
  }
}
    `;
export type GqlUploadProductImageMutationFn = Apollo.MutationFunction<GqlUploadProductImageMutation, GqlUploadProductImageMutationVariables>;

/**
 * __useUploadProductImageMutation__
 *
 * To run a mutation, you first call `useUploadProductImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadProductImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadProductImageMutation, { data, loading, error }] = useUploadProductImageMutation({
 *   variables: {
 *      image: // value for 'image'
 *      product_id: // value for 'product_id'
 *   },
 * });
 */
export function useUploadProductImageMutation(baseOptions?: Apollo.MutationHookOptions<GqlUploadProductImageMutation, GqlUploadProductImageMutationVariables>) {
        return Apollo.useMutation<GqlUploadProductImageMutation, GqlUploadProductImageMutationVariables>(UploadProductImageDocument, baseOptions);
      }
export type UploadProductImageMutationHookResult = ReturnType<typeof useUploadProductImageMutation>;
export type UploadProductImageMutationResult = Apollo.MutationResult<GqlUploadProductImageMutation>;
export type UploadProductImageMutationOptions = Apollo.BaseMutationOptions<GqlUploadProductImageMutation, GqlUploadProductImageMutationVariables>;
export const AddProductTagDocument = gql`
    mutation addProductTag($data: ProductTagInput!) {
  add_product_tag(data: $data) {
    created
    product_tag {
      product_tag
      product {
        product_name
      }
    }
  }
}
    `;
export type GqlAddProductTagMutationFn = Apollo.MutationFunction<GqlAddProductTagMutation, GqlAddProductTagMutationVariables>;

/**
 * __useAddProductTagMutation__
 *
 * To run a mutation, you first call `useAddProductTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProductTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProductTagMutation, { data, loading, error }] = useAddProductTagMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddProductTagMutation(baseOptions?: Apollo.MutationHookOptions<GqlAddProductTagMutation, GqlAddProductTagMutationVariables>) {
        return Apollo.useMutation<GqlAddProductTagMutation, GqlAddProductTagMutationVariables>(AddProductTagDocument, baseOptions);
      }
export type AddProductTagMutationHookResult = ReturnType<typeof useAddProductTagMutation>;
export type AddProductTagMutationResult = Apollo.MutationResult<GqlAddProductTagMutation>;
export type AddProductTagMutationOptions = Apollo.BaseMutationOptions<GqlAddProductTagMutation, GqlAddProductTagMutationVariables>;
export const DeleteProductTagDocument = gql`
    mutation deleteProductTag($product_tag_id: String!) {
  delete_product_tag(product_tag_id: $product_tag_id) {
    deleted
  }
}
    `;
export type GqlDeleteProductTagMutationFn = Apollo.MutationFunction<GqlDeleteProductTagMutation, GqlDeleteProductTagMutationVariables>;

/**
 * __useDeleteProductTagMutation__
 *
 * To run a mutation, you first call `useDeleteProductTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductTagMutation, { data, loading, error }] = useDeleteProductTagMutation({
 *   variables: {
 *      product_tag_id: // value for 'product_tag_id'
 *   },
 * });
 */
export function useDeleteProductTagMutation(baseOptions?: Apollo.MutationHookOptions<GqlDeleteProductTagMutation, GqlDeleteProductTagMutationVariables>) {
        return Apollo.useMutation<GqlDeleteProductTagMutation, GqlDeleteProductTagMutationVariables>(DeleteProductTagDocument, baseOptions);
      }
export type DeleteProductTagMutationHookResult = ReturnType<typeof useDeleteProductTagMutation>;
export type DeleteProductTagMutationResult = Apollo.MutationResult<GqlDeleteProductTagMutation>;
export type DeleteProductTagMutationOptions = Apollo.BaseMutationOptions<GqlDeleteProductTagMutation, GqlDeleteProductTagMutationVariables>;
export const AddProductBundleDocument = gql`
    mutation addProductBundle($data: [BundleAssociationInput]) {
  add_bundle_association(data: $data) {
    created
  }
}
    `;
export type GqlAddProductBundleMutationFn = Apollo.MutationFunction<GqlAddProductBundleMutation, GqlAddProductBundleMutationVariables>;

/**
 * __useAddProductBundleMutation__
 *
 * To run a mutation, you first call `useAddProductBundleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProductBundleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProductBundleMutation, { data, loading, error }] = useAddProductBundleMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddProductBundleMutation(baseOptions?: Apollo.MutationHookOptions<GqlAddProductBundleMutation, GqlAddProductBundleMutationVariables>) {
        return Apollo.useMutation<GqlAddProductBundleMutation, GqlAddProductBundleMutationVariables>(AddProductBundleDocument, baseOptions);
      }
export type AddProductBundleMutationHookResult = ReturnType<typeof useAddProductBundleMutation>;
export type AddProductBundleMutationResult = Apollo.MutationResult<GqlAddProductBundleMutation>;
export type AddProductBundleMutationOptions = Apollo.BaseMutationOptions<GqlAddProductBundleMutation, GqlAddProductBundleMutationVariables>;
export const DeleteBundleAssociationDocument = gql`
    mutation deleteBundleAssociation($association_id: String, $client_stamp: String) {
  delete_bundle_association(
    association_id: $association_id
    client_stamp: $client_stamp
  ) {
    deleted
  }
}
    `;
export type GqlDeleteBundleAssociationMutationFn = Apollo.MutationFunction<GqlDeleteBundleAssociationMutation, GqlDeleteBundleAssociationMutationVariables>;

/**
 * __useDeleteBundleAssociationMutation__
 *
 * To run a mutation, you first call `useDeleteBundleAssociationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBundleAssociationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBundleAssociationMutation, { data, loading, error }] = useDeleteBundleAssociationMutation({
 *   variables: {
 *      association_id: // value for 'association_id'
 *      client_stamp: // value for 'client_stamp'
 *   },
 * });
 */
export function useDeleteBundleAssociationMutation(baseOptions?: Apollo.MutationHookOptions<GqlDeleteBundleAssociationMutation, GqlDeleteBundleAssociationMutationVariables>) {
        return Apollo.useMutation<GqlDeleteBundleAssociationMutation, GqlDeleteBundleAssociationMutationVariables>(DeleteBundleAssociationDocument, baseOptions);
      }
export type DeleteBundleAssociationMutationHookResult = ReturnType<typeof useDeleteBundleAssociationMutation>;
export type DeleteBundleAssociationMutationResult = Apollo.MutationResult<GqlDeleteBundleAssociationMutation>;
export type DeleteBundleAssociationMutationOptions = Apollo.BaseMutationOptions<GqlDeleteBundleAssociationMutation, GqlDeleteBundleAssociationMutationVariables>;
export const GetBundleAssociationDocument = gql`
    query getBundleAssociation($product_item_id: String!) {
  bundle_association_v2_by_sku(product_item_id: $product_item_id) {
    association_id
    quantity
    discount
    client_stamp
    parent_product_item_id
    parent_sku {
      product_item_name
      product {
        product_name
        product_id
      }
    }
    child_product_item_id
    child_sku {
      product_item_id
      product_item_name
      product {
        product_id
        product_name
      }
    }
  }
}
    `;

/**
 * __useGetBundleAssociationQuery__
 *
 * To run a query within a React component, call `useGetBundleAssociationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBundleAssociationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBundleAssociationQuery({
 *   variables: {
 *      product_item_id: // value for 'product_item_id'
 *   },
 * });
 */
export function useGetBundleAssociationQuery(baseOptions: Apollo.QueryHookOptions<GqlGetBundleAssociationQuery, GqlGetBundleAssociationQueryVariables>) {
        return Apollo.useQuery<GqlGetBundleAssociationQuery, GqlGetBundleAssociationQueryVariables>(GetBundleAssociationDocument, baseOptions);
      }
export function useGetBundleAssociationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlGetBundleAssociationQuery, GqlGetBundleAssociationQueryVariables>) {
          return Apollo.useLazyQuery<GqlGetBundleAssociationQuery, GqlGetBundleAssociationQueryVariables>(GetBundleAssociationDocument, baseOptions);
        }
export type GetBundleAssociationQueryHookResult = ReturnType<typeof useGetBundleAssociationQuery>;
export type GetBundleAssociationLazyQueryHookResult = ReturnType<typeof useGetBundleAssociationLazyQuery>;
export type GetBundleAssociationQueryResult = Apollo.QueryResult<GqlGetBundleAssociationQuery, GqlGetBundleAssociationQueryVariables>;
export const Uoms_V2Document = gql`
    query uoms_v2($page: Int, $per_page: Int, $search: String) {
  uoms_v2(per_page: $per_page, page: $page, search: $search) {
    page_info {
      has_next
      has_previous
      total_pages
      total_count
      current_page
      per_page
    }
    edges {
      node {
        uom_id
        name
        etims_quantity_code
        description
        abbreviation
        active
      }
    }
  }
}
    `;

/**
 * __useUoms_V2Query__
 *
 * To run a query within a React component, call `useUoms_V2Query` and pass it any options that fit your needs.
 * When your component renders, `useUoms_V2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUoms_V2Query({
 *   variables: {
 *      page: // value for 'page'
 *      per_page: // value for 'per_page'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useUoms_V2Query(baseOptions?: Apollo.QueryHookOptions<GqlUoms_V2Query, GqlUoms_V2QueryVariables>) {
        return Apollo.useQuery<GqlUoms_V2Query, GqlUoms_V2QueryVariables>(Uoms_V2Document, baseOptions);
      }
export function useUoms_V2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlUoms_V2Query, GqlUoms_V2QueryVariables>) {
          return Apollo.useLazyQuery<GqlUoms_V2Query, GqlUoms_V2QueryVariables>(Uoms_V2Document, baseOptions);
        }
export type Uoms_V2QueryHookResult = ReturnType<typeof useUoms_V2Query>;
export type Uoms_V2LazyQueryHookResult = ReturnType<typeof useUoms_V2LazyQuery>;
export type Uoms_V2QueryResult = Apollo.QueryResult<GqlUoms_V2Query, GqlUoms_V2QueryVariables>;
export const Add_UomDocument = gql`
    mutation add_uom($name: String!, $abbreviation: String, $description: String, $etims_quantity_code: String) {
  add_uom(
    data: {name: $name, abbreviation: $abbreviation, description: $description, etims_quantity_code: $etims_quantity_code}
  ) {
    uom {
      uom_id
      name
      abbreviation
      description
    }
    created
  }
}
    `;
export type GqlAdd_UomMutationFn = Apollo.MutationFunction<GqlAdd_UomMutation, GqlAdd_UomMutationVariables>;

/**
 * __useAdd_UomMutation__
 *
 * To run a mutation, you first call `useAdd_UomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdd_UomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUomMutation, { data, loading, error }] = useAdd_UomMutation({
 *   variables: {
 *      name: // value for 'name'
 *      abbreviation: // value for 'abbreviation'
 *      description: // value for 'description'
 *      etims_quantity_code: // value for 'etims_quantity_code'
 *   },
 * });
 */
export function useAdd_UomMutation(baseOptions?: Apollo.MutationHookOptions<GqlAdd_UomMutation, GqlAdd_UomMutationVariables>) {
        return Apollo.useMutation<GqlAdd_UomMutation, GqlAdd_UomMutationVariables>(Add_UomDocument, baseOptions);
      }
export type Add_UomMutationHookResult = ReturnType<typeof useAdd_UomMutation>;
export type Add_UomMutationResult = Apollo.MutationResult<GqlAdd_UomMutation>;
export type Add_UomMutationOptions = Apollo.BaseMutationOptions<GqlAdd_UomMutation, GqlAdd_UomMutationVariables>;
export const Update_UomDocument = gql`
    mutation update_uom($uom_id: String!, $data: UOMInput!) {
  update_uom(uom_id: $uom_id, data: $data) {
    uom {
      uom_id
      name
      active
      abbreviation
      description
    }
  }
}
    `;
export type GqlUpdate_UomMutationFn = Apollo.MutationFunction<GqlUpdate_UomMutation, GqlUpdate_UomMutationVariables>;

/**
 * __useUpdate_UomMutation__
 *
 * To run a mutation, you first call `useUpdate_UomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_UomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUomMutation, { data, loading, error }] = useUpdate_UomMutation({
 *   variables: {
 *      uom_id: // value for 'uom_id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdate_UomMutation(baseOptions?: Apollo.MutationHookOptions<GqlUpdate_UomMutation, GqlUpdate_UomMutationVariables>) {
        return Apollo.useMutation<GqlUpdate_UomMutation, GqlUpdate_UomMutationVariables>(Update_UomDocument, baseOptions);
      }
export type Update_UomMutationHookResult = ReturnType<typeof useUpdate_UomMutation>;
export type Update_UomMutationResult = Apollo.MutationResult<GqlUpdate_UomMutation>;
export type Update_UomMutationOptions = Apollo.BaseMutationOptions<GqlUpdate_UomMutation, GqlUpdate_UomMutationVariables>;
export const BookingSummaryDocument = gql`
    query bookingSummary($search: String, $per_page: Int, $page: Int, $bookingSummaryFilters: GenericScalar) {
  booking_summary(
    search: $search
    per_page: $per_page
    page: $page
    filters: $bookingSummaryFilters
  ) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        harvest_date
        booking_date
        product_id
        product_item_id
        product_item {
          product_item_name
          product {
            product_name
          }
        }
        supplier_name
        unit_price
        quantity
        weight
        amount
        created_by
        created_by_user {
          first_name
          last_name
        }
        collection_center_id
        collection_center_name
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useBookingSummaryQuery__
 *
 * To run a query within a React component, call `useBookingSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingSummaryQuery({
 *   variables: {
 *      search: // value for 'search'
 *      per_page: // value for 'per_page'
 *      page: // value for 'page'
 *      bookingSummaryFilters: // value for 'bookingSummaryFilters'
 *   },
 * });
 */
export function useBookingSummaryQuery(baseOptions?: Apollo.QueryHookOptions<GqlBookingSummaryQuery, GqlBookingSummaryQueryVariables>) {
        return Apollo.useQuery<GqlBookingSummaryQuery, GqlBookingSummaryQueryVariables>(BookingSummaryDocument, baseOptions);
      }
export function useBookingSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlBookingSummaryQuery, GqlBookingSummaryQueryVariables>) {
          return Apollo.useLazyQuery<GqlBookingSummaryQuery, GqlBookingSummaryQueryVariables>(BookingSummaryDocument, baseOptions);
        }
export type BookingSummaryQueryHookResult = ReturnType<typeof useBookingSummaryQuery>;
export type BookingSummaryLazyQueryHookResult = ReturnType<typeof useBookingSummaryLazyQuery>;
export type BookingSummaryQueryResult = Apollo.QueryResult<GqlBookingSummaryQuery, GqlBookingSummaryQueryVariables>;
export const BookingSummaryTotalsDocument = gql`
    query bookingSummaryTotals($product_id: [String], $product_item_id: [String], $harvest_date: [String], $collection_center_id: [String], $created_by: String) {
  booking_summary_totals(
    product_id: $product_id
    product_item_id: $product_item_id
    harvest_date: $harvest_date
    collection_center_id: $collection_center_id
    created_by: $created_by
  ) {
    quantity
    weight
    amount
  }
}
    `;

/**
 * __useBookingSummaryTotalsQuery__
 *
 * To run a query within a React component, call `useBookingSummaryTotalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingSummaryTotalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingSummaryTotalsQuery({
 *   variables: {
 *      product_id: // value for 'product_id'
 *      product_item_id: // value for 'product_item_id'
 *      harvest_date: // value for 'harvest_date'
 *      collection_center_id: // value for 'collection_center_id'
 *      created_by: // value for 'created_by'
 *   },
 * });
 */
export function useBookingSummaryTotalsQuery(baseOptions?: Apollo.QueryHookOptions<GqlBookingSummaryTotalsQuery, GqlBookingSummaryTotalsQueryVariables>) {
        return Apollo.useQuery<GqlBookingSummaryTotalsQuery, GqlBookingSummaryTotalsQueryVariables>(BookingSummaryTotalsDocument, baseOptions);
      }
export function useBookingSummaryTotalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlBookingSummaryTotalsQuery, GqlBookingSummaryTotalsQueryVariables>) {
          return Apollo.useLazyQuery<GqlBookingSummaryTotalsQuery, GqlBookingSummaryTotalsQueryVariables>(BookingSummaryTotalsDocument, baseOptions);
        }
export type BookingSummaryTotalsQueryHookResult = ReturnType<typeof useBookingSummaryTotalsQuery>;
export type BookingSummaryTotalsLazyQueryHookResult = ReturnType<typeof useBookingSummaryTotalsLazyQuery>;
export type BookingSummaryTotalsQueryResult = Apollo.QueryResult<GqlBookingSummaryTotalsQuery, GqlBookingSummaryTotalsQueryVariables>;
export const BookingSummaryUnpaginatedDocument = gql`
    query bookingSummaryUnpaginated($product_id: [String], $product_item_id: [String], $harvest_date: [String], $collection_center_id: [String]) {
  booking_summary_unpaginated(
    product_id: $product_id
    product_item_id: $product_item_id
    harvest_date: $harvest_date
    collection_center_id: $collection_center_id
  ) {
    harvest_date
    booking_date
    product_id
    product_item_id
    unit_price
    quantity
    weight
    amount
    created_by
    collection_center_id
    collection_center_name
    product_id
    product_item_id
    product_item {
      product_item_name
      product {
        product_name
      }
    }
    created_by_user {
      first_name
      last_name
    }
    supplier_name
  }
}
    `;

/**
 * __useBookingSummaryUnpaginatedQuery__
 *
 * To run a query within a React component, call `useBookingSummaryUnpaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingSummaryUnpaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingSummaryUnpaginatedQuery({
 *   variables: {
 *      product_id: // value for 'product_id'
 *      product_item_id: // value for 'product_item_id'
 *      harvest_date: // value for 'harvest_date'
 *      collection_center_id: // value for 'collection_center_id'
 *   },
 * });
 */
export function useBookingSummaryUnpaginatedQuery(baseOptions?: Apollo.QueryHookOptions<GqlBookingSummaryUnpaginatedQuery, GqlBookingSummaryUnpaginatedQueryVariables>) {
        return Apollo.useQuery<GqlBookingSummaryUnpaginatedQuery, GqlBookingSummaryUnpaginatedQueryVariables>(BookingSummaryUnpaginatedDocument, baseOptions);
      }
export function useBookingSummaryUnpaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlBookingSummaryUnpaginatedQuery, GqlBookingSummaryUnpaginatedQueryVariables>) {
          return Apollo.useLazyQuery<GqlBookingSummaryUnpaginatedQuery, GqlBookingSummaryUnpaginatedQueryVariables>(BookingSummaryUnpaginatedDocument, baseOptions);
        }
export type BookingSummaryUnpaginatedQueryHookResult = ReturnType<typeof useBookingSummaryUnpaginatedQuery>;
export type BookingSummaryUnpaginatedLazyQueryHookResult = ReturnType<typeof useBookingSummaryUnpaginatedLazyQuery>;
export type BookingSummaryUnpaginatedQueryResult = Apollo.QueryResult<GqlBookingSummaryUnpaginatedQuery, GqlBookingSummaryUnpaginatedQueryVariables>;
export const CollectionCentersDocument = gql`
    query collectionCenters($filter: GenericScalar) {
  collection_centers(filter: $filter) {
    collection_center_name
    collection_center_id
  }
}
    `;

/**
 * __useCollectionCentersQuery__
 *
 * To run a query within a React component, call `useCollectionCentersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectionCentersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectionCentersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCollectionCentersQuery(baseOptions?: Apollo.QueryHookOptions<GqlCollectionCentersQuery, GqlCollectionCentersQueryVariables>) {
        return Apollo.useQuery<GqlCollectionCentersQuery, GqlCollectionCentersQueryVariables>(CollectionCentersDocument, baseOptions);
      }
export function useCollectionCentersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlCollectionCentersQuery, GqlCollectionCentersQueryVariables>) {
          return Apollo.useLazyQuery<GqlCollectionCentersQuery, GqlCollectionCentersQueryVariables>(CollectionCentersDocument, baseOptions);
        }
export type CollectionCentersQueryHookResult = ReturnType<typeof useCollectionCentersQuery>;
export type CollectionCentersLazyQueryHookResult = ReturnType<typeof useCollectionCentersLazyQuery>;
export type CollectionCentersQueryResult = Apollo.QueryResult<GqlCollectionCentersQuery, GqlCollectionCentersQueryVariables>;
export const DailyOtifDocument = gql`
    query dailyOtif($search: String, $per_page: Int, $page: Int, $dailyOtifFilters: GenericScalar) {
  daily_otif(
    search: $search
    per_page: $per_page
    page: $page
    filters: $dailyOtifFilters
  ) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        delivery_date
        region_name
        depot_name
        route_name
        shop_name
        phone_number
        product_item_id
        product_item {
          product_item_name
          product {
            product_id
            product_name
          }
        }
        source
        delivery_time
        payment_mode
        ordered_quantity
        delivered_quantity
        ordered_amount
        delivery_amount
        fill_rate
        on_time
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useDailyOtifQuery__
 *
 * To run a query within a React component, call `useDailyOtifQuery` and pass it any options that fit your needs.
 * When your component renders, `useDailyOtifQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDailyOtifQuery({
 *   variables: {
 *      search: // value for 'search'
 *      per_page: // value for 'per_page'
 *      page: // value for 'page'
 *      dailyOtifFilters: // value for 'dailyOtifFilters'
 *   },
 * });
 */
export function useDailyOtifQuery(baseOptions?: Apollo.QueryHookOptions<GqlDailyOtifQuery, GqlDailyOtifQueryVariables>) {
        return Apollo.useQuery<GqlDailyOtifQuery, GqlDailyOtifQueryVariables>(DailyOtifDocument, baseOptions);
      }
export function useDailyOtifLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlDailyOtifQuery, GqlDailyOtifQueryVariables>) {
          return Apollo.useLazyQuery<GqlDailyOtifQuery, GqlDailyOtifQueryVariables>(DailyOtifDocument, baseOptions);
        }
export type DailyOtifQueryHookResult = ReturnType<typeof useDailyOtifQuery>;
export type DailyOtifLazyQueryHookResult = ReturnType<typeof useDailyOtifLazyQuery>;
export type DailyOtifQueryResult = Apollo.QueryResult<GqlDailyOtifQuery, GqlDailyOtifQueryVariables>;
export const DailyOtifUnpaginatedDocument = gql`
    query dailyOtifUnpaginated($start_date: String, $end_date: String, $region_id: String, $depot_id: String, $route_id: String, $category_id: String, $product_segment_id: String, $product_id: String, $payment_mode: String, $on_time: String) {
  daily_otif_unpaginated(
    start_date: $start_date
    end_date: $end_date
    region_id: $region_id
    depot_id: $depot_id
    route_id: $route_id
    category_id: $category_id
    product_segment_id: $product_segment_id
    product_id: $product_id
    payment_mode: $payment_mode
    on_time: $on_time
  ) {
    delivery_date
    region_name
    depot_name
    route_name
    shop_name
    phone_number
    product_item_id
    product_item {
      product_item_name
      product {
        product_id
        product_name
      }
    }
    source
    delivery_time
    payment_mode
    ordered_quantity
    delivered_quantity
    ordered_amount
    delivery_amount
    fill_rate
    on_time
  }
}
    `;

/**
 * __useDailyOtifUnpaginatedQuery__
 *
 * To run a query within a React component, call `useDailyOtifUnpaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useDailyOtifUnpaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDailyOtifUnpaginatedQuery({
 *   variables: {
 *      start_date: // value for 'start_date'
 *      end_date: // value for 'end_date'
 *      region_id: // value for 'region_id'
 *      depot_id: // value for 'depot_id'
 *      route_id: // value for 'route_id'
 *      category_id: // value for 'category_id'
 *      product_segment_id: // value for 'product_segment_id'
 *      product_id: // value for 'product_id'
 *      payment_mode: // value for 'payment_mode'
 *      on_time: // value for 'on_time'
 *   },
 * });
 */
export function useDailyOtifUnpaginatedQuery(baseOptions?: Apollo.QueryHookOptions<GqlDailyOtifUnpaginatedQuery, GqlDailyOtifUnpaginatedQueryVariables>) {
        return Apollo.useQuery<GqlDailyOtifUnpaginatedQuery, GqlDailyOtifUnpaginatedQueryVariables>(DailyOtifUnpaginatedDocument, baseOptions);
      }
export function useDailyOtifUnpaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlDailyOtifUnpaginatedQuery, GqlDailyOtifUnpaginatedQueryVariables>) {
          return Apollo.useLazyQuery<GqlDailyOtifUnpaginatedQuery, GqlDailyOtifUnpaginatedQueryVariables>(DailyOtifUnpaginatedDocument, baseOptions);
        }
export type DailyOtifUnpaginatedQueryHookResult = ReturnType<typeof useDailyOtifUnpaginatedQuery>;
export type DailyOtifUnpaginatedLazyQueryHookResult = ReturnType<typeof useDailyOtifUnpaginatedLazyQuery>;
export type DailyOtifUnpaginatedQueryResult = Apollo.QueryResult<GqlDailyOtifUnpaginatedQuery, GqlDailyOtifUnpaginatedQueryVariables>;
export const DailyOtifSummaryDocument = gql`
    query dailyOtifSummary($start_date: String, $end_date: String, $region_id: String, $depot_id: String, $route_id: String, $category_id: String, $product_segment_id: String, $product_id: String, $payment_mode: String, $on_time: String) {
  daily_otif_summary(
    start_date: $start_date
    end_date: $end_date
    region_id: $region_id
    depot_id: $depot_id
    route_id: $route_id
    category_id: $category_id
    product_segment_id: $product_segment_id
    product_id: $product_id
    payment_mode: $payment_mode
    on_time: $on_time
  ) {
    ordered_amount
    ordered_quantity
    delivery_amount
    delivered_quantity
    on_time
    in_full
    otif
  }
}
    `;

/**
 * __useDailyOtifSummaryQuery__
 *
 * To run a query within a React component, call `useDailyOtifSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useDailyOtifSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDailyOtifSummaryQuery({
 *   variables: {
 *      start_date: // value for 'start_date'
 *      end_date: // value for 'end_date'
 *      region_id: // value for 'region_id'
 *      depot_id: // value for 'depot_id'
 *      route_id: // value for 'route_id'
 *      category_id: // value for 'category_id'
 *      product_segment_id: // value for 'product_segment_id'
 *      product_id: // value for 'product_id'
 *      payment_mode: // value for 'payment_mode'
 *      on_time: // value for 'on_time'
 *   },
 * });
 */
export function useDailyOtifSummaryQuery(baseOptions?: Apollo.QueryHookOptions<GqlDailyOtifSummaryQuery, GqlDailyOtifSummaryQueryVariables>) {
        return Apollo.useQuery<GqlDailyOtifSummaryQuery, GqlDailyOtifSummaryQueryVariables>(DailyOtifSummaryDocument, baseOptions);
      }
export function useDailyOtifSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlDailyOtifSummaryQuery, GqlDailyOtifSummaryQueryVariables>) {
          return Apollo.useLazyQuery<GqlDailyOtifSummaryQuery, GqlDailyOtifSummaryQueryVariables>(DailyOtifSummaryDocument, baseOptions);
        }
export type DailyOtifSummaryQueryHookResult = ReturnType<typeof useDailyOtifSummaryQuery>;
export type DailyOtifSummaryLazyQueryHookResult = ReturnType<typeof useDailyOtifSummaryLazyQuery>;
export type DailyOtifSummaryQueryResult = Apollo.QueryResult<GqlDailyOtifSummaryQuery, GqlDailyOtifSummaryQueryVariables>;
export const DeliveryManifestDocument = gql`
    query deliveryManifest($start_date: String!, $end_date: String!, $depot_ids: [String], $product_types: [String], $search: String, $per_page: Int, $page: Int) {
  ordered_stock_allocation(
    search: $search
    per_page: $per_page
    page: $page
    start_date: $start_date
    end_date: $end_date
    depot_ids: $depot_ids
    product_types: $product_types
  ) {
    page_info {
      current_page
      per_page
      has_next
      has_previous
      total_pages
      total_count
    }
    edges {
      node {
        delivery_date
        depot_name
        route_plan_id
        product_item_id
        unit_of_measure_id
        ordered_quantity
        ordered_weight
        ordered_amount
        product_item {
          product_item_id
          product_item_name
          product {
            product_name
          }
        }
        route_plan {
          route_plan_id
          vehicle {
            registration_number
          }
        }
        uom {
          uom_id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useDeliveryManifestQuery__
 *
 * To run a query within a React component, call `useDeliveryManifestQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeliveryManifestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeliveryManifestQuery({
 *   variables: {
 *      start_date: // value for 'start_date'
 *      end_date: // value for 'end_date'
 *      depot_ids: // value for 'depot_ids'
 *      product_types: // value for 'product_types'
 *      search: // value for 'search'
 *      per_page: // value for 'per_page'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useDeliveryManifestQuery(baseOptions: Apollo.QueryHookOptions<GqlDeliveryManifestQuery, GqlDeliveryManifestQueryVariables>) {
        return Apollo.useQuery<GqlDeliveryManifestQuery, GqlDeliveryManifestQueryVariables>(DeliveryManifestDocument, baseOptions);
      }
export function useDeliveryManifestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlDeliveryManifestQuery, GqlDeliveryManifestQueryVariables>) {
          return Apollo.useLazyQuery<GqlDeliveryManifestQuery, GqlDeliveryManifestQueryVariables>(DeliveryManifestDocument, baseOptions);
        }
export type DeliveryManifestQueryHookResult = ReturnType<typeof useDeliveryManifestQuery>;
export type DeliveryManifestLazyQueryHookResult = ReturnType<typeof useDeliveryManifestLazyQuery>;
export type DeliveryManifestQueryResult = Apollo.QueryResult<GqlDeliveryManifestQuery, GqlDeliveryManifestQueryVariables>;
export const ManifestSummaryDocument = gql`
    query manifestSummary($start_date: String!, $end_date: String!, $depot_ids: [String], $product_types: [String]) {
  delivery_vehicle_manifest_summary(
    start_date: $start_date
    end_date: $end_date
    depot_ids: $depot_ids
    product_types: $product_types
  ) {
    total_ordered_quantity
    total_ordered_weight
    total_ordered_amount
  }
}
    `;

/**
 * __useManifestSummaryQuery__
 *
 * To run a query within a React component, call `useManifestSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useManifestSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManifestSummaryQuery({
 *   variables: {
 *      start_date: // value for 'start_date'
 *      end_date: // value for 'end_date'
 *      depot_ids: // value for 'depot_ids'
 *      product_types: // value for 'product_types'
 *   },
 * });
 */
export function useManifestSummaryQuery(baseOptions: Apollo.QueryHookOptions<GqlManifestSummaryQuery, GqlManifestSummaryQueryVariables>) {
        return Apollo.useQuery<GqlManifestSummaryQuery, GqlManifestSummaryQueryVariables>(ManifestSummaryDocument, baseOptions);
      }
export function useManifestSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlManifestSummaryQuery, GqlManifestSummaryQueryVariables>) {
          return Apollo.useLazyQuery<GqlManifestSummaryQuery, GqlManifestSummaryQueryVariables>(ManifestSummaryDocument, baseOptions);
        }
export type ManifestSummaryQueryHookResult = ReturnType<typeof useManifestSummaryQuery>;
export type ManifestSummaryLazyQueryHookResult = ReturnType<typeof useManifestSummaryLazyQuery>;
export type ManifestSummaryQueryResult = Apollo.QueryResult<GqlManifestSummaryQuery, GqlManifestSummaryQueryVariables>;
export const Unpaginated_Order_Stock_AllocationDocument = gql`
    query unpaginated_order_stock_allocation($start_date: String!, $end_date: String!, $depot_ids: [String], $product_types: [String]) {
  unpaginated_ordered_stock_allocation(
    start_date: $start_date
    end_date: $end_date
    depot_ids: $depot_ids
    product_types: $product_types
  ) {
    delivery_date
    depot_name
    route_plan_id
    product_item_id
    unit_of_measure_id
    ordered_quantity
    ordered_weight
    ordered_amount
    product_item {
      product_item_id
      product_item_name
      product {
        product_name
      }
    }
    route_plan {
      vehicle {
        registration_number
      }
    }
    uom {
      uom_id
      name
    }
  }
}
    `;

/**
 * __useUnpaginated_Order_Stock_AllocationQuery__
 *
 * To run a query within a React component, call `useUnpaginated_Order_Stock_AllocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnpaginated_Order_Stock_AllocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnpaginated_Order_Stock_AllocationQuery({
 *   variables: {
 *      start_date: // value for 'start_date'
 *      end_date: // value for 'end_date'
 *      depot_ids: // value for 'depot_ids'
 *      product_types: // value for 'product_types'
 *   },
 * });
 */
export function useUnpaginated_Order_Stock_AllocationQuery(baseOptions: Apollo.QueryHookOptions<GqlUnpaginated_Order_Stock_AllocationQuery, GqlUnpaginated_Order_Stock_AllocationQueryVariables>) {
        return Apollo.useQuery<GqlUnpaginated_Order_Stock_AllocationQuery, GqlUnpaginated_Order_Stock_AllocationQueryVariables>(Unpaginated_Order_Stock_AllocationDocument, baseOptions);
      }
export function useUnpaginated_Order_Stock_AllocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlUnpaginated_Order_Stock_AllocationQuery, GqlUnpaginated_Order_Stock_AllocationQueryVariables>) {
          return Apollo.useLazyQuery<GqlUnpaginated_Order_Stock_AllocationQuery, GqlUnpaginated_Order_Stock_AllocationQueryVariables>(Unpaginated_Order_Stock_AllocationDocument, baseOptions);
        }
export type Unpaginated_Order_Stock_AllocationQueryHookResult = ReturnType<typeof useUnpaginated_Order_Stock_AllocationQuery>;
export type Unpaginated_Order_Stock_AllocationLazyQueryHookResult = ReturnType<typeof useUnpaginated_Order_Stock_AllocationLazyQuery>;
export type Unpaginated_Order_Stock_AllocationQueryResult = Apollo.QueryResult<GqlUnpaginated_Order_Stock_AllocationQuery, GqlUnpaginated_Order_Stock_AllocationQueryVariables>;
export const ProductExpiryDocument = gql`
    query productExpiry($search: String, $per_page: Int, $page: Int, $productExpiryFilters: GenericScalar) {
  product_expiry(
    search: $search
    per_page: $per_page
    page: $page
    filters: $productExpiryFilters
  ) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        storage_location_id
        storage_location_number
        location_type_name
        asset_number
        stock_availability_status
        product_item_id
        product_item {
          product_item_name
          product {
            product_id
            product_name
            category {
              category_name
            }
          }
        }
        stock_date
        order_by_date
        stock_age
        expiry_duration
        hui_count
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useProductExpiryQuery__
 *
 * To run a query within a React component, call `useProductExpiryQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductExpiryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductExpiryQuery({
 *   variables: {
 *      search: // value for 'search'
 *      per_page: // value for 'per_page'
 *      page: // value for 'page'
 *      productExpiryFilters: // value for 'productExpiryFilters'
 *   },
 * });
 */
export function useProductExpiryQuery(baseOptions?: Apollo.QueryHookOptions<GqlProductExpiryQuery, GqlProductExpiryQueryVariables>) {
        return Apollo.useQuery<GqlProductExpiryQuery, GqlProductExpiryQueryVariables>(ProductExpiryDocument, baseOptions);
      }
export function useProductExpiryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlProductExpiryQuery, GqlProductExpiryQueryVariables>) {
          return Apollo.useLazyQuery<GqlProductExpiryQuery, GqlProductExpiryQueryVariables>(ProductExpiryDocument, baseOptions);
        }
export type ProductExpiryQueryHookResult = ReturnType<typeof useProductExpiryQuery>;
export type ProductExpiryLazyQueryHookResult = ReturnType<typeof useProductExpiryLazyQuery>;
export type ProductExpiryQueryResult = Apollo.QueryResult<GqlProductExpiryQuery, GqlProductExpiryQueryVariables>;
export const ProductExpiryUnpaginatedDocument = gql`
    query productExpiryUnpaginated($storage_location_number: [String], $location_type_id: [String], $product_id: [String], $category_id: [String], $product_item_id: [String], $asset_number: [String], $stock_age: Int, $expiry_duration: Int) {
  product_expiry_unpaginated(
    storage_location_number: $storage_location_number
    location_type_id: $location_type_id
    category_id: $category_id
    product_id: $product_id
    product_item_id: $product_item_id
    asset_number: $asset_number
    stock_age: $stock_age
    expiry_duration: $expiry_duration
  ) {
    storage_location_id
    storage_location_number
    location_type_name
    asset_number
    stock_availability_status
    product_item_id
    product_item {
      product_item_name
      product {
        product_id
        product_name
        category {
          category_name
        }
      }
    }
    stock_date
    order_by_date
    stock_age
    expiry_duration
    hui_count
  }
}
    `;

/**
 * __useProductExpiryUnpaginatedQuery__
 *
 * To run a query within a React component, call `useProductExpiryUnpaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductExpiryUnpaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductExpiryUnpaginatedQuery({
 *   variables: {
 *      storage_location_number: // value for 'storage_location_number'
 *      location_type_id: // value for 'location_type_id'
 *      product_id: // value for 'product_id'
 *      category_id: // value for 'category_id'
 *      product_item_id: // value for 'product_item_id'
 *      asset_number: // value for 'asset_number'
 *      stock_age: // value for 'stock_age'
 *      expiry_duration: // value for 'expiry_duration'
 *   },
 * });
 */
export function useProductExpiryUnpaginatedQuery(baseOptions?: Apollo.QueryHookOptions<GqlProductExpiryUnpaginatedQuery, GqlProductExpiryUnpaginatedQueryVariables>) {
        return Apollo.useQuery<GqlProductExpiryUnpaginatedQuery, GqlProductExpiryUnpaginatedQueryVariables>(ProductExpiryUnpaginatedDocument, baseOptions);
      }
export function useProductExpiryUnpaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlProductExpiryUnpaginatedQuery, GqlProductExpiryUnpaginatedQueryVariables>) {
          return Apollo.useLazyQuery<GqlProductExpiryUnpaginatedQuery, GqlProductExpiryUnpaginatedQueryVariables>(ProductExpiryUnpaginatedDocument, baseOptions);
        }
export type ProductExpiryUnpaginatedQueryHookResult = ReturnType<typeof useProductExpiryUnpaginatedQuery>;
export type ProductExpiryUnpaginatedLazyQueryHookResult = ReturnType<typeof useProductExpiryUnpaginatedLazyQuery>;
export type ProductExpiryUnpaginatedQueryResult = Apollo.QueryResult<GqlProductExpiryUnpaginatedQuery, GqlProductExpiryUnpaginatedQueryVariables>;
export const Purchases_Vs_IntakesDocument = gql`
    query purchases_vs_intakes($page: Int, $per_page: Int, $search: String, $filters: GenericScalar) {
  harvest_vs_intake(
    filters: $filters
    per_page: $per_page
    page: $page
    search: $search
  ) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        harvest_date
        product_item {
          product_item_name
          product {
            product_name
          }
        }
        batch_id
        batch {
          batch_name
          batch_date
          field_weight
          intake_weight
          processing_loss_weight
        }
        invoice_number
        control_unit_invoice_number
        amount
        harvested_weight
        harvested_quantity
        buying_price_uom
        buying_price_kg
        product_item_id
        stock_intake_summary {
          stock_intake_number
          distribution_center_name
          truck_number
          intake_weight
          intake_quantity
          intake_variance_kg
          intake_variance_uom
          net_variance_uom
          rejected_quantity
        }
        farmer {
          farmer_type
          supplier_name
        }
        collection_center {
          collection_center_name
          area {
            area_name
          }
        }
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __usePurchases_Vs_IntakesQuery__
 *
 * To run a query within a React component, call `usePurchases_Vs_IntakesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurchases_Vs_IntakesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePurchases_Vs_IntakesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      per_page: // value for 'per_page'
 *      search: // value for 'search'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function usePurchases_Vs_IntakesQuery(baseOptions?: Apollo.QueryHookOptions<GqlPurchases_Vs_IntakesQuery, GqlPurchases_Vs_IntakesQueryVariables>) {
        return Apollo.useQuery<GqlPurchases_Vs_IntakesQuery, GqlPurchases_Vs_IntakesQueryVariables>(Purchases_Vs_IntakesDocument, baseOptions);
      }
export function usePurchases_Vs_IntakesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlPurchases_Vs_IntakesQuery, GqlPurchases_Vs_IntakesQueryVariables>) {
          return Apollo.useLazyQuery<GqlPurchases_Vs_IntakesQuery, GqlPurchases_Vs_IntakesQueryVariables>(Purchases_Vs_IntakesDocument, baseOptions);
        }
export type Purchases_Vs_IntakesQueryHookResult = ReturnType<typeof usePurchases_Vs_IntakesQuery>;
export type Purchases_Vs_IntakesLazyQueryHookResult = ReturnType<typeof usePurchases_Vs_IntakesLazyQuery>;
export type Purchases_Vs_IntakesQueryResult = Apollo.QueryResult<GqlPurchases_Vs_IntakesQuery, GqlPurchases_Vs_IntakesQueryVariables>;
export const GetUnpaginatedPurchasesDocument = gql`
    query getUnpaginatedPurchases($start_date: String, $end_date: String, $batch_id: String, $product_id: String, $product_item_id: String, $product_type: String) {
  harvest_vs_intake_unpaginated(
    start_date: $start_date
    end_date: $end_date
    batch_id: $batch_id
    product_id: $product_id
    product_item_id: $product_item_id
    product_type: $product_type
  ) {
    harvest_date
    product_item {
      product_item_name
      product {
        product_name
      }
    }
    batch_id
    batch {
      batch_name
      batch_date
      field_weight
      intake_weight
      processing_loss_weight
    }
    invoice_number
    control_unit_invoice_number
    amount
    harvested_weight
    harvested_quantity
    buying_price_uom
    buying_price_kg
    product_item_id
    stock_intake_summary {
      stock_intake_number
      distribution_center_name
      truck_number
      intake_weight
      intake_quantity
      intake_variance_kg
      intake_variance_uom
      net_variance_uom
      rejected_quantity
    }
    farmer {
      farmer_type
      supplier_name
    }
    collection_center {
      collection_center_name
      area {
        area_name
      }
    }
  }
}
    `;

/**
 * __useGetUnpaginatedPurchasesQuery__
 *
 * To run a query within a React component, call `useGetUnpaginatedPurchasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnpaginatedPurchasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnpaginatedPurchasesQuery({
 *   variables: {
 *      start_date: // value for 'start_date'
 *      end_date: // value for 'end_date'
 *      batch_id: // value for 'batch_id'
 *      product_id: // value for 'product_id'
 *      product_item_id: // value for 'product_item_id'
 *      product_type: // value for 'product_type'
 *   },
 * });
 */
export function useGetUnpaginatedPurchasesQuery(baseOptions?: Apollo.QueryHookOptions<GqlGetUnpaginatedPurchasesQuery, GqlGetUnpaginatedPurchasesQueryVariables>) {
        return Apollo.useQuery<GqlGetUnpaginatedPurchasesQuery, GqlGetUnpaginatedPurchasesQueryVariables>(GetUnpaginatedPurchasesDocument, baseOptions);
      }
export function useGetUnpaginatedPurchasesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlGetUnpaginatedPurchasesQuery, GqlGetUnpaginatedPurchasesQueryVariables>) {
          return Apollo.useLazyQuery<GqlGetUnpaginatedPurchasesQuery, GqlGetUnpaginatedPurchasesQueryVariables>(GetUnpaginatedPurchasesDocument, baseOptions);
        }
export type GetUnpaginatedPurchasesQueryHookResult = ReturnType<typeof useGetUnpaginatedPurchasesQuery>;
export type GetUnpaginatedPurchasesLazyQueryHookResult = ReturnType<typeof useGetUnpaginatedPurchasesLazyQuery>;
export type GetUnpaginatedPurchasesQueryResult = Apollo.QueryResult<GqlGetUnpaginatedPurchasesQuery, GqlGetUnpaginatedPurchasesQueryVariables>;
export const Sales_Per_ProductDocument = gql`
    query sales_per_product($page: Int, $per_page: Int, $search: String, $filters: GenericScalar) {
  sales_per_product(
    filters: $filters
    per_page: $per_page
    page: $page
    search: $search
  ) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        product_item {
          product_item_name
          product {
            product_name
            category {
              category_name
            }
          }
          product_item_segment {
            segment_name
          }
        }
        quantity
        amount
        weight
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useSales_Per_ProductQuery__
 *
 * To run a query within a React component, call `useSales_Per_ProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useSales_Per_ProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSales_Per_ProductQuery({
 *   variables: {
 *      page: // value for 'page'
 *      per_page: // value for 'per_page'
 *      search: // value for 'search'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useSales_Per_ProductQuery(baseOptions?: Apollo.QueryHookOptions<GqlSales_Per_ProductQuery, GqlSales_Per_ProductQueryVariables>) {
        return Apollo.useQuery<GqlSales_Per_ProductQuery, GqlSales_Per_ProductQueryVariables>(Sales_Per_ProductDocument, baseOptions);
      }
export function useSales_Per_ProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlSales_Per_ProductQuery, GqlSales_Per_ProductQueryVariables>) {
          return Apollo.useLazyQuery<GqlSales_Per_ProductQuery, GqlSales_Per_ProductQueryVariables>(Sales_Per_ProductDocument, baseOptions);
        }
export type Sales_Per_ProductQueryHookResult = ReturnType<typeof useSales_Per_ProductQuery>;
export type Sales_Per_ProductLazyQueryHookResult = ReturnType<typeof useSales_Per_ProductLazyQuery>;
export type Sales_Per_ProductQueryResult = Apollo.QueryResult<GqlSales_Per_ProductQuery, GqlSales_Per_ProductQueryVariables>;
export const Sales_Per_Product_UnpaginatedDocument = gql`
    query sales_per_product_unpaginated($start_date: String, $end_date: String, $depot_id: String, $route_id: String, $product_item_id: String) {
  sales_per_product_unpaginated(
    start_date: $start_date
    end_date: $end_date
    product_item_id: $product_item_id
    route_id: $route_id
    depot_id: $depot_id
  ) {
    product_item {
      product_item_name
      product {
        product_name
        category {
          category_name
        }
      }
      product_item_segment {
        segment_name
      }
    }
    quantity
    amount
    weight
  }
}
    `;

/**
 * __useSales_Per_Product_UnpaginatedQuery__
 *
 * To run a query within a React component, call `useSales_Per_Product_UnpaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useSales_Per_Product_UnpaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSales_Per_Product_UnpaginatedQuery({
 *   variables: {
 *      start_date: // value for 'start_date'
 *      end_date: // value for 'end_date'
 *      depot_id: // value for 'depot_id'
 *      route_id: // value for 'route_id'
 *      product_item_id: // value for 'product_item_id'
 *   },
 * });
 */
export function useSales_Per_Product_UnpaginatedQuery(baseOptions?: Apollo.QueryHookOptions<GqlSales_Per_Product_UnpaginatedQuery, GqlSales_Per_Product_UnpaginatedQueryVariables>) {
        return Apollo.useQuery<GqlSales_Per_Product_UnpaginatedQuery, GqlSales_Per_Product_UnpaginatedQueryVariables>(Sales_Per_Product_UnpaginatedDocument, baseOptions);
      }
export function useSales_Per_Product_UnpaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlSales_Per_Product_UnpaginatedQuery, GqlSales_Per_Product_UnpaginatedQueryVariables>) {
          return Apollo.useLazyQuery<GqlSales_Per_Product_UnpaginatedQuery, GqlSales_Per_Product_UnpaginatedQueryVariables>(Sales_Per_Product_UnpaginatedDocument, baseOptions);
        }
export type Sales_Per_Product_UnpaginatedQueryHookResult = ReturnType<typeof useSales_Per_Product_UnpaginatedQuery>;
export type Sales_Per_Product_UnpaginatedLazyQueryHookResult = ReturnType<typeof useSales_Per_Product_UnpaginatedLazyQuery>;
export type Sales_Per_Product_UnpaginatedQueryResult = Apollo.QueryResult<GqlSales_Per_Product_UnpaginatedQuery, GqlSales_Per_Product_UnpaginatedQueryVariables>;
export const StockAvailabilityReportDocument = gql`
    query stockAvailabilityReport($page: Int, $per_page: Int, $search: String, $filters: GenericScalar) {
  v2_stock_availability_product_item_summary(
    filters: $filters
    per_page: $per_page
    page: $page
    search: $search
  ) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        product_item_id
        quantity
        sale_date
        last_date_available
        stock_status
        no_days_oos
        product_item {
          product_item_name
          product_item_id
          product_id
          product {
            product_name
            category {
              category_name
              category_id
            }
          }
          product_item_segment {
            segment_name
            product_item_segment_id
          }
        }
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useStockAvailabilityReportQuery__
 *
 * To run a query within a React component, call `useStockAvailabilityReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useStockAvailabilityReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStockAvailabilityReportQuery({
 *   variables: {
 *      page: // value for 'page'
 *      per_page: // value for 'per_page'
 *      search: // value for 'search'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useStockAvailabilityReportQuery(baseOptions?: Apollo.QueryHookOptions<GqlStockAvailabilityReportQuery, GqlStockAvailabilityReportQueryVariables>) {
        return Apollo.useQuery<GqlStockAvailabilityReportQuery, GqlStockAvailabilityReportQueryVariables>(StockAvailabilityReportDocument, baseOptions);
      }
export function useStockAvailabilityReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlStockAvailabilityReportQuery, GqlStockAvailabilityReportQueryVariables>) {
          return Apollo.useLazyQuery<GqlStockAvailabilityReportQuery, GqlStockAvailabilityReportQueryVariables>(StockAvailabilityReportDocument, baseOptions);
        }
export type StockAvailabilityReportQueryHookResult = ReturnType<typeof useStockAvailabilityReportQuery>;
export type StockAvailabilityReportLazyQueryHookResult = ReturnType<typeof useStockAvailabilityReportLazyQuery>;
export type StockAvailabilityReportQueryResult = Apollo.QueryResult<GqlStockAvailabilityReportQuery, GqlStockAvailabilityReportQueryVariables>;
export const StockAvailabilityReportUnpaginatedDocument = gql`
    query stockAvailabilityReportUnpaginated($filters: GenericScalar) {
  v2_stock_availability_unpaginated(filters: $filters) {
    product_item_id
    quantity
    sale_date
    last_date_available
    stock_status
    no_days_oos
    product_item {
      product_item_name
      product_item_id
      product_id
      product {
        product_name
        category {
          category_name
          category_id
        }
      }
      product_item_segment {
        segment_name
        product_item_segment_id
      }
    }
  }
}
    `;

/**
 * __useStockAvailabilityReportUnpaginatedQuery__
 *
 * To run a query within a React component, call `useStockAvailabilityReportUnpaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useStockAvailabilityReportUnpaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStockAvailabilityReportUnpaginatedQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useStockAvailabilityReportUnpaginatedQuery(baseOptions?: Apollo.QueryHookOptions<GqlStockAvailabilityReportUnpaginatedQuery, GqlStockAvailabilityReportUnpaginatedQueryVariables>) {
        return Apollo.useQuery<GqlStockAvailabilityReportUnpaginatedQuery, GqlStockAvailabilityReportUnpaginatedQueryVariables>(StockAvailabilityReportUnpaginatedDocument, baseOptions);
      }
export function useStockAvailabilityReportUnpaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlStockAvailabilityReportUnpaginatedQuery, GqlStockAvailabilityReportUnpaginatedQueryVariables>) {
          return Apollo.useLazyQuery<GqlStockAvailabilityReportUnpaginatedQuery, GqlStockAvailabilityReportUnpaginatedQueryVariables>(StockAvailabilityReportUnpaginatedDocument, baseOptions);
        }
export type StockAvailabilityReportUnpaginatedQueryHookResult = ReturnType<typeof useStockAvailabilityReportUnpaginatedQuery>;
export type StockAvailabilityReportUnpaginatedLazyQueryHookResult = ReturnType<typeof useStockAvailabilityReportUnpaginatedLazyQuery>;
export type StockAvailabilityReportUnpaginatedQueryResult = Apollo.QueryResult<GqlStockAvailabilityReportUnpaginatedQuery, GqlStockAvailabilityReportUnpaginatedQueryVariables>;
export const Vehicle_AssignmentDocument = gql`
    query vehicle_assignment($page: Int, $per_page: Int, $search: String, $shops_vs_vehicles: String, $ordered_weight_vs_capacity: String, $delivery_date: String, $depot_ids: [String]) {
  pseudo_routes_vehicle_assignment(
    per_page: $per_page
    page: $page
    search: $search
    delivery_date: $delivery_date
    depot_ids: $depot_ids
    shops_vs_vehicles: $shops_vs_vehicles
    ordered_weight_vs_capacity: $ordered_weight_vs_capacity
  ) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        delivery_date
        route_id
        combined_routes
        unique_shops
        ordered_weight
        available_vehicles
        load_capacity
        shops_vs_vehicles
        ordered_weight_vs_capacity
        distance
        route {
          depot {
            depot_name
            depot_id
          }
        }
        user_id
        user {
          first_name
          last_name
        }
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useVehicle_AssignmentQuery__
 *
 * To run a query within a React component, call `useVehicle_AssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehicle_AssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicle_AssignmentQuery({
 *   variables: {
 *      page: // value for 'page'
 *      per_page: // value for 'per_page'
 *      search: // value for 'search'
 *      shops_vs_vehicles: // value for 'shops_vs_vehicles'
 *      ordered_weight_vs_capacity: // value for 'ordered_weight_vs_capacity'
 *      delivery_date: // value for 'delivery_date'
 *      depot_ids: // value for 'depot_ids'
 *   },
 * });
 */
export function useVehicle_AssignmentQuery(baseOptions?: Apollo.QueryHookOptions<GqlVehicle_AssignmentQuery, GqlVehicle_AssignmentQueryVariables>) {
        return Apollo.useQuery<GqlVehicle_AssignmentQuery, GqlVehicle_AssignmentQueryVariables>(Vehicle_AssignmentDocument, baseOptions);
      }
export function useVehicle_AssignmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlVehicle_AssignmentQuery, GqlVehicle_AssignmentQueryVariables>) {
          return Apollo.useLazyQuery<GqlVehicle_AssignmentQuery, GqlVehicle_AssignmentQueryVariables>(Vehicle_AssignmentDocument, baseOptions);
        }
export type Vehicle_AssignmentQueryHookResult = ReturnType<typeof useVehicle_AssignmentQuery>;
export type Vehicle_AssignmentLazyQueryHookResult = ReturnType<typeof useVehicle_AssignmentLazyQuery>;
export type Vehicle_AssignmentQueryResult = Apollo.QueryResult<GqlVehicle_AssignmentQuery, GqlVehicle_AssignmentQueryVariables>;
export const Vehicle_Assignment_UnpaginatedDocument = gql`
    query vehicle_assignment_unpaginated($delivery_date: String) {
  unpaginated_pseudo_routes_vehicle_assignment(delivery_date: $delivery_date) {
    available_vehicles
    combined_routes
    delivery_date
    distance
    load_capacity
    ordered_weight
    ordered_weight_vs_capacity
    route {
      route_id
      route_name
      route_type
      depot {
        depot_name
        depot_id
      }
    }
    user_id
    user {
      first_name
      last_name
    }
    shops_vs_vehicles
    unique_shops
  }
}
    `;

/**
 * __useVehicle_Assignment_UnpaginatedQuery__
 *
 * To run a query within a React component, call `useVehicle_Assignment_UnpaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehicle_Assignment_UnpaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicle_Assignment_UnpaginatedQuery({
 *   variables: {
 *      delivery_date: // value for 'delivery_date'
 *   },
 * });
 */
export function useVehicle_Assignment_UnpaginatedQuery(baseOptions?: Apollo.QueryHookOptions<GqlVehicle_Assignment_UnpaginatedQuery, GqlVehicle_Assignment_UnpaginatedQueryVariables>) {
        return Apollo.useQuery<GqlVehicle_Assignment_UnpaginatedQuery, GqlVehicle_Assignment_UnpaginatedQueryVariables>(Vehicle_Assignment_UnpaginatedDocument, baseOptions);
      }
export function useVehicle_Assignment_UnpaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlVehicle_Assignment_UnpaginatedQuery, GqlVehicle_Assignment_UnpaginatedQueryVariables>) {
          return Apollo.useLazyQuery<GqlVehicle_Assignment_UnpaginatedQuery, GqlVehicle_Assignment_UnpaginatedQueryVariables>(Vehicle_Assignment_UnpaginatedDocument, baseOptions);
        }
export type Vehicle_Assignment_UnpaginatedQueryHookResult = ReturnType<typeof useVehicle_Assignment_UnpaginatedQuery>;
export type Vehicle_Assignment_UnpaginatedLazyQueryHookResult = ReturnType<typeof useVehicle_Assignment_UnpaginatedLazyQuery>;
export type Vehicle_Assignment_UnpaginatedQueryResult = Apollo.QueryResult<GqlVehicle_Assignment_UnpaginatedQuery, GqlVehicle_Assignment_UnpaginatedQueryVariables>;
export const VehicleDeliveryAndLoadingDocument = gql`
    query vehicleDeliveryAndLoading($page: Int, $per_page: Int, $filters: GenericScalar, $search: String) {
  vehicle_delivery_and_loading(
    search: $search
    per_page: $per_page
    page: $page
    filters: $filters
  ) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        delivery_date
        delivery_payments {
          payment_mode
          amount
        }
        depot {
          depot_id
          depot_name
        }
        route_plan {
          route_plan_id
          vehicle {
            registration_number
          }
        }
        vehicle_allocated_amount
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useVehicleDeliveryAndLoadingQuery__
 *
 * To run a query within a React component, call `useVehicleDeliveryAndLoadingQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehicleDeliveryAndLoadingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleDeliveryAndLoadingQuery({
 *   variables: {
 *      page: // value for 'page'
 *      per_page: // value for 'per_page'
 *      filters: // value for 'filters'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useVehicleDeliveryAndLoadingQuery(baseOptions?: Apollo.QueryHookOptions<GqlVehicleDeliveryAndLoadingQuery, GqlVehicleDeliveryAndLoadingQueryVariables>) {
        return Apollo.useQuery<GqlVehicleDeliveryAndLoadingQuery, GqlVehicleDeliveryAndLoadingQueryVariables>(VehicleDeliveryAndLoadingDocument, baseOptions);
      }
export function useVehicleDeliveryAndLoadingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlVehicleDeliveryAndLoadingQuery, GqlVehicleDeliveryAndLoadingQueryVariables>) {
          return Apollo.useLazyQuery<GqlVehicleDeliveryAndLoadingQuery, GqlVehicleDeliveryAndLoadingQueryVariables>(VehicleDeliveryAndLoadingDocument, baseOptions);
        }
export type VehicleDeliveryAndLoadingQueryHookResult = ReturnType<typeof useVehicleDeliveryAndLoadingQuery>;
export type VehicleDeliveryAndLoadingLazyQueryHookResult = ReturnType<typeof useVehicleDeliveryAndLoadingLazyQuery>;
export type VehicleDeliveryAndLoadingQueryResult = Apollo.QueryResult<GqlVehicleDeliveryAndLoadingQuery, GqlVehicleDeliveryAndLoadingQueryVariables>;
export const VehicleDeliveryAndLoadingSummaryDocument = gql`
    query vehicleDeliveryAndLoadingSummary {
  vehicle_delivery_and_loading_summary {
    total_delivered_amount
    total_loaded_amount
  }
}
    `;

/**
 * __useVehicleDeliveryAndLoadingSummaryQuery__
 *
 * To run a query within a React component, call `useVehicleDeliveryAndLoadingSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehicleDeliveryAndLoadingSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleDeliveryAndLoadingSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useVehicleDeliveryAndLoadingSummaryQuery(baseOptions?: Apollo.QueryHookOptions<GqlVehicleDeliveryAndLoadingSummaryQuery, GqlVehicleDeliveryAndLoadingSummaryQueryVariables>) {
        return Apollo.useQuery<GqlVehicleDeliveryAndLoadingSummaryQuery, GqlVehicleDeliveryAndLoadingSummaryQueryVariables>(VehicleDeliveryAndLoadingSummaryDocument, baseOptions);
      }
export function useVehicleDeliveryAndLoadingSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlVehicleDeliveryAndLoadingSummaryQuery, GqlVehicleDeliveryAndLoadingSummaryQueryVariables>) {
          return Apollo.useLazyQuery<GqlVehicleDeliveryAndLoadingSummaryQuery, GqlVehicleDeliveryAndLoadingSummaryQueryVariables>(VehicleDeliveryAndLoadingSummaryDocument, baseOptions);
        }
export type VehicleDeliveryAndLoadingSummaryQueryHookResult = ReturnType<typeof useVehicleDeliveryAndLoadingSummaryQuery>;
export type VehicleDeliveryAndLoadingSummaryLazyQueryHookResult = ReturnType<typeof useVehicleDeliveryAndLoadingSummaryLazyQuery>;
export type VehicleDeliveryAndLoadingSummaryQueryResult = Apollo.QueryResult<GqlVehicleDeliveryAndLoadingSummaryQuery, GqlVehicleDeliveryAndLoadingSummaryQueryVariables>;
export const Get_Vendor_PaymentsDocument = gql`
    query get_vendor_payments($start_date: String, $end_date: String, $depot_ids: [String], $payment_modes: [String], $shop_ids: [String], $search: String, $page: Int, $per_page: Int) {
  vendor_payments(
    start_date: $start_date
    end_date: $end_date
    depot_ids: $depot_ids
    payment_modes: $payment_modes
    shop_ids: $shop_ids
    search: $search
    per_page: $per_page
    page: $page
  ) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        delivery_date
        depot_name
        delivery_fee
        total_amount
        route_name
        route_plan_id
        shop_number
        shop_name
        customer_name
        phone_number
        delivery_number
        payment_mode
        reference_number
        payment_number
        delivery_amount
        paid_amount
        route_plan {
          route_plan_id
          vehicle {
            registration_number
          }
        }
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useGet_Vendor_PaymentsQuery__
 *
 * To run a query within a React component, call `useGet_Vendor_PaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Vendor_PaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Vendor_PaymentsQuery({
 *   variables: {
 *      start_date: // value for 'start_date'
 *      end_date: // value for 'end_date'
 *      depot_ids: // value for 'depot_ids'
 *      payment_modes: // value for 'payment_modes'
 *      shop_ids: // value for 'shop_ids'
 *      search: // value for 'search'
 *      page: // value for 'page'
 *      per_page: // value for 'per_page'
 *   },
 * });
 */
export function useGet_Vendor_PaymentsQuery(baseOptions?: Apollo.QueryHookOptions<GqlGet_Vendor_PaymentsQuery, GqlGet_Vendor_PaymentsQueryVariables>) {
        return Apollo.useQuery<GqlGet_Vendor_PaymentsQuery, GqlGet_Vendor_PaymentsQueryVariables>(Get_Vendor_PaymentsDocument, baseOptions);
      }
export function useGet_Vendor_PaymentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlGet_Vendor_PaymentsQuery, GqlGet_Vendor_PaymentsQueryVariables>) {
          return Apollo.useLazyQuery<GqlGet_Vendor_PaymentsQuery, GqlGet_Vendor_PaymentsQueryVariables>(Get_Vendor_PaymentsDocument, baseOptions);
        }
export type Get_Vendor_PaymentsQueryHookResult = ReturnType<typeof useGet_Vendor_PaymentsQuery>;
export type Get_Vendor_PaymentsLazyQueryHookResult = ReturnType<typeof useGet_Vendor_PaymentsLazyQuery>;
export type Get_Vendor_PaymentsQueryResult = Apollo.QueryResult<GqlGet_Vendor_PaymentsQuery, GqlGet_Vendor_PaymentsQueryVariables>;
export const Get_Vendor_Payments_SummaryDocument = gql`
    query get_vendor_payments_summary($start_date: String, $end_date: String, $depot_ids: [String], $shop_ids: [String]) {
  vendor_payments_summary(
    start_date: $start_date
    end_date: $end_date
    depot_ids: $depot_ids
    shop_ids: $shop_ids
  ) {
    payment_mode
    amount
  }
}
    `;

/**
 * __useGet_Vendor_Payments_SummaryQuery__
 *
 * To run a query within a React component, call `useGet_Vendor_Payments_SummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Vendor_Payments_SummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Vendor_Payments_SummaryQuery({
 *   variables: {
 *      start_date: // value for 'start_date'
 *      end_date: // value for 'end_date'
 *      depot_ids: // value for 'depot_ids'
 *      shop_ids: // value for 'shop_ids'
 *   },
 * });
 */
export function useGet_Vendor_Payments_SummaryQuery(baseOptions?: Apollo.QueryHookOptions<GqlGet_Vendor_Payments_SummaryQuery, GqlGet_Vendor_Payments_SummaryQueryVariables>) {
        return Apollo.useQuery<GqlGet_Vendor_Payments_SummaryQuery, GqlGet_Vendor_Payments_SummaryQueryVariables>(Get_Vendor_Payments_SummaryDocument, baseOptions);
      }
export function useGet_Vendor_Payments_SummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlGet_Vendor_Payments_SummaryQuery, GqlGet_Vendor_Payments_SummaryQueryVariables>) {
          return Apollo.useLazyQuery<GqlGet_Vendor_Payments_SummaryQuery, GqlGet_Vendor_Payments_SummaryQueryVariables>(Get_Vendor_Payments_SummaryDocument, baseOptions);
        }
export type Get_Vendor_Payments_SummaryQueryHookResult = ReturnType<typeof useGet_Vendor_Payments_SummaryQuery>;
export type Get_Vendor_Payments_SummaryLazyQueryHookResult = ReturnType<typeof useGet_Vendor_Payments_SummaryLazyQuery>;
export type Get_Vendor_Payments_SummaryQueryResult = Apollo.QueryResult<GqlGet_Vendor_Payments_SummaryQuery, GqlGet_Vendor_Payments_SummaryQueryVariables>;
export const Get_Unpaginated_Vendor_PaymentsDocument = gql`
    query get_unpaginated_vendor_payments($start_date: String, $end_date: String, $depot_ids: [String], $payment_modes: [String], $shop_ids: [String]) {
  unpaginated_vendor_payments(
    start_date: $start_date
    end_date: $end_date
    depot_ids: $depot_ids
    payment_modes: $payment_modes
    shop_ids: $shop_ids
  ) {
    delivery_date
    depot_name
    route_plan_id
    delivery_fee
    total_amount
    route_name
    shop_number
    shop_name
    customer_name
    phone_number
    delivery_number
    payment_mode
    reference_number
    payment_number
    delivery_amount
    paid_amount
    route_plan {
      route_plan_id
      vehicle {
        registration_number
      }
    }
  }
}
    `;

/**
 * __useGet_Unpaginated_Vendor_PaymentsQuery__
 *
 * To run a query within a React component, call `useGet_Unpaginated_Vendor_PaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Unpaginated_Vendor_PaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Unpaginated_Vendor_PaymentsQuery({
 *   variables: {
 *      start_date: // value for 'start_date'
 *      end_date: // value for 'end_date'
 *      depot_ids: // value for 'depot_ids'
 *      payment_modes: // value for 'payment_modes'
 *      shop_ids: // value for 'shop_ids'
 *   },
 * });
 */
export function useGet_Unpaginated_Vendor_PaymentsQuery(baseOptions?: Apollo.QueryHookOptions<GqlGet_Unpaginated_Vendor_PaymentsQuery, GqlGet_Unpaginated_Vendor_PaymentsQueryVariables>) {
        return Apollo.useQuery<GqlGet_Unpaginated_Vendor_PaymentsQuery, GqlGet_Unpaginated_Vendor_PaymentsQueryVariables>(Get_Unpaginated_Vendor_PaymentsDocument, baseOptions);
      }
export function useGet_Unpaginated_Vendor_PaymentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlGet_Unpaginated_Vendor_PaymentsQuery, GqlGet_Unpaginated_Vendor_PaymentsQueryVariables>) {
          return Apollo.useLazyQuery<GqlGet_Unpaginated_Vendor_PaymentsQuery, GqlGet_Unpaginated_Vendor_PaymentsQueryVariables>(Get_Unpaginated_Vendor_PaymentsDocument, baseOptions);
        }
export type Get_Unpaginated_Vendor_PaymentsQueryHookResult = ReturnType<typeof useGet_Unpaginated_Vendor_PaymentsQuery>;
export type Get_Unpaginated_Vendor_PaymentsLazyQueryHookResult = ReturnType<typeof useGet_Unpaginated_Vendor_PaymentsLazyQuery>;
export type Get_Unpaginated_Vendor_PaymentsQueryResult = Apollo.QueryResult<GqlGet_Unpaginated_Vendor_PaymentsQuery, GqlGet_Unpaginated_Vendor_PaymentsQueryVariables>;
export const PaymentModesDocument = gql`
    query paymentModes {
  payment_modes
}
    `;

/**
 * __usePaymentModesQuery__
 *
 * To run a query within a React component, call `usePaymentModesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentModesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentModesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePaymentModesQuery(baseOptions?: Apollo.QueryHookOptions<GqlPaymentModesQuery, GqlPaymentModesQueryVariables>) {
        return Apollo.useQuery<GqlPaymentModesQuery, GqlPaymentModesQueryVariables>(PaymentModesDocument, baseOptions);
      }
export function usePaymentModesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlPaymentModesQuery, GqlPaymentModesQueryVariables>) {
          return Apollo.useLazyQuery<GqlPaymentModesQuery, GqlPaymentModesQueryVariables>(PaymentModesDocument, baseOptions);
        }
export type PaymentModesQueryHookResult = ReturnType<typeof usePaymentModesQuery>;
export type PaymentModesLazyQueryHookResult = ReturnType<typeof usePaymentModesLazyQuery>;
export type PaymentModesQueryResult = Apollo.QueryResult<GqlPaymentModesQuery, GqlPaymentModesQueryVariables>;
export const WmsStockBalancesDocument = gql`
    query wmsStockBalances($search: String, $per_page: Int, $page: Int, $stockBalancesFilters: GenericScalar) {
  product_locations(
    search: $search
    per_page: $per_page
    page: $page
    filters: $stockBalancesFilters
  ) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        storage_location_number
        location_type_name
        hu_count
        hui_count
        product_item {
          product_item_name
          product {
            product_id
            product_name
            category {
              category_name
              category_id
            }
          }
          product_item_segment {
            segment_name
            product_item_segment_id
          }
        }
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useWmsStockBalancesQuery__
 *
 * To run a query within a React component, call `useWmsStockBalancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWmsStockBalancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWmsStockBalancesQuery({
 *   variables: {
 *      search: // value for 'search'
 *      per_page: // value for 'per_page'
 *      page: // value for 'page'
 *      stockBalancesFilters: // value for 'stockBalancesFilters'
 *   },
 * });
 */
export function useWmsStockBalancesQuery(baseOptions?: Apollo.QueryHookOptions<GqlWmsStockBalancesQuery, GqlWmsStockBalancesQueryVariables>) {
        return Apollo.useQuery<GqlWmsStockBalancesQuery, GqlWmsStockBalancesQueryVariables>(WmsStockBalancesDocument, baseOptions);
      }
export function useWmsStockBalancesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlWmsStockBalancesQuery, GqlWmsStockBalancesQueryVariables>) {
          return Apollo.useLazyQuery<GqlWmsStockBalancesQuery, GqlWmsStockBalancesQueryVariables>(WmsStockBalancesDocument, baseOptions);
        }
export type WmsStockBalancesQueryHookResult = ReturnType<typeof useWmsStockBalancesQuery>;
export type WmsStockBalancesLazyQueryHookResult = ReturnType<typeof useWmsStockBalancesLazyQuery>;
export type WmsStockBalancesQueryResult = Apollo.QueryResult<GqlWmsStockBalancesQuery, GqlWmsStockBalancesQueryVariables>;
export const WmsStockBalancesUnpaginatedDocument = gql`
    query wmsStockBalancesUnpaginated($location_type_id: [String], $product_item_id: [String], $product_id: [String], $category_id: [String], $product_segment_id: [String], $storage_location_number: String) {
  product_locations_unpaginated(
    location_type_id: $location_type_id
    product_item_id: $product_item_id
    product_id: $product_id
    category_id: $category_id
    product_segment_id: $product_segment_id
    storage_location_number: $storage_location_number
  ) {
    storage_location_number
    location_type_name
    hu_count
    hui_count
    product_item {
      product_item_name
      product {
        product_id
        product_name
        category {
          category_name
          category_id
        }
      }
      product_item_segment {
        segment_name
        product_item_segment_id
      }
    }
  }
}
    `;

/**
 * __useWmsStockBalancesUnpaginatedQuery__
 *
 * To run a query within a React component, call `useWmsStockBalancesUnpaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useWmsStockBalancesUnpaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWmsStockBalancesUnpaginatedQuery({
 *   variables: {
 *      location_type_id: // value for 'location_type_id'
 *      product_item_id: // value for 'product_item_id'
 *      product_id: // value for 'product_id'
 *      category_id: // value for 'category_id'
 *      product_segment_id: // value for 'product_segment_id'
 *      storage_location_number: // value for 'storage_location_number'
 *   },
 * });
 */
export function useWmsStockBalancesUnpaginatedQuery(baseOptions?: Apollo.QueryHookOptions<GqlWmsStockBalancesUnpaginatedQuery, GqlWmsStockBalancesUnpaginatedQueryVariables>) {
        return Apollo.useQuery<GqlWmsStockBalancesUnpaginatedQuery, GqlWmsStockBalancesUnpaginatedQueryVariables>(WmsStockBalancesUnpaginatedDocument, baseOptions);
      }
export function useWmsStockBalancesUnpaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlWmsStockBalancesUnpaginatedQuery, GqlWmsStockBalancesUnpaginatedQueryVariables>) {
          return Apollo.useLazyQuery<GqlWmsStockBalancesUnpaginatedQuery, GqlWmsStockBalancesUnpaginatedQueryVariables>(WmsStockBalancesUnpaginatedDocument, baseOptions);
        }
export type WmsStockBalancesUnpaginatedQueryHookResult = ReturnType<typeof useWmsStockBalancesUnpaginatedQuery>;
export type WmsStockBalancesUnpaginatedLazyQueryHookResult = ReturnType<typeof useWmsStockBalancesUnpaginatedLazyQuery>;
export type WmsStockBalancesUnpaginatedQueryResult = Apollo.QueryResult<GqlWmsStockBalancesUnpaginatedQuery, GqlWmsStockBalancesUnpaginatedQueryVariables>;
export const ProductPriceRangeDocument = gql`
    query productPriceRange($search: String, $productFilters: GenericScalar, $per_page: Int, $page: Int) {
  paginated_products_v2(
    search: $search
    per_page: $per_page
    page: $page
    filters: $productFilters
  ) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        active
        product_name
        product_id
        sourcing_price_range {
          min_price
          max_price
          date_updated
          price_range_id
        }
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useProductPriceRangeQuery__
 *
 * To run a query within a React component, call `useProductPriceRangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductPriceRangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductPriceRangeQuery({
 *   variables: {
 *      search: // value for 'search'
 *      productFilters: // value for 'productFilters'
 *      per_page: // value for 'per_page'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useProductPriceRangeQuery(baseOptions?: Apollo.QueryHookOptions<GqlProductPriceRangeQuery, GqlProductPriceRangeQueryVariables>) {
        return Apollo.useQuery<GqlProductPriceRangeQuery, GqlProductPriceRangeQueryVariables>(ProductPriceRangeDocument, baseOptions);
      }
export function useProductPriceRangeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlProductPriceRangeQuery, GqlProductPriceRangeQueryVariables>) {
          return Apollo.useLazyQuery<GqlProductPriceRangeQuery, GqlProductPriceRangeQueryVariables>(ProductPriceRangeDocument, baseOptions);
        }
export type ProductPriceRangeQueryHookResult = ReturnType<typeof useProductPriceRangeQuery>;
export type ProductPriceRangeLazyQueryHookResult = ReturnType<typeof useProductPriceRangeLazyQuery>;
export type ProductPriceRangeQueryResult = Apollo.QueryResult<GqlProductPriceRangeQuery, GqlProductPriceRangeQueryVariables>;
export const PurchasesDocument = gql`
    query Purchases($search: String, $per_page: Int, $page: Int, $purchaseFilters: GenericScalar) {
  harvests(
    search: $search
    per_page: $per_page
    page: $page
    filter: $purchaseFilters
  ) {
    page_info {
      per_page
      total_count
      total_pages
      has_next
      has_previous
      current_page
    }
    edges {
      node {
        active
        original_currency
        cancelled
        cancellation_reason
        date_created
        receipt_number
        harvest_id
        invoice_number
        batch_id
        batch {
          batch_name
        }
        signed_receipt_url
        farmer_id
        quantity
        weight
        unit_price
        collection_center_id
        collection_center {
          collection_center_name
          area_id
          area {
            area_name
          }
        }
        farmer {
          first_name
          last_name
          phone_number
          farmer_number
          farmer_id
          supplier_name
        }
        farm_id
        farm {
          farm_number
          farmer {
            first_name
            last_name
            phone_number
            supplier_name
            farmer_id
          }
        }
        product_id
        product {
          product_name
        }
        product_item_id
        product_item {
          product_item_name
        }
        unit_of_measure_id
        uom {
          name
        }
        control_unit_invoice_number
        harvest_date
        harvest_items {
          harvest_id
          harvest {
            batch_id
          }
          product_id
          product {
            product_name
          }
          product_item_id
          product_item {
            product_item_name
            product_id
            product {
              product_name
            }
          }
          quantity
          unit_price
          weight
          unit_of_measure_id
          uom {
            name
          }
        }
        payment {
          amount_paid
          total_payable
          harvest_payment_status
        }
      }
    }
  }
}
    `;

/**
 * __usePurchasesQuery__
 *
 * To run a query within a React component, call `usePurchasesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurchasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePurchasesQuery({
 *   variables: {
 *      search: // value for 'search'
 *      per_page: // value for 'per_page'
 *      page: // value for 'page'
 *      purchaseFilters: // value for 'purchaseFilters'
 *   },
 * });
 */
export function usePurchasesQuery(baseOptions?: Apollo.QueryHookOptions<GqlPurchasesQuery, GqlPurchasesQueryVariables>) {
        return Apollo.useQuery<GqlPurchasesQuery, GqlPurchasesQueryVariables>(PurchasesDocument, baseOptions);
      }
export function usePurchasesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlPurchasesQuery, GqlPurchasesQueryVariables>) {
          return Apollo.useLazyQuery<GqlPurchasesQuery, GqlPurchasesQueryVariables>(PurchasesDocument, baseOptions);
        }
export type PurchasesQueryHookResult = ReturnType<typeof usePurchasesQuery>;
export type PurchasesLazyQueryHookResult = ReturnType<typeof usePurchasesLazyQuery>;
export type PurchasesQueryResult = Apollo.QueryResult<GqlPurchasesQuery, GqlPurchasesQueryVariables>;
export const BookingsDocument = gql`
    query Bookings($search: String, $per_page: Int, $page: Int, $bookingFilters: GenericScalar) {
  bookings(
    search: $search
    per_page: $per_page
    page: $page
    filter: $bookingFilters
  ) {
    page_info {
      per_page
      total_count
      total_pages
      has_next
      has_previous
      current_page
    }
    edges {
      node {
        active
        cancelled
        cancellation_reason
        date_created
        receipt_number
        booking_id
        booking_date
        harvest_date
        date_created
        signed_invoice_url
        quantity
        weight
        price
        farmer_id
        farmer {
          first_name
          last_name
          phone_number
          supplier_name
          farmer_id
        }
        product_id
        product {
          product_name
        }
        product_item_id
        product_item {
          product_item_name
        }
        unit_of_measure_id
        uom {
          name
        }
        collection_center_id
        collection_center {
          collection_center_name
          area_id
          area {
            area_name
          }
        }
        farm_id
        farm {
          farm_number
          farmer {
            first_name
            last_name
            phone_number
            supplier_name
            farmer_id
          }
          harvest_area_id
          harvest_area {
            harvest_area_name
          }
        }
        booking_items {
          booking_id
          product_id
          product {
            product_name
          }
          product_item_id
          product_item {
            product_item_name
            product_id
            product {
              product_name
            }
            units_of_measure {
              business_domain {
                domain_name
              }
              conversion_ratio
            }
          }
          unit_price
          quantity
          weight
          date_created
          harvest_date
          unit_of_measure_id
          uom {
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useBookingsQuery__
 *
 * To run a query within a React component, call `useBookingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      per_page: // value for 'per_page'
 *      page: // value for 'page'
 *      bookingFilters: // value for 'bookingFilters'
 *   },
 * });
 */
export function useBookingsQuery(baseOptions?: Apollo.QueryHookOptions<GqlBookingsQuery, GqlBookingsQueryVariables>) {
        return Apollo.useQuery<GqlBookingsQuery, GqlBookingsQueryVariables>(BookingsDocument, baseOptions);
      }
export function useBookingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlBookingsQuery, GqlBookingsQueryVariables>) {
          return Apollo.useLazyQuery<GqlBookingsQuery, GqlBookingsQueryVariables>(BookingsDocument, baseOptions);
        }
export type BookingsQueryHookResult = ReturnType<typeof useBookingsQuery>;
export type BookingsLazyQueryHookResult = ReturnType<typeof useBookingsLazyQuery>;
export type BookingsQueryResult = Apollo.QueryResult<GqlBookingsQuery, GqlBookingsQueryVariables>;
export const SupplierProductsDocument = gql`
    query SupplierProducts($per_page: Int, $page: Int, $productFilter: GenericScalar) {
  supplier_products(per_page: $per_page, page: $page, filters: $productFilter) {
    page_info {
      per_page
      total_count
      total_pages
      has_next
      has_previous
      current_page
    }
    edges {
      node {
        active
        farmer_id
        supplier_product_id
        product_id
        product {
          product_name
          product_id
        }
      }
    }
  }
}
    `;

/**
 * __useSupplierProductsQuery__
 *
 * To run a query within a React component, call `useSupplierProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupplierProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupplierProductsQuery({
 *   variables: {
 *      per_page: // value for 'per_page'
 *      page: // value for 'page'
 *      productFilter: // value for 'productFilter'
 *   },
 * });
 */
export function useSupplierProductsQuery(baseOptions?: Apollo.QueryHookOptions<GqlSupplierProductsQuery, GqlSupplierProductsQueryVariables>) {
        return Apollo.useQuery<GqlSupplierProductsQuery, GqlSupplierProductsQueryVariables>(SupplierProductsDocument, baseOptions);
      }
export function useSupplierProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlSupplierProductsQuery, GqlSupplierProductsQueryVariables>) {
          return Apollo.useLazyQuery<GqlSupplierProductsQuery, GqlSupplierProductsQueryVariables>(SupplierProductsDocument, baseOptions);
        }
export type SupplierProductsQueryHookResult = ReturnType<typeof useSupplierProductsQuery>;
export type SupplierProductsLazyQueryHookResult = ReturnType<typeof useSupplierProductsLazyQuery>;
export type SupplierProductsQueryResult = Apollo.QueryResult<GqlSupplierProductsQuery, GqlSupplierProductsQueryVariables>;
export const PivotsDocument = gql`
    query Pivots {
  pivots {
    pivot_id
    pivot_name
    pivot_code
  }
}
    `;

/**
 * __usePivotsQuery__
 *
 * To run a query within a React component, call `usePivotsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePivotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePivotsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePivotsQuery(baseOptions?: Apollo.QueryHookOptions<GqlPivotsQuery, GqlPivotsQueryVariables>) {
        return Apollo.useQuery<GqlPivotsQuery, GqlPivotsQueryVariables>(PivotsDocument, baseOptions);
      }
export function usePivotsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlPivotsQuery, GqlPivotsQueryVariables>) {
          return Apollo.useLazyQuery<GqlPivotsQuery, GqlPivotsQueryVariables>(PivotsDocument, baseOptions);
        }
export type PivotsQueryHookResult = ReturnType<typeof usePivotsQuery>;
export type PivotsLazyQueryHookResult = ReturnType<typeof usePivotsLazyQuery>;
export type PivotsQueryResult = Apollo.QueryResult<GqlPivotsQuery, GqlPivotsQueryVariables>;
export const BlocksDocument = gql`
    query Blocks {
  blocks {
    block_id
    block_name
    block_code
  }
}
    `;

/**
 * __useBlocksQuery__
 *
 * To run a query within a React component, call `useBlocksQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlocksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlocksQuery({
 *   variables: {
 *   },
 * });
 */
export function useBlocksQuery(baseOptions?: Apollo.QueryHookOptions<GqlBlocksQuery, GqlBlocksQueryVariables>) {
        return Apollo.useQuery<GqlBlocksQuery, GqlBlocksQueryVariables>(BlocksDocument, baseOptions);
      }
export function useBlocksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlBlocksQuery, GqlBlocksQueryVariables>) {
          return Apollo.useLazyQuery<GqlBlocksQuery, GqlBlocksQueryVariables>(BlocksDocument, baseOptions);
        }
export type BlocksQueryHookResult = ReturnType<typeof useBlocksQuery>;
export type BlocksLazyQueryHookResult = ReturnType<typeof useBlocksLazyQuery>;
export type BlocksQueryResult = Apollo.QueryResult<GqlBlocksQuery, GqlBlocksQueryVariables>;
export const PartyProviderModesDocument = gql`
    query PartyProviderModes {
  partyProviderModes {
    edges {
      providerModeID
      id
      paymentMode
      partyID
      party {
        name
        partyCode
      }
      providerMode {
        name
        providerID
        minAmount
        maxAmount
        balance
      }
    }
  }
}
    `;

/**
 * __usePartyProviderModesQuery__
 *
 * To run a query within a React component, call `usePartyProviderModesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartyProviderModesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartyProviderModesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePartyProviderModesQuery(baseOptions?: Apollo.QueryHookOptions<GqlPartyProviderModesQuery, GqlPartyProviderModesQueryVariables>) {
        return Apollo.useQuery<GqlPartyProviderModesQuery, GqlPartyProviderModesQueryVariables>(PartyProviderModesDocument, baseOptions);
      }
export function usePartyProviderModesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlPartyProviderModesQuery, GqlPartyProviderModesQueryVariables>) {
          return Apollo.useLazyQuery<GqlPartyProviderModesQuery, GqlPartyProviderModesQueryVariables>(PartyProviderModesDocument, baseOptions);
        }
export type PartyProviderModesQueryHookResult = ReturnType<typeof usePartyProviderModesQuery>;
export type PartyProviderModesLazyQueryHookResult = ReturnType<typeof usePartyProviderModesLazyQuery>;
export type PartyProviderModesQueryResult = Apollo.QueryResult<GqlPartyProviderModesQuery, GqlPartyProviderModesQueryVariables>;
export const GetHarvestToConfirmPurchaseDocument = gql`
    query getHarvestToConfirmPurchase($harvestFilter: GenericScalar) {
  harvests(filter: $harvestFilter) {
    edges {
      node {
        harvest_date
        harvest_id
        date_created
        invoice_number
        farmer_id
        farmer {
          first_name
          last_name
        }
        harvest_items {
          packaging_unit_quantity
          quantity
          unit_price
          product_item {
            item_sequence
            product_item_id
            etims_code
            etims_class_code
            tax_type_code
            product_item_name
            units_of_measure {
              uom {
                etims_quantity_code
              }
            }
            product {
              packaging_units {
                etims_packaging_code
              }
            }
            dynamic_price {
              discount_value_type
              discount_value
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetHarvestToConfirmPurchaseQuery__
 *
 * To run a query within a React component, call `useGetHarvestToConfirmPurchaseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHarvestToConfirmPurchaseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHarvestToConfirmPurchaseQuery({
 *   variables: {
 *      harvestFilter: // value for 'harvestFilter'
 *   },
 * });
 */
export function useGetHarvestToConfirmPurchaseQuery(baseOptions?: Apollo.QueryHookOptions<GqlGetHarvestToConfirmPurchaseQuery, GqlGetHarvestToConfirmPurchaseQueryVariables>) {
        return Apollo.useQuery<GqlGetHarvestToConfirmPurchaseQuery, GqlGetHarvestToConfirmPurchaseQueryVariables>(GetHarvestToConfirmPurchaseDocument, baseOptions);
      }
export function useGetHarvestToConfirmPurchaseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlGetHarvestToConfirmPurchaseQuery, GqlGetHarvestToConfirmPurchaseQueryVariables>) {
          return Apollo.useLazyQuery<GqlGetHarvestToConfirmPurchaseQuery, GqlGetHarvestToConfirmPurchaseQueryVariables>(GetHarvestToConfirmPurchaseDocument, baseOptions);
        }
export type GetHarvestToConfirmPurchaseQueryHookResult = ReturnType<typeof useGetHarvestToConfirmPurchaseQuery>;
export type GetHarvestToConfirmPurchaseLazyQueryHookResult = ReturnType<typeof useGetHarvestToConfirmPurchaseLazyQuery>;
export type GetHarvestToConfirmPurchaseQueryResult = Apollo.QueryResult<GqlGetHarvestToConfirmPurchaseQuery, GqlGetHarvestToConfirmPurchaseQueryVariables>;
export const StorageDocument = gql`
    query storage($filters: GenericScalar, $per_page: Int!, $page: Int!, $search: String) {
  storage(per_page: $per_page, filter: $filters, page: $page, search: $search) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        storage_id
        storage_entry_number
        batch_id
        batch {
          batch_name
        }
        date
        quantity
        packaging_unit_quantity
        created_by
        user {
          first_name
          last_name
        }
        direction
        weight
        comments
        packaging_unit_id
        packaging_unit {
          name
        }
        unit_of_measure_id
        uom {
          name
        }
        storage_unit {
          unit_name
          warehouse_id
          warehouse {
            warehouse_name
            distribution_center_id
            distribution_center {
              distribution_center_name
            }
          }
        }
        product_item_id
        product_item {
          product_item_name
          product {
            product_name
          }
        }
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useStorageQuery__
 *
 * To run a query within a React component, call `useStorageQuery` and pass it any options that fit your needs.
 * When your component renders, `useStorageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStorageQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      per_page: // value for 'per_page'
 *      page: // value for 'page'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useStorageQuery(baseOptions: Apollo.QueryHookOptions<GqlStorageQuery, GqlStorageQueryVariables>) {
        return Apollo.useQuery<GqlStorageQuery, GqlStorageQueryVariables>(StorageDocument, baseOptions);
      }
export function useStorageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlStorageQuery, GqlStorageQueryVariables>) {
          return Apollo.useLazyQuery<GqlStorageQuery, GqlStorageQueryVariables>(StorageDocument, baseOptions);
        }
export type StorageQueryHookResult = ReturnType<typeof useStorageQuery>;
export type StorageLazyQueryHookResult = ReturnType<typeof useStorageLazyQuery>;
export type StorageQueryResult = Apollo.QueryResult<GqlStorageQuery, GqlStorageQueryVariables>;
export const GetBatchesDocument = gql`
    query getBatches($filters: GenericScalar, $per_page: Int, $page: Int, $search: String) {
  batches(per_page: $per_page, filter: $filters, page: $page, search: $search) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        batch_id
        batch_name
        batch_date
        field_weight
        processing_loss_weight
        intake_weight
        active
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useGetBatchesQuery__
 *
 * To run a query within a React component, call `useGetBatchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBatchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBatchesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      per_page: // value for 'per_page'
 *      page: // value for 'page'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetBatchesQuery(baseOptions?: Apollo.QueryHookOptions<GqlGetBatchesQuery, GqlGetBatchesQueryVariables>) {
        return Apollo.useQuery<GqlGetBatchesQuery, GqlGetBatchesQueryVariables>(GetBatchesDocument, baseOptions);
      }
export function useGetBatchesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlGetBatchesQuery, GqlGetBatchesQueryVariables>) {
          return Apollo.useLazyQuery<GqlGetBatchesQuery, GqlGetBatchesQueryVariables>(GetBatchesDocument, baseOptions);
        }
export type GetBatchesQueryHookResult = ReturnType<typeof useGetBatchesQuery>;
export type GetBatchesLazyQueryHookResult = ReturnType<typeof useGetBatchesLazyQuery>;
export type GetBatchesQueryResult = Apollo.QueryResult<GqlGetBatchesQuery, GqlGetBatchesQueryVariables>;
export const BatchSummaryDetailDocument = gql`
    query batchSummaryDetail($batch_id: String!) {
  batch_by_id(batch_id: $batch_id) {
    batch_id
    batch_name
    batch_date
    field_weight
    intake_weight
    collection_center_id
    collection_center {
      collection_center_name
      area {
        area_name
      }
    }
    stock_intake_items_batch {
      batch_id
      stock_intake_items {
        active
        stock_intake_item_id
        stock_intake {
          stock_intake_number
        }
        product_item_id
        product_item {
          product_item_name
          product {
            product_name
          }
        }
        cancellation_reason
        weight
        quantity
        unit_of_measure_id
        uom {
          name
        }
        packaging_unit_quantity
        packaging_unit_id
        packaging_unit {
          name
        }
      }
    }
  }
}
    `;

/**
 * __useBatchSummaryDetailQuery__
 *
 * To run a query within a React component, call `useBatchSummaryDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useBatchSummaryDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBatchSummaryDetailQuery({
 *   variables: {
 *      batch_id: // value for 'batch_id'
 *   },
 * });
 */
export function useBatchSummaryDetailQuery(baseOptions: Apollo.QueryHookOptions<GqlBatchSummaryDetailQuery, GqlBatchSummaryDetailQueryVariables>) {
        return Apollo.useQuery<GqlBatchSummaryDetailQuery, GqlBatchSummaryDetailQueryVariables>(BatchSummaryDetailDocument, baseOptions);
      }
export function useBatchSummaryDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlBatchSummaryDetailQuery, GqlBatchSummaryDetailQueryVariables>) {
          return Apollo.useLazyQuery<GqlBatchSummaryDetailQuery, GqlBatchSummaryDetailQueryVariables>(BatchSummaryDetailDocument, baseOptions);
        }
export type BatchSummaryDetailQueryHookResult = ReturnType<typeof useBatchSummaryDetailQuery>;
export type BatchSummaryDetailLazyQueryHookResult = ReturnType<typeof useBatchSummaryDetailLazyQuery>;
export type BatchSummaryDetailQueryResult = Apollo.QueryResult<GqlBatchSummaryDetailQuery, GqlBatchSummaryDetailQueryVariables>;
export const DcReturnsDocument = gql`
    query dcReturns($filters: GenericScalar, $per_page: Int, $page: Int, $filter_array: GenericScalar) {
  returns(
    filter: $filters
    per_page: $per_page
    page: $page
    filter_array: $filter_array
  ) {
    page_info {
      per_page
      total_count
      total_pages
      current_page
    }
    edges {
      node {
        ...DCReturn
      }
    }
  }
}
    ${DcReturnFragmentDoc}`;

/**
 * __useDcReturnsQuery__
 *
 * To run a query within a React component, call `useDcReturnsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDcReturnsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDcReturnsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      per_page: // value for 'per_page'
 *      page: // value for 'page'
 *      filter_array: // value for 'filter_array'
 *   },
 * });
 */
export function useDcReturnsQuery(baseOptions?: Apollo.QueryHookOptions<GqlDcReturnsQuery, GqlDcReturnsQueryVariables>) {
        return Apollo.useQuery<GqlDcReturnsQuery, GqlDcReturnsQueryVariables>(DcReturnsDocument, baseOptions);
      }
export function useDcReturnsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlDcReturnsQuery, GqlDcReturnsQueryVariables>) {
          return Apollo.useLazyQuery<GqlDcReturnsQuery, GqlDcReturnsQueryVariables>(DcReturnsDocument, baseOptions);
        }
export type DcReturnsQueryHookResult = ReturnType<typeof useDcReturnsQuery>;
export type DcReturnsLazyQueryHookResult = ReturnType<typeof useDcReturnsLazyQuery>;
export type DcReturnsQueryResult = Apollo.QueryResult<GqlDcReturnsQuery, GqlDcReturnsQueryVariables>;
export const DcReturnByIdDocument = gql`
    query dcReturnById($return_id: String!) {
  return_by_id(return_id: $return_id) {
    ...DCReturn
  }
}
    ${DcReturnFragmentDoc}`;

/**
 * __useDcReturnByIdQuery__
 *
 * To run a query within a React component, call `useDcReturnByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useDcReturnByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDcReturnByIdQuery({
 *   variables: {
 *      return_id: // value for 'return_id'
 *   },
 * });
 */
export function useDcReturnByIdQuery(baseOptions: Apollo.QueryHookOptions<GqlDcReturnByIdQuery, GqlDcReturnByIdQueryVariables>) {
        return Apollo.useQuery<GqlDcReturnByIdQuery, GqlDcReturnByIdQueryVariables>(DcReturnByIdDocument, baseOptions);
      }
export function useDcReturnByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlDcReturnByIdQuery, GqlDcReturnByIdQueryVariables>) {
          return Apollo.useLazyQuery<GqlDcReturnByIdQuery, GqlDcReturnByIdQueryVariables>(DcReturnByIdDocument, baseOptions);
        }
export type DcReturnByIdQueryHookResult = ReturnType<typeof useDcReturnByIdQuery>;
export type DcReturnByIdLazyQueryHookResult = ReturnType<typeof useDcReturnByIdLazyQuery>;
export type DcReturnByIdQueryResult = Apollo.QueryResult<GqlDcReturnByIdQuery, GqlDcReturnByIdQueryVariables>;
export const DispatchesDocument = gql`
    query Dispatches($search: String, $per_page: Int, $page: Int, $dispatchFilters: GenericScalar) {
  dispatches(
    search: $search
    per_page: $per_page
    page: $page
    return_all: true
    filter: $dispatchFilters
  ) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        active
        dispatch_id
        depot_id
        dispatch_number
        date_created
        total_products
        created_by
        sale_date
        dispatch_type
        user {
          first_name
          last_name
          phone_number
          user_number
          user_id
        }
        depot_id
        depot {
          depot_name
          depot_id
        }
        distribution_center_id
        distribution_center {
          distribution_center_id
          distribution_center_name
        }
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useDispatchesQuery__
 *
 * To run a query within a React component, call `useDispatchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDispatchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDispatchesQuery({
 *   variables: {
 *      search: // value for 'search'
 *      per_page: // value for 'per_page'
 *      page: // value for 'page'
 *      dispatchFilters: // value for 'dispatchFilters'
 *   },
 * });
 */
export function useDispatchesQuery(baseOptions?: Apollo.QueryHookOptions<GqlDispatchesQuery, GqlDispatchesQueryVariables>) {
        return Apollo.useQuery<GqlDispatchesQuery, GqlDispatchesQueryVariables>(DispatchesDocument, baseOptions);
      }
export function useDispatchesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlDispatchesQuery, GqlDispatchesQueryVariables>) {
          return Apollo.useLazyQuery<GqlDispatchesQuery, GqlDispatchesQueryVariables>(DispatchesDocument, baseOptions);
        }
export type DispatchesQueryHookResult = ReturnType<typeof useDispatchesQuery>;
export type DispatchesLazyQueryHookResult = ReturnType<typeof useDispatchesLazyQuery>;
export type DispatchesQueryResult = Apollo.QueryResult<GqlDispatchesQuery, GqlDispatchesQueryVariables>;
export const DispatchDetailsDocument = gql`
    query DispatchDetails($dispatch_id: String!, $depot_id: String!, $date: String!) {
  dispatch_by_id(dispatch_id: $dispatch_id) {
    dispatch_id
    dispatch_type
    date_created
    sale_date
    depot_id
    depot {
      depot_name
      depot_id
    }
    created_by
    user {
      first_name
      last_name
      phone_number
      user_number
      user_id
    }
    dispatch_items {
      dispatch_item_id
      quantity
      cancellation_reason
      packaging_unit_quantity
      active
      route_id
      route {
        route_name
      }
      packaging_unit_id
      packaging_unit {
        name
      }
      unit_of_measure_id
      uom {
        name
        abbreviation
        uom_id
      }
      product_item_id
      product_item {
        product_item_id
        product_item_name
        product_id
        product {
          product_name
          product_type
          packaging_units {
            active
            name
            weight
            packaging_unit_id
          }
        }
        ordered_stock_depot(date: $date, depot_id: $depot_id) {
          order_quantity
        }
      }
    }
  }
}
    `;

/**
 * __useDispatchDetailsQuery__
 *
 * To run a query within a React component, call `useDispatchDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDispatchDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDispatchDetailsQuery({
 *   variables: {
 *      dispatch_id: // value for 'dispatch_id'
 *      depot_id: // value for 'depot_id'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useDispatchDetailsQuery(baseOptions: Apollo.QueryHookOptions<GqlDispatchDetailsQuery, GqlDispatchDetailsQueryVariables>) {
        return Apollo.useQuery<GqlDispatchDetailsQuery, GqlDispatchDetailsQueryVariables>(DispatchDetailsDocument, baseOptions);
      }
export function useDispatchDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlDispatchDetailsQuery, GqlDispatchDetailsQueryVariables>) {
          return Apollo.useLazyQuery<GqlDispatchDetailsQuery, GqlDispatchDetailsQueryVariables>(DispatchDetailsDocument, baseOptions);
        }
export type DispatchDetailsQueryHookResult = ReturnType<typeof useDispatchDetailsQuery>;
export type DispatchDetailsLazyQueryHookResult = ReturnType<typeof useDispatchDetailsLazyQuery>;
export type DispatchDetailsQueryResult = Apollo.QueryResult<GqlDispatchDetailsQuery, GqlDispatchDetailsQueryVariables>;
export const Processing_LossDocument = gql`
    query processing_loss($filters: GenericScalar, $per_page: Int!, $page: Int!, $search: String) {
  processing_loss(
    per_page: $per_page
    filter: $filters
    page: $page
    search: $search
  ) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        date
        processing_loss_number
        batch_id
        batch {
          batch_name
        }
        processing_loss_id
        processing_loss_type {
          processing_loss_type_id
          processing_loss_type_name
        }
        date
        loss_type
        created_by
        user {
          first_name
          last_name
        }
        distribution_center_id
        distribution_center {
          distribution_center_name
        }
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useProcessing_LossQuery__
 *
 * To run a query within a React component, call `useProcessing_LossQuery` and pass it any options that fit your needs.
 * When your component renders, `useProcessing_LossQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProcessing_LossQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      per_page: // value for 'per_page'
 *      page: // value for 'page'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useProcessing_LossQuery(baseOptions: Apollo.QueryHookOptions<GqlProcessing_LossQuery, GqlProcessing_LossQueryVariables>) {
        return Apollo.useQuery<GqlProcessing_LossQuery, GqlProcessing_LossQueryVariables>(Processing_LossDocument, baseOptions);
      }
export function useProcessing_LossLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlProcessing_LossQuery, GqlProcessing_LossQueryVariables>) {
          return Apollo.useLazyQuery<GqlProcessing_LossQuery, GqlProcessing_LossQueryVariables>(Processing_LossDocument, baseOptions);
        }
export type Processing_LossQueryHookResult = ReturnType<typeof useProcessing_LossQuery>;
export type Processing_LossLazyQueryHookResult = ReturnType<typeof useProcessing_LossLazyQuery>;
export type Processing_LossQueryResult = Apollo.QueryResult<GqlProcessing_LossQuery, GqlProcessing_LossQueryVariables>;
export const Processing_Loss_TypesDocument = gql`
    query processing_loss_types {
  processing_loss_types {
    edges {
      node {
        processing_loss_type_name
        processing_loss_type_id
      }
    }
  }
}
    `;

/**
 * __useProcessing_Loss_TypesQuery__
 *
 * To run a query within a React component, call `useProcessing_Loss_TypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProcessing_Loss_TypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProcessing_Loss_TypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useProcessing_Loss_TypesQuery(baseOptions?: Apollo.QueryHookOptions<GqlProcessing_Loss_TypesQuery, GqlProcessing_Loss_TypesQueryVariables>) {
        return Apollo.useQuery<GqlProcessing_Loss_TypesQuery, GqlProcessing_Loss_TypesQueryVariables>(Processing_Loss_TypesDocument, baseOptions);
      }
export function useProcessing_Loss_TypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlProcessing_Loss_TypesQuery, GqlProcessing_Loss_TypesQueryVariables>) {
          return Apollo.useLazyQuery<GqlProcessing_Loss_TypesQuery, GqlProcessing_Loss_TypesQueryVariables>(Processing_Loss_TypesDocument, baseOptions);
        }
export type Processing_Loss_TypesQueryHookResult = ReturnType<typeof useProcessing_Loss_TypesQuery>;
export type Processing_Loss_TypesLazyQueryHookResult = ReturnType<typeof useProcessing_Loss_TypesLazyQuery>;
export type Processing_Loss_TypesQueryResult = Apollo.QueryResult<GqlProcessing_Loss_TypesQuery, GqlProcessing_Loss_TypesQueryVariables>;
export const ProcessingLossByIdDocument = gql`
    query processingLossById($processingLossId: String) {
  processing_loss_by_id(processing_loss_id: $processingLossId) {
    date
    batch_id
    batch {
      batch_name
    }
    processing_loss_id
    processing_loss_type {
      processing_loss_type_id
      processing_loss_type_name
    }
    date
    loss_type
    created_by
    user {
      first_name
      last_name
    }
    processing_loss_items {
      active
      quantity
      thumbnail
      product_loss_reason {
        loss_reason {
          loss_reason
        }
      }
      cancellation_reason
      processing_loss_item_id
      product_item_id
      product_item {
        product_item_name
        product_id
        product {
          product_name
        }
      }
      unit_of_measure_id
      uom {
        name
      }
      processing_loss {
        processing_loss_id
        loss_type
      }
    }
  }
}
    `;

/**
 * __useProcessingLossByIdQuery__
 *
 * To run a query within a React component, call `useProcessingLossByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useProcessingLossByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProcessingLossByIdQuery({
 *   variables: {
 *      processingLossId: // value for 'processingLossId'
 *   },
 * });
 */
export function useProcessingLossByIdQuery(baseOptions?: Apollo.QueryHookOptions<GqlProcessingLossByIdQuery, GqlProcessingLossByIdQueryVariables>) {
        return Apollo.useQuery<GqlProcessingLossByIdQuery, GqlProcessingLossByIdQueryVariables>(ProcessingLossByIdDocument, baseOptions);
      }
export function useProcessingLossByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlProcessingLossByIdQuery, GqlProcessingLossByIdQueryVariables>) {
          return Apollo.useLazyQuery<GqlProcessingLossByIdQuery, GqlProcessingLossByIdQueryVariables>(ProcessingLossByIdDocument, baseOptions);
        }
export type ProcessingLossByIdQueryHookResult = ReturnType<typeof useProcessingLossByIdQuery>;
export type ProcessingLossByIdLazyQueryHookResult = ReturnType<typeof useProcessingLossByIdLazyQuery>;
export type ProcessingLossByIdQueryResult = Apollo.QueryResult<GqlProcessingLossByIdQuery, GqlProcessingLossByIdQueryVariables>;
export const Stock_AvailabilityDocument = gql`
    query stock_availability($page: Int, $per_page: Int, $search: String, $distribution_center_id: String!, $filters: GenericScalar) {
  paginated_product_items_v2(
    per_page: $per_page
    page: $page
    search: $search
    filters: $filters
    active: true
  ) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        ...ProductItemV2
        current_stock_availability(distribution_center_id: $distribution_center_id) {
          region_id
          created_by
          region {
            region_name
          }
          stock_availability_id
          updated_by
          sale_date
          quantity
          stock_entry_number
          unit_of_measure_id
          active
          uom {
            name
            abbreviation
          }
          user {
            user_id
            first_name
            last_name
            user_number
          }
        }
      }
    }
  }
}
    ${PaginationFragmentDoc}
${ProductItemV2FragmentDoc}`;

/**
 * __useStock_AvailabilityQuery__
 *
 * To run a query within a React component, call `useStock_AvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useStock_AvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStock_AvailabilityQuery({
 *   variables: {
 *      page: // value for 'page'
 *      per_page: // value for 'per_page'
 *      search: // value for 'search'
 *      distribution_center_id: // value for 'distribution_center_id'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useStock_AvailabilityQuery(baseOptions: Apollo.QueryHookOptions<GqlStock_AvailabilityQuery, GqlStock_AvailabilityQueryVariables>) {
        return Apollo.useQuery<GqlStock_AvailabilityQuery, GqlStock_AvailabilityQueryVariables>(Stock_AvailabilityDocument, baseOptions);
      }
export function useStock_AvailabilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlStock_AvailabilityQuery, GqlStock_AvailabilityQueryVariables>) {
          return Apollo.useLazyQuery<GqlStock_AvailabilityQuery, GqlStock_AvailabilityQueryVariables>(Stock_AvailabilityDocument, baseOptions);
        }
export type Stock_AvailabilityQueryHookResult = ReturnType<typeof useStock_AvailabilityQuery>;
export type Stock_AvailabilityLazyQueryHookResult = ReturnType<typeof useStock_AvailabilityLazyQuery>;
export type Stock_AvailabilityQueryResult = Apollo.QueryResult<GqlStock_AvailabilityQuery, GqlStock_AvailabilityQueryVariables>;
export const GetDCsStockAvailabilityDocument = gql`
    query getDCsStockAvailability($regionId: String!, $page: Int, $per_page: Int, $search: String, $distribution_center_ids: [String]!, $filters: GenericScalar, $depot_id: String!, $static_params: GenericScalar) {
  paginated_product_items_v2(
    per_page: $per_page
    page: $page
    search: $search
    filters: $filters
    active: true
    static_params: $static_params
  ) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        ...ProductItemV2
        current_pricing_v2(region_id: $regionId) {
          unit_of_measure_id
          base_price
          currency
          rrp
          pricing_date
          region_id
        }
        current_dcs_stock_availability(
          distribution_center_ids: $distribution_center_ids
          depot_id: $depot_id
        ) {
          quantity
        }
        dynamic_price {
          base_price
          dynamic_price
        }
        base_price
      }
    }
  }
}
    ${PaginationFragmentDoc}
${ProductItemV2FragmentDoc}`;

/**
 * __useGetDCsStockAvailabilityQuery__
 *
 * To run a query within a React component, call `useGetDCsStockAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDCsStockAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDCsStockAvailabilityQuery({
 *   variables: {
 *      regionId: // value for 'regionId'
 *      page: // value for 'page'
 *      per_page: // value for 'per_page'
 *      search: // value for 'search'
 *      distribution_center_ids: // value for 'distribution_center_ids'
 *      filters: // value for 'filters'
 *      depot_id: // value for 'depot_id'
 *      static_params: // value for 'static_params'
 *   },
 * });
 */
export function useGetDCsStockAvailabilityQuery(baseOptions: Apollo.QueryHookOptions<GqlGetDCsStockAvailabilityQuery, GqlGetDCsStockAvailabilityQueryVariables>) {
        return Apollo.useQuery<GqlGetDCsStockAvailabilityQuery, GqlGetDCsStockAvailabilityQueryVariables>(GetDCsStockAvailabilityDocument, baseOptions);
      }
export function useGetDCsStockAvailabilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlGetDCsStockAvailabilityQuery, GqlGetDCsStockAvailabilityQueryVariables>) {
          return Apollo.useLazyQuery<GqlGetDCsStockAvailabilityQuery, GqlGetDCsStockAvailabilityQueryVariables>(GetDCsStockAvailabilityDocument, baseOptions);
        }
export type GetDCsStockAvailabilityQueryHookResult = ReturnType<typeof useGetDCsStockAvailabilityQuery>;
export type GetDCsStockAvailabilityLazyQueryHookResult = ReturnType<typeof useGetDCsStockAvailabilityLazyQuery>;
export type GetDCsStockAvailabilityQueryResult = Apollo.QueryResult<GqlGetDCsStockAvailabilityQuery, GqlGetDCsStockAvailabilityQueryVariables>;
export const WarehouseStockIntakeDocument = gql`
    query warehouseStockIntake($filters: GenericScalar, $per_page: Int, $page: Int, $search: String) {
  stock_intakes(
    filter: $filters
    per_page: $per_page
    page: $page
    search: $search
  ) {
    page_info {
      per_page
      total_count
      total_pages
      current_page
    }
    edges {
      node {
        created_by
        stock_intake_id
        stock_intake_number
        arrival_date
        truck_number
        intake_count
        distribution_center_id
        distribution_center {
          distribution_center_id
          distribution_center_name
        }
      }
    }
  }
}
    `;

/**
 * __useWarehouseStockIntakeQuery__
 *
 * To run a query within a React component, call `useWarehouseStockIntakeQuery` and pass it any options that fit your needs.
 * When your component renders, `useWarehouseStockIntakeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWarehouseStockIntakeQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      per_page: // value for 'per_page'
 *      page: // value for 'page'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useWarehouseStockIntakeQuery(baseOptions?: Apollo.QueryHookOptions<GqlWarehouseStockIntakeQuery, GqlWarehouseStockIntakeQueryVariables>) {
        return Apollo.useQuery<GqlWarehouseStockIntakeQuery, GqlWarehouseStockIntakeQueryVariables>(WarehouseStockIntakeDocument, baseOptions);
      }
export function useWarehouseStockIntakeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlWarehouseStockIntakeQuery, GqlWarehouseStockIntakeQueryVariables>) {
          return Apollo.useLazyQuery<GqlWarehouseStockIntakeQuery, GqlWarehouseStockIntakeQueryVariables>(WarehouseStockIntakeDocument, baseOptions);
        }
export type WarehouseStockIntakeQueryHookResult = ReturnType<typeof useWarehouseStockIntakeQuery>;
export type WarehouseStockIntakeLazyQueryHookResult = ReturnType<typeof useWarehouseStockIntakeLazyQuery>;
export type WarehouseStockIntakeQueryResult = Apollo.QueryResult<GqlWarehouseStockIntakeQuery, GqlWarehouseStockIntakeQueryVariables>;
export const WarehouseStockIntakeByIdDocument = gql`
    query warehouseStockIntakeById($stock_intake_id: String!) {
  stock_intake_by_id(stock_intake_id: $stock_intake_id) {
    stock_intake_id
    stock_intake_number
    arrival_date
    truck_number
    stock_intake_photos {
      signed_url
    }
    created_by
    user {
      first_name
      last_name
    }
    stock_intake_items {
      active
      stock_intake_item_id
      cancellation_reason
      gross_weight
      batch_id
      batch {
        batch_name
      }
      weight
      quantity
      packaging_unit_id
      packaging_unit_quantity
      packaging_unit {
        name
      }
      unit_of_measure_id
      uom {
        name
      }
      product_item_id
      product_item {
        product_item_name
        product_id
        product {
          product_name
        }
      }
    }
  }
}
    `;

/**
 * __useWarehouseStockIntakeByIdQuery__
 *
 * To run a query within a React component, call `useWarehouseStockIntakeByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useWarehouseStockIntakeByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWarehouseStockIntakeByIdQuery({
 *   variables: {
 *      stock_intake_id: // value for 'stock_intake_id'
 *   },
 * });
 */
export function useWarehouseStockIntakeByIdQuery(baseOptions: Apollo.QueryHookOptions<GqlWarehouseStockIntakeByIdQuery, GqlWarehouseStockIntakeByIdQueryVariables>) {
        return Apollo.useQuery<GqlWarehouseStockIntakeByIdQuery, GqlWarehouseStockIntakeByIdQueryVariables>(WarehouseStockIntakeByIdDocument, baseOptions);
      }
export function useWarehouseStockIntakeByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlWarehouseStockIntakeByIdQuery, GqlWarehouseStockIntakeByIdQueryVariables>) {
          return Apollo.useLazyQuery<GqlWarehouseStockIntakeByIdQuery, GqlWarehouseStockIntakeByIdQueryVariables>(WarehouseStockIntakeByIdDocument, baseOptions);
        }
export type WarehouseStockIntakeByIdQueryHookResult = ReturnType<typeof useWarehouseStockIntakeByIdQuery>;
export type WarehouseStockIntakeByIdLazyQueryHookResult = ReturnType<typeof useWarehouseStockIntakeByIdLazyQuery>;
export type WarehouseStockIntakeByIdQueryResult = Apollo.QueryResult<GqlWarehouseStockIntakeByIdQuery, GqlWarehouseStockIntakeByIdQueryVariables>;
export const DistributionCentersDocument = gql`
    query distributionCenters($dc_names: [String]) {
  distribution_centers(dc_names: $dc_names) {
    distribution_center_name
    distribution_center_id
    warehouses {
      warehouse_id
      warehouse_name
      storage_units {
        storage_unit_id
        unit_name
        warehouse {
          warehouse_name
          warehouse_id
        }
      }
    }
  }
}
    `;

/**
 * __useDistributionCentersQuery__
 *
 * To run a query within a React component, call `useDistributionCentersQuery` and pass it any options that fit your needs.
 * When your component renders, `useDistributionCentersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDistributionCentersQuery({
 *   variables: {
 *      dc_names: // value for 'dc_names'
 *   },
 * });
 */
export function useDistributionCentersQuery(baseOptions?: Apollo.QueryHookOptions<GqlDistributionCentersQuery, GqlDistributionCentersQueryVariables>) {
        return Apollo.useQuery<GqlDistributionCentersQuery, GqlDistributionCentersQueryVariables>(DistributionCentersDocument, baseOptions);
      }
export function useDistributionCentersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlDistributionCentersQuery, GqlDistributionCentersQueryVariables>) {
          return Apollo.useLazyQuery<GqlDistributionCentersQuery, GqlDistributionCentersQueryVariables>(DistributionCentersDocument, baseOptions);
        }
export type DistributionCentersQueryHookResult = ReturnType<typeof useDistributionCentersQuery>;
export type DistributionCentersLazyQueryHookResult = ReturnType<typeof useDistributionCentersLazyQuery>;
export type DistributionCentersQueryResult = Apollo.QueryResult<GqlDistributionCentersQuery, GqlDistributionCentersQueryVariables>;
export const BatchesDocument = gql`
    query batches($search: String, $batchFilters: GenericScalar, $per_page: Int) {
  batches(search: $search, per_page: $per_page, filter: $batchFilters) {
    edges {
      node {
        active
        batch_id
        batch_name
        harvests {
          original_currency
          batch_id
          batch {
            batch_name
          }
          harvest_items {
            product_item_id
            quantity
            unit_of_measure_id
            uom {
              name
            }
            weight
            packaging_unit_id
            packaging_unit {
              packaging_unit_id
              name
            }
            number_of_crates
            packaging_unit_quantity
            product_item {
              product_item_name
              product {
                product_name
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useBatchesQuery__
 *
 * To run a query within a React component, call `useBatchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBatchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBatchesQuery({
 *   variables: {
 *      search: // value for 'search'
 *      batchFilters: // value for 'batchFilters'
 *      per_page: // value for 'per_page'
 *   },
 * });
 */
export function useBatchesQuery(baseOptions?: Apollo.QueryHookOptions<GqlBatchesQuery, GqlBatchesQueryVariables>) {
        return Apollo.useQuery<GqlBatchesQuery, GqlBatchesQueryVariables>(BatchesDocument, baseOptions);
      }
export function useBatchesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlBatchesQuery, GqlBatchesQueryVariables>) {
          return Apollo.useLazyQuery<GqlBatchesQuery, GqlBatchesQueryVariables>(BatchesDocument, baseOptions);
        }
export type BatchesQueryHookResult = ReturnType<typeof useBatchesQuery>;
export type BatchesLazyQueryHookResult = ReturnType<typeof useBatchesLazyQuery>;
export type BatchesQueryResult = Apollo.QueryResult<GqlBatchesQuery, GqlBatchesQueryVariables>;
export const WarehouseTransferDocument = gql`
    query warehouseTransfer($search: String, $per_page: Int, $page: Int, $warehouseFilters: GenericScalar) {
  warehouse_transfer(
    search: $search
    per_page: $per_page
    page: $page
    filter: $warehouseFilters
  ) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        cancelled
        cancellation_reason
        active
        date_created
        warehouse_transfer_id
        transfer_number
        transfer_date
        direction
        vehicle_id
        vehicle {
          registration_number
        }
        transfer_reason_id
        transfer_reason {
          reason_name
          reason_id
        }
        source_distribution_center_id
        source_distribution_center {
          distribution_center_name
        }
        dest_distribution_center_id
        dest_distribution_center {
          distribution_center_name
        }
        transferred_by
        transferred_by_user {
          first_name
          last_name
          user_number
        }
        warehouse_transfer_items {
          cancelled
          cancellation_reason
          warehouse_transfer_item_id
          warehouse_transfer_id
          quantity
          packaging_unit_quantity
          batch_id
          batch {
            batch_name
          }
          product_id
          product {
            product_name
          }
          weight
          product_item_id
          product_item {
            product_item_name
            product_id
            product {
              product_name
            }
            units_of_measure {
              business_domain {
                domain_name
              }
              conversion_ratio
            }
          }
          unit_of_measure_id
          uom {
            name
          }
          packaging_unit_id
          packaging_unit {
            name
          }
          warehouse {
            warehouse_name
            storage_units {
              unit_name
            }
          }
          storage_unit {
            unit_name
          }
        }
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useWarehouseTransferQuery__
 *
 * To run a query within a React component, call `useWarehouseTransferQuery` and pass it any options that fit your needs.
 * When your component renders, `useWarehouseTransferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWarehouseTransferQuery({
 *   variables: {
 *      search: // value for 'search'
 *      per_page: // value for 'per_page'
 *      page: // value for 'page'
 *      warehouseFilters: // value for 'warehouseFilters'
 *   },
 * });
 */
export function useWarehouseTransferQuery(baseOptions?: Apollo.QueryHookOptions<GqlWarehouseTransferQuery, GqlWarehouseTransferQueryVariables>) {
        return Apollo.useQuery<GqlWarehouseTransferQuery, GqlWarehouseTransferQueryVariables>(WarehouseTransferDocument, baseOptions);
      }
export function useWarehouseTransferLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlWarehouseTransferQuery, GqlWarehouseTransferQueryVariables>) {
          return Apollo.useLazyQuery<GqlWarehouseTransferQuery, GqlWarehouseTransferQueryVariables>(WarehouseTransferDocument, baseOptions);
        }
export type WarehouseTransferQueryHookResult = ReturnType<typeof useWarehouseTransferQuery>;
export type WarehouseTransferLazyQueryHookResult = ReturnType<typeof useWarehouseTransferLazyQuery>;
export type WarehouseTransferQueryResult = Apollo.QueryResult<GqlWarehouseTransferQuery, GqlWarehouseTransferQueryVariables>;
export const VehiclesDocument = gql`
    query vehicles($search: String, $per_page: Int) {
  vehicles(search: $search, per_page: $per_page) {
    edges {
      node {
        vehicle_id
        registration_number
        owner_name
        phone_number
      }
    }
  }
}
    `;

/**
 * __useVehiclesQuery__
 *
 * To run a query within a React component, call `useVehiclesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehiclesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehiclesQuery({
 *   variables: {
 *      search: // value for 'search'
 *      per_page: // value for 'per_page'
 *   },
 * });
 */
export function useVehiclesQuery(baseOptions?: Apollo.QueryHookOptions<GqlVehiclesQuery, GqlVehiclesQueryVariables>) {
        return Apollo.useQuery<GqlVehiclesQuery, GqlVehiclesQueryVariables>(VehiclesDocument, baseOptions);
      }
export function useVehiclesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlVehiclesQuery, GqlVehiclesQueryVariables>) {
          return Apollo.useLazyQuery<GqlVehiclesQuery, GqlVehiclesQueryVariables>(VehiclesDocument, baseOptions);
        }
export type VehiclesQueryHookResult = ReturnType<typeof useVehiclesQuery>;
export type VehiclesLazyQueryHookResult = ReturnType<typeof useVehiclesLazyQuery>;
export type VehiclesQueryResult = Apollo.QueryResult<GqlVehiclesQuery, GqlVehiclesQueryVariables>;
export const AppointmentsDocument = gql`
    query appointments($search: String, $per_page: Int, $page: Int, $appointmentFilters: GenericScalar) {
  appointments(
    search: $search
    per_page: $per_page
    page: $page
    filter: $appointmentFilters
  ) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        active
        cancelled
        cancellation_reason
        date_created
        appointment_id
        appointment_number
        appointment_type
        eta
        decline_reason
        distribution_center_id
        distribution_center {
          distribution_center_id
          distribution_center_name
        }
        driver_name
        driver_phone_number
        images
        type_of_truck
        driver_id
        vehicle_seal_number
        dock_door_id
        dock_door {
          dock_door_number
        }
        vehicle_registration
        status
        issued_by
        issued_by_user {
          first_name
          last_name
        }
        appointment_items {
          booking_id
          booking {
            farmer_id
            farmer {
              first_name
              last_name
              farmer_number
              supplier_name
              farmer_id
            }
            booking_items {
              product_item_id
              product_item {
                product_item_name
                product_id
                product {
                  product_name
                }
              }
            }
          }
          batch_id
          batch {
            batch_id
            harvests {
              farmer_id
              farmer {
                farmer_id
                farmer_number
                first_name
                last_name
              }
            }
          }
        }
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useAppointmentsQuery__
 *
 * To run a query within a React component, call `useAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      per_page: // value for 'per_page'
 *      page: // value for 'page'
 *      appointmentFilters: // value for 'appointmentFilters'
 *   },
 * });
 */
export function useAppointmentsQuery(baseOptions?: Apollo.QueryHookOptions<GqlAppointmentsQuery, GqlAppointmentsQueryVariables>) {
        return Apollo.useQuery<GqlAppointmentsQuery, GqlAppointmentsQueryVariables>(AppointmentsDocument, baseOptions);
      }
export function useAppointmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlAppointmentsQuery, GqlAppointmentsQueryVariables>) {
          return Apollo.useLazyQuery<GqlAppointmentsQuery, GqlAppointmentsQueryVariables>(AppointmentsDocument, baseOptions);
        }
export type AppointmentsQueryHookResult = ReturnType<typeof useAppointmentsQuery>;
export type AppointmentsLazyQueryHookResult = ReturnType<typeof useAppointmentsLazyQuery>;
export type AppointmentsQueryResult = Apollo.QueryResult<GqlAppointmentsQuery, GqlAppointmentsQueryVariables>;
export const AppointmentItemsDocument = gql`
    query appointmentItems($search: String, $per_page: Int, $page: Int, $appointmentFilters: GenericScalar) {
  appointment_items(
    search: $search
    per_page: $per_page
    page: $page
    filter: $appointmentFilters
  ) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        active
        cancelled
        appointment_id
        warehouse_transfer_id
        batch_id
        booking_id
        return_id
        warehouse_transfer {
          warehouse_transfer_id
          warehouse_transfer_items {
            active
            product_item_id
            product_item {
              product_item_name
              product {
                product_name
              }
            }
            quantity
            weight
            unit_of_measure_id
            uom {
              name
            }
          }
        }
        returns {
          active
          return_id
          product_return_items {
            active
            product_item_id
            product_item {
              product_item_name
              product {
                product_name
              }
            }
            quantity
            unit_of_measure_id
            uom {
              name
            }
          }
        }
        booking {
          booking_id
          farmer_id
          farmer {
            supplier_name
            first_name
            last_name
            farmer_type
            phone_number
          }
          booking_items {
            active
            unit_of_measure_id
            uom {
              name
            }
            product_item_id
            product_item {
              product_item_name
              product {
                product_name
              }
            }
            quantity
            weight
          }
        }
        batch {
          batch_name
          batch_id
          harvests {
            original_currency
            farmer_id
            farmer {
              supplier_name
              first_name
              last_name
              farmer_type
              phone_number
            }
            weight
            harvest_items {
              product_item_id
              quantity
              unit_of_measure_id
              uom {
                name
              }
              weight
              packaging_unit_id
              packaging_unit {
                packaging_unit_id
                name
              }
              packaging_unit_quantity
              product_item {
                product_item_name
                product {
                  product_name
                }
              }
            }
          }
        }
        warehouse_transfer_request {
          warehouse_transfer_request_items {
            active
            product_item_id
            product_item {
              product_item_name
              product {
                product_name
              }
            }
            quantity
            weight
          }
        }
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useAppointmentItemsQuery__
 *
 * To run a query within a React component, call `useAppointmentItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentItemsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      per_page: // value for 'per_page'
 *      page: // value for 'page'
 *      appointmentFilters: // value for 'appointmentFilters'
 *   },
 * });
 */
export function useAppointmentItemsQuery(baseOptions?: Apollo.QueryHookOptions<GqlAppointmentItemsQuery, GqlAppointmentItemsQueryVariables>) {
        return Apollo.useQuery<GqlAppointmentItemsQuery, GqlAppointmentItemsQueryVariables>(AppointmentItemsDocument, baseOptions);
      }
export function useAppointmentItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlAppointmentItemsQuery, GqlAppointmentItemsQueryVariables>) {
          return Apollo.useLazyQuery<GqlAppointmentItemsQuery, GqlAppointmentItemsQueryVariables>(AppointmentItemsDocument, baseOptions);
        }
export type AppointmentItemsQueryHookResult = ReturnType<typeof useAppointmentItemsQuery>;
export type AppointmentItemsLazyQueryHookResult = ReturnType<typeof useAppointmentItemsLazyQuery>;
export type AppointmentItemsQueryResult = Apollo.QueryResult<GqlAppointmentItemsQuery, GqlAppointmentItemsQueryVariables>;
export const DockDoorsDocument = gql`
    query dockDoors($per_page: Int, $page: Int, $search: String, $filter: GenericScalar) {
  dock_doors(per_page: $per_page, page: $page, search: $search, filter: $filter) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        active
        status
        dock_door_id
        dock_door_number
        door_type
        warehouse {
          distribution_center {
            distribution_center_name
          }
        }
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useDockDoorsQuery__
 *
 * To run a query within a React component, call `useDockDoorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDockDoorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDockDoorsQuery({
 *   variables: {
 *      per_page: // value for 'per_page'
 *      page: // value for 'page'
 *      search: // value for 'search'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDockDoorsQuery(baseOptions?: Apollo.QueryHookOptions<GqlDockDoorsQuery, GqlDockDoorsQueryVariables>) {
        return Apollo.useQuery<GqlDockDoorsQuery, GqlDockDoorsQueryVariables>(DockDoorsDocument, baseOptions);
      }
export function useDockDoorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlDockDoorsQuery, GqlDockDoorsQueryVariables>) {
          return Apollo.useLazyQuery<GqlDockDoorsQuery, GqlDockDoorsQueryVariables>(DockDoorsDocument, baseOptions);
        }
export type DockDoorsQueryHookResult = ReturnType<typeof useDockDoorsQuery>;
export type DockDoorsLazyQueryHookResult = ReturnType<typeof useDockDoorsLazyQuery>;
export type DockDoorsQueryResult = Apollo.QueryResult<GqlDockDoorsQuery, GqlDockDoorsQueryVariables>;
export const StockIntakeDocument = gql`
    query StockIntake($intakeFilters: GenericScalar) {
  stock_intakes(filter: $intakeFilters) {
    edges {
      node {
        appointment_id
        stock_intake_number
        appointment {
          appointment_number
          vehicle_registration
          driver_name
          eta
          dock_door_id
          dock_door {
            dock_door_number
          }
          appointment_items {
            batch_id
            booking_id
            warehouse_transfer_id
            return_id
            returns {
              active
              return_id
              product_return_items {
                active
                product_item_id
                product_item {
                  product_item_name
                  product {
                    product_name
                  }
                }
                quantity
                unit_of_measure_id
                uom {
                  name
                }
              }
            }
            warehouse_transfer {
              warehouse_transfer_id
              warehouse_transfer_items {
                active
                product_item_id
                product_item {
                  product_item_name
                  product {
                    product_name
                  }
                }
                quantity
                weight
                unit_of_measure_id
                uom {
                  name
                }
              }
            }
            booking {
              booking_id
              farmer_id
              farmer {
                first_name
                last_name
                farmer_number
                supplier_name
              }
              booking_items {
                active
                unit_of_measure_id
                uom {
                  name
                }
                product_item_id
                product_item {
                  product_item_name
                  product {
                    product_name
                  }
                }
                quantity
                weight
              }
            }
            batch {
              batch_name
              harvests {
                original_currency
                farmer_id
                farmer {
                  first_name
                  last_name
                  farmer_number
                  supplier_name
                }
                weight
                batch_id
                harvest_items {
                  product_item_id
                  quantity
                  unit_of_measure_id
                  uom {
                    name
                  }
                  weight
                  packaging_unit_id
                  packaging_unit {
                    packaging_unit_id
                    name
                  }
                  packaging_unit_quantity
                  product_item {
                    product_item_name
                    product {
                      product_name
                    }
                  }
                }
              }
            }
            warehouse_transfer_request_id
            warehouse_transfer_request {
              warehouse_transfer_request_id
              warehouse_transfer_request_items {
                active
                product_item_id
                product_item {
                  product_item_name
                  product {
                    product_name
                  }
                }
                quantity
                weight
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useStockIntakeQuery__
 *
 * To run a query within a React component, call `useStockIntakeQuery` and pass it any options that fit your needs.
 * When your component renders, `useStockIntakeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStockIntakeQuery({
 *   variables: {
 *      intakeFilters: // value for 'intakeFilters'
 *   },
 * });
 */
export function useStockIntakeQuery(baseOptions?: Apollo.QueryHookOptions<GqlStockIntakeQuery, GqlStockIntakeQueryVariables>) {
        return Apollo.useQuery<GqlStockIntakeQuery, GqlStockIntakeQueryVariables>(StockIntakeDocument, baseOptions);
      }
export function useStockIntakeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlStockIntakeQuery, GqlStockIntakeQueryVariables>) {
          return Apollo.useLazyQuery<GqlStockIntakeQuery, GqlStockIntakeQueryVariables>(StockIntakeDocument, baseOptions);
        }
export type StockIntakeQueryHookResult = ReturnType<typeof useStockIntakeQuery>;
export type StockIntakeLazyQueryHookResult = ReturnType<typeof useStockIntakeLazyQuery>;
export type StockIntakeQueryResult = Apollo.QueryResult<GqlStockIntakeQuery, GqlStockIntakeQueryVariables>;
export const ReturnsDocument = gql`
    query Returns($search: String, $per_page: Int, $page: Int, $returnsFilters: GenericScalar) {
  returns(
    search: $search
    per_page: $per_page
    page: $page
    filter: $returnsFilters
  ) {
    edges {
      node {
        return_number
        return_id
        product_return_items {
          return_id
          product_item_id
          product_item {
            product_item_name
            product_id
            product {
              product_name
            }
          }
          unit_of_measure_id
          uom {
            name
          }
          quantity
          return_type
        }
      }
    }
  }
}
    `;

/**
 * __useReturnsQuery__
 *
 * To run a query within a React component, call `useReturnsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReturnsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReturnsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      per_page: // value for 'per_page'
 *      page: // value for 'page'
 *      returnsFilters: // value for 'returnsFilters'
 *   },
 * });
 */
export function useReturnsQuery(baseOptions?: Apollo.QueryHookOptions<GqlReturnsQuery, GqlReturnsQueryVariables>) {
        return Apollo.useQuery<GqlReturnsQuery, GqlReturnsQueryVariables>(ReturnsDocument, baseOptions);
      }
export function useReturnsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlReturnsQuery, GqlReturnsQueryVariables>) {
          return Apollo.useLazyQuery<GqlReturnsQuery, GqlReturnsQueryVariables>(ReturnsDocument, baseOptions);
        }
export type ReturnsQueryHookResult = ReturnType<typeof useReturnsQuery>;
export type ReturnsLazyQueryHookResult = ReturnType<typeof useReturnsLazyQuery>;
export type ReturnsQueryResult = Apollo.QueryResult<GqlReturnsQuery, GqlReturnsQueryVariables>;
export const WarehouseTransferReasonDocument = gql`
    query WarehouseTransferReason {
  warehouse_transfer_reason {
    edges {
      node {
        reason_id
        reason_name
      }
    }
  }
}
    `;

/**
 * __useWarehouseTransferReasonQuery__
 *
 * To run a query within a React component, call `useWarehouseTransferReasonQuery` and pass it any options that fit your needs.
 * When your component renders, `useWarehouseTransferReasonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWarehouseTransferReasonQuery({
 *   variables: {
 *   },
 * });
 */
export function useWarehouseTransferReasonQuery(baseOptions?: Apollo.QueryHookOptions<GqlWarehouseTransferReasonQuery, GqlWarehouseTransferReasonQueryVariables>) {
        return Apollo.useQuery<GqlWarehouseTransferReasonQuery, GqlWarehouseTransferReasonQueryVariables>(WarehouseTransferReasonDocument, baseOptions);
      }
export function useWarehouseTransferReasonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlWarehouseTransferReasonQuery, GqlWarehouseTransferReasonQueryVariables>) {
          return Apollo.useLazyQuery<GqlWarehouseTransferReasonQuery, GqlWarehouseTransferReasonQueryVariables>(WarehouseTransferReasonDocument, baseOptions);
        }
export type WarehouseTransferReasonQueryHookResult = ReturnType<typeof useWarehouseTransferReasonQuery>;
export type WarehouseTransferReasonLazyQueryHookResult = ReturnType<typeof useWarehouseTransferReasonLazyQuery>;
export type WarehouseTransferReasonQueryResult = Apollo.QueryResult<GqlWarehouseTransferReasonQuery, GqlWarehouseTransferReasonQueryVariables>;
export const StockTakeDocument = gql`
    query StockTake($search: String, $per_page: Int, $page: Int, $filters: GenericScalar, $distribution_center_id: String!) {
  paginated_product_items_v2(
    filters: $filters
    search: $search
    per_page: $per_page
    page: $page
  ) {
    page_info {
      per_page
      total_count
      total_pages
      current_page
    }
    edges {
      node {
        active
        product_item_name
        product_item_id
        product {
          product_name
        }
        units_of_measure {
          business_domain {
            domain_name
          }
          uom {
            abbreviation
            uom_id
          }
        }
        inventory(distribution_center_id: $distribution_center_id) {
          storage_quantity
          inventory_quantity
          verified_quantity
          distribution_center_id
        }
      }
    }
  }
}
    `;

/**
 * __useStockTakeQuery__
 *
 * To run a query within a React component, call `useStockTakeQuery` and pass it any options that fit your needs.
 * When your component renders, `useStockTakeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStockTakeQuery({
 *   variables: {
 *      search: // value for 'search'
 *      per_page: // value for 'per_page'
 *      page: // value for 'page'
 *      filters: // value for 'filters'
 *      distribution_center_id: // value for 'distribution_center_id'
 *   },
 * });
 */
export function useStockTakeQuery(baseOptions: Apollo.QueryHookOptions<GqlStockTakeQuery, GqlStockTakeQueryVariables>) {
        return Apollo.useQuery<GqlStockTakeQuery, GqlStockTakeQueryVariables>(StockTakeDocument, baseOptions);
      }
export function useStockTakeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlStockTakeQuery, GqlStockTakeQueryVariables>) {
          return Apollo.useLazyQuery<GqlStockTakeQuery, GqlStockTakeQueryVariables>(StockTakeDocument, baseOptions);
        }
export type StockTakeQueryHookResult = ReturnType<typeof useStockTakeQuery>;
export type StockTakeLazyQueryHookResult = ReturnType<typeof useStockTakeLazyQuery>;
export type StockTakeQueryResult = Apollo.QueryResult<GqlStockTakeQuery, GqlStockTakeQueryVariables>;
export const StockIntakesDocument = gql`
    query StockIntakes($page: Int, $per_page: Int, $search: String, $filter: GenericScalar) {
  stock_intakes(
    page: $page
    per_page: $per_page
    search: $search
    filter: $filter
  ) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        active
        stock_intake_id
        stock_intake_number
        stock_intake_status
        date_created
        created_by
        user {
          user_id
          first_name
          last_name
        }
        appointment_id
        appointment {
          appointment_number
          vehicle_seal_number
          dock_door {
            dock_door_number
          }
          distribution_center_id
          distribution_center {
            distribution_center_name
          }
          appointment_items {
            batch_id
            batch {
              batch_name
            }
            booking_id
            booking {
              farmer_id
              farmer {
                first_name
                last_name
                farmer_number
                supplier_name
                farmer_id
              }
              booking_items {
                product_item_id
                product_item {
                  product_item_name
                  product_id
                  product {
                    product_name
                  }
                }
              }
            }
            warehouse_transfer_request_id
            warehouse_transfer_request {
              warehouse_transfer_request_id
              warehouse_transfer_request_items {
                active
                product_item_id
                product_item {
                  product_item_name
                  product {
                    product_name
                  }
                }
                quantity
                weight
              }
            }
          }
        }
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useStockIntakesQuery__
 *
 * To run a query within a React component, call `useStockIntakesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStockIntakesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStockIntakesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      per_page: // value for 'per_page'
 *      search: // value for 'search'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useStockIntakesQuery(baseOptions?: Apollo.QueryHookOptions<GqlStockIntakesQuery, GqlStockIntakesQueryVariables>) {
        return Apollo.useQuery<GqlStockIntakesQuery, GqlStockIntakesQueryVariables>(StockIntakesDocument, baseOptions);
      }
export function useStockIntakesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlStockIntakesQuery, GqlStockIntakesQueryVariables>) {
          return Apollo.useLazyQuery<GqlStockIntakesQuery, GqlStockIntakesQueryVariables>(StockIntakesDocument, baseOptions);
        }
export type StockIntakesQueryHookResult = ReturnType<typeof useStockIntakesQuery>;
export type StockIntakesLazyQueryHookResult = ReturnType<typeof useStockIntakesLazyQuery>;
export type StockIntakesQueryResult = Apollo.QueryResult<GqlStockIntakesQuery, GqlStockIntakesQueryVariables>;
export const StockIntakeItemsDocument = gql`
    query StockIntakeItems($per_page: Int, $search: String, $filter: GenericScalar) {
  stock_intakes(per_page: $per_page, search: $search, filter: $filter) {
    edges {
      node {
        active
        stock_intake_id
        stock_intake_number
        stock_intake_skus {
          product_item_id
          product_item {
            product_id
            product {
              product_name
            }
            product_item_name
            units_of_measure {
              business_domain {
                domain_name
              }
              uom_id
              conversion_ratio
            }
          }
        }
        stock_intake_items {
          stock_intake_item_id
          batch_id
          quantity
          active
          weight
          product_item_id
          product_item {
            product_id
            product {
              product_name
            }
            product_item_name
            units_of_measure {
              business_domain {
                domain_name
              }
              conversion_ratio
            }
          }
          unit_of_measure_id
          uom {
            name
          }
          booking_id
          booking {
            price
          }
        }
      }
    }
  }
}
    `;

/**
 * __useStockIntakeItemsQuery__
 *
 * To run a query within a React component, call `useStockIntakeItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStockIntakeItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStockIntakeItemsQuery({
 *   variables: {
 *      per_page: // value for 'per_page'
 *      search: // value for 'search'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useStockIntakeItemsQuery(baseOptions?: Apollo.QueryHookOptions<GqlStockIntakeItemsQuery, GqlStockIntakeItemsQueryVariables>) {
        return Apollo.useQuery<GqlStockIntakeItemsQuery, GqlStockIntakeItemsQueryVariables>(StockIntakeItemsDocument, baseOptions);
      }
export function useStockIntakeItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlStockIntakeItemsQuery, GqlStockIntakeItemsQueryVariables>) {
          return Apollo.useLazyQuery<GqlStockIntakeItemsQuery, GqlStockIntakeItemsQueryVariables>(StockIntakeItemsDocument, baseOptions);
        }
export type StockIntakeItemsQueryHookResult = ReturnType<typeof useStockIntakeItemsQuery>;
export type StockIntakeItemsLazyQueryHookResult = ReturnType<typeof useStockIntakeItemsLazyQuery>;
export type StockIntakeItemsQueryResult = Apollo.QueryResult<GqlStockIntakeItemsQuery, GqlStockIntakeItemsQueryVariables>;
export const StockIntakeSkusDocument = gql`
    query StockIntakeSkus($page: Int, $filter: GenericScalar, $per_page: Int, $search: String) {
  stock_intake_skus(
    page: $page
    filter: $filter
    per_page: $per_page
    search: $search
  ) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        active
        stock_intake_sku_id
        stock_intake_id
        product_item_id
        supplier_batch_number
        stock_date
        order_by_date
        stock_availability_status
        rejected_quantity
        rejection_reason
        product_item {
          product_id
          product {
            product_name
          }
          product_item_name
          units_of_measure {
            business_domain {
              domain_name
            }
            uom {
              name
            }
          }
        }
        stock_intake {
          stock_intake_status
          stock_intake_items {
            active
            weight
            stock_intake_item_assets {
              stock_intake_item_asset_id
              quantity
            }
            quantity
            unit_of_measure_id
            uom {
              name
            }
            product_item_id
            product_item {
              product_item_name
              product {
                product_name
              }
            }
          }
          appointment_id
          appointment {
            appointment_items {
              active
              appointment_id
              warehouse_transfer_id
              batch_id
              booking_id
              return_id
              warehouse_transfer {
                warehouse_transfer_id
                warehouse_transfer_items {
                  active
                  product_item_id
                  product_item {
                    product_item_name
                    product {
                      product_name
                    }
                  }
                  quantity
                  weight
                }
              }
              returns {
                active
                return_id
                product_return_items {
                  active
                  product_item_id
                  product_item {
                    product_item_name
                    product {
                      product_name
                    }
                  }
                  quantity
                  weight
                }
              }
              booking {
                booking_id
                booking_items {
                  active
                  product_item_id
                  product_item {
                    product_item_name
                    product {
                      product_name
                    }
                  }
                  quantity
                  weight
                }
              }
              batch {
                batch_name
                batch_id
                harvests {
                  original_currency
                  harvest_items {
                    product_item_id
                    quantity
                    weight
                    product_item {
                      product_item_name
                      product {
                        product_name
                      }
                    }
                  }
                }
              }
              warehouse_transfer_request_id
              warehouse_transfer_request {
                warehouse_transfer_request_id
                warehouse_transfer_request_items {
                  active
                  product_item_id
                  product_item {
                    product_item_name
                    product {
                      product_name
                    }
                  }
                  quantity
                  weight
                }
              }
            }
          }
        }
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useStockIntakeSkusQuery__
 *
 * To run a query within a React component, call `useStockIntakeSkusQuery` and pass it any options that fit your needs.
 * When your component renders, `useStockIntakeSkusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStockIntakeSkusQuery({
 *   variables: {
 *      page: // value for 'page'
 *      filter: // value for 'filter'
 *      per_page: // value for 'per_page'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useStockIntakeSkusQuery(baseOptions?: Apollo.QueryHookOptions<GqlStockIntakeSkusQuery, GqlStockIntakeSkusQueryVariables>) {
        return Apollo.useQuery<GqlStockIntakeSkusQuery, GqlStockIntakeSkusQueryVariables>(StockIntakeSkusDocument, baseOptions);
      }
export function useStockIntakeSkusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlStockIntakeSkusQuery, GqlStockIntakeSkusQueryVariables>) {
          return Apollo.useLazyQuery<GqlStockIntakeSkusQuery, GqlStockIntakeSkusQueryVariables>(StockIntakeSkusDocument, baseOptions);
        }
export type StockIntakeSkusQueryHookResult = ReturnType<typeof useStockIntakeSkusQuery>;
export type StockIntakeSkusLazyQueryHookResult = ReturnType<typeof useStockIntakeSkusLazyQuery>;
export type StockIntakeSkusQueryResult = Apollo.QueryResult<GqlStockIntakeSkusQuery, GqlStockIntakeSkusQueryVariables>;
export const StockIntakeByIdDocument = gql`
    query StockIntakeById($stock_intake_id: String) {
  stock_intake_by_id(stock_intake_id: $stock_intake_id) {
    active
    stock_intake_number
    stock_intake_id
    stock_intake_photos {
      intake_photo_id
    }
    stock_intake_users {
      active
      user_id
      stock_intake_user_id
      user {
        first_name
        last_name
        phone_number
        user_number
      }
    }
    appointment_id
    appointment {
      appointment_number
      vehicle_registration
      vehicle_seal_number
      driver_name
      issued_by
      issued_by_user {
        first_name
        last_name
      }
      dock_door {
        dock_door_number
        dock_door_id
      }
      distribution_center_id
      distribution_center {
        distribution_center_name
      }
      appointment_items {
        active
        appointment_id
        warehouse_transfer_id
        batch_id
        booking_id
        return_id
        warehouse_transfer {
          warehouse_transfer_id
          warehouse_transfer_items {
            active
            product_item_id
            product_item {
              product_item_name
              product {
                product_name
              }
            }
            unit_of_measure_id
            uom {
              name
            }
            quantity
          }
        }
        returns {
          active
          return_id
          product_return_items {
            active
            product_item_id
            product_item {
              product_item_name
              product {
                product_name
              }
            }
            quantity
            unit_of_measure_id
            uom {
              name
            }
          }
        }
        booking {
          booking_id
          farmer_id
          farmer {
            first_name
            last_name
            farmer_number
          }
          booking_items {
            active
            product_item_id
            product_item {
              product_item_name
              product {
                product_name
              }
            }
            quantity
            unit_of_measure_id
            uom {
              name
            }
          }
        }
        batch {
          batch_name
          batch_id
          harvests {
            original_currency
            farmer_id
            farmer {
              first_name
              last_name
              farmer_number
            }
            harvest_items {
              product_item_id
              quantity
              unit_of_measure_id
              uom {
                name
              }
              product_item {
                product_item_name
                product {
                  product_name
                }
              }
            }
          }
        }
        warehouse_transfer_request_id
        warehouse_transfer_request {
          warehouse_transfer_request_id
          warehouse_transfer_request_items {
            active
            product_item_id
            product_item {
              product_item_name
              product {
                product_name
              }
            }
            quantity
            weight
          }
        }
      }
    }
    date_created
    created_by
    user {
      first_name
      last_name
    }
    stock_intake_status
    decline_reason
    stock_intake_items {
      stock_intake_item_id
      quantity
      active
      weight
      product_item_id
      product_item {
        product_id
        product {
          product_name
        }
        product_item_name
      }
      unit_of_measure_id
      uom {
        name
      }
      pallet_id
      pallet {
        asset_number
      }
      stock_intake_item_status
    }
  }
}
    `;

/**
 * __useStockIntakeByIdQuery__
 *
 * To run a query within a React component, call `useStockIntakeByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useStockIntakeByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStockIntakeByIdQuery({
 *   variables: {
 *      stock_intake_id: // value for 'stock_intake_id'
 *   },
 * });
 */
export function useStockIntakeByIdQuery(baseOptions?: Apollo.QueryHookOptions<GqlStockIntakeByIdQuery, GqlStockIntakeByIdQueryVariables>) {
        return Apollo.useQuery<GqlStockIntakeByIdQuery, GqlStockIntakeByIdQueryVariables>(StockIntakeByIdDocument, baseOptions);
      }
export function useStockIntakeByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlStockIntakeByIdQuery, GqlStockIntakeByIdQueryVariables>) {
          return Apollo.useLazyQuery<GqlStockIntakeByIdQuery, GqlStockIntakeByIdQueryVariables>(StockIntakeByIdDocument, baseOptions);
        }
export type StockIntakeByIdQueryHookResult = ReturnType<typeof useStockIntakeByIdQuery>;
export type StockIntakeByIdLazyQueryHookResult = ReturnType<typeof useStockIntakeByIdLazyQuery>;
export type StockIntakeByIdQueryResult = Apollo.QueryResult<GqlStockIntakeByIdQuery, GqlStockIntakeByIdQueryVariables>;
export const StockIntakeStatusDocument = gql`
    query StockIntakeStatus {
  stock_intake_status
}
    `;

/**
 * __useStockIntakeStatusQuery__
 *
 * To run a query within a React component, call `useStockIntakeStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useStockIntakeStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStockIntakeStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useStockIntakeStatusQuery(baseOptions?: Apollo.QueryHookOptions<GqlStockIntakeStatusQuery, GqlStockIntakeStatusQueryVariables>) {
        return Apollo.useQuery<GqlStockIntakeStatusQuery, GqlStockIntakeStatusQueryVariables>(StockIntakeStatusDocument, baseOptions);
      }
export function useStockIntakeStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlStockIntakeStatusQuery, GqlStockIntakeStatusQueryVariables>) {
          return Apollo.useLazyQuery<GqlStockIntakeStatusQuery, GqlStockIntakeStatusQueryVariables>(StockIntakeStatusDocument, baseOptions);
        }
export type StockIntakeStatusQueryHookResult = ReturnType<typeof useStockIntakeStatusQuery>;
export type StockIntakeStatusLazyQueryHookResult = ReturnType<typeof useStockIntakeStatusLazyQuery>;
export type StockIntakeStatusQueryResult = Apollo.QueryResult<GqlStockIntakeStatusQuery, GqlStockIntakeStatusQueryVariables>;
export const StockAvailabilityStatusDocument = gql`
    query StockAvailabilityStatus {
  stock_availability_status
}
    `;

/**
 * __useStockAvailabilityStatusQuery__
 *
 * To run a query within a React component, call `useStockAvailabilityStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useStockAvailabilityStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStockAvailabilityStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useStockAvailabilityStatusQuery(baseOptions?: Apollo.QueryHookOptions<GqlStockAvailabilityStatusQuery, GqlStockAvailabilityStatusQueryVariables>) {
        return Apollo.useQuery<GqlStockAvailabilityStatusQuery, GqlStockAvailabilityStatusQueryVariables>(StockAvailabilityStatusDocument, baseOptions);
      }
export function useStockAvailabilityStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlStockAvailabilityStatusQuery, GqlStockAvailabilityStatusQueryVariables>) {
          return Apollo.useLazyQuery<GqlStockAvailabilityStatusQuery, GqlStockAvailabilityStatusQueryVariables>(StockAvailabilityStatusDocument, baseOptions);
        }
export type StockAvailabilityStatusQueryHookResult = ReturnType<typeof useStockAvailabilityStatusQuery>;
export type StockAvailabilityStatusLazyQueryHookResult = ReturnType<typeof useStockAvailabilityStatusLazyQuery>;
export type StockAvailabilityStatusQueryResult = Apollo.QueryResult<GqlStockAvailabilityStatusQuery, GqlStockAvailabilityStatusQueryVariables>;
export const IntakesPutAwayJobsDocument = gql`
    query IntakesPutAwayJobs($page: Int, $per_page: Int, $search: String, $filter: GenericScalar) {
  putaway_jobs(page: $page, per_page: $per_page, search: $search, filter: $filter) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        active
        stock_intake_id
        job_id
        job_number
        job_status
        assigned_to
        assigned {
          first_name
          last_name
        }
        product_item_id
        product_item {
          product_item_name
          product {
            product_name
          }
        }
        destination_handling_unit {
          handling_unit_id
          quantity
          asset {
            asset_number
            asset_type {
              asset_type_name
            }
          }
        }
        source_location_id
        source_location {
          dock_door {
            dock_door_number
          }
        }
        destination_location {
          bin {
            aisle
            bin_position
            level
            bin_names {
              bin_name_direction
              aisle
              bin_position
              level
            }
          }
        }
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useIntakesPutAwayJobsQuery__
 *
 * To run a query within a React component, call `useIntakesPutAwayJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntakesPutAwayJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntakesPutAwayJobsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      per_page: // value for 'per_page'
 *      search: // value for 'search'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useIntakesPutAwayJobsQuery(baseOptions?: Apollo.QueryHookOptions<GqlIntakesPutAwayJobsQuery, GqlIntakesPutAwayJobsQueryVariables>) {
        return Apollo.useQuery<GqlIntakesPutAwayJobsQuery, GqlIntakesPutAwayJobsQueryVariables>(IntakesPutAwayJobsDocument, baseOptions);
      }
export function useIntakesPutAwayJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlIntakesPutAwayJobsQuery, GqlIntakesPutAwayJobsQueryVariables>) {
          return Apollo.useLazyQuery<GqlIntakesPutAwayJobsQuery, GqlIntakesPutAwayJobsQueryVariables>(IntakesPutAwayJobsDocument, baseOptions);
        }
export type IntakesPutAwayJobsQueryHookResult = ReturnType<typeof useIntakesPutAwayJobsQuery>;
export type IntakesPutAwayJobsLazyQueryHookResult = ReturnType<typeof useIntakesPutAwayJobsLazyQuery>;
export type IntakesPutAwayJobsQueryResult = Apollo.QueryResult<GqlIntakesPutAwayJobsQuery, GqlIntakesPutAwayJobsQueryVariables>;
export const StockIntakeUsersDocument = gql`
    query StockIntakeUsers($stock_intake_id: String) {
  stock_intake_by_id(stock_intake_id: $stock_intake_id) {
    stock_intake_id
    stock_intake_users {
      active
      user_id
      stock_intake_user_id
      user {
        active
        first_name
        last_name
      }
    }
  }
}
    `;

/**
 * __useStockIntakeUsersQuery__
 *
 * To run a query within a React component, call `useStockIntakeUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useStockIntakeUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStockIntakeUsersQuery({
 *   variables: {
 *      stock_intake_id: // value for 'stock_intake_id'
 *   },
 * });
 */
export function useStockIntakeUsersQuery(baseOptions?: Apollo.QueryHookOptions<GqlStockIntakeUsersQuery, GqlStockIntakeUsersQueryVariables>) {
        return Apollo.useQuery<GqlStockIntakeUsersQuery, GqlStockIntakeUsersQueryVariables>(StockIntakeUsersDocument, baseOptions);
      }
export function useStockIntakeUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlStockIntakeUsersQuery, GqlStockIntakeUsersQueryVariables>) {
          return Apollo.useLazyQuery<GqlStockIntakeUsersQuery, GqlStockIntakeUsersQueryVariables>(StockIntakeUsersDocument, baseOptions);
        }
export type StockIntakeUsersQueryHookResult = ReturnType<typeof useStockIntakeUsersQuery>;
export type StockIntakeUsersLazyQueryHookResult = ReturnType<typeof useStockIntakeUsersLazyQuery>;
export type StockIntakeUsersQueryResult = Apollo.QueryResult<GqlStockIntakeUsersQuery, GqlStockIntakeUsersQueryVariables>;
export const PutAwayJobsDocument = gql`
    query PutAwayJobs($page: Int, $per_page: Int, $search: String, $filter: GenericScalar) {
  putaway_jobs(page: $page, per_page: $per_page, search: $search, filter: $filter) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        ...PutAwayJobs
        date_created
        close_time
        start_time
        stock_intake {
          stock_intake_number
          stock_intake_id
        }
        stock_intake_item_id
        cancelled
      }
    }
  }
}
    ${PaginationFragmentDoc}
${PutAwayJobsFragmentDoc}`;

/**
 * __usePutAwayJobsQuery__
 *
 * To run a query within a React component, call `usePutAwayJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePutAwayJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePutAwayJobsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      per_page: // value for 'per_page'
 *      search: // value for 'search'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePutAwayJobsQuery(baseOptions?: Apollo.QueryHookOptions<GqlPutAwayJobsQuery, GqlPutAwayJobsQueryVariables>) {
        return Apollo.useQuery<GqlPutAwayJobsQuery, GqlPutAwayJobsQueryVariables>(PutAwayJobsDocument, baseOptions);
      }
export function usePutAwayJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlPutAwayJobsQuery, GqlPutAwayJobsQueryVariables>) {
          return Apollo.useLazyQuery<GqlPutAwayJobsQuery, GqlPutAwayJobsQueryVariables>(PutAwayJobsDocument, baseOptions);
        }
export type PutAwayJobsQueryHookResult = ReturnType<typeof usePutAwayJobsQuery>;
export type PutAwayJobsLazyQueryHookResult = ReturnType<typeof usePutAwayJobsLazyQuery>;
export type PutAwayJobsQueryResult = Apollo.QueryResult<GqlPutAwayJobsQuery, GqlPutAwayJobsQueryVariables>;
export const AllReservePickingJobsDocument = gql`
    query AllReservePickingJobs($search: String, $filter: GenericScalar, $per_page: Int, $page: Int) {
  reserve_picking_jobs(
    search: $search
    per_page: $per_page
    page: $page
    filter: $filter
  ) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        ...ReservePickingJobsFragment
        date_created
        close_time
        start_time
        job_type
        warehouse_transfer_request_id
        warehouse_transfer_request {
          warehouse_transfer_request_number
        }
        fdr_id
        fdr {
          fdr_number
        }
        destination_location {
          dock_door {
            dock_door_number
          }
          bin {
            aisle
            bin_position
            level
            bin_names {
              bin_name_direction
              aisle
              bin_position
              level
            }
          }
        }
      }
    }
  }
}
    ${PaginationFragmentDoc}
${ReservePickingJobsFragmentFragmentDoc}`;

/**
 * __useAllReservePickingJobsQuery__
 *
 * To run a query within a React component, call `useAllReservePickingJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllReservePickingJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllReservePickingJobsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      filter: // value for 'filter'
 *      per_page: // value for 'per_page'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useAllReservePickingJobsQuery(baseOptions?: Apollo.QueryHookOptions<GqlAllReservePickingJobsQuery, GqlAllReservePickingJobsQueryVariables>) {
        return Apollo.useQuery<GqlAllReservePickingJobsQuery, GqlAllReservePickingJobsQueryVariables>(AllReservePickingJobsDocument, baseOptions);
      }
export function useAllReservePickingJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlAllReservePickingJobsQuery, GqlAllReservePickingJobsQueryVariables>) {
          return Apollo.useLazyQuery<GqlAllReservePickingJobsQuery, GqlAllReservePickingJobsQueryVariables>(AllReservePickingJobsDocument, baseOptions);
        }
export type AllReservePickingJobsQueryHookResult = ReturnType<typeof useAllReservePickingJobsQuery>;
export type AllReservePickingJobsLazyQueryHookResult = ReturnType<typeof useAllReservePickingJobsLazyQuery>;
export type AllReservePickingJobsQueryResult = Apollo.QueryResult<GqlAllReservePickingJobsQuery, GqlAllReservePickingJobsQueryVariables>;
export const ProductItemPickfacesBinDocument = gql`
    query ProductItemPickfacesBin($page: Int, $per_page: Int, $search: String, $filter: GenericScalar) {
  bins(search: $search, filter: $filter, page: $page, per_page: $per_page) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        pickface_product_item_id
        active
        bin_type
        is_pickface
        storage_location_id
        storage_location {
          storage_location_number
        }
        capacity
        bin_id
        product_zone {
          active
          product_zone_id
          product_zone_name
        }
        pickface_product_item {
          product_item_id
          product_item_name
          product_id
          product {
            product_name
          }
        }
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useProductItemPickfacesBinQuery__
 *
 * To run a query within a React component, call `useProductItemPickfacesBinQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductItemPickfacesBinQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductItemPickfacesBinQuery({
 *   variables: {
 *      page: // value for 'page'
 *      per_page: // value for 'per_page'
 *      search: // value for 'search'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useProductItemPickfacesBinQuery(baseOptions?: Apollo.QueryHookOptions<GqlProductItemPickfacesBinQuery, GqlProductItemPickfacesBinQueryVariables>) {
        return Apollo.useQuery<GqlProductItemPickfacesBinQuery, GqlProductItemPickfacesBinQueryVariables>(ProductItemPickfacesBinDocument, baseOptions);
      }
export function useProductItemPickfacesBinLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlProductItemPickfacesBinQuery, GqlProductItemPickfacesBinQueryVariables>) {
          return Apollo.useLazyQuery<GqlProductItemPickfacesBinQuery, GqlProductItemPickfacesBinQueryVariables>(ProductItemPickfacesBinDocument, baseOptions);
        }
export type ProductItemPickfacesBinQueryHookResult = ReturnType<typeof useProductItemPickfacesBinQuery>;
export type ProductItemPickfacesBinLazyQueryHookResult = ReturnType<typeof useProductItemPickfacesBinLazyQuery>;
export type ProductItemPickfacesBinQueryResult = Apollo.QueryResult<GqlProductItemPickfacesBinQuery, GqlProductItemPickfacesBinQueryVariables>;
export const LocationsDocument = gql`
    query Locations($search: String) {
  storage_locations(search: $search) {
    edges {
      node {
        status
        storage_location_id
        storage_location_number
        active
        bin {
          bin_id
          capacity
          is_pickface
          product_zone {
            active
            product_zone_id
            product_zone_name
          }
        }
        location_type {
          location_type_name
        }
        handling_units {
          quantity
          stock_date
          stock_availability_status
          order_by_date
          active
          handling_unit_id
          asset {
            asset_id
            asset_number
          }
          handling_unit_items {
            dispatched
            active
            product_item_id
            product_item {
              product_item_name
              product {
                product_name
              }
            }
            handling_unit_item_id
          }
        }
      }
    }
  }
}
    `;

/**
 * __useLocationsQuery__
 *
 * To run a query within a React component, call `useLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationsQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useLocationsQuery(baseOptions?: Apollo.QueryHookOptions<GqlLocationsQuery, GqlLocationsQueryVariables>) {
        return Apollo.useQuery<GqlLocationsQuery, GqlLocationsQueryVariables>(LocationsDocument, baseOptions);
      }
export function useLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlLocationsQuery, GqlLocationsQueryVariables>) {
          return Apollo.useLazyQuery<GqlLocationsQuery, GqlLocationsQueryVariables>(LocationsDocument, baseOptions);
        }
export type LocationsQueryHookResult = ReturnType<typeof useLocationsQuery>;
export type LocationsLazyQueryHookResult = ReturnType<typeof useLocationsLazyQuery>;
export type LocationsQueryResult = Apollo.QueryResult<GqlLocationsQuery, GqlLocationsQueryVariables>;
export const ProductLocationsDocument = gql`
    query ProductLocations($per_page: Int, $page: Int, $search: String, $productLocationFilters: GenericScalar) {
  product_locations(
    per_page: $per_page
    page: $page
    filters: $productLocationFilters
    search: $search
  ) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        storage_location_id
        storage_location_number
        product_item_id
        product_item {
          product_id
          product {
            product_id
            product_name
          }
          image_url
          product_item_name
        }
        hu_count
        hui_count
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useProductLocationsQuery__
 *
 * To run a query within a React component, call `useProductLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductLocationsQuery({
 *   variables: {
 *      per_page: // value for 'per_page'
 *      page: // value for 'page'
 *      search: // value for 'search'
 *      productLocationFilters: // value for 'productLocationFilters'
 *   },
 * });
 */
export function useProductLocationsQuery(baseOptions?: Apollo.QueryHookOptions<GqlProductLocationsQuery, GqlProductLocationsQueryVariables>) {
        return Apollo.useQuery<GqlProductLocationsQuery, GqlProductLocationsQueryVariables>(ProductLocationsDocument, baseOptions);
      }
export function useProductLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlProductLocationsQuery, GqlProductLocationsQueryVariables>) {
          return Apollo.useLazyQuery<GqlProductLocationsQuery, GqlProductLocationsQueryVariables>(ProductLocationsDocument, baseOptions);
        }
export type ProductLocationsQueryHookResult = ReturnType<typeof useProductLocationsQuery>;
export type ProductLocationsLazyQueryHookResult = ReturnType<typeof useProductLocationsLazyQuery>;
export type ProductLocationsQueryResult = Apollo.QueryResult<GqlProductLocationsQuery, GqlProductLocationsQueryVariables>;
export const ProductTransferReasonsDocument = gql`
    query ProductTransferReasons {
  product_transfer_reasons {
    edges {
      node {
        product_transfer_reason_id
        product_transfer_reason_name
      }
    }
  }
}
    `;

/**
 * __useProductTransferReasonsQuery__
 *
 * To run a query within a React component, call `useProductTransferReasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductTransferReasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductTransferReasonsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProductTransferReasonsQuery(baseOptions?: Apollo.QueryHookOptions<GqlProductTransferReasonsQuery, GqlProductTransferReasonsQueryVariables>) {
        return Apollo.useQuery<GqlProductTransferReasonsQuery, GqlProductTransferReasonsQueryVariables>(ProductTransferReasonsDocument, baseOptions);
      }
export function useProductTransferReasonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlProductTransferReasonsQuery, GqlProductTransferReasonsQueryVariables>) {
          return Apollo.useLazyQuery<GqlProductTransferReasonsQuery, GqlProductTransferReasonsQueryVariables>(ProductTransferReasonsDocument, baseOptions);
        }
export type ProductTransferReasonsQueryHookResult = ReturnType<typeof useProductTransferReasonsQuery>;
export type ProductTransferReasonsLazyQueryHookResult = ReturnType<typeof useProductTransferReasonsLazyQuery>;
export type ProductTransferReasonsQueryResult = Apollo.QueryResult<GqlProductTransferReasonsQuery, GqlProductTransferReasonsQueryVariables>;
export const HandlingUnitItemsDocument = gql`
    query HandlingUnitItems($filter: GenericScalar) {
  handling_unit_items(filter: $filter) {
    edges {
      node {
        active
        handling_unit_item_id
        quantity
        dispatched
        asset {
          asset_type {
            asset_type_name
          }
          asset_number
        }
        storage_location {
          location_type {
            location_type_name
          }
          storage_location_number
        }
        handling_unit {
          asset {
            asset_type {
              asset_type_name
            }
            asset_number
          }
        }
        stock_date
        stock_availability_status
        order_by_date
        product_item_id
        product_item {
          product_item_name
          product_id
          product {
            product_name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useHandlingUnitItemsQuery__
 *
 * To run a query within a React component, call `useHandlingUnitItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHandlingUnitItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHandlingUnitItemsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useHandlingUnitItemsQuery(baseOptions?: Apollo.QueryHookOptions<GqlHandlingUnitItemsQuery, GqlHandlingUnitItemsQueryVariables>) {
        return Apollo.useQuery<GqlHandlingUnitItemsQuery, GqlHandlingUnitItemsQueryVariables>(HandlingUnitItemsDocument, baseOptions);
      }
export function useHandlingUnitItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlHandlingUnitItemsQuery, GqlHandlingUnitItemsQueryVariables>) {
          return Apollo.useLazyQuery<GqlHandlingUnitItemsQuery, GqlHandlingUnitItemsQueryVariables>(HandlingUnitItemsDocument, baseOptions);
        }
export type HandlingUnitItemsQueryHookResult = ReturnType<typeof useHandlingUnitItemsQuery>;
export type HandlingUnitItemsLazyQueryHookResult = ReturnType<typeof useHandlingUnitItemsLazyQuery>;
export type HandlingUnitItemsQueryResult = Apollo.QueryResult<GqlHandlingUnitItemsQuery, GqlHandlingUnitItemsQueryVariables>;
export const AssetsDocument = gql`
    query Assets($filter: GenericScalar, $search: String) {
  assets(filter: $filter, search: $search) {
    edges {
      node {
        asset_id
        active
        asset_number
        asset_type_id
        asset_type {
          asset_type_name
        }
        handling_units {
          active
          quantity
          storage_location_id
          stock_availability_status
          stock_date
          order_by_date
          storage_location {
            location_type {
              location_type_name
            }
            storage_location_number
          }
          handling_unit_id
          quantity
          asset {
            asset_id
            asset_number
            asset_type {
              asset_type_name
            }
          }
          handling_unit_items {
            dispatched
            storage_location_id
            active
            asset {
              asset_id
              asset_number
              asset_type {
                asset_type_name
              }
            }
            product_item_id
            product_item {
              product_id
              product_item_id
              product_item_name
              product_id
              product {
                product_name
              }
            }
            handling_unit_id
            handling_unit_item_id
            date_updated
            order_by_date
          }
        }
      }
    }
  }
}
    `;

/**
 * __useAssetsQuery__
 *
 * To run a query within a React component, call `useAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useAssetsQuery(baseOptions?: Apollo.QueryHookOptions<GqlAssetsQuery, GqlAssetsQueryVariables>) {
        return Apollo.useQuery<GqlAssetsQuery, GqlAssetsQueryVariables>(AssetsDocument, baseOptions);
      }
export function useAssetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlAssetsQuery, GqlAssetsQueryVariables>) {
          return Apollo.useLazyQuery<GqlAssetsQuery, GqlAssetsQueryVariables>(AssetsDocument, baseOptions);
        }
export type AssetsQueryHookResult = ReturnType<typeof useAssetsQuery>;
export type AssetsLazyQueryHookResult = ReturnType<typeof useAssetsLazyQuery>;
export type AssetsQueryResult = Apollo.QueryResult<GqlAssetsQuery, GqlAssetsQueryVariables>;
export const OrderFulfilmentDocument = gql`
    query OrderFulfilment($search: String, $orderFulfilmentFilters: GenericScalar, $per_page: Int, $page: Int) {
  fulfilment_dispatch_requests(
    search: $search
    per_page: $per_page
    page: $page
    filter: $orderFulfilmentFilters
  ) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        ...FulfilmentDispatchReQuest
        distribution_center_id
        distribution_center {
          distribution_center_name
        }
      }
    }
  }
}
    ${PaginationFragmentDoc}
${FulfilmentDispatchReQuestFragmentDoc}`;

/**
 * __useOrderFulfilmentQuery__
 *
 * To run a query within a React component, call `useOrderFulfilmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderFulfilmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderFulfilmentQuery({
 *   variables: {
 *      search: // value for 'search'
 *      orderFulfilmentFilters: // value for 'orderFulfilmentFilters'
 *      per_page: // value for 'per_page'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useOrderFulfilmentQuery(baseOptions?: Apollo.QueryHookOptions<GqlOrderFulfilmentQuery, GqlOrderFulfilmentQueryVariables>) {
        return Apollo.useQuery<GqlOrderFulfilmentQuery, GqlOrderFulfilmentQueryVariables>(OrderFulfilmentDocument, baseOptions);
      }
export function useOrderFulfilmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlOrderFulfilmentQuery, GqlOrderFulfilmentQueryVariables>) {
          return Apollo.useLazyQuery<GqlOrderFulfilmentQuery, GqlOrderFulfilmentQueryVariables>(OrderFulfilmentDocument, baseOptions);
        }
export type OrderFulfilmentQueryHookResult = ReturnType<typeof useOrderFulfilmentQuery>;
export type OrderFulfilmentLazyQueryHookResult = ReturnType<typeof useOrderFulfilmentLazyQuery>;
export type OrderFulfilmentQueryResult = Apollo.QueryResult<GqlOrderFulfilmentQuery, GqlOrderFulfilmentQueryVariables>;
export const OrderFulfilmentByIdDocument = gql`
    query OrderFulfilmentById($search: String, $orderFulfilmentFilters: GenericScalar, $per_page: Int) {
  fulfilment_dispatch_requests(
    search: $search
    per_page: $per_page
    filter: $orderFulfilmentFilters
  ) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        ...FulfilmentDispatchReQuest
        ...FDRSkus
        loading_task {
          loaded_quantity {
            quantity
          }
        }
        created_by
        created_by_user {
          first_name
          last_name
        }
      }
    }
  }
}
    ${PaginationFragmentDoc}
${FulfilmentDispatchReQuestFragmentDoc}
${FdrSkusFragmentDoc}`;

/**
 * __useOrderFulfilmentByIdQuery__
 *
 * To run a query within a React component, call `useOrderFulfilmentByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderFulfilmentByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderFulfilmentByIdQuery({
 *   variables: {
 *      search: // value for 'search'
 *      orderFulfilmentFilters: // value for 'orderFulfilmentFilters'
 *      per_page: // value for 'per_page'
 *   },
 * });
 */
export function useOrderFulfilmentByIdQuery(baseOptions?: Apollo.QueryHookOptions<GqlOrderFulfilmentByIdQuery, GqlOrderFulfilmentByIdQueryVariables>) {
        return Apollo.useQuery<GqlOrderFulfilmentByIdQuery, GqlOrderFulfilmentByIdQueryVariables>(OrderFulfilmentByIdDocument, baseOptions);
      }
export function useOrderFulfilmentByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlOrderFulfilmentByIdQuery, GqlOrderFulfilmentByIdQueryVariables>) {
          return Apollo.useLazyQuery<GqlOrderFulfilmentByIdQuery, GqlOrderFulfilmentByIdQueryVariables>(OrderFulfilmentByIdDocument, baseOptions);
        }
export type OrderFulfilmentByIdQueryHookResult = ReturnType<typeof useOrderFulfilmentByIdQuery>;
export type OrderFulfilmentByIdLazyQueryHookResult = ReturnType<typeof useOrderFulfilmentByIdLazyQuery>;
export type OrderFulfilmentByIdQueryResult = Apollo.QueryResult<GqlOrderFulfilmentByIdQuery, GqlOrderFulfilmentByIdQueryVariables>;
export const OrderFulfilmentByIdSummaryDocument = gql`
    query OrderFulfilmentByIdSummary($orderFulfilmentFilters: GenericScalar) {
  fulfilment_dispatch_requests(filter: $orderFulfilmentFilters) {
    edges {
      node {
        ...FulfilmentDispatchReQuest
        ...FDRSkus
        reserve_picking_jobs {
          job_status
          job_id
          quantity
        }
        pickface_picking_tasks {
          task_id
          quantity
          balance
          product_item_id
          product_item {
            product_item_name
            product {
              product_name
            }
          }
        }
      }
    }
  }
}
    ${FulfilmentDispatchReQuestFragmentDoc}
${FdrSkusFragmentDoc}`;

/**
 * __useOrderFulfilmentByIdSummaryQuery__
 *
 * To run a query within a React component, call `useOrderFulfilmentByIdSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderFulfilmentByIdSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderFulfilmentByIdSummaryQuery({
 *   variables: {
 *      orderFulfilmentFilters: // value for 'orderFulfilmentFilters'
 *   },
 * });
 */
export function useOrderFulfilmentByIdSummaryQuery(baseOptions?: Apollo.QueryHookOptions<GqlOrderFulfilmentByIdSummaryQuery, GqlOrderFulfilmentByIdSummaryQueryVariables>) {
        return Apollo.useQuery<GqlOrderFulfilmentByIdSummaryQuery, GqlOrderFulfilmentByIdSummaryQueryVariables>(OrderFulfilmentByIdSummaryDocument, baseOptions);
      }
export function useOrderFulfilmentByIdSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlOrderFulfilmentByIdSummaryQuery, GqlOrderFulfilmentByIdSummaryQueryVariables>) {
          return Apollo.useLazyQuery<GqlOrderFulfilmentByIdSummaryQuery, GqlOrderFulfilmentByIdSummaryQueryVariables>(OrderFulfilmentByIdSummaryDocument, baseOptions);
        }
export type OrderFulfilmentByIdSummaryQueryHookResult = ReturnType<typeof useOrderFulfilmentByIdSummaryQuery>;
export type OrderFulfilmentByIdSummaryLazyQueryHookResult = ReturnType<typeof useOrderFulfilmentByIdSummaryLazyQuery>;
export type OrderFulfilmentByIdSummaryQueryResult = Apollo.QueryResult<GqlOrderFulfilmentByIdSummaryQuery, GqlOrderFulfilmentByIdSummaryQueryVariables>;
export const FdrSkuDocument = gql`
    query FdrSKU($search: String, $fdrSkuFilters: GenericScalar, $per_page: Int, $page: Int) {
  fdr_skus(
    search: $search
    per_page: $per_page
    filter: $fdrSkuFilters
    page: $page
  ) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        active
        product_item_id
        available_stock
        inventory_balance
        loaded_quantity
        verified
        unverified
        product_item {
          product_item_name
          product_id
          product {
            product_name
          }
        }
        quantity
        fdr_id
        fdr {
          reserve_picking_jobs {
            product_item_id
            job_status
            product_item {
              product_item_name
              product {
                product_name
              }
            }
          }
          reserve_picking_balance {
            balance
          }
          pickface_picking_tasks {
            quantity
            balance
            product_item_id
          }
        }
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useFdrSkuQuery__
 *
 * To run a query within a React component, call `useFdrSkuQuery` and pass it any options that fit your needs.
 * When your component renders, `useFdrSkuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFdrSkuQuery({
 *   variables: {
 *      search: // value for 'search'
 *      fdrSkuFilters: // value for 'fdrSkuFilters'
 *      per_page: // value for 'per_page'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useFdrSkuQuery(baseOptions?: Apollo.QueryHookOptions<GqlFdrSkuQuery, GqlFdrSkuQueryVariables>) {
        return Apollo.useQuery<GqlFdrSkuQuery, GqlFdrSkuQueryVariables>(FdrSkuDocument, baseOptions);
      }
export function useFdrSkuLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlFdrSkuQuery, GqlFdrSkuQueryVariables>) {
          return Apollo.useLazyQuery<GqlFdrSkuQuery, GqlFdrSkuQueryVariables>(FdrSkuDocument, baseOptions);
        }
export type FdrSkuQueryHookResult = ReturnType<typeof useFdrSkuQuery>;
export type FdrSkuLazyQueryHookResult = ReturnType<typeof useFdrSkuLazyQuery>;
export type FdrSkuQueryResult = Apollo.QueryResult<GqlFdrSkuQuery, GqlFdrSkuQueryVariables>;
export const ReservePickingJobsDocument = gql`
    query ReservePickingJobs($search: String, $pickingJobsFilters: GenericScalar, $per_page: Int, $page: Int) {
  reserve_picking_jobs(
    search: $search
    per_page: $per_page
    page: $page
    filter: $pickingJobsFilters
  ) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        ...ReservePickingJobsFragment
      }
    }
  }
}
    ${PaginationFragmentDoc}
${ReservePickingJobsFragmentFragmentDoc}`;

/**
 * __useReservePickingJobsQuery__
 *
 * To run a query within a React component, call `useReservePickingJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReservePickingJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReservePickingJobsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      pickingJobsFilters: // value for 'pickingJobsFilters'
 *      per_page: // value for 'per_page'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useReservePickingJobsQuery(baseOptions?: Apollo.QueryHookOptions<GqlReservePickingJobsQuery, GqlReservePickingJobsQueryVariables>) {
        return Apollo.useQuery<GqlReservePickingJobsQuery, GqlReservePickingJobsQueryVariables>(ReservePickingJobsDocument, baseOptions);
      }
export function useReservePickingJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlReservePickingJobsQuery, GqlReservePickingJobsQueryVariables>) {
          return Apollo.useLazyQuery<GqlReservePickingJobsQuery, GqlReservePickingJobsQueryVariables>(ReservePickingJobsDocument, baseOptions);
        }
export type ReservePickingJobsQueryHookResult = ReturnType<typeof useReservePickingJobsQuery>;
export type ReservePickingJobsLazyQueryHookResult = ReturnType<typeof useReservePickingJobsLazyQuery>;
export type ReservePickingJobsQueryResult = Apollo.QueryResult<GqlReservePickingJobsQuery, GqlReservePickingJobsQueryVariables>;
export const WarehouseTransferRequestDocument = gql`
    query WarehouseTransferRequest($search: String, $warehouseFilters: GenericScalar, $per_page: Int) {
  warehouse_transfer_requests(
    search: $search
    per_page: $per_page
    filter: $warehouseFilters
  ) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        active
        warehouse_transfer_request_id
        warehouse_transfer_request_number
        warehouse_transfer_request_complete
        vehicle_id
        vehicle {
          registration_number
        }
        date_created
        destination_dc_id
        destination_dc {
          distribution_center_name
        }
        dock_door_id
        dock_door {
          dock_door_number
          staging_area_name
          warehouse_id
          warehouse {
            warehouse_name
            distribution_center_id
            distribution_center {
              distribution_center_name
            }
          }
        }
        warehouse_transfer_request_items {
          warehouse_transfer_request_item_id
          warehouse_transfer_request_id
          quantity
          weight
          product_item_id
          product_item {
            product_item_name
            product {
              product_name
            }
            units_of_measure {
              business_domain {
                domain_name
                business_domain_id
              }
              uom {
                name
              }
            }
          }
        }
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useWarehouseTransferRequestQuery__
 *
 * To run a query within a React component, call `useWarehouseTransferRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useWarehouseTransferRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWarehouseTransferRequestQuery({
 *   variables: {
 *      search: // value for 'search'
 *      warehouseFilters: // value for 'warehouseFilters'
 *      per_page: // value for 'per_page'
 *   },
 * });
 */
export function useWarehouseTransferRequestQuery(baseOptions?: Apollo.QueryHookOptions<GqlWarehouseTransferRequestQuery, GqlWarehouseTransferRequestQueryVariables>) {
        return Apollo.useQuery<GqlWarehouseTransferRequestQuery, GqlWarehouseTransferRequestQueryVariables>(WarehouseTransferRequestDocument, baseOptions);
      }
export function useWarehouseTransferRequestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlWarehouseTransferRequestQuery, GqlWarehouseTransferRequestQueryVariables>) {
          return Apollo.useLazyQuery<GqlWarehouseTransferRequestQuery, GqlWarehouseTransferRequestQueryVariables>(WarehouseTransferRequestDocument, baseOptions);
        }
export type WarehouseTransferRequestQueryHookResult = ReturnType<typeof useWarehouseTransferRequestQuery>;
export type WarehouseTransferRequestLazyQueryHookResult = ReturnType<typeof useWarehouseTransferRequestLazyQuery>;
export type WarehouseTransferRequestQueryResult = Apollo.QueryResult<GqlWarehouseTransferRequestQuery, GqlWarehouseTransferRequestQueryVariables>;
export const WarehouseTransferRequestItemsDocument = gql`
    query WarehouseTransferRequestItems($search: String, $warehouseItemFilters: GenericScalar, $per_page: Int) {
  warehouse_transfer_request_items(
    search: $search
    per_page: $per_page
    filter: $warehouseItemFilters
  ) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        active
        warehouse_transfer_request_id
        product_item_id
        product_item {
          product_item_name
          product_id
          product {
            product_name
          }
        }
        warehouse_transfer_request {
          reserve_picking_jobs {
            product_item_id
            job_status
            product_item {
              product_item_name
              product {
                product_name
              }
            }
          }
          reserve_picking_balance {
            balance
          }
          pickface_picking_tasks {
            quantity
            balance
            product_item_id
          }
        }
        quantity
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useWarehouseTransferRequestItemsQuery__
 *
 * To run a query within a React component, call `useWarehouseTransferRequestItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWarehouseTransferRequestItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWarehouseTransferRequestItemsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      warehouseItemFilters: // value for 'warehouseItemFilters'
 *      per_page: // value for 'per_page'
 *   },
 * });
 */
export function useWarehouseTransferRequestItemsQuery(baseOptions?: Apollo.QueryHookOptions<GqlWarehouseTransferRequestItemsQuery, GqlWarehouseTransferRequestItemsQueryVariables>) {
        return Apollo.useQuery<GqlWarehouseTransferRequestItemsQuery, GqlWarehouseTransferRequestItemsQueryVariables>(WarehouseTransferRequestItemsDocument, baseOptions);
      }
export function useWarehouseTransferRequestItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlWarehouseTransferRequestItemsQuery, GqlWarehouseTransferRequestItemsQueryVariables>) {
          return Apollo.useLazyQuery<GqlWarehouseTransferRequestItemsQuery, GqlWarehouseTransferRequestItemsQueryVariables>(WarehouseTransferRequestItemsDocument, baseOptions);
        }
export type WarehouseTransferRequestItemsQueryHookResult = ReturnType<typeof useWarehouseTransferRequestItemsQuery>;
export type WarehouseTransferRequestItemsLazyQueryHookResult = ReturnType<typeof useWarehouseTransferRequestItemsLazyQuery>;
export type WarehouseTransferRequestItemsQueryResult = Apollo.QueryResult<GqlWarehouseTransferRequestItemsQuery, GqlWarehouseTransferRequestItemsQueryVariables>;
export const DisposalsDocument = gql`
    query Disposals($search: String, $disposalFilters: GenericScalar, $per_page: Int, $page: Int) {
  disposals(
    search: $search
    per_page: $per_page
    page: $page
    filter: $disposalFilters
  ) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        dock_door_id
        disposal_id
        active
        date_created
        disposal_number
        disposal_complete
        dock_door_id
        dock_door {
          dock_door_number
          warehouse_id
          warehouse {
            warehouse_name
            distribution_center_id
            distribution_center {
              distribution_center_name
            }
          }
        }
        disposal_items {
          quantity
          product_item_id
          product_item {
            product_item_name
            product {
              product_name
            }
            units_of_measure {
              business_domain {
                domain_name
                business_domain_id
              }
              uom {
                name
              }
            }
          }
        }
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useDisposalsQuery__
 *
 * To run a query within a React component, call `useDisposalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDisposalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDisposalsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      disposalFilters: // value for 'disposalFilters'
 *      per_page: // value for 'per_page'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useDisposalsQuery(baseOptions?: Apollo.QueryHookOptions<GqlDisposalsQuery, GqlDisposalsQueryVariables>) {
        return Apollo.useQuery<GqlDisposalsQuery, GqlDisposalsQueryVariables>(DisposalsDocument, baseOptions);
      }
export function useDisposalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlDisposalsQuery, GqlDisposalsQueryVariables>) {
          return Apollo.useLazyQuery<GqlDisposalsQuery, GqlDisposalsQueryVariables>(DisposalsDocument, baseOptions);
        }
export type DisposalsQueryHookResult = ReturnType<typeof useDisposalsQuery>;
export type DisposalsLazyQueryHookResult = ReturnType<typeof useDisposalsLazyQuery>;
export type DisposalsQueryResult = Apollo.QueryResult<GqlDisposalsQuery, GqlDisposalsQueryVariables>;
export const DisposalItemsDocument = gql`
    query DisposalItems($search: String, $disposalItemFilters: GenericScalar, $per_page: Int) {
  disposal_items(
    search: $search
    per_page: $per_page
    filter: $disposalItemFilters
  ) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        active
        disposal_item_id
        disposal_id
        product_item_id
        product_item {
          product_item_name
          product_id
          product {
            product_name
          }
        }
        quantity
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useDisposalItemsQuery__
 *
 * To run a query within a React component, call `useDisposalItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDisposalItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDisposalItemsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      disposalItemFilters: // value for 'disposalItemFilters'
 *      per_page: // value for 'per_page'
 *   },
 * });
 */
export function useDisposalItemsQuery(baseOptions?: Apollo.QueryHookOptions<GqlDisposalItemsQuery, GqlDisposalItemsQueryVariables>) {
        return Apollo.useQuery<GqlDisposalItemsQuery, GqlDisposalItemsQueryVariables>(DisposalItemsDocument, baseOptions);
      }
export function useDisposalItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlDisposalItemsQuery, GqlDisposalItemsQueryVariables>) {
          return Apollo.useLazyQuery<GqlDisposalItemsQuery, GqlDisposalItemsQueryVariables>(DisposalItemsDocument, baseOptions);
        }
export type DisposalItemsQueryHookResult = ReturnType<typeof useDisposalItemsQuery>;
export type DisposalItemsLazyQueryHookResult = ReturnType<typeof useDisposalItemsLazyQuery>;
export type DisposalItemsQueryResult = Apollo.QueryResult<GqlDisposalItemsQuery, GqlDisposalItemsQueryVariables>;
export const WmsReturnsDocument = gql`
    query WMSReturns($search: String, $returnsFilters: GenericScalar, $per_page: Int) {
  wms_returns(search: $search, per_page: $per_page, filter: $returnsFilters) {
    edges {
      node {
        wms_returns_number
        wms_returns_id
        appointment_id
        appointment {
          appointment_number
          vehicle_registration
          driver_name
          eta
          dock_door_id
          dock_door {
            dock_door_number
          }
          issued_by
          issued_by_user {
            first_name
            last_name
          }
          distribution_center_id
          distribution_center {
            distribution_center_name
          }
          appointment_items {
            return_id
            returns {
              product_return_items {
                quantity
                unit_of_measure_id
                uom {
                  name
                }
                product_item_id
                product_item {
                  product_item_name
                  product {
                    product_name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useWmsReturnsQuery__
 *
 * To run a query within a React component, call `useWmsReturnsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWmsReturnsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWmsReturnsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      returnsFilters: // value for 'returnsFilters'
 *      per_page: // value for 'per_page'
 *   },
 * });
 */
export function useWmsReturnsQuery(baseOptions?: Apollo.QueryHookOptions<GqlWmsReturnsQuery, GqlWmsReturnsQueryVariables>) {
        return Apollo.useQuery<GqlWmsReturnsQuery, GqlWmsReturnsQueryVariables>(WmsReturnsDocument, baseOptions);
      }
export function useWmsReturnsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlWmsReturnsQuery, GqlWmsReturnsQueryVariables>) {
          return Apollo.useLazyQuery<GqlWmsReturnsQuery, GqlWmsReturnsQueryVariables>(WmsReturnsDocument, baseOptions);
        }
export type WmsReturnsQueryHookResult = ReturnType<typeof useWmsReturnsQuery>;
export type WmsReturnsLazyQueryHookResult = ReturnType<typeof useWmsReturnsLazyQuery>;
export type WmsReturnsQueryResult = Apollo.QueryResult<GqlWmsReturnsQuery, GqlWmsReturnsQueryVariables>;
export const LoadingTasksDocument = gql`
    query LoadingTasks($search: String, $filters: GenericScalar, $per_page: Int, $page: Int) {
  loading_tasks(
    search: $search
    per_page: $per_page
    filter: $filters
    page: $page
  ) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        active
        status
        loading_number
        loaded_weight
        loading_task_id
        date_updated
        fdr {
          fdr_number
          dock_door {
            warehouse {
              distribution_center {
                distribution_center_name
              }
            }
          }
          fdr_items {
            route_id
            route {
              route_name
              depot_id
              depot {
                depot_name
              }
            }
            route_plan_id
            route_plan {
              vehicle_id
              vehicle {
                registration_number
              }
              depot_id
              depot {
                depot_name
              }
            }
          }
        }
        inventory_location_id
        inventory_location {
          inventory_location_name
        }
        total_loaded_quantity
        loading_task_items {
          handling_unit_item_id
          handling_unit_item {
            quantity
            product_item_id
            product_item {
              product_item_name
              product {
                product_name
              }
              units_of_measure {
                business_domain {
                  domain_name
                }
                uom {
                  name
                }
              }
            }
          }
          loading_task_id
          loading_task {
            loading_task_id
            fdr {
              fdr_id
              fdr_skus {
                fdr_sku_id
                product_item_id
                verified
                unverified
              }
            }
          }
        }
        picture
        created_by
        created_by_user {
          first_name
          last_name
        }
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useLoadingTasksQuery__
 *
 * To run a query within a React component, call `useLoadingTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadingTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadingTasksQuery({
 *   variables: {
 *      search: // value for 'search'
 *      filters: // value for 'filters'
 *      per_page: // value for 'per_page'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useLoadingTasksQuery(baseOptions?: Apollo.QueryHookOptions<GqlLoadingTasksQuery, GqlLoadingTasksQueryVariables>) {
        return Apollo.useQuery<GqlLoadingTasksQuery, GqlLoadingTasksQueryVariables>(LoadingTasksDocument, baseOptions);
      }
export function useLoadingTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlLoadingTasksQuery, GqlLoadingTasksQueryVariables>) {
          return Apollo.useLazyQuery<GqlLoadingTasksQuery, GqlLoadingTasksQueryVariables>(LoadingTasksDocument, baseOptions);
        }
export type LoadingTasksQueryHookResult = ReturnType<typeof useLoadingTasksQuery>;
export type LoadingTasksLazyQueryHookResult = ReturnType<typeof useLoadingTasksLazyQuery>;
export type LoadingTasksQueryResult = Apollo.QueryResult<GqlLoadingTasksQuery, GqlLoadingTasksQueryVariables>;
export const PickingHandlingUnitsDocument = gql`
    query PickingHandlingUnits($filters: GenericScalar, $per_page: Int, $page: Int) {
  picking_handling_units(filter: $filters, per_page: $per_page, page: $page) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        active
        picking_hu_id
        dispatch_reference_id
        handling_unit_id
        final_location_id
        final_location {
          storage_location_number
        }
        handling_unit {
          asset {
            asset_number
          }
          storage_location_id
          storage_location {
            storage_location_number
          }
          updated_by
          updated_by_user {
            first_name
            last_name
          }
          handling_unit_items {
            storage_location_id
            storage_location {
              storage_location_number
            }
            asset {
              asset_number
            }
            product_item_id
            product_item {
              product_item_name
              product_id
              product {
                product_name
              }
            }
          }
        }
        is_complete
        picked_quantity
        handling_unit_id
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __usePickingHandlingUnitsQuery__
 *
 * To run a query within a React component, call `usePickingHandlingUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePickingHandlingUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePickingHandlingUnitsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      per_page: // value for 'per_page'
 *      page: // value for 'page'
 *   },
 * });
 */
export function usePickingHandlingUnitsQuery(baseOptions?: Apollo.QueryHookOptions<GqlPickingHandlingUnitsQuery, GqlPickingHandlingUnitsQueryVariables>) {
        return Apollo.useQuery<GqlPickingHandlingUnitsQuery, GqlPickingHandlingUnitsQueryVariables>(PickingHandlingUnitsDocument, baseOptions);
      }
export function usePickingHandlingUnitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlPickingHandlingUnitsQuery, GqlPickingHandlingUnitsQueryVariables>) {
          return Apollo.useLazyQuery<GqlPickingHandlingUnitsQuery, GqlPickingHandlingUnitsQueryVariables>(PickingHandlingUnitsDocument, baseOptions);
        }
export type PickingHandlingUnitsQueryHookResult = ReturnType<typeof usePickingHandlingUnitsQuery>;
export type PickingHandlingUnitsLazyQueryHookResult = ReturnType<typeof usePickingHandlingUnitsLazyQuery>;
export type PickingHandlingUnitsQueryResult = Apollo.QueryResult<GqlPickingHandlingUnitsQuery, GqlPickingHandlingUnitsQueryVariables>;
export const ProductZonesDocument = gql`
    query ProductZones($per_page: Int, $page: Int, $filters: GenericScalar, $search: String) {
  product_zones(
    per_page: $per_page
    page: $page
    filter: $filters
    search: $search
  ) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        active
        product_zone_name
        product_zone_type
        product_zone_id
        product_zone_description
        rules {
          active
          rule_name
          rule_id
        }
        bins {
          active
          is_pickface
          capacity
          storage_location_id
          storage_location {
            status
          }
          bin_names {
            aisle
            level
            bin_position
            bin_id
          }
        }
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useProductZonesQuery__
 *
 * To run a query within a React component, call `useProductZonesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductZonesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductZonesQuery({
 *   variables: {
 *      per_page: // value for 'per_page'
 *      page: // value for 'page'
 *      filters: // value for 'filters'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useProductZonesQuery(baseOptions?: Apollo.QueryHookOptions<GqlProductZonesQuery, GqlProductZonesQueryVariables>) {
        return Apollo.useQuery<GqlProductZonesQuery, GqlProductZonesQueryVariables>(ProductZonesDocument, baseOptions);
      }
export function useProductZonesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlProductZonesQuery, GqlProductZonesQueryVariables>) {
          return Apollo.useLazyQuery<GqlProductZonesQuery, GqlProductZonesQueryVariables>(ProductZonesDocument, baseOptions);
        }
export type ProductZonesQueryHookResult = ReturnType<typeof useProductZonesQuery>;
export type ProductZonesLazyQueryHookResult = ReturnType<typeof useProductZonesLazyQuery>;
export type ProductZonesQueryResult = Apollo.QueryResult<GqlProductZonesQuery, GqlProductZonesQueryVariables>;
export const RulesDocument = gql`
    query Rules($per_page: Int, $page: Int, $filters: GenericScalar, $search: String, $active: Boolean) {
  rules(
    per_page: $per_page
    page: $page
    filter: $filters
    search: $search
    active: $active
  ) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        active
        rule_id
        rule_name
        rule_description
        product_rules {
          product_id
          product_item_id
          product {
            active
            product_name
            product_id
          }
          product_item_id
          is_sku_rule
          product_item {
            active
            product {
              product_name
            }
            product_item_name
          }
        }
        category_rules {
          category_id
          category_rule_type
          active
          category {
            category_name
            category_id
          }
        }
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useRulesQuery__
 *
 * To run a query within a React component, call `useRulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRulesQuery({
 *   variables: {
 *      per_page: // value for 'per_page'
 *      page: // value for 'page'
 *      filters: // value for 'filters'
 *      search: // value for 'search'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useRulesQuery(baseOptions?: Apollo.QueryHookOptions<GqlRulesQuery, GqlRulesQueryVariables>) {
        return Apollo.useQuery<GqlRulesQuery, GqlRulesQueryVariables>(RulesDocument, baseOptions);
      }
export function useRulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlRulesQuery, GqlRulesQueryVariables>) {
          return Apollo.useLazyQuery<GqlRulesQuery, GqlRulesQueryVariables>(RulesDocument, baseOptions);
        }
export type RulesQueryHookResult = ReturnType<typeof useRulesQuery>;
export type RulesLazyQueryHookResult = ReturnType<typeof useRulesLazyQuery>;
export type RulesQueryResult = Apollo.QueryResult<GqlRulesQuery, GqlRulesQueryVariables>;
export const ProductRulesDocument = gql`
    query ProductRules($filters: GenericScalar) {
  product_rules(filter: $filters) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        active
        rule_id
        product_id
        product_item_id
        is_sku_rule
        product_item {
          product {
            product_name
          }
          product_item_name
        }
        product {
          product_name
        }
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useProductRulesQuery__
 *
 * To run a query within a React component, call `useProductRulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductRulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductRulesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useProductRulesQuery(baseOptions?: Apollo.QueryHookOptions<GqlProductRulesQuery, GqlProductRulesQueryVariables>) {
        return Apollo.useQuery<GqlProductRulesQuery, GqlProductRulesQueryVariables>(ProductRulesDocument, baseOptions);
      }
export function useProductRulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlProductRulesQuery, GqlProductRulesQueryVariables>) {
          return Apollo.useLazyQuery<GqlProductRulesQuery, GqlProductRulesQueryVariables>(ProductRulesDocument, baseOptions);
        }
export type ProductRulesQueryHookResult = ReturnType<typeof useProductRulesQuery>;
export type ProductRulesLazyQueryHookResult = ReturnType<typeof useProductRulesLazyQuery>;
export type ProductRulesQueryResult = Apollo.QueryResult<GqlProductRulesQuery, GqlProductRulesQueryVariables>;
export const CategoryRulesDocument = gql`
    query CategoryRules($filters: GenericScalar) {
  category_rules(filter: $filters) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        active
        rule_id
        category_id
        category {
          category_name
        }
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useCategoryRulesQuery__
 *
 * To run a query within a React component, call `useCategoryRulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryRulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryRulesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCategoryRulesQuery(baseOptions?: Apollo.QueryHookOptions<GqlCategoryRulesQuery, GqlCategoryRulesQueryVariables>) {
        return Apollo.useQuery<GqlCategoryRulesQuery, GqlCategoryRulesQueryVariables>(CategoryRulesDocument, baseOptions);
      }
export function useCategoryRulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlCategoryRulesQuery, GqlCategoryRulesQueryVariables>) {
          return Apollo.useLazyQuery<GqlCategoryRulesQuery, GqlCategoryRulesQueryVariables>(CategoryRulesDocument, baseOptions);
        }
export type CategoryRulesQueryHookResult = ReturnType<typeof useCategoryRulesQuery>;
export type CategoryRulesLazyQueryHookResult = ReturnType<typeof useCategoryRulesLazyQuery>;
export type CategoryRulesQueryResult = Apollo.QueryResult<GqlCategoryRulesQuery, GqlCategoryRulesQueryVariables>;
export const ZoneTypesDocument = gql`
    query ZoneTypes {
  product_zone_types
}
    `;

/**
 * __useZoneTypesQuery__
 *
 * To run a query within a React component, call `useZoneTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useZoneTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useZoneTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useZoneTypesQuery(baseOptions?: Apollo.QueryHookOptions<GqlZoneTypesQuery, GqlZoneTypesQueryVariables>) {
        return Apollo.useQuery<GqlZoneTypesQuery, GqlZoneTypesQueryVariables>(ZoneTypesDocument, baseOptions);
      }
export function useZoneTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlZoneTypesQuery, GqlZoneTypesQueryVariables>) {
          return Apollo.useLazyQuery<GqlZoneTypesQuery, GqlZoneTypesQueryVariables>(ZoneTypesDocument, baseOptions);
        }
export type ZoneTypesQueryHookResult = ReturnType<typeof useZoneTypesQuery>;
export type ZoneTypesLazyQueryHookResult = ReturnType<typeof useZoneTypesLazyQuery>;
export type ZoneTypesQueryResult = Apollo.QueryResult<GqlZoneTypesQuery, GqlZoneTypesQueryVariables>;
export const StorageLocationsDocument = gql`
    query StorageLocations($filters: GenericScalar, $search: String, $per_page: Int) {
  bins(filter: $filters, search: $search, per_page: $per_page) {
    edges {
      node {
        pickface_product_item_id
        is_pickface
        bin_names {
          bin_id
          aisle
          level
          bin_position
        }
      }
    }
  }
}
    `;

/**
 * __useStorageLocationsQuery__
 *
 * To run a query within a React component, call `useStorageLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStorageLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStorageLocationsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      search: // value for 'search'
 *      per_page: // value for 'per_page'
 *   },
 * });
 */
export function useStorageLocationsQuery(baseOptions?: Apollo.QueryHookOptions<GqlStorageLocationsQuery, GqlStorageLocationsQueryVariables>) {
        return Apollo.useQuery<GqlStorageLocationsQuery, GqlStorageLocationsQueryVariables>(StorageLocationsDocument, baseOptions);
      }
export function useStorageLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlStorageLocationsQuery, GqlStorageLocationsQueryVariables>) {
          return Apollo.useLazyQuery<GqlStorageLocationsQuery, GqlStorageLocationsQueryVariables>(StorageLocationsDocument, baseOptions);
        }
export type StorageLocationsQueryHookResult = ReturnType<typeof useStorageLocationsQuery>;
export type StorageLocationsLazyQueryHookResult = ReturnType<typeof useStorageLocationsLazyQuery>;
export type StorageLocationsQueryResult = Apollo.QueryResult<GqlStorageLocationsQuery, GqlStorageLocationsQueryVariables>;
export const FilterBinsDocument = gql`
    query FilterBins($from: String, $to: String) {
  filter_bins(_from: $from, _to: $to) {
    bin_id
    aisle
    bin_position
    level
    storage_location_id
  }
}
    `;

/**
 * __useFilterBinsQuery__
 *
 * To run a query within a React component, call `useFilterBinsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilterBinsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilterBinsQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useFilterBinsQuery(baseOptions?: Apollo.QueryHookOptions<GqlFilterBinsQuery, GqlFilterBinsQueryVariables>) {
        return Apollo.useQuery<GqlFilterBinsQuery, GqlFilterBinsQueryVariables>(FilterBinsDocument, baseOptions);
      }
export function useFilterBinsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlFilterBinsQuery, GqlFilterBinsQueryVariables>) {
          return Apollo.useLazyQuery<GqlFilterBinsQuery, GqlFilterBinsQueryVariables>(FilterBinsDocument, baseOptions);
        }
export type FilterBinsQueryHookResult = ReturnType<typeof useFilterBinsQuery>;
export type FilterBinsLazyQueryHookResult = ReturnType<typeof useFilterBinsLazyQuery>;
export type FilterBinsQueryResult = Apollo.QueryResult<GqlFilterBinsQuery, GqlFilterBinsQueryVariables>;
export const RoutePlansDocument = gql`
    query RoutePlans($filters: GenericScalar, $search: String) {
  route_plans(filters: $filters, search: $search) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        route_plan_id
        delivery_date
        depot_id
        depot {
          depot_name
        }
        multiple_trips
        vehicle_trip
        vehicle_id
        vehicle {
          registration_number
        }
        route_id
        route {
          route_name
        }
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useRoutePlansQuery__
 *
 * To run a query within a React component, call `useRoutePlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoutePlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoutePlansQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useRoutePlansQuery(baseOptions?: Apollo.QueryHookOptions<GqlRoutePlansQuery, GqlRoutePlansQueryVariables>) {
        return Apollo.useQuery<GqlRoutePlansQuery, GqlRoutePlansQueryVariables>(RoutePlansDocument, baseOptions);
      }
export function useRoutePlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlRoutePlansQuery, GqlRoutePlansQueryVariables>) {
          return Apollo.useLazyQuery<GqlRoutePlansQuery, GqlRoutePlansQueryVariables>(RoutePlansDocument, baseOptions);
        }
export type RoutePlansQueryHookResult = ReturnType<typeof useRoutePlansQuery>;
export type RoutePlansLazyQueryHookResult = ReturnType<typeof useRoutePlansLazyQuery>;
export type RoutePlansQueryResult = Apollo.QueryResult<GqlRoutePlansQuery, GqlRoutePlansQueryVariables>;
export const StorageLocationTypesDocument = gql`
    query StorageLocationTypes($filters: GenericScalar, $search: String) {
  storage_location_types(filters: $filters, search: $search) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        location_type_id
        location_type_name
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useStorageLocationTypesQuery__
 *
 * To run a query within a React component, call `useStorageLocationTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStorageLocationTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStorageLocationTypesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useStorageLocationTypesQuery(baseOptions?: Apollo.QueryHookOptions<GqlStorageLocationTypesQuery, GqlStorageLocationTypesQueryVariables>) {
        return Apollo.useQuery<GqlStorageLocationTypesQuery, GqlStorageLocationTypesQueryVariables>(StorageLocationTypesDocument, baseOptions);
      }
export function useStorageLocationTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlStorageLocationTypesQuery, GqlStorageLocationTypesQueryVariables>) {
          return Apollo.useLazyQuery<GqlStorageLocationTypesQuery, GqlStorageLocationTypesQueryVariables>(StorageLocationTypesDocument, baseOptions);
        }
export type StorageLocationTypesQueryHookResult = ReturnType<typeof useStorageLocationTypesQuery>;
export type StorageLocationTypesLazyQueryHookResult = ReturnType<typeof useStorageLocationTypesLazyQuery>;
export type StorageLocationTypesQueryResult = Apollo.QueryResult<GqlStorageLocationTypesQuery, GqlStorageLocationTypesQueryVariables>;
export const Wms_Storage_LocationsDocument = gql`
    query WMS_Storage_Locations($filters: GenericScalar, $search: String) {
  storage_locations(filters: $filters, search: $search) {
    page_info {
      ...Pagination
    }
    edges {
      node {
        storage_location_id
        storage_location_number
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useWms_Storage_LocationsQuery__
 *
 * To run a query within a React component, call `useWms_Storage_LocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWms_Storage_LocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWms_Storage_LocationsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useWms_Storage_LocationsQuery(baseOptions?: Apollo.QueryHookOptions<GqlWms_Storage_LocationsQuery, GqlWms_Storage_LocationsQueryVariables>) {
        return Apollo.useQuery<GqlWms_Storage_LocationsQuery, GqlWms_Storage_LocationsQueryVariables>(Wms_Storage_LocationsDocument, baseOptions);
      }
export function useWms_Storage_LocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlWms_Storage_LocationsQuery, GqlWms_Storage_LocationsQueryVariables>) {
          return Apollo.useLazyQuery<GqlWms_Storage_LocationsQuery, GqlWms_Storage_LocationsQueryVariables>(Wms_Storage_LocationsDocument, baseOptions);
        }
export type Wms_Storage_LocationsQueryHookResult = ReturnType<typeof useWms_Storage_LocationsQuery>;
export type Wms_Storage_LocationsLazyQueryHookResult = ReturnType<typeof useWms_Storage_LocationsLazyQuery>;
export type Wms_Storage_LocationsQueryResult = Apollo.QueryResult<GqlWms_Storage_LocationsQuery, GqlWms_Storage_LocationsQueryVariables>;
export const GetEtimsEventOutboxItemsDocument = gql`
    query GetEtimsEventOutboxItems($filter: GenericScalar) {
  etims_event_outbox_items(filter: $filter) {
    edges {
      node {
        processed
        dispatch_id
        intake_id
        dms_disposal_id
      }
    }
  }
}
    `;

/**
 * __useGetEtimsEventOutboxItemsQuery__
 *
 * To run a query within a React component, call `useGetEtimsEventOutboxItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEtimsEventOutboxItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEtimsEventOutboxItemsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetEtimsEventOutboxItemsQuery(baseOptions?: Apollo.QueryHookOptions<GqlGetEtimsEventOutboxItemsQuery, GqlGetEtimsEventOutboxItemsQueryVariables>) {
        return Apollo.useQuery<GqlGetEtimsEventOutboxItemsQuery, GqlGetEtimsEventOutboxItemsQueryVariables>(GetEtimsEventOutboxItemsDocument, baseOptions);
      }
export function useGetEtimsEventOutboxItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlGetEtimsEventOutboxItemsQuery, GqlGetEtimsEventOutboxItemsQueryVariables>) {
          return Apollo.useLazyQuery<GqlGetEtimsEventOutboxItemsQuery, GqlGetEtimsEventOutboxItemsQueryVariables>(GetEtimsEventOutboxItemsDocument, baseOptions);
        }
export type GetEtimsEventOutboxItemsQueryHookResult = ReturnType<typeof useGetEtimsEventOutboxItemsQuery>;
export type GetEtimsEventOutboxItemsLazyQueryHookResult = ReturnType<typeof useGetEtimsEventOutboxItemsLazyQuery>;
export type GetEtimsEventOutboxItemsQueryResult = Apollo.QueryResult<GqlGetEtimsEventOutboxItemsQuery, GqlGetEtimsEventOutboxItemsQueryVariables>;